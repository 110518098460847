import React, { useContext, useEffect, Fragment, useState } from 'react';
import ArtistContext from '../../context/artist/artistContext'; // might have to capitalize

import { Link, useParams } from 'react-router-dom';
// import { useAuth, clearErrors, login } from '../../context/auth/AuthState';
import { useAuth } from '../../context/auth/AuthState';

import ProfileHeader from './ProfileHeader';
import UpcomingEventsForUser from '../events/UpcomingEventsForUser';
import { Button, Form, Modal } from 'react-bootstrap';

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Calendar from '../artist/Calendar';
import PrivateLessonsWithNoEvent from '../artist/PrivateLessonsWithNoEvent';
import PrivateLessonRequest from '../artist/PrivateLessonRequest';
import PrivateLessonRequestNoEvent from '../artist/PrivateLessonRequestNoEvent';
// import { Link } from 'react-router-dom';
// import EditUserProfile from './EditUserProfile';

// export default class UserProfile extends Component {
const ArtistTools = (props) => {
  let { instructor_id } = useParams(); //this is the id
  console.log(`instructor_id id is ${instructor_id}`);

  const artistContext = useContext(ArtistContext);
  const {
    getArtistById,
    artist,
    sendPrivateLessonRequest,
    privateLessonRequest,
    getPrivateLessonRequestsForArtist,

    privateLessonRequests,
    getPrivateLessonRequestsForArtistAtEvents,
    privateLessonRequestsAtEvents,
  } = artistContext;

  // console.log('location.state is:-----------');
  // const location = useLocation();

  // console.log(location.state);

  // const [authState, authDispatch] = useAuth();
  const [authState] = useAuth();

  // const { error, isAuthenticated } = authState;
  const { user } = authState;

  // const { target_user, joined_events, trips } = props;
  // const isCurrentUserProfile = user?._id === target_user._id;
  useEffect(() => {
    console.log('instructor_id iss....');
    console.log(instructor_id);
    getArtistById(instructor_id);
    getPrivateLessonRequestsForArtist(instructor_id);
    getPrivateLessonRequestsForArtistAtEvents(instructor_id);
    console.log('artist FROM AP');
    console.log(artist);
    console.log('privateLessonRequests');
    console.log(privateLessonRequests);
    console.log('privateLessonRequestsAtEvents for silvia');
    console.log(privateLessonRequestsAtEvents);
    // eslint-disable-next-line
  }, []);

  const [selected, setSelected] = React.useState();
  const [updateDate, setUpdateDate] = React.useState();
  const [showSelection, setShowSelection] = React.useState(false);
  const [date, setDate] = React.useState();
  const [privateLessonTime, setPrivateLessonTime] = useState('09:00');

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    lessonTime: privateLessonTime,
    lessonDate: selected,
    event_id: '', // might not be applicable
    // requesting_user_id: '123',

    requestor_name: user?.name,
    requestor_id: user?._id,
    requestor_phone_number: user?.phoneNumber,
    requested_artist_id: instructor_id,
    status: 'TBD',
    comments_or_special_requests: 'none',

    // location: 'Charlotte, NC',
  });
  const { lessonDate } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const setFunction = (selected) => {
    console.log('selected fropm func');
    console.log(selected);
    // setDetails({ ...details, lessonDate: selected });
  };
  let detailsNotUpdated = false;

  const updatedDate = (selected, hasBeenUpdated) => {
    // detailsNotUpdated = true;
    if (!hasBeenUpdated) {
      console.log('selected from sr');
      console.log(selected);
      setDetails({ ...details, lessonDate: selected });
      // hasBeenUpdated = ;
    }
    return false;
  };
  let footer = <p>Please pick a day.</p>;
  // if (selected && updatedDate(selected, false)) {
  if (selected) {
    // setDate('today');
    footer = <p>You picked {format(selected, 'PP')}.</p>;
    // console.log('dud ut');
    // if (notUpdated) {
    //   setFunction(selected);
    //   notUpdated = false;
    // }

    // setDate(selected);
    // setShowSelection(true);
    // setSelected(false);
  }
  if (updateDate) {
    setDetails({
      ...details,
      lessonDate: selected,
      requestor_id: user?._id,
      requestor_name: user?.name,
      requestor_phone_number: user?.phoneNumber,
    });
    setUpdateDate(false);
  }

  if (showSelection) {
    // footer = <p>You picked {format(selected, 'PP')}.</p>;
    footer = <p>You picked {format(lessonDate, 'PP')}.</p>;

    // footer = <p>You picked {format(lessonDate}.</p>;
  }
  const [calendarSelected, setCalendarSelected] = React.useState(false);
  const [showEventCalendar, setShowEventCalendar] = React.useState(false);
  const [
    showNonEventLessonRequests,
    setShowNonEventLessonRequests,
  ] = React.useState(false);
  const [bookPrivateSelected, setBookPrivateSelected] = React.useState(false);
  const handleTimeChange = (time) => {
    console.log('changed time');
    console.log(time);
    setPrivateLessonTime(time);
    setDetails({ ...details, lessonTime: time });

    // setDetails({ ...details, ['time']: time });

    // let date_time = workshopDate + ' ' + time;
    // filterRoomsByDateAndTime(date_time);
  };

  let times_array = [
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '09:30',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '13:30',
    '14:00',
    '14:00',
    '15:00',
    '16:00',
    '16:30',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    // setDetails({ ...details, lessonDate: selected });

    console.log('user is');
    console.log(user);
    sendPrivateLessonRequest(details);
    console.log('details');
    console.log(details);
    // if (details === '') {
    //   //   setAlert('Please fill in all fields', 'danger');
    // } else {
    //   const data = new FormData();
    //   data.append('file', file);
    //   data.append('title', blog_title);
    //   data.append('text', text);
    //   data.append('blog_image', blog_image);

    //   postBlogPost(data);
    // }
  };

  const setDatePlease = (selected) => {
    // setDate(selected)
    console.log('truing to set date from setDateplease');
    console.log(selected);
    setDetails({ ...details, lessonDate: selected });
  };

  let events_and_lessons = {
    event_id: '1',
    event_name: 'zoukheat',

    privateLessonRequests: [
      {
        req_id: '1',
        req_name: 'tom',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
      {
        req_id: '12',
        req_name: 'jen',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
      {
        req_id: '13',
        req_name: 'lenny',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
    ],
  };

  let calendar = [events_and_lessons];
  console.log('privateLessonRequests from the return');
  console.log(privateLessonRequests);
  console.log('privateLessonRequests 4 silv');
  console.log(privateLessonRequestsAtEvents);

  const [artistOptionsPopupSelected, setArtistOptionsPopupSelected] = useState(
    false
  );
  const handleClose = () => {
    setArtistOptionsPopupSelected(!artistOptionsPopupSelected);
  };

  const popupArtistOptions = () => {
    setArtistOptionsPopupSelected(!artistOptionsPopupSelected);
  };
  return (
    <div>
      {/* <ProfileHeader
        isCurrentUser={isCurrentUserProfile}
        current_user_id={user?._id}
        user={target_user}
      />
      <UpcomingEventsForUser
        isCurrentUser={isCurrentUserProfile}
        joined_events={joined_events}
        trips={trips}
      /> */}

      <div>
        <div className="row">
          <div className="col-md-6">
            <Link to="/user/profile" className="btn btn-light mb-3 float-left">
              Back To Profile
            </Link>
            {/* <Link to="/profiles" className="btn btn-light mb-3 float-left">
              Back To Profiles
            </Link>
            <Link to="/profiles" className="btn btn-light mb-3 float-left">
              Edit Profile
            </Link> */}
            {/* <EditUserProfile /> */}
          </div>

          <div className="col-md-6" />
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card card-body bgg-primary text-white mb-3">
              <div className="row text-center">
                <div className="row">
                  <Button
                    onClick={() => {
                      popupArtistOptions();
                    }}
                    style={{
                      width: '1em',
                      alignContent: 'center',
                      backgroundColor: 'white',
                      backgroundSize: '1',
                    }}
                    className="all-center"
                  >
                    ✅
                  </Button>
                </div>

                {artistOptionsPopupSelected ? (
                  <div>
                    {' '}
                    <Modal
                      show={artistOptionsPopupSelected}
                      onHide={handleClose}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{props.event_name}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            {/* <Form.Label>popup</Form.Label> */}
                            <h5 style={{ color: 'black' }}>
                              artist verified
                              {/* if this profile belongs to you, click here to{' '}
                              <Link
                                to={`/register-and-claim-artist/${instructor_id}`}
                                state={{ instructor_id: instructor_id }}
                              >
                                <span style={{ color: 'blue' }}>
                                  claim your page
                                </span>
                              </Link> */}
                            </h5>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  </div>
                ) : (
                  <></>
                )}

                <div className="col col-md-3 m-auto">
                  <img
                    className="rounded-circle"
                    // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                    src={artist?.image_url?.url}
                    // src={image}
                    alt=""
                    height="150"
                    width="150"
                  />
                </div>
                <h5>{artist.name}</h5>
                <div className="row">
                  <div className="col">
                    <Button
                      onClick={() => {
                        setBookPrivateSelected(!bookPrivateSelected);
                        console.log(`setbook is ${bookPrivateSelected}`);
                      }}
                    >
                      Book Private 👍🏽
                    </Button>
                  </div>
                  <div className="col">
                    <Button
                      onClick={() => {
                        setCalendarSelected(!calendarSelected);
                      }}
                    >
                      Calendar
                    </Button>
                  </div>

                  {/* <div className="col">
                    <Button>Book Private</Button>
                  </div>
                  <div className="col">
                    <Button>Book Private</Button>
                  </div> */}
                </div>

                {/* <h3 className="display-4 text-center">{user.name}</h3>

                <h4>{user?.location}</h4>
                <Link to={`/my-connections`} state={{ target_user: user }}>
                  {user.connections?.length} Connections
                </Link>
                <div className="row">
                
                  {connected()}
                  
                  <div className="col">
                    
                    {renderOptions()}
                  </div>
                  {user?.instagram_name ? (
                    <h5 style={{ fontSize: '16px' }}>
                      {' '}
                      Instagram: {user?.instagram_name}
                    </h5>
                  ) : (
                    <></>
                  )}
                  {user?.tiktok_name ? (
                    <h5 style={{ fontSize: '16px' }}>
                      {' '}
                      Tiktok: {user?.tiktok_name}
                    </h5>
                  ) : (
                    <></>
                  )}

                  {user?.website_name ? (
                    <h5 style={{ fontSize: '16px' }}>
                      {' '}
                      Website: {user?.website_name}
                    </h5>
                  ) : (
                    <></>
                  )}

                  {user?.website_name ||
                  user?.tiktok_name ||
                  user?.instagram_name ? (
                    <hr />
                  ) : (
                    <></>
                  )}
                  <h5 style={{ fontSize: '16px' }}>{user?.about_you}</h5>
                </div> */}
              </div>

              <div className="text-center" />
            </div>
          </div>
        </div>
        <div>
          {bookPrivateSelected && (
            <div style={{ justifyContent: 'center' }}>
              <DayPicker
                mode="single"
                selected={selected}
                onSelect={setSelected}
                footer={footer}
                onDayClick={() => {
                  // if(stateUpdated()){

                  setSelected(selected);
                  setDatePlease(selected);
                  console.log('hello');
                  console.log('selected');
                  console.log(selected);
                  setDetails({ ...details, lessonDate: selected });
                  setUpdateDate(true);
                  // }
                }}
              />

              <div className="row">
                <div className="col">
                  <label>Workshop Time</label>
                </div>

                <div className="col">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      as={ButtonGroup}
                      key={idx}
                      id={`dropdown-button-drop-${idx}`}
                      size="sm"
                      variant="secondary"
                      title={privateLessonTime}
                      // title="10:40"
                      onSelect={handleTimeChange}
                      // onChange={onChange}
                    >
                      {times_array.map((time) => (
                        <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>
                      ))}
                    </DropdownType>
                  ))}
                </div>

                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <input
                      type="submit"
                      value={
                        user ? 'Send Lesson Request' : 'Sign in to request'
                      }
                      class="btn btn-secondary"
                      disabled={user ? false : true}
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        {calendarSelected && (
          <div className="row">
            <div className="col">
              <Button
                onClick={() => {
                  setShowEventCalendar(!showEventCalendar);
                  if (showNonEventLessonRequests) {
                    setShowNonEventLessonRequests(false);
                  }
                }}
              >
                {showEventCalendar ? `Hide` : `Event Calendar`}
              </Button>
            </div>
            <div className="col">
              <Button
                onClick={() => {
                  setShowNonEventLessonRequests(!showNonEventLessonRequests);
                  if (showEventCalendar) {
                    setShowEventCalendar(false);
                  }
                }}
              >
                {showNonEventLessonRequests ? `Hide` : `Other Private Lessons`}
              </Button>
            </div>
          </div>
        )}

        <div>
          {calendarSelected &&
            showEventCalendar && (
              <div>
                <h5>Event Calendar</h5>
                <Calendar
                  privateLessonRequestsAtEvents={privateLessonRequestsAtEvents}
                />
              </div>
            )}
        </div>

        {/* 
        
        */}
      </div>
      {/* <PrivateLessonsWithNoEvent lessons={privateLessonRequests} /> */}

      {calendarSelected &&
        showNonEventLessonRequests && (
          <div>
            {privateLessonRequests.map((lesson) => (
              <PrivateLessonRequestNoEvent
                key={lesson._id}
                lesson={lesson}
                user={user}
                artist={artist}
              />
              // {lesson.requested_artist_id}
            ))}
          </div>
        )}
    </div>
  );
};
export default ArtistTools;

/*


  <ProfileHeader profile={profile} /> */
/* <ProfileAbout profile={profile} />
        <ProfileCreds
          education={profile.education}
          experience={profile.experience}
        />
        {profile.githubusername ? (
          <ProfileGithub username={profile.githubusername} />
        ) : null}

*/
