//consider renaming to potential_mate to differentiate btw user
import React, { useContext, useEffect, Fragment, useState } from 'react';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import AdvertismentContext from '../../context/advertisement/advertismentContext';
// import AdvertisementContext from '../../context/advertisement/advertisementContext'; // might have to capitalize

import { Button, Dropdown } from 'react-bootstrap';
import EventItem from './EventItem';
import PropTypes from 'prop-types';

import AlertContext from '../../context/alert/alertContext';
import { useAuth } from '../../context/auth/AuthState';
import { Navigate } from 'react-router-dom';
import Pagination from './Pagination';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import ListOfStates from './ListOfStates.json';
import ListOfStatesAndCities from './ListOfStatesAndCities.json';
import TopDancerOnMainTimeline from '../topLists/TopDancerOnMainTimeline';
import ActivitiesWithPagination from '../activities/ActivitiesWithPagination';
import LocationFilterForMainEventPage from '../layout/LocationFilterForMainEventPage';
import DanceReactions from '../reactions/DanceReactions';
import EventTypeHorizontalMenu from './EventTypeHorizontalMenu';
import EventType from './EventType';
import ArtistContext from '../../context/artist/artistContext';
import ArtistsWeLove from './MainTimelineInjectionsToIncreaseEngagement/ArtistsWeLove';
import RandomRankings from './MainTimelineInjectionsToIncreaseEngagement/RandomRankings';
import RandomStudios from './MainTimelineInjectionsToIncreaseEngagement/RandomStudios';
import DJsForYourNextEvent from './MainTimelineInjectionsToIncreaseEngagement/DJsForYourNextEvent';
import EventFilter from './EventFilter';
import EventFilter2 from './EventFilter2';
import AddEventPopup from './Events/AddEventPopup';
// import ReactGA from 'react-ga4';

const EventWithPagination = () => {
  // ReactGA.pageview(window.location.pathname + window.location.search);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [authState] = useAuth();
  const { user, isAuthenticated } = authState;
  const [loading, setLoading] = useState(false);

  const eventContext = useContext(EventContext);
  const {
    getAllEvents,
    events,
    createNewEvent,
    eventsInLocation,
    getEventsByLocation,
    getHighlightedEvents,
    highlighted_events,
    getEventsWithEventType,
    allEventsWithEventType,
  } = eventContext;

  const adContext = useContext(AdvertismentContext);
  const { getAllAds, advertisements } = adContext;
  const [randomStyle, setRandomStyle] = useState('');
  const artistContext = useContext(ArtistContext);
  const { getFourRandomArtistsForTimeline, artists_we_love } = artistContext;

  useEffect(() => {
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    // ReactGA.send({ hitType: 'pageview', page: '/', title: 'home page' });

    // commented on july 3 2024

    getEventsWithEventType('Concert', 'none', 'none', 'none');
    // getEventsWithEventType('Dance Events', 'none', 'none', 'none');

    // done july 3 2024

    setLoading(true);
    getHighlightedEvents();
    getAllEvents();
    // getEventsByLocation('Charlotte', 'North Carolina', 'all');
    if (user?.location) {
      // if (isAuthenticated && user != null) {
      const cityStateArr = user?.location?.split(',');
      const city = cityStateArr[0];
      const state = cityStateArr[1];
      console.log('city,state for user: ');
      console.log(city, state);
      getEventsByLocation(city, state, 'all');
    }
    // getEventsByLocation('Phoenix', 'Arizona', 'all');
    getAllAds();
    // getAllEventsInLocation(user.location);
    // getAllAdsInLocation(user.location);

    getFourRandomArtistsForTimeline();
    setLoading(false);
    // eslint-disable-next-line
  }, []);
  // const [the_event_type, setEventType] = useState('Concert');

  console.log('allEventsWithEventType yoski');
  console.log(allEventsWithEventType);

  const [the_event_type, setEventType] = useState('Dance Event');

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  // let listOfStates = ['NC', 'SC'];

  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);

  const [selectedState, setSelectedState] = useState('');

  const [selectedCity, setSelectedCity] = useState('');

  // // Get current posts
  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentEvents = events.slice(indexOfFirstPost, indexOfLastPost);
  // console.log('currentEvents');
  // console.log(currentEvents);
  // // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [pageNumber, setPageNumber] = useState(0);
  const eventsPerPage = 10;
  const pagesVisited = pageNumber * eventsPerPage;
  // commented out on 3.7.23
  // const currentEvents = events.slice(
  //   pagesVisited,
  //   pagesVisited + eventsPerPage
  // );
  // let [allEventsWithEventType, setAllEventsWithEventType] = [];
  let currentEvents = [];
  if (eventsInLocation.length > 0) {
    currentEvents = eventsInLocation.slice(
      pagesVisited,
      pagesVisited + eventsPerPage
    );
  }
  if (allEventsWithEventType.length > 0) {
    currentEvents = allEventsWithEventType.slice(
      pagesVisited,
      pagesVisited + eventsPerPage
    );
  }

  // option 1 -- this would make it tricky when it comes to how to
  //render btw events and ads
  let eventsAndAdsArray = currentEvents; //array
  console.log('eventsAndAdsArray before function');
  console.log(eventsAndAdsArray);
  const eventsAndAds = () => {
    let counter = 0;
    console.log('eventsAndAdsArray b4 any insertion');
    console.log(eventsAndAdsArray);
    for (let i = 0; i < currentEvents.length; i++) {
      if (i % 4 === 0 && i !== 1 && i !== 0) {
        //is multiple, show ad
        console.log('i is multiple of 4');
        console.log(i);
        let index = Math.floor(Math.random() * advertisements.length);
        eventsAndAdsArray.splice(i, 0, advertisements[index]);
        // eventsAndAdsArray.splice(i, 0, { name: 'rumbao ldc' });

        console.log('eventsAndAdsArray after insertion');
        console.log(eventsAndAdsArray);
        // counter++;
      }

      if (i === 1) {
        //show top list after 6 and 12 events
        eventsAndAdsArray.splice(i, 0, 'render ArtistsWeLove component');
      }
      if (i === 3) {
        //show top list after 6 and 12 events
        eventsAndAdsArray.splice(i, 0, 'render RandomRankings component');
      }
      if (i === 5) {
        //show top list after 6 and 12 events
        eventsAndAdsArray.splice(i, 0, 'render RandomStudioRankings component');
      }
      if (i === 7) {
        //show top list after 6 and 12 events
        eventsAndAdsArray.splice(i, 0, 'render DJsForYourNextEvent component');
      }
      // if (i === 4 || i === 10) {
      //   //show top list after 6 and 12 events
      //   eventsAndAdsArray.splice(
      //     i,
      //     0,
      //     'render TopDancerOnMainTimeline component'
      //   );
      //   // <TopDancerOnMainTimeline />;
      // }

      // else {
      //   counter++;
      // }
    }
    //master array but how do we render?
    console.log('eventsAndAds');
    console.log(eventsAndAdsArray);
    return eventsAndAdsArray;
  };

  // option 2 should make rendering easy
  const eventsAndAdsWithCounter = () => {
    for (let i = 0; i < events.length; i++) {
      let counter = 0; //5%0 => NaN -- should resolve
      if (5 % counter === 0) {
        let index = Math.floor(Math.random() * advertisements.length);
        console.log(index);
        //addToMasterArray
        renderAd(index);
      } else {
        counter++;
        return renderEvent(counter);
      }
    }
  };

  const renderEvent = (counter) => (
    <Fragment>
      {/* {currentEvents[counter].map((event) => ( */}
      {/* // <EventItem key={event.id} loading={loading} event={event} /> */}
      <EventItem
        key={currentEvents[counter].id}
        loading={loading}
        event={currentEvents[counter]}
      />
      {/* ))} */}
    </Fragment>
  );

  const renderAd = (counter) => (
    <Fragment>
      {/* {advertisements[counter].map((ad) => ( */}

      <div>
        <div className="timelineCard bg-light smallScreenEvents bigScreenEvents">
          {advertisements[counter]?.promotionText}
          <h5>ad by user: {advertisements[counter].ownersId}</h5>
        </div>
      </div>
      {/* ))} */}
    </Fragment>
  );

  const [visible, setVisible] = React.useState(false);
  const [eventsVisible, setEventsVisible] = React.useState(true);
  const [activitiesVisible, setActivitiesVisible] = React.useState(false);
  const [companiesVisible, setCompaniesVisible] = React.useState(false);

  const [showFilter, setShowFilter] = useState(false);

  const handleShowEventFilter = () => {
    setShowFilter(true);
  };
  const onAssociationChange = () => {
    setCompaniesVisible(!companiesVisible);
    if (user?.companies) {
      setDetails({ ...details, company_id: user?.companies[0].company_id });
    }
    console.log(details);
  };
  const alertContext = useContext(AlertContext);

  const [details, setDetails] = useState({
    descr: '',
    event_type: the_event_type,
    eventName: '',
    start_date: '',
    end_date: '',
    event_image: '',
    state: '',
    city: '',
    venueName: '',
    company_association: '',
    company_id:
      user?.companies && user?.companies.length > 0
        ? user?.companies[0]?.company_id
        : '',
  });
  const [file, setFile] = useState();

  const { setAlert } = alertContext;

  const handleSelectEventType = (e) => {
    //send request to backend to update level
    //updateWorkshopLevel(level)
    console.log('e is frm et: ');
    console.log(e);
    setEventType(e);
    // setDetails({ ...details, [event_type]: e });

    // setLevelTitle(e);
    // updatedWorkshop.level = e;
  };

  const {
    descr,
    eventName,
    event_image,
    start_date,
    end_date,
    state,
    city,
    venueName,
    company_association,
    company_id,
    event_type,
  } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    // console.log('cities');
    // console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  const onSubmit2 = (e) => {
    e.preventDefault();
    console.log('details from submission');
    console.log(details);
    const data = new FormData();
    data.append('file', file);
    data.append('descr', descr);
    data.append('event_type', event_type);
    data.append('name', eventName);
    data.append('state', state);
    data.append('city', city);
    data.append('venueName', venueName);
    data.append('start_date', start_date);
    data.append('end_date', end_date);
    data.append('image', event_image);
    data.append('hostId', user._id);
    data.append('hostName', user.name);
    data.append('hostImageUrl', user?.image?.url);
    data.append('hostImageFilename', user?.image?.filename);
    data.append('company_association', company_association);
    data.append('company_id', company_id);
    // const hostImage = {
    //   url: user.image.url,
    //   filename: user.image.filename,
    // };

    // data.append('hostImage', hostImage);
    if (descr === '') {
      setAlert('Please fill in description field', 'danger');
    }
    if (city === '') {
      setAlert('Please enter a city field', 'danger');
    } else {
      // login(authDispatch, {
      //   descr,
      // });
      // request_roommate();
      // createNewEvent(eventName, descr, user._id);
      console.log('data from EWP');
      console.log(data);

      createNewEvent(data);
    }
  };
  if (loading) {
    return <h2>Loading...</h2>;
  }
  // commented out on 3.7.23
  // const pageCount = Math.ceil(events.length / eventsPerPage);

  let pageCount = 1;
  //in the reducer we made eventsInLocation => 0
  if (eventsInLocation.length > 0) {
    pageCount = Math.ceil(eventsInLocation.length / eventsPerPage);
  } else {
    pageCount = Math.ceil(allEventsWithEventType.length / eventsPerPage);
  }

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const splitValue1 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[0];
  };
  const splitValue2 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[1];
  };
  // let event_type_array = ['Dance Event', 'City Festival', 'Homecoming'];

  const userLoggedIn = (
    <Fragment>
      {eventsVisible && (
        <div>
          <button
            onClick={() => setVisible(!visible)}
            class="btn btn-secondary"
          >
            {visible ? 'Cancel' : 'Create Event'}
          </button>
          {/* <Link to="/advertise">
          <button class="btn btn-secondary"> Promote Your Company</button>
        </Link> */}
          {/* <hr /> */}
          {/* <Link to="/sharktank">
          <button class="btn btn-secondary"> Sharktank</button>
        </Link> */}
          {visible && (
            <AddEventPopup show={visible} setShow={setVisible} />

            // <div className="form-container">
            //   <h1 />
            //   <form onSubmit={onSubmit2}>
            //     <div className="form-group">
            //       <h1>Event Details</h1>
            //       <input
            //         id="eventName"
            //         type="text"
            //         name="eventName"
            //         placeholder="Event Name"
            //         value={eventName}
            //         onChange={onChange}
            //         required
            //       />
            //       <input
            //         id="descr"
            //         type="text"
            //         name="descr"
            //         placeholder="Event Description"
            //         value={descr}
            //         onChange={onChange}
            //         required
            //       />
            //       <div className="row">
            //         <div className="col-3">
            //           <h5>Event Type</h5>
            //         </div>
            //         <div className="col">
            //           <EventType
            //             // setEventType={setEventType}
            //             details={details}
            //             setDetails={setDetails}
            //             event_type={the_event_type}
            //             handleSelectEventType={handleSelectEventType}
            //           />
            //         </div>
            //       </div>
            //       {/* venue and city */}
            //       <input
            //         id="venueName"
            //         type="text"
            //         name="venueName"
            //         placeholder="Venue"
            //         value={venueName}
            //         onChange={onChange}
            //         required
            //       />

            //       <Form.Group controlId="formBasicSelect">
            //         <Form.Label>State</Form.Label>
            //         <Form.Control
            //           id="state"
            //           name="state"
            //           as="select"
            //           value={state}
            //           onChange={(e) => {
            //             let cities = selectedCities(e.target.value);
            //           }}
            //         >
            //           {listOfStates.map((state) => (
            //             <option value={state}>{state}</option>
            //           ))}
            //         </Form.Control>
            //       </Form.Group>
            //       <Form.Group controlId="formBasicSelect">
            //         <Form.Label>City</Form.Label>
            //         <Form.Control
            //           id="city"
            //           name="city"
            //           as="select"
            //           value={city}
            //           required
            //           onChange={(e) => {
            //             setDetails({
            //               ...details,
            //               [e.target.name]: e.target.value,
            //             });
            //             console.log(details);
            //           }}
            //         >
            //           {listOfCitiesForSelectedState.map((city) => (
            //             <option value={city}>{city}</option>
            //           ))}
            //         </Form.Control>
            //       </Form.Group>

            //       <label for="start_date">Start Date:</label>

            //       <input
            //         id="start_date"
            //         type="date"
            //         name="start_date"
            //         placeholder="Start Date"
            //         value={start_date}
            //         onChange={onChange}
            //         required
            //       />

            //       <label for="end_date">End Date:</label>

            //       <input
            //         id="end_date"
            //         type="date"
            //         name="end_date"
            //         placeholder="End Date"
            //         value={end_date}
            //         onChange={onChange}
            //         required
            //       />
            //       <input
            //         id="event_image"
            //         name="event_image"
            //         type="file"
            //         value={event_image}
            //         required
            //         onChange={(event) => {
            //           const file = event.target.files[0];
            //           setFile(file);
            //           setDetails({
            //             ...details,
            //             [event.target.name]: event.target.value,
            //           });
            //         }}
            //       />

            //       {user.companies &&
            //         user.companies.length > 0 && (
            //           <div class="form-check">
            //             <input
            //               id="associate_with_company"
            //               type="checkbox"
            //               name="associate_with_company"
            //               value={'associate_with_company'}
            //               onChange={onAssociationChange}
            //             />

            //             <label for="associate_with_company">
            //               {' '}
            //               Would you like to link this event with your company?
            //             </label>

            //             <Form.Group controlId="formBasicSelect">
            //               <Form.Label>Which Company</Form.Label>
            //               <Form.Control
            //                 id="company_association"
            //                 name="company_association"
            //                 as="select"
            //                 value={company_association}
            //                 onChange={(e) => {
            //                   setDetails({
            //                     ...details,
            //                     [e.target.name]: splitValue1(e),
            //                     company_id: splitValue2(e),
            //                   });

            //                   console.log('details..company filled?');
            //                   console.log(details);
            //                 }}
            //               >
            //                 {companiesVisible &&
            //                   user.companies.map((company, index) => (
            //                     <option
            //                       value={
            //                         company.company_name +
            //                         '/' +
            //                         company.company_id
            //                       }
            //                     >
            //                       {company.company_name}
            //                     </option>
            //                   ))}
            //               </Form.Control>
            //             </Form.Group>
            //           </div>
            //         )}
            //     </div>

            //     <input
            //       type="submit"
            //       value="Create Event"
            //       class="btn btn-secondary"
            //     />
            //   </form>
            // </div>
          )}
        </div>
      )}

      {!eventsVisible && (
        <div>
          <label>
            <h5>Activities</h5>
          </label>
        </div>
      )}

      {/* {!eventsVisible && (
        <div>
          <ActivitiesWithPagination />
        </div>
      )} */}
    </Fragment>
  );

  const loginToCreateEvent = (
    <Fragment>
      <Link to="login">
        <Button
          variant="primary"
          style={{
            background: 'white',
            // color: 'blue',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            textAlign: 'center',
            padding: '6px',
          }}
        >
          Login To Create Events
        </Button>
      </Link>
    </Fragment>
  );

  const handleLocationChange = (city, state, type) => {
    getEventsByLocation(city, state, type);
    console.log(
      ' filter changing printed from parent LocationFilterForMainEventPage.js'
    );
    console.log(`type from child ${type}--- ${city} , ${state}`);
    // setDanceType(type);
    return 'all good';
  };

  const getEventsHavingEventType = (eventType) => {
    console.log('event_type from ETB');
    console.log(eventType);
    // getEventsWithEventType(eventType);

    // july 3 2024
    getEventsWithEventType(eventType, 'none', 'none', 'none');
    // getEventsWithEventType('Concerts', 'none', 'none', 'none');
  };

  return (
    <div>
      {/* <DanceReactions /> -- this was apart of the /event/bzwc button route*/}
      {/* <div className="row">
        <div>
          <Link to="/event/brazilian-zouk-world-championships">
            <Button
              style={{
                width: '300px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                height: '40px',
                fontSize: 30,
                borderStyle: 'solid',
                borderColor: 'black',
                paddingBottom: '50px',
              }}
            >
              How's My Zouk ?
            </Button>
          </Link>
        </div>
      </div> */}
      {/* above code is important */}
      {/* <Link to="/invest">
        <a>
          <span className="text-primary">invest in airmeus.com</span>
        </a>
      </Link> */}
      {/* <a className="text-primary" href="">
        Send email to Governor
      </a> */}
      {/* <button onClick={window.open('mailto:test@example.com')}>
        email Governor x about Affirmation Tower
      </button> */}
      {/* <button onClick={window.open('mailto:test@example.com')}>
        email Governor x about Affirmation Tower
        location.href = "mailto:"+emailTo+'?cc='+emailCC+'&subject='+emailSub+'&body='+emailBody;

      </button> */}
      {/* <a
        className="text-primary"
        href="http://www.gilbertbrooks.com/projects/tinyhep.html"
      >
        Invest in airmeus.com
      </a>
      <br />

      <hr /> */}
      {/* <br />
      <div className="row">
        <div className="col">
          <Button>
            Events
          </Button>
        </div>
        <div className="col">
          <Button
            onClick={() => {
              setActivitiesVisible(!activitiesVisible);
              setEventsVisible(!eventsVisible);
            }}
          >
            {activitiesVisible ? 'Show Events' : 'Activities'}
          </Button>
        </div>
      </div> */}
      {/* <br /> */}
      <div className="row">
        <div className="col">
          {!user && !visible ? loginToCreateEvent : userLoggedIn}
        </div>
        <div className="col">
          <Button
            onClick={handleShowEventFilter}
            style={{
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '6px',
            }}
          >
            Find Events
          </Button>
        </div>

        {showFilter ? (
          <EventFilter2
            // studio={studio}
            showModal={showFilter}
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            getEventsWithEventType={getEventsWithEventType}
          />
        ) : (
          <></>
        )}

        {/* <div className="col">
          <Button
            onClick={() => {
              setActivitiesVisible(!activitiesVisible);
              setEventsVisible(!eventsVisible);
            }}
          >
            {activitiesVisible ? 'Show Events' : 'Activities'}
          </Button>
        </div> */}
      </div>
      {/* <div className="col">
        {
          <LocationFilterForMainEventPage
            user={user}
            handleLocationChange={handleLocationChange}
          />
        }
      </div> */}
      {/* <Button>Charlotte NC</Button> */}
      {/* {currentEvents.map((event) => (
        <EventItem key={event.id} loading={loading} event={event} />
      ))} */}
      {/* {eventsAndAdsWithCounter()} */}
      {/* <br /> */}
      <EventTypeHorizontalMenu
        getEventsWithEventType={getEventsHavingEventType}
      />
      {/* instead of having the highlighted_events show at the top, we can just splice them into the eventsAndAdsArray where we 
      do our other splicing and then we can style that highlighted event component to show its highlighted.. we can splice it to be in the front/top of array if we want 
      or we can just keep the below code as we originally did the highlights to showcase the coolest events that were happening and gauge the engagement of users */}
      {/* <div>
        {highlighted_events.length > 0 && <h1>Highlighted Events</h1>}
        {highlighted_events.map((event) => (
          <div>
            <EventItem key={event._id} loading={loading} event={event} />
          </div>
        ))}
      </div> */}
      <h1
        style={
          {
            // background: 'white',
            // // color: 'blue',
            // borderStyle: 'solid',
            // borderColor: 'purple',
          }
        }
      >
        Events Around The World
      </h1>
      {/* Other Events in Your Area */}
      {/* run get event type conert */}
      {/* {allEventsWithEventType ? (
        <div>
          {allEventsWithEventType.map((event) => {
            if (event?.name) {
              return (
                <EventItem key={event._id} loading={loading} event={event} />
              );
            }
          })}
        </div>
      ) : (
        <></>
      )} */}

      {!visible &&
        eventsVisible &&
        eventsAndAds().map((eventOrAd, index) => {
          if (eventOrAd?.name) {
            return (
              <EventItem
                key={eventOrAd._id}
                loading={loading}
                event={eventOrAd}
              />
            );
          }
          if (eventOrAd?.promotionText) {
            return (
              <div className="timelineCard smallScreenCenterAd bigScreenEvents">
                <h5>Ad</h5>
                <div className="">
                  <img
                    className="rounded-circle all-center"
                    src={eventOrAd.adImage?.url}
                    alt=""
                    height="75"
                    width="75"
                  />
                </div>
                <h4>{eventOrAd.promotionText}</h4>
                <Button className="text-center" variant="btn btn-info">
                  <Link
                    to={`/user/profile/${eventOrAd.ownersId}`}
                    className="btn btn-info"
                  >
                    View Profile
                  </Link>
                </Button>
                <hr />
              </div>
            );
          }
          if (eventOrAd === 'render ArtistsWeLove component') {
            return (
              <div>
                <h5 style={{ fontSize: '24px' }}>Artists We ❤️</h5>
                {/* <div className="col grid-container-small-screen grid-container-big-screen"> */}
                {/* <div className="card card-body" style={{ width: '170px' }}> */}
                {artists_we_love.map((artist) => (
                  <ArtistsWeLove artist={artist} />
                ))}
                {/* </div> */}
                <hr />
              </div>
            );
          }

          if (eventOrAd === 'render RandomRankings component') {
            return (
              <div>
                {/* <div className="col grid-container-small-screen grid-container-big-screen"> */}
                {/* <div className="card card-body" style={{ width: '170px' }}> */}
                {/* {artists_we_love.map((artist) => ( */}
                <RandomRankings />
                {/* ))} */}
                {/* </div> */}
                <hr />
              </div>
            );
          }
          if (eventOrAd === 'render RandomStudioRankings component') {
            return (
              <div>
                {/* <div className="col grid-container-small-screen grid-container-big-screen"> */}
                {/* <div className="card card-body" style={{ width: '170px' }}> */}
                {/* {artists_we_love.map((artist) => ( */}
                <RandomStudios />
                {/* ))} */}
                {/* </div> */}
                <hr />
              </div>
            );
          }

          if (eventOrAd === 'render DJsForYourNextEvent component') {
            return (
              <div>
                {/* <div className="col grid-container-small-screen grid-container-big-screen"> */}
                {/* <div className="card card-body" style={{ width: '170px' }}> */}
                {/* {artists_we_love.map((artist) => ( */}
                <DJsForYourNextEvent />
                {/* ))} */}
                {/* </div> */}
                <hr />
              </div>
            );
          } else {
            // return <TopDancerOnMainTimeline />;
            return <></>;
          }
        })}
      {activitiesVisible && !eventsVisible && <ActivitiesWithPagination />}
      {/* {eventsAndAds().map(
        (eventOrAd) =>
          !eventOrAd?.promotionText ? (
            <EventItem
              key={eventOrAd._id}
              loading={loading}
              event={eventOrAd}
            />
          ) : (
            <div>
              <div className="timelineCard smallScreenCenterAd bigScreenEvents">
                <h5>Ad</h5>
                <div className="">
                  <img
                    className="rounded-circle all-center"
                    src={eventOrAd.adImage?.url}
                    alt=""
                    height="75"
                    width="75"
                  />
                </div>
                <h4>{eventOrAd.promotionText}</h4>
                <Button className="text-center" variant="btn btn-info">
                  <Link
                    to={`/user/profile/${eventOrAd.ownersId}`}
                    className="btn btn-info"
                  >
                    View Profile
                  </Link>
                </Button>
              </div>
            </div>
          )
      )} */}
      {!visible &&
        eventsVisible && (
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={'paginationBttns'}
            previousLinkClassName={'previousBttn'}
            nextLinkClassName={'nextBttn'}
            disabledClassName={'paginationDisabled'}
            activeClassName={'paginationActive'}
          />
        )}
      {/* <Pagination
        postsPerPage={postsPerPage}
        totalPosts={events.length}
        paginate={paginate}
      /> */}
      {/* <h1>hi</h1> */}
    </div>
  );
};

Event.propTypes = {};

export default EventWithPagination;
