import React, { Component, useContext, useState } from 'react';
import EventFeedbackCategory from './EventFeedbackCategory';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import { useAuth } from '../../context/auth/AuthState';
const FeedbackForm = (props) => {
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;
  const eventContext = useContext(EventContext);
  const { addEventFeedback, submitted_event_feedback } = eventContext;
  let feedbackCategories = [
    {
      category: 'music',
      categoryScore: '1',
    },
    {
      category: 'workshops',
      categoryScore: '1',
    },
    {
      category: 'schedule',
      categoryScore: '4',
    },
  ];

  const [details, setDetails] = useState({
    event_id: '',
    feedback_giver_id: '',
    feedback_giver_name: '',
    feedback_categories: [],
    general_comment: '',
    how_can_this_event_improve: '',
    what_did_you_love_about_this_event: '',
  });

  const {
    general_comment,
    how_can_this_event_improve,
    what_did_you_love_about_this_event,
  } = details;
  const [
    feedbackCategoriesAndScores,
    setFeedbackCategoriesAndScores,
  ] = useState(props.feedbackCategories);
  const setFieldsFromChildComponent = (score, indexToChange) => {
    console.log('score,indexToChange from child');
    console.log(score, indexToChange);
    const key = 'categoryScore';
    setFeedbackCategoriesAndScores([
      ...feedbackCategoriesAndScores.slice(0, indexToChange),
      {
        // here update data value
        ...feedbackCategoriesAndScores[indexToChange],
        [key]: score,
      },
      ...feedbackCategoriesAndScores.slice(indexToChange + 1),
    ]);
    console.log('feedbackCategoriesAndScores after update ??');
    console.log(feedbackCategoriesAndScores);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let obj = {
      // dancer_reaction_id_to_react_to: dancer._id,
      // reacting_user_id: isAuthenticated ? user._id : '',
      // reacting_user_name: isAuthenticated ? user.name : '',
      // //   reacting_user_name: {user ? user.name:""},
      // reaction_emoji: selectedEmoji,
      // comment: '',

      event_id: props.event._id,
      feedback_giver_id: isAuthenticated ? user._id : '',
      feedback_giver_name: isAuthenticated ? user.name : '',
      feedback_categories: feedbackCategoriesAndScores,
      general_comment: general_comment,
      how_can_this_event_improve: how_can_this_event_improve,
      what_did_you_love_about_this_event: what_did_you_love_about_this_event,
    };

    addEventFeedback(obj);
    props.handleFeedbackSubmitted();
  };
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="row">
            {props.feedbackCategories.map((category, index) => (
              <div style={{ paddingBottom: '10px' }}>
                <EventFeedbackCategory
                  category={category}
                  indexToChange={index}
                  setFieldsFromChildComponent={setFieldsFromChildComponent}
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <br />
          <textarea
            id="general_comment"
            type="textarea"
            name="general_comment"
            rows="4"
            cols="50"
            placeholder="General comments on the event"
            value={general_comment}
            onChange={onChange}
            required
          />
          <br />
          <textarea
            id="how_can_this_event_improve"
            type="textarea"
            name="how_can_this_event_improve"
            rows="4"
            cols="50"
            placeholder="How can this event improve in the future"
            value={how_can_this_event_improve}
            onChange={onChange}
            required
          />
          <br />
          <textarea
            id="what_did_you_love_about_this_event"
            type="textarea"
            name="what_did_you_love_about_this_event"
            rows="4"
            cols="50"
            placeholder="What did you love about this event?"
            value={what_did_you_love_about_this_event}
            onChange={onChange}
            required
          />
          <br />
          {/* </textarea> */}
        </div>
        <input type="submit" value="Add Feedback" class="btn btn-secondary" />
      </form>
    </div>
  );
};
export default FeedbackForm;
