// import React from 'react';
import React, { useContext, useEffect, useState } from 'react';

// import User from '../user/User';
import UserProfile from '../user/UserProfile';
// import { Link } from 'react-router-dom';
// import { useAuth, clearErrors, login } from '../../context/auth/AuthState';

// import { useAuth } from '../../context/auth/AuthState';
import UserContext from '../../context/user/userContext'; // might have to capitalize

// import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const UserProfileContainer = (props) => {
  // const [authState, authDispatch] = useAuth();
  // const { error, isAuthenticated } = authState;
  const userContext = useContext(UserContext);
  const { state } = props;
  // const [authState] = useAuth();

  // const { user } = authState;

  const {
    getUser,
    user,
    getJoinedEventsForUser,
    joined_events,
    trips,
    loading,
    rankings,
    dancerWorldRanking,
  } = userContext;

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    console.log('hello');
    getUser(id);
    getJoinedEventsForUser(id);
    console.log(`joined_events from user: ${id}`);
    if (state?.reloadState) {
      window.location.reload();
    } else {
      console.log('no reloadstate here');
    }
    setIsLoading(false);
    // console.log('id is from userprof');
    // console.log(id);

    //there is an error thrown when we reload page
    //bcuz we are calling useEffect and getting "user" object from authState
    //figure out how to fix this or call the user._id from somewhere else..
    // getJoinedEventsForUser('6248c1337e4d6d57c43991f1'); //-this works without error on reload

    // eslint-disable-next-line
  }, []);

  // console.log('user is');
  // console.log(user);
  // console.log('done');
  console.log('joined_event');
  console.log(joined_events);
  let target_user = user;
  if (isLoading) return <h5>loading</h5>;
  if (loading) return <h5>loading</h5>;
  return (
    <div>
      {/* <User /> */}

      <UserProfile
        // target_user={target_user}
        target_user={user}
        joined_events={joined_events}
        trips={trips}
        dancerWorldRanking={dancerWorldRanking}
        rankings={rankings}
      />

      {/* <h1>{user.name}</h1> */}
      {/* <div>
      {user.map((user) => (
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name={user.name}
            id={user.id}
            value="option1"
            onChange={this.handleChange}
          />
          <img src={imgg} height="100" alt="hello" />

          <label class="form-check-label" for={user.id}>
            {user.name}
          </label>
        </div>
      ))}
    </div> */}
    </div>
  );
};

export default UserProfileContainer;

{
  /* 

  unneeded

  
      {/* <Link to={`/user/${props.user._id}`} state={{ user: user }}>
        Profile
      </Link> */
}
