import React, { Fragment, Component, useState } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize

import { useContext, useEffect } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import { Button } from 'react-bootstrap';
import TopListContext from '../../context/topList/topListContext';

const Dancer = (props) => {
  const [authState] = useAuth();
  const { user } = authState;
  const { dancer, index } = props;

  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;

  const [disabled, setDisabled] = useState(false);
  const [likeCount, setLikeCount] = React.useState(
    dancer.likeCount ? dancer.likeCount : 0
  );

  const handleLike = async (liked_user_id) => {
    if (user) {
      //like user & disable like button
      let obj = {
        likedUserId: liked_user_id,
        likingUserId: user?._id,
        // eventId: roomie_request.event,
        // accepted_user_id: roomie_request.requestor,
      };
      console.log('obj is');
      console.log(obj);
      likeDancer(obj);
      setDisabled(true);
    } else {
      console.log('alert user to log in to like and put login button in box');
    }
  };
  let notLiked = '🤍';
  let liked = '💙';
  const [likedBtn, setLikedBtn] = useState(notLiked);

  const claimed = () => {
    if (dancer?.claimed) {
      console.log(' claimed');
      return `/user/profile/${dancer.claimed_id}`;
    } else {
      console.log('not claimed');
      return `/dancer-profile/${dancer._id}`;
    }
  };

  return (
    <div>
      <h5>
        {/* <i className="fas fa-sign-out-alt" /> */}
        {/* <FontAwesomeIcon icon="fa-regular fa-heart" /> */}

        <hr />
        <div className="row">
          <div className="col-2">{index + 1}</div>
          <div className="col">
            <Link
              to={`${claimed()}`}
              state={{ user: dancer }}
              style={{ color: 'black' }}
            >
              {dancer.first_name} {dancer.last_name}
            </Link>
          </div>

          <div className="col">
            <Button
              onClick={() => {
                handleLike(dancer._id);
                setLikeCount(likeCount + 1);
                setDisabled(true);
                console.log(dancer._id);
              }}
              disabled={disabled || dancer.likes.includes(user?._id)}

              //   disabled={dancer.likes.includes(user?._id)}
            >
              {dancer.likes.includes(user?._id) ? liked : notLiked}{' '}
            </Button>

            {/* {dancer?.likeCount} */}
            {/* {dancer?.likeCount > 0 ? `${likeCount} likes` : ``} */}
            <label style={{ fontSize: '14px' }}>{likeCount}</label>
          </div>
        </div>
      </h5>
    </div>
  );
};

export default Dancer;
