import {
  ARTIST_ERROR,
  GET_ONE_ARTIST,
  GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST,
  GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST_AT_EVENTS,
  SEND_PRIVATE_LESSON_REQUEST,
  DECISION_PRIVATE_LESSON_REQUEST,
  DECISION_PRIVATE_LESSON_REQUEST_AT_EVENT,
  GET_ALL_ARTISTS_FOR_DIRECTORY,
  GET_OTHER_ARTISTS,
  GET_ONE_RANDOM_ARTIST,
  GET_TOP_ARTISTS_LIMIT_FIVE,
  GET_ARTISTS_FOR_SEARCH_CRITERIA,
  CONVERT_TO_PREMIUM_ARTIST,
  GET_RANDOM_ARTISTS_FOR_TIMELINE,
  ADD_NEW_ARTIST,
  GET_ALL_DJS,
  GET_RANDOM_DJS_FOR_TIMELINE,
  GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_NO_EVENT,
  GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_AT_EVENT,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_ONE_ARTIST:
      return {
        ...state,
        artist: action.payload[0],
        rankings: action.payload[1],
        artistWorldRanking: action.payload[2],
        user_events: action?.payload[3],
        djRankings: action?.payload[4],
        djRankDetailsObj: action?.payload[5],
        loading: false,
      };
    case GET_ALL_ARTISTS_FOR_DIRECTORY:
      return {
        ...state,
        artist_directory: action.payload,
        loading: false,
      };
    case GET_ALL_DJS:
      return {
        ...state,
        DJs: action.payload,
        loading: false,
      };
    case GET_RANDOM_DJS_FOR_TIMELINE:
      return {
        ...state,
        randomDJsForTimeline: action.payload,
        loading: false,
      };
    case ADD_NEW_ARTIST:
      return {
        ...state,
        new_artist: action.payload,
        loading: false,
      };
    case GET_OTHER_ARTISTS:
      return {
        ...state,
        other_artists: action.payload,
        loading: false,
      };
    case GET_RANDOM_ARTISTS_FOR_TIMELINE:
      return {
        ...state,
        artists_we_love: action.payload,
        loading: false,
      };
    case CONVERT_TO_PREMIUM_ARTIST:
      return {
        ...state,
        artist: action.payload,
        loading: false,
      };
    case GET_ONE_RANDOM_ARTIST:
      return {
        ...state,
        random_artist: action.payload,
        loading: false,
      };
    case GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST:
      return {
        ...state,
        privateLessonRequests: action.payload,
        loading: false,
      };
    case GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST_AT_EVENTS:
      return {
        ...state,
        privateLessonRequestsAtEvents: action.payload,
        loading: false,
      };

    case GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_AT_EVENT:
      return {
        ...state,
        confirmedPrivateLessonsForArtistInstructorAtEvents: action.payload,
        loading: false,
      };
    case GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_NO_EVENT:
      return {
        ...state,
        confirmedPrivateLessonsForArtistInstructorNoEvent: action.payload,
        loading: false,
      };

    case SEND_PRIVATE_LESSON_REQUEST:
      return {
        ...state,
        privateLessonRequest: action.payload,
        loading: false,
      };
    case GET_TOP_ARTISTS_LIMIT_FIVE:
      return {
        ...state,
        topArtistsLimitFive: action.payload,
        loading: false,
      };
    case GET_ARTISTS_FOR_SEARCH_CRITERIA:
      return {
        ...state,
        artistSearchResults: action.payload,
        loading: false,
      };
    case DECISION_PRIVATE_LESSON_REQUEST:
      return {
        ...state,
        // privateLessonRequests: action.payload,
        loading: false,

        //return the id of the plr that was removed and filter it out
        privateLessonRequests: state.privateLessonRequests.filter(
          (request) => request._id !== action.payload
        ),
      };
    case DECISION_PRIVATE_LESSON_REQUEST_AT_EVENT:
      return {
        ...state,
        // privateLessonRequests: action.payload,
        loading: false,

        //return the id of the plr that was removed and filter it out
        privateLessonRequestsAtEvents: state.privateLessonRequestsAtEvents.filter(
          (request) => request._id !== action.payload
        ),
      };

    case ARTIST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
