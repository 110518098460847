import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import React, { useState } from 'react';
import { useAuth } from '../../../context/auth/AuthState';
import React, { useContext, useEffect, useState } from 'react';
import userContext from '../../../context/user/userContext'; // might have to capitalize
import eventContext from '../../../context/event/eventContext'; // might have to capitalize
import Workshop from './Workshop'; // might have to capitalize
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
const moment = require('moment');
moment().format();

const WorkshopDate = (props) => {
  let str = '2022-09-01';
  let time = '18:00';

  let timeAndDate = moment(str + ' ' + time);
  console.log('timeAndDate');

  console.log(timeAndDate);
  // const workshopDate = moment(props.workshop.date).format('dddd MM-DD-YYYY');
  let start_date = moment(props.event?.start_date).zone(0);
  let end_date = moment(props.event?.end_date).zone(0);
  console.log('start_date ----');
  console.log(start_date);
  let date_array = [];

  let d = Math.abs(moment.duration(start_date.diff(end_date)).asDays());
  console.log('duration');
  console.log(d);

  for (let day = 0; day < d + 1; day++) {
    // start_date.add(day, 'days');
    let s = moment(start_date).add(day, 'days');
    console.log(s);
    date_array.push(moment(s).format('dddd MM-DD-YYYY'));
  }
  console.log('date_array times');
  console.log(date_array);
  console.log('props.workshopDate');
  console.log(props.workshopDate);
  return (
    <div>
      {[DropdownButton].map((DropdownType, idx) => (
        <DropdownType
          as={ButtonGroup}
          key={idx}
          id={`dropdown-button-drop-${idx}`}
          size="sm"
          variant="secondary"
          title={moment(props.workshopDate)
            .zone(0)
            .format('dddd MM-DD-YYYY')}
          onSelect={props.onChangeValue}

          // onClick={() => {
          //   setLevelButtonClicked(!levelButtonClicked);
          // }}
          // onSelect={handleSelectLevel}
        >
          {date_array.map((date) => (
            <Dropdown.Item eventKey={date}>{date}</Dropdown.Item>
          ))}
          {/* <Dropdown.Item eventKey="Beginner">
            // Friday, Aug. 31, 2022 //{' '}
          </Dropdown.Item>
          //{' '}
          <Dropdown.Item eventKey="Intermediate">
            // Friday, Aug. 32, 2022 //{' '}
          </Dropdown.Item>
          //{' '}
          <Dropdown.Item eventKey="Advanced">
            // Friday, Aug. 32, 2022 //{' '}
          </Dropdown.Item> */}
          {/* <Dropdown.Divider />
      <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
        </DropdownType>
      ))}
    </div>
  );
};

export default WorkshopDate;
