// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext';

const Attendee = (props) => {
  const { attendee } = props;
  // console.log('-------------- user is from Attendee');
  // console.log(user);
  const userContext = useContext(UserContext);

  const { passOnUser, likeUser } = userContext;
  let image;
  if (attendee.image) {
    image = attendee.image.url;
  } else {
    image =
      'https://www.weho.org/home/showpublishedimage/5617/635754012603070000';
  }
  return (
    // <div className="attendeeContainer">
    <div className="attendee-card bg-light">
      <div className="bigScreenAttendee smallScreenAttendee">
        <div className="row">
          <div className="col-3">
            <img
              // className="rounded-circle"
              // style={{ width: '80px' }}
              className="rounded-circle"
              src={image}
              alt="avatar"
            />
          </div>
          <div className="col">
            <div className="row">
              <label>{attendee?.name}</label>
            </div>
            <div className="row">
              <label>{attendee?.location}</label>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <label>{attendee?.name}</label> */}
        {/* <br /> */}
        {/* <h3>{attendee.dance_company}</h3> */}
        {/* <label>{attendee?.location}</label> */}
        {/* <h5>dance studio: Rumbao RW Dance</h5> */}
        {props.user?._id === attendee?._id || !props.user ? (
          <></>
        ) : (
          <Button
            style={{
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '0px 12px',
              // width: '120px',
              borderWidth: '1px',
            }}
          >
            Connect
          </Button>
        )}
      </div>
      {/* <div className="bg-dark p">
          <button onClick={onPass}>pass</button>
          <button onClick={onLike}>like</button>
        </div> */}
    </div>
    // </div>
  );
};
export default Attendee;

// const onLike = () => {
//   console.log('user liked and my info below');
//   console.log(user);

//   likeUser(user);
//   //what should happen when you like a user
//   //the user's info should go to backend and the messages>liked users should be updated (may be a premium feature)
//   //delete current first user in the loaded in array of users and replace with the next one up
// };

// const onPass = () => {
//   console.log('passed on user');
//   // passOnUser(user._id);
//   passOnUser(user);
// };
