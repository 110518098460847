import React, { Component, useContext, useEffect } from 'react';
import TriviaContext from '../../../context/trivia/triviaContext';
import { useAuth } from '../../../context/auth/AuthState';
import AllTrivia from './AllTrivia';

const ThisOrThatHome = (props) => {
  // get all trivias
  // -- trivias we make, trivias
  const [authState] = useAuth();

  const { user } = authState;
  let triviaContext = useContext(TriviaContext);

  let { loading, getAllTrivias, allTrivias } = triviaContext;

  useEffect(() => {
    getAllTrivias();
    // eslint-disable-next-line
  }, []);

  console.log('allTrivias');
  console.log(allTrivias);
  return (
    <div>
      ThisOrThatHome list
      {allTrivias.map((trivia) => (
        <div>
          <AllTrivia trivia={trivia} />
        </div>
      ))}
    </div>
  );
};
export default ThisOrThatHome;
