import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
  useRef,
} from 'react';
import StudioContext from '../../context/studio/studioContext';
import TopCompanies from './TopCompanies';
import TopArtists from './TopArtists';
import DynamicSearch from '../layout/DynamicSearch';
import DynamicStudioSearch from '../layout/DynamicStudioSearch';
// import DynamicSearch from '../layout/DynamicSearch';
const LoadCompaniesToAdd = (props) => {
  const [display, setDisplay] = useState(false);

  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };
  // 1.load studios ---- 2.load artists
  const studioContext = useContext(StudioContext);
  const {
    topStudiosLimitFive,
    getTopStudiosWithLimitOfFive,
    getStudiosForSearchCriteria,
    studioSearchResults,
  } = studioContext;

  console.log('topStudiosLimitFive ---');
  console.log(topStudiosLimitFive);
  useEffect(() => {
    getTopStudiosWithLimitOfFive();
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  const [details, setDetails] = useState({
    searchText: '',
  });
  const [dynamicText, setDynamicText] = useState('');
  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log('text is:');
    console.log(e.target.value);
    getStudiosForSearchCriteria(e.target.value);
    setDynamicText(e.target.value);
  };
  const { searchText } = details;

  return (
    <div>
      <div className="row">
        <div className="row">
          <h3>Top Dance Companies 🔥</h3>
        </div>

        {/* <div ref={wrapperRef} className="flex-container flex-column pos-rel"> */}
        {/* <div ref={wrapperRef} className="flex-container flex-column pos-rel"> */}
        {/* <div ref={wrapperRef} className="row"> */}
        <div className="row">
          <div className="col">
            <input
              id="searchText"
              class="form-control mr-sm-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              name="searchText"
              value={searchText}
              onChange={onChange}
              style={{ width: '150px' }}
              onClick={() => setDisplay(!display)}
              autoComplete="off"
            />
          </div>
          {/* {display && (
            <div>
              <DynamicSearch
                userSearchText={dynamicText}
                dynamicSearchResults={studioSearchResults}
              />
            </div>
          )} */}

          <div ref={wrapperRef} className="flex-container flex-column pos-rel">
            {display && (
              // <div className="autoContainer">
              <div>
                {/* <h5>Try searching for</h5> */}
                {/* {options.map((person) => (
                  <div className="option">
                    <h5 style={{ color: 'black' }}>{person}</h5>
                  </div>
                ))} */}
                <DynamicStudioSearch
                  // onChangeValue={handleSearch}
                  userSearchText={dynamicText}
                  dynamicStudioSearch={studioSearchResults}
                  event_id={props.event_id}
                />
              </div>
            )}
          </div>
        </div>

        {/* <div style={{ marginLeft: '3em' }}> */}

        {!display && (
          <div className="row" style={{ marginLeft: '3em' }}>
            {topStudiosLimitFive.map((company) => (
              <TopCompanies
                key={company._id}
                company={company}
                event_id={props.event_id}
              />
            ))}
          </div>
        )}

        <hr />
      </div>
      {/* <div>
        <h3>Top Artists 🔥</h3>
        <div style={{ marginLeft: '3em' }}>
          {[1, 2, 3, 4, 5].map((company) => (
            <TopArtists key={company._id} company={company} />
          ))}
        </div>
        <hr />
      </div> */}
    </div>
  );
};

export default LoadCompaniesToAdd;
