import React, { useReducer } from 'react';
import axios from 'axios';
import userContext from './userContext'; //be sure the case is not case sensitive
import userReducer from './userReducer';
// import GET_USERS_ONE_AT_A_TIME from '../types';
import {
  GET_USERS_ONE_AT_A_TIME,
  GET_CURRENT_USER,
  USER_ERROR,
  LIKE_USER,
  PASS_ON_USER,
  GET_USER,
  EDIT_USER_PROFILE,
  GET_JOINED_EVENTS,
  ACCEPT_ROOMMATES_FOR_JOINED_EVENTS,
  GET_EVENTS_AND_ROOMMATES_FOR_USER,
  SEND_ROOMMATE_REQUEST_ACCEPTED_SMS,
  GET_USERS_FOR_SEARCH_CRITERIA,
  ACCEPT_CONNECTION_REQUEST,
  CONNECT_TO_USER,
  GET_CURRENT_USER_CONNECTIONS,
  GET_CONNECTIONS_FOR_USER,
  ADD_USERS_AS_ROOMMATES,
  CREATE_NEW_TRIP_AND_ADD_ROOMMATES,
  ADD_ROOM_NUMBER,
  EDIT_USER_PROFILE_IMAGE,
  GET_HOST,
  GET_USERS_FROM_CITY,
  LIKE_COMPANY,
  GET_SHARKTANK_PARTICIPANTS,
  JOIN_SHARKTANK,
  UNJOIN_EVENT,
  SEND_VERIFICATION_AND_CLAIM_ARTIST,
  GET_USER_CLAIMS,
  DECISION_CLAIM,
  GET_USER_REFERRALS,
  SEND_VERIFICATION_AND_CLAIM_STUDIO,
  GET_ONE_USER,
  SAVE_STYLE,
  GET_MY_PRIVATE_LESSONS,
  GET_USER_EVENTS,
  ADD_TO_SAFETY_COUNCIL,
  SEND_VERIFICATION_FOR_EVENT_OWNERSHIP,
  ADD_BUSINESS_DETAILS,
  GET_ONE_SHARKTANK_PARTICIPANT,
} from '../types';

const UserState = (props) => {
  const initialState = {
    users: [],
    userReferrals: [],
    ambassadorRevenueShareAmount: 0,
    current_user: {},
    newConnection: {},
    connections: [],
    user_connections: [],
    user_verification: {},
    user_claims: [],

    // filtered: null,
    error: null,
    user: {},
    one_user: {},
    joined_events: [],
    users_roommates_for_events: [],
    userSearchResults: [],
    users_added_to_roommates: [],
    room_number_added: '',
    trip_owner_id: '',
    trips: [],
    new_trip: {},
    loading: true,
    loading2: true,
    updated_profile_image: '',
    host: {},
    usersFromCity: [],
    liked_company: {},
    sharktank_partipants: [],
    one_sharktank_participant: {},
    joined_sharktank: Boolean,
    sharktank_loading: true,
    dancerWorldRanking: {},
    rankings: {},
    myPrivateLessons: [],
    user_events2: [],
  };
  const [state, dispatch] = useReducer(userReducer, initialState);

  const getUserEvents = async (user_id) => {
    try {
      const res = await axios.get(`/api/users/user/${user_id}/events`);
      console.log('res below from userstate.js');
      console.log(res);

      dispatch({
        type: GET_USER_EVENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  // Get current user and save into state
  const getCurrentUser = async () => {
    try {
      const res = await axios.get('/api/users/user');
      console.log('res below from userstate.js');
      console.log(res);

      dispatch({
        type: GET_CURRENT_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  // Get Users
  const getUsersOneAtATime = async () => {
    try {
      const res = await axios.get('/api/users');

      dispatch({
        type: GET_USERS_ONE_AT_A_TIME,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  // like user
  const likeUser = async (user) => {
    try {
      //this api will place
      //pass user.id to backend
      const res = await axios.post('/api/users/like', user); //api/like/:user_id
      console.log('res below');
      console.log(res);

      dispatch({
        type: LIKE_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const likeCompany = async (obj) => {
    try {
      //this api will place
      //pass user.id to backend
      const res = await axios.post('/api/users/likeCompany', obj); //api/like/:user_id
      console.log('res below');
      console.log(res);

      dispatch({
        type: LIKE_COMPANY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const getAllSharktankParticipants = async () => {
    try {
      const res = await axios.get(`/api/users/getAllSharktankParticipants`);
      console.log('response is:');
      console.log(res.data);
      dispatch({
        type: GET_SHARKTANK_PARTICIPANTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };
  const getOneSharkTankBusiness = async () => {
    try {
      const res = await axios.get(`/api/users/getOneSharkTankBusiness`);
      console.log('response from getOneShartTankBusiness is ---- :');
      console.log(res.data);
      dispatch({
        type: GET_ONE_SHARKTANK_PARTICIPANT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const joinSharktank = async (user_id) => {
    try {
      const res = await axios.post(`/api/users/joinSharktank/${user_id}`);
      console.log('response is:');
      console.log(res.data);
      dispatch({
        type: JOIN_SHARKTANK,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  // Get Users
  const passOnUser = async (user) => {
    try {
      //this api call will place the passed user in the user.passed_users array in the db
      const res = await axios.post('/api/users/pass', user); //api/pass/:user_id
      console.log('passuser -- res below');
      console.log(res);
      dispatch({
        type: PASS_ON_USER,
        payload: res.data, //the responsewas
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  //get user
  const getUser = async (user_id) => {
    try {
      console.log('trying to send id to backend from retrieving user');
      console.log(`id is: ${user_id}`);
      const res = await axios.get(`/api/users/user/${user_id}`);
      console.log('response is:');
      console.log(res.data);
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };
  // const getSimpleUserObject = async (user_id) => {
  //   try {
  //     console.log('trying to send id to backend from retrieving user');
  //     console.log(`id is: ${user_id}`);
  //     const res = await axios.get(`/api/users/simple/user/${user_id}`);
  //     console.log('response is:');
  //     console.log(res.data);
  //     dispatch({
  //       type: GET_USER,
  //       payload: res.data,
  //     });
  //   } catch (err) {
  //     dispatch({
  //       type: USER_ERROR,
  //       payload: err,
  //     });
  //   }
  // };
  const getOneUser = async (user_id) => {
    try {
      console.log('trying to send id to backend from retrieving user');
      console.log(`id is: ${user_id}`);
      const res = await axios.get(`/api/users/user/${user_id}`);
      console.log('response is:');
      console.log(res.data);
      dispatch({
        type: GET_ONE_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };
  //this returns a lot of data just to display the host's name.. consider changing
  //we could just add the host name along with the hostid (already there) to the event
  //we may also need the hostImage
  const getHost = async (user_id) => {
    try {
      console.log('trying to send id to backend from retrieving host');
      console.log(`id is: ${user_id}`);
      const res = await axios.get(`/api/users/user/${user_id}`);
      console.log('response is:');
      console.log(res.data);
      dispatch({
        type: GET_HOST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  // edit profile
  //we may need to send the user id instead of the entire user object,
  //bcuz we will load user from db in backend and send the updated user back
  //to our frontend and reload the page with updated info (which means another user req?)
  // ^^^ i think we already have the user info being loaded so we dont need any more queries

  //^^what about the updated info being sent to backend?
  //we need to send the entire user bcuz this is the new info
  //thus we can just go ahead and save/overwrite? that user

  const editProfile = async (user) => {
    try {
      console.log('trying to updateProfile');
      //this api call will place the passed user in the user.passed_users array in the db
      const res = await axios.post('/api/users/user/edit-profile', user); //api/pass/:user_id
      console.log('passuser from editProfile in userState -- res below');
      console.log(res);
      dispatch({
        type: EDIT_USER_PROFILE,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const editProfileImage = async (user) => {
    try {
      //this api call will place the passed user in the user.passed_users array in the db
      const res = await axios.post('/api/users/user/edit-profile-image', user); //api/pass/:user_id
      console.log('passing user from editProfile in userState -- res below');
      console.log(res);
      dispatch({
        type: EDIT_USER_PROFILE_IMAGE,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  // send eventid to backend or can we load this info in when the user selects the event???
  const getJoinedEventsForUser = async (user_id) => {
    try {
      console.log(
        'trying to get res.data from getJoinedEventsForUser in userstate.js'
      );

      // const res = await axios.get(`/api/users/user/${user_id}/joined-events`);
      const res = await axios.get(`/api/trips/eventsAndTrips/${user_id}`);
      console.log('res.data from getJoinedEventsForUser ');
      console.log(res.data);
      dispatch({
        type: GET_JOINED_EVENTS,
        payload: res.data,
      });
    } catch (err) {
      console.log('getJoinedEventsForUser has an error');
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const unjoinEvent = async (user_id, event_id) => {
    try {
      const res = await axios.delete(
        `/api/events/joined-event/${user_id}/${event_id}`
      );

      dispatch({
        type: UNJOIN_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response,
      });
    }
  };

  // send eventid to backend or can we load this info in when the user selects the event???
  const acceptRoommatesRequest = async (obj) => {
    try {
      console.log('trying accept-roommate-request():');
      // const res = await axios.post(`/api/events/accept-roommate-request`, obj);
      const res = await axios.post(
        `/api/trips/accept-roommate-request-updated`,
        obj
      );

      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data');
      console.log(res.data);

      dispatch({
        type: ACCEPT_ROOMMATES_FOR_JOINED_EVENTS,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const sendSMSAlertForAcceptedRoommate = async (obj) => {
    try {
      console.log('trying SEND NOTIFICATION accepted-roommate-request:');
      //CAN WE SEND SMS NOTIF
      const res = await axios.get(
        `/api/smsNotifications/accepted-roommate-request`,
        obj
      );
      console.log('res.data from sms notif');
      console.log(res.data);

      dispatch({
        type: SEND_ROOMMATE_REQUEST_ACCEPTED_SMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  // send eventid to backend or can we load this info in when the user selects the event???

  //we cant pass data with get request, we can pass parameters..
  // const getEventsAndRoommatesForUser = async (obj) => {
  const getEventsAndRoommatesForUser = async (user_id, event_id) => {
    try {
      console.log('trying get users events');
      // let event_id = '624c8b4f236daaa095ba62e7';
      const res = await axios.get(
        `/api/events/all-my-events/${user_id}/${event_id}`
      );
      // const res = await axios.get('/api/events/all-my-events', obj);
      console.log('res.data');
      console.log(res.data);

      dispatch({
        type: GET_EVENTS_AND_ROOMMATES_FOR_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const getEventsAndRoommatesForUsersTrip = async (trip_id) => {
    try {
      console.log(
        'trying get users events from getEventsAndRoommatesForUsersTrip'
      );
      // let event_id = '624c8b4f236daaa095ba62e7';
      const res = await axios.get(
        `/api/trips/eventsAndTripsDetails/${trip_id}`
      );
      // const res = await axios.get('/api/events/all-my-events', obj);
      console.log('res.data');
      console.log(res.data);

      dispatch({
        type: GET_EVENTS_AND_ROOMMATES_FOR_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const getUsersForSearchCriteria = async (searchParams) => {
    try {
      console.log('custom search from frontend');
      // let event_id = '624c8b4f236daaa095ba62e7';
      const res = await axios.get(`/api/users/search/${searchParams}`);
      // const res = await axios.get('/api/events/all-my-events', obj);
      console.log('res.data');
      console.log(res.data);

      dispatch({
        type: GET_USERS_FOR_SEARCH_CRITERIA,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  //FINISH IMPLEMENTING LATER -- PRIORITIZE CONNECTING WITHOUT REQUESTS RIGHT NOW ----------------------------
  const acceptConnectionRequest = async (obj) => {
    try {
      console.log('trying accept-connection-request:');
      const res = await axios.post(
        `/api/events/accept-connection-request`,
        obj
      );
      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data');
      console.log(res.data);

      dispatch({
        type: ACCEPT_CONNECTION_REQUEST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const connectToUser = async (obj) => {
    try {
      console.log('trying to connect to user:');
      const res = await axios.post(`/api/users/connect-to-user`, obj);
      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data');
      console.log(res.data);

      dispatch({
        type: CONNECT_TO_USER,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const getConnections = async (user_id) => {
    try {
      //getConnectionsForUserWithId(u123)

      console.log('trying to get connections:');
      const res = await axios.get(
        `/api/users/getCurrentUsersConnections/${user_id}`
      );
      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data from getConnections');
      console.log(res.data);

      dispatch({
        type: GET_CURRENT_USER_CONNECTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const getConnectionsForUserWithId = async (user_id) => {
    try {
      //getConnectionsForUserWithId(u123)

      console.log('trying to get connections:');
      const res = await axios.get(`/api/users/getConnections/${user_id}`);
      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data --- connection list ');
      console.log(res.data);

      dispatch({
        type: GET_CONNECTIONS_FOR_USER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const addUsersAsRoommates = async (obj) => {
    try {
      //getConnectionsForUserWithId(u123)

      console.log('trying to addUsersAsRoommates() :');
      // const res = await axios.post(`/api/users/addUsersAsRoommates`, obj);

      // const res = await axios.post(`/api/trips/addRoommatesToTrip`, obj);
      const res = await axios.post(
        `/api/events/addMultipleUsersAsRoommates`,
        obj
      );
      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data --- connection list ');
      console.log(res.data);

      dispatch({
        type: ADD_USERS_AS_ROOMMATES,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const createTripAndAddRoommates = async (obj) => {
    try {
      //getConnectionsForUserWithId(u123)

      console.log('trying to createTripAndAddRoommates() :');
      // const res = await axios.post(`/api/users/addUsersAsRoommates`, obj);

      // const res = await axios.post(`/api/trips/addRoommatesToTrip`, obj);
      const res = await axios.post(`/api/trips/createTripAndAddRoommates`, obj);
      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data should be the new_trip ');
      console.log(res.data);
      console.log('done---------');

      dispatch({
        type: ADD_USERS_AS_ROOMMATES,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const addRoommatesToTrip = async (obj) => {
    try {
      //getConnectionsForUserWithId(u123)

      console.log('trying to addUsersAsRoommates() :');
      // const res = await axios.post(`/api/users/addUsersAsRoommates`, obj);

      const res = await axios.post(`/api/trips/addRoommatesToTrip`, obj);
      // const res = await axios.post(
      //   `/api/events/addMultipleUsersAsRoommates`,
      //   obj
      // );
      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data --- connection list ');
      console.log(res.data);

      dispatch({
        type: ADD_USERS_AS_ROOMMATES,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const addRoomNumber = async (obj) => {
    try {
      //getConnectionsForUserWithId(u123)

      console.log('trying to addRoomNumber() :');
      const res = await axios.post(`/api/events/my-events/addRoomNumber`, obj);
      //CAN WE SEND SMS NOTIF
      // const res2 = await axios.get(
      //   `/api/sms-notifications/accepted-roommate-request`,
      //   obj
      // );
      console.log('res.data --- connection list ');
      console.log(res.data);

      dispatch({
        type: ADD_ROOM_NUMBER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const getUsersFromCity = async (city) => {
    try {
      console.log('city is');
      console.log(city);
      const res = await axios.get(`/api/users/in/${city}`);
      // const res = await axios.get(`/api/users/in/Charlotte, NC`);
      // console.log('res.data');
      // console.log(res.data);

      dispatch({
        type: GET_USERS_FROM_CITY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // const connectToUser = async (userIdToConnectTo) => {
  //   try {
  //     console.log('custom search from frontend');
  //     // let event_id = '624c8b4f236daaa095ba62e7';
  //     const res = await axios.get(`/api/users/search/${searchParams}`);
  //     // const res = await axios.get('/api/events/all-my-events', obj);
  //     console.log('res.data');
  //     console.log(res.data);

  //     dispatch({
  //       type: GET_USERS_FOR_SEARCH_CRITERIA,
  //       payload: res.data,
  //     });
  //   } catch (err) {
  //     dispatch({
  //       type: USER_ERROR,
  //       payload: err.response.msg,
  //     });
  //   }
  // };

  const sendUserVerificationAndClaimArtist = async (verificationDetails) => {
    try {
      console.log('trying to sendUserVerificationAndClaimArtist');
      const res = await axios.post(
        '/api/user-claims/new-claim',
        verificationDetails
      ); //api/pass/:user_id
      console.log('sendUserVerificationAndClaimArtist -- res below');
      console.log(res);
      dispatch({
        type: SEND_VERIFICATION_AND_CLAIM_ARTIST,
        payload: res.data, //the response was
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };
  const sendUserVerificationAndClaimStudio = async (verificationDetails) => {
    try {
      console.log('trying to sendUserVerificationAndClaimStudio');
      const res = await axios.post(
        '/api/user-claims/new-studio-claim',
        verificationDetails
      ); //api/pass/:user_id
      console.log('sendUserVerificationAndClaimStudio -- res below');
      console.log(res);
      dispatch({
        type: SEND_VERIFICATION_AND_CLAIM_STUDIO,
        payload: res.data, //the response was
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };
  const sendUserVerificationForEventOwnership = async (verificationDetails) => {
    try {
      console.log('trying to sendUserVerificationForEventOwnership');
      const res = await axios.post(
        '/api/user-claims/new-event-claim',
        verificationDetails
      ); //api/pass/:user_id
      console.log('sendUserVerificationForEventOwnership -- res below');
      console.log(res);
      dispatch({
        type: SEND_VERIFICATION_FOR_EVENT_OWNERSHIP,
        payload: res.data, //the response was
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };
  const getUserClaims = async (verificationDetails) => {
    try {
      console.log('trying to getUserClaims');
      const res = await axios.get(
        '/api/user-claims/admin/claims',
        verificationDetails
      ); //api/pass/:user_id
      console.log('getClaimRequests -- res below');
      console.log(res);
      dispatch({
        type: GET_USER_CLAIMS,
        payload: res.data, //the response was
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const decisionClaim = async (decisionObject) => {
    try {
      console.log('trying to decisionClaim');
      const res = await axios.post(
        '/api/user-claims/admin/decision',
        decisionObject
      );
      console.log('decisionClaim -- res below');
      console.log(res);
      dispatch({
        type: DECISION_CLAIM,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const getUserReferrals = async (user_id) => {
    try {
      console.log('trying to getUserReferrals');
      const res = await axios.get(`/api/users/referred-users/${user_id}`); //api/pass/:user_id
      console.log('getUserReferrals -- res below');
      console.log(res);
      dispatch({
        type: GET_USER_REFERRALS,
        payload: res.data, //the response was
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const getMyStudentPrivateLessons = async (user_id) => {
    try {
      console.log('trying to getMyStudentPrivateLessons');
      const res = await axios.get(`/api/users/my-private-lessons/${user_id}`); //api/pass/:user_id
      console.log('getMyStudentPrivateLessons -- res below');
      console.log(res);
      dispatch({
        type: GET_MY_PRIVATE_LESSONS,
        payload: res.data, //the response was
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  const saveStyle = async (obj) => {
    try {
      console.log('trying to decisionClaim');
      const res = await axios.post('/api/users/saveStyle', obj);
      console.log('decisionClaim -- res below');
      console.log(res);
      dispatch({
        type: SAVE_STYLE,
        payload: res.data, //the response was
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };
  const addToSafetyCouncil = async (user_id) => {
    try {
      console.log('trying to addToSafetyCouncil');
      const res = await axios.put(`/api/users/addToSafetyCouncil/${user_id}`);
      console.log('addToSafetyCouncil -- res below');
      console.log(res);
      dispatch({
        type: ADD_TO_SAFETY_COUNCIL,
        payload: res.data, //the response was
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };
  const addBusinessDetails = async (user_id) => {
    try {
      console.log('trying to addBusinessDetails');
      const res = await axios.put(`/api/users/addBusinessDetails/${user_id}`);
      console.log('addBusinessDetails -- res below');
      console.log(res);
      dispatch({
        type: ADD_BUSINESS_DETAILS,
        payload: res.data, //the response was
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err,
      });
    }
  };

  return (
    <userContext.Provider
      value={{
        // props -- where our state and actions will go
        users: state.users,
        user: state.user,
        host: state.host,
        current_user: state.current_user,
        userSearchResults: state.userSearchResults,
        newConnection: state.newConnection,
        joined_events: state.joined_events,
        users_roommates_for_events: state.users_roommates_for_events,
        connections: state.connections,
        user_connections: state.user_connections,
        users_added_to_roommates: state.users_added_to_roommates,
        room_number_added: state.room_number_added,
        trip_owner_id: state.trip_owner_id,
        trips: state.trips,
        new_trip: state.new_trip,
        usersFromCity: state.usersFromCity,
        updated_profile_image: state.updated_profile_image,
        liked_company: state.liked_company,
        sharktank_partipants: state.sharktank_partipants,
        one_sharktank_participant: state.one_sharktank_participant,
        joined_sharktank: state.joined_sharktank,
        sharktank_loading: state.sharktank_loading,
        user_verification: state.user_verification,
        user_claims: state.user_claims,
        userReferrals: state.userReferrals,
        ambassadorRevenueShareAmount: state.ambassadorRevenueShareAmount,
        one_user: state.one_user,
        rankings: state.rankings,
        dancerWorldRanking: state.dancerWorldRanking,
        myPrivateLessons: state.myPrivateLessons,
        user_events2: state.user_events2,

        getUsersOneAtATime,
        getCurrentUser,
        likeUser,
        passOnUser,
        getUser,
        editProfile,
        getJoinedEventsForUser,
        acceptRoommatesRequest,
        sendSMSAlertForAcceptedRoommate,
        getEventsAndRoommatesForUser,
        getUsersForSearchCriteria,
        acceptConnectionRequest,
        connectToUser,
        getConnections,
        getConnectionsForUserWithId,
        addUsersAsRoommates,
        addRoomNumber,
        getEventsAndRoommatesForUsersTrip,
        addRoommatesToTrip,
        createTripAndAddRoommates,
        editProfileImage,
        getHost,
        getUsersFromCity,
        likeCompany,
        getAllSharktankParticipants,
        joinSharktank,
        unjoinEvent,
        sendUserVerificationAndClaimArtist,
        sendUserVerificationAndClaimStudio,
        getUserClaims,
        decisionClaim,
        getUserReferrals,
        getOneUser,
        saveStyle,
        getMyStudentPrivateLessons,
        getUserEvents,
        addToSafetyCouncil,
        sendUserVerificationForEventOwnership,
        addBusinessDetails,
        getOneSharkTankBusiness,
      }}
    >
      {props.children}
    </userContext.Provider>
  );
};

export default UserState;
