import React, { Component, useContext, useEffect, useState } from 'react';
import CurriculumContext from '../../context/curriculum/curriculumContext';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CurriculumPopup from './CurriculumPopup';
import BuildCurriculumPopup from './BuildCurriculumPopup';
import BuildCurriculumPopup2 from './BuildCurriculumPopup2';
import BuildCurriculumPopup3 from './BuildCurriculumPopup3';

const CurriculumMarketplace = (props) => {
  const curriculumContext = useContext(CurriculumContext);
  const { curriculum, getCurriculum, addNewCurriculum } = curriculumContext;
  const [curriculumClicked, setCurriculumClicked] = useState(false);
  const [indexChosen, setIndexChosen] = useState(0);
  // let newCurriculum = [];
  useEffect(() => {
    getCurriculum('all');
    console.log('curriculum ------------------------------');
    console.log(curriculum);

    // newCurriculum = [].concat(
    //   curriculum.slice(0, 4),
    //   'inject upsell message/popup',
    //   curriculum.slice(4, curriculum.length)
    // );
    // eslint-disable-next-line
  }, []);
  const [buildCurriculumClicked, setBuildCurriculumClicked] = useState(false);
  return (
    <div>
      <Button
        style={{
          fontSize: '16px',
          width: 'max-content',
          backgroundColor: 'white',

          background: 'white',
          color: 'rgb(1, 126, 252)',
          borderStyle: 'solid',
          // borderWidth: '1px',

          padding: '6px',
          marginTop: '10px',
          border: '1px solid rgb(1, 126, 252)',
        }}
        onClick={() => setBuildCurriculumClicked(!buildCurriculumClicked)}
      >
        Build Your Own Curriculum
      </Button>
      {buildCurriculumClicked && (
        // <BuildCurriculumPopup
        <BuildCurriculumPopup3
          addNewCurriculum={addNewCurriculum}
          // curriculum={curriculum[indexChosen]}
          buildCurriculumClicked={buildCurriculumClicked}
        />
      )}
      <div className="col grid-container-small-screen grid-container-big-screen">
        {curriculum?.map((curriculum, index) => (
          // <Link to="/">
          <div
            className="card card-body"
            style={{
              width: '170px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '1px',
              borderRadius: '10px',
            }}
            onClick={() => {
              setCurriculumClicked(!curriculumClicked);
              setIndexChosen(index);
            }}
          >
            {/* {index === 4 && (
            <div>
              {' '}
              <Button style={{ fontSize: '24px' }}>
                Build Your Own Curriculum injection msg
              </Button>
            </div>
          )} */}
            <Button
              // onClick={() => {
              //   setCurriculumClicked(!curriculumClicked);
              //   setIndexChosen(index);
              // }}
              style={{
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                // width: 'max-content',
                maxWidth: '130px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'wrap',
                padding: '0px',
                margin: '0px',
                alignContent: 'left',

                // borderStyle: 'solid',
                // borderColor: 'rgb(1, 126, 252)',
                // borderWidth: '1px',
                // borderRadius: '10px',
              }}
            >
              <h5
                style={{
                  fontSize: '16px',
                  alignContent: 'left',

                  // textOverflow: 'ellipsis',
                  // whiteSpace: 'nowrap',
                }}
              >
                {curriculum.curriculum_name}
              </h5>
            </Button>

            {/* <h5></h5> */}

            <hr />

            {curriculum.moves.map((move, index) => (
              <div>
                <h5 style={{}}>
                  {index < 3 && (
                    <h5
                      style={{
                        fontSize: '16px',
                        maxWidth: '130px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {move}
                    </h5>
                  )}
                </h5>
              </div>
            ))}

            <div style={{ paddingBottom: '0px' }}>
              <Link to={`/user/profile/${curriculum.creator_id}`}>
                <h5
                  style={{
                    color: 'black',
                    fontSize: '10px',
                    marginBottom: '2px',
                  }}
                >
                  made with ❤️ by{' '}
                  <a href="/" style={{ color: 'rgb(1, 126, 252)' }}>
                    {/* <a style={{ color: 'rgb(1, 126, 252)' }}> */}
                    {curriculum.creator_name}{' '}
                  </a>
                </h5>
              </Link>
            </div>
          </div>
          // </Link>
        ))}
        {curriculumClicked && (
          <CurriculumPopup
            curriculum={curriculum[indexChosen]}
            curriculumClicked={curriculumClicked}
          />
        )}
      </div>{' '}
    </div>
  );
};

export default CurriculumMarketplace;
