import React, { Component, useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ThisOrThat from './ThisOrThat';
import { useAuth } from '../../context/auth/AuthState';
import TriviaContext from '../../context/trivia/triviaContext';
import CompletedTriviaResponses from './CompletedTriviaResponses';
import GeneratedConnections from './GeneratedConnections';

const Trivia = (props) => {
  let { eventTrivia, event_id, event } = props;
  if (eventTrivia) {
    console.log('eventTrivia');
    console.log(eventTrivia);
    console.log('event yoski');
    console.log(event);
  }
  const [authState] = useAuth();
  const { user } = authState;

  const triviaContext = useContext(TriviaContext);
  const {
    getAllTrivias,
    allTrivias,
    getTriviaForThisEvent,
    triviaForThisEvent,
  } = triviaContext;

  useEffect(() => {
    if (eventTrivia) {
      getTriviaForThisEvent(eventTrivia.trivia_id);
      console.log('trying logic');
      userHasAlreadyDoneTrivia(user, eventTrivia);
    }
    // eslint-disable-next-line
  }, []);
  console.log('triviaForThisEvent yo');
  console.log(triviaForThisEvent);

  const [details, setDetails] = useState({});
  console.log('allTrivias are');
  console.log(allTrivias);
  //functions

  //getTriviaQuestions(based on event type and VIP )
  //-- a VIP might be concert artist, olympians/sports persons
  //even big named dancers

  const generatePotentialConnections = () => {
    console.log('generating ..');
  };

  let navigate = useNavigate();

  const [editingTrivia, setEditingTrivia] = useState(false);
  const [triviaQuestion, setTriviaQuestion] = useState('');
  const handleTriviaChange = (trivia) => {
    console.log('trivia is');
    console.log(trivia);
    setTriviaQuestion(trivia);
    setDetails({ ...details, trivia: trivia });
  };

  const [usersAnswers, setUsersAnswers] = useState([]);

  let addToUsersAnswers = (answer) => {
    console.log('answer');
    console.log(answer);
    setUsersAnswers([...usersAnswers, answer]);
  };

  const userHasAlreadyDoneTrivia = (user, eventTrivia) => {
    console.log('eventTrivia from subr');
    console.log(eventTrivia);
    let eventTriviaId = eventTrivia?.trivia_id;
    let usersTriviasPlayed = user?.trivias_played;

    if (usersTriviasPlayed && usersTriviasPlayed.length > 0) {
      for (
        let userTriviaIndex = 0;
        userTriviaIndex < usersTriviasPlayed.length;
        userTriviaIndex++
      ) {
        console.log('usersTriviasPlayed[userTriviaIndex].trivia_id.toString()');
        console.log(usersTriviasPlayed[userTriviaIndex].trivia_id.toString());
        console.log('eventTriviaId');
        console.log(eventTriviaId);

        if (
          usersTriviasPlayed[userTriviaIndex].trivia_id.toString() ===
          eventTriviaId
        ) {
          console.log(' true -- userHasAlreadyDoneTrivia');
          return true;
        }
      }
    }
    console.log(' false -- userHasAlreadyDoneTrivia');
    return false;
  };

  return (
    <div>
      {user?.email === 'airmeus_admin@gmail.com' ||
      user?.email === 'airmeus.team@gmail.com' ? (
        <div className="row">
          <div className="col">
            <Button
              onClick={() => {
                getAllTrivias();
                setEditingTrivia(true);
              }}
              style={{ backgroundColor: 'green' }}
            >
              Edit/Add Trivia
            </Button>
          </div>

          <div className="col">
            <Button onClick={() => setEditingTrivia(false)}>close</Button>
          </div>

          <hr />
          <br />
        </div>
      ) : (
        <></>
      )}

      {editingTrivia ? (
        // allTrivias?.map((trivia) => (
        //   <div>
        //     <div className="row">
        //       <div className="col">{trivia.trivia_name}</div>

        <div className="col">
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="sm"
              variant="secondary"
              title={triviaQuestion}
              onSelect={handleTriviaChange}
            >
              {allTrivias.map((trivia) => (
                <Dropdown.Item eventKey={trivia}>
                  {trivia.trivia_name}
                </Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>
      ) : (
        //   </div>

        // </div>
        <></>
      )}

      {/* see if user has already done this trivia, if so display answers and generated connections
        allow the user to generate more users to connect with and nudge to 
        start an activity
        -share trivia with your friends button/link  (we suggest IG - tag us (: )
      */}

      {userHasAlreadyDoneTrivia(user, eventTrivia) ? (
        <div>
          {/* <h5>trivia already done - see your responses : </h5> */}

          <CompletedTriviaResponses user={user} eventTrivia={eventTrivia} />

          {/* <SeeOtherUsersTriviaResponses /> */}

          <div>
            <GeneratedConnections
              trivia_id_from_event_page={eventTrivia.trivia_id}
              user={user}
              event={event}
            />
          </div>
        </div>
      ) : (
        <div>
          <h5>Complete The Trivia and Make Friends</h5>

          {eventTrivia && triviaForThisEvent ? (
            <div>
              {triviaForThisEvent?.questions?.map((question) => (
                <div>
                  <ThisOrThat
                    question={question}
                    usersAnswers={usersAnswers}
                    // setUsersAnswers={setUsersAnswers}
                    addToUsersAnswers={addToUsersAnswers}
                  />
                  <br />

                  {/* moved submit button here for conditional rendering  */}
                </div>
              ))}
              <Button
                onClick={() => {
                  // check if user is registered.

                  if (user) {
                    console.log('user is registered');
                    console.log('save response and generate connections');

                    navigate('/register', {
                      state: {
                        trivia_answers: {
                          trivia_id: eventTrivia.trivia_id,
                          usersAnswers,
                        },
                        event_id_from_trivia: event_id,
                        event: event,
                      },
                    });
                  } else {
                    console.log('user is not registered');
                    // register popup
                    navigate('/register', {
                      state: {
                        trivia_answers: {
                          trivia_id: eventTrivia.trivia_id,
                          usersAnswers,
                        },
                        event_id_from_trivia: event_id,
                        event: event,
                      },
                    });
                  }

                  generatePotentialConnections();
                  console.log(usersAnswers);
                  // navigate('/generated-potential-connections', {
                  //   state: {
                  //     messageText:
                  //       'We have received your request and a member from our team will follow up shortly',
                  //   },
                  // });
                }}
              >
                Submit
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}

      {/* commented on june 28 and moved to the conditional rendering above based on if user had alrady done the trivia */}
      {/* {eventTrivia && triviaForThisEvent ? (
        <div>
          {triviaForThisEvent?.questions?.map((question) => (
            <div>
              <ThisOrThat
                question={question}
                usersAnswers={usersAnswers}
                // setUsersAnswers={setUsersAnswers}
                addToUsersAnswers={addToUsersAnswers}
              />
              <br />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )} */}

      {/* <h5>trivia question</h5> */}
      {/* 
      {trivia.map((question) => (
        <div>
          <ThisOrThat
            question={question}
            usersAnswers={usersAnswers}
            // setUsersAnswers={setUsersAnswers}
            addToUsersAnswers={addToUsersAnswers}
          />
          <br />
        </div>
      ))} */}

      {/* on submit, go to backend
  1. use trivia_id to get other users trivia responses
  2. select 5 random users and run matching algorithm and return to frontend
  3. let user connect w/ users and / or regenerate users to connect w/ 
  4. also allow user to plan activity 

*/}
    </div>
  );
};

export default Trivia;
