import React, { useReducer } from 'react';
import axios from 'axios';
import EventContext from './eventContext'; //be sure the case is not case sensitive
import EventReducer from './eventReducer';
// import GET_USERS_ONE_AT_A_TIME from '../types';
import {
  GET_ALL_EVENTS,
  GET_ONE_EVENT,
  DELETE_EVENT,
  CREATE_EVENT,
  JOIN_EVENT,
  REQUEST_ROOMMATE,
  EVENT_ERROR,
  GET_ATTENDEES_FOR_EVENT,
  GET_ROOMMATE_REQUESTS_FOR_EVENT,
  EDIT_EVENT,
  JOIN_EVENT_AS_VENDOR,
  GET_VENDORS_FOR_EVENT,
  UNJOIN_EVENT,
  ADD_TO_LINEUP,
  GET_LINEUP_FOR_EVENT,
  GET_DICT_LINEUP_FOR_EVENT,
  GET_ALL_ARTISTS,
  GET_ALL_ARTISTS_DICTIONARY,
  GET_WORKSHOP_SCHEDULE,
  UPDATE_WORKSHOP,
  GET_TAKEN_WORKSHOP_ROOMS,
  GET_WORKSHOP_ATTENDEES,
  GET_JOINED_WORKSHOP,
  CREATE_NEW_ARTIST,
  GET_USER_ITINERARY,
  ADD_NEW_DJ,
  GET_DJS_AND_REQUESTS,
  ADD_SONG_REQUEST,
  GET_EVENTS_BY_LOCATION,
  GET_WORKSHOP_SCHEDULE_NEW_STRUCTURE,
  ADD_EVENT_FEEDBACK,
  GET_ATTENDEE_FEEDBACK,
  GET_HIGHLIGHTED_EVENTS,
  GET_EVENTS_OF_TYPE,
  JOIN_LINEUP,
  ADD_PREMIUM_EVENT_SUBSCRIPTION,
  ADD_SAFETY_OFFICERS,
  GET_EVENTS_FOR_SEARCH_CRITERIA,
} from '../types';

const EventState = (props) => {
  const initialState = {
    events: [],
    eventsInLocation: [],
    event: {},
    new_event: null,
    updatedEvent: {},
    attendees: [],
    roommate_requests: [],
    // current: null,
    // filtered: null,
    error: null,
    joined_event: null,
    roommate_request: null,
    vendor: {},
    vendors: [],
    lineup: [],
    lineupObject: {},
    all_artists: {},
    all_artists_dict: {},
    workshop_schedule: {},
    workshop_schedule_new_structure: {},
    updated_workshop: {},
    takenWorkshopRoomsMap: {},
    workshop_attendees: [],
    joined_workshop: {},
    created_artist: {},
    myItinerary: [],
    newDJ: {},
    djs_and_requests: [],
    newSongRequest: {},
    submitted_event_feedback: {},
    attendee_feedback: [],
    highlighted_events: [],
    allEventsWithEventType: [],
    loading: true,
    safetyOfficers: [],
    eventsFromSearch: [],
  };
  const [state, dispatch] = useReducer(EventReducer, initialState);

  // Get Users
  const getAllEvents = async () => {
    try {
      console.log('running getAllEvents()');
      const res = await axios.get('/api/events');
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_ALL_EVENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getEventsWithEventType = async (event_type, country, state, city) => {
    try {
      console.log('running getEventsWithEventType()');
      const res = await axios.get(
        `/api/events/type/${event_type}/${country}/${state}/${city}`
      );
      console.log('results should be filtered');
      console.log(res.data);
      dispatch({
        type: GET_EVENTS_OF_TYPE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getEventsByLocation = async (city, state, dance_type) => {
    try {
      console.log('trying to getEventsByLocation ');
      const res = await axios.get(
        `/api/events/eventsByLocation/${city}/${state}/${dance_type}`
      );

      console.log('res below from eventstate.js');
      console.log(res);

      dispatch({
        type: GET_EVENTS_BY_LOCATION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err,
      });
    }
  };

  const getOneEvent = async (event_id) => {
    try {
      console.log('running getOneEvent()');
      const res = await axios.get(`/api/events/event/${event_id}`);
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_ONE_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // send eventid to backend or can we load this info in when the user selects the event???
  const getAttendeesForEvent = async (event_id) => {
    try {
      console.log('trying to send id to backend from EventState');
      const res = await axios.get(`/api/events/attendees/${event_id}`);

      dispatch({
        type: GET_ATTENDEES_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getRoommateRequestsForEvent = async (event_id) => {
    try {
      console.log('trying to send id to backend from EventState');
      const res = await axios.get(`/api/events/roommate-requests/${event_id}`);
      // console.log(
      //   `all reqs belonging to event id: ${event_id} and should be sorted`
      // );
      // console.log(res.data);
      // console.log('end ----');
      dispatch({
        type: GET_ROOMMATE_REQUESTS_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const joinEvent = async (user_id, event_id) => {
    try {
      console.log(` userid: ${user_id} ----- event_id: ${event_id}`);
      console.log(
        'trying to send user_id and event_id to backend from EventState'
      );
      // const res = await axios.post('/api/events/join', user_id, event_id);

      const idList = {
        userId: user_id,
        eventId: event_id,
      };

      const res = await axios.post('/api/events/join', idList);
      console.log('res from joinEvent is -----------------------');
      console.log(res);
      console.log('res is done -----------------------');

      dispatch({
        type: JOIN_EVENT,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const joinEventAsVendor = async (user_id, event_id) => {
    try {
      // console.log(` userid: ${user_id} ----- event_id: ${event_id}`);
      // console.log(
      //   'trying to send user_id and event_id to backend from EventState'
      // );
      // const res = await axios.post('/api/events/join', user_id, event_id);

      const idList = {
        user_id: user_id,
        event_id: event_id,
      };

      const res = await axios.post(`/api/events/${event_id}/vendors`, idList);
      console.log(
        'res from joinEventAsVendor() on backend is -----------------------'
      );
      console.log(res);
      console.log('res is done -----------------------');

      dispatch({
        type: JOIN_EVENT_AS_VENDOR,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const getVendorsForEvent = async (event_id) => {
    try {
      console.log('getting event vendors from frontend');
      const res = await axios.get(`/api/events/${event_id}/vendors`);

      dispatch({
        type: GET_VENDORS_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const requestRoommate = async (user_id, event_id, descr) => {
    try {
      console.log(` userid: ${user_id} ----- event_id: ${event_id}`);
      console.log(
        'trying to send user_id and event_id to backend from EventState'
      );
      // const res = await axios.post('/api/events/join', user_id, event_id);

      const requestObject = {
        userId: user_id,
        eventId: event_id,
        descr: descr,
      };

      const res = await axios.post(
        '/api/events/request-roommate',
        requestObject
      );
      console.log('res is -----------------------');
      console.log(res);
      console.log('res is done -----------------------');

      dispatch({
        type: REQUEST_ROOMMATE,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  // const createNewEvent = async (name, descr, hostId) => {
  const createNewEvent = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log('trying to createNewEvent backend from EventState');
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post('/api/events', form);

      dispatch({
        type: CREATE_EVENT,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const deleteEvent = async (id) => {
    try {
      console.log(`trying to delete event w id : ${id}`);
      await axios.delete(`/api/events/${id}`);

      dispatch({
        type: DELETE_EVENT,
        payload: id,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const unjoinEvent = async (user_id, event_id) => {
    try {
      const res = await axios.delete(
        `/api/events/joined-event/${user_id}/${event_id}`
      );

      dispatch({
        type: UNJOIN_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const editEvent = async (event) => {
    try {
      console.log('trying to edit event');
      const res = await axios.post('/api/events/edit-event', event);
      console.log('editing event');
      console.log(res);
      dispatch({
        type: EDIT_EVENT,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err,
      });
    }
  };

  //EVENT-LINEUP

  // const createNewEvent = async (name, descr, hostId) => {
  const addToEventLineup = async (obj) => {
    try {
      console.log('trying to addToEventLineup from EventState');
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   artists:artists
      // };

      const res = await axios.post('/api/event-lineup/addToLineup', obj);

      dispatch({
        type: ADD_TO_LINEUP,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };
  const premiumArtistJoinEventLineup = async (obj) => {
    try {
      console.log('trying to premiumArtistJoinEventLineup from EventState');
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   artists:artists
      // };

      const res = await axios.post(
        '/api/event-lineup/premiumArtistJoinEventLineup',
        obj
      );

      dispatch({
        type: JOIN_LINEUP,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const getLineupForEvent = async (event_id) => {
    try {
      console.log('trying to GET_LINEUP_FOR_EVENT EventState');
      const res = await axios.get(`/api/event-lineup/lineup/${event_id}`);

      dispatch({
        type: GET_LINEUP_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getDictLineupForEvent = async (event_id) => {
    try {
      console.log('trying to GET_LINEUP_FOR_EVENT EventState');
      const res = await axios.get(
        `/api/event-lineup/lineup/dictionary/${event_id}`
      );

      console.log('res.data from getDictLineupForEvent');
      console.log(res.data);

      // console.log('res.data should be dict - bachata ------------');
      // console.log(res.data.bachata);
      // for (const [key, value] of Object.entries(res.data)) {
      //   console.log(`${key}: ${value} -------`);
      // }
      dispatch({
        type: GET_DICT_LINEUP_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getArtistsDictionary = async () => {
    try {
      console.log('trying to GET_LINEUP_FOR_EVENT EventState');
      const res = await axios.get(`/api/event-lineup/all-artists/dictionary`);

      console.log('res.data should be dict - bachata ------------');
      console.log(res.data.bachata);
      for (const [key, value] of Object.entries(res.data)) {
        console.log(`${key}: ${value} -------`);
      }
      dispatch({
        type: GET_ALL_ARTISTS_DICTIONARY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getAllArtists = async () => {
    try {
      console.log('trying to GET_LINEUP_FOR_EVENT EventState');
      const res = await axios.get(`/api/event-lineup/all-artists`);

      dispatch({
        type: GET_ALL_ARTISTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getWorkshopSchedule = async (event_id) => {
    try {
      console.log('trying to GET_LINEUP_FOR_EVENT EventState');
      const res = await axios.get(
        `/api/event-lineup/workshopSchedule/${event_id}`
      );

      dispatch({
        type: GET_WORKSHOP_SCHEDULE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //testing - added 5.17.23
  const getWorkshopScheduleNewStructure = async (event_id) => {
    try {
      console.log('trying to getWorkshopScheduleNewStructure EventState');
      const res = await axios.get(
        `/api/event-lineup/workshopScheduleNewStructure/${event_id}`
      );

      console.log(
        'getWorkshopScheduleNewStructure -- res.data should be dict ------------'
      );
      console.log(res.data);
      for (const [key, value] of Object.entries(res.data)) {
        console.log(`${key}: ${value} -------`);
      }
      dispatch({
        type: GET_WORKSHOP_SCHEDULE_NEW_STRUCTURE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //WORKSHOP

  const updateWorkshop = async (obj) => {
    try {
      console.log('trying to updateWorkshop from EventState');
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   artists:artists
      // };

      const res = await axios.post('/api/event-lineup/updateWorkshop', obj);
      console.log('res.data -- updated workshop is');
      console.log(res.data);
      dispatch({
        type: UPDATE_WORKSHOP,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const getTakenWorkshopRooms = async (event_id) => {
    try {
      const res = await axios.get(`/api/event-lineup/taken-rooms/${event_id}`);
      console.log('taken-rooms');
      console.log(res.data);
      dispatch({
        type: GET_TAKEN_WORKSHOP_ROOMS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const createWorkshopScheduleFromScratch = async (obj) => {
    try {
      console.log(
        'trying to createWorkshopScheduleFromScratch from EventState -- obj should have artist_id'
      );
      console.log(obj);
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   artists:artists
      // };

      const res = await axios.post('/api/event-lineup/addWorkshop', obj);
      //idk if this is updating the state properly like we want, but
      //take time to think about how the state should loook after this

      console.log(
        'res.data from -- createWorkshopScheduleFromScratch() -- api/event-lineup/addWorkshop'
      );
      console.log(res.data);

      dispatch({
        type: GET_WORKSHOP_SCHEDULE,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const joinWorkshop = async (obj) => {
    try {
      const res = await axios.post('/api/workshops/join', obj);
      console.log('res.data from joinworkshop frontend');
      console.log(res.data);
      dispatch({
        type: GET_JOINED_WORKSHOP, //? returns []
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };
  const getWorkshopAttendees = async (workshop_id) => {
    try {
      const res = await axios.get(`/api/workshops/attendees/${workshop_id}`);
      console.log('res.data from getWorkshopAttendees frontend');
      console.log(res.data);
      dispatch({
        type: GET_WORKSHOP_ATTENDEES, //? returns []
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const addNewArtist = async (form) => {
    try {
      console.log(` form is ${form}`);

      const res = await axios.post('/api/workshops/new-artist', form);
      console.log('res.data from addNewArtist ---');
      console.log(res.data);
      dispatch({
        type: CREATE_NEW_ARTIST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const getMyItinerary = async (event_id, user_id) => {
    try {
      // console.log('obj from getMyItinerary');
      // console.log(obj);
      const res = await axios.get(
        `/api/workshops/joined/${event_id}/${user_id}`
      );
      console.log('res.data from getMyItinerary frontend');
      console.log(res.data);
      let dict = new Map(Object.entries(res.data));
      console.log('dict');
      console.log(dict);
      dispatch({
        type: GET_USER_ITINERARY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const addNewDJ = async (details) => {
    try {
      // console.log(` userid: ${user_id} ----- event_id: ${event_id}`);
      console.log('trying to add new DJ EventState');
      // const res = await axios.post('/api/events/join', user_id, event_id);

      // const idList = {
      //   // userId: user_id,
      //   eventId: event_id,
      // };

      const res = await axios.post('/api/events/addNewDJ', details);
      console.log('res from joinEvent is -----------------------');
      console.log(res);
      console.log('res is done -----------------------');

      dispatch({
        type: ADD_NEW_DJ,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const get_djs_and_requests = async (event_id) => {
    try {
      const res = await axios.get(`/api/events/djs_and_requests/${event_id}`);
      console.log('res.data from get_djs_and_requests frontend');

      dispatch({
        type: GET_DJS_AND_REQUESTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const addSongRequest = async (details) => {
    try {
      // console.log(` userid: ${user_id} ----- event_id: ${event_id}`);
      console.log('trying to add new DJ EventState');
      // const res = await axios.post('/api/events/join', user_id, event_id);

      // const idList = {
      //   // userId: user_id,
      //   eventId: event_id,
      // };

      const res = await axios.post('/api/events/addSongRequest', details);
      console.log('res from addSongRequest is -----------------------');
      console.log(res);
      console.log('res is done -----------------------');

      dispatch({
        type: ADD_SONG_REQUEST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const addEventFeedback = async (obj) => {
    try {
      const res = await axios.post('/api/events/addEventFeedback', obj);
      console.log('res.data from addEventFeedback frontend');
      console.log(res.data);
      dispatch({
        type: ADD_EVENT_FEEDBACK, //? returns []
        payload: res.data,
      });
      getAttendeeFeedback(obj.event_id);

      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const getAttendeeFeedback = async (event_id) => {
    try {
      const res = await axios.get(`/api/events/feedback/${event_id}`);
      console.log('res.data from getAttendeeFeedback frontend');

      dispatch({
        type: GET_ATTENDEE_FEEDBACK,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };
  const getHighlightedEvents = async () => {
    try {
      const res = await axios.get(`/api/events/highlighted-events`);
      console.log('res.data from getHighlightedEvents frontend');
      console.log(res.data);
      dispatch({
        type: GET_HIGHLIGHTED_EVENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const addPremiumEventSubscription = async (obj) => {
    try {
      const res = await axios.post('/api/events/add-event-subscription', obj);
      console.log('res.data from add-event-subscription frontend');
      console.log(res.data);
      dispatch({
        type: ADD_PREMIUM_EVENT_SUBSCRIPTION, //? returns []
        payload: res.data,
      });

      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response,
      });
    }
  };

  const addSafetyOfficers = async (obj) => {
    try {
      console.log('trying to add safety officers');
      const res = await axios.put('/api/events/add-safety-officers', obj);
      console.log('adding safety officers');
      console.log(res);
      dispatch({
        type: ADD_SAFETY_OFFICERS,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err,
      });
    }
  };

  const getEventsForSearchCriteria = async (searchParams) => {
    try {
      console.log('custom STUDIO search from frontend');
      // let event_id = '624c8b4f236daaa095ba62e7';
      const res = await axios.get(`/api/events/search-events/${searchParams}`);
      // const res = await axios.get('/api/events/all-my-events', obj);
      console.log('res.data');
      console.log(res.data);

      dispatch({
        type: GET_EVENTS_FOR_SEARCH_CRITERIA,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err,
      });
    }
  };

  return (
    <EventContext.Provider
      value={{
        // props -- where our state and actions will go
        events: state.events,
        eventsInLocation: state.eventsInLocation,
        event: state.event,
        attendees: state.attendees,
        roommate_requests: state.roommate_requests,
        roommate_request: state.roommate_request, //for when you submit a R.R.
        updatedEvent: state.updatedEvent,
        vendor: state.vendor,
        vendors: state.vendors,
        lineup: state.lineup,
        lineupObject: state.lineupObject,
        all_artists: state.all_artists,
        all_artists_dict: state.all_artists_dict,
        workshop_schedule: state.workshop_schedule,
        updated_workshop: state.updated_workshop,
        takenWorkshopRoomsMap: state.takenWorkshopRoomsMap,
        workshop_attendees: state.workshop_attendees,
        joined_workshop: state.joined_workshop,
        created_artist: state.created_artist,
        myItinerary: state.myItinerary,
        newDJ: state.newDJ,
        djs_and_requests: state.djs_and_requests,
        newSongRequest: state.newSongRequest,
        workshop_schedule_new_structure: state.workshop_schedule_new_structure,
        submitted_event_feedback: state.submitted_event_feedback,
        attendee_feedback: state.attendee_feedback,
        highlighted_events: state.highlighted_events,
        allEventsWithEventType: state.allEventsWithEventType,
        loading: state.loading,
        safetyOfficers: state.safetyOfficers,
        eventsFromSearch: state.eventsFromSearch,

        getAllEvents,
        getEventsByLocation,
        getOneEvent,
        getAttendeesForEvent,
        getRoommateRequestsForEvent,
        joinEvent,
        requestRoommate,
        createNewEvent,
        deleteEvent,
        editEvent,
        joinEventAsVendor,
        getVendorsForEvent,
        unjoinEvent,
        addToEventLineup,
        getLineupForEvent,
        getDictLineupForEvent,
        getAllArtists,
        getArtistsDictionary,
        getWorkshopSchedule,
        updateWorkshop,
        getTakenWorkshopRooms,
        createWorkshopScheduleFromScratch,
        joinWorkshop, // workshop attendees should update
        getWorkshopAttendees,
        addNewArtist,
        getMyItinerary,
        addNewDJ,
        get_djs_and_requests,
        addSongRequest,
        getWorkshopScheduleNewStructure,
        addEventFeedback,
        getAttendeeFeedback,
        getHighlightedEvents,
        getEventsWithEventType,
        premiumArtistJoinEventLineup,
        addPremiumEventSubscription,
        addSafetyOfficers,
        getEventsForSearchCriteria,
      }}
    >
      {props.children}
    </EventContext.Provider>
  );
};

export default EventState;
