import React, { Component, useContext, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
} from 'react-bootstrap';
// import { Form } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import Countries from '../Filter/countries.json';
import Countries2 from '../location/Countries.json';
import RankingsContext from '../../context/rankings/rankingsContext';
import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';
import { Country, State, City } from 'country-state-city';
import AddLocation from '../location/AddLocation';

const EventFilter2 = (props) => {
  const rankingsContext = useContext(RankingsContext);
  // const { rankings, getRankings, getFilteredRankings } = rankingsContext;

  const [show, setShow] = useState(props.showFilter);
  //   const [show, setShow] = useState(props.filterClicked);
  const handleClose = () => props.setShowFilter(false);
  const countries = Countries;
  const countries2 = Countries2;
  let State = require('country-state-city').State;

  // let { style } = props;
  const [filterDetails, setFilterDetails] = useState({
    // country: countries[0],
    country: 'United States',
    state: 'none',
    city: 'none',
    style: 'Dance Events',
  });

  const { city, state, country, style } = filterDetails;
  const styles = [
    'Dance Events',
    'City Festivals',
    'Concerts',
    'Homecoming',
    'Other',
  ];

  const handleFilter = () => {
    let obj = filterDetails;
    console.log('obj fdd');
    console.log(obj);
    // getFilteredRankings(obj);

    // getFilteredEvents(obj)

    props.getEventsWithEventType(style, country, state, city);
    props.setShowFilter(false);
  };

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState(['']);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setFilterDetails({
      ...filterDetails,
      city: cities ? cities[0] : '',
      state: state,
    });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  const [selectedCountry, setSelectedCountry] = useState('Select A Country');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');

  let Country = require('country-state-city').Country;

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const [citiesOfState, setCitiesOfState] = useState([]);

  const handleCountryChange = (index) => {
    console.log('changed country countryCode');
    console.log('index');
    console.log(index);
    // console.log(countryCode);
    // get country from api
    let country = countries2[index];

    console.log('country with code');
    console.log(country);
    let selectedCountry = Country.getCountryByCode(country.code);
    // setCountry(country.name);
    console.log('selectedCountry from api');
    console.log(selectedCountry);
    // setSelectedCountry(country.name);
    setSelectedCountry(country.name);
    setSelectedCountryCode(country.code);

    setFilterDetails({
      ...filterDetails,
      country: country.name,
      //   [e.target.name]: e.target.value,
    });
    //get states by country code

    let statesOfCountry = State.getStatesOfCountry(country.code);

    setStates(statesOfCountry);

    setSelectedState(statesOfCountry[statesOfCountry.length - 1]?.name);
    if (selectedState !== '') {
      console.log('hi');
    }

    // setCityData();
  };

  const handleStateChange = (index) => {
    console.log('changed country countryCode');
    // console.log(countryCode);
    // get cities from api
    let state = states[index];
    console.log('state is');
    console.log(state);

    // setFilterDetails({
    //   ...filterDetails,
    //   state: state.name,

    // });
    let selectedCities = City.getCitiesOfState(
      selectedCountryCode,
      state.isoCode
    );
    console.log('selectedCities from api');
    console.log(selectedCities);
    setCitiesOfState(selectedCities);
    setSelectedState(state.name);

    setFilterDetails({
      ...filterDetails,
      state: state.name,
      city: selectedCities[0]?.name,
      //   [e.target.name]: e.target.value,
    });
    // setState(state.name);

    //get states by country code

    // let statesOfCountry = State.getStatesOfCountry(country.code);
    // setStates(statesOfCountry);

    setSelectedCity(selectedCities[0]?.name);
    // setFilterDetails({
    //   ...filterDetails,
    //   city: selectedCities[0]?.name,
    //   //   [e.target.name]: e.target.value,
    // });

    console.log('selectedCities');
    console.log(selectedCities);
    if (props?.setLocation) {
      props.setLocation(selectedCity + ',' + selectedState);
    }
  };

  const handleCityChange = (index) => {
    console.log('changed country countryCode');
    // console.log(countryCode);
    // get cities from api
    console.log('index frp, coty cjamge');
    console.log('citiesOfState[index].name');
    console.log(citiesOfState[index].name);
    console.log(index);
    setSelectedCity(citiesOfState[index].name);
    console.log('state frp, hcc');
    console.log(state);
    let cities = listOfStatesAndCities[state];

    // let cities = selectedCities(e.target.value);

    setFilterDetails({
      ...filterDetails,
      city: cities ? cities[0] : '',
      // state: state,
    });

    console.log('cities yo');
    console.log(cities);

    // setCity(citiesOfState[index].name);
    // if (props?.setLocation) {
    //   props.setLocation(citiesOfState[index].name + ',' + selectedState);
    // }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="col"> */}
          <Form.Group controlId="formBasicSelect">
            <Form.Label>Type of Event</Form.Label>
            <Form.Control
              id="style"
              name="style"
              as="select"
              value={style}
              required
              onChange={(e) => {
                setFilterDetails({
                  ...filterDetails,
                  [e.target.name]: e.target.value,
                });
                console.log(filterDetails);
                //   props.handleLocationChange(e.target.value, state, 'all');
              }}
            >
              {styles?.map((style) => <option value={style}>{style}</option>)}
            </Form.Control>

            <div className="row" style={{ marginTop: '14px' }}>
              <div className="col">
                <h5>Country</h5>
              </div>

              <div className="col">
                {[DropdownButton].map((DropdownType, index) => (
                  <DropdownType
                    as={ButtonGroup}
                    key={index}
                    id={`dropdown-button-drop-${index}`}
                    size="sm"
                    variant="secondary"
                    title={selectedCountry}
                    onSelect={handleCountryChange}
                    // onChange={onChange}
                  >
                    {countries2.map((country, index) => (
                      <Dropdown.Item eventKey={index}>
                        {country.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownType>
                ))}
              </div>
            </div>

            <div className="row">
              <div className="col">
                <h5>State</h5>
              </div>
              <div className="col">
                {[DropdownButton].map((DropdownType, index) => (
                  <DropdownType
                    as={ButtonGroup}
                    key={index}
                    id={`dropdown-button-drop-${index}`}
                    size="sm"
                    variant="secondary"
                    title={selectedState}
                    onSelect={handleStateChange}
                  >
                    {states.map((state, index) => (
                      <Dropdown.Item eventKey={index}>
                        {state.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownType>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5>City</h5>
              </div>
              <div className="col">
                {[DropdownButton].map((DropdownType, index) => (
                  <DropdownType
                    as={ButtonGroup}
                    key={index}
                    id={`dropdown-button-drop-${index}`}
                    size="sm"
                    variant="secondary"
                    title={selectedCity}
                    onSelect={handleCityChange}
                  >
                    {citiesOfState.map((city, index) => (
                      <Dropdown.Item eventKey={index}>
                        {city.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownType>
                ))}
              </div>
            </div>
          </Form.Group>
          <div style={{ paddingTop: '10px' }}>
            {/* <Button onClick={() => console.log(filterDetails)}>Filter</Button> */}
            <Button onClick={handleFilter}>Filter</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default EventFilter2;
