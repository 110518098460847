import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { Component } from 'react';

function Example() {
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const imgg =
    'https://res.cloudinary.com/tinyhouselistings/image/upload/c_fill,h_1200,w_1200/CD6F62B2-42BA-4C70-B639-D21FB6C0AACF_t6juqa.jpg';

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        add members
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Woohoo, you're reading this text in a modal!
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="option1"
              checked
            />
            <img src={imgg} height="100" alt="hello" />

            <label class="form-check-label" for="exampleRadios1">
              Default radio
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              value="option2"
            />
            <img src={imgg} height="100" alt="hello" />
            <label class="form-check-label" for="exampleRadios2">
              Second default radio
            </label>
          </div>
          <div class="form-check disabled">
            <input
              class="form-check-input"
              type="radio"
              name="exampleRadios"
              id="exampleRadios3"
              value="option3"
              disabled
            />
            <img src={imgg} height="100" alt="hello" />

            <label class="form-check-label" for="exampleRadios3">
              Disabled radio
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Example;
