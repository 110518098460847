import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import ReactionContext from '../../context/reaction/reactionContext';
import EventFeedbackCategory from './EventFeedbackCategory';
// import Category from './Category';
import { useAuth } from '../../context/auth/AuthState';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import AddFeedBackForm from './AddFeedBackForm';

const EventFeedback_v02 = (props) => {
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;
  const reactionContext = useContext(ReactionContext);
  const {} = reactionContext;

  const eventContext = useContext(EventContext);
  const { addEventFeedback, submitted_event_feedback } = eventContext;

  const [details, setDetails] = useState({
    event_id: '',
    feedback_giver_id: '',
    feedback_giver_name: '',
    feedback_categories: [],
    general_comment: '',
    how_can_this_event_improve: '',
    what_did_you_love_about_this_event: '',
  });
  const {
    event_id,
    feedback_giver_id,
    feedback_giver_name,
    feedback_categories,
    general_comment,
    how_can_this_event_improve,
    what_did_you_love_about_this_event,
  } = details;

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    // getAllReactions();
    // eslint-disable-next-line
  }, []);

  let feedbackCategories = [
    {
      category: 'music',
      categoryScore: '1',
    },
    {
      category: 'workshops',
      categoryScore: '1',
    },
    {
      category: 'schedule',
      categoryScore: '1',
    },
  ];
  //   let feedbackCategories = [
  //     {
  //       category_name: 'music',
  //       score: '1',
  //     },
  //     {
  //       category_name: 'workshops',
  //       score: '1',
  //     },
  //     {
  //       category_name: 'schedule',
  //       score: '1',
  //     },
  //   ];

  const [
    feedbackCategoriesAndScores,
    setFeedbackCategoriesAndScores,
  ] = useState(feedbackCategories);

  const [showWorkshopSchedule, setShowWorkshopSchedule] = useState(false);

  const setFieldsFromChildComponent = (score, indexToChange) => {
    console.log('score,indexToChange from child');
    console.log(score, indexToChange);
    const key = 'categoryScore';
    setFeedbackCategoriesAndScores([
      ...feedbackCategoriesAndScores.slice(0, indexToChange),
      {
        // here update data value
        ...feedbackCategoriesAndScores[indexToChange],
        [key]: score,
      },
      ...feedbackCategoriesAndScores.slice(indexToChange + 1),
    ]);
    console.log('feedbackCategoriesAndScores after update ??');
    console.log(feedbackCategoriesAndScores);
  };

  //   const handleShowWorkshopSchedule = () => {
  //     setShowWorkshopSchedule(!showWorkshopSchedule);
  //   };
  //   const [visible, setVisible] = React.useState(false);

  const showDancers = () => {
    // let showDancers = false;

    if (props.dance_reaction_elected === true) {
      console.log('props from event DanceReactionsComponent');
      console.log(props);
      return props.dance_reaction_elected;
    } else {
      return false;
    }
  };
  const [showDanceReactions, setShowDanceReactions] = useState(showDancers);

  const handleShowDancerReactions = () => {
    setShowAddFeedBackForm(!showAddFeedBackForm);
    console.log(`showLineup is: ${showAddFeedBackForm}`);
  };
  //   const handleShowDancerReactions = () => {
  //     setShowDanceReactions(!showDanceReactions);
  //     console.log(`showLineup is: ${showDanceReactions}`);
  //   };

  const handleCloseDanceReactions = () => {
    console.log('closing ');
    setShowDanceReactions(false);
  };
  //   console.log('all_reactions from DR ----');
  //   console.log(all_reactions);

  const onSubmit = (e) => {
    e.preventDefault();

    let obj = {
      // dancer_reaction_id_to_react_to: dancer._id,
      // reacting_user_id: isAuthenticated ? user._id : '',
      // reacting_user_name: isAuthenticated ? user.name : '',
      // //   reacting_user_name: {user ? user.name:""},
      // reaction_emoji: selectedEmoji,
      // comment: '',

      event_id: props.event._id,
      feedback_giver_id: isAuthenticated ? user._id : '',
      feedback_giver_name: isAuthenticated ? user.name : '',
      feedback_categories: feedbackCategoriesAndScores,
      general_comment,
      how_can_this_event_improve,
      what_did_you_love_about_this_event,
    };

    // if (dancer_name === '') {
    //   //   setAlert('Please fill in all fields', 'danger');
    //   console.log('type in dancer name');
    // } else {
    // setDetails({
    //   ...details,
    //   [dance_company_id]: 'help',
    // });

    console.log(
      'feedbackCategories from eventfeedback.js --- should be updated'
    );
    console.log(feedbackCategoriesAndScores);
    console.log('----------------');
    console.log('obj from eventfeedback');
    console.log(obj);
    addEventFeedback(obj);
    // console.log('e is');
    // console.log(e);

    // const newObj = {
    //   bib_number: bib_number,
    //   dancer_name: dancer_name,

    //   category: category,
    // };
    // console.log('newObj to pass to backend');
    // console.log(newObj);

    // addDancer(newObj);
  };

  const setShowAddFeedBackFormFromChild = (showDecision) => {
    setShowAddFeedBackForm(showDecision);
  };

  const [showAddFeedBackForm, setShowAddFeedBackForm] = useState(false);

  return (
    // <div>
    <div>
      <div>
        <h1>Leave Feedback For The Organizer</h1>
      </div>
      <Button
        onClick={() => handleShowDancerReactions()}
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '20px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '0px 12px',
          borderWidth: '1px',
        }}
      >
        Add Feedback
      </Button>

      {showAddFeedBackForm && (
        <AddFeedBackForm
          event={props.event}
          dance_reaction_elected={showAddFeedBackForm}
        />
      )}

      {/* <Modal show={showDanceReactions} onHide={handleCloseDanceReactions}>
        <Modal.Header closeButton>
          <Modal.Title>Event Feedback</Modal.Title>
        </Modal.Header>
        <div>
          <Modal.Body>
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="row">
                  {feedbackCategories.map((category, index) => (
                    <div style={{ paddingBottom: '10px' }}>
                      <EventFeedbackCategory
                        category={category}
                        indexToChange={index}
                        setFieldsFromChildComponent={
                          setFieldsFromChildComponent
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <input
                type="submit"
                value="Add Feedback"
                class="btn btn-secondary"
              />
            </form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDanceReactions}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal> */}
    </div>
  );
};

export default EventFeedback_v02;
