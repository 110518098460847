// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { useAuth } from '../../context/auth/AuthState';

import SubEventContext from '../../context/subevent/subEventContext';
import SubEvent from './SubEvent';
import { Button } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom';

const SubEvents = (props) => {
  const { event } = props;
  const [authState] = useAuth();

  const { user } = authState;

  //   const eventContext = useContext(EventContext);
  const subEventContext = useContext(SubEventContext);
  const {
    addSubEventForEvent,
    getSubEventsForEvent,
    sub_events,
  } = subEventContext;

  useEffect(() => {
    console.log(
      'getting events from Event.js but need to change component name'
    );
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    getSubEventsForEvent(event._id);
    // eslint-disable-next-line
  }, []);
  const [visible, setVisible] = React.useState(false);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [details, setDetails] = useState({
    sub_event_name: '',
    descr: '',
    sub_event_image: '',
    activity_type: 'other',
  });
  const { sub_event_name, descr, sub_event_image, activity_type } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });
  const [file, setFile] = useState();
  const onSubmit2 = (e) => {
    e.preventDefault();
    if (descr === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      const data = new FormData();
      data.append('file', file);
      data.append('descr', descr);
      data.append('sub_event_name', sub_event_name);
      data.append('image', sub_event_image);
      data.append('eventId', event._id);
      data.append('hostId', user._id);
      data.append('activity_type', activity_type);
      console.log('add subevent hit');
      // console.log('at:');
      // console.log(activity_type);
      addSubEventForEvent(data);
      setVisible(!visible);
    }
  };

  const nouser = (
    <Fragment>
      <Link to="/login">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          sign in to add an event
        </button>
      </Link>
    </Fragment>
  );

  const activity_list = [
    {
      activity_type: 'pregame',
      id: 1,
    },
    {
      activity_type: 'afterparty',
      id: 2,
    },
    {
      activity_type: 'game night',
      id: 3,
    },
    {
      activity_type: 'secret santa',
      id: 3,
    },
    {
      activity_type: 'other',
      id: 4,
    },
  ];

  const onActivityChange = (e) => {
    console.log('e.target.name');
    console.log(e.target.name);
    console.log('e.target.value');
    console.log(e.target.value);
    setDetails({ ...details, activity_type: e.target.value });
  };

  const addEvent = (
    <Fragment>
      <form onSubmit={onSubmit2}>
        <div className="form-group">
          {activity_list.map((activity) => (
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="radio_button"
                id={activity.id}
                value={activity.activity_type}
                onChange={onActivityChange}
              />
              {/* <img
                src={activity.image ? activity.image.url : imgg}
                height="100"
                alt="activity"
              /> */}

              <label class="form-check-label" for={activity.id}>
                {activity.activity_type}
              </label>
            </div>
          ))}

          <label htmlFor="descr">Activity Name</label>
          <input
            id="sub_event_name"
            type="text"
            name="sub_event_name"
            // placeholder="tell us what you need"
            value={sub_event_name}
            onChange={onChange}
            required
          />
          <label htmlFor="descr">Details</label>
          <input
            id="descr"
            type="text"
            name="descr"
            // placeholder="tell us what you need"
            value={descr}
            onChange={onChange}
            required
          />
          <input
            id="sub_event_image"
            required
            name="sub_event_image"
            type="file"
            value={sub_event_image}
            onChange={(event) => {
              const file = event.target.files[0];
              setFile(file);
              setDetails({
                ...details,
                [event.target.name]: event.target.value,
              });
            }}
          />
        </div>

        <input
          type="submit"
          value="Add Activity"
          // className="btn btn-primary btn-block"
          class="btn btn-secondary"
          // onClick={onSubmit2}
        />
      </form>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!user) {
      console.log('no user found');
      return nouser;
    }
    return addEvent;
  };
  return (
    <div className="">
      {props?.back_button_active && (
        <Button
          onClick={() => {
            // selectedChat = '';
            console.log('selectedChat is:');
            // console.log(selectedChat);
            props.setShowChatsFromChild(true);
          }}
        >
          Back
        </Button>
      )}
      {/* <div className="card bg-light"> */}
      <div>
        <button onClick={() => setVisible(!visible)} class="btn btn-secondary">
          {visible ? 'Close' : 'Add Activity'}
        </button>
        {visible && (
          <div className="form-container">
            <h1>
              {/* Request <span className="text-primary">Details</span> */}
            </h1>
            {renderOptions()}
          </div>
        )}
      </div>

      {!visible &&
        sub_events.map((subevent) => (
          <SubEvent key={subevent.id} subevent={subevent} />
        ))}

      <div>{/* <h1>event name: {props.event.name}</h1> */}</div>
      <div className="bg-dark p">
        {/* <button onClick={onPass}>pass</button>
          <button onClick={onLike}>like</button> */}

        {/* <Button /> */}

        {/* <button>push</button> */}
        {/* <Link to={`/event/${props.event._id}`} state={{ event: event }}>
            Event Details
          </Link> */}
      </div>
      {/* </div> */}
    </div>
  );
};
export default SubEvents;
