import React, { Component } from 'react';
import OffenseLevels from './OffenseLevels';
import { pink } from '@material-ui/core/colors';
// import video from './contactReporter-giphy.mp4';

const SafetyToolsLandingPage = () => {
  return (
    <div style={{ backgroundColor: '#f8f8f8' }}>
      {/* <div className="trapezoid">
        <h5
          className="overlay-text"
          style={{
            // marginTop: '12px',
            // marginBottom: '12px',
            // // color: 'blue',
            // background: 'white',
            // borderStyle: 'solid',
            // borderColor: 'rgb(1, 126, 252)',
            // borderWidth: '2px',
            borderRadius: '6px',
            // // fontSize: '14px',
            // backgroundColor: 'white',
            // color: 'rgb(1, 126, 252)',
            // textAlign: 'center',
            fontSize: '60px',
            color: '#452c63',
            fontFamily: 'sans-serif',
            fontWeight: 'bolder',
            // height: '400px',
          }}
        >
          Safety Tools For Dancers
        </h5>
      </div> */}
      <h5
        style={{
          borderRadius: '6px',

          fontSize: '60px',
          color: '#452c63',
          fontFamily: 'sans-serif',
          fontWeight: 'bolder',
        }}
      >
        Safety Tools For Dancers
      </h5>

      <div />

      <div style={{ padding: '10px' }}>
        {' '}
        <div>
          <p
            className="content-1"
            // style={{
            //   fontFamily: 'sohne-var, Helvetica Neue, Arial, sans-serif',
            //   fontWeight: '400',
            // }}
          >
            Safety tools are designed to keep organizers updated on unsafe
            individuals in the dance community.
          </p>
        </div>
        <div className="card-colored-border" style={{ paddingTop: '' }}>
          <h5
            style={{
              fontSize: '32px',
              marginTop: '12px',
              marginBottom: '12px',
              // color: 'blue',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '2px',
              borderRadius: '6px',

              // fontSize: '14px',
              backgroundColor: 'rgb(1, 126, 252)',
              color: 'white',
              textAlign: 'center',
            }}
          >
            Determine Offense Level
          </h5>

          <OffenseLevels />
          <p style={{ color: 'gray', textAlign: 'center' }}>
            we will be updating these as needed --
            {'>'} --
            {'>'}
          </p>
        </div>
        {/* <div className="row">
          <video width="320" height="240" controls autoPlay={true}>
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div> */}
        <div className="card-colored-border">
          <div className="" style={{ paddingTop: '14px' }}>
            <h1
              style={{
                fontSize: '32px',
                marginTop: '12px',
                marginBottom: '12px',
                // color: 'blue',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                // fontSize: '14px',
                backgroundColor: 'rgb(1, 126, 252)',
                color: 'white',
                textAlign: 'center',
              }}
            >
              Conduct Investigation
            </h1>

            <p className="content-1">
              Contact the reporter, the witnesses, and the accused party to take
              their statements on the incident. Once this is done, a summary of
              the investigation will be written.
            </p>
          </div>
          <div>
            <h1
              style={{
                fontSize: '32px',
                marginTop: '12px',
                marginBottom: '12px',
                // color: 'blue',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                // fontSize: '14px',
                backgroundColor: 'rgb(1, 126, 252)',
                color: 'white',
                textAlign: 'center',
              }}
            >
              Summary of Investigation
            </h1>
            <div
              className="center-content"
              style={{
                // justifyContent: 'center',

                textAlign: '-webkit-center',
              }}
            >
              <iframe
                src="https://giphy.com/embed/lh1Gs4cMV97tcM0l1x"
                width="320"
                height="258"
                class="giphy-embed"
                allowFullScreen
                title="blah"
                className="dimensionsForGifs"
              />
            </div>
            <p>
              <a href="https://giphy.com/gifs/lh1Gs4cMV97tcM0l1x">via GIPHY</a>
            </p>
          </div>
          <div>
            <h1
              style={{
                fontSize: '32px',
                marginTop: '12px',
                marginBottom: '12px',
                // color: 'blue',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                // fontSize: '14px',
                backgroundColor: 'rgb(1, 126, 252)',
                color: 'white',
                textAlign: 'center',
              }}
            >
              Recommend a Resolution
            </h1>

            <p className="content-1">
              According to the nature of the offense and the findings of the
              investigation, a council member will recommend a fair and just
              determination for the council to vote on{' '}
            </p>
          </div>
          <div style={{ textAlign: '-webkit-center' }}>
            <iframe
              src="https://giphy.com/embed/TeaIeuTXzsnj0rG4Wl"
              width="320"
              height="266"
              // frameBorder="0"
              class="giphy-embed"
              allowFullScreen
              title="b3"
            />
            <p>
              <a href="https://giphy.com/gifs/TeaIeuTXzsnj0rG4Wl">via GIPHY</a>
            </p>
          </div>
        </div>
        <div className="card-colored-border">
          <h1
            style={{
              fontSize: '32px',
              marginTop: '12px',
              marginBottom: '12px',
              // color: 'blue',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '2px',
              borderRadius: '6px',

              // fontSize: '14px',
              backgroundColor: 'rgb(1, 126, 252)',
              color: 'white',
              textAlign: 'center',
            }}
          >
            Hold a vote to finalize the resolution
          </h1>

          <p
            className="content-1"
            // style={{
            //   fontFamily: 'sohne-var, Helvetica Neue, Arial, sans-serif',
            //   fontWeight: '400',
            // }}
          >
            After the vote, if the determination is that the offender was
            culplable, then they will be placed on a safety list for organizers
            to reference. A simple majority will suffice.
          </p>
          <div style={{ textAlign: '-webkit-center' }}>
            <iframe
              src="https://giphy.com/embed/DkEHK2U8yyKY7fQGzX"
              width="320"
              height="480"
              class="giphy-embed"
              allowFullScreen
              title="b2"
            />
          </div>
          <p>
            <a href="https://giphy.com/gifs/DkEHK2U8yyKY7fQGzX">via GIPHY</a>
          </p>
        </div>
        <div className="card-colored-border">
          <h5>
            If you or someone you know would make a great council member, email
            us at airmeus.team@gmail.com
          </h5>
        </div>
      </div>
    </div>
  );
};

export default SafetyToolsLandingPage;
