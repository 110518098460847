import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import FeedbackDetail from './FeedbackDetail';

const AllFeedback = (props) => {
  const [showFeedback, setShowFeedback] = useState(false);

  const nameLabel = (index) => {
    if (showFeedback) return 'Hide Feedback';

    if (!showFeedback && props?.feedback.feedback_giver_name)
      return props.feedback.feedback_giver_name;

    if (!showFeedback && props.feedback?.feedback_giver_name === '')
      return `Feedback ${props.index + 1}`;
  };

  return (
    <div className="row">
      <div className="col-1">
        <img
          className="rounded-circle"
          //   src={profile.user.avatar}
          src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
          // src={image}
          alt=""
          height="35"
          width="35"
        />
      </div>
      <div className="col">
        <Button
          onClick={() => {
            setShowFeedback(!showFeedback);
          }}
          style={{
            backgroundColor: 'transparent',
            color: 'black',
            fontSize: '14px',
            width: 'max-content',
          }}
        >
          {/* {props.feedback.feedback_giver_name === ''
            ? `Feedback ${props.index + 1}`
            : `${props.feedback.feedback_giver_name}`} */}
          {nameLabel(props.index)}
        </Button>

        {showFeedback && <FeedbackDetail feedback={props.feedback} />}
      </div>

      <div className="col" />
      <hr />
    </div>
  );
};

export default AllFeedback;
