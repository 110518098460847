import {
  GET_ALL_TRIVIA,
  POST_NEW_TRIVIA,
  ADD_TRIVIA_TO_EVENT,
  GET_TRIVIA_FOR_EVENT,
  GENERATE_CONNECTIONS,
  TRIVIA_ERROR,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_ALL_TRIVIA:
      return {
        ...state,
        allTrivias: action.payload,
        loading: false,
      };
    case GET_TRIVIA_FOR_EVENT:
      return {
        ...state,
        triviaForThisEvent: action.payload,
        loading: false,
      };
    case POST_NEW_TRIVIA:
      return {
        ...state,
        newTrivia: action.payload,
        loading: false,
      };

    case ADD_TRIVIA_TO_EVENT:
      return {
        ...state,
        event: action.payload,
        loading: false,
      };
    case GENERATE_CONNECTIONS:
      return {
        ...state,
        potentialConnections: action.payload,
        loading: false,
      };

    case TRIVIA_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
