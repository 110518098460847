import { GET_BLOG_POST, BLOG_ERROR } from '../types';

const blogReducer = (state, action) => {
  switch (action.type) {
    case GET_BLOG_POST:
      return {
        ...state,
        blog_post: action.payload,
        loading: false,
      };
    case BLOG_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default blogReducer;
