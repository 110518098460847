import React, { Component } from 'react';

import SearchResults from './SearchResults';
export default function SearchResultsPage() {
  return (
    <div>
      <SearchResults />
      {/* <h1>hello</h1> */}
    </div>
  );
}
