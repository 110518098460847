import React, { Component } from 'react';

const ReferralSubscriptionPurchase = (props) => {
  let { subscription } = props;
  return (
    <div className="card bg-light">
      <h5 style={{ color: 'green', fontSize: '16px' }}>
        subscription type: {subscription.subscription_type}
      </h5>
      <h5 style={{ color: 'green', fontSize: '16px' }}>
        Total Revenue Generated: ${subscription.total_revenue_generated}
      </h5>
      <h5 style={{ color: 'green', fontSize: '16px' }}>
        Revenue Share Amount: ${subscription.revenue_share_amount}
      </h5>
    </div>
  );
};

export default ReferralSubscriptionPurchase;
