import { Drawer, Box, Typography, IconButton } from '@material-ui/core';
import { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import Box from '@material-ui/core/Box';
// import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
// import List from '@material-ui/core/List';
// import Divider from '@material-ui/core/Divider';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemButton from '@material-ui/core';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import { logout, useAuth } from '../context/auth/AuthState';

const MuiDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;

  const onLogout = () => {
    logout(authDispatch);
    // clearContacts(contactDispatch);
  };
  //   const list = (anchor: Anchor) => (
  //     <Box
  //       sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
  //       role="presentation"
  //       onClick={toggleDrawer(anchor, false)}
  //       onKeyDown={toggleDrawer(anchor, false)}
  //     >
  //       <List>
  //         {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
  //           <ListItem key={text} disablePadding>
  //             <ListItemButton>
  //               <ListItemIcon>
  //                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //               </ListItemIcon>
  //               <ListItemText primary={text} />
  //             </ListItemButton>
  //           </ListItem>
  //         ))}
  //       </List>
  //       <Divider />
  //       <List>
  //         {['All mail', 'Trash', 'Spam'].map((text, index) => (
  //           <ListItem key={text} disablePadding>
  //             <ListItemButton>
  //               <ListItemIcon>
  //                 {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
  //               </ListItemIcon>
  //               <ListItemText primary={text} />
  //             </ListItemButton>
  //           </ListItem>
  //         ))}
  //       </List>
  //     </Box>
  //   );

  const safetyCouncilAccessGranted = () => {
    if (user?.safety_council_member === 'false') {
      console.log('user.safety_council is false');
      return false;
    }

    // if (!('safety_council_member' in user)) {
    //   console.log('userxx');
    //   console.log(user);
    //   console.log('safety_council not in user');
    //   return false;
    // }
    if (user?.email === 'airmeus.team@gmail.com') return true;
    if (user?.safety_council_member === 'true') {
      return true;
    }

    // if (
    //   user?.safety_council_member === 'false' ||
    //   user?.email !== 'airmeus.team@gmail.com' ||
    //   !('safety_council_member' in user)
    // ) {
    //   console.log('user.email');
    //   console.log(user?.email);
    //   console.log('user?.safety_council_member');
    //   console.log(user?.safety_council_member);
    //   return false;
    // } else {
    //   return true;
    // }
  };

  return (
    <>
      <IconButton
        onClick={() => setIsDrawerOpen(true)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="logo"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box p={2} width="250px" role="presentation" textAlign="center">
          {/* <Typography variant="h6" component="div">
            Menu
          </Typography> */}
          {/* <div className="row"> */}
          {/* <Button onClick={() => setIsDrawerOpen(false)}> */}
          <Link
            to="/"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '8em' }}
          >
            🏠 Home
          </Link>
          {/* </Button> */}
          {/* </div> */}
          <hr />

          <Link
            to="/safety"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '8em' }}
          >
            ❤️ Safety
          </Link>
          {/* </Button> */}
          {/* </div> */}
          <hr />
          {/* <div className="row"> */}

          <Link
            to="/upcoming-events"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '6em' }}
          >
            🥳 My Events
          </Link>

          <hr />

          <Link
            to="/user/profile"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '8em' }}
          >
            👤 Profile
          </Link>

          <hr />

          <Link
            to="/notifications"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '5em' }}
          >
            🔔 Notifications
          </Link>

          <hr />
          <Link
            to="/artist-directory"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '60px' }}
          >
            💃 Dancers <span class="badge badge-danger">New</span>
          </Link>

          <hr />
          <Link
            to="/curriculum-marketplace"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '10px' }}
          >
            📚 Curriculum Marketplace{' '}
            {/* <span class="badge badge-danger">New</span> */}
          </Link>

          <hr />

          {/* <Link
            to="/topDancer"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '5em' }}
          >
            💃 Top Dancers
          </Link> */}

          {/* <hr /> */}

          {/* <Button onClick={() => setIsDrawerOpen(false)}>
            <Link to="/advertise"> Promote Your Company</Link>
          </Button> */}
          {/* <Link to="/adRequest" style={{ color: 'white' }}> */}
          <Link
            to="/advertise"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black' }}
          >
            🚀 Promote Your Company
          </Link>

          <hr />
          {user?.email === 'airmeus_admin@gmail.com' ||
          user?.email === 'airmeus.team@gmail.com' ? (
            <div>
              <Button
                onClick={() => setIsDrawerOpen(false)}
                style={{ backgroundColor: 'green' }}
              >
                <Link to="/approvals">Ad Request Approvals</Link>
              </Button>
              <hr />
            </div>
          ) : (
            <></>
          )}

          {user?.email === 'airmeus_admin@gmail.com' ||
          user?.email === 'airmeus.team@gmail.com' ? (
            <div>
              <Button
                onClick={() => setIsDrawerOpen(false)}
                style={{ backgroundColor: 'green' }}
              >
                <Link to="/claims">User Claim Requests</Link>
              </Button>
              <hr />
            </div>
          ) : (
            <></>
          )}
          {/* <div>
            <Button
              onClick={() => setIsDrawerOpen(false)}
              style={{ backgroundColor: 'white' }}
            >
              <Link to="/blog">Blog Post</Link>
            </Button>
            <hr />
          </div> */}
          <div>
            {/* <Link
              to="/blog"
              onClick={() => setIsDrawerOpen(false)}
              style={{ color: 'black' }}
            >
              {' '}
              Blog Post
            </Link>

            <hr /> */}
          </div>
          <Link
            to="/studio"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '8em' }}
            state={{ loggedInUser: user }}
          >
            {' '}
            🥰 Studio
          </Link>

          <hr />
          <Link
            to="/what-is-airmeus"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '4em' }}
            state={{ loggedInUser: user }}
          >
            {' '}
            🙋🏽 What is Airmeus
          </Link>
          <hr />
          <Link
            to="/ambassador"
            onClick={() => setIsDrawerOpen(false)}
            style={{ color: 'black', paddingRight: '5em' }}
            state={{ loggedInUser: user }}
          >
            {' '}
            🤑 Ambassador
          </Link>

          <hr />

          {/* {user && safetyCouncilAccessGranted() ? ( */}
          {user &&
          (user?.email === 'airmeus.team@gmail.com' ||
            user?.safety_council_member === true) ? (
            <div>
              <Button
                onClick={() => setIsDrawerOpen(false)}
                style={{
                  // color: 'black',
                  paddingRight: '4em',
                  backgroundColor: 'green',
                }}
              >
                <Link to="/all-incident-reports">All Incident Reports</Link>
              </Button>
              <hr />
            </div>
          ) : (
            <></>
          )}

          {user && user.name ? (
            // <Button
            //   onClick={() => {
            //     setIsDrawerOpen(false);
            //     onLogout();
            //   }}
            // >
            <Link
              to="/login"
              style={{ color: 'black', paddingRight: '8em' }}
              onClick={() => {
                setIsDrawerOpen(false);
                onLogout();
              }}
            >
              🔑 Logout
            </Link>
          ) : (
            // </Button>
            <Link
              to="/login"
              onClick={() => setIsDrawerOpen(false)}
              style={{ color: 'black', paddingRight: '8em' }}
            >
              🔑 Login
            </Link>
          )}

          <hr />
          {/* <li>
            <Link to="/events"> Events</Link>
          </li> */}
          {/* </div> */}
        </Box>
      </Drawer>
    </>
  );
};

export default MuiDrawer;
