import React, { Fragment, Component, useState } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize

import { useContext, useEffect } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import NotificationContext from '../../context/notification/notificationContext';

const UserSearchItem = (props) => {
  const [disabled, setDisabled] = useState(false);

  const [authState] = useAuth();

  const { user } = authState;
  const userContext = useContext(UserContext);

  const { connectToUser } = userContext;

  const notificationContext = useContext(NotificationContext);

  const { sendNewConnectionNotification } = notificationContext;

  const { target_user, connections } = props;
  console.log('user from UserSearchItem ');
  console.log(target_user);
  console.log('connections from usersearchitem');
  console.log(connections);
  // useEffect(() => {
  //   console.log('getting myeventdetail');
  //     getCurrentUser();
  //   // eslint-disable-next-line
  // }, []);
  const [buttonText, setButtonText] = useState('Add Connection');

  const connectToUserFunction = (e) => {
    e.preventDefault();
    console.log('connecting to user');
    // console.log(user);
    setButtonText('Added');
    setDisabled(true);
    let obj = {
      userIdToConnectTo: target_user._id,
      current_user_id: user._id,
      // eventId: roomie_request.event,
      // accepted_user_id: roomie_request.requestor,
    };

    sendNewConnectionNotification(user._id, target_user._id, user.name);

    // let notification_obj = {
    //   current_user_id: user._id,
    //   // eventId: roomie_request.event,
    //   accepted_user_id: roomie_request.requestor,
    //   //will also need accepting users name
    // };

    connectToUser(obj);
    // sendSMSAlertForAcceptedRoommate(notification_obj);
    console.log('success????');
  };

  // this button will be shareable in v1.01 (this btn is for current user who already submitted a RR)
  const currentUsersButtonHid = <Fragment />;
  const connectButton = (
    <Fragment>
      <button
        onClick={connectToUserFunction}
        class="btn btn-primary my-2 my-sm-0"
        type="submit"
        disabled={disabled}
        style={{
          backgroundColor: 'rgb(1, 126, 252)',
        }}
      >
        {buttonText}
      </button>
    </Fragment>
  );
  const connectedButton = (
    <Fragment>
      <button
        // onClick={connectToUserFunction}
        // class="btn btn-primary my-2 my-sm-0"
        // type="submit"
        disabled={disabled}
      >
        Connected
      </button>
    </Fragment>
  );

  const nouser = <Fragment />;

  const renderOptions = () => {
    console.log('trying to render');
    if (!user || target_user._id === user._id) {
      console.log('no user found');
      return nouser;
    }
    console.log('connections from usersearchitem');
    console.log(connections);
    return connections.includes(target_user._id) ? <></> : connectButton;
  };
  let image;
  if (target_user.image) {
    image = target_user.image.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }

  return (
    <div>
      {/* <h1>hello</h1> */}
      {/* <h1>{user.name}</h1> */}

      <div className="card card-body bg-light mb-3">
        <div className="row">
          <div className="col">
            <img
              // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
              src={image}
              alt=""
              className="rounded-circle"
              height="150"
              width="150"
            />
          </div>
          <div className="col-lg-6 col-md-4 col-8">
            <h3>{target_user.name}</h3>
            {/* <p>
              <span>Rumbao</span>
            </p> */}
            {/* <p>
              {isEmpty(target_user.location) ? null : <span>{target_user.location}</span>}
            </p> */}
            <p>
              <span>{target_user.location}</span>
            </p>
            <Link
              to={`/user/profile/${target_user._id}`}
              className="btn btn-info"
              style={{
                color: 'white',
                backgroundColor: 'rgb(1, 126, 252)',
              }}
            >
              View Profile
            </Link>
            {/* {target_user._id === user._id
              ? currentUsersButtonHid
              : connectButton} */}

            {renderOptions()}
            <button
              // onClick={}
              class="btn btn-primary my-2 my-sm-0"
              type="submit"
              style={{
                backgroundColor: 'rgb(1, 126, 252)',
              }}
            >
              Roommate Request
            </button>
          </div>
          {/* <div className="col-md-4 d-none d-md-block">
            <h4>Skill Set</h4>
            <ul className="list-group">
              {profile.skills.slice(0, 4).map((skill, index) => (
                <li key={index} className="list-group-item">
                  <i className="fa fa-check pr-1" />
                  {skill}
                </li>
              ))}
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserSearchItem;
