import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import React, { useState } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import React, { useContext, useEffect, useState } from 'react';
import userContext from '../../context/user/userContext'; // might have to capitalize

function EditUserProfile() {
  const uContext = useContext(userContext);
  const { editProfile } = uContext;
  const [authState] = useAuth();

  const { user } = authState;
  const [show, setShow] = useState(false);
  console.log(`user name from edit is: ${user?.name}`);
  console.log(`user id from edit is: ${user?._id}`);
  const handleClose = () => {
    console.log('closing ');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //const { name } = user;

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    name: user?.name,
    dance_company: user?.dance_company,
    location: user?.location,
    about_you: user?.about_you,
    profile_image: '',
    instagram_name: user?.instagram_name,
    tiktok_name: user?.tiktok_name,
    website_name: user?.website_name,
  });

  const {
    name,
    dance_company,
    location,
    about_you,
    profile_image,
    instagram_name,
    tiktok_name,
    website_name,
  } = details;

  const user_id = user?._id;
  const updatedUser = {};
  updatedUser.user_id = user_id;
  // updatedUser.descr = descr;
  updatedUser.name = name;
  updatedUser.dance_company = dance_company;
  updatedUser.location = location;
  updatedUser.about_you = about_you;
  updatedUser.profile_image = profile_image;
  updatedUser.instagram_name = instagram_name;
  updatedUser.tiktok_name = tiktok_name;
  updatedUser.website_name = website_name;
  console.log('updatedUser is:');
  console.log(updatedUser);
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving updated user from frontend');

    if (details === '') {
      //   setAlert('Please fill in all fields', 'danger');
    } else {
      editProfile(updatedUser);
    }
  };
  console.log(`user from edit is: ${user}`);
  console.log('done ---');
  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{
          width: 'max-content',
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
        }}
      >
        Edit Profile
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={onChange}
                autoFocus
              />
              <Form.Label>Dance Company</Form.Label>
              <Form.Control
                id="dance_company"
                name="dance_company"
                type="text"
                value={dance_company}
                onChange={onChange}
                autoFocus
              />
              <Form.Label>Location</Form.Label>
              <Form.Control
                id="location"
                name="location"
                type="text"
                value={location}
                onChange={onChange}
                autoFocus
              />
              <Form.Label>Instagram</Form.Label>
              <Form.Control
                id="instagram_name"
                name="instagram_name"
                type="text"
                value={instagram_name}
                onChange={onChange}
                autoFocus
              />
              <Form.Label>Tiktok</Form.Label>
              <Form.Control
                id="tiktok_name"
                name="tiktok_name"
                type="text"
                value={tiktok_name}
                onChange={onChange}
                autoFocus
              />
              <Form.Label>Website</Form.Label>
              <Form.Control
                id="website_name"
                name="website_name"
                type="text"
                value={website_name}
                onChange={onChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>About You</Form.Label>
              <Form.Control
                id="about_you"
                name="about_you"
                as="textarea"
                value={about_you}
                onChange={onChange}
                rows={3}
                placeholder="tell us about yourself"
              />
              {/* <Form.Label>Profile Image</Form.Label> */}
              {/* <Form.Control
                id="profile_image"
                name="profile_image"
                type="file"
                value={profile_image}
                onChange={onChange}
                autoFocus
              /> */}
              {/* <Form.Control
                id="profile_image"
                name="profile_image"
                type="file"
                value={profile_image}
                onChange={onChange}
                autoFocus
              /> */}
            </Form.Group>
          </Form>
          {/* <form encType="multipart/form-data">
            <input
              id=""
              name="profile_image"
              type="file"
              value={profile_image}
              onChange={onChange}
            />
          </form> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default EditUserProfile;

//   render(<Example />);
