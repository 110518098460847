import React, { Component } from 'react';
import ConfirmedPrivateLesson from './ConfirmedPrivateLesson';

const ConfirmedPrivateLessons = (props) => {
  console.log('props fromn ConfirmedPrivateLessons');
  console.log(props);
  const { one_event_with_lessons } = props;
  return (
    <div style={{ marginTop: '16px' }}>
      {one_event_with_lessons?.confirmedPrivateLessons.map((lesson) => (
        <ConfirmedPrivateLesson
          lesson={lesson}
          event_name={one_event_with_lessons?.event_name}
        />
      ))}
    </div>
  );
};

export default ConfirmedPrivateLessons;
