import React, { Component } from 'react';

const WorkshopAttendees = (props) => {
  const { workshopAttendees } = props;
  console.log('workshopAttendees --');
  console.log(workshopAttendees);
  const mockImage =
    'https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg';
  const famousPeopleNames = [
    'Shirley Chisholm',
    'Maya Angelou',
    'Thurgood Marshall',
    "N'Jadaka",

    'Khalil Gibran',
    'Paulo Coelho',
    'Yuval Noah Harari',
    'George Washington Carver',
  ];
  return (
    <div>
      <br />

      <div>
        {workshopAttendees.length === 0 && (
          <div>
            <h1 style={{ fontSize: '22px', color: 'rgb(1, 126, 252)' }}>
              Attendees{' '}
              <span style={{ fontSize: '12px', color: 'gray' }}>example</span>
            </h1>
            <div className="row">
              <h5 style={{ fontSize: '12px', color: 'gray' }}>
                Once a user joins, we'll show the actual attendees. Be the first
                "actual" user 😏{' '}
              </h5>
            </div>
            <div className="vertical-attendee-container">
              <div className="container2">
                <div className="row">
                  {/* <div className="col"> */}
                  {famousPeopleNames.map((person) => (
                    <div className="col">
                      <div className="card bg-light">
                        <div className="">
                          <img
                            src={mockImage}
                            height="40"
                            width="40"
                            alt="user"
                          />
                        </div>
                        <div>
                          <h5 style={{ fontSize: '10px' }}>{person} </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {workshopAttendees.length > 0 && (
          <div>
            <h1 style={{ fontSize: '22px', color: 'rgb(1, 126, 252)' }}>
              Attendees{' '}
            </h1>
            <div className="vertical-attendee-container">
              <div className="container2">
                <div className="row">
                  {/* <div className="col"> */}
                  {workshopAttendees.map((attendee) => (
                    <div className="col">
                      <div className="card bg-light">
                        <div className="">
                          <img
                            src={mockImage}
                            height="40"
                            width="40"
                            alt="user"
                          />
                        </div>
                        <div>
                          <h5 style={{ fontSize: '16px' }}>{attendee.name} </h5>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkshopAttendees;
