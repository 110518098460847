import React, { Component, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';

import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';
import ListSelectedLocations from './ListSelectedLocations';

const AddNewTour = (props) => {
  const { instructor_id, instructor_name } = props;
  const [addNewTourClicked, setAddNewTourClicked] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [filterDetails, setFilterDetails] = useState({
    // country: countries[0],
    country: 'United States',
    state: 'none',
    city: 'none',
  });

  const { city, state, country } = filterDetails;
  const listOfStates = ListOfStates;

  const listOfStatesAndCities = ListOfStatesAndCities;

  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setFilterDetails({
      ...filterDetails,
      city: cities ? cities[0] : '',
      state: state,
    });
  };
  const [tourName, setTourName] = useState({
    tour_name: '',
  });
  const { tour_name } = tourName;
  const onChange = (e) =>
    setTourName({ ...tourName, [e.target.name]: e.target.value });

  return (
    <div>
      <Button
        onClick={() => {
          setAddNewTourClicked(!addNewTourClicked);
          setShow(!show);
        }}
        style={{
          backgroundColor: 'white',
          background: 'white',
          color: 'rgb(1, 126, 252)',

          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',
        }}
      >
        Add Tour
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Start New Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="name">Tour Name</label>
            <input
              id="tour_name"
              type="text"
              name="tour_name"
              value={tour_name}
              onChange={onChange}
              required
              // required
              // onInvalid="add name"
            />
          </div>
          <div>
            <h5>Select state</h5>
          </div>
          <Form.Group controlId="formBasicSelect">
            <Form.Label>State</Form.Label>
            <Form.Control
              id="state"
              name="state"
              as="select"
              value={state}
              required
              onChange={(e) => {
                setFilterDetails({
                  ...filterDetails,
                  [e.target.name]: e.target.value,
                });
                let cities = selectedCities(e.target.value);

                console.log(filterDetails);
                //   props.handleLocationChange(e.target.value, state, 'all');
              }}
            >
              {listOfStates.map((state) => (
                <option value={state}>{state}</option>
              ))}
              {/* {countries?.map((state) => (
                <option value={state}>{state}</option>
              ))} */}
            </Form.Control>
          </Form.Group>
          {/* <br /> */}
          {/* <div className="row">
            <div className="col">
              <Button>Add Another State</Button>
            </div>
            <div className="col">
              <Button>Done Adding States</Button>
            </div>
          </div> */}

          <div>
            {/* list selected states/cities */}

            <ListSelectedLocations
              state={state}
              instructor_id={instructor_id}
              instructor_name={instructor_name}
              tour_name={tour_name}
            />
          </div>

          {/* <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
          <div>
            <h5>Select Dance Companies</h5>
          </div> */}

          {/* <div>
            <h5>Select Dates</h5>
          </div> */}
        </Modal.Body>
      </Modal>

      {/* on submission take user to view only the newly created tour and nudge them to share to socials */}
    </div>
  );
};

export default AddNewTour;
