import React from 'react';
import PropTypes from 'prop-types';

import {
  GET_USERS_ONE_AT_A_TIME,
  USER_ERROR,
  LIKE_USER,
  LIKE_COMPANY,
  PASS_ON_USER,
  GET_USER,
  GET_ONE_USER,
  EDIT_USER_PROFILE,
  GET_JOINED_EVENTS,
  ACCEPT_ROOMMATES_FOR_JOINED_EVENTS,
  GET_EVENTS_AND_ROOMMATES_FOR_USER,
  SEND_ROOMMATE_REQUEST_ACCEPTED_SMS,
  GET_USERS_FOR_SEARCH_CRITERIA,
  ACCEPT_CONNECTION_REQUEST,
  CONNECT_TO_USER,
  GET_CURRENT_USER,
  GET_CURRENT_USER_CONNECTIONS,
  GET_CONNECTIONS_FOR_USER,
  ADD_USERS_AS_ROOMMATES,
  ADD_ROOM_NUMBER,
  CREATE_NEW_TRIP_AND_ADD_ROOMMATES,
  EDIT_USER_PROFILE_IMAGE,
  GET_HOST,
  GET_USERS_FROM_CITY,
  GET_SHARKTANK_PARTICIPANTS,
  JOIN_SHARKTANK,
  SEND_VERIFICATION_AND_CLAIM_ARTIST,
  SEND_VERIFICATION_AND_CLAIM_STUDIO,
  GET_USER_CLAIMS,
  DECISION_CLAIM,
  GET_USER_REFERRALS,
  SAVE_STYLE,
  GET_MY_PRIVATE_LESSONS,
  ADD_TO_SAFETY_COUNCIL,
  GET_USER_EVENTS,
  SEND_VERIFICATION_FOR_EVENT_OWNERSHIP,
  ADD_BUSINESS_DETAILS,
  GET_ONE_SHARKTANK_PARTICIPANT,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_USER_EVENTS:
      return {
        ...state,
        user_events2: action.payload,
        loading: false,
      };
    case GET_USERS_ONE_AT_A_TIME:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USER_REFERRALS:
      return {
        ...state,
        userReferrals: action.payload[0],
        ambassadorRevenueShareAmount: action.payload[1],
        loading: false,
      };
    case GET_MY_PRIVATE_LESSONS:
      return {
        ...state,
        myPrivateLessons: action.payload,

        loading: false,
      };
    case ADD_TO_SAFETY_COUNCIL:
      return {
        ...state,
        user: action.payload,

        loading: false,
      };
    case ADD_BUSINESS_DETAILS:
      return {
        ...state,
        user: action.payload,

        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        // user: action.payload,
        user: action.payload[0],
        rankings: action.payload[1],
        dancerWorldRanking: action.payload[2],
        loading: false,
      };
    case GET_ONE_USER:
      return {
        ...state,
        one_user: action.payload,
        loading: false,
      };
    case GET_HOST:
      return {
        ...state,
        host: action.payload,
        loading: false,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        current_user: action.payload,
        loading: false,
      };
    case LIKE_USER:
      return {
        ...state,
        user: action.payload,
        // loading: false,//we probably should load a new user to be liked in a next_user attribute
        //that may be done from the State file
        //after we like a user, how do we want the state to change?
      };
    case LIKE_COMPANY:
      return {
        ...state,
        liked_company: action.payload,
        // loading: false,//we probably should load a new user to be liked in a next_user attribute
        //that may be done from the State file
        //after we like a user, how do we want the state to change?
      };
    case GET_SHARKTANK_PARTICIPANTS:
      return {
        ...state,
        sharktank_partipants: action.payload,
        sharktank_loading: false,
        // loading: false,//we probably should load a new user to be liked in a next_user attribute
        //that may be done from the State file
        //after we like a user, how do we want the state to change?
      };
    case GET_ONE_SHARKTANK_PARTICIPANT:
      return {
        ...state,
        one_sharktank_participant: action.payload,
        sharktank_loading: false,
        // loading: false,//we probably should load a new user to be liked in a next_user attribute
        //that may be done from the State file
        //after we like a user, how do we want the state to change?
      };
    case JOIN_SHARKTANK:
      return {
        ...state,
        joined_sharktank: action.payload,
        // loading: false,//we probably should load a new user to be liked in a next_user attribute
        //that may be done from the State file
        //after we like a user, how do we want the state to change?
      };
    case PASS_ON_USER:
      return {
        ...state,
        users: action.payload, //should be one less
        // loading: false,
      };
    case EDIT_USER_PROFILE:
      return {
        ...state,
        updated_profile: action.payload, //should be one less
        loading: false,
      };
    case EDIT_USER_PROFILE_IMAGE:
      return {
        ...state,
        updated_profile_image: action.payload, //should be one less
        loading: false,
      };

    // case GET_JOINED_EVENTS:
    //   return {
    //     ...state,
    //     joined_events: action.payload,
    // loading: false,
    //   };
    case GET_JOINED_EVENTS:
      return {
        ...state,
        joined_events: action.payload.events,
        trips: action.payload.trips,
        loading: false,
        loading2: false,
      };
    case ACCEPT_ROOMMATES_FOR_JOINED_EVENTS:
      return {
        ...state,
        //users_roommates_for_events: action.payload,

        ///// CHECK TO SEE IF YOU NEED THIS BCUZ
        //IT MIGHT OVERWRITE/DELETE DATA YOU NEED FOR GET_EVENTS_AND_ROOMMATES_FOR_USER
        loading: false,
      };
    case SEND_ROOMMATE_REQUEST_ACCEPTED_SMS:
      return {
        ...state,
        //MAYBE RETURN A MSG SAYING NOTIF SENT?
      };

    case GET_EVENTS_AND_ROOMMATES_FOR_USER:
      return {
        ...state,
        users_roommates_for_events: action.payload,
        //users_roommates_for_events: action.payload.roommates,
        //users_roommates_for_events: action.payload.trip_details,
        // trip_owner_id: action.payload.trip_owner.id
        // trip_owner_id: '123',
        loading: false,
      };

    case GET_USERS_FOR_SEARCH_CRITERIA:
      return {
        ...state,
        userSearchResults: action.payload,
        loading: false,
      };
    case GET_USERS_FROM_CITY:
      return {
        ...state,
        usersFromCity: action.payload,
        loading: false,
      };
    case USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ACCEPT_CONNECTION_REQUEST:
      return {
        ...state,
        newConnection: action.payload,

        ///// CHECK TO SEE IF YOU NEED THIS BCUZ
        //IT MIGHT OVERWRITE/DELETE DATA YOU NEED FOR GET_EVENTS_AND_ROOMMATES_FOR_USER
        loading: false,
      };
    case CONNECT_TO_USER:
      return {
        ...state,
        newConnection: action.payload,

        ///// CHECK TO SEE IF YOU NEED THIS BCUZ
        //IT MIGHT OVERWRITE/DELETE DATA YOU NEED FOR GET_EVENTS_AND_ROOMMATES_FOR_USER
        loading: false,
      };

    case GET_CURRENT_USER_CONNECTIONS:
      return {
        ...state,
        connections: action.payload,
        loading: false,
      };

    case GET_CONNECTIONS_FOR_USER:
      return {
        ...state,
        user_connections: action.payload,
        loading: false,
      };
    case ADD_USERS_AS_ROOMMATES:
      return {
        ...state,
        users_added_to_roommates: action.payload,
        loading: false,
      };

    case ADD_ROOM_NUMBER:
      return {
        ...state,
        room_number_added: action.payload,
        loading: false,
      };

    case CREATE_NEW_TRIP_AND_ADD_ROOMMATES:
      return {
        ...state,
        new_trip: action.payload,
        loading: false,
      };
    case SEND_VERIFICATION_AND_CLAIM_ARTIST:
      return {
        ...state,
        user_verification: action.payload,
        loading: false,
      };
    case SEND_VERIFICATION_AND_CLAIM_STUDIO:
      return {
        ...state,
        user_verification: action.payload,
        loading: false,
      };
    case SEND_VERIFICATION_FOR_EVENT_OWNERSHIP:
      return {
        ...state,
        user_verification: action.payload,
        loading: false,
      };
    case GET_USER_CLAIMS:
      return {
        ...state,
        user_claims: action.payload,
        loading: false,
      };
    case DECISION_CLAIM:
      return {
        ...state,
        user_claims: state.user_claims.filter(
          (claim) => claim._id !== action.payload
        ),
        loading: false,
      };

    default:
      return state;
  }
};
