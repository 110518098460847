import React, { useReducer } from 'react';
import axios from 'axios';
import blogContext from './blogContext';
import blogReducer from './blogReducer';
import { GET_BLOG_POST, BLOG_ERROR, POST_BLOG_POST } from '../types';

const BlogState = (props) => {
  const initialState = {
    blog_post: {},
    error: null,
  };

  const [state, dispatch] = useReducer(blogReducer, initialState);

  const getBlogPost = async (blog_id) => {
    try {
      const res = await axios.get(`/api/blog/${blog_id}`);
      console.log('res below from blogstate.js');
      console.log(res);

      dispatch({
        type: GET_BLOG_POST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: BLOG_ERROR,
        payload: err,
      });
    }
  };
  const postBlogPost = async (obj) => {
    try {
      const res = await axios.post(`/api/blog`, obj);
      console.log('res below from blogstate.js');
      console.log(res);

      dispatch({
        type: POST_BLOG_POST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: BLOG_ERROR,
        payload: err,
      });
    }
  };

  return (
    <blogContext.Provider
      value={{
        blog_post: state.blog_post,

        getBlogPost,
        postBlogPost,
      }}
    >
      {props.children}
    </blogContext.Provider>
  );
};

export default BlogState;
