//consider renaming to potential_mate to differentiate btw user
import React, { Fragment, useContext, useEffect } from 'react';
import NotificationContext from '../../context/notification/notificationContext';
import Notification from './Notification';
import PropTypes from 'prop-types';
import { useAuth } from '../../context/auth/AuthState';
import { HTML5_FMT } from 'moment';

const MyNotifications = () => {
  const [authState] = useAuth();
  const { user } = authState;

  const notificationContext = useContext(NotificationContext);

  const { getUsersNotifications, user_notifications } = notificationContext;

  useEffect(() => {
    console.log('getting users from useEffect from User Component');
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    if (user) {
      getUsersNotifications(user._id);
    }
    console.log('user_notifications');
    console.log(user_notifications);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        {!user_notifications?.length > 0 && (
          <h5>Currently there are no noticications to show</h5>
        )}
      </div>
      {user_notifications.map((notif) => (
        <Notification key={notif?._id} notif={notif} />
      ))}
      {/* <h1>hi</h1> */}
    </div>
  );
};

// UseMyNotificationsr.propTypes = {};

export default MyNotifications;
