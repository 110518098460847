import React, { Component, useContext, useEffect } from 'react';
import EventDetail from './EventDetail';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import EventDetailBZWC from './EventDetailBZWC';

const BrazilianZoukWorldChampionships = () => {
  const eventContext = useContext(EventContext);
  const { getOneEvent, event } = eventContext;

  //load bzwc event (will need to update codebase after)
  useEffect(() => {
    getOneEvent('64e140ab9af736c6472a1929');
    // getOneEvent('649f6a70a11c00733e688233');
    console.log('loading from bzwc component');
    // eslint-disable-next-line
  }, []);
  //   event id in prod : 64e140ab9af736c6472a1929
  return (
    <div>
      <EventDetailBZWC
        event={event}
        page="subevent"
        dance_reaction_elected={true}
      />
    </div>
  );
};
export default BrazilianZoukWorldChampionships;
