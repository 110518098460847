import React, { useReducer } from 'react';
import axios from 'axios';

import ReactionContext from './reactionContext';
import ReactionReducer from './reactionReducer';

import {
  GET_ALL_REACTIONS,
  GET_REACTIONS_FOR_DANCER,
  REACTION_ERROR,
  POST_REACTION_FOR_DANCER,
  ADD_DANCER,
} from '../types';

const ReactionState = (props) => {
  const initialState = {
    all_reactions: {},
    reactionsForDancer: [],
    reactionFromUser: {},
    error: null,
  };
  const [state, dispatch] = useReducer(ReactionReducer, initialState);

  const getAllReactions = async (event_id) => {
    try {
      console.log('running getAllReactions()');
      const res = await axios.get(`/api/reactions/reactions-by-category`);
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_ALL_REACTIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: REACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getReactionsForDancer = async (reactionObj) => {
    try {
      console.log('running getReactionsForDancer()');
      console.log('reactionObj from getReactionsForDancer');
      console.log(reactionObj);
      const res = await axios.get(
        `/api/reactions/reactions-for-dancer`,
        reactionObj
      );
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_REACTIONS_FOR_DANCER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: REACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const postReactionForDancer = async (reactionObj) => {
    try {
      console.log('running postReactionForDancer()');
      console.log('reactionObj from getReactionsForDancer');
      console.log(reactionObj);
      const res = await axios.post(`/api/reactions/new-reaction`, reactionObj);
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: POST_REACTION_FOR_DANCER,
        payload: res.data,
      });
      getAllReactions();
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: REACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const addDancer = async (dancerObj) => {
    try {
      console.log('running addDancer()');
      console.log('dancerObj from addDancer');
      console.log(dancerObj);
      const res = await axios.post(`/api/reactions/new-dancer`, dancerObj);
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: ADD_DANCER,
        payload: res.data,
      });
      getAllReactions();
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: REACTION_ERROR,
        payload: err.response.msg,
      });
    }
  };

  return (
    <ReactionContext.Provider
      value={{
        //might be a problem 8.18.23
        all_reactions: Object.entries(state.all_reactions),
        reactionsForDancer: state.reactionsForDancer,
        reactionFromUser: state.reactionFromUser,
        new_dancer: state.new_dancer,

        getAllReactions,
        getReactionsForDancer,
        postReactionForDancer,
        addDancer,
      }}
    >
      {props.children}
    </ReactionContext.Provider>
  );
};

export default ReactionState;
