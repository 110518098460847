import React, { Fragment, useContext, useEffect, useState } from 'react';
import WorkshopDetailPopup from './WorkshopDetailPopup';
import WorkshopDetails from './WorkshopDetails';
const moment = require('moment');
moment().format();

const WorkshopsTestValues2 = (props) => {
  const returnDateAndRoom = (date) => {
    let dateAndRoomArr = date.split('-');
    console.log('dateAndRoomArr');
    console.log(dateAndRoomArr);

    let splitDate = `${dateAndRoomArr[0]}-${dateAndRoomArr[1]}-${
      dateAndRoomArr[2]
    }`;
    let splitDate2 = `${dateAndRoomArr[2]}-${dateAndRoomArr[0]}-${
      dateAndRoomArr[1]
    }`;
    console.log('splitDate2 is:');
    console.log(splitDate2);
    // let obj = {
    //   // dateFormatted: `${dateAndRoomArr[0]}-${dateAndRoomArr[1]}-${
    //   //   dateAndRoomArr[2]
    //   // }`,
    //   date_formatted: moment(splitDate).format('MMM Do YYYY'),
    //   roomNumber: `${dateAndRoomArr[3]}`,
    // };

    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    console.log('my month is:');

    console.log(dateAndRoomArr[0]);

    let newDate = new Date(splitDate2);
    let month = months[newDate.getMonth()];
    var daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

    console.log('newDate is');
    console.log(newDate);

    let day = daysOfWeek[newDate.getDay()];

    let obj = {
      // dateFormatted: `${dateAndRoomArr[0]}-${dateAndRoomArr[1]}-${
      //   dateAndRoomArr[2]
      // }`,

      // date_formatted: moment(splitDate).format('MMM Do YYYY'),
      date_formatted: `${day}, ${month} ${dateAndRoomArr[1]}`,
      roomNumber: `${dateAndRoomArr[3]}`,
    };
    console.log('obj from WTV');
    console.log(obj);

    return obj;
  };

  let date = props?.workshops[0];
  console.log('date from workshops');
  console.log(date);
  //TAKE OFF ROOM
  let date_formatted = moment(date).format('MMM Do YYYY');
  console.log('props from wtv in WorkshopsTestValues2');
  console.log(props);
  let dateFormatted = returnDateAndRoom(date);
  console.log('dateFormatted --');
  console.log(dateFormatted);
  return (
    <div>
      <div className="row" style={{ marginTop: '10px', marginLeft: '10px' }}>
        <h1>Room {dateFormatted.roomNumber} </h1>
        {/* <h5>{props.workshops[0]}</h5> */}
        <h5
          style={{
            border: '1px',
            // backgroundColor: 'purple',
            borderRadius: '0.25rem',
            borderStyle: 'solid',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderColor: 'rgb(1, 126, 252)',
            marginBottom: '10px',
          }}
        >
          {dateFormatted.date_formatted} - Room {dateFormatted.roomNumber}
        </h5>

        {props.workshops[1].map((workshop) => (
          // <div>
          <div className="card-horizontal-menu card-block mx-2">
            <WorkshopDetails workshop={workshop} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkshopsTestValues2;
