import React, { Component, useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import PrivateLessonRequests from './PrivateLessonRequests';
import ConfirmedPrivateLessons from '../instructor/ConfirmedPrivateLessons';

const CalendarEventsUpdated = (props) => {
  console.log('props from CalendarEventsUpdated');
  console.log(props);
  //artist.events will be coming from props
  //we will have a toggle for [ workshops | confirmed private lessons | private lesson Requests ]

  const [eventClicked, setEventClicked] = useState(false);
  const [showPrivateLessonRequests, setShowPrivateLessonRequests] = useState(
    false
  );
  const [
    showConfirmedPrivateLessons,
    setShowConfirmedPrivateLessons,
  ] = useState(false);
  return (
    <div>
      <section
        id="introduction-to-everything"
        onClick={() => setEventClicked(!eventClicked)}
      >
        <div className="row">
          <div className="col-md-12">
            <div
              className="card card-body mb-3"
              style={{
                borderWidth: 3,
                borderStyle: 'solid',
                borderColor: 'black',
              }}
            >
              <div className="row text-center">
                <div className="col m-auto">
                  <h3 style={{ color: 'black' }}>{props.event.event_name}</h3>

                  {/* render out Workshop Button and workshops and then private lesson reqauests */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{' '}
      {eventClicked && (
        <div className="row">
          {/* we may bring this back later -- jan 28th we commented out */}

          {/* <div className="col">
            <Button
              onClick={() => console.log('show workshops')}
              style={{
                width: '110px',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
              }}
            >
              Workshops
            </Button>
          </div> */}

          {/* got rid of this bcuz we are showing confirmed lessons differently now */}
          {/* <div className="col">
            <Button
              onClick={() =>
                setShowConfirmedPrivateLessons(!showConfirmedPrivateLessons)
              }
              style={{
                width: '220px',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
              }}
            >
              Confirmed Private Lessons
              
            </Button>
          </div> */}
          <div className="col">
            <Button
              onClick={() =>
                setShowPrivateLessonRequests(!showPrivateLessonRequests)
              }
              style={{
                width: '220px',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
              }}
            >
              Private Lesson Requests
              {' - '}
              {props?.event?.privateLessonRequests.length}
            </Button>
          </div>
        </div>
      )}
      {showPrivateLessonRequests &&
        eventClicked && (
          <div>
            <PrivateLessonRequests
              event_id={props.event.event_id}
              event_name={props.event.event_name}
              privatelessonRequests={props.event.privateLessonRequests}
            />
          </div>
        )}
      {showConfirmedPrivateLessons &&
        eventClicked && (
          <div>
            <ConfirmedPrivateLessons
              confirmedPrivateLessons={props.event.confirmedPrivateLessons}
            />
          </div>
        )}
    </div>
  );
};

export default CalendarEventsUpdated;
