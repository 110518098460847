import React, { Component, useContext, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import StudioContext from '../../context/studio/studioContext';

const StudioSelectedDisplayRoles = (props) => {
  const studioContext = useContext(StudioContext);

  const { addNewStudio, newStudio, loading } = studioContext;

  let {
    selectedStudio,
    setRoles,
    roles,
    setPrimaryStyle,
    primaryStyle,
    secondaryStyles,
    setSecondaryStyles,
  } = props;
  //   const [roles, setRoles] = useState(['student']);
  let role_array = [];
  let secondaryStyleArray = [];
  const onChange3 = (e) => {
    if (roles.includes(e.target.id)) {
      console.log('removing role');
      role_array = roles;
      role_array = role_array.filter((item) => item !== e.target.id);
      setRoles(role_array);
    } else {
      console.log('adding role');
      role_array.push(e.target.id);
      let role = e.target.id;
      setRoles([...roles, role]);
    }

    const member_set = new Set(role_array);
    const uniqueMembers = [...member_set];
    console.log('role_array');
    console.log(role_array);
  };
  const handleSecondaryStyles = (e) => {
    if (secondaryStyles?.includes(e.target.id)) {
      console.log('removing secpndary style');
      secondaryStyleArray = secondaryStyles;
      secondaryStyleArray = secondaryStyleArray.filter(
        (item) => item !== e.target.id
      );
      setSecondaryStyles(secondaryStyleArray);
    } else {
      console.log('adding secpndary style');
      secondaryStyleArray.push(e.target.id);
      let style = e.target.id;
      setSecondaryStyles([...secondaryStyles, style]);
    }

    // const member_set = new Set(secondaryStyleArray);
    // const uniqueMembers = [...member_set];
    // console.log('secondaryStyleArray');
    // console.log(secondaryStyleArray);
  };

  //   const onSubmit2 = (e) => {
  //     e.preventDefault();

  //     setDetails({
  //       ...details,
  //       [dance_company_id]: 'help',
  //       // ['city']: studio?.city,
  //       // ['state']: studio?.state,
  //     });

  //     const newObj = {
  //       dance_company_id: studio._id,
  //       dance_company_city: studio.city,
  //       dance_company_state: studio.state,
  //       dance_company_name: studio.name,
  //       roles: roles,
  //       // roles: role_array,
  //     };
  //     console.log('newObj');
  //     console.log(newObj);

  //     addNewMember(newObj);
  //   };
  const stylesArray = ['bachata', 'salsa', 'kizomba', 'zouk'];
  const [selectedStyle, setSelectedStyle] = useState('bachata');

  const handleStyleChange = (style) => {
    console.log('changed style');
    console.log(style);
    setPrimaryStyle(style);
    // setSelectedStyle(style);
    // setDetails({ ...details, lessonTime: time });
  };
  return (
    <div>
      <h5>
        What are your role(s) at{' '}
        <span
          className="row"
          style={{
            background: 'white',

            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
            borderWidth: '1px',
            borderRadius: '6px',

            fontSize: '24px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            textAlign: 'center',
            width: 'max-content',
            marginLeft: '16px',
          }}
        >
          {selectedStudio?.name}
          {newStudio?.name}
        </span>{' '}
      </h5>
      <div className="form-container">
        <form onSubmit={console.log('boom')}>
          {/* <form onSubmit={onSubmit2}> */}
          <div className="form-group">
            <label htmlFor="">Role</label>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="student"
                id="student"
                value="student"
                onChange={onChange3}
                // checked={roles.includes('instructor')}
              />

              <label class="form-check-label" for="student">
                student
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="instructor"
                id="instructor"
                value="instructor"
                onChange={onChange3}
                checked={roles.includes('instructor')}
              />

              <label class="form-check-label" for="instructor">
                instructor
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="staff"
                id="staff"
                value="staff"
                onChange={onChange3}
              />

              <label class="form-check-label" for="staff">
                staff
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="director"
                id="director"
                value="director"
                onChange={onChange3}
              />

              <label class="form-check-label" for="director">
                director
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="owner"
                id="owner"
                value="owner"
                onChange={onChange3}
              />

              <label class="form-check-label" for="owner">
                owner
              </label>
            </div>
          </div>

          {/* select styles */}
          <hr />
          <br />
          <h5>What is your primary style of dance?</h5>
          {/* <div className="form-group">
            {['bachata', 'salsa','kizomba','zouk'].map((style) => (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="style"
                  id="style"
                  value="style"
                  onChange={onChange3}
                  checked={roles.includes('style')}
                />

                <label class="form-check-label" for="style">
                  style
                </label>
              </div>
            ))}
          </div> */}

          <div className="col">
            {[DropdownButton].map((DropdownType, index) => (
              <DropdownType
                as={ButtonGroup}
                key={index}
                id={`dropdown-button-drop-${index}`}
                size="sm"
                variant="secondary"
                title={primaryStyle}
                // title="10:40"
                onSelect={handleStyleChange}
                // onChange={onChange}
              >
                {stylesArray.map((style) => (
                  <Dropdown.Item eventKey={style}>{style}</Dropdown.Item>
                ))}
              </DropdownType>
            ))}
          </div>
          <hr />
          <br />
          <h5>What are your secondary styles: </h5>
          {/* checkbox */}
          <div className="form-group">
            {['bachata', 'salsa', 'kizomba', 'zouk'].map((style) => (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="style"
                  id={style}
                  value="style"
                  onChange={handleSecondaryStyles}
                  //   checked={roles.includes('style')}
                />

                <label class="form-check-label" for="style">
                  {style}
                </label>
              </div>
            ))}
          </div>
          {/* <input type="submit" value="Add Member" class="btn btn-secondary" /> */}
        </form>
      </div>
    </div>
  );
};

export default StudioSelectedDisplayRoles;
