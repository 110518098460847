import React from 'react';
// import { Document } from 'react-pdf';
// import { pdfjs } from 'react-pdf';

// import privacy-policy from
import privacyPolicy from '../business-documents/privacy-policy.txt';
const PrivacyPolicy = () => {
  //   const gotText = privacyPolicy;
  //   pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //     'pdfjs-dist/build/pdf.worker.min.js',
  //     import.meta.url
  //   ).toString();

  return (
    <div>
      {/* <Document file="../../public/privacy-policy.pdf" /> */}
      {/* <textarea name="tesrt" id="tst_id" cols="30" rows="10">
        {privacyPolicy}
      </textarea> */}
      <h1>Privacy Policy</h1>

      <p>
        Protecting your private information is our priority. This Statement of
        Privacy applies to Airmeus , and Gilbert Brooks and governs data
        collection and usage. For the purposes of this Privacy Policy, unless
        otherwise noted, all references to Gilbert Brooks include
        www.airmeus.com .The Airmeus application is an information technology
        application. By using the Gilbert Brooks application you consent to the
        data practices described in this statement. Collection of your Personal
        Information In order to better provide you with products and services
        offered, Gilbert Brooks may collect personally identifiable information,
        such as your:
      </p>
      <p>First and Last Name E-mail Address Phone Number</p>

      <h2>Age</h2>
      <p>
        We do not collect any personal information about you unless you
        voluntarily provide it to us. However, you may be required to provide
        certain personal information to us when you elect to use certain
        products or services. These may include: (a) registering for an account;
        (b) entering a sweepstakes or contest sponsored by us or one of our
        partners; (c) signing up for special offers from selected third parties;
        (d) sending us an email message; (e) submitting your credit card or
        other payment information when ordering and purchasing products and
        services. To wit, we will use your information for, but not limited to,
        communicating with you in relation to services and/or products you have
        requested from us. We also may gather additional personal or
        non-personal information in the future.
      </p>

      <h2>Use of your Personal Information</h2>

      <p>
        Gilbert Brooks collects and uses your personal information to operate
        and deliver the services you have requested.
      </p>
      <p>
        Gilbert Brooks may also use your personally identifiable information to
        inform you of other products or services available from Gilbert Brooks
        and its affiliates.
      </p>

      <h2>Sharing Information with Third Parties</h2>

      <p>
        Gilbert Brooks does not sell, rent or lease its customer lists to third
        parties.
      </p>
      <p>
        Gilbert Brooks may, from time to time, contact you on behalf of external
        business partners about a particular offering that may be of interest to
        you. In those cases, your unique personally identifiable information
        (e-mail, name, address, telephone number) is transferred to the third
        party.
      </p>
      <p>
        Gilbert Brooks may share data with trusted partners to help perform
        statistical analysis, send you email or postal mail, provide customer
        support, or arrange for deliveries. All such third parties are
        prohibited from using your personal information except to provide these
        services to
      </p>
      <p>
        Gilbert Brooks, and they are required to maintain the confidentiality of
        your information.
      </p>
      <p>
        Gilbert Brooks may disclose your personal information, without notice,
        if required to do so by law or in the good faith belief that such action
        is necessary to: (a) conform to the edicts of the law or comply with
        legal process served on Gilbert Brooks or the site; (b) protect and
        defend the rights or property of Gilbert Brooks ; and/or (c) act under
        exigent circumstances to protect the personal safety of users of Gilbert
        Brooks , Or the public.
      </p>

      <h2>Opt-Out of Disclosure of Personal Information to Third Parties</h2>

      <p>
        In connection with any personal information we may disclose to a third
        party for a business purpose, you have the right to know:
      </p>
      <p>
        - The categories of personal information that we disclosed about you for
        a business purpose. You have the right under the California Consumer
        Privacy Act of 2018 (CCPA) and certain other privacy and data protection
        laws, as applicable, to opt-out of the disclosure of your personal
        information. If you exercise your right to opt-out of the disclosure of
        your personal information, we will refrain from disclosing your personal
        information, unless you subsequently provide express authorization for
        the disclosure of your personal information. To opt-out of the
        disclosure of your personal information, email airmeus.team@gmail.com
        with your stated intent.
      </p>

      <h2>Right to Deletion</h2>

      <p>
        Subject to certain exceptions set out below, on receipt of a verifiable
        request from you, we will:
      </p>
      <p> - Delete your personal information from our records; and</p>
      <p>
        {' '}
        - Direct any service providers to delete your personal information from
        their records.
      </p>

      <h2>
        Please note that we may not be able to comply with requests to delete
        your personal information if it is necessary to:
      </h2>

      <ul>
        <li>
          {' '}
          - Complete the transaction for which the personal information was
          collected, fulfill the terms of a written warranty or product recall
          conducted in accordance with federal law, provide a good or service
          requested by you, or reasonably anticipated within the context of our
          ongoing business relationship with you, or otherwise perform a
          contract between you and us;
        </li>
        <li>
          {' '}
          - Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity; or prosecute those responsible for
          that activity;
        </li>
        <li>
          {' '}
          - Debug to identify and repair errors that impair existing intended
          functionality;
        </li>
        <li>
          {' '}
          - Exercise free speech, ensure the right of another consumer to
          exercise his or her right of free speech, or exercise another right
          provided for by law;
        </li>
        <li>
          {' '}
          - Comply with the California Electronic Communications Privacy Act;
        </li>
        <li>
          {' '}
          - Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when our deletion of the
          information is likely to render impossible or seriously impair the
          achievement of such research, provided we have obtained your informed
          consent;
        </li>
        <li>
          {' '}
          - Enable solely internal uses that are reasonably aligned with your
          expectations based on your relationship with us;
        </li>
        <li> - Comply with an existing legal obligation; or</li>

        <li>
          {' '}
          - Otherwise use your personal information, internally, in a lawful
          manner that is compatible with the context in which you provided the
          information.
        </li>
      </ul>
      <h2>Children Under Thirteen</h2>
      <p>
        Gilbert Brooks does not knowingly collect personally identifiable
        information from children under the age of thirteen. If you are under
        the age of thirteen, you must ask your parent or guardian for permission
        to use this application
      </p>
      <h2>Opt-Out & Unsubscribe from Third Party Communications</h2>
      <p>
        We respect your privacy and give you an opportunity to opt-out of
        receiving announcements of certain information. Users may opt-out of
        receiving any or all communications from third-party partners of Gilbert
        Brooks by contacting us here: - Email: airmeus.team@gmail.com
      </p>
      <h2>E-mail Communications</h2>
      <p>
        From time to time, Gilbert Brooks may contact you via email for the
        purpose of providing announcements, promotional offers, alerts,
        confirmations, surveys, and/or other general communication. In order to
        improve our Services, we may receive a notification when you open an
        email from Gilbert Brooks or click on a link therein. If you would like
        to stop receiving marketing or promotional communications via email from
        Gilbert Brooks , you may opt out of such communications by clicking on
        the UNSUBSCRIBE button.
      </p>
      <h2>External Data Storage Sites</h2>
      <p>
        We may store your data on servers provided by third party hosting
        vendors with whom we have contracted.
      </p>
      <h2>Changes to this Statement</h2>
      <p>
        Gilbert Brooks reserves the right to change this Privacy Policy from
        time to time. We will notify you about significant changes in the way we
        treat personal information by sending a notice to the primary email
        address specified in your account, by placing a prominent notice on our
        application , and/or by updating any privacy information. Your continued
        use of the application and/or Services available after such
        modifications will constitute your: (a) acknowledgment of the modified
        Privacy Policy; and (b) agreement to abide and be bound by that Policy.
      </p>
      <p>Effective as of August 16, 2023</p>
    </div>
  );
};

export default PrivacyPolicy;
