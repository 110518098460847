import React, { Component } from 'react';

const FeedbackDetail = (props) => {
  let { feedback } = props;
  return (
    <div
      style={{
        backgroundColor: 'rgb(1, 126, 252)',
        color: 'white',
        borderRadius: '0.5rem',
        paddingLeft: '10px',
        width: '250px',
      }}
    >
      <br />
      {feedback.feedback_categories.map((category) => (
        <div className="row">
          <div className="col-5">{category.category}</div>
          <div className="col">{category.categoryScore}</div>
        </div>
      ))}

      {feedback?.what_did_you_love_about_this_event !== '' && (
        <div>
          <hr />
          <div className="row">
            <label htmlFor="" style={{ fontSize: '16px' }}>
              What did you love about this event?
            </label>
            <p>{feedback?.what_did_you_love_about_this_event}</p>
          </div>
        </div>
      )}
      {feedback?.general_comment !== '' && (
        <div>
          <hr />
          <div className="row">
            <label htmlFor="" style={{ fontSize: '16px' }}>
              General comments about this event
            </label>
            <p>{feedback?.general_comment}</p>
          </div>
        </div>
      )}
      {feedback?.how_can_this_event_improve !== '' && (
        <div>
          <hr />
          <div className="row">
            <label htmlFor="" style={{ fontSize: '16px' }}>
              How can this event improve in the future?
            </label>
            <p>{feedback?.how_can_this_event_improve}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackDetail;
