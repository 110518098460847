import React, { Component } from 'react';
import Roommates from '../../../Roommates';

const RoomShare = (props) => {
  const { event } = props;
  return (
    <div className="card">
      <h1>Room Share</h1>
      <div
        class="container-fluid  overflow-auto example"
        style={{ padding: '0px' }}
      >
        {event?.event_type === 'dance_event' && (
          // <Tab eventKey="roommates" title="Room Share">
          <Roommates event={event} />
          // </Tab>
        )}
      </div>
    </div>
  );
};

export default RoomShare;
