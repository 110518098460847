import React, { Component } from 'react';
import DancerItem from './DancerItem';

const Reactions = (props) => {
  let { reactions } = props;
  return (
    <div>
      {reactions.map((dancer) => (
        <div>
          <DancerItem dancer={dancer} />
        </div>
      ))}
    </div>
  );
};

export default Reactions;
