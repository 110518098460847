import React, { Component, useContext, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectInterests from './SelectInterests';
import UserContext from '../../context/user/userContext';
import { useAuth } from '../../context/auth/AuthState';

const BusinessOnboard = () => {
  const userContext = useContext(UserContext);
  const { addBusinessDetails } = userContext;

  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;

  const [businessDetails, setBusinessDetails] = useState({
    business_name: '',
    business_type: 'Fashion',
    interests: [],
    joinSharktank: true,
    instagram: '@',
    website: '',
    business_description: '',
  });

  let {
    business_name,
    business_type,
    interests,
    joinSharktank,
    instagram,
    website,
    business_description,
  } = businessDetails;

  const handleArtistTypeChange = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    setBusinessDetails({ ...businessDetails, business_type: type });
  };
  const addOrDeleteInterest = (interest) => {
    console.log('interest');
    console.log(interest);
    if (!interests.includes(interest)) {
      setBusinessDetails({
        ...businessDetails,
        interests: [...interests, interest],
      });
    } else {
      //remove interst

      let updatedInterests = interests.filter(
        (iteratedInterest) => iteratedInterest !== interest
      );

      setBusinessDetails({
        ...businessDetails,
        interests: updatedInterests,
      });
    }
    console.log('businessDetails');
    console.log(businessDetails);
  };

  let business_type_array = [
    'Technology',
    'Fashion',
    'Agriculture',
    'Real Estate',
    'Finance',
  ];
  let interests_array = [
    'Artificial Intelligence',
    'Investing',
    'Agriculture',
    'Real Estate',
    'Finance',
    'Media',
    'Music',
  ];

  const onRecommendationChange = (e) => {
    console.log('e.target.value');
    console.log(e.target.value);
    if (e.target.value === 'Yes') {
      setBusinessDetails({
        ...businessDetails,
        joinSharktank: true,
      });
    } else {
      setBusinessDetails({
        ...businessDetails,
        joinSharktank: false,
      });
    }
    console.log('businessDetails');
    console.log(businessDetails);
  };
  let navigate = useNavigate();
  const saveDetails = () => {
    console.log('saving details..');

    console.log('businessDetails');
    console.log(businessDetails);
  };

  const saveBusinessDetails = () => {
    console.log('businessDetails');
    console.log(businessDetails);
    // addBusinessDetails(businessDetails,user._id)
  };
  const onChange = (e) =>
    setBusinessDetails({ ...businessDetails, [e.target.name]: e.target.value });

  const sendToSharktankAndSaveDetails = () => {
    //save details

    //navigate to shark tank

    console.log('payment successful, now run createAdvertisement ...');

    saveDetails();
    navigate('/sharktank', {
      state: {
        messageText: 'Welcome to sharktank',
      },
    });
  };

  const [clicked, setClicked] = useState(false);
  const [saveButtonSelected, setSaveButtonSelected] = useState(false);

  return (
    <div className="card">
      <h5>business name</h5>

      <input
        id="business_name"
        type="text"
        name="business_name"
        placeholder="Business Name"
        value={business_name}
        onChange={onChange}
        required
        style={{ borderRadius: '8px' }}
      />
      <h5>Tell us about your business in 1 sentence</h5>

      <input
        id="business_description"
        type="text"
        name="business_description"
        placeholder="Short Business Description"
        value={business_description}
        onChange={onChange}
        required
        style={{ borderRadius: '8px' }}
      />

      <h5>Main Line of Business</h5>

      <div className="col" style={{ paddingBottom: '20px' }}>
        {[DropdownButton].map((DropdownType, idx) => (
          <DropdownType
            as={ButtonGroup}
            key={idx}
            id={`dropdown-button-drop-${idx}`}
            size="sm"
            variant="secondary"
            title={business_type}
            onSelect={handleArtistTypeChange}
          >
            {business_type_array.map((type) => (
              <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
            ))}
          </DropdownType>
        ))}
      </div>
      <h5>Choose Up to 3 Interests</h5>

      <div>
        {interests_array.map((interest) => (
          <div
            className="col grid-container-small-screen grid-container-big-screen-main-timeline"
            style={{ paddingBottom: '14px' }}
          >
            <SelectInterests
              addOrDeleteInterest={addOrDeleteInterest}
              interest={interest}
            />
          </div>
        ))}
      </div>

      <div className="form-group">
        <label htmlFor="instagram">Instagram</label>
        <input
          id="instagram"
          type="text"
          name="instagram"
          value={instagram}
          onChange={onChange}
          placeholder="@ name"
          required
          style={{ borderRadius: '8px' }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="website">Website</label>
        <input
          id="website"
          type="text"
          name="website"
          value={website}
          onChange={onChange}
          placeholder="www.enter-site-name.com"
          required
          style={{ borderRadius: '8px' }}
        />
      </div>

      <div>
        <h5>Join Sharktank</h5>
        {['Yes', 'No'].map((selection) => (
          <div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="selection"
                id={selection}
                value={`${selection}`}
                // onChange={onChangeRoles}
                onChange={onRecommendationChange}
                dance_style={selection}
              />

              <label class="form-check-label" for="police report filed">
                {selection}
              </label>
            </div>
          </div>
        ))}
      </div>

      {!saveButtonSelected ? (
        <Button
          onClick={() => {
            saveBusinessDetails();
            setSaveButtonSelected(!saveButtonSelected);
          }}
        >
          Save
        </Button>
      ) : (
        <></>
      )}

      {saveButtonSelected ? (
        <Button onClick={() => sendToSharktankAndSaveDetails()}>Next</Button>
      ) : (
        <></>
      )}
    </div>
  );
};
export default BusinessOnboard;
