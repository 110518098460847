import React, { Component, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CurriculumPopup from '../../curriculum/CurriculumPopup';
import CurriculumPopup2 from './CurriculumPopup2';

const Curriculum2 = (props) => {
  const [showCurriculumPopup, setShowCurriculumPopup] = useState(false);

  const handleClose = () => setShowCurriculumPopup(false);
  let { curriculum } = props;
  console.log('curriculum._id');
  console.log(curriculum?._id);
  console.log(curriculum);
  return (
    <div className="curriculum-card bg-light" style={{ paddingRight: '40px' }}>
      <div
        className="card-horizontal-menu card-block mx-2"
        //   style={{ height: '140px' }}
        style={{ width: '200px', height: '140px', paddingRight: '20px' }}
      >
        <div className="">
          <div className="" style={{ minWidth: 'max-content' }}>
            <Button
              onClick={() => setShowCurriculumPopup(!showCurriculumPopup)}
              style={{ minWidth: 'max-content' }}
            >
              {curriculum.curriculum_name}
            </Button>
            {/* <div className="row"> */}
          </div>

          <div className="">
            <div className="" style={{ minWidth: 'max-content' }}>
              <h5>{curriculum.dance_style}</h5>
            </div>
            <div className="" style={{ minWidth: 'max-content' }}>
              <h5>{curriculum.dance_level}</h5>
            </div>
          </div>
          {/* </div> */}
          {/* <h5>{curriculum._id}</h5> */}
        </div>

        {showCurriculumPopup && (
          <div>
            <CurriculumPopup2
              curriculum={curriculum}
              setShowCurriculumPopup={setShowCurriculumPopup}
              showCurriculumPopup={showCurriculumPopup}
              handleClose={handleClose}
              school_id={props?.school_id}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Curriculum2;
