function returnSkillsBasedOnType(dance_type) {
  switch (dance_type) {
    case 'bachata':
      return ['timing', 'musicality', 'connection', 'creativity'];
    case 'salsa':
      return ['salsa timing', 's musicality', 's connection', 's creativity'];
    case 'kizomba':
      return [
        'kizomba timing',
        'kizomba musicality',
        'kizomba connection',
        'kizomba creativity',
      ];
    case 'zouk':
      return [
        'zouk timing',
        'zouk musicality',
        'zouk connection',
        'zouk creativity',
      ];

    default:
      return ['timing', 'musicality', 'connection', 'creativity'];
  }
}
module.exports = { returnSkillsBasedOnType };
