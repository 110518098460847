import React, { Component, Fragment, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import StudioListForUser from './StudioListForUser';
import authState, { useAuth } from '../../context/auth/AuthState';
import { Link } from 'react-router-dom';

const CurriculumPopup = (props) => {
  const [authState] = useAuth();

  const { user, isAuthenticated } = authState;

  const [show, setShow] = useState(props.curriculumClicked);
  const handleClose = () => setShow(false);
  let { curriculum } = props;

  //get list of studios to add curriculum to

  const [adoptButtonClicked, setAdoptButtonClicked] = useState(false);
  const [studioId, setStudioId] = useState('');

  const showAdoptOptions = (
    <Fragment>
      <Button
        onClick={() => setAdoptButtonClicked(!adoptButtonClicked)}
        style={{
          width: '170px',
          margin: '12px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',
          borderWidth: '1px',
          borderRadius: '10px',
        }}
      >
        Adopt
      </Button>
    </Fragment>
  );

  const showLoginToAdoptOption = (
    <Fragment>
      <Link to="/login">
        <Button variant="primary">Login To Adopt Curriculum</Button>
      </Link>
    </Fragment>
  );

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{curriculum.curriculum_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {curriculum.moves.map((move) => (
            <div>
              <h5>{move}</h5>
            </div>
          ))}
        </Modal.Body>
        {adoptButtonClicked && (
          <div>
            <StudioListForUser
              user={user}
              curriculum={curriculum}
              setStudioId={setStudioId}
            />
          </div>
        )}
        {/* <Button
          onClick={() => setAdoptButtonClicked(!adoptButtonClicked)}
          style={{
            width: '170px',
            margin: '12px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
            borderWidth: '1px',
            borderRadius: '10px',
          }}
        >
          Adopt
        </Button> */}
        {!adoptButtonClicked && (
          <div>
            {!isAuthenticated ? showLoginToAdoptOption : showAdoptOptions}
          </div>
        )}
        {/* {adoptButtonClicked && <div />} */}
      </Modal>
    </div>
  );
};
export default CurriculumPopup;
