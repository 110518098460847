import React, { Component, useState, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import TabContainer from 'react-bootstrap/TabContainer';
// import TabPane from 'react-bootstrap/TabPane';

import { Link } from 'react-router-dom';

// import userContext from '../../context/user/userContext'; // might have to capitalize
import eventContext from '../../context/event/eventContext'; // might have to capitalize
import blogContext from '../../context/blog/blogContext'; // might have to capitalize

// export function EventDetail() {
const BlogDetail = (props) => {
  const getIdFromParams = (param) => {
    //be sure blog titles don't contain -.. or check for it
    let arr = param.split('-'); //be sure blog titles don't contain -

    // ["blog1","id1"]
    // ["blog1","subtitle","id1"]
    return arr[arr.length - 1];
  };
  const unhyphenate = (title) => {
    let newTitle = '';
    console.log('title');
    console.log(title);
    let splitTitle = title?.split('-');

    if (splitTitle?.length > 1) {
      for (let i = 0; i < splitTitle.length; i++) {
        if (i === 0) {
          newTitle = splitTitle[i];
          continue;
        }
        if (i === splitTitle.length - 1) {
          break;
        } else {
          newTitle = newTitle + ' ' + splitTitle[i];
        }
      }
    }
    console.log('newTitle');
    console.log(newTitle);
    return newTitle;
  };

  const getNameFromParams = (param) => {
    //be sure blog titles don't contain -.. or check for it
    // let arr = param.split('-'); //be sure blog titles don't contain -
    console.log('param from getNameFromParams');
    console.log(param);
    // ["blog1","id1"]
    // ["blog1","subtitle","id1"]
    return unhyphenate(param);
  };
  let { title } = useParams();
  let blog_id = getIdFromParams(title);
  const bContext = useContext(blogContext);
  const { getBlogPost, blog_post } = bContext;
  useEffect(() => {
    console.log('title');
    console.log(title);
    console.log('blog_id');
    console.log(blog_id);
    getBlogPost(blog_id);
    // let bpt = getNameFromParams(blog_post?.title);
    // eslint-disable-next-line
  }, []);
  // const [key, setKey] = useState('attendees');

  // const [key, setKey] = useState(props.page);
  // const uContext = useContext(userContext);
  // const { } = uContext;

  const [show, setShow] = useState(props.showModal);

  const handleClose = () => {
    console.log('closing ');
    setShow(false);
    // showModal = false;
  };
  const handleShow = () => setShow(true);

  const eContext = useContext(eventContext);
  const { editEvent } = eContext;

  const location = useLocation();
  // const { event, page } = location.state;
  const {} = props;
  // useEffect(() => {
  //   getHost(event.hostId);
  //   console.log('event host: ');
  //   console.log(host);
  // }, []);

  //form inputs
  // const [details, setDetails] = useState({
  //   // descr: '',
  //   name: event.name,
  //   descr: 'fun event',
  //   // location: 'Charlotte, NC',
  // });

  // const onChange = (e) =>
  //   setDetails({ ...details, [e.target.name]: e.target.value });
  // const { name, descr } = details;

  const updatedEvent = {};
  // updatedEvent.event_id = event._id;
  // updatedEvent.descr = descr;
  // updatedEvent.name = name;
  // updatedEvent.descr = descr;
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving updated user from frontend');

    // if (details === '') {
    //   //   setAlert('Please fill in all fields', 'danger');
    // } else {
    //   editEvent(updatedEvent);

    //   console.log('updatedEvent from edit is: ---');
    //   console.log(updatedEvent);
    //   console.log('saving updated user from frontend');
    // }
  };

  const { showModal } = props;
  console.log(`showModal is ${showModal}`);
  function createMarkup() {
    return { __html: blog_post.text };
  }

  let image;
  if (blog_post.blogImage) {
    image = blog_post.blogImage.url;
  } else {
    image =
      'https://www.weho.org/home/showpublishedimage/5617/635754012603070000';
  }
  return (
    <div>
      <div className="all-center">
        <div className="bigScreen smallScreenEventDetail">
          <img
            // style={{ width: '350px' }}
            src={image}
            alt="event"
            // src="https://static.wixstatic.com/media/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg/v1/fit/w_2500,h_1330,al_c/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg"
          />
        </div>
        <br />
      </div>
      <h5>
        {/* Like this content?{' '} */}
        <a href="/register" style={{ color: '#0d6efd' }}>
          Join The Dance Revolution! 🙂 💃🏽
        </a>
        <hr />
      </h5>

      <h1>{getNameFromParams(blog_post?.title)}</h1>

      <div dangerouslySetInnerHTML={createMarkup()} />
      <br />
      <h5>
        Like this content?{' '}
        <a href="/register" style={{ color: '#0d6efd' }}>
          Join Us!
        </a>
      </h5>
      {/* <p>{blog_post.text}</p> */}
      {/* <img
        // className="rounded-circle"
        //   src={profile.user.avatar}
        src={blog_post.blogImage?.url}
        // src={image}
        alt=""
        height="1000"
        width="350"
      /> */}
      {/* render attendees under attendees h1 or bring the attendees to a child component */}
    </div>
  );
};
export default BlogDetail;
