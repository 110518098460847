import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';
import StudioContext from '../../context/studio/studioContext';
import AddStudioLevel from './AddStudioLevel';
import Levels from './Levels';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Score from './Score';
import { useAuth } from '../../context/auth/AuthState';

const Scores = (props) => {
  const topListContext = useContext(TopListContext);
  const [authState] = useAuth();
  const { user } = authState;

  const { likeDancer } = topListContext;

  const studioContext = useContext(StudioContext);
  const {
    getStudioById,
    studio,
    getStudioMembers,
    members,
    addNewMember,
    getStudioLevels,
    studio_levels,
    updateStudentsScores,
  } = studioContext;

  const userContext = useContext(UserContext);

  const { connectToUser } = userContext;
  let copyClicked = false;
  const location = useLocation();
  //   const { user } = location.state;

  // const { user, current_user_id, isCurrentUser } = props;
  const { scores, member_id, studio_id, isStudentAccount } = props;
  // const { studio_id } = useParams();

  useEffect(() => {
    console.log('scores from score.js');
    console.log(scores);
    // console.log('studio_id');
    // console.log(studio_id);
    // //get studio by id
    // getStudioById(studio_id);
    // getStudioMembers(studio_id);
    // getStudioLevels(studio_id);
    // console.log('studio from company');
    // console.log(studio);
    // eslint-disable-next-line
  }, []);
  let possible_scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [score, setScore] = useState(1);

  const handleScoreChange = (e) => {
    console.log('score changed');
    setScore(e);
    // setDetails({
    //   ...details,
    //   ['maxTrainingHoursPerDay']: e,
    // });
  };
  const [visible, setVisible] = React.useState(false);
  const updateStudent = (e) => {
    e.preventDefault();
    console.log('hi');
  };

  const [myScoresAndStyle, setMyScoresAndStyle] = useState(scores);
  console.log('myScoresAndStyle -- from scores');
  console.log(myScoresAndStyle);
  const updateScoresObj = (moveName, updatedScore) => {
    //run every time a score is changed to update the
    //scores_and_styles array.
    //do we first need to useState ?
    console.log('trying to updatescore');
    // for (let i = 0; i < myScoresAndStyle.length; i++) {
    console.log('dance_style');
    console.log(scores.dance_style);
    if (myScoresAndStyle.dance_style === scores.dance_style) {
      let skills = myScoresAndStyle.skills;
      for (let y = 0; y < skills.length; y++) {
        console.log(moveName);
        if (skills[y].move_name === moveName) {
          console.log('move name matches');
          //new line - 7.25.23 - add change since last update
          // if(skills[y]?.change_since_last_update){

          skills[y].change_since_last_update = updatedScore - skills[y].score;
          // }else{
          //   skills[y].change_since_last_update =
          // }
          skills[y].score = updatedScore;
          //assign updated skills to myScoresAndStyle
          myScoresAndStyle.skills = skills;
        }
      }
    }
    // }

    let updatedScoresObj = myScoresAndStyle;
    // updatedScoresObj.skills;
    setMyScoresAndStyle(updatedScoresObj);
  };

  //scores.skills is scores_and_styles array
  let updatedScores = scores.skills;

  const handleScoreUpdate = (updatedScore, move_name) => {
    //update scores object
    updateScoresObj(move_name, updatedScore);

    //myScoresAndStyle is one of the elements within
    // the scores_and_styles []..
    //so send it to the backend and perform update on studio
    //by comparing the dance_style and level and
    //once found you can just replace that element in the
    //scores_and_styles array for the update
  };
  return (
    <div>
      <Button
        onClick={() => setVisible(!visible)}
        style={{
          width: 'max-content',
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
        }}
      >
        {scores.dance_style + ' ' + scores.class_name}
        {/* {scores.dance_style + ' ' + scores.level} */}
      </Button>

      {updatedScores.map((skill) => (
        <div className="row">
          <Score
            skill={skill}
            visible={visible}
            handleScoreUpdate={handleScoreUpdate}
            isStudentAccount={isStudentAccount}
          />
          {/* <div className="col">
            <h5>{skill.move_name}</h5>
          </div> */}

          <div className="col">
            {/* <h5>{skill.score}</h5> */}

            {/* <div className="col">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={skill.score}
                  onSelect={handleScoreChange}

                  // onClick={() => {
                  //   setLevelButtonClicked(!levelButtonClicked);
                  // }}
                  // onSelect={handleSelectLevel}
                >
                  {possible_scores.map((score) => (
                    <Dropdown.Item eventKey={score}>{score}</Dropdown.Item>
                  ))}
                </DropdownType>
              ))}
            </div> */}
          </div>
        </div>
      ))}
      {visible &&
        !isStudentAccount && (
          <Button
            className="btn-danger"
            onClick={() => {
              console.log('scores');
              console.log(updatedScores);

              console.log('user updating stuff:');
              console.log(user);
              console.log('studio_id from scores.js');
              console.log(studio_id);
              updateStudentsScores({
                studio_id: studio_id,
                member_id: member_id,
                myScoresAndStyle: myScoresAndStyle,
                updateStyle: scores.dance_style,
                // updateLevel: scores.level,
                updateLevel: scores.class_name,
                instructor_user_id: user._id,
                instructor_name: user.name,
                image: user.image.url,
              });
              // updateStudent(member_id, studio_id, scores);
            }}
          >
            Update Student
          </Button>
        )}
      <hr />
    </div>
  );
};

export default Scores;
