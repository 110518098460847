import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

const OptionalRegistrationInput = (props) => {
  return (
    <div>
      <h5>add phone#,image,location</h5>

      <Button onClick={() => props.setOptionalFieldsSkipped(true)}>skip</Button>
    </div>
  );
};
export default OptionalRegistrationInput;
