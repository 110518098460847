import React, { useReducer } from 'react';
import axios from 'axios';
import CurriculumContext from './curriculumContext'; //be sure the case is not case sensitive
import CurriculumReducer from './curriculumReducer';

import {
  GET_CURRICULUM,
  ADOPT_CURRICULUM,
  ADD_CURRICULUM,
  GET_DANCE_MOVES_BY_STYLE,
  GET_CURRICULA_FOR_SCHOOL,
  GET_LESSON_PLAN_BY_ID,
  GET_DANCE_MOVES_BY_STYLE2,
  ADD_LESSON_PLAN_FOR_CURRICULUM,
  ADD_CURRICULUM_AND_LESSON_PLAN,
  REMOVE_CURRICULUM,
  GET_CURRICULUMS_WITH_STYLE_AND_LEVEL,
  GET_LESSON_PLANS_FOR_CURRICULUM,
  ENROLL_STUDENT_IN_CURRICULUM,
  UPDATE_LESSON_PLAN_FOR_CURRICULUM,
  GET_ENROLLED_STUDENTS_FOR_CURRICULUM,
  CURRICULUM_ERROR,
} from '../types';

const CurriculumState = (props) => {
  const initialState = {
    curriculaForSchool: [], // array of all curriculums
    curriculum: [],
    curriculumsWithStyleAndLevel: [],
    lessonPlansForCurriculum: [],
    enrolledStudents: [],
    newLessonPlanAndCurriculum: {}, // mioght need curricula?
    lessonPlanObj: {},
    newLessonPlan: {},
    danceMovesByStyle: [],
    // updated_studio_curriculum: [],
    loading: true,
    error: {},

    // confirmedPrivateLessons: {},
  };

  const [state, dispatch] = useReducer(CurriculumReducer, initialState);

  const getCurriculum = async (style) => {
    try {
      console.log('style from getCurriculum');
      console.log(style);
      console.log('trying getCurriculum');
      const res = await axios.get(`/api/curriculum/all/${style}`);
      console.log('res below from getCurriculum curriculumstate.js');
      console.log(res);

      dispatch({
        type: GET_CURRICULUM,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const getCurriculumsWithStyleAndLevel = async (style, level) => {
    try {
      const res = await axios.get(`/api/curriculum/all/${style}/${level}`);
      console.log(
        'res below from getCurriculumsWithStyleAndLevel curriculumstate.js'
      );
      console.log(res);

      dispatch({
        type: GET_CURRICULUMS_WITH_STYLE_AND_LEVEL,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };

  const getDanceMovesByStyle = async (style) => {
    try {
      console.log('style from getDanceMovesByStyle');
      console.log(style);
      console.log('trying getDanceMovesByStyle');
      const res = await axios.get(
        `/api/dance-moves/movesForParticularStyle/${style}`
      );
      console.log('res below from getDanceMovesByStyle curriculumstate.js');
      console.log(res);

      dispatch({
        type: GET_DANCE_MOVES_BY_STYLE,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const getDanceMovesByStyle2 = async (style) => {
    try {
      console.log('style from getDanceMovesByStyle');
      console.log(style);
      console.log('trying getDanceMovesByStyle');
      const res = await axios.get(`/api/dance-moves/moves/${style}`);
      console.log('res below from getDanceMovesByStyle curriculumstate.js');
      console.log(res);

      dispatch({
        type: GET_DANCE_MOVES_BY_STYLE2,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const getCurriculaForSchool = async (studio_id) => {
    try {
      const res = await axios.get(`/api/curriculum/curricula/${studio_id}`);
      console.log('res below from getCurriculaForSchool curriculumstate.js');
      console.log(res);

      dispatch({
        type: GET_CURRICULA_FOR_SCHOOL,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const getLessonPlanById = async (id) => {
    try {
      const res = await axios.get(`/api/curriculum/lesson-plan/${id}`);
      console.log('res below from getlessonplanbyID curriculumstate.js');
      console.log(res);

      dispatch({
        type: GET_LESSON_PLAN_BY_ID,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const getLessonPlansForCurriculum = async (curriculum_id) => {
    try {
      const res = await axios.get(
        `/api/curriculum/allLessonPlansForCurriculum/${curriculum_id}`
      );
      console.log(
        'res below from getLessonPlansForCurriculum curriculumstate.js'
      );
      console.log(res);

      dispatch({
        type: GET_LESSON_PLANS_FOR_CURRICULUM,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };

  //lot of variations on this one on backend - resolve the updates.
  const adoptCurriculum = async (obj) => {
    try {
      const res = await axios.post(`/api/curriculum/adopt-curriculum`, obj);
      console.log('res below from getCurriculum curriculumstate.js');
      console.log(res);

      dispatch({
        type: ADOPT_CURRICULUM,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const adoptCurriculum_v03 = async (obj) => {
    try {
      const res = await axios.post(
        `/api/curriculum/adopt-curriculum_v_0.3`,
        obj
      );
      console.log('res below from getCurriculum curriculumstate.js');
      console.log(res);

      dispatch({
        type: ADOPT_CURRICULUM,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const enrollStudentInCurriculum = async (obj) => {
    try {
      const res = await axios.post(`/api/curriculum/enroll-student`, obj);
      console.log('res below from getCurriculum curriculumstate.js');
      console.log(res);

      dispatch({
        type: ENROLL_STUDENT_IN_CURRICULUM,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const getEnrolledStudents = async (curriculum_id) => {
    try {
      const res = await axios.get(
        `/api/curriculum/enrolled-students/${curriculum_id}`
      );
      console.log('res below from getEnrolledStudents curriculumstate.js');
      console.log(res);

      dispatch({
        type: GET_ENROLLED_STUDENTS_FOR_CURRICULUM,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const addNewCurriculum = async (obj) => {
    try {
      console.log('obj from context');
      console.log(obj);

      const res = await axios.post(`/api/curriculum/add-curriculum`, obj);
      console.log('res below from getCurriculum curriculumstate.js');
      console.log(res);

      dispatch({
        type: ADD_CURRICULUM,
        payload: res.data,
      });

      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const addLessonPlan = async (obj) => {
    try {
      console.log('trying addLessonPlan');
      console.log(obj);
      const res = await axios.post(`/api/dance-moves/add-lesson-plan`, obj);
      console.log('res below from addLessonPlan curriculumstate.js');
      console.log(res);

      dispatch({
        type: ADD_LESSON_PLAN_FOR_CURRICULUM,
        payload: res.data,
      });

      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const updateLessonPlan = async (obj) => {
    try {
      console.log('trying updateLessonPlan');
      console.log(obj);
      const res = await axios.post(
        `/api/curriculum/updateCurriculumsLessonPlan`,
        obj
      );
      console.log('res below from updateLessonPlan curriculumstate.js');
      console.log(res);

      dispatch({
        type: UPDATE_LESSON_PLAN_FOR_CURRICULUM,
        payload: res.data,
      });

      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const addCurriculumAndLessonPlan = async (obj) => {
    try {
      console.log('trying addLessonPlan');
      console.log(obj);
      const res = await axios.post(
        `/api/curriculum/add-curriculum-and-lesson-plan`,
        obj
      );
      console.log('res below from addLessonPlan curriculumstate.js');
      console.log(res);

      dispatch({
        type: ADD_CURRICULUM_AND_LESSON_PLAN,
        payload: res.data,
      });

      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };
  const removeCurriculum = async (curr_id, school_id) => {
    try {
      console.log('trying removeCurriculum');

      const res = await axios.delete(
        `/api/curriculum/delete/${curr_id}/${school_id}`
      );
      console.log('res below from removeCurriculum curriculumstate.js');
      console.log(res);

      dispatch({
        type: REMOVE_CURRICULUM,
        payload: res.data,
      });

      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CURRICULUM_ERROR,
        payload: err,
      });
    }
  };

  return (
    <CurriculumContext.Provider
      value={{
        // props -- where our state and actions will go

        loading: state.loading,
        curriculum: state.curriculum,
        curriculumsWithStyleAndLevel: state.curriculumsWithStyleAndLevel,
        lessonPlanObj: state.lessonPlanObj,
        curriculaForSchool: state.curriculaForSchool,
        danceMovesByStyle: state.danceMovesByStyle,
        danceMovesByStyle2: state.danceMovesByStyle2,
        newLessonPlan: state.newLessonPlan,
        newLessonPlanAndCurriculum: state.newLessonPlanAndCurriculum,
        lessonPlansForCurriculum: state.lessonPlansForCurriculum,
        enrolledStudents: state.enrolledStudents,
        error: state.error,

        getCurriculum,
        adoptCurriculum,
        adoptCurriculum_v03,
        addNewCurriculum,
        getDanceMovesByStyle,
        getDanceMovesByStyle2,
        getCurriculaForSchool,
        getLessonPlanById,
        addLessonPlan,
        addCurriculumAndLessonPlan,
        removeCurriculum,
        getCurriculumsWithStyleAndLevel,
        getLessonPlansForCurriculum,
        enrollStudentInCurriculum,
        getEnrolledStudents,
      }}
    >
      {props.children}
    </CurriculumContext.Provider>
  );
};

export default CurriculumState;
