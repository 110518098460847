import React, { Component, useContext, useEffect, useState } from 'react';
import CurriculumContext from '../../../context/curriculum/curriculumContext';
import { Button, Modal } from 'react-bootstrap';
import Curriculum from './Curriculum';
import Curriculum2 from './Curriculum2';

const Curricula = (props) => {
  //load in curricula
  //   let curriculumContext = useContext(CurriculumContext);

  //curriculaForSchool will be list of curriculums
  //   const { getCurriculaForSchool, curriculaForSchool } = curriculumContext;

  let { curriculaForSchool } = props;

  useEffect(() => {
    console.log('style is....');

    // getCurriculaForSchool(props.school_id);

    // eslint-disable-next-line
  }, []);
  console.log();

  let curriculums = ['c1', 'c2'];

  const [showCurriculumPopup, setShowCurriculumPopup] = useState(false);

  const handleClose = () => setShowCurriculumPopup(false);

  return (
    // <div>
    //   <h5>Curricula home</h5>
    <div class="d-flex flex-row flex-nowrap">
      {curriculaForSchool.map((curriculum) => (
        <Curriculum2 curriculum={curriculum} school_id={props?.school_id} />
      ))}
    </div>
    // </div>
    // </div>
    // </div>
  );
};

export default Curricula;
