import React, { Component, useState, useContext, useEffect } from 'react';
import CalendarEvents from './CalendarEvents';
import CalendarEventsUpdated from './CalendarEventsUpdated';
import InstructorContext from '../../context/instructor/instructorContext';
import { useAuth } from '../../context/auth/AuthState';
import { Button } from 'react-bootstrap';
import ConfirmedPrivateLessons from '../instructor/ConfirmedPrivateLessons';
import PrivateLessonRequestNoEvent from './PrivateLessonRequestNoEvent';
import ConfirmedPrivateLesson from '../instructor/ConfirmedPrivateLesson';

const Calendar2 = (props) => {
  console.log('props.privateLessonRequestsAtEvents');
  console.log(props.privateLessonRequestsAtEvents);
  const [authState] = useAuth();

  const { user } = authState;

  const instructorContext = useContext(InstructorContext);
  const {
    getPrivateLessonRequestsForInstructorAtEvents,
    privateLessonRequestsForInstructorAtEvents,
  } = instructorContext;
  const [showPrivateLessonRequests, setShowPrivateLessonRequests] = useState(
    false
  );
  const [
    showConfirmedPrivateLessons,
    setShowConfirmedPrivateLessons,
  ] = useState(false);

  useEffect(() => {
    // if (user?.level_progression) {
    //   console.log('user.level_progression');
    //   console.log(user.level_progression);
    //   //getStudentScores(user._id)
    // }
    if (user && user?._id) {
      // if (Object.keys(user).length !== 0 && user.constructor === Object) {
      console.log(
        'trying to getPrivateLessonRequestsForInstructorAtEvents from currentuserprofile.js '
      );
      getPrivateLessonRequestsForInstructorAtEvents(user?._id);
    } else {
      console.log('no user bruh');
    }

    // eslint-disable-next-line
  }, []);

  //artist.events will be coming from props
  //we will have a toggle for [ workshops | confirmed private lessons | private lesson Requests ]

  return (
    <div>
      {/* {calendar.map((event) => (
        <CalendarEvents key={event.id} event={event} />
      ))} */}

      <Button
        onClick={() => {
          setShowConfirmedPrivateLessons(!showConfirmedPrivateLessons);
          setShowPrivateLessonRequests(false);
        }}
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '6px',
        }}
      >
        Confirmed Lessons
      </Button>
      <Button
        onClick={() => {
          setShowPrivateLessonRequests(!showPrivateLessonRequests);
          setShowConfirmedPrivateLessons(false);
        }}
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '6px',
        }}
      >
        Private Lesson Requests
      </Button>
      {showPrivateLessonRequests && (
        <div>
          {props.privateLessonRequestsAtEvents.map((event) => (
            <CalendarEventsUpdated key={event.event_id} event={event} />
          ))}
          <hr />
          <h5>Other Requests</h5>
          {props.isNotArtistButInstructorOnlyAccount() && (
            <div>
              <h5>
                {props.privateLessonRequestsForInstructor.length > 0
                  ? `Private Lesson Requests`
                  : `Private Lesson Requests Will Be Shown Here`}
              </h5>
              {props.privateLessonRequestsForInstructor.map((lesson) => (
                <PrivateLessonRequestNoEvent
                  key={lesson._id}
                  lesson={lesson}
                  user={user}
                  isNotArtistButInstructorOnlyAccount={
                    props.isNotArtistButInstructorOnlyAccount
                  }
                  // artist={artist}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {showConfirmedPrivateLessons && (
        <div>
          {props.confirmedPrivateLessonsAtEvents.map(
            (one_event_with_lessons) => (
              // <CalendarEventsUpdated key={event.event_id} event={event} />
              <ConfirmedPrivateLessons
                one_event_with_lessons={one_event_with_lessons}
              />
            )
          )}

          <h5>Other Confirmed Lessons</h5>

          {props.confirmedPrivateLessonsNoEvent.map((lesson) => (
            <ConfirmedPrivateLesson lesson={lesson} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Calendar2;
