// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { useAuth } from '../../context/auth/AuthState';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import Layout from '../advertise/Layout';
import CheckoutForm from '../advertise/CheckoutForm';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import SubEventContext from '../../context/subevent/subEventContext';
import SubEvent from './SubEvent';
import Vendor from './Vendor';
import DJRequest from './DJRequest';
import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';

import Form from 'react-bootstrap/Form';

// import { useHistory } from 'react-router-dom';

const DJRequests = (props) => {
  let navigate = useNavigate();

  const eventContext = useContext(EventContext);
  const {
    joinEventAsVendor,
    getVendorsForEvent,
    // vendors,
    addNewDJ,
    newDJ,
    get_djs_and_requests,
    djs_and_requests,
  } = eventContext;

  const { event } = props;
  const [authState] = useAuth();

  const { user } = authState;

  useEffect(() => {
    console.log(
      'getting events from Event.js but need to change component name'
    );
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    getVendorsForEvent(event._id);
    get_djs_and_requests(event._id);
    // eslint-disable-next-line
  }, []);

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  const [visible, setVisible] = React.useState(false);
  const [addDJFieldsVisible, setAddDJFieldsVisible] = React.useState(false);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [details, setDetails] = useState({
    dj_name: '',
    state: 'North Carolina',
    city: 'Charlotte',
    eventId: event._id,
    verified: false,
    verified_user_id: '',
    // sub_event_name: '',
    // descr: '',
    // sub_event_image: '',
  });
  const {
    dj_name,
    state,
    city,

    // sub_event_name,
    // descr,
    // sub_event_image,
  } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });
  const [file, setFile] = useState();
  const [buttonText, setButtonText] = useState('Join Vendors');

  // const onSubmit2 = (e) => {
  //   e.preventDefault();
  //   if (descr === '') {
  //     setAlert('Please fill in all fields', 'danger');
  //   }
  //   else {
  //     const data = new FormData();
  //     data.append('file', file);
  //     // data.append('descr', descr);
  //     // data.append('sub_event_name', sub_event_name);
  //     // data.append('image', sub_event_image);
  //     data.append('eventId', event._id);
  //     data.append('hostId', user._id);
  //     console.log('add subevent hit');
  //     // addSubEventForEvent(event._id, sub_event_name, descr, user._id);
  //     // addSubEventForEvent(data);
  //   }
  // };

  const nouser = (
    <Fragment>
      <Link to="/login">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          sign in to join vendors/exhibitors
        </button>
      </Link>
    </Fragment>
  );
  const [show, setShow] = useState(false);

  const handleClose = () => {
    console.log('closing ');
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    console.log(`show is: ${show}`);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving from frontend');
  };

  const handleJoin = () => {
    joinEventAsVendor(user._id, event._id);
    setButtonText('Joined');
    window.location.reload();
  };

  // let djs_and_requests = [{ dj_name: 'dj sabroso' }, { dj_name: 'dj six' }];

  let requests = [
    { requestor_name: 'dafne', song_name: 'quiere beber' },
    { requestor_name: 'courtney', song_name: 'vivir mi vida' },
    { requestor_name: 'claire', song_name: 'quien mas' },
  ];

  // let djs_and_requests

  const addDJ = (e) => {
    e.preventDefault();
    console.log('add new dj');
    console.log('dj details');
    console.log(details);
    addNewDJ(details);
  };

  // ishostOrAdmin
  const ishostOrAdmin = () => {
    // for (let i = 0; i < event?.owners?.length; i++) {
    if (event?.hostId.toString() === user?._id) {
      return true;
    }
    // }
    return false;
  };

  return (
    <div>
      {/* <h1>go dj</h1>{' '} */}
      <Button
        onClick={() => {
          // addNewDJ(event._id);
          setAddDJFieldsVisible(!addDJFieldsVisible);
        }}
      >
        {addDJFieldsVisible ? `Close` : `Add New DJ`}
      </Button>
      <div className="row">
        {addDJFieldsVisible && (
          <div className="col">
            {/* <h2>{user?.name}</h2> */}
            {/* <label class="form-check-label" for={'name'}>
              Studio Name
            </label> */}
            <form onSubmit={addDJ}>
              <label class="form-check-label" for={'first_name'}>
                DJ Name
              </label>
              <input
                id="dj_name"
                name="dj_name" // as="text" type="text"
                value={dj_name}
                required
                onChange={(e) => {
                  setDetails({
                    ...details,
                    [e.target.name]: e.target.value,
                    //   ['existing_studio_id']: splitValue2(e),
                  });
                  // setSelectedCity();
                  console.log('e is');
                  console.log(e);
                  console.log(details);
                }}
              />

              <Form.Group controlId="formBasicSelect">
                <Form.Label>State</Form.Label>
                <Form.Control
                  id="state"
                  name="state"
                  as="select"
                  value={state}
                  onChange={(e) => {
                    // setDetails({
                    //   ...details,
                    //   [e.target.name]: e.target.value,
                    // });
                    console.log(' e.target.value');
                    console.log(e.target.value);
                    // setSelectedState(e.target.value);
                    let cities = selectedCities(e.target.value);
                    // setSelectedCity(cities[0]);
                    console.log('details..city filled?');
                    console.log(details);
                  }}
                >
                  {listOfStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicSelect">
                <Form.Label>City</Form.Label>
                <Form.Control
                  id="city"
                  name="city"
                  as="select"
                  value={city}
                  required
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      [e.target.name]: e.target.value,
                    });
                    // setSelectedCity();
                    console.log(details);
                  }}
                >
                  {listOfCitiesForSelectedState.map((city) => (
                    <option value={city}>{city}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <div class="form-check">
                {/* <input
                  //  id="name"
                  //  name="name" // as="text" type="text"
                  //  value={name}
                  id="owner"
                  type="checkbox"
                  name="owner"
                  value={'owner'}
                  onChange={onOwnerChange}
                 
                /> */}
              </div>

              <input
                type="submit"
                value={
                  ishostOrAdmin() ? `Add DJ` : `Only the event host can add djs`
                }
                // className="btn btn-primary btn-block"
                class="btn btn-secondary"
                disabled={ishostOrAdmin() ? false : true}
                // onClick={onSubmit2}
              />
            </form>

            <br />
          </div>
        )}
      </div>
      {/* {renderOptions()} */}
      <div className="bigScreenAttendee smallScreenAttendee">
        {djs_and_requests.map((dj) => (
          <div className="row">
            <div className="">
              <DJRequest event={event} dj={dj} />
            </div>
          </div>
          /*
          <div className="row">
            <div className="col-1">
              <img
                className="rounded-circle"
                
                src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                
                alt=""
                height="50"
              />
            </div>
            <div className="col">
              <div>{dj.dj_name}</div>
              <div>
                <Button
                  onClick={() => setVisible(!visible)}
                  class="btn btn-secondary"
                >
                  {visible ? 'Cancel' : 'Request Song'}
                </Button>
              </div>
            </div>
          </div>

 */
        ))}
      </div>
      {/* 
      {visible && (
        <div className="form-container">
          <div className="form-group">
            <h1>Songs</h1>
            {requests.map((request) => (
              <div>
                <div className="row">
                  <div className="col-1">
                    <img
                      className="rounded-circle"
                      src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                      alt=""
                      height="25"
                    />
                  </div>
                  <div className="col-2">
                    <h5>{request.requestor_name} - </h5>
                  </div>
                  <div className="col">
                    <h5>{request.song_name}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
       */}
      {/* {!user ? (
        // `sign in to join vendors`

        <Link to="/login">
          <button
            type="button"
            className="btn btn-dark"

            // onClick={join_event}
          >
            sign in to join vendors
          </button>
        </Link>
      ) : (
        <Button variant="primary" onClick={handleJoin}>
          {buttonText}
        </Button>
      )} */}
      {/* {vendors.map((vendor) => (
        <Vendor key={vendor._id} vendor={vendor} />
      ))} */}
    </div>
  );
};
export default DJRequests;
