import React, { Component, useState } from 'react';
import Skill from './Skill';
import EndorseSkill from './EndorseSkill';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const DanceSkills = (props) => {
  let {
    style,
    skillsToEndorse,
    setSkillsToEndorse,
    loading,
    danceTypeToEndorse,
    setDanceTypeToEndorse,
  } = props;
  const [skillArray, setSkillArray] = useState([]);
  const [cancelledEndorsment, setCancelledEndorsment] = useState(false);

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div
      class="container-fluid  overflow-auto example"
      style={{ padding: '0px' }}
    >
      <div class="d-flex flex-row flex-nowrap">
        {style.skills.map((skill) => (
          // <div>{skill.skill_name}</div>

          <EndorseSkill
            skill={skill.skill_name}
            skillEndorsementsTotal={skill.endorsements.length}
            skillsToEndorse={skillsToEndorse}
            setSkillsToEndorse={setSkillsToEndorse}
            setSkillArray={setSkillArray}
            skillArray={skillArray}
            cancelledEndorsment={cancelledEndorsment}
            setCancelledEndorsment={setCancelledEndorsment}
            setDanceTypeToEndorse={setDanceTypeToEndorse}
            // danceTypeToEndorse={style}

            style={style}
          />
        ))}
      </div>

      <div className="row">
        {skillArray.length > 0 ? (
          <div className="col">
            {props?.user ? (
              <Button
                style={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  backgroundColor: 'lightgreen',
                  color: 'black',
                }}
                onClick={() => {
                  props?.isCurrentUserProfile === true
                    ? console.log('you cannot endorse yourself')
                    : props.saveEndorsement();

                  console.log('skillArray');
                  console.log(skillArray);
                }}
              >
                {props?.isCurrentUserProfile === true
                  ? `Other dancers can now endorse you`
                  : `Save Endorsement`}
              </Button>
            ) : (
              <div>
                <Link to={'/login'}>
                  <Button style={{ marginTop: '12px', marginBottom: '12px' }}>
                    login to endorse user
                  </Button>
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div>
            <h5 style={{ fontSize: '14px' }}>click to endorse</h5>
          </div>
        )}

        <div className="col">
          {skillArray.length > 0 ? (
            <div>
              <Button
                onClick={() => {
                  // setSkillArray([]);
                  // setCancelledEndorsment(!cancelledEndorsment);
                  window.location.reload();
                }}
                style={{
                  backgroundColor: 'red',
                  marginTop: '12px',
                  marginBottom: '12px',
                }}
              >
                Cancel Endorsement
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default DanceSkills;
