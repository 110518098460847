import { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import React, { Component } from 'react';
import EventContext from '../../context/event/eventContext';

const AddSafetyOfficerPopup = (props) => {
  let eventContext = useContext(EventContext);
  const { addSafetyOfficers } = eventContext;

  const [show, setShow] = useState(props.addSafetyOfficerSelected);
  const handleClose = () => setShow(false);
  const [details, setDetails] = useState({
    reporter_id: '',
    safety_officer_name: '',
    safety_officer_phone_number: '',
  });

  const { safety_officer_name, safety_officer_phone_number } = details;
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    let safety_officer_obj = {
      name: safety_officer_name,
      phone_number: safety_officer_phone_number,
    };
    let obj = {
      event_id: props.event_id,
      safety_officers: [safety_officer_obj],
    };
    console.log(' safety officer obj');
    console.log(obj);
    // addSafetyOfficers(obj);
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Safety Officer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={onSubmit}>
              <div className="row">
                <div className="col">
                  <div className="form-group">
                    <label htmlFor="name">Safety Officer Name</label>
                    <input
                      id="safety_officer_name"
                      type="text"
                      name="safety_officer_name"
                      value={safety_officer_name}
                      onChange={onChange}
                      required
                      // required
                      // onInvalid="add name"
                    />
                  </div>
                </div>

                <div className="col">
                  <div className="form-group">
                    <label htmlFor="name">Safety Officer Phone Number</label>
                    <input
                      id="safety_officer_phone_number"
                      type="text"
                      name="safety_officer_phone_number"
                      value={safety_officer_phone_number}
                      onChange={onChange}
                      required
                      // required
                      // onInvalid="add name"
                    />
                  </div>
                </div>
              </div>

              <input
                type="submit"
                value="Add Officer"
                class="btn btn-secondary"
              />
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddSafetyOfficerPopup;
