import React, { Component, useState } from 'react';
import { Form } from 'react-bootstrap';

const Witness = (props) => {
  const [
    witnessHasAlreadyBeenContacted,
    setWitnessHasAlreadyBeenContacted,
  ] = useState(props.witness.witness_contacted);
  return (
    <div>
      <div className="row" style={{ paddingTop: '10px' }}>
        <div className="col">
          <h5>{props?.witness?.witness_name}</h5>
        </div>
        <div className="col">
          <h5>{props?.witness?.witness_phone_number}</h5>
        </div>
        <div className="col">
          <Form>
            <Form.Check // prettier-ignore
              type="switch"
              id="custom-switch"
              label={
                // props?.witness?.witness_contacted
                witnessHasAlreadyBeenContacted
                  ? 'Yes'
                  : //   : props?.witnessContactedLabel
                    'Not Yet'
              }
              // label={getWitnessContacted(witness.witness_contacted)}
              // value
              //   checked={props.witness.witness_contacted}
              // onChange={(e) => {
              //   onContactedPersonChange(witness, e);
              //   //show update button for witness section
              //   setWitnessContactedUpdateShowButton(true);

              //   console.log('e.target from insdie');
              //   console.log(e.target);
              //   if (e.target.name === 'Yes') {
              //     setWitnessContactedLabel('Not Yet');
              //   } else {
              //     setWitnessContactedLabel('Yes');
              //   }
              // }}
              checked={witnessHasAlreadyBeenContacted}
              onClick={(e) => {
                props.onContactedPersonChange(props?.witness, e);
                // props.setFromChild(props?.witness, e);
                //show update button for witness section
                props?.setWitnessContactedUpdateShowButton(true);
                // if (e.target.label === 'Yes') {
                //   props?.setWitnessContactedLabel('Not Yet');
                // } else {
                //   props?.setWitnessContactedLabel('Yes');
                // }
                if (!witnessHasAlreadyBeenContacted) {
                  //   props?.setWitnessContactedLabel('Not Yet');
                  setWitnessHasAlreadyBeenContacted(true);
                } else {
                  //   props?.setWitnessContactedLabel('Yes');
                  setWitnessHasAlreadyBeenContacted(false);
                }
              }}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Witness;
