import React, { Component, useContext, useEffect, useState } from 'react';
import CurriculumContext from '../../context/curriculum/curriculumContext';
import { Button } from 'react-bootstrap';

const SelectedCurriculum = (props) => {
  const [curriculumSelected, setCurriculumSelected] = useState(false);

  let curriculumContext = useContext(CurriculumContext);
  let {
    getLessonPlansForCurriculum,
    lessonPlansForCurriculum,
    adoptCurriculum_v03,
  } = curriculumContext;

  //   useEffect(() => {
  //     console.log('loading ..');
  //     // eslint-disable-next-line
  //   }, []);
  return (
    <div
      className="card"
      onClick={() => {
        setCurriculumSelected(!curriculumSelected);
        //get all lesson plans and return their objects
        getLessonPlansForCurriculum(props.curriculum._id);
      }}
    >
      <div className="row top-right">{props.curriculum.dance_level}</div>
      <div className="row">
        {/* <h5>{props.curriculum._id}</h5> */}
        <h5>{props.curriculum?.lesson_plans_by_week.length} Lesson Plans</h5>
      </div>

      {curriculumSelected ? (
        <div>
          <Button
            onClick={() => {
              let obj = {
                curriculum_id_list: [props.curriculum._id],
                adoptingCompanyId: props.school_id,
              };
              adoptCurriculum_v03(obj);
            }}
          >
            Adopt Curriculum
          </Button>
          {lessonPlansForCurriculum &&
            lessonPlansForCurriculum.map((lessonPlan, index) => (
              //   <div>{lessonPlan._id}</div>

              <div>
                <h1>Lesson Plan {index + 1}</h1>
                {lessonPlan.moves_to_learn.map((move) => (
                  <div>{move.move_name}</div>
                ))}
              </div>
            ))}
          {/* {props.curriculum.lesson_plans_by_week.map((lessonPlan) => (
            <div>{lessonPlan._id}</div>
          ))} */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SelectedCurriculum;
