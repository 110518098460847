import React, { Component, useContext, useEffect, useState } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import AlertContext from '../../context/alert/alertContext';
import UserContext from '../../context/user/userContext';
import { Navigate, useNavigate } from 'react-router-dom';
import PotentialConnections from '../user/PotentialConnections';

const UserVerificationAndClaim = (props) => {
  useEffect(() => {
    getUser(user?._id);
    console.log('user');
    console.log(user);

    // eslint-disable-next-line
  }, []);
  //load artist photo,name
  const userContext = useContext(UserContext);
  const {
    user_verification,
    sendUserVerificationAndClaimArtist,
    getUser,
    user,
  } = userContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  //get user
  const [authState, authDispatch] = useAuth();

  const { loadUser } = authState;

  const [verificationDetails, setVerificationDetails] = useState({
    verification_image: '',
    requesting_user_id: props?.user?._id,
    // image_for_verification: '',
    claim_type: 'artist',
    account_id_to_claim: props.artist_id_to_claim,
    requesting_user_name: props?.user?.name,
    requesting_user_image: props?.user?.image,
  });
  const {
    verification_image,
    requesting_user_id,
    // image_for_verification,
    claim_type,
    account_id_to_claim,
  } = verificationDetails; //?
  const [file, setFile] = useState();
  let navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', file);
    data.append('image_for_verification', verification_image);

    data.append('requesting_user_id', props?.user?._id);
    data.append('claim_type', claim_type);
    data.append('account_id_to_claim', account_id_to_claim);
    data.append('requesting_user_name', props?.user?.name);
    data.append('requesting_user_image_url', props?.user?.image?.url);
    if (verification_image === '') {
      setAlert('Please enter all fields', 'danger');
    } else {
      console.log('user is ---');
      console.log(user);
      sendVerificationImage(data);
      //navigate to home/onboarding

      // return (
      //   <PotentialConnections
      //     city={props?.user?.location ? props?.user?.location : 'New York City'}
      //     claim_message={`We have received your claim request and a member from our team will follow up shortly`}
      //   />
      // );
      if (props?.onboarding !== true) {
        navigate('/', {
          state: {
            messageText:
              'We have received your claim request and a member from our team will follow up shortly',
          },
        });
      } else {
        props?.setShowArtistClaimedMsg(true);
      }
    }
  };

  const onChange = (e) =>
    setVerificationDetails({
      ...verificationDetails,
      [e.target.name]: e.target.value,
    });

  const sendVerificationImage = (data) => {
    //call fbackend
    sendUserVerificationAndClaimArtist(data);
    console.log('seding verification -- verificationDetails:');
    console.log(verificationDetails);
  };
  return (
    <div>
      {user && (
        <div>
          <h3>Hello {user?.name}</h3>
          <h5 style={{ fontSize: '14px' }}>
            To claim an artist page as your own, submit photo identification. An
            Admin will review your request as soon as possible.
          </h5>
        </div>
      )}
      <div>
        <h5>
          <span className="text-primary">Artist to claim</span>{' '}
          {props.artist_id_to_claim}
        </h5>
      </div>

      <hr />

      <div className="form-container">
        <label htmlFor="verification_image">Photo Identification</label>

        <form onSubmit={onSubmit}>
          <input
            id="verification_image"
            name="verification_image"
            type="file"
            value={verification_image}
            onChange={(event) => {
              const file = event.target.files[0];
              setFile(file);
              setVerificationDetails({
                ...verificationDetails,
                [event.target.name]: event.target.value,
              });
            }}
          />

          <input
            type="submit"
            value="Claim Artist"
            className="btn btn-primary btn-block"
          />
        </form>
      </div>
    </div>
  );
};

export default UserVerificationAndClaim;
