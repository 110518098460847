//consider renaming to potential_mate to differentiate btw user
import React, { Fragment, useContext, useEffect } from 'react';
import UserContext from '../../context/user/userContext';
import UserItem from './UserItem';
import PropTypes from 'prop-types';

const AllUsers = () => {
  const userContext = useContext(UserContext);

  const { getUsersOneAtATime, users } = userContext;

  useEffect(() => {
    console.log('getting users from useEffect from AllUsers Component');
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    getUsersOneAtATime();
    // getCurrentUser();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {users.map((user) => (
        <UserItem key={user.id} user={user} />
      ))}
      {/* <h1>hi</h1> */}
    </div>
  );
};

AllUsers.propTypes = {};

export default AllUsers;
