import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Link } from 'react-router-dom';

const PremiumPopoverDetail = (props) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">My Premium</Popover.Header>
      <Popover.Body>
        <h5>Here's what you get:</h5>
        <h5>
          ✅ You can now be booked for private lessons directly from all event
          pages
          {/* <strong>perks 1</strong>{' '} */}
        </h5>

        <h5>
          ✅ List your workshops on the event page, even if the event is not
          premium{' '}
        </h5>
        <br />
        {/* <div className="col">
          <Link to={`/go-pro-event/${props.event?._id}`}>
            <button
              style={{
                width: '180px',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '1px',
                borderRadius: '2px',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
              }}
            >
              Access Premium Event
            </button>
          </Link>
        </div>
        <br />
        <div className="col">
          <Link to={`/go-pro-artist/${props.artist?._id}`}>
            <button
              style={{
                width: '180px',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '1px',
                borderRadius: '2px',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
              }}
            >
              Access Premium Artist
            </button>
          </Link>
        </div> */}
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
        <Button variant="success">Premium Artist</Button>
      </OverlayTrigger>
    </div>
  );
};

export default PremiumPopoverDetail;
