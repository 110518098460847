import moment from 'moment';
import React, { Component, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

const PotentialActivities = (props) => {
  const locationObj = useLocation();
  if (locationObj?.state?.potentialConnections) {
    //auto-add to invite list for activity
  }
  let activities = ['Dancing', 'Karaoke', 'Bowling', 'Other'];
  const [selected, setSelected] = React.useState();

  const [activity, setActivity] = useState({
    description: '',
    location: '',
    lessonDate: selected,

    // ownersEmail: '',
    // ownersId: '',
    // phoneNumber: '',
  });

  const { description, location, lessonDate } = activity;
  const onChange = (e) =>
    setActivity({ ...activity, [e.target.name]: e.target.value });

  let times_array = [
    '05:00',
    '05:30',
    '06:00',
    '07:00',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '12:45',
    '13:00',
    '13:30',
    '13:45',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
  ];
  let workshop_date_array = [];

  const handleTimeChange = (time) => {
    console.log('changed time');
    console.log(time);
    setWorkshopTime(time);
    setActivity({ ...activity, time: time });

    // let date_time = workshopDate + ' ' + time;
    // filterRoomsByDateAndTime(date_time);
  };

  const [workshopTime, setWorkshopTime] = useState('09:00');

  const [workshopDate, setWorkshopDate] = useState(
    moment()
      .zone(0)
      .format('MM-DD-YYYY')
  );

  const handleDateChange = (date) => {
    console.log('date from handleDateChange');
    console.log(date);
    let formattedDate = moment(date).format('MM-DD-YYYY');

    console.log('formattedDate');
    console.log(formattedDate);
    setWorkshopDate(formattedDate);
    setActivity({ ...activity, date: formattedDate });
    //refilter rooms
    // let date_time = formattedDate + ' ' + workshopTime;
    // filterRoomsByDateAndTime(date_time);
  };

  const [updateDate, setUpdateDate] = React.useState();
  const [showSelection, setShowSelection] = React.useState(false);

  const setDatePlease = (selected) => {
    console.log('truing to set date from setDateplease');
    console.log(selected);
    setActivity({ ...activity, lessonDate: selected });
  };
  const [emphasisOnSetADateLabel, setEmphasisOnSetADateLabel] = useState(false);

  const renderFooter = () => {
    if (emphasisOnSetADateLabel) {
      return <p style={{ backgroundColor: 'red' }}>Please pick a day.</p>;
    } else {
      return <p>Please pick a day.</p>;
    }
  };
  let footer = renderFooter();
  if (selected) {
    footer = <p>You picked {format(selected, 'PP')}.</p>;
  }
  if (updateDate) {
    setActivity({
      ...activity,
      lessonDate: selected,
      //   requestor_id: user?._id,
      //   requestor_name: user?.name,
      //   requestor_phone_number: user?.phoneNumber,
    });
    setUpdateDate(false);
  }

  if (showSelection) {
    footer = <p>You picked {format(lessonDate, 'PP')}.</p>;
  }
  return (
    <div className="card">
      <h1> Plan An Activity</h1>
      <div>
        {activities.map((activity) => (
          <Button
            onClick={() => {}}
            style={{
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
            }}
          >
            {activity}
          </Button>
        ))}
      </div>
      <div>
        <label htmlFor="name">Location</label>

        <input
          id="location"
          type="text"
          name="location"
          value={location}
          onChange={onChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="name">Short Description For Attendees</label>
        <br />
        {description.length > 0 ? (
          <label style={{ color: 'red' }} htmlFor="">
            {0}/{60 - description.length} characters left
          </label>
        ) : (
          <></>
        )}
        <input
          id="description"
          type="text"
          name="description"
          value={description}
          onChange={onChange}
          required
        />
      </div>

      <div>
        <label htmlFor="">Select a time</label>
      </div>

      <div className="col" style={{ paddingTop: '14px' }}>
        {[DropdownButton].map((DropdownType, idx) => (
          <DropdownType
            as={ButtonGroup}
            key={idx}
            id={`dropdown-button-drop-${idx}`}
            size="sm"
            variant="secondary"
            title={workshopTime}
            onSelect={handleTimeChange}
            // disabled="true"

            // onClick={() => {
            //   setLevelButtonClicked(!levelButtonClicked);
            // }}
            //   onSelect={handleSelectLevel}
          >
            {times_array.map((time) => (
              <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>
            ))}
          </DropdownType>
        ))}
      </div>

      <DayPicker
        mode="single"
        selected={selected}
        onSelect={setSelected}
        footer={footer}
        onDayClick={() => {
          // if(stateUpdated()){

          setSelected(selected);
          setDatePlease(selected);
          console.log('hello');
          console.log('selected');
          console.log(selected);
          setActivity({ ...activity, lessonDate: selected });
          setUpdateDate(true);
          // }
        }}
      />
      <div>
        <Button>Add Activity</Button>
      </div>
    </div>
  );
};
export default PotentialActivities;
