import React, { Fragment } from 'react';

// import { useLocation } from 'react-router-dom';
// import { useAuth, clearErrors, login } from '../../context/auth/AuthState';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import CurrentUserProfileHeader from './CurrentUserProfileHeader';
import UpcomingEvents from '../events/UpcomingEvents';
import UpcomingEventsNew from '../user/UpcomingEventsNew';
import { logout, useAuth } from '../../context/auth/AuthState';
import { AdAnalyticsSidePanel } from '../side-panel/currentUserProfile/AdAnalyticsSidePanel';
import AdPanel from '../side-panel/currentUserProfile/AdPanel';
import { useState, useEffect, useContext } from 'react';
import Calendar from '../artist/Calendar';
import InstructorContext from '../../context/instructor/instructorContext';

// import { Link } from 'react-router-dom';
import EditUserProfile from './EditUserProfile';
import Scores from '../studio/Scores';
import ArtistTools from './ArtistTools';
import UserReferrals from './UserReferrals';
import Calendar2 from '../artist/Calendar2';
import PrivateLessonRequestNoEvent from '../artist/PrivateLessonRequestNoEvent';
import BookPrivatePopup from '../instructor/BookPrivatePopup';
import ArtistInstructorPrivateLessons from '../artist-instructor/ArtistInstructorPrivateLessons';
import ArtistContext from '../../context/artist/artistContext';
import StudentPrivateLessonsPopup from './StudentPrivateLessonsPopup';
import CurrentUserTour from './CurrentUserTour';
import AddNewTour from '../tour/AddNewTour';

// import EditUserProfile_new from './EditUserProfile_new';
// import EditUserProfileImage from './EditUserProfileImage';

// export default class UserProfile extends Component {
const CurrentUserProfile = (props) => {
  const instructorContext = useContext(InstructorContext);
  const {
    getPrivateLessonRequestsForInstructorAtEvents,
    privateLessonRequestsForInstructorAtEvents,
    getPrivateLessonRequestsForInstructor,
    privateLessonRequestsForInstructor,
    decisionPrivateLessonRequestForInstructorAtEvent,
    getConfirmedPrivateLessonsForInstructorAtEvents,
    confirmedPrivateLessonsForInstructorAtEvents,
    confirmedPrivateLessonsForInstructorNoEvent,
    getConfirmedPrivateLessonsForInstructorNoEvent,
  } = instructorContext;

  const artistContext = useContext(ArtistContext);
  const {
    getPrivateLessonRequestsForArtist,
    getPrivateLessonRequestsForArtistAtEvents,
    privateLessonRequestsAtEvents,
    privateLessonRequests,
    getConfirmedPrivateLessonsForArtistInstructorAtEvents,
    getConfirmedPrivateLessonsForArtistInstructorNoEvent,
    confirmedPrivateLessonsForArtistInstructorAtEvents,
    confirmedPrivateLessonsForArtistInstructorNoEvent,
  } = artistContext;

  const [shareButtonText, setShareButtonText] = React.useState('Share Profile');
  const [showStudentScores, setShowStudentScores] = useState(false);

  const [authState, authDispatch] = useAuth();
  const [calendarSelected, setCalendarSelected] = React.useState(false);
  const [bookPrivateSelected, setBookPrivateSelected] = React.useState(false);
  const [studentLessonsClicked, setStudentLessonsClicked] = useState(false);

  console.log('confirmedPrivateLessonsForInstructorAtEvents --');
  console.log(confirmedPrivateLessonsForInstructorAtEvents);

  // console.log('location.state is:-----------');
  // const location = useLocation();

  // console.log(location.state);

  // const { error, isAuthenticated } = authState;
  const { user, loading, isAuthenticated } = authState;
  // const { loading, isAuthenticated } = authState;
  // const { user } = props;
  useEffect(() => {
    // if (user?.level_progression) {
    //   console.log('user.level_progression');
    //   console.log(user.level_progression);
    //   //getStudentScores(user._id)
    // }
    if (user && user?._id && isNotArtistButInstructorOnlyAccount()) {
      // if (Object.keys(user).length !== 0 && user.constructor === Object) {
      console.log(
        'trying to getPrivateLessonRequestsForInstructorAtEvents from currentuserprofile.js '
      );
      getPrivateLessonRequestsForInstructor(user?._id);

      getPrivateLessonRequestsForInstructorAtEvents(user?._id);
      getConfirmedPrivateLessonsForInstructorAtEvents(user?._id);
      getConfirmedPrivateLessonsForInstructorNoEvent(user?._id);
    } else {
      console.log('no user bruh');
    }

    if (user && user?._id && !isNotArtistButInstructorOnlyAccount()) {
      console.log('getting artist privates');
      getPrivateLessonRequestsForArtist(user.artist_id);
      getPrivateLessonRequestsForArtistAtEvents(user.artist_id);
      getConfirmedPrivateLessonsForArtistInstructorNoEvent(user.artist_id);
      getConfirmedPrivateLessonsForArtistInstructorAtEvents(user.artist_id);
    }

    // eslint-disable-next-line
  }, []);

  console.log('user from UP: -----');
  console.log(user);
  console.log('DONE: -----');
  if (!isAuthenticated && !loading) {
    return <Navigate to="/login" />;
  }
  const onLogout = () => {
    logout(authDispatch);
    // clearContacts(contactDispatch);
  };

  // we use this to distinguish btw artist and instructor only
  const isNotArtistButInstructorOnlyAccount = () => {
    console.log('user from isNotArtistButInstructorOnlyAccount ()');
    console.log(user);
    if (user?.artist_id && user?.artist_id !== '') {
      return false;
    }
    return true;
  };

  if (loading) return <h5>loading</h5>;

  return (
    <div>
      {/* <AdAnalyticsSidePanel /> */}
      <AdPanel />

      <CurrentUserProfileHeader user={user} />

      <div>
        <div className="row">
          {/* <div className="col-md-6"> */}
          <div
            class="container-fluid  overflow-auto example"
            style={{ padding: '0px' }}
          >
            <div class="d-flex flex-row flex-nowrap">
              {/* <div className="col"> */}
              {/* 
              <div className="card-horizontal-menu card-block mx-2">
                <div className="col">
                  <Button
                    onClick={() => {
                      setBookPrivateSelected(!bookPrivateSelected);
                      console.log(`setbook is ${bookPrivateSelected}`);
                    }}
                  >
                    Book Private
                  </Button>
                </div>
              </div> */}

              {user?.roles.includes('instructor') ||
              (user?.artist_id && user?.artist_id !== '') ? (
                <div className="card-horizontal-menu card-block mx-2">
                  <div className="col">
                    <Button
                      onClick={() => {
                        setCalendarSelected(!calendarSelected);
                        // if (bookPrivateSelected) {
                        //   setBookPrivateSelected(false);
                        // }
                      }}
                      style={{
                        background: 'white',
                        // color: 'blue',
                        borderStyle: 'solid',
                        borderColor: 'rgb(1, 126, 252)',

                        fontSize: '14px',
                        backgroundColor: 'white',
                        color: 'rgb(1, 126, 252)',
                        textAlign: 'center',
                      }}
                    >
                      {calendarSelected ? 'Hide' : 'Calendar'}
                    </Button>
                  </div>
                  <div>
                    {calendarSelected && (
                      <div>
                        <Modal
                          show={calendarSelected}
                          onHide={() => setCalendarSelected(false)}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Calendar</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {isNotArtistButInstructorOnlyAccount() &&
                            user?.roles?.includes('instructor') ? (
                              <Calendar2
                                confirmedPrivateLessonsAtEvents={
                                  confirmedPrivateLessonsForInstructorAtEvents
                                }
                                confirmedPrivateLessonsNoEvent={
                                  confirmedPrivateLessonsForInstructorNoEvent
                                }
                                privateLessonRequestsAtEvents={
                                  privateLessonRequestsForInstructorAtEvents
                                }
                                privateLessonRequestsForInstructor={
                                  privateLessonRequestsForInstructor
                                }
                                isNotArtistButInstructorOnlyAccount={
                                  isNotArtistButInstructorOnlyAccount
                                }
                              />
                            ) : (
                              <></>
                            )}
                            {/* ArtistInstructor account below */}
                            {!isNotArtistButInstructorOnlyAccount() ? (
                              <ArtistInstructorPrivateLessons
                                confirmedPrivateLessonsAtEvents={
                                  // confirmedPrivateLessonsForInstructorAtEvents
                                  confirmedPrivateLessonsForArtistInstructorAtEvents
                                }
                                confirmedPrivateLessonsNoEvent={
                                  // confirmedPrivateLessonsForInstructorNoEvent
                                  confirmedPrivateLessonsForArtistInstructorNoEvent
                                }
                                privateLessonRequestsAtEvents={
                                  privateLessonRequestsAtEvents
                                }
                                privateLessonRequestsForInstructor={
                                  privateLessonRequests
                                }
                                isNotArtistButInstructorOnlyAccount={
                                  isNotArtistButInstructorOnlyAccount
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </Modal.Body>
                        </Modal>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="card-horizontal-menu card-block mx-2">
                <Button
                  style={{
                    width: 'max-content',
                    background: 'white',
                    // color: 'blue',
                    borderStyle: 'solid',
                    borderColor: 'rgb(1, 126, 252)',

                    fontSize: '14px',
                    backgroundColor: 'white',
                    color: 'rgb(1, 126, 252)',
                    textAlign: 'center',
                  }}
                  onClick={() =>
                    setStudentLessonsClicked(!studentLessonsClicked)
                  }
                >
                  Student Private Lessons
                </Button>
              </div>
              {studentLessonsClicked && (
                <div className="card-horizontal-menu card-block mx-2">
                  <StudentPrivateLessonsPopup
                    studentLessonsClicked={studentLessonsClicked}
                    setStudentLessonsClicked={setStudentLessonsClicked}
                    user={user}
                  />
                </div>
              )}
              <div className="card-horizontal-menu card-block mx-2">
                <Button
                  variant="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      window.location.href + '/' + user._id
                    );
                    setShareButtonText('copied');

                    console.log('copied text');
                  }}
                  style={{
                    width: 'max-content',
                    background: 'white',
                    // color: 'blue',
                    borderStyle: 'solid',
                    borderColor: 'rgb(1, 126, 252)',

                    fontSize: '14px',
                    backgroundColor: 'white',
                    color: 'rgb(1, 126, 252)',
                    textAlign: 'center',
                  }}
                >
                  {shareButtonText}
                </Button>
              </div>

              <div className="card-horizontal-menu card-block mx-2">
                <EditUserProfile />
              </div>

              <div className="card-horizontal-menu card-block mx-2">
                <Link
                  style={{ color: 'black' }}
                  to={`/my-connections`}
                  state={{ target_user: user }}
                >
                  <h5 style={{ width: 'max-content', paddingTop: '6px' }}>
                    {user?.connections?.length} Connections
                  </h5>
                </Link>
              </div>

              <div className="card-horizontal-menu card-block mx-2">
                {user?.level_progression ? (
                  <Button
                    onClick={() => {
                      setShowStudentScores(!showStudentScores);
                    }}
                    style={{
                      width: 'max-content',
                      background: 'white',
                      // color: 'blue',
                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',

                      fontSize: '14px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      textAlign: 'center',
                    }}
                  >
                    {showStudentScores ? 'Close' : 'Student Scores'}
                  </Button>
                ) : (
                  <></>
                )}
                {showStudentScores && (
                  <div>
                    <hr />
                    {user?.level_progression?.scores_and_styles.map(
                      (scores) => (
                        <Scores
                          scores={scores}
                          member_id={user._id}
                          // studio_id={user?.dance_company_id}
                          studio_id={user?.companies[0].company_id}
                          isStudentAccount={true}
                        />
                      )
                    )}
                  </div>
                )}
              </div>

              {user?.artist_id && (
                <div className="card-horizontal-menu card-block mx-2">
                  {/* {user?.level_progression?.scores_and_styles.map((scores) => ( */}
                  <Link to={`/artist/tools/${user.artist_id}`}>
                    <Button
                      style={{
                        width: 'max-content',
                        background: 'white',
                        // color: 'blue',
                        borderStyle: 'solid',
                        borderColor: 'rgb(1, 126, 252)',

                        fontSize: '14px',
                        backgroundColor: 'white',
                        color: 'rgb(1, 126, 252)',
                        textAlign: 'center',
                      }}
                    >
                      Artist Tools
                    </Button>
                  </Link>
                  {/* <ArtistTools
                  instructor_id={user?.artist_id}
                  // scores={scores}
                  // member_id={user._id}
                  // // studio_id={user?.dance_company_id}
                  // studio_id={user?.companies[0].company_id}
                  // isStudentAccount={true}
                /> */}
                  {/* ))} */}
                </div>
              )}

              {/* </div> */}
              {/* <EditUserProfileImage /> */}

              {user && (
                <div className="card-horizontal-menu card-block mx-2">
                  <UserReferrals user={user} />
                </div>
              )}
              {user && (
                <div className="card-horizontal-menu card-block mx-2">
                  <Button
                    variant="primary"
                    style={{
                      width: 'max-content',
                      background: 'white',
                      // color: 'blue',
                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',

                      fontSize: '14px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      textAlign: 'center',
                    }}
                  >
                    <Link
                      to={`/my-incident-reports/${user?._id}`}
                      style={{
                        color: 'rgb(1, 126, 252)',
                      }}
                    >
                      My Incident Reports
                    </Link>
                  </Button>
                </div>
              )}

              <div className="card-horizontal-menu card-block mx-2">
                <Button variant="danger" style={{ width: 'max-content' }}>
                  <a onClick={onLogout} href="/">
                    <i
                      className="fas fa-sign-out-alt"
                      style={{ fontSize: '12px' }}
                    />{' '}
                    <span className="hide-sm" style={{ fontSize: '12px' }}>
                      Logout
                    </span>
                  </a>
                </Button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <br />
        {!bookPrivateSelected &&
          !calendarSelected &&
          isNotArtistButInstructorOnlyAccount() && (
            <div>
              <h5>
                {privateLessonRequestsForInstructor.length > 0
                  ? `Private Lesson Requests`
                  : `Private Lesson Requests Will Be Shown Here`}
              </h5>
              {privateLessonRequestsForInstructor.map((lesson) => (
                <PrivateLessonRequestNoEvent
                  key={lesson._id}
                  lesson={lesson}
                  user={user}
                  isNotArtistButInstructorOnlyAccount={
                    isNotArtistButInstructorOnlyAccount
                  }
                  // artist={artist}
                />
              ))}
            </div>
          )}

        <hr />
        <div>
          {!showStudentScores && (
            <div>
              <h3>Upcoming Events</h3>

              {/* <UpcomingEvents showDeleteButton={true} /> */}
              <UpcomingEventsNew />
            </div>
          )}
        </div>

        <div className="col-md-6" />
        <hr />
        <div className="row">
          <div className="col-7">
            <h1>Upcoming Tours</h1>
          </div>
          <div className="col">
            <AddNewTour instructor_id={user._id} instructor_name={user.name} />
            {/* <Button onClick={()=>{setAddNewTourClicked(!addNewTourClicked)}}>Add Tour</Button> */}
          </div>
        </div>
        <CurrentUserTour instructor_id={user._id} />

        {/* the below showDeleteButton will only be going thru
        when the current user goes to their profile page either thru 
        search or thru profile nav btn
      */}

        {/* <CurrentUserProfileHeader profile={profile} /> */}
        {/* <ProfileAbout profile={profile} />
        <ProfileCreds
          education={profile.education}
          experience={profile.experience}
        />
        {profile.githubusername ? (
          <ProfileGithub username={profile.githubusername} />
        ) : null} */}
      </div>
    </div>
  );
};
export default CurrentUserProfile;
