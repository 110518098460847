import React, { Component, useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import UserContext from '../../context/user/userContext';
import PrivateLesson from './PrivateLesson';

const StudentPrivateLessonsPopup = (props) => {
  const [show, setShow] = useState(props.studentLessonsClicked);
  const handleClose = () => props.setStudentLessonsClicked(false);

  const userContext = useContext(UserContext);
  const { getMyStudentPrivateLessons, myPrivateLessons } = userContext;

  useEffect(() => {
    getMyStudentPrivateLessons(props?.user._id);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 style={{ fontSize: '16px' }}>Student Private Lessons</h5>

            <h5 style={{ fontSize: '12px' }}>
              This is where lessons you have requested will show
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {myPrivateLessons.length > 0 &&
            myPrivateLessons?.map((request) => (
              //   <h5>{request._id}</h5>
              <PrivateLesson request={request} />
            ))}

          {myPrivateLessons.length === 0 && (
            <div>
              <h5>You have not requested any private lessons yet</h5>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default StudentPrivateLessonsPopup;
