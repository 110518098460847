import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import RankFields from './rank/RankFields';
import Calendar from '../artist/Calendar';
import InstructorContext from '../../context/instructor/instructorContext';
import { useParams } from 'react-router-dom';
import BookPrivatePopup from '../instructor/BookPrivatePopup';

const ProfileHeader = (props) => {
  const userContext = useContext(UserContext);

  const instructorContext = useContext(InstructorContext);
  let { instructor_id } = useParams(); //this is the id

  const {
    getPrivateLessonRequestsForInstructorAtEvents,
    privateLessonRequestsForInstructorAtEvents,
  } = instructorContext;
  console.log('privateLessonRequestsForInstructorAtEvents --');
  console.log(privateLessonRequestsForInstructorAtEvents);

  const { connectToUser, addToSafetyCouncil } = userContext;
  let copyClicked = false;
  const { user, current_user_id, isCurrentUser, isAdmin } = props;
  let { id } = useParams();

  useEffect(() => {
    alreadyConnected();
    console.log('id');
    console.log(id);
    console.log('isCurrentUserProfile');
    console.log(props.isCurrentUser);
    console.log('user._id ---');
    console.log(user._id);
    // getPrivateLessonRequestsForInstructorAtEvents(user?._id);
    getPrivateLessonRequestsForInstructorAtEvents(id);
    // eslint-disable-next-line
  }, []);
  const [bookPrivateSelected, setBookPrivateSelected] = React.useState(false);

  const [shareButtonText, setShareButtonText] = React.useState('Share Profile');

  const isNotConnected = () => {
    return (
      typeof current_user_id !== 'undefined' &&
      !alreadyConnected() &&
      !isCurrentUser
    );
  };
  const [connectButtonText, setConnectButtonText] = useState(
    isNotConnected ? `Connect` : `Connected`
  );

  const connectToUserFunction = (e) => {
    e.preventDefault();

    let obj = {
      userIdToConnectTo: user._id,
      current_user_id: current_user_id,
      // eventId: roomie_request.event,
      // accepted_user_id: roomie_request.requestor,
    };
    console.log(obj);
    setConnectButtonText('Connected');

    // let notification_obj = {
    //   current_user_id: user._id,
    //   // eventId: roomie_request.event,
    //   accepted_user_id: roomie_request.requestor,
    //   //will also need accepting users name
    // };

    connectToUser(obj);
    // sendSMSAlertForAcceptedRoommate(notification_obj);
    console.log('success????');
  };

  const alreadyConnected = () => {
    return user?.connections?.includes(current_user_id);
  };

  // const { profile } = this.props;
  let image;
  if (user?.image) {
    image = user?.image.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }

  const notClicked = (
    // <div className="card-horizontal-menu card-block mx-2">
    <Fragment>
      <Button
        variant="primary"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          console.log('copied text');
          copyClicked = true;
          setShareButtonText('copied');
        }}
        style={{
          background: 'white',
          width: 'max-content',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '6px',
        }}
      >
        {shareButtonText}
      </Button>
    </Fragment>
    // </div>
  );
  const clicked = (
    <Fragment>
      {/* <div className="col-3"> */}
      <div className="card-horizontal-menu card-block mx-2">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          Profile Link Copied
        </button>
      </div>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!copyClicked) {
      console.log('no user found');
      return notClicked;
    } else {
      return clicked;
    }
  };

  const usersNotConnected = (
    // <div className="card-horizontal-menu card-block mx-2">
    <Fragment>
      <div className="card-horizontal-menu card-block mx-2">
        {/* <div className="col-3"> */}
        <Button
          type="button"
          // className="btn btn-dark"
          className=""
          onClick={connectToUserFunction}
          disabled={connectButtonText === 'Connected'}
          style={{
            background: 'white',
            // color: 'blue',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            textAlign: 'center',
            padding: '6px',
          }}
        >
          {connectButtonText}
        </Button>
      </div>
    </Fragment>
    // </div>
  );

  const connected = () => {
    if (
      typeof current_user_id !== 'undefined' &&
      !alreadyConnected() &&
      !isCurrentUser
    ) {
      return usersNotConnected;
    } else {
      return <></>;
    }
  };
  const [calendarSelected, setCalendarSelected] = React.useState(false);
  const [privateLessonRequestSent, setPrivateLessonRequestSent] = useState(
    false
  );
  const setPrivateLessonRequestSentFromChild = (privateLessonRequestSent) => {
    setPrivateLessonRequestSent(privateLessonRequestSent);
    // setBookPrivateWasClicked(!bookPrivateWasClicked);
    setBookPrivateSelected(false);
    console.log('privateLessonRequestSent from parent');
    console.log(privateLessonRequestSent);
  };
  return (
    <div className="row">
      {privateLessonRequestSent && (
        <div className="row">
          <Button
            style={{ color: 'white', backgroundColor: '#53cf00' }}
            className="all-center"
          >
            private lesson request sent !
          </Button>
        </div>
      )}
      <div className="col-md-12">
        <div className="card card-body bg-primary text-white mb-3">
          <div className="row text-center">
            <div>
              <RankFields
                artist={user}
                artistWorldRanking={props.dancerWorldRanking}
                rankings={props.rankings}
                // setShowOtherRank={setShowOtherRank}
                // showOtherRank={showOtherRank}
                // setRankingsToShow = {setRankingsToShow}
              />
            </div>
            {/* <div className="col col-md-3 m-auto">
              <img
                className="rounded-circle"
                //   src={profile.user.avatar}
                // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                src={image}
                alt=""
                height="150"
                width="150"
              />
            </div>

            <h3 className="display-4 text-center">{user.name}</h3> */}

            {/* <h4>{user?.location}</h4> */}

            <Link to={`/my-connections`} state={{ target_user: user }}>
              {user?.connections?.length} Connections
            </Link>

            <h5 className="">{user?.dance_company}</h5>
            {user?.companies &&
              user?.companies.length > 0 && (
                <div>
                  <Link to={`/studio/${user.companies[0].company_id}`}>
                    <Button
                      className="display-12"
                      style={{
                        // width: '220px',
                        background: 'white',

                        borderStyle: 'solid',
                        borderColor: 'rgb(1, 126, 252)',
                        borderWidth: '2px',
                        borderRadius: '6px',

                        fontSize: '14px',
                        backgroundColor: 'white',
                        color: 'rgb(1, 126, 252)',
                        textAlign: 'center',
                      }}
                    >
                      {/* {user?.dance_company} */}

                      {user.companies[0].company_name}
                    </Button>
                  </Link>
                </div>
              )}

            {/* <div className="row"> */}
            {/* <div className="col-3"> */}
            {/* <button
                  onClick={connectToUserFunction}
                  class="btn btn-primary my-2 my-sm-0"
                  type="submit"
                >
                  Add Connectionx
                </button> */}
            <div
              class="container-fluid  overflow-auto example"
              style={{ padding: '0px' }}
            >
              <div class="d-flex flex-row flex-nowrap">
                {/* <div className=""> */}
                {/* <div className="col"> */}

                {user && isCurrentUser ? (
                  <div className="card-horizontal-menu card-block mx-2">
                    <Button
                      onClick={() => {
                        setCalendarSelected(!calendarSelected);
                        // if (bookPrivateSelected) {
                        //   setBookPrivateSelected(false);
                        // }
                      }}
                      style={{
                        background: 'white',
                        // color: 'blue',
                        borderStyle: 'solid',
                        borderColor: 'rgb(1, 126, 252)',

                        fontSize: '14px',
                        backgroundColor: 'white',
                        color: 'rgb(1, 126, 252)',
                        textAlign: 'center',
                        padding: '6px',
                      }}
                    >
                      {calendarSelected ? 'Hide' : 'Calendar'}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}

                <div className="card-horizontal-menu card-block mx-2">
                  {/* <div className="col"> */}
                  <Button
                    onClick={() => {
                      setBookPrivateSelected(!bookPrivateSelected);
                      console.log(`setbook is ${bookPrivateSelected}`);
                    }}
                    style={{
                      width: 'max-content',
                      background: 'white',
                      // color: 'blue',
                      borderStyle: 'solid',
                      // borderColor: 'rgb(1, 126, 252)',

                      fontSize: '14px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      textAlign: 'center',
                      padding: '6px',
                    }}
                  >
                    Book Private 👍🏽
                  </Button>
                  {/* </div> */}
                </div>
                <div>
                  {calendarSelected && (
                    <div>
                      <Modal
                        show={calendarSelected}
                        onHide={() => setCalendarSelected(false)}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Calendar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Calendar
                            privateLessonRequestsAtEvents={
                              privateLessonRequestsForInstructorAtEvents
                            }
                          />
                        </Modal.Body>
                      </Modal>
                    </div>
                  )}
                </div>

                {connected()}
                <div className="card-horizontal-menu card-block mx-2">
                  {renderOptions()}
                </div>
              </div>

              {isAdmin &&
              (user?.safety_council_member === false ||
                !('safety_council_member' in user)) ? (
                <div className="">
                  <Button onClick={() => addToSafetyCouncil(user._id)}>
                    add to safety council
                  </Button>
                </div>
              ) : (
                <></>
              )}
              {isAdmin && user?.safety_council_member === true ? (
                <div className="">
                  <Button onClick={() => console.log('remove user')}>
                    remove from safety council
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* <div className="card-horizontal-menu card-block mx-2"> */}
            {/* </div> */}
            {/* </div> */}
            <div className="col">
              {/* <Button
                  variant="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    console.log('copied text');
                    copyClicked = true;
                  }}
                >
                  Share Profile
                </Button> */}
            </div>
            {user?.instagram_name ? (
              <h5 style={{ fontSize: '16px' }}>
                {' '}
                Instagram: {user?.instagram_name}
              </h5>
            ) : (
              <></>
            )}
            {user?.tiktok_name ? (
              <h5 style={{ fontSize: '16px' }}> Tiktok: {user?.tiktok_name}</h5>
            ) : (
              <></>
            )}

            {user?.website_name ? (
              <h5 style={{ fontSize: '16px' }}>
                {' '}
                Website: {user?.website_name}
              </h5>
            ) : (
              <></>
            )}

            {user?.website_name || user?.tiktok_name || user?.instagram_name ? (
              <hr />
            ) : (
              <></>
            )}
            <h5 style={{ fontSize: '16px' }}>{user?.about_you}</h5>
            {/* </div> */}
          </div>
          {bookPrivateSelected && (
            <div>
              <BookPrivatePopup
                bookPrivateSelected={bookPrivateSelected}
                setBookPrivateSelected={setBookPrivateSelected}
                instructor_id={instructor_id}
                dancer={user}
                onSendPrivateLessonRequest={
                  setPrivateLessonRequestSentFromChild
                }
              />
            </div>
          )}
          <div className="text-center">
            {/* <h1 className="display-4 text-center">{profile.user.name}</h1> */}

            {/* <h5 className="text-left">{user.dance_company}</h5>
              <h5 className="text-left">{user.location}</h5> */}

            {/* <p className="lead text-center">
                {profile.status}{' '}
                {isEmpty(profile.company) ? null : (
                  <span>at {profile.company}</span>
                )}
              </p> */}
            {/* {isEmpty(profile.location) ? null : <p>{profile.location}</p>}
              <p>
                {isEmpty(profile.website) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.website}
                    target="_blank"
                  >
                    <i className="fas fa-globe fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.twitter) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.twitter}
                    target="_blank"
                  >
                    <i className="fab fa-twitter fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.facebook) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.facebook}
                    target="_blank"
                  >
                    <i className="fab fa-facebook fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.linkedin) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.linkedin}
                    target="_blank"
                  >
                    <i className="fab fa-linkedin fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.youtube) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.youtube}
                    target="_blank"
                  >
                    <i className="fab fa-youtube fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.instagram) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.instagram}
                    target="_blank"
                  >
                    <i className="fab fa-instagram fa-2x" />
                  </a>
                )} */}
            {/* </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
