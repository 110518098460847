import React, { Component, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useAuth } from '../../context/auth/AuthState';
import ReactionContext from '../../context/reaction/reactionContext';

const EmojiButton = (props) => {
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated, user } = authState;

  const reactionContext = useContext(ReactionContext);
  const {
    getReactionsForDancer,
    reactionsForDancer,
    reactionFromUser,
    postReactionForDancer,
  } = reactionContext;
  //get dancer.reactions
  const [buttonClicked, setButtonClicked] = useState(false);

  let { emoji, dancer } = props;

  const matchEmoji = (emoji) => {
    let emoji_and_count = '';
    let emojiFound = false;
    if (dancer?.reactionsCountArray.length > 0) {
      for (let i = 0; i < dancer?.reactionsCountArray.length; i++) {
        if (dancer.reactionsCountArray[i].emoji === emoji) {
          emoji_and_count =
            dancer.reactionsCountArray[i].numberOfReactions + ' ' + emoji;
          emojiFound = true;
          //set state to 1+dancer.reactionsCountArray[i].numberOfReactions
          //   setCountStateForEmoji(
          //     emoji,
          //     dancer.reactionsCountArray[i].numberOfReactions
          //   );
        }
      }

      //check if  emoji was found and if not add with 0
      if (!emojiFound) {
        //set state to 1
        // setCountStateForEmoji(emoji, 0);

        emoji_and_count = `0 ${emoji}`;
      }
    } else {
      //set state to 1
      //   setCountStateForEmoji(emoji, 0);
      emoji_and_count = `0 ${emoji}`;
    }

    return emoji_and_count;
  };

  const reactToDancer = (selectedEmoji) => {
    // dancer_reaction_id_to_react_to,
    //   reacting_user_id,
    //   reacting_user_name,
    //   reaction_emoji,
    //   comment,

    // let  reacting_user_id = user !== null ? "" : "";
    let obj = {
      dancer_reaction_id_to_react_to: dancer._id,
      reacting_user_id: isAuthenticated ? user._id : '',
      reacting_user_name: isAuthenticated ? user.name : '',
      //   reacting_user_name: {user ? user.name:""},
      reaction_emoji: selectedEmoji,
      comment: '',
    };

    console.log(obj);
    postReactionForDancer(obj);
  };
  return (
    <div>
      {/* <h5 style={{ fontSize: '16px' }}> 0 {emoji}</h5> */}
      <Button
        onClick={() => {
          console.log(`clicked ${emoji} ---`);
          console.log(dancer);
          reactToDancer(emoji);
          setButtonClicked(!buttonClicked);
        }}
        style={{
          fontSize: '16px',
          background: 'transparent',

          color: 'black',
          display: 'inline',
          width: '50px',
          padding: 0,
          borderStyle: 'solid',
          borderRadius: '1rem',
          // borderColor:
          //   buttonClicked === true ? 'rgb(1, 126, 252)' : 'transparent',
          borderColor: 'rgb(1, 126, 252)',

          backgroundColor:
            buttonClicked === true ? 'rgb(1, 126, 252)' : 'transparent',
          borderWidth: '1px',
        }}
        disabled={buttonClicked}
      >
        {matchEmoji(emoji)}
      </Button>
    </div>
  );
};
export default EmojiButton;
