import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import ReactionContext from '../../context/reaction/reactionContext';
import Category from './Category';

const DanceReactions = (props) => {
  const reactionContext = useContext(ReactionContext);
  const { getAllReactions, all_reactions } = reactionContext;

  useEffect(() => {
    getAllReactions();
    // eslint-disable-next-line
  }, []);

  const [showWorkshopSchedule, setShowWorkshopSchedule] = useState(false);
  const handleShowWorkshopSchedule = () => {
    setShowWorkshopSchedule(!showWorkshopSchedule);
  };
  const [visible, setVisible] = React.useState(false);

  const showDancers = () => {
    // let showDancers = false;

    if (props.dance_reaction_elected === true) {
      console.log('props from event DanceReactionsComponent');
      console.log(props);
      return props.dance_reaction_elected;
    } else {
      return false;
    }
  };
  const [showDanceReactions, setShowDanceReactions] = useState(showDancers);

  const handleShowDancerReactions = () => {
    setShowDanceReactions(!showDanceReactions);
    console.log(`showLineup is: ${showDanceReactions}`);
  };

  const handleCloseDanceReactions = () => {
    console.log('closing ');
    setShowDanceReactions(false);
  };
  console.log('all_reactions from DR ----');
  console.log(all_reactions);
  return (
    <div>
      <Button onClick={() => handleShowDancerReactions()}>
        Dance Reactions
      </Button>
      <Modal show={showDanceReactions} onHide={handleCloseDanceReactions}>
        <Modal.Header closeButton>
          <Modal.Title>Dance Reactions</Modal.Title>
        </Modal.Header>
        <div>
          <Modal.Body>
            <div className="row">
              {/* {renderSalsaButton()}
              {renderBachataButton()}

              {renderKizombaButton()}
              {renderZoukButton()} */}

              {all_reactions.map((reactions) => (
                <div>
                  <Category reactions={reactions} />
                </div>
              ))}
            </div>
            <hr />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDanceReactions}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default DanceReactions;
