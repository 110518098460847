import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AllTrivia = (props) => {
  let { trivia } = props;
  return (
    <div style={{ paddingTop: '16px' }}>
      <Link to={`/trivia/${trivia._id}`}>
        <Button>{trivia.trivia_name}</Button>
      </Link>
    </div>
  );
};

export default AllTrivia;
