import React, { Component, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import AdPanelStyle from './AdPanelStyle';
import AdvertisementContext from '../../context/advertisement/advertismentContext';
import { Link } from 'react-router-dom';
import HappeningThisWeekPanelStyle from './HappeningThisWeekPanelStyle';
import EventsThisWeekContext from '../../context/eventsThisWeek/eventsThisWeekContext';
// const moment = require('moment');

const HappeningThisWeekPanel = (props) => {
  const advertisementContext = useContext(AdvertisementContext);
  const { getSideAd, side_advertisement } = advertisementContext;
  const eventsThisWeekContext = useContext(EventsThisWeekContext);
  const { getAllEventsThisWeek, eventsThisWeek } = eventsThisWeekContext;

  useEffect(() => {
    console.log(
      'getting events from Event.js but need to change component name'
    );
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    getSideAd();
    getAllEventsThisWeek();

    console.log(side_advertisement);
    console.log('side_advertisement');
    // eslint-disable-next-line
  }, []);

  // let eventsThisWeek = [
  //   'noche sabor con rumbao',
  //   'qcz classes',
  //   'class with afincao',
  //   'noche sabor con rumbao',
  //   'qcz classes',
  //   'class with afincao',
  //   'noche sabor con rumbao',
  //   'qcz classes',
  //   'class with afincao',
  // ];

  return (
    <HappeningThisWeekPanelStyle>
      {/* <div> */}
      {/* <h5>Ad</h5> */}

      <div class="sidenav blog">
        <h5>Happening This Week</h5>
        <hr />
        {eventsThisWeek.map((event, index) => (
          <div>
            <h5>{index + 1 + ' ' + event.name}</h5>

            {/* <h5
              style={{
                fontSize: '8px',
                right: '0',
                width: 'fit-content',

                borderColor: 'rgb(1, 126, 252)',
                borderRadius: '1rem',
                background: 'white',
                color: 'rgb(1, 126, 252)',
                borderStyle: 'solid',
                textAlign: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',

                // borderColor: 'rgb(1, 126, 252)',
              }}
            >
              Queen City Zouk
            </h5> */}
          </div>
        ))}

        {/* <div className="all-center">
          <div className="">
            <img
              className="rounded-circle"
              src={side_advertisement.adImage?.url}
              alt=""
              height="150"
              width="150"
            />
          </div>
          <h4>{side_advertisement.promotionText}</h4>
          <Button className="text-center" variant="btn btn-info">
            <Link
              to={`/user/profile/${side_advertisement.ownersId}`}
              className="btn btn-info"
            >
              View Profile
            </Link>
          </Button>
        </div> */}
        {/* <Button className="text-center" variant="small-btn"> */}
        {/* Like */}
        {/* <Link to={`/happening-this-week`} className="small-btn">
          <h5 style={{ fontSize: '10px' }}>See More</h5>
        </Link> */}

        <h5>
          {/* Like this content?{' '} */}
          <a
            href="/events-this-week"
            style={{ fontSize: '14px', color: '#0d6efd' }}
          >
            See More
          </a>
        </h5>
        {/* </Button> */}
      </div>
    </HappeningThisWeekPanelStyle>
  );
};
export default HappeningThisWeekPanel;
