import React, { Component, Fragment } from 'react';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../context/auth/AuthState';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { csonParser } from 'config/parser';
import MyRoommate from './MyRoommate'; // might have to capitalize
import Example from './Example';

const MyTripDetails = (props) => {
  const [show, setShow] = React.useState(false);
  const [carpoolShow, setCarpoolShow] = React.useState(false);

  // member_array : [],
  // const [member_array, setMember_array] = React.useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCarpoolClose = () => setCarpoolShow(false);
  const handleCarpoolShow = () => setCarpoolShow(true);
  // const handleMemberArray = () => setMember_array([]);
  // const { user } = props;
  const [authState] = useAuth();
  // const { user } = authState;
  const userContext = useContext(UserContext);

  //send user object to backend and let server do filtering for you?
  // const { events_and_roommates } = props;
  // const { event } = props;

  const location = useLocation();
  const { trip, user, event } = location.state;

  const {
    getJoinedEventsForUser,
    getEventsAndRoommatesForUser,
    // joined_events,
    users_roommates_for_events,
    getConnectionsForUserWithId,
    user_connections,
    addUsersAsRoommates,
    users_added_to_roommates,
    getEventsAndRoommatesForUsersTrip,
    addRoommatesToTrip,
  } = userContext;

  // let member_array = [];

  // const [member_array, setMemberArray] = useState({
  //   // descr: '',
  //   // members: [],
  // });

  let member_array = [];

  const onChange = (e) => {
    console.log('changing list -- e is:');
    // console.log(e.target);
    // setMemberArray(...member_array, e.target.name);
    // console.log(member_array);

    if (member_array.includes(e.target.id)) {
      console.log('removing user');
      member_array = member_array.filter((item) => item !== e.target.id);
    } else {
      console.log('adding user');
      member_array.push(e.target.id);
    }

    const member_set = new Set(member_array);
    const uniqueMembers = [...member_set];

    console.log(member_array);
  };
  console.log(`user_connections ----- ${user?._id}`);
  console.log(user_connections);

  const isConnected = () => {
    for (let i = 0; i < user_connections.length; i++) {
      console.log(`user_connections[i]._id --- ${user_connections[i]._id}`);

      console.log(`event.trip_owner_id ---- ${event?.trip_owner_id}`);
      if (user_connections[i]._id === event?.trip_owner_id) {
        console.log('we;re connected');
        return true;
      }
    }
    return false;
  };
  // const {event}=state;
  // const events_array = Object.values(events_and_roommates);

  // console.log(typeof events_array);
  // let eventID_array = [];
  // for (let i = 0; i < events_and_roommates.length; i++) {
  //   console.log('event id:');
  //   console.log(events_and_roommates[i].event_id);
  //   eventID_array.push(events_and_roommates[i].event_id);
  // }

  //query eventbyid and userbyid
  //do query for finding usersbyid and render the returned users to the sub-component

  useEffect(() => {
    // this.setState({
    //   member_array: [...this.state, []],
    // });
    // this.setState({
    //   ...state,
    //   member_array: [],
    // });

    console.log('getting myeventdetail');

    //what data are you trying to send back?

    // let my_obj = {
    //   event_id: event._id,
    //   events_with_roommates: user.events_with_roommates,
    // };
    // handleMemberArray();
    // getEventsAndRoommatesForUser(my_obj);
    getEventsAndRoommatesForUsersTrip(trip?._id);
    getConnectionsForUserWithId(user?._id);

    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later

    // console.log('user from upcoming');
    // console.log(user);
    // getJoinedEventsForUser(user._id);

    //there is an error thrown when we reload page
    //bcuz we are calling useEffect and getting "user" object from authState
    //figure out how to fix this or call the user._id from somewhere else..
    // getJoinedEventsForUser('6248c1337e4d6d57c43991f1'); //-this works without error on reload

    // eslint-disable-next-line
  }, []);

  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  //send mem

  const handleAddUsersAsRoommates = () => {
    //action from userState
    //---do we need below code?
    let obj = {
      current_user_id: user._id,
      event_id: event._id,
      member_array: member_array,
      trip_owner_id: event.trip_owner_id,
    };
    console.log('event from addUsersAsRoommates');
    console.log(event);
    //---do we need below code?
    // addUsersAsRoommates(obj);
  };

  const handleAddRoommatesToTrip = () => {
    console.log('trying to handleAddRoommatesToTrip');

    //action from userState
    //---do we need below code?
    let obj = {
      current_user_id: user?._id,
      event_id: trip.event_id,
      member_array: member_array,
      trip_id: trip._id,
    };

    //---do we need below code?
    addRoommatesToTrip(obj);
  };
  // const handleChange = (e) => {
  //   console.log('handling changing');
  //   // const { name, type, value } = e.target;
  //   // const val = type === 'number' ? parseFloat(value) : value;
  //   // this.setState({ [name]: val });

  //   // if(this.memberSelected(this.state.member_array,e.target.id)){
  //   // 	console.log("already selected")
  //   // }

  //   // this.setState({
  //   //   member_array: [...this.state.member_array, e.target.id],
  //   // });
  //   console.log('member_array after schange');

  //   console.log(member_array);
  // };

  // console.log('member array is:');
  // console.log(member_array);

  const smallText = (
    <Fragment>
      <div className="smallText">
        <p>{event?.descr}</p>
      </div>
    </Fragment>
  );

  const largeText = (
    <Fragment>
      <div className="smallTextExpanded">
        <p>{event?.descr}</p>
      </div>
    </Fragment>
  );
  const [expandButtonSelected, setExpandButtonSelected] = useState(false);

  const userIsMember = () => {
    console.log('inside userismember');
    console.log('users_roommates_for_events');
    console.log(users_roommates_for_events);
    let isMember = false;
    for (let i = 0; i < users_roommates_for_events.length; i++) {
      if (users_roommates_for_events[i]._id === user?._id) {
        console.log('user is a member');
        isMember = true;
      } else {
        console.log('roommate id');
        console.log(users_roommates_for_events[i]._id);
        console.log('user id');
        console.log(user?._id);
      }
    }
    return isMember;
  };

  return (
    <div>
      {/* <div className="all-center">
        <div className="bigScreen smallScreen">
          <img
            // style={{ width: '350px' }}
            src={event?.image ? event.image.url : imgg}
            alt="event"
            // src="https://static.wixstatic.com/media/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg/v1/fit/w_2500,h_1330,al_c/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg"
          />
        </div>
      </div> */}
      <h1>{trip.event_name}</h1>
      {!expandButtonSelected ? smallText : largeText}
      <Button
        variant="primary"
        onClick={() => setExpandButtonSelected(!expandButtonSelected)}
      >
        {!expandButtonSelected ? 'see more' : 'see less'}
      </Button>
      <hr />
      {/* <h3>{event.descr}</h3> */}
      {/* <h3>My Event Details</h3> */}

      {/* {isConnected() === true ? ( */}

      {userIsMember(users_added_to_roommates) ? (
        <Button variant="primary" onClick={handleShow}>
          Add Members
        </Button>
      ) : (
        <></>
      )}
      {/* ) : (
        <></>
      )} */}
      {/* <Button variant="primary" onClick={handleCarpoolShow}>
        Add Carpool
      </Button> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Roommates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <form class="form-inline my-2 my-lg-0">
                <input
                  class="form-control mr-sm-2"
                  type="search"
                  placeholder="Search members"
                  aria-label="Search"
                />

                <button class="btn btn-outline-info my-2 my-sm-0" type="submit">
                  Search
                </button>
              </form>
            </div>
            <br />

            {user_connections.map((user) => (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name={user.name}
                  id={user._id}
                  value="option1"
                  onChange={onChange}
                />
                <img
                  src={user.image ? user.image.url : imgg}
                  height="100"
                  alt="user"
                />

                <label class="form-check-label" for={user.id}>
                  {user.name}
                </label>
              </div>
            ))}
            <Button variant="primary" onClick={handleAddRoommatesToTrip}>
              Save Changes
            </Button>
          </div>

          <br />
          <hr />
          {/* <MembersToAddToBuild buildId={props.buildId} /> */}
        </Modal.Body>
      </Modal>
      <Modal show={carpoolShow} onHide={handleCarpoolClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Carpool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form class="form-inline my-2 my-lg-0">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Search members"
                aria-label="Search"
              />

              {/* <button class="btn btn-outline-info my-2 my-sm-0" type="submit">
                Search
              </button> */}
            </form>
          </div>
          <br />
          <hr />
          {/* <MembersToAddToBuild buildId={props.buildId} /> */}
        </Modal.Body>
      </Modal>
      <h4>Roommates</h4>
      {/* {event_and_roommates.event_id} */}
      <div>
        {users_roommates_for_events.map((roommate) => (
          <MyRoommate key={roommate.id} roommate={roommate} event={event} />
        ))}
      </div>
      <hr />
      {/* <div>
        {events_with_roommates.roommates_for_event.map((roommates) => (
          <MyTripDetails
            key={roommates.id}
            roommates={roommates}
          />
        ))}
      </div> */}
    </div>
  );
};

export default MyTripDetails;
