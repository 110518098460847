import React, { Component, useState } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import ConnectionItem from '../user/ConnectionItem';
import { useContext, useEffect } from 'react';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Company from './Company';
import { Button, Fade } from 'react-bootstrap';
import spinner from '../../components/events/spinner.gif';
import Company2 from './Company2';
import Company3Hardcoded from './Company3Hardcoded';

const Sharktank = (props) => {
  const [authState] = useAuth();

  const { user } = authState;
  const userContext = useContext(UserContext);
  const {
    getOneSharkTankBusiness,
    getAllSharktankParticipants,
    sharktank_partipants,
    joinSharktank,
    sharktank_loading,
    one_sharktank_participant,
  } = userContext;

  const location = useLocation();

  //   const { target_user } = location.state;

  console.log('target user is:');
  //   console.log(target_user);
  useEffect(() => {
    getOneSharkTankBusiness();
    getAllSharktankParticipants();

    console.log('sharktank_partipants');
    console.log(sharktank_partipants);
    // getConnectionsForUserWithId(target_user._id);
  }, []);

  const [sharktankBusinesses, setSharktankBusinesses] = useState(
    sharktank_partipants
  );
  // const [sharktankBusinesses, setSharktankBusinesses] = useState(
  //   sharktank_partipants ? sharktank_partipants : []
  // );

  console.log('after sharktank_partipants');
  console.log(sharktank_partipants);
  const onSubmit = (e) => {
    // e.preventDefault();
    joinSharktank(user._id);
    window.location.reload();
  };
  const [open, setOpen] = useState(false);
  let businesses = [
    {
      name: 'Rashad and Troy',
      location: 'New York City, New York',
      _id: '1',
      image:
        'https://pbs.twimg.com/profile_images/1480568764043337728/1B2_oYzM_400x400.jpg',
      business_name: 'Earn Your Leisure',
      business_type: 'Investing / Business',
      business_description:
        'The Biggest Investing Education Platform in the world',
    },
    {
      name: 'Jasmine Terry',
      location: 'Houston, Texas',
      _id: '8',
      image:
        'https://ugc.production.linktr.ee/CQ6Ml3pKTUeG5ZD5t9I9_0001-25407604981.png?io=true&size=avatar-v3_0',
      business_name: 'Holistic Roots Skincare',
      business_type: 'Skincare',
      business_description: 'Let us help you restore your ancestral glow',
    },
    {
      name: 'Ashaala Shanae',
      location: 'Atlanta, Georgia',
      _id: '2',
      image:
        'https://d11n7da8rpqbjy.cloudfront.net/themahi/161737374433Vocal_Wallstreet_Logo_-_Registered.png',

      business_name: 'Vocal Wallstreet',
      business_type: 'Investing / Business',
      business_description:
        'WE HELP SPEAKERS & SINGERS OPTIMIZE THEIR VOICE ASSET!',
    },
    {
      name: 'Donni Wiggins',
      location: 'Atlanta, Georgia',
      _id: '3',
      image:
        'https://www.actionableceo.com/hosted/images/fb/a8009f3c8941168cda699696932537/Actionable-CEO-blue-shadow.png',

      business_name: 'Actionable CEO',
      business_type: 'Business Coaching',
      business_description: 'The #1 Mentorship Community For Entrepreneurs',
    },
    {
      name: 'David Shands',
      location: 'Atlanta, Georgia',
      _id: '4',
      image:
        'https://static.wixstatic.com/media/e48d63_e60c34fbff734fc1ba891aa7f8062f0b~mv2.png/v1/fill/w_512,h_512,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/e48d63_e60c34fbff734fc1ba891aa7f8062f0b~mv2.png',

      business_name: 'Sleep Is For Suckers',
      business_type: 'Business Podcast',
      business_description: 'Helping Hustlers Turn Dreams into Reality',
    },
    {
      name: 'Enron',
      location: 'Dallas, Texas',
      _id: '7',
      image:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA3CAMAAABuDnn5AAAAsVBMVEX///8Ae8MAd8EAfcQAecLoDiDnABAAk0kPlU4AgcUAg8YAf8Tj7fb6/P3a5/PtaW774+SAvJYAcb+wzObs8/nN3+/oABn619jwgYXtZGn2trnpJC+KwZ7O5NXsWmD98PH1r7Fsp9WbwOBDlM1en9K70+qMuN1/sNn0p6nven/ymZzmAAD3wcLoGiex1L13uI/d7OLcYnDeS1oMjs/qMz3rUVfWNzM3n2JMpnAZlWC/28nWmJbKAAACYUlEQVRIiZ3U63qaQBAG4NkDKCxLEhBtrImBRWxjTdPS1rT3f2GdBWFFBYH5Iw/L6zd7AID2+tIx1lV39tdxztpbYyS65dz6NMJZ97CwB8vCwXCJfd4XFwtrkKwdwOt+922MW87nr73d44mz7f6n4NH6fOHeRrrV5Ps4N51Ob0l0a+NeKvd2S153kyd475atDrrls3Ev9q7hCvnQyy2bDrdkOrm+Kw1nl+7BuNbEW27S4n7Mr7r3472nykVnTrGftdvt210YxGeO5bPj9frX/rnVUUHihnMz6hhp/W5zNHG4kUowD5JAgZHPq4ZbVS4Ej9US+2SIEglG7v9MrzswUjFnllKFzGemW3vR4lCW89xygo+nGOlzIarUdbvDLWE0KxK1l+gckcJZ1S4xbuay8jlF9S86l/pSeTedQ6r/V7nOYwoDc6L6O5Da8e2WRjnL5XW3qZwgp/PxKYklVUq7mVt1Kw/TD9npQOYxZMIRSjtGZkYe5KnzSheFSf0ISOYKVS6ZYtLIjyt5SRYmdWhCCoepENH6OKL8y+s8duwzzCIDQRV5gkcQi9xM4jD5d+5gk2Qnu4ZrK7HPlAes7k3fvnQXhfNTuMBxiq95lHjNwQ4HUne7LU8wpUF4Otblim63nGNUTHioAq/h4naHtQmIXzgfXwcT6d9yuLbR0UWc+r3zygopQ0fJFi8L65NeDlKq87TbUN14X4cruonwZdHOxVPj854OjwGlVPdJXScf4FBm6aaYqssjr2+fdYWUJ44a42gYkkwMdaDwxDHHHexwSSFkYxyWcMY5PAjjHH5Nugb/A/V6PqPPjDF6AAAAAElFTkSuQmCC',
      business_name: 'Enron Corporation',
      business_type: 'Energy',
      business_description: 'Fraudulent Energy company',
    },
    {
      name: 'Bernie Maddof',
      location: 'New York City, New York',
      _id: '6',
      image:
        'https://esoftskills.com/fs/wp-content/uploads/2024/04/madoff_s_ponzi_scheme_unraveling.jpg',
      business_name: 'Maddof Investments',
      business_type: 'Finance',
      business_description: 'Biggest Ponzi scheme ever',
    },
    {
      name: 'Covered By God Clothing',
      location: 'Atlanta, Georgia',
      _id: '5',
      image:
        'https://coveredbygodclothing.com/cdn/shop/files/cbg.png?v=1613721389&width=200',
      business_name: 'Covered By God Clothing',
      business_type: 'Fashion',
      business_description: 'Showcasing Faith based Apparel and stories',
    },
  ];
  const [businessesHardcoded, setBusinessesHardcoded] = useState(businesses);

  const getNextBusiness = (oldID) => {
    //filter one business out
    console.log('oldID');
    console.log(oldID);
    console.log('sharktankBusinesses');
    console.log(sharktankBusinesses);

    // let newList = sharktankBusinesses.filter((item) => item._id !== oldID);
    // let newList = sharktank_partipants.filter((item) => item._id !== oldID);
    let newList = sharktankBusinesses.filter((item) => item._id !== oldID);

    setSharktankBusinesses(newList);

    // getOneSharkTankBusiness();
  };

  if (sharktank_loading) {
    return (
      <img
        src={spinner}
        style={{ width: '200px', margin: 'auto', display: 'block' }}
        alt="Loading..."
      />
    );
  }

  const getNextBusinessHardcoded = (oldID) => {
    //filter one business out
    console.log('oldID');
    console.log(oldID);
    console.log('businesses');
    console.log(businesses);

    // let newList = businesses.filter((item) => item._id !== oldID);
    // let newList = sharktank_partipants.filter((item) => item._id !== oldID);

    let newList = businessesHardcoded.filter((item) => item._id !== oldID);

    setBusinessesHardcoded(newList);

    // getOneSharkTankBusiness();
  };

  const getSharktankBusiness = (
    sharktankBusinesses,
    sharktank_participants
  ) => {
    console.log('sharktankBusinesses from the get');
    console.log(sharktankBusinesses);

    if (sharktankBusinesses && sharktankBusinesses.length > 0) {
      console.log('from sharktankBusinesses[0] ');
      return sharktankBusinesses[0];
    }

    if (sharktank_participants) {
      setSharktankBusinesses(sharktank_participants);
      console.log('from sharktank_participants[0] ');
      return sharktank_participants[0];
    }
  };
  // const getSharktankBusinessHardcoded = (
  //   businesses,
  //   sharktank_participants
  // ) => {
  //   console.log('sharktankBusinesses from the get');
  //   console.log(sharktankBusinesses);

  //   if (sharktankBusinesses && sharktankBusinesses.length > 0) {
  //     console.log('from sharktankBusinesses[0] ');
  //     return sharktankBusinesses[0];
  //   }

  //   if (sharktank_participants) {
  //     setSharktankBusinesses(sharktank_participants);
  //     console.log('from sharktank_participants[0] ');
  //     return sharktank_participants[0];
  //   }
  // };

  return (
    <div className="" style={{ paddingTop: '14px' }}>
      <h1 style={{ fontSize: '36px' }}>InvestFest Sharktank</h1>
      <h4>Post here for all of InvestFest to see</h4>
      {/* {user?.investfest_sharktank ? (
        <Button onClick={onSubmit}>Join InvestFest Sharktank</Button>
      ) : (
        <></>
      )} */}
      {user ? (
        <Button onClick={onSubmit}>Join InvestFest Sharktank</Button>
      ) : (
        <></>
      )}

      {/*  

      {sharktank_partipants.map((target_user) => (
        <div>
          <Fade in={open}>
            <Company
              key={target_user.id}
              target_user={target_user}
              leader={sharktank_partipants[0]}
              getNextBusiness={getNextBusiness}
            />
          </Fade>
        </div>
      ))} */}

      {/* {one_sharktank_participant ? ( */}

      {/* {sharktankBusinesses ? (
        <div>
          <Fade in={open}>
            <Company2
              // key={target_user.id}
              // target_user={one_sharktank_participant}
              // leader={one_sharktank_participant}
              setOpen={setOpen}
              open={open}
              target_user={getSharktankBusiness(
                sharktankBusinesses,
                sharktank_partipants
              )}
              leader={getSharktankBusiness(
                sharktankBusinesses,
                sharktank_partipants
              )}
            
              getNextBusiness={getNextBusiness}
            />
          </Fade>
        </div>
      ) : (
        <></>
      )} */}
      {businesses ? (
        <div>
          <Fade in={open}>
            <Company3Hardcoded
              setOpen={setOpen}
              open={open}
              target_user={businessesHardcoded[0]}
              leader={businessesHardcoded[0]}
              getNextBusinessHardcoded={getNextBusinessHardcoded}
            />
          </Fade>
        </div>
      ) : (
        <></>
      )}

      {/* ) : (
        <></>
      )} */}

      {/* <h1>
        {target_user._id === user._id
          ? 'My Connections'
          : target_user.name + "'s Connections"}
      </h1>
      {user_connections.map((user) => (
        <ConnectionItem key={user._id} user={user} />
      ))} */}
    </div>
  );
};

export default Sharktank;
