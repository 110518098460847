import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import OnboardStudioSearch from './OnboardStudioSearch';

const StudentOnboard = (props) => {
  const returnNextScreen = (remainingScreens) => {
    // if (remainingScreens.includes('Instructor')) {
    //   return '/instructor-onboarding';
    // }

    if (remainingScreens.includes('Dance Company Owner')) {
      return '/studio-onboarding';
    }
    return '/';

    // switch (array[0]) {
    //   case 'event organizer':
    //     return '/event-organizer-onboarding';
    //   case 'Dance Studio Owner':
    //     return '/studio-onboarding';
    //   case 'dance student':
    //     return '/student-onboarding';

    //   default:
    //     console.log('defualt route running');
    //     return '/privacy-policy';
    // }
  };
  const [studioOnboardAlsoCompleted, setStudioOnboardAlsoCompleted] = useState(
    false
  );
  let remainingScreens = '';
  const location = useLocation();
  let artistWasOnboarded = false;
  if (location?.state) {
    console.log('checking location.state');
    let { onboardingScreens, artistOnboarded } = location?.state;
    artistWasOnboarded = artistOnboarded;
    if (onboardingScreens.length > 0) {
      console.log('removing inscturctor screen');
      remainingScreens = onboardingScreens.filter(
        (screen) => screen !== 'Instructor'
        // (screen) => screen !== onboardingScreens[0]
      );
    }

    if (studioOnboardAlsoCompleted) {
      console.log('removing dance company owner screen');
      remainingScreens = onboardingScreens.filter(
        (screen) => screen !== 'Dance Company Owner'
        // (screen) => screen !== onboardingScreens[0]
      );
    }
  }

  const renderDynamicRoutes = () => {
    if (remainingScreens.length > 0) {
      console.log('remaining screens --:');
      console.log(remainingScreens);
      let nextScreen = '/';
      nextScreen = returnNextScreen(remainingScreens);

      // if (nextScreen === '') {
      //   nextScreen = '/';
      // } else {
      //   nextScreen = returnNextScreen(remainingScreens);
      // }
      console.log('nextScreen is');
      console.log(nextScreen);
      return (
        <Link to={nextScreen} state={{ onboardingScreens: remainingScreens }}>
          <Button>
            {nextScreen === '/' ? 'Onboard Complete, Lets Dance!' : `next`}
          </Button>
        </Link>
      );
    } else {
      console.log('we should have a onboard complete button');

      console.log(remainingScreens);
      return (
        <Link to={'/'}>
          <Button>Onboard Complete, Let's Dance! </Button>
        </Link>
      );
    }
  };

  return (
    <div className="card">
      <h4>Welcome To Your Dance Journey</h4>
      <h5>First, Select your home dance company:</h5>
      {/* search bar */}

      <OnboardStudioSearch
        setStudioOnboardAlsoCompleted={setStudioOnboardAlsoCompleted}
        artistOnboarded={artistWasOnboarded}
      />

      {/* roles and styles  */}

      {renderDynamicRoutes()}
    </div>
  );
};

export default StudentOnboard;
