import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import PaymentForm from './PaymentForm';

//dev
// const PUBLIC_KEY = 'pk_test_S9vGbKw6oRNtRROsFtGAIJr4';

//prod
const PUBLIC_KEY = 'pk_live_sGdf3njWtyt2trZWxUD4sQjw';

const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function StripeContainer() {
  return (
    <Elements stripe={stripeTestPromise}>
      <PaymentForm />
    </Elements>
  );
}
