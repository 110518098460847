import React, { useReducer } from 'react';
import axios from 'axios';

import AdvertismentContext from './advertismentContext';
import advertismentReducer from './advertismentReducer';
import {
  GET_ALL_ADS,
  GET_BANNER_ADS,
  //-- etw will just be 2 arrays - boosted and non-boosted combined
  //GET_EVENTS_THIS_WEEK_ADS
  AD_ERROR,
  GET_MAIN_AD,
  GET_SIDE_AD,
  CREATE_AD,
  CREATE_AD_REQUEST,
  GET_AD_REQUESTS,
  GET_BANNER_AD_REQUESTS,
  APPROVE_AD_REQUEST,
  APPROVE_BANNER_AD_REQUEST,
  APPROVE_ETW_AD_REQUEST,
  GET_EVENTS_THIS_WEEK_AD_REQUESTS,
  //ADD APPROVAL FOR ETW
  DENY_AD_REQUEST,
  CREATE_GENERAL_AD_REQUEST,
} from '../types';

const AdvertismentState = (props) => {
  const initialState = {
    new_advertisement: null,
    main_advertisement: {},
    side_advertisement: {},
    advertisements: [],
    bannerAdvertisements: [],
    advertisement_error: null,
    adRequest: {},
    generalAdRequest: {},
    allAdRequests: [],
    bannerAdRequests: [],
    etw_AdRequests: [],
    eventsThisWeekAdRequests: [],
    approvedAdRequest: {},
  };

  const [state, dispatch] = useReducer(advertismentReducer, initialState);

  const getAllAds = async () => {
    try {
      console.log('running getAllAds()');
      const res = await axios.get('/api/advertisements/');
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_ALL_ADS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getBannerAds = async () => {
    try {
      console.log('running getBannerAds()');
      const res = await axios.get('/api/advertisements/banner-ads');
      console.log('banner ads are:');
      console.log(res.data);
      dispatch({
        type: GET_BANNER_ADS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getAllAdRequests = async () => {
    try {
      console.log('running getAllAds()');
      const res = await axios.get('/api/advertisements/requests');
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_AD_REQUESTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getSideAd = async () => {
    try {
      console.log('running getSideAd()');
      const res = await axios.get('/api/advertisements/sideAd');
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_SIDE_AD,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getAdById = async (ad_id) => {
    try {
      console.log('running getAllAds()');
      const res = await axios.get(`/api/advertisements/${ad_id}`);
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_ALL_ADS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const createAd = async (obj) => {
    try {
      console.log('running createAd()');
      const res = await axios.post('/api/advertisements/', obj);
      // console.log('results should be filtered');
      // console.log(res.data);
      console.log(obj);
      dispatch({
        type: CREATE_AD,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const createAdRequest = async (obj) => {
    try {
      console.log('running createAdRequest() -----xxx');
      const res = await axios.post('/api/advertisements/request', obj);
      // console.log('results should be filtered');
      // console.log(res.data);
      console.log(obj);
      dispatch({
        type: CREATE_AD_REQUEST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const approveAdRequest = async (adRequestId) => {
    try {
      console.log('running approveAdRequest()');
      const res = await axios.post(
        `/api/advertisements/approve/${adRequestId}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);

      dispatch({
        type: APPROVE_AD_REQUEST,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const approveBannerAdRequest = async (adRequestId) => {
    try {
      console.log('running approveBannerAdRequest()');
      const res = await axios.post(
        `/api/advertisements/approve-banner-ad/${adRequestId}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);

      dispatch({
        type: APPROVE_BANNER_AD_REQUEST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const approveETWAdRequest = async (adRequestId) => {
    try {
      console.log('running approveETWAdRequest()');
      const res = await axios.post(
        `/api/advertisements/approve-ad-for-ETW/${adRequestId}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);

      dispatch({
        type: APPROVE_ETW_AD_REQUEST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err,
      });
    }
  };

  const denyRequest = async (adRequestId, adType) => {
    try {
      console.log('running denyRequest()');
      const res = await axios.delete(
        `/api/advertisements/${adRequestId}/${adType}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);

      dispatch({
        type: DENY_AD_REQUEST,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const createGeneralAdRequest = async (obj) => {
    try {
      console.log('running createAd()');
      const res = await axios.post('/api/advertisements/requestPromotion', obj);
      // console.log('results should be filtered');
      // console.log(res.data);
      console.log(obj);
      dispatch({
        type: CREATE_GENERAL_AD_REQUEST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getBannerAdRequests = async () => {
    try {
      console.log('running getBannerAdRequests()');
      const res = await axios.get('/api/advertisements/bannerAdRequests');
      console.log('banner ad is');
      console.log(res.data);
      dispatch({
        type: GET_BANNER_AD_REQUESTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getEventsThisWeekAdRequests = async () => {
    try {
      console.log('running getEventsThisWeekAdRequests()');
      const res = await axios.get(
        '/api/advertisements/eventsThisWeekAdRequests'
      );
      console.log('getEventsThisWeekAdRequests ad is');
      console.log(res.data);
      dispatch({
        // type: GET_BANNER_AD_REQUESTS,
        type: GET_EVENTS_THIS_WEEK_AD_REQUESTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: AD_ERROR,
        payload: err.response.msg,
      });
    }
  };

  return (
    <AdvertismentContext.Provider
      value={{
        main_advertisement: state.main_advertisement,
        side_advertisement: state.side_advertisement,
        advertisements: state.advertisements,
        bannerAdvertisements: state.bannerAdvertisements,
        advertisement_error: state.advertisement_error,
        new_event: state.new_event,
        adRequest: state.adRequest,
        generalAdRequest: state.generalAdRequest,
        allAdRequests: state.allAdRequests,
        bannerAdRequests: state.bannerAdRequests,
        etw_AdRequests: state.etw_AdRequests,
        eventsThisWeekAdRequests: state.eventsThisWeekAdRequests,
        approvedAdRequest: state.approvedAdRequest,

        denyRequest,
        getAllAdRequests,
        createAdRequest,
        approveAdRequest,
        approveBannerAdRequest,
        approveETWAdRequest,
        createAd,
        getSideAd,
        getAllAds,
        getAdById,
        createGeneralAdRequest,
        getBannerAdRequests,
        getEventsThisWeekAdRequests,
        getBannerAds,
        // getETWAds
      }}
    >
      {props.children}
    </AdvertismentContext.Provider>
  );
};

export default AdvertismentState;
