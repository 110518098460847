import React, { Component, useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import StudioContext from '../../context/studio/studioContext';

const EditCompany = (props) => {
  const studioContext = useContext(StudioContext);
  const { editStudio } = studioContext;

  const handleClose = () => {
    // console.log('closing ');
    // setShow(false);
    props.setShow(false);
    // showModal = false;
  };
  const [details, setDetails] = useState({
    // descr: '',
    name: props.studio.name,
    image: '',
    // image: props.studio?.image ? props.studio.image : '',
    // descr: 'fun event',
    // location: 'Charlotte, NC',
  });
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });
  const { name, image } = details;

  const updatedStudio = {};
  updatedStudio.studio_id = props.studio._id;
  // updatedStudio.descr = descr;
  updatedStudio.name = name;
  //   updatedStudio.descr = descr;
  const [file, setFile] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving updated user from frontend');

    if (details === '') {
      //   setAlert('Please fill in all fields', 'danger');
    } else {
      const data = new FormData();
      data.append('name', name);
      data.append('image', image);
      data.append('file', file); //image
      data.append('studio_id', props.studio._id);
      // editStudio(updatedStudio);
      editStudio(data);

      // console.log
      console.log('updatedStudio from edit is: ---');
      console.log(updatedStudio);
      console.log('saving updated user from frontend');
    }
  };
  const [show, setShow] = useState(props.showModal);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Studio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Studio Name</Form.Label>
              <Form.Control
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={onChange}
                autoFocus
              />

              <div className="row">
                <label htmlFor="image">Profile Image</label>
              </div>

              <input
                id="image"
                name="image"
                type="file"
                value={image}
                onChange={(event) => {
                  const file = event?.target?.files[0];
                  setFile(file);
                  setDetails({
                    ...details,
                    [event.target.name]: event.target.value,
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default EditCompany;
