import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
  useRef,
} from 'react';
import StudioContext from '../context/studio/studioContext';
// import TopArtists from './TopArtists';
// import DynamicSearch from '../layout/DynamicSearch';
import OnboardDynamicStudioSearch from './instructor-onboard/OnboardDynamicStudioSearch';
import { Button } from 'react-bootstrap';
import StudioSelectedDisplayRoles from './instructor-onboard/StudioSelectedDisplayRoles';
import { useAuth } from '../context/auth/AuthState';
import AddStudio from '../components/studio/AddStudio';
// import DynamicSearch from '../layout/DynamicSearch';
const OnboardStudioSearch = (props) => {
  const [authState] = useAuth();

  const { user } = authState;
  const [display, setDisplay] = useState(false);

  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };
  // 1.load studios ---- 2.load artists
  const studioContext = useContext(StudioContext);
  const {
    topStudiosLimitFive,
    getTopStudiosWithLimitOfFive,
    getStudiosForSearchCriteria,
    studioSearchResults,
    joinStudioFromOnboard,
  } = studioContext;

  console.log('topStudiosLimitFive ---');
  console.log(topStudiosLimitFive);
  useEffect(() => {
    getTopStudiosWithLimitOfFive();
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  const [details, setDetails] = useState({
    searchText: '',
  });
  const [dynamicText, setDynamicText] = useState('');
  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log('text is:');
    console.log(e.target.value);
    getStudiosForSearchCriteria(e.target.value);
    setDynamicText(e.target.value);
  };
  const { searchText } = details;
  const [studioWasSelected, setStudioWasSelected] = useState(false);
  const [selectedStudio, setSelectedStudio] = useState({});
  const [primaryStyle, setPrimaryStyle] = useState('bachata');
  const [secondaryStyles, setSecondaryStyles] = useState([]);
  const [roles, setRoles] = useState(['instructor']);
  const [studioSaved, setStudioSaved] = useState(false);

  const handleSave = () => {
    let obj = {
      studio_id: selectedStudio._id,
      roles: roles,
      primary_style: primaryStyle,
      secondary_styles: secondaryStyles,
      user_id: user?._id,
      artistOnboarded: props?.artistOnboarded ? props.artistOnboarded : false,
      city: user?.city,
      state: user?.state,
      country: user?.country,
    };

    setStudioSaved(true);
    props.setStudioOnboardAlsoCompleted(true);
    joinStudioFromOnboard(obj);
  };
  const [showAddStudio, setShowAddStudio] = useState(false);
  return (
    <div>
      <div className="row">
        <div className="row">{/* <h3>Top Dance Companies x🔥</h3> */}</div>
        <div className="row" style={{ marginBottom: '14px' }}>
          <div className="col">
            <input
              id="searchText"
              class="form-control mr-sm-2"
              type="search"
              placeholder="Search Dance Company"
              aria-label="Search"
              name="searchText"
              value={searchText}
              onChange={onChange}
              style={{ width: '250px' }}
              onClick={() => setDisplay(!display)}
              autoComplete="off"
            />
          </div>

          <div ref={wrapperRef} className="flex-container flex-column pos-rel">
            {display && (
              // <div className="autoContainer">
              <div>
                {/* <h5>Try searching for</h5> */}

                <OnboardDynamicStudioSearch
                  // onChangeValue={handleSearch}
                  userSearchText={dynamicText}
                  dynamicStudioSearch={studioSearchResults}
                  event_id={props.event_id}
                  setSelectedStudio={setSelectedStudio}
                  setStudioWasSelected={setStudioWasSelected}
                  setDisplay={setDisplay}
                />
              </div>
            )}
          </div>
          {dynamicText === '' ||
            (studioSearchResults.length === 0 && (
              <div style={{ marginTop: '16px' }}>
                <h5>If no results:</h5>
                <Button onClick={() => setShowAddStudio(!showAddStudio)}>
                  Add Studio
                </Button>
              </div>
            ))}
        </div>

        {studioWasSelected &&
          !studioSaved && (
            <div>
              <StudioSelectedDisplayRoles
                selectedStudio={selectedStudio}
                setRoles={setRoles}
                roles={roles}
                setPrimaryStyle={setPrimaryStyle}
                primaryStyle={primaryStyle}
                setSecondaryStyles={setSecondaryStyles}
                secondaryStyles={secondaryStyles}
              />
              <Button
                style={{ marginTop: '14px' }}
                onClick={() => {
                  handleSave();
                }}
              >
                Save
              </Button>
            </div>
          )}

        {studioSaved && (
          <div
            style={{
              backgroundColor: 'lightgreen',
              borderWidth: '1px',
              borderRadius: '10px',
              color: '#65a765',
              margin: '10px',
              borderColor: '#65a765',
              borderStyle: 'solid',
            }}
          >
            <h5>Studio Saved, Continue your onboarding!</h5>
          </div>
        )}

        {showAddStudio && (
          <div>
            <AddStudio
              setStudioOnboardAlsoCompleted={
                props.setStudioOnboardAlsoCompleted
              }
            />
          </div>
        )}

        {/* <div style={{ marginLeft: '3em' }}> */}

        {/* {!display && (
          <div className="row" style={{ marginLeft: '3em' }}>
            {topStudiosLimitFive.map((company) => (
              <TopCompanies
                key={company._id}
                company={company}
                event_id={props.event_id}
              />
            ))}
          </div>
        )} */}

        <hr />
      </div>
    </div>
  );
};

export default OnboardStudioSearch;
/**
 * 
 <div>
          <h3>Top Artists 🔥</h3>
          <div style={{ marginLeft: '3em' }}>
            {[1, 2, 3, 4, 5].map((company) => (
              <TopArtists key={company._id} company={company} />
            ))}
          </div>
          <hr />
        </div> 
 */
