import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
import CheckoutForm from '../advertise/CheckoutForm';
import Layout from '../advertise/Layout';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../context/auth/AuthState';
import StudioContext from '../../context/studio/studioContext';
import StudioSubscriptionCheckoutForm from '../advertise/StudioSubscriptionCheckoutForm';

const GoProSubscriptionPayment = (props) => {
  const [authState] = useAuth();

  const { user } = authState;
  let navigate = useNavigate();
  const { studio_id } = useParams();

  const studioContext = useContext(StudioContext);
  const {
    convertAccountToPremiumSubscription,
    premium_subscriber,
  } = studioContext;

  console.log('studio id is:');
  console.log(studio_id);
  return (
    <div>
      <h3>
        Go Pro with our{' '}
        <span className="text-primary">Premium Subscription</span>
      </h3>

      <hr />
      <h5>Here's what you get:</h5>
      <h5>
        ✅ Enroll students in custom curriculums
        {/* <strong>perks 1</strong>{' '} */}
      </h5>

      <h5>✅ Real time grades to assess proficiency </h5>
      <h5>
        ✅ Analytics that help you understand how your company is performing{' '}
      </h5>
      <span style={{ fontSize: '12px', color: 'grey' }}>
        this is a recurring monthly subscription
      </span>
      <div>
        <div className="smallScreenPayFields">
          <Layout title="Donut Shop">
            {/* <Row>
            <DonutShop
              onAddDonut={console.log('added donut')}
              onRemoveDonut={console.log('rm donut')}
              numDonuts={5}
            />
          </Row> */}
            {/* <CheckoutForm */}
            <StudioSubscriptionCheckoutForm
              price={50}
              onSuccessfulCheckout={() => {
                console.log(
                  'payment successful, now run convertAccountToPremiumSubscription ...'
                );
                console.log(`studio id is : ${studio_id}`);
                console.log(`user id is : ${user._id}`);
                convertAccountToPremiumSubscription({
                  studio_id: studio_id,
                  user_id: user._id,
                });
                navigate(`/studio/${studio_id}`, {
                  state: {
                    messageText: `Welcome To Premium!`,
                  },

                  // });
                });
              }}
            />
          </Layout>
        </div>
      </div>
    </div>
  );
};

export default GoProSubscriptionPayment;
