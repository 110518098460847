import React, { useReducer } from 'react';
import axios from 'axios';
import ArtistContext from './artistContext'; //be sure the case is not case sensitive
import ArtistReducer from './artistReducer';

import {
  GET_ONE_ARTIST,
  GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST,
  SEND_PRIVATE_LESSON_REQUEST,
  DECISION_PRIVATE_LESSON_REQUEST,
  GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST_AT_EVENTS,
  GET_ALL_ARTISTS_FOR_DIRECTORY,
  GET_OTHER_ARTISTS,
  ARTIST_ERROR,
  GET_ONE_RANDOM_ARTIST,
  GET_ALL_ARTISTS,
  GET_TOP_ARTISTS_LIMIT_FIVE,
  GET_ARTISTS_FOR_SEARCH_CRITERIA,
  CONVERT_TO_PREMIUM_ARTIST,
  GET_RANDOM_ARTISTS_FOR_TIMELINE,
  ADD_NEW_ARTIST,
  GET_ALL_DJS,
  GET_RANDOM_DJS_FOR_TIMELINE,
  SEND_PRIVATE_LESSON_REQUEST_AT_EVENT,
  GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_NO_EVENT,
  GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_AT_EVENT,
  DECISION_PRIVATE_LESSON_REQUEST_AT_EVENT,
  SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_ARTIST_INSTRUCTOR,
  GET_USER_EVENTS,
  // GET_ONE_ARTISTS_RANKING,
} from '../types';
const ArtistState = (props) => {
  const initialState = {
    artist: {},
    artist_directory: {},
    other_artists: [],
    random_artist: {},
    privateLessonRequests: [],
    privateLessonRequest: {},
    privateLessonRequestsAtEvents: [],
    confirmedPrivateLessonsForArtistInstructorAtEvents: [],
    confirmedPrivateLessonsForArtistInstructorNoEvent: [],
    topArtistsLimitFive: [],
    artistSearchResults: [],
    loading: true,
    rankings: {},
    artistWorldRanking: {},
    djRankings: {},
    djRankDetailsObj: {},
    artists_we_love: [],
    new_artist: {},
    DJs: [],
    randomDJsForTimeline: [],
    user_events: [],
    // confirmedPrivateLessons: {},
  };

  const [state, dispatch] = useReducer(ArtistReducer, initialState);

  const getArtistById = async (instructor_id) => {
    try {
      console.log('trying getartistbyid');
      const res = await axios.get(
        `/api/artists/get-artist-profile/${instructor_id}`
      );
      console.log('res below from getArtistById artiststate.js');
      console.log(res.data);

      dispatch({
        type: GET_ONE_ARTIST,
        payload: res.data,
        loading: false,
      });
      // dispatch({
      //   type: GET_ONE_ARTISTS_RANKING,
      //   payload: res.data[1],
      //   loading: false,
      // });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };
  const getArtistDirectory = async () => {
    try {
      console.log('trying getArtistDirectory');
      const res = await axios.get(`/api/artists/artists-sorted-by-name`);
      console.log('res below from getArtistDirectory artiststate.js');
      console.log(res);

      dispatch({
        type: GET_ALL_ARTISTS_FOR_DIRECTORY,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };
  const getDJs = async () => {
    try {
      console.log('trying getDJs');
      const res = await axios.get(`/api/artists/getDJs`);
      console.log('res below from getDJs artiststate.js');
      console.log(res);

      dispatch({
        type: GET_ALL_DJS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };
  const getRandomDJs = async () => {
    try {
      console.log('trying getDJs');
      const res = await axios.get(`/api/artists/random-djs`);
      console.log('res below from getRandomDJs artiststate.js');
      console.log(res);

      dispatch({
        type: GET_RANDOM_DJS_FOR_TIMELINE,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };
  const getOtherArtists = async () => {
    try {
      console.log('trying getOtherArtists');
      const res = await axios.get(`/api/artists/other-artists`);
      console.log('res below from getOtherArtists artiststate.js');
      console.log(res);

      dispatch({
        type: GET_OTHER_ARTISTS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };
  const getRandomArtist = async () => {
    try {
      console.log('trying getRandomArtist');
      const res = await axios.get(`/api/artists/getOneRandomArtist`);
      console.log('res below from getRandomArtist artiststate.js');
      console.log(res);

      dispatch({
        type: GET_ONE_RANDOM_ARTIST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };

  /*
  *
  * TO-DO : lesson requests with no event amd then lesson requests with event
  * 
  * 1. DONE --- sendPrivateLessonRequest 
  * 2. DONE --- getPrivateLessonRequestsForArtist
  * 3. getConfirmedLessonsForArtist - is there a distinction on lessons at and away from events? there is right? two diff. routes
  * 4. DONE --- approve/decline lesson requests
  * 
  * 
  * WITH EVENTS -----------------------------------------------
  * 
  * 5. sendPrivateLessonRequest - EVENTS
  * 6. getPrivateLessonRequestsForArtist - EVENTS
  * 7. getConfirmedLessonsForArtist - is there a distinction on lessons at and away from events? there is right? two diff. routes
  * 8. approve/decline lesson requests - EVENTS
  *
  * ------------------------------------------------------------ 
  */

  const getPrivateLessonRequestsForArtist = async (instructor_id) => {
    try {
      console.log('trying getartistbyid');
      const res = await axios.get(
        `/api/artists/privateLessonRequests/${instructor_id}`
      );
      console.log(
        'res below from getPrivateLessonRequestsForArtist artiststate.js'
      );
      console.log(res);

      dispatch({
        type: GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };

  const sendPrivateLessonRequestWithoutAccountToArtistInstructor = async (
    form
  ) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to sendPrivateLessonRequestWithoutAccountToArtistInstructor backend from artiststate'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post(
        '/api/artists/privateLessonRequest/no-account',
        form
      );

      dispatch({
        type: SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_ARTIST_INSTRUCTOR,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err.response,
      });
    }
  };

  // const createNewEvent = async (name, descr, hostId) => {
  const sendPrivateLessonRequest = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to sendPrivateLessonRequest backend from ArtistState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post('/api/artists/privateLessonRequest', form);

      dispatch({
        type: SEND_PRIVATE_LESSON_REQUEST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err.response,
      });
    }
  };

  const sendPrivateLessonRequestAtEvent = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to sendPrivateLessonRequestAtEvent backend from ArtistState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post(
        '/api/artists/privateLessonRequestAtEvent',
        form
      );

      dispatch({
        type: SEND_PRIVATE_LESSON_REQUEST_AT_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err.response,
      });
    }
  };

  //DECISION A PRIVATE LESSON REQUEST

  const decisionPrivateLessonRequest = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log('trying to decisionPrivateLessonRequest from ArtistState');
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post(
        '/api/artists/approve_or_decline_private_lesson_request',
        form
      );

      dispatch({
        type: DECISION_PRIVATE_LESSON_REQUEST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err.response,
      });
    }
  };

  const decisionPrivateLessonRequestAtEvent = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to decisionPrivateLessonRequestAtEvent from ArtistState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post(
        '/api/artists/approve_or_decline_private_lesson_request_at_event',
        form
      );

      dispatch({
        type: DECISION_PRIVATE_LESSON_REQUEST_AT_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err.response,
      });
    }
  };

  const getPrivateLessonRequestsForArtistAtEvents = async (instructor_id) => {
    try {
      console.log('trying getartistbyid');
      const res = await axios.get(
        `/api/artists/privateLessonRequestsAndEvents/${instructor_id}`
      );
      console.log(
        'res below from getPrivateLessonRequestsForArtist artiststate.js'
      );
      console.log(res);

      dispatch({
        type: GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST_AT_EVENTS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };

  const getTopArtistsWithLimitOfFive = async () => {
    try {
      const res = await axios.get(`/api/artists/getArtistsLimitFiveResults`);
      console.log('res below from getStudiosWithLimitOfFive studiostate.js');
      console.log(res);

      dispatch({
        type: GET_TOP_ARTISTS_LIMIT_FIVE,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };

  const getFourRandomArtistsForTimeline = async () => {
    try {
      const res = await axios.get(`/api/artists/four-random-artists`);
      console.log(
        'res below from getFourRandomArtistsForTimeline studiostate.js'
      );
      console.log(res);

      dispatch({
        type: GET_RANDOM_ARTISTS_FOR_TIMELINE,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };

  const getArtistsForSearchCriteria = async (searchParams) => {
    try {
      console.log('custom Artist search from frontend');
      // let event_id = '624c8b4f236daaa095ba62e7';
      const res = await axios.get(`/api/artists/search/${searchParams}`);
      // const res = await axios.get('/api/events/all-my-events', obj);
      console.log('res.data');
      console.log(res.data);

      dispatch({
        // loading: true,
        type: GET_ARTISTS_FOR_SEARCH_CRITERIA,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };

  const convertAccountToPremiumArtistSubscription = async (obj) => {
    try {
      console.log(
        'trying to convertAccountToPremiumArtistSubscription in state from ArtistState'
      );

      const res = await axios.post(
        '/api/artists/add-premium-artist-subscription',
        obj
      );
      console.log('res.data from conversion:');
      console.log(res.data);
      dispatch({
        type: CONVERT_TO_PREMIUM_ARTIST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err.response,
      });
    }
  };

  const addNewArtist = async (form) => {
    try {
      console.log(` form is ${form}`);

      const res = await axios.post('/api/artists/new-artist', form);
      console.log('res.data from addNewArtist in ArtistState.js ---');
      console.log(res.data);
      dispatch({
        type: ADD_NEW_ARTIST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err.response,
      });
    }
  };

  const getConfirmedPrivateLessonsForArtistInstructorAtEvents = async (
    artist_instructor_id
  ) => {
    try {
      console.log(
        'trying getConfirmedPrivateLessonsForArtistInstructorAtEvents'
      );
      const res = await axios.get(
        `/api/artists/confirmedPrivateLessonsAndEvents/${artist_instructor_id}`
      );
      console.log(
        'res below from getConfirmedPrivateLessonsForArtistInstructorAtEvents artiststate.js'
      );
      console.log(res.data);

      dispatch({
        type: GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_AT_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };
  const getConfirmedPrivateLessonsForArtistInstructorNoEvent = async (
    artist_instructor_id
  ) => {
    try {
      console.log(
        'trying getConfirmedPrivateLessonsForArtistInstructorNoEvent'
      );
      const res = await axios.get(
        `/api/artists/confirmedPrivateLessons/${artist_instructor_id}`
      );
      console.log(
        'res below from getConfirmedPrivateLessonsForArtistInstructorNoEvent artiststate.js'
      );
      console.log(res.data);

      dispatch({
        type: GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_NO_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: ARTIST_ERROR,
        payload: err,
      });
    }
  };
  // const getUserEvents = async (user_id) => {
  //   try {
  //     console.log(
  //       'trying getConfirmedPrivateLessonsForArtistInstructorNoEvent'
  //     );
  //     const res = await axios.get(
  //       `/api/artists/confirmedPrivateLessons/${artist_instructor_id}`
  //     );
  //     console.log(
  //       'res below from getConfirmedPrivateLessonsForArtistInstructorNoEvent artiststate.js'
  //     );
  //     console.log(res.data);

  //     dispatch({
  //       type: GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_NO_EVENT,
  //       payload: res.data,
  //     });
  //     // window.location.reload();
  //   } catch (err) {
  //     dispatch({
  //       type: ARTIST_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  return (
    <ArtistContext.Provider
      value={{
        // props -- where our state and actions will go
        artist: state.artist,
        // allStudios: state.allStudios,
        privateLessonRequests: state.privateLessonRequests,
        privateLessonRequest: state.privateLessonRequest,
        privateLessonRequestsAtEvents: state.privateLessonRequestsAtEvents,
        artist_directory: state.artist_directory,
        other_artists: state.other_artists,
        random_artist: state.random_artist,
        topArtistsLimitFive: state.topArtistsLimitFive,
        artistSearchResults: state.artistSearchResults,
        loading: state.loading,
        rankings: state.rankings,
        artistWorldRanking: state.artistWorldRanking,
        djRankings: state.djRankings,
        djRankDetailsObj: state.djRankDetailsObj,
        artists_we_love: state.artists_we_love,
        new_artist: state.new_artist,
        DJs: state.DJs,
        randomDJsForTimeline: state.randomDJsForTimeline,
        confirmedPrivateLessonsForArtistInstructorNoEvent:
          state.confirmedPrivateLessonsForArtistInstructorNoEvent,
        confirmedPrivateLessonsForArtistInstructorAtEvents:
          state.confirmedPrivateLessonsForArtistInstructorAtEvents,
        user_events: state.user_events,

        getArtistsForSearchCriteria,
        getTopArtistsWithLimitOfFive,
        getArtistById,
        sendPrivateLessonRequest,
        sendPrivateLessonRequestAtEvent,
        getPrivateLessonRequestsForArtist,
        getPrivateLessonRequestsForArtistAtEvents,
        decisionPrivateLessonRequest,
        getArtistDirectory,
        getOtherArtists,
        getRandomArtist,
        convertAccountToPremiumArtistSubscription,
        getFourRandomArtistsForTimeline,
        addNewArtist,
        getDJs,
        getRandomDJs,
        getConfirmedPrivateLessonsForArtistInstructorAtEvents,
        getConfirmedPrivateLessonsForArtistInstructorNoEvent,
        decisionPrivateLessonRequestAtEvent,
        sendPrivateLessonRequestWithoutAccountToArtistInstructor,
        // getUserEvents,
      }}
    >
      {props.children}
    </ArtistContext.Provider>
  );
};

export default ArtistState;
