import React, { Component, useContext, useState } from 'react';
import ArtistContext from '../../../context/artist/artistContext';
import { useAuth } from '../../../context/auth/AuthState';
import { DayPicker } from 'react-day-picker';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { format } from 'date-fns';
import InstructorContext from '../../../context/instructor/instructorContext';

const BookPrivate = (props) => {
  const [authState] = useAuth();

  const { user } = authState;

  const artistContext = useContext(ArtistContext);

  const {
    getArtistById,
    artist,
    sendPrivateLessonRequest,
    sendPrivateLessonRequestAtEvent,
    privateLessonRequest,
    getPrivateLessonRequestsForArtist,

    privateLessonRequests,
    getPrivateLessonRequestsForArtistAtEvents,
    privateLessonRequestsAtEvents,
  } = artistContext;
  const instructorContext = useContext(InstructorContext);

  const {
    sendPrivateLessonRequestToInstructor,
    sendPrivateLessonRequestToInstructorAtEvent,
  } = instructorContext;

  const [privateLessonTime, setPrivateLessonTime] = useState('09:00');

  const [selected, setSelected] = React.useState();
  const [updateDate, setUpdateDate] = React.useState();
  const [showSelection, setShowSelection] = React.useState(false);
  const { dancer } = props;
  console.log('dancer from bookpri.');
  console.log(dancer);
  //form inputs

  const isArtist = () => {
    // if (dancer?.artist_id && dancer?.artist_id !== '') {
    if (dancer?.artist_type && dancer?.artist_type !== '') {
      return true;
    }
    return false;
    // return dancer?.artist_id && dancer?.artist_id !== '';
    // return true
  };

  console.log('isArtist bool');
  console.log(isArtist());
  const [details, setDetails] = useState({
    // descr: '',
    lessonTime: privateLessonTime,
    lessonDate: selected,
    event_id: props.event_id, // might not be applicable
    // requesting_user_id: '123',
    event_name: props.event_name,
    requestor_name: user?.name,
    requestor_id: user?._id,
    requestor_phone_number: user?.phoneNumber,

    requested_artist_id: isArtist() ? props.instructor_id : '',
    requested_instructor_id: isArtist() ? '' : dancer?._id,

    status: 'TBD',
    comments_or_special_requests: 'none',

    // location: 'Charlotte, NC',
  });
  console.log('details before submission');
  console.log(details);

  const { lessonDate } = details;
  const [emphasisOnSetADateLabel, setEmphasisOnSetADateLabel] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('details from submit');
    console.log(details);

    if (isArtist()) {
      console.log('dancer is: ');
      console.log(dancer);
      console.log('artist api');
      //uncomment j.18.24
      if (lessonDate !== undefined) {
        // sendPrivateLessonRequest(details);
        sendPrivateLessonRequestAtEvent(details);
        props.onSendPrivateLessonRequest(true);
      } else {
        // setLessonDateMessage("select a date")
        setEmphasisOnSetADateLabel(true);
      }
    } else {
      console.log('inst. api');
      // if (dancer?.artist_id && dancer?.artist_id !== '') {

      //this means the dacner is an artist and can be booked using
      //requested_artist_id
      console.log('teacher is not artist');
      //use /instructor api

      if (lessonDate !== undefined) {
        // sendPrivateLessonRequestToInstructor(details);
        sendPrivateLessonRequestToInstructorAtEvent(details);
        props.onSendPrivateLessonRequest(true);
      } else {
        // setLessonDateMessage("select a date")
        setEmphasisOnSetADateLabel(true);
      }
      // } else {
      //   // setDetails({ ...details, requested_instructor_id: dancer?._id });
      //   console.log(details);
      //   console.log('teacher is not  artist use user._id');
    }
  };
  //set privatelessonRequestSent Value to send back to parent component
  // props.onSendPrivateLessonRequest(true);

  const setDatePlease = (selected) => {
    console.log('truing to set date from setDateplease');
    console.log(selected);
    setDetails({ ...details, lessonDate: selected });
  };

  const renderPrivateLessonCalendar = () => {
    return (
      <div>
        {/* {bookPrivateWasClicked && ( */}
        <div style={{ justifyContent: 'center' }}>
          <DayPicker
            mode="single"
            selected={selected}
            onSelect={setSelected}
            footer={footer}
            onDayClick={() => {
              // if(stateUpdated()){

              setSelected(selected);
              setDatePlease(selected);
              console.log('hello');
              console.log('selected');
              console.log(selected);
              setDetails({ ...details, lessonDate: selected });
              setUpdateDate(true);
              // }
            }}
          />

          <div className="row">
            <div className="col">
              <label>Workshop Time</label>
            </div>

            <div className="col">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={privateLessonTime}
                  onSelect={handleTimeChange}
                >
                  {times_array.map((time) => (
                    <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>
                  ))}
                </DropdownType>
              ))}
            </div>

            <form onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  type="submit"
                  value={user ? 'Send Lesson Request' : 'Sign in to request'}
                  class="btn btn-secondary"
                  disabled={user ? false : true}
                  // style={{
                  //   width: '100px',
                  //   background: 'white',
                  //   color: 'rgb(1, 126, 252)',
                  //   borderStyle: 'solid',
                  //   borderColor: 'rgb(1, 126, 252)',
                  // }}
                />
              </div>
            </form>
          </div>
        </div>
        {/* // )} */}
      </div>
    );
  };

  const renderFooter = () => {
    if (emphasisOnSetADateLabel) {
      return <p style={{ backgroundColor: 'red' }}>Please pick a day.</p>;
    } else {
      return <p>Please pick a day.</p>;
    }
  };
  let footer = renderFooter();
  //   return (
  // <div>

  //       {emphasisOnSetADateLabel ?
  //         <p>Please pick a day.</p>;
  //         :
  //         <p>Please pick a day.</p>;

  //     }
  //     </div>
  //     )

  if (selected) {
    footer = <p>You picked {format(selected, 'PP')}.</p>;
  }
  if (updateDate) {
    setDetails({
      ...details,
      lessonDate: selected,
      requestor_id: user?._id,
      requestor_name: user?.name,
      requestor_phone_number: user?.phoneNumber,
    });
    setUpdateDate(false);
  }

  if (showSelection) {
    footer = <p>You picked {format(lessonDate, 'PP')}.</p>;
  }
  //   const [calendarSelected, setCalendarSelected] = React.useState(false);
  //   const [bookPrivateSelected, setBookPrivateSelected] = React.useState(false);
  const handleTimeChange = (time) => {
    console.log('changed time');
    console.log(time);
    setPrivateLessonTime(time);
    setDetails({ ...details, lessonTime: time });
  };
  let times_array = [
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '09:30',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '13:30',
    '14:00',
    '14:00',
    '15:00',
    '16:00',
    '16:30',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
  ];
  return (
    <div>
      {/* <h1>book private</h1> */}
      {renderPrivateLessonCalendar()}
    </div>
  );
};

export default BookPrivate;
