import React, { useReducer } from 'react';
import axios from 'axios';
import TourContext from './tourContext'; //be sure the case is not case sensitive
import TourReducer from './tourReducer';

import {
  GET_TOURS_FOR_INSTRUCTOR,
  GET_STUDIOS_IN_STATE,
  POST_NEW_TOUR,
  TOUR_ERROR,
} from '../types';
const TourState = (props) => {
  const initialState = {
    // instructor: {},
    tours: [],
    studiosInState: [],
    newTour: {},
    loading: true,
  };

  const [state, dispatch] = useReducer(TourReducer, initialState);

  const publishTourRequest = async (tourObj) => {
    try {
      console.log('trying publishTourRequest');
      const res = await axios.post(`/api/tours/new-tour`, tourObj);
      console.log('res below from publishTourRequest tourstate.js');
      console.log(res);

      dispatch({
        type: POST_NEW_TOUR,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: TOUR_ERROR,
        payload: err,
      });
    }
  };
  const getToursForInstructor = async (dancer_id) => {
    try {
      console.log('trying getToursForInstructor');
      const res = await axios.get(`/api/tours/all-tours/${dancer_id}`);
      console.log('res below from getToursForInstructor tourstate.js');
      console.log(res);

      dispatch({
        type: GET_TOURS_FOR_INSTRUCTOR,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: TOUR_ERROR,
        payload: err,
      });
    }
  };
  const getStudiosInState = async (state) => {
    try {
      console.log('trying getStudiosInState');
      const res = await axios.get(`/api/studio/getStudiosByState/${state}`);
      console.log('res below from getStudiosInState tourstate.js');
      console.log(res);

      dispatch({
        type: GET_STUDIOS_IN_STATE,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: TOUR_ERROR,
        payload: err,
      });
    }
  };

  return (
    <TourContext.Provider
      value={{
        // props -- where our state and actions will go
        tours: state.tours,
        studiosInState: state.studiosInState,
        newTour: state.newTour,

        getToursForInstructor,
        getStudiosInState,
        publishTourRequest,
      }}
    >
      {props.children}
    </TourContext.Provider>
  );
};

export default TourState;
