import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import Iframe from 'react-iframe';

const AirmeusAmbassador = () => {
  return (
    <div
      style={{ backgroundColor: '', paddingLeft: '20px', paddingRight: '20px' }}
    >
      <h1 style={{ color: 'green' }}>
        Earn Money with Airmeus Ambassador Program
      </h1>
      <span style={{ fontSize: '16px', color: 'grey' }}>
        in three easy steps:{' '}
      </span>
      <h5> 1. Go to your profile and select User referrals</h5>
      <h5> 2. Copy your referral Link & Share it with your network</h5>
      <h5> 3. Earn money depending on your ambassador level</h5>
      <div>
        <hr />
        <h3>
          🥇 Gold Ambassador{' '}
          <span style={{ fontSize: '16px', color: 'green' }}>
            5% revenue share for 6 months
          </span>
        </h3>
        <h4>Requirements: </h4>
        <h5>
          <span style={{ fontSize: '16px', color: 'grey' }}>
            1. Add the following to your Instagram Bio : “ Dance Ambassador
            @airmeus.app ”
          </span>
        </h5>
        <h5>
          <span
            style={{ fontSize: '16px', color: 'grey', autoCapitalize: 'false' }}
          >
            2. When tagged from the @airmeus.app Instagram page, repost the
            content, add your ambassador link and mention the @airmeus.app
            instagram page
          </span>
        </h5>
        <hr />
        <h3>
          🥈 Silver Ambassador{' '}
          <span style={{ fontSize: '16px', color: 'green' }}>
            2.5% revenue share for 6 months
          </span>
        </h3>
        <h4>Requirements: </h4>
        <h5>
          <span
            style={{ fontSize: '16px', color: 'grey', autoCapitalize: 'none' }}
          >
            1. Add the following to your Instagram Bio : “ Dance Ambassador
            @airmeus.app ”
          </span>
        </h5>
      </div>
      <Link to="/register">
        <Button>Get Started</Button>
      </Link>

      <hr />

      <div className="row">
        <h4>More Details</h4>
        <h4>tldr (too long, didn’t read)</h4>
        <li style={{ marginBottom: '10px' }}>
          {' '}
          1. If you want to make a lot of money, read the entire document
        </li>

        <li style={{ marginBottom: '10px' }}>
          2. Revenue share money is good. But the opportunities you’ll get from
          joining airmeus.com now are better. You’ll get first access to tools
          that will put you and your company in front of every dance company in
          the world.
        </li>

        <li style={{ marginBottom: '10px' }}>
          <a
            href="https://www.instagram.com/p/CzxHt58LGKX/?igsh=ZDE1MWVjZGVmZQ=="
            style={{ color: 'blue' }}
          >
            3. Click to see an Instagram post detailing the Ambassador Program
          </a>
        </li>
      </div>

      <div className="row">
        <p>
          We see dance as an opportunity for togetherness and community. To that
          end, we build tools that make it as easy as possible to dance, make
          friends and build communities.
        </p>
        <p>
          The Airmeus Ambassador program is designed to put money into your
          pocket in proportion to the number of dancers you are able to get to
          join airmeus.com and purchase a subscription. Basically, if we get
          paid, then you get paid.
        </p>

        <p>Two Examples :</p>
        <p>
          if you get 100 dancers to join and purchase a $15 subscription, based
          on a 5% Gold Ambassador Revenue Share, you will get $75 for the first
          6 months of that dancers joining date.
        </p>
        <p>
          if you get 1000 dancers to join and purchase a $15 subscription, based
          on a 5% Gold Ambassador Revenue Share, you will get $750 for the first
          6 months of that dancers joining date.
        </p>
        <p>
          We’re working on a 10% revenue share plan as well, but that is
          forthcoming.
        </p>
        <p>
          As a dance entrepreneur, there are several other reasons to be an
          ambassador - some more important than the initial revenue share you
          stand to gain.
        </p>
        <p>
          We see airmeus.com as the Town Square for the world’s dance community,
          and thus we’re building tools that expand the capability and reach of
          dancers.
        </p>
        <p>
          Dance is an important lever to creating sustainable relationships and
          we believe every student needs a dance home. To make this possible,
          we’re building Dance Company analytics that will help you prioritize
          what’s important to your dance family.
        </p>
        <p>
          We aim to build better dancers, faster. We’ve built Custom Curriculum
          and dancer ranking software for this reason. We will be releasing
          Class Notes in 2024 that will allow dance instruction to continue
          outside of the class.
        </p>
        <p>
          We believe we’re only scratching the surface of our connectedness and
          so in 2024 we’re releasing Touring and Collaboration software. This
          will drastically increase the opportunities dance entrepreneurs have
          to create not only locally but abroad as well.
        </p>
        <p>
          It is important to note that the Airmeus Ambassador Program is a 6
          month deal between Airmeus and it’s ambassadors. This means that after
          a referred user has been on airmeus.com for 6 months, no more payments
          will be made to the ambassador. We made this decision because due to
          the hardworking folks at airmeus.com, the platform is only going to
          get better and better and thus everyone’s continued compensation will
          be due to their continued value add to the platform. So as an
          ambassador, you’d need to keep getting new dancers to join to continue
          being compensated.
        </p>
        <p>
          Take heart, we value your contribution and thus we are hard at work
          building tools that will allow you to always be fairly compensated.
        </p>
      </div>

      <Link to="/register">
        <Button>Get Started</Button>
      </Link>
      {/* <div className="row">
        <embed
          src="airmeus_ambassador_details_pdf.pdf"
          width="800px"
          height="2100px"
        />
       
      </div> */}
    </div>
  );
};

export default AirmeusAmbassador;
