import React from 'react';

export default function About() {
  return (
    <div>
      <h1>about page2</h1>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className="nav-link active"
            id="nav-home-tab"
            aria-current="page"
            data-toggle="tab"
            href="#nav-home"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            Feed
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="nav-home1-tab"
            data-toggle="tab"
            href="#nav-home1"
            role="tab"
            aria-controls="nav-home1"
            aria-selected="false"
          >
            Rooms
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="nav-home2-tab"
            data-toggle="tab"
            href="#nav-home2"
            role="tab"
            aria-controls="nav-home2"
            aria-selected="false"
          >
            People
          </a>
        </li>
      </ul>
      {/*       
      //#endregion
      //#endregion
      //#endregion */}
      <div className="container-fluid">
        <div className="tab-content">
          <div
            id="nav-home"
            className="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <h1>home0 cool</h1>
          </div>

          <div
            id="nav-home1"
            className="tab-pane fade"
            role="tabpanel"
            aria-labelledby="nav-home1-tab"
          >
            <h1>home1 cool</h1>
          </div>

          <div
            id="nav-home2"
            className="tab-pane fade"
            crole="tabpanel"
            aria-labelledby="nav-home2-tab"
          >
            <h1>home2 col</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
