import React, {
  Fragment,
  Component,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import eventContext from '../../../context/event/eventContext'; // might have to capitalize
import { useAuth } from '../../../context/auth/AuthState';
import { Link } from 'react-router-dom';

let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

const Workshop = (props) => {
  const [authState] = useAuth();
  const { user } = authState;
  const eContext = useContext(eventContext);
  const {
    updateWorkshop,
    joinWorkshop,
    workshop_attendees,
    getWorkshopAttendees,
    getWorkshopSchedule,
    // getWorkshop
  } = eContext;
  useEffect(() => {
    getWorkshopAttendees(workshop._id);
  }, []);

  //   setVisible(false);
  console.log('workshop_attendees --- from workshop');
  console.log(workshop_attendees);
  const {
    workshop,
    event,
    attendee_count,
    workshopJoined,
    showAttendees,
    alreadyJoined,
    // showTime,
    showJoinedButton,
  } = props;
  const [visible, setVisible] = React.useState(showAttendees);

  let updatedWorkshop = workshop;
  const [levelButtonClicked, setLevelButtonClicked] = useState(false);
  const [roomButtonClicked, setRoomButtonClicked] = useState(false);

  const [levelTitle, setLevelTitle] = useState('Beginner');
  const [workshopTitleButtonClicked, setWorkshopTitleButtonClicked] = useState(
    false
  );
  let navigate = useNavigate();

  const workshopCard = () => {
    if (levelButtonClicked) {
    }
  };
  const [level, setLevel] = useState('');
  const handleSelectLevel = (e) => {
    //send request to backend to update level
    //updateWorkshopLevel(level)
    setLevel(e);
    setLevelTitle(e);
    updatedWorkshop.level = e;
  };

  const isHost = () => {
    return event.hostId === user?._id;
  };
  // let h = isHost === true ? '300px' : '175px';
  // if (isHost === true) {
  //   if (levelButtonClicked || roomButtonClicked || workshopTitleButtonClicked) {
  //     console.log('levelButtonClicked');

  //     console.log(levelButtonClicked);
  //     h = '300px';
  //   } else {
  //     console.log(levelButtonClicked);

  //     h = '300px';
  //   }
  // }

  const [room, setRoom] = useState(workshop.location);
  const handleSelectRoom = (e) => {
    //send request to backend to update level
    //updateWorkshopLevel(level)
    setRoom(e);
    updatedWorkshop.location = e;
  };
  const renderCardRegular = (
    // <div className="card bg-light" style={{ height: `${h}` }}>
    <div className="card bg-light">
      <div className="row">
        <div className="col-3">
          <img style={{ width: '50px' }} alt="" src={imgg} />
        </div>
        <div className="col">
          <h5>Adolfo Indacochea</h5>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <label>{workshop?.location}</label>
        </div>
        <div className="col">
          <label>Advanced</label>
        </div>
        <div className="col">
          <label>37 going</label>
        </div>
        <div className="col">
          <Button>Join</Button>
        </div>
      </div>

      <div>
        {[DropdownButton].map((DropdownType, idx) => (
          <DropdownType
            as={ButtonGroup}
            key={idx}
            id={`dropdown-button-drop-${idx}`}
            size="sm"
            variant="secondary"
            title={levelTitle}
            onClick={() => {
              setLevelButtonClicked(!levelButtonClicked);
            }}
            onSelect={handleSelectLevel}
          >
            <Dropdown.Item eventKey="Beginner">Beginner</Dropdown.Item>
            <Dropdown.Item eventKey="Intermediate">Intermediate</Dropdown.Item>
            <Dropdown.Item eventKey="Advanced">Advanced</Dropdown.Item>
            {/* <Dropdown.Divider />
              <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
          </DropdownType>
        ))}
        <h5>{level}</h5>
      </div>

      {/* <div className="bg-dark p">
          <button onClick={onPass}>pass</button>
          <button onClick={onLike}>like</button>
        </div> */}
    </div>
  );

  const renderCardExtended = (
    // <div className="card bg-light" style={{ height: `${h}` }}>
    <div className="card bg-light">
      <div className="row">
        <div className="col-3">
          <img style={{ width: '50px' }} alt="" src={imgg} />
        </div>
        <div className="col">
          <h5>Adolfo Indacochea</h5>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <label>{workshop?.location}</label>
        </div>
        <div className="col">
          <label>Advanced</label>
        </div>
        <div className="col">
          <label>38 going</label>
          {/* <i class="fa-solid fa-circle-info" /> */}
          {/* <i className="fa fa-check pr-1" /> */}

          {/* <FontAwesomeIcon icon="fa-solid fa-circle-info" /> */}
        </div>
        <div className="col">
          <Button
            onClick={() => {
              let obj = { workshop_id: workshop._id, user_id: user._id };
              joinWorkshop(obj);
            }}
          >
            Join us
          </Button>
        </div>
      </div>

      <div>
        {[DropdownButton].map((DropdownType, idx) => (
          <DropdownType
            as={ButtonGroup}
            key={idx}
            id={`dropdown-button-drop-${idx}`}
            size="sm"
            variant="secondary"
            title={levelTitle}
            onClick={() => {
              setLevelButtonClicked(!levelButtonClicked);
            }}
            onSelect={handleSelectLevel}
          >
            <Dropdown.Item eventKey="Beginner">Beginner</Dropdown.Item>
            <Dropdown.Item eventKey="Intermediate">Intermediate</Dropdown.Item>
            <Dropdown.Item eventKey="Advanced">Advanced</Dropdown.Item>
            {/* <Dropdown.Divider />
              <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
          </DropdownType>
        ))}
        <h5>{level}</h5>
        {/* <FontAwesomeIcon icon="fa-solid fa-circle-info" /> */}
      </div>

      {/* <div className="bg-dark p">
          <button onClick={onPass}>pass</button>
          <button onClick={onLike}>like</button>
        </div> */}
    </div>
  );
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(e);
  };
  const [title, setTitle] = useState(workshop?.title ? workshop.title : '');

  //   const onChange = (e) => setTitle(e.target.name);

  let [user2, setUser2] = useState({
    name2: 'hi',
  });
  const { name2 } = user2;
  const onChange = (e) => {
    console.log('click');
    setUser2({ ...user2, [e.target.name]: e.target.value });
    console.log(e.target.value);
    updatedWorkshop.title = e.target.value;
  };

  const showEditButtons = (
    <Fragment>
      <div>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            {/* <label htmlFor="name2">Name--</label> */}
            <input
              id="name2"
              type="text"
              name="name2"
              value={workshop?.title ? workshop.title : ''}
              onChange={onChange}
              placeholder={workshop?.title ? workshop.title : ''}
              required
            />
          </div>
          {/* <input
            type="submit"
            value="Save"
            className="btn btn-primary btn-block"
          /> */}
        </form>
      </div>
      <div className="row">
        <div className="col">
          <Button
            className="btn-cancel"
            style={{
              bottom: '10px',
              position: 'absolute',
            }}
            onClick={() => {
              //   h = '150px';
              // console.log(h);
              setLevelButtonClicked(false);
              setRoomButtonClicked(false);
              setWorkshopTitleButtonClicked(false);
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="col">
          <Button
            className="btn-save"
            style={{
              bottom: '10px',
              position: 'absolute',
            }}
            onClick={() => {
              //   h = '150px';
              console.log('updatedWorkshop ----');
              console.log(updatedWorkshop);
              setLevelButtonClicked(false);
              setRoomButtonClicked(false);
              setWorkshopTitleButtonClicked(false);

              updateWorkshop(workshop);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );

  const popunder = (
    <Fragment>
      <>
        {['bottom'].map((placement) => (
          <OverlayTrigger
            trigger="click"
            key={placement}
            placement={placement}
            overlay={
              <Popover id={`popover-positioned-${placement}`}>
                <Popover.Header as="h5">{`update name`}</Popover.Header>
                <Popover.Body>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label htmlFor="name2">Name--</label>
                      <input
                        id="name2"
                        type="text"
                        name="name2"
                        value={name2}
                        onChange={onChange}
                        required
                      />
                    </div>
                    <input
                      type="submit"
                      value="Save"
                      className="btn btn-primary btn-block"
                    />
                  </form>
                </Popover.Body>
              </Popover>
            }
          >
            <Button variant="secondary">intro to salsa</Button>
          </OverlayTrigger>
        ))}
      </>
    </Fragment>
  );

  //   const isHost = () => {
  //     if (event.hostId === user._id) {
  //       //render editable buttons
  //     } else {
  //       //render non-edit buttons/labels
  //     }
  //   };

  const [joinedWorkshop, setJoinedWorkshop] = useState(false);
  const [joinButtonStyle, setjoinButtonStyle] = useState('');
  // let [attendees, setAttendees] = useState(workshop?.attendees.length);

  let [attendees, setAttendees] = useState(workshop?.attendees.length);

  // let [attendees, setAttendees] = useState(workshop_attendees.length);
  const handleJoinWorkshop = () => {
    // workshopJoined();
    if (!user) {
      navigate('/login');
    } else {
      let obj = { workshop_id: workshop._id, user_id: user._id };
      joinWorkshop(obj);
      //   setAttendees(workshop_attendees); //after we join, we request workshop_attendees
      setAttendees(attendees + 1);
      console.log('attendees is');
      console.log(attendee_count);
      setDisabled(true); //?
      setJoinButtonTitle('Joined');
      setjoinButtonStyle('bg-primary');
      setJoined(true);
      props.onJoin();
      // getWorkshopSchedule(event._id);
      // getWorkshopAttendees(workshop._id);
      // workshop.attendees = workshop_attendees;
    }
  };

  const joinedAttendeeCount = (
    <Fragment>
      <Button>{workshop?.attendees.length + 1} going</Button>
    </Fragment>
  );
  const beforeJoiningAttendeeCount = (
    <Fragment>
      <Button>{workshop?.attendees.length} going</Button>
    </Fragment>
  );

  const goingCount = () => {
    if (!joined) {
      return beforeJoiningAttendeeCount;
    } else {
      return joinedAttendeeCount;
    }
  };

  // const joined = () =>{

  // }
  const [joined, setJoined] = useState(false);
  const attendeeCount = (
    <Fragment>
      {/* <label> {workshop_attendees} going</label> */}
      {/* {joinedWorkshop ? (
        <label> {attendee_count + 1} going</label>
      ) : (
        <label> {attendee_count} going</label>
      )} */}
      <div style={{ marginTop: '5px' }}>
        <Button
          onClick={() => {
            setVisible(!visible);
            console.log(workshop._id);
            getWorkshopAttendees(workshop._id);
          }}
        >
          {' '}
          {/* {visible ? `close` : `${attendee_count} going`} */}
          {visible ? `close` : `${workshop?.attendees.length} going`}
          {/* {visible ? `close` : goingCount()} */}
        </Button>
      </div>
    </Fragment>
  );
  const noshow = (
    <Fragment>
      <label>none</label>
    </Fragment>
  );

  const workshopAttendeesComponent = (
    <Fragment>
      <div
        className=""
        // style={{ marginLeft: '5px', marginRight: '5px' }}
      >
        {/* {workshop_attendees.map((attendee) => (
          <img
            className="rounded-circle"
            src={attendee?.image?.url ? attendee?.image?.url : imgg}
            width="50"
            height="50"
            alt="avatar"
            style={{ marginLeft: '5px', marginRight: '5px' }}
          />
        ))} */}
        {/* {workshop_attendees.map((attendee) => ( */}
        {workshop?.attendees.map((attendee) => (
          <img
            className="rounded-circle"
            src={attendee?.image?.url ? attendee?.image?.url : imgg}
            width="50"
            height="50"
            alt="avatar"
            style={{ marginLeft: '5px', marginRight: '5px' }}
          />
        ))}
      </div>
    </Fragment>
  );

  const [disabled, setDisabled] = useState(false);
  const [joinButtonTitle, setJoinButtonTitle] = useState('Join');

  const joinButton = (
    <Fragment>
      {alreadyJoined === true ? (
        <Button className="bg-primary">Joined</Button>
      ) : (
        <Button
          onClick={handleJoinWorkshop}
          disabled={disabled}
          className={joinButtonStyle}
        >
          {joinButtonTitle}
        </Button>
      )}
    </Fragment>
  );
  const dontRenderButton = (
    <Fragment>
      <></>
    </Fragment>
  );

  const renderJoinButton = () => {
    if (showJoinedButton === true) {
      console.log('show button true');

      //regular render
      return joinButton;
    } else {
      console.log('showJoinedButton should be false');
      console.log(showJoinedButton);

      return dontRenderButton;
    }
  };
  return (
    <div className="container">
      {/* {showTime ? (
        <h2
          style={{ backgroundColor: 'black', color: 'white', width: '150px' }}
        >
          {workshop.time}
        </h2>
      ) : (
        <></>
      )} */}
      {/* <div className="card bg-light" style={{ height: `${h}` }}> */}
      <div className="card bg-light">
        <div className="row">
          <div className="col-3">
            <img
              style={{ width: '50px' }}
              alt=""
              src={
                workshop?.instructor_image ? workshop?.instructor_image : imgg
              }
            />
            {/* src={attendee?.image?.url ? attendee?.image?.url : imgg} */}
          </div>
          <div className="col">
            <div className="row">
              <div className="col">
                <Link
                  to={`/artist-profile/${workshop?.instructor}`}
                  style={{ color: 'blue' }}
                >
                  <label>{workshop.instructor_name}</label>
                </Link>
              </div>
              <div className="col">
                <label style={{ position: 'absolute', right: '20px' }}>
                  {workshop.time}
                </label>
              </div>
              {/* <label>eddy peligro</label> */}
            </div>

            <div className="row">
              <label htmlFor="">
                {workshop?.title ? workshop.title : <></>}
              </label>
              {/* <Button onClick={()={}}>intro to salsa</Button> */}
              {/* <label>intro salas</label> */}
              {/* {popunder} */}
            </div>
          </div>
        </div>
        {/* <div className="row"> */}
        {isHost() === true ? (
          <div className="row">
            <div className="col">
              <div>
                {[DropdownButton].map((DropdownType, idx) => (
                  <DropdownType
                    as={ButtonGroup}
                    key={idx}
                    id={`dropdown-button-drop-${idx}`}
                    size="sm"
                    variant="secondary"
                    title={room}
                    onClick={() => {
                      setRoomButtonClicked(true);
                    }}
                    onSelect={handleSelectRoom}
                  >
                    {/* we'll dynamically render based on # of rooms */}
                    <Dropdown.Item eventKey="Room 1">Room 1</Dropdown.Item>
                    <Dropdown.Item eventKey="Room 2">Room 2</Dropdown.Item>
                    <Dropdown.Item eventKey="Room 3">Room 3</Dropdown.Item>

                    {/* <Dropdown.Divider />
              <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
                  </DropdownType>
                ))}
              </div>
            </div>

            <div className="col">
              <div>
                {[DropdownButton].map((DropdownType, idx) => (
                  <DropdownType
                    as={ButtonGroup}
                    key={idx}
                    id={`dropdown-button-drop-${idx}`}
                    size="sm"
                    variant="secondary"
                    title={workshop?.level ? workshop.level : 'select a level'}
                    onClick={() => {
                      setLevelButtonClicked(true);
                    }}
                    onSelect={handleSelectLevel}
                  >
                    <Dropdown.Item eventKey="Beginner">Beginner</Dropdown.Item>
                    <Dropdown.Item eventKey="Intermediate">
                      Intermediate
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Advanced">Advanced</Dropdown.Item>
                    {/* <Dropdown.Divider />
              <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
                  </DropdownType>
                ))}
              </div>
            </div>
            <div className="col">
              <Button
                style={{ fontSize: '12px' }}
                onClick={() => {
                  setWorkshopTitleButtonClicked(!workshopTitleButtonClicked);
                }}
              >
                Edit Title
              </Button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col">
              <label>{`room ${room}`}</label>
            </div>

            <div className="col">
              <label style={{ position: 'absolute', right: '20px' }}>
                {workshop?.level ? workshop.level : ''}
              </label>
            </div>
            {renderJoinButton()}
            {/* {alreadyJoined === true ? (
              <Button className="bg-primary">Joined</Button>
            ) : (
              <Button
                onClick={handleJoinWorkshop}
                disabled={disabled}
                className={joinButtonStyle}
              >
                {joinButtonTitle}
              </Button>
            )} */}
          </div>
        )}
        <div>
          {/* <label>37 going --- </label> */}
          {/* {attendee_count > 0 ? attendeeCount : <></>} */}
          {attendees > 0 ? attendeeCount : <></>}

          {visible ? workshopAttendeesComponent : <></>}

          {/* {workshop_attendees} going */}
        </div>
        {/* <div className="row"> */}
        {/* <div className="col">
            <label>{workshop?.location}</label>
          </div> */}
        {/* <div className="col">
            <label>Advanced</label>
          </div> */}
        {/* <div className="col-3">
            <label>37 going</label>
          </div> */}
        {/* <div className="col"> */}

        {/* <Button>Join</Button> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="bg-dark p">
          <button onClick={onPass}>pass</button>
          <button onClick={onLike}>like</button>
        </div> */}
        {roomButtonClicked ||
        levelButtonClicked ||
        workshopTitleButtonClicked ? (
          showEditButtons
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default Workshop;
