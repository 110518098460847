import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import ArtistOnboardingDetails from './ArtistOnboardingDetails';

const ArtistOnboard = (props) => {
  const returnNextScreen = (array) => {
    if (array.includes('Instructor')) {
      return '/instructor-onboarding';
    } else if (array.includes('Dance Company Owner')) {
      return '/studio-onboarding';
    }

    // switch (array[0]) {
    //   case 'Instructor':
    //     return '/instructor-onboarding';
    //   // return '/terms-of-service';
    //   case 'event organizer':
    //     return '/event-organizer-onboarding';
    //   case 'dance studio owner':
    //     return '/studio-onboarding';
    //   case 'dance student':
    //     return '/student-onboarding';

    //   default:
    //     console.log('defualt route running');
    //     return '/privacy-policy';
    // }
  };

  let remainingScreens = '';
  const location = useLocation();
  console.log('location.state');
  console.log(location.state);
  let { onboardingScreens } = location.state;
  if (onboardingScreens.length > 1) {
    remainingScreens = onboardingScreens.filter(
      (screen) => screen !== 'Artist'
      // (screen) => screen !== onboardingScreens[0]
    );
  }

  const renderDynamicRoutes = () => {
    if (remainingScreens.length > 0) {
      console.log('remaining screens --:');
      console.log(remainingScreens);
      let nextScreen = returnNextScreen(remainingScreens);
      console.log('nextScreen is');
      console.log(nextScreen);
      return (
        <Link
          to={nextScreen}
          state={{ onboardingScreens: remainingScreens, artistOnboarded: true }}
        >
          <Button>next</Button>
        </Link>
      );
    } else {
      console.log('we should have a onboard complete button');

      console.log(remainingScreens);
      return (
        <Link to={'/'}>
          <Button>Onboard Complete, Let's Dance! </Button>
        </Link>
      );
    }
  };

  return (
    <div>
      <ArtistOnboardingDetails />
      <br />
      {renderDynamicRoutes()}
    </div>
  );
};

export default ArtistOnboard;
