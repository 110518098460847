import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';
import StudioContext from '../../context/studio/studioContext';
import AddStudioLevel from './AddStudioLevel';
import Levels from './Levels';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Score from './Score';
import Form from 'react-bootstrap/Form';
import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';
import { useAuth } from '../../context/auth/AuthState';
import ReactGA from 'react-ga4';

const Studio = (props) => {
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    title: '/studio',
  });

  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;

  const studioContext = useContext(StudioContext);
  const {
    getStudioById,
    studio,
    getStudioMembers,
    members,
    addNewMember,
    getStudioLevels,
    studio_levels,
    updateStudentsScores,

    newStudio,
    addNewStudio,
    allStudios,
    userStudios,
    getAllStudios,
    getUserStudios,
  } = studioContext;

  const userContext = useContext(UserContext);

  const { connectToUser } = userContext;
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;
  let copyClicked = false;
  const location = useLocation();
  const { loggedInUser } = location?.state;

  // const { user, current_user_id, isCurrentUser } = props;
  const { scores, member_id, studio_id } = props;
  // const { studio_id } = useParams();

  useEffect(() => {
    console.log('scores from score.js');
    console.log(scores);
    getAllStudios();
    // console.log('studio_id');
    // console.log(studio_id);
    // //get studio by id
    // getStudioById(studio_id);
    // getStudioMembers(studio_id);
    // getStudioLevels(studio_id);
    // console.log('all studios');
    // console.log(allStudios);
    console.log('loggedInUser ---');
    console.log(loggedInUser);
    getUserStudios(loggedInUser?._id);
    // eslint-disable-next-line
  }, []);
  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const [addDancerFieldsVisible, setAddDancerFieldsVisible] = React.useState(
    false
  );

  const [details, setDetails] = useState({
    name: '',
    state: 'North Carolina',
    city: 'Charlotte', //default to user.city or first city from list
    owner_flag: false,
    owner_id: '',
    image: '',
    // artist_type: 'bachata',
    // existing_studio_name: '',
    // existing_studio_id: '',

    //add img
  });

  const addStudio = (e) => {
    e.preventDefault();
    console.log('submitted');
    console.log('details');
    const data = new FormData();

    data.append('name', name);
    data.append('state', state);
    data.append('city', city);
    data.append('owner_flag', owner_flag);
    data.append('owner_id', owner_id);
    data.append('image', image);
    data.append('file', file); //image

    console.log(details);
    addNewStudio(data);
    addNewStudio(details);
  };

  const { name, city, state, owner_flag, owner_id, image } = details;
  const splitValue2 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[1];
  };

  //city and states

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  const onOwnerChange = () => {
    setDetails({
      ...details,
      owner_flag: !owner_flag,
      owner_id: user?._id,
      //   ['existing_studio_id']: splitValue2(e),
    });

    console.log(details);
  };
  console.log('userStudios --');
  console.log(userStudios);
  const [file, setFile] = useState('');

  return (
    <div>
      <div>
        {location?.state?.messageText ? (
          <div
            className="promotionText all-center"
            style={{ backgroundColor: '#6495ED', color: 'white' }}
          >
            {/* <br /> */}
            <h4>{location.state.messageText}</h4>
          </div>
        ) : (
          <></>
        )}
        <br />
      </div>

      <div className="col">
        <br />

        {userStudios.length > 0 && <h1>My Studios</h1>}
        {userStudios.length > 0 &&
          userStudios.map((studio, index) => (
            <div className="row">
              <div className="col-2">
                <img
                  // src={user.image ? user?.image.url : imgg}
                  // src={user.image ? user?.image : imgg}
                  src={studio?.image ? studio?.image.url : imgg}
                  style={{
                    borderRadius: '0.25rem',
                    // borderStyle: 'solid',
                    height: '50px',
                    width: '50px',
                  }}
                  alt="user"
                />
              </div>
              <div className="col">
                <Link to={`/studio/${studio?._id}`} style={{ color: 'black' }}>
                  <h3>{studio?.name}</h3>
                </Link>
              </div>

              {studio?.premium_subscription?.premium_subscriber === true ? (
                <div className="col">
                  {/* <Link to={`/go-pro-subscription/${studio?._id}`}> */}
                  <button
                    className="rounded-pill"
                    style={{
                      width: '100px',
                      background: 'white',
                      // color: 'blue',
                      borderStyle: 'solid',
                      //  borderColor: 'rgb(1, 126, 252)',

                      fontSize: '14px',
                      backgroundColor: 'white',
                      //  color: 'rgb(1, 126, 252)',
                      color: 'green',
                      textAlign: 'center',
                    }}
                  >
                    Premium Active
                  </button>
                  {/* </Link> */}
                </div>
              ) : (
                <div className="col">
                  <Link to={`/go-pro-subscription/${studio?._id}`}>
                    <button
                      className="rounded-pill"
                      style={{
                        width: '100px',
                        background: 'white',
                        // color: 'blue',
                        borderStyle: 'solid',
                        borderColor: 'rgb(1, 126, 252)',

                        fontSize: '14px',
                        backgroundColor: 'white',
                        color: 'rgb(1, 126, 252)',
                        textAlign: 'center',
                      }}
                    >
                      Go Pro
                    </button>
                  </Link>
                </div>
              )}
            </div>
          ))}
        <hr />
        <h3>
          All Studios{' '}
          <span style={{ fontSize: '12px', color: 'grey' }}>
            ( in alphabetical order )
          </span>
        </h3>
        {user ? (
          <Button
            onClick={() => setAddDancerFieldsVisible(!addDancerFieldsVisible)}
            class="btn btn-secondary"
          >
            {addDancerFieldsVisible ? 'cancel' : 'Add New Studio'}
          </Button>
        ) : (
          <>
            <Link to="/login">
              <Button>log in to post your studio</Button>
            </Link>
          </>
        )}
      </div>

      <div style={{ paddingTop: '8px' }}>
        <a href="/studios-by-region" style={{ color: 'rgb(1, 126, 252)' }}>
          see studios by region
        </a>
      </div>
      <div className="row">
        {addDancerFieldsVisible && (
          <div className="col">
            {/* <h2>{user?.name}</h2> */}
            <label class="form-check-label" for={'name'}>
              Studio Name
            </label>
            <form onSubmit={addStudio}>
              <input
                id="name"
                name="name" // as="text" type="text"
                value={name}
                required
                onChange={(e) => {
                  setDetails({
                    ...details,
                    [e.target.name]: e.target.value,
                    //   ['existing_studio_id']: splitValue2(e),
                  });
                  // setSelectedCity();
                  console.log('e is');
                  console.log(e);
                  console.log(details);
                }}
              />

              <input
                id="image"
                name="image"
                type="file"
                value={image}
                onChange={(event) => {
                  const file = event?.target?.files[0];
                  setFile(file);
                  setDetails({
                    ...details,
                    [event.target.name]: event.target.value,
                  });
                }}
              />

              <Form.Group controlId="formBasicSelect">
                <Form.Label>State</Form.Label>
                <Form.Control
                  id="state"
                  name="state"
                  as="select"
                  value={state}
                  onChange={(e) => {
                    // setDetails({
                    //   ...details,
                    //   [e.target.name]: e.target.value,
                    // });
                    console.log(' e.target.value');
                    console.log(e.target.value);
                    // setSelectedState(e.target.value);
                    let cities = selectedCities(e.target.value);
                    // setSelectedCity(cities[0]);
                    console.log('details..city filled?');
                    console.log(details);
                  }}
                >
                  {listOfStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicSelect">
                <Form.Label>City</Form.Label>
                <Form.Control
                  id="city"
                  name="city"
                  as="select"
                  value={city}
                  required
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      [e.target.name]: e.target.value,
                    });
                    // setSelectedCity();
                    console.log(details);
                  }}
                >
                  {listOfCitiesForSelectedState.map((city) => (
                    <option value={city}>{city}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <div class="form-check">
                {/* <input
                  //   class="form-check-input"
                  //   type="checkbox"
                  //   name={user.name}
                  //   id={user._id}
                  //   value="option1"
                  //   onChange={onChange}
                  type="checkbox"
                  id="are_you_the_owner"
                  name="are_you_the_owner" // as="text" type="text"
                  value={''}
                  required
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      [e.target.name]: e.target.value,
                      //   ['existing_studio_id']: splitValue2(e),
                    });
                    // setSelectedCity();
                    console.log('e is');
                    console.log(e);
                    console.log(details);
                  }}
                /> */}

                {/* <label class="form-check-label" for={}>
                  {"Are you one of the owners of this studio?"}
                </label> */}

                <input
                  //  id="name"
                  //  name="name" // as="text" type="text"
                  //  value={name}
                  id="owner"
                  type="checkbox"
                  name="owner"
                  value={'owner'}
                  onChange={onOwnerChange}
                  //   onChange={(e) => {
                  //     setDetails({
                  //       ...details,
                  //       [e.target.name]: !owner,
                  //       ['owner_id']: user?._id,
                  //       //   ['existing_studio_id']: splitValue2(e),
                  //     });
                  //     // setSelectedCity();
                  //     console.log('e is');
                  //     console.log(e);
                  //     console.log(details);
                  //   }}
                />

                <label for="owner">
                  {' '}
                  Are you one of the owners of this studio
                </label>
              </div>

              <input
                type="submit"
                value="Add Studio"
                // className="btn btn-primary btn-block"
                class="btn btn-secondary"
                // onClick={onSubmit2}
              />
            </form>

            <br />
          </div>
        )}
      </div>

      {!addDancerFieldsVisible &&
        allStudios.map((studio) => (
          <div>
            <br />
            <div className="row">
              <div className="col-2">
                <img
                  // src={user.image ? user?.image.url : imgg}
                  // src={user.image ? user?.image : imgg}
                  src={studio?.image ? studio?.image.url : imgg}
                  height="50px"
                  width="50px"
                  alt="user"
                  style={{ borderRadius: '0.25rem', borderStyle: 'solid' }}
                />
              </div>
              <div className="col">
                <Link to={`/studio/${studio._id}`} style={{ color: 'black' }}>
                  <h3>{studio.name}</h3>
                </Link>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Studio;
