import React, { useReducer } from 'react';
import axios from 'axios';
//be sure the case is not case sensitive

import incidentReportReducer from './incidentReportReducer';
import IncidentReportContext from './incidentReportContext';

import {
  GET_INCIDENT_REPORT,
  GET_ALL_INCIDENT_REPORTS,
  SUBMIT_INCIDENT_REPORT,
  GET_USER_INCIDENT_REPORTS,
  UPDATE_INCIDENT_REPORT,
  VOTE_ON_INCIDENT_REPORT,
  SUSPEND_PERSON,
  GET_SUSPENSION_LIST,
  INCIDENT_REPORT_ERROR,

  // GET_ONE_ARTISTS_RANKING,
} from '../types';
const IncidentReportState = (props) => {
  const initialState = {
    loading: true,
    incidentReport: {},
    all_incident_reports: [],
    incident_report: {},
    user_incident_reports: [],
    submitted_incident_report: {},
    suspensionList: [],
    suspended_person: {},
    error: {},
    // confirmedPrivateLesso    ns: {},
  };

  const [state, dispatch] = useReducer(incidentReportReducer, initialState);

  const getAllIncidentReports = async () => {
    try {
      console.log('trying getAllIncidentReports');
      const res = await axios.get(`/api/incident-reports`);
      console.log(
        'res below from getAllIncidentReports IncidentReportState.js'
      );
      console.log(res);

      dispatch({
        type: GET_ALL_INCIDENT_REPORTS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INCIDENT_REPORT_ERROR,
        payload: err,
      });
    }
  };
  const getUserIncidentReports = async (user_id) => {
    try {
      console.log('trying getUserIncidentReports');
      const res = await axios.get(`/api/incident-reports/user/${user_id}`);
      console.log(
        'res below from getUserIncidentReports IncidentReportState.js'
      );
      console.log(res);

      dispatch({
        type: GET_USER_INCIDENT_REPORTS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INCIDENT_REPORT_ERROR,
        payload: err,
      });
    }
  };
  const getSuspensionList = async () => {
    try {
      console.log('trying getSuspensionList');
      const res = await axios.get(`/api/incident-reports/suspension-list`);
      console.log('res below from getSuspensionList IncidentReportState.js');
      console.log(res);

      dispatch({
        type: GET_SUSPENSION_LIST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INCIDENT_REPORT_ERROR,
        payload: err,
      });
    }
  };
  const getIncidentReport = async (incident_report_id) => {
    try {
      console.log('trying getIncidentReport');
      const res = await axios.get(
        `/api/incident-reports/incident/${incident_report_id}`
      );
      console.log('res below from getIncidentReport IncidentReportState.js');
      console.log(res);

      dispatch({
        type: GET_INCIDENT_REPORT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INCIDENT_REPORT_ERROR,
        payload: err,
      });
    }
  };

  const submitIncidentReport = async (form) => {
    try {
      console.log(` form is ${form}`);

      const res = await axios.post(
        '/api/incident-reports/new-incident-report',
        form
      );
      console.log('res.data from addNewArtist in IncidentReportState.js ---');
      console.log(res.data);
      dispatch({
        type: SUBMIT_INCIDENT_REPORT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INCIDENT_REPORT_ERROR,
        payload: err.response,
      });
    }
  };
  const updateIncidentReport = async (form, incident_id) => {
    try {
      console.log(` form is ${form}`);

      const res = await axios.put(
        `/api/incident-reports/update-incident-report/${incident_id}`,
        form
      );
      console.log(
        'res.data from updateIncidentReport in IncidentReportState.js ---'
      );
      console.log(res.data);
      dispatch({
        type: UPDATE_INCIDENT_REPORT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INCIDENT_REPORT_ERROR,
        payload: err.response,
      });
    }
  };
  const submitVote = async (vote) => {
    try {
      console.log(` vote is ${vote}`);

      const res = await axios.put(
        // `/api/incident-reports/incident-report-vote/${incident_id}`,
        `/api/incident-reports/incident-report-vote`,
        vote
      );
      console.log('res.data from submitVote in IncidentReportState.js ---');
      console.log(res.data);
      dispatch({
        type: VOTE_ON_INCIDENT_REPORT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INCIDENT_REPORT_ERROR,
        payload: err.response,
      });
    }
  };

  const suspendPerson = async (form) => {
    try {
      console.log(` form is ${form}`);

      const res = await axios.post(
        '/api/incident-reports/suspend-person',
        form
      );
      console.log('res.data from suspendPerson in IncidentReportState.js ---');
      console.log(res.data);
      dispatch({
        type: SUSPEND_PERSON,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INCIDENT_REPORT_ERROR,
        payload: err.response,
      });
    }
  };

  return (
    <IncidentReportContext.Provider
      value={{
        // props -- where our state and actions will go
        loading: state.loading,
        all_incident_reports: state.all_incident_reports,
        submitted_incident_report: state.submitted_incident_report,
        user_incident_reports: state.user_incident_reports,
        incident_report: state.incident_report,
        suspended_person: state.suspended_person,
        suspensionList: state.suspensionList,

        getAllIncidentReports,
        submitIncidentReport,
        getUserIncidentReports,
        getIncidentReport,
        updateIncidentReport,
        submitVote,
        suspendPerson,
        getSuspensionList,
      }}
    >
      {props.children}
    </IncidentReportContext.Provider>
  );
};

export default IncidentReportState;
