// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import AuthContext from '../../context/auth/authContext'; // might have to capitalize

import Attendee from '../user/Attendee';

import { useAuth, clearErrors, login } from '../../context/auth/AuthState';

// import EventContext from '../../context/event/eventContext';
// import { useHistory } from 'react-router-dom';

const Attendees = (props) => {
  const eventContext = useContext(EventContext);
  const authContext = useContext(AuthContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;
  const { user } = authState;

  const { getAttendeesForEvent, attendees, joinEvent, loading } = eventContext;
  //get user from AuthState
  // const { user } = authContext.state;
  const { event, event_attendees } = props;
  useEffect(() => {
    console.log('event from attendees props:');
    console.log(event);
    console.log('authContext below:'); //the user is not being populated nor is isauthentivcated
    //so somehting is not hooked up right .. start from the source .. what is the authcontext pathway/relevance
    //to this file

    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    if (!event?._id) {
      console.log('no event id');
      console.log('event');
      console.log(event);
    }
    getAttendeesForEvent(event?._id);

    // userAlreadyAttending(attendees);
    // eslint-disable-next-line
  }, []);

  //conditionally render attendees based on if there are any
  //attendees signed up yet

  const event_has_attendees = attendees > 0;

  //for forms
  // const onSubmit = (e) => {
  //   e.preventDefault();

  //   console.log('joining event');
  // };

  //helper for submit button
  const join_event = () => {
    joinEvent(user._id, event._id);
  };

  let alreadyJoined = false;
  // const userAlreadyAttending = (attendeesList) => {
  //   console.log('attendeesList');
  //   console.log(attendeesList);
  //   for (var i = 0; i < attendeesList.length; i++) {
  //     if (attendeesList[i]._id === user._id) {
  //       console.log(`${user._id} - ${user.name}  already in attendees list`);
  //       alreadyJoined = true;
  //       return true;
  //     }
  //   }
  //   console.log('user not in attendees yet');
  //   return false;
  // };
  const userAlreadyAttending = () => {
    console.log('attendees');
    console.log(attendees);
    for (var i = 0; i < attendees.length; i++) {
      if (user && attendees[i]._id === user._id) {
        console.log(`${user._id} - ${user.name}  already in attendees list`);
        alreadyJoined = true;
        return true;
      }
    }
    console.log('user not in attendees yet');
    return false;
  };

  const joinButton = (
    <Fragment>
      <button
        type="button"
        className="btn btn-light btn-block"
        onClick={join_event}
      >
        Join Event
      </button>
    </Fragment>
  );
  const alreadyJoinedButton = (
    <Fragment>
      <button
        type="button"
        className="btn btn-dark"

        // onClick={join_event}
      >
        Joined
      </button>
    </Fragment>
  );

  const nouser = (
    <Fragment>
      <Link to="/login">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          sign in to join
        </button>
      </Link>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!user) {
      console.log('no user found');
      return nouser;
    }
    if (user && userAlreadyAttending()) {
      return alreadyJoinedButton;
    } else {
      return joinButton;
    }
  };
  if (loading) {
    return <h2>Loading...</h2>;
  }
  return (
    <div className="container">
      {/* <button type="button" class="btn btn-secondary"> */}
      {/* {!isAuthenticated ? nouser:
     
      } */}

      {/* {!user ? nouser : joinButton} */}

      {renderOptions()}
      {/* {userAlreadyAttending() ? alreadyJoinedButton : joinButton} */}

      {/* <div className="card bg-light"> */}
      <div className=" p">
        {/* <button onClick={onPass}>pass</button>
          <button onClick={onLike}>like</button> */}

        {/* <Button /> */}

        {/* <button>push</button> */}
        {/* <Link to={`/event/${props.event._id}`} state={{ event: event }}>
            Event Details
          </Link> */}

        {/* {event_has_attendees ? ( firstcomp): (secondcomp) } */}
        <div class="d-flex flex-row flex-nowrap">
          {attendees.map((attendee) => (
            <div className="card-horizontal-menu card-block mx-2">
              <Attendee key={attendee.id} attendee={attendee} user={user} />
            </div>
          ))}
        </div>
      </div>
    </div>
    // </div>
  );
};
export default Attendees;

//   const eventContext = useContext(EventContext);

//   const { passOnUser, likeUser } = userContext;

//   const onLike = () => {
//     console.log('user liked and my info below');
//     console.log(user);

//     likeUser(user);
//     //what should happen when you like a user
//     //the user's info should go to backend and the messages>liked users should be updated (may be a premium feature)
//     //delete current first user in the loaded in array of users and replace with the next one up
//   };

//   const onPass = () => {
//     console.log('passed on user');
//     // passOnUser(user._id);
//     passOnUser(user);
//   };

/* conditional render not yet working 

{event_has_attendees ? 
          ({attendees.map((attendee) => (
            <Attendee key={attendee.id} attendee={attendee} />
          ))})

          :(<h1>no attendees yet</h1>)}
          
          */
