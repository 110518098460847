import React, { Component, useContext, useState } from 'react';
import { register, useAuth, clearErrors } from '../../context/auth/AuthState';
import AlertContext from '../../context/alert/alertContext';

const RequiredRegistrationInput = (props) => {
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated, user } = authState;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    password2: '',
    // profile_image: '',
    // location: '',
    safetyOfficers: [],
  });
  const [file, setFile] = useState();
  // const [location, setLocation] = useState();

  const {
    name,
    email,
    phoneNumber,
    password,
    password2,
    // profile_image,
    // location,
  } = userDetails;

  const onChange = (e) =>
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', file);
    data.append('name', name);
    data.append('email', email);
    data.append('phoneNumber', phoneNumber);
    data.append('password', password);
    // data.append('profile_image', profile_image);
    // data.append('location', location);

    if (name === '' || email === '' || password === '' || phoneNumber === '') {
      // if (name === '' || email === '' || password === '') {
      setAlert('Please enter all fields', 'danger');
    } else if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      console.log('registering user');
      //navigate to optional fields and skip to last step

      props.setRequiredFieldsInputted(true);
      //what is this field below for?
      props.setInputtingOptionalFields(true);

      console.log('event');
      console.log(props.event);

      console.log('props yo --');
      console.log(props);
      let obj = {};
      if (props?.safetyOfficerAndEventId) {
        //add safety officers
        console.log('yes..we have officer');
        console.log('props?.safetyOfficerAndEventId');
        console.log(props?.safetyOfficerAndEventId);

        let { name, phone_number } = props.safetyOfficerAndEventId;
        // let obj = [{ name: name, phone_number: phone_number,event_id:props?.event._id }];
        obj = {
          safetyOfficers: [
            {
              name: name,
              phone_number: phone_number,
            },
          ],
          event_id: props?.event._id,
        };
        console.log('SO obj available');
        console.log(obj);
        setUserDetails({ ...userDetails, example1: 'this works 1' });
        // setUserDetails({ ...userDetails, event_id: props?.event._id });

        setUserDetails({
          ...userDetails,
          example2: 'this works2',
          safetyOfficers: obj,
        });
      } else {
        console.log('no officer');
      }

      if (props?.event) {
        console.log('yes, we have event -- adding event to userDetails');
        console.log('props.event');
        console.log(props.event);

        setUserDetails({
          ...userDetails,
          event_id: props?.event._id,
          example: 'example3',
          safetyOfficers: [props.safetyOfficerAndEventId],
        });
      } else {
        console.log('no event');
      }
      console.log('userDetails after additions from RequiredRegistrationInput');
      console.log(userDetails);
      //add safety officer to event
      //   data.append('safety_officer_and_event', obj);
      data.append('safety_officer_and_event', obj);
      //   register(authDispatch, data);
      let { name, phone_number } = props.safetyOfficerAndEventId;
      // let obj = [{ name: name, phone_number: phone_number,event_id:props?.event._id }];
      obj = {
        safetyOfficers: [
          {
            name: name,
            phone_number: phone_number,
          },
        ],
        event_id: props?.event._id,
      };

      let data2 = {
        ...userDetails,
        safetyOfficers: [
          {
            name: name,
            phone_number: phone_number,
          },
        ],
        event_id: props?.event._id,
      };

      console.log('data2');
      console.log(data2);

      console.log('userDetails,obj');
      console.log(userDetails, obj);
      //   register(authDispatch, data);
      register(authDispatch, data2);
    }
  };
  return (
    <div>
      <form onSubmit={onSubmit}>
        {/* <form onSubmit={onSubmit}> */}
        <div className="form-group">
          <input
            id="name"
            type="text"
            name="name"
            value={name}
            onChange={onChange}
            placeholder="Enter Name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            placeholder="Email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            id="phoneNumber"
            type="text"
            name="phoneNumber"
            value={phoneNumber}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={onChange}
            required
            placeholder="Password"
            minLength="6"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password2">Confirm Password</label>
          <input
            id="password2"
            type="password"
            name="password2"
            value={password2}
            onChange={onChange}
            required
            placeholder="Confirm Password"
            minLength="6"
          />
        </div>
        <input
          type="submit"
          value="Complete Registration"
          className="btn btn-primary btn-block"
        />
      </form>
    </div>
  );
};
export default RequiredRegistrationInput;
