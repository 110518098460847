import React, { Component, useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import CurriculumContext from '../../../context/curriculum/curriculumContext';
import DanceMoves from '../../instructor/DanceMoves';
import Alerts from '../../Alerts';
import AlertContext from '../../../context/alert/alertContext';

const AddLessonPlan = (props) => {
  //   const [loading, setLoading] = useState(false);
  const [lessonPlanDetails, setLessonPlanDetails] = useState({
    name: '',
    style: 'Select Style',
    lessonPlan: [],
    dance_moves_id_list: [],
    curriculum_id: props?.curriculum_id ? props.curriculum_id : '',
    dance_type: props?.curriculum_dance_style
      ? props.curriculum_dance_style
      : '',
  });
  let { curriculum_dance_style } = props;
  let curriculumContext = useContext(CurriculumContext);
  let {
    getDanceMovesByStyle2,
    danceMovesByStyle2,
    addLessonPlan,
    loading,
  } = curriculumContext;

  console.log('props from add lesson plans');
  console.log(props);
  useEffect(() => {
    // setLoading(true);
    getDanceMovesByStyle2(curriculum_dance_style);
    // setLoading(false);
    //get
    // if(props.curriculum_id){

    // }

    // eslint-disable-next-line
  }, []);
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;

  //   const [danceMoves, setDanceMoves] = useState(danceMovesByStyle2);
  console.log('danceMovesByStyle2 yoski -');
  console.log(danceMovesByStyle2);

  let test;
  let arr = [];
  if (danceMovesByStyle2 !== undefined) {
    // setDanceMoves(new Map(Object.entries(danceMovesByStyle2)));
    test = new Map(Object.entries(danceMovesByStyle2));
    console.log('test array ----');
    console.log(test);
    test?.forEach((values, keys) => {
      console.log(values, keys);
      arr.push({ key: keys, values: values });
    });

    // Array.from(test.entries()).forEach(([key, value]) => {
    //   console.log(key + ' is ' + JSON.stringify(value));
    // });
    // for (let [key, value] of test) {
    //   console.log(key + ' is ' + value);
    // }
  }

  //   let test = new Map(Object?.entries(danceMovesByStyle2));
  //   console.log('test');
  //   console.log(test);
  //   test?.forEach((values, keys) => {
  //     console.log('checking --------');
  //     console.log(values + ' is ' + keys);
  //   });
  //   getDanceMovesByStyle(style)
  console.log('props from addlp');
  console.log(props);

  const { name, style, dance_moves_id_list } = lessonPlanDetails;

  const onChange = (e) =>
    setLessonPlanDetails({
      ...lessonPlanDetails,
      [e.target.name]: e.target.value,
    });
  const handleDanceStyle = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    setLessonPlanDetails({ ...lessonPlanDetails, style: type });
  };
  const [movesForLessonPlan, setMovesForLessonPlan] = useState([]);

  const addMoveToLessonPlan = (move) => {
    console.log('adding move from parent');
    setMovesForLessonPlan([...movesForLessonPlan, move]);
    //add to details from BookPRivatePopup.js

    if (movesForLessonPlan.includes(move)) {
      console.log('removing move');
      // movesForLessonPlan = movesForLessonPlan.filter((item) => item !== move);
      setMovesForLessonPlan(movesForLessonPlan.filter((item) => item !== move));
      props.setDetails({
        ...lessonPlanDetails,
        lessonPlan: movesForLessonPlan.filter((item) => item !== move),
      });
    } else {
      console.log('adding move');
      // movesForLessonPlan.push(move);
      setMovesForLessonPlan([...movesForLessonPlan, move]);
      setLessonPlanDetails({
        ...lessonPlanDetails,
        lessonPlan: [...movesForLessonPlan, move],
      });
    }

    // props.setDetails({
    //   ...props.details,
    //   lessonPlan: [...movesForLessonPlan, move],
    // });

    console.log('movesForLessonPlan after adding');
    console.log(movesForLessonPlan);
  };
  const addMoveIDToLessonPlan = (move_id) => {
    console.log('adding move_id from parent');
    setMovesForLessonPlan([...movesForLessonPlan, move_id]);
    //add to details from BookPRivatePopup.js

    if (movesForLessonPlan.includes(move_id)) {
      console.log('removing move_id');
      // movesForLessonPlan = movesForLessonPlan.filter((item) => item !== move);
      setMovesForLessonPlan(
        movesForLessonPlan.filter((item) => item !== move_id)
      );
      props.setDetails({
        ...lessonPlanDetails,
        dance_moves_id_list: movesForLessonPlan.filter(
          (item) => item !== move_id
        ),
      });
    } else {
      console.log('adding move_id');
      // movesForLessonPlan.push(move_id);
      setMovesForLessonPlan([...movesForLessonPlan, move_id]);
      setLessonPlanDetails({
        ...lessonPlanDetails,
        dance_moves_id_list: [...movesForLessonPlan, move_id],
      });
    }

    // props.setDetails({
    //   ...props.details,
    //   lessonPlan: [...movesForLessonPlan, move],
    // });

    console.log('movesForLessonPlan after adding');
    console.log(movesForLessonPlan);
  };
  if (loading) {
    return <h2>Loading...</h2>;
  }

  const submitAddLessonPlan = () => {
    if (name === '') {
      //show alert <Alerts />
      //   if (error === 'User already exists') {
      setAlert('Please add a lesson plan name', 'danger');
      // clearErrors(authDispatch);
      //   }
      console.log(lessonPlanDetails);
    } else {
      addLessonPlan(lessonPlanDetails);
    }
  };

  return (
    <div className="card">
      {/* <h5>AddLessonPlan</h5> */}

      <div>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Lesson Plan Name"
          value={name}
          onChange={onChange}
          required
        />

        <div className="col">
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="sm"
              variant="secondary"
              title={style}
              placeholder="Select Dance Style"
              onSelect={handleDanceStyle}
            >
              {['zouk', 'salsa'].map((type) => (
                <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>
      </div>

      {arr?.map((level) => (
        <div>
          {console.log('level')}
          {console.log(level)}
          <h5>{level.key}</h5>
          {level.values?.map((dance_move) => (
            // console.log(dance_move)
            // <div>{dance_move.move_name}</div>

            <div
              className="col grid-container-small-screen grid-container-big-screen"
              style={{ padding: '2px' }}
            >
              <DanceMoves
                move={dance_move}
                addMoveToLessonPlan={addMoveToLessonPlan}
                addMoveIDToLessonPlan={addMoveIDToLessonPlan}
              />
            </div>
          ))}
        </div>
      ))}
      <Alerts />
      <Button
        onClick={() => {
          submitAddLessonPlan();
        }}
      >
        Add Lesson Plan
      </Button>
      {/* {test?.forEach((values, keys) =>  (
        <div>
          <h5>test1</h5>
          {values?.map((dance_move) => (
            // console.log(dance_move)
            // <div>{dance_move.move_name}</div>
            <div>
              <div>{console.log('dance_move obj')}</div>
              <div>{console.log(dance_move)}</div>
              <div>
                <h5>boom</h5>
              </div>
            </div>
          ))}
        </div>
      ))} */}
    </div>
  );
};

export default AddLessonPlan;
