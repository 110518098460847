import React, { Fragment, Component, useState } from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize

import { useContext, useEffect } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import { Button } from 'react-bootstrap';
import Fade from 'react-bootstrap/Fade';

const Company3Hardcoded = (props) => {
  const [open, setOpen] = useState(true);

  const [disabled, setDisabled] = useState(false);

  const [authState] = useAuth();

  const { user } = authState;
  const userContext = useContext(UserContext);

  const {
    connectToUser,
    likeCompany,
    getOneSharkTankBusiness,
    one_sharktank_participant,
  } = userContext;

  const {
    target_user,
    leader,
    key,
    // getNextBusiness,
    getNextBusinessHardcoded,
  } = props;
  console.log('user from UserSearchItem ');
  console.log(target_user);
  console.log('leader');
  console.log(leader);
  // useEffect(() => {
  //   console.log('getting myeventdetail');
  //     getCurrentUser();
  //   // eslint-disable-next-line
  // }, []);
  const [buttonText, setButtonText] = useState('Add Connection');

  const [likeCount, setLikeCount] = React.useState(target_user?.likes?.length);

  const connectToUserFunction = (e) => {
    e.preventDefault();
    console.log('connecting to user');
    // console.log(user);
    setButtonText('Added');
    setDisabled(true);
    let obj = {
      userIdToConnectTo: target_user?._id,
      current_user_id: user._id,
      // eventId: roomie_request.event,
      // accepted_user_id: roomie_request.requestor,
    };

    // let notification_obj = {
    //   current_user_id: user._id,
    //   // eventId: roomie_request.event,
    //   accepted_user_id: roomie_request.requestor,
    //   //will also need accepting users name
    // };

    connectToUser(obj);
    // sendSMSAlertForAcceptedRoommate(notification_obj);
    console.log('success????');
  };

  // this button will be shareable in v1.01 (this btn is for current user who already submitted a RR)
  const currentUsersButtonHid = <Fragment />;
  const voteButton = (
    <Fragment>
      <button
        //   onClick={handleLike}
        onClick={() => {
          handleLike();
          setLikeCount(target_user?.likes?.length + 1);
        }}
        disabled={disabled}
        class="btn btn-primary my-2 my-sm-0"
        type="submit"
      >
        {target_user?.likes?.length > 0 ? `${likeCount} likes` : `like user`}
      </button>
    </Fragment>
  );

  const nouser = <Fragment />;

  const renderOptions = () => {
    console.log('trying to render');
    if (!user) {
      console.log('no user found');
      return (
        <Link to="/login">
          <Button>sign in to vote</Button>;
        </Link>
      );
    }
    return voteButton;
  };
  let image;
  if (target_user?.image) {
    image = target_user?.image;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }

  const handleLike = async (e) => {
    //like user & disable like button
    let obj = {
      likedUserId: target_user?._id,
      likingUserId: user?._id,
      // eventId: roomie_request.event,
      // accepted_user_id: roomie_request.requestor,
    };
    likeCompany(obj);
    setDisabled(true);
  };

  const addMoreBusinesses = () => {
    props.sharktank_partipants = [
      ...props?.sharktank_partipants,
      props?.sharktank_partipants,
    ];
  };

  return (
    <div>
      {/* <Fade in={open}> */}
      {/* <h1>hello</h1> */}
      {/* <h1>{user.name}</h1> */}

      <div className="card card-body bg-light mb-3 ">
        <div className="">
          <img
            // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
            src={image}
            alt=""
            className="rounded-circle all-center-img"
            height="250"
            width="250"
          />
        </div>
        <div className="row all-center">
          <div className="col-lg-6 col-md-4">
            <h5>{target_user?.name}</h5>

            {/* <p>
              {isEmpty(target_user.location) ? null : <span>{target_user.location}</span>}
            </p> */}
            <p>
              <span style={{ fontSize: '14px' }}>{target_user?.location}</span>
            </p>

            <div className="row">
              <div className="row">
                <div className="col">
                  <h5>{target_user?.business_name}</h5>
                </div>
                <div className="col">
                  <h5
                    style={{
                      // width: '100px',
                      background: 'white',

                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',
                      borderWidth: '2px',
                      borderRadius: '4px',

                      fontSize: '20px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      textAlign: 'center',
                    }}
                  >
                    {target_user?.business_type}
                  </h5>
                </div>
              </div>

              <div>
                <h5 style={{ fontSize: '18px', alignItems: 'left' }}>
                  {target_user?.business_description}
                </h5>
              </div>
            </div>
            <div>
              <div
                className="row"
                style={{ paddingBottom: '20px', paddingTop: '20px' }}
              >
                {user?.instagram_name ? (
                  <div className="row">
                    <div className="col">Instagram: </div>
                    <div className="col">
                      <Button
                        onClick={() =>
                          window.open(
                            `https://www.instagram.com/${user?.instagram_name}`,
                            '_blank'
                          )
                        }
                      >
                        {user?.instagram_name}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                {user?.website ? (
                  <div>
                    Instagram:{' '}
                    <Button
                      onClick={() =>
                        window.open(`https://${user?.website}`, '_blank')
                      }
                    >
                      {user?.website}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col">
                <Button
                  onClick={() => {
                    getNextBusinessHardcoded(target_user._id);
                    setOpen(true);
                  }}
                  style={{
                    // width: '100px',
                    background: 'white',
                    // fontSize:
                    borderStyle: 'solid',
                    borderColor: 'red',
                    borderWidth: '2px',
                    borderRadius: '6px',

                    fontSize: '32px',
                    backgroundColor: 'white',
                    color: 'rgb(1, 126, 252)',
                  }}
                >
                  ❌
                </Button>
              </div>
              <div className="col">
                <Button
                  onClick={() => getNextBusinessHardcoded(target_user._id)}
                  style={{
                    // width: '100px',
                    background: 'white',
                    // fontSize:
                    borderStyle: 'solid',
                    borderColor: 'rgb(1, 126, 252)',
                    borderWidth: '2px',
                    borderRadius: '6px',

                    fontSize: '32px',
                    backgroundColor: 'white',
                    color: 'rgb(1, 126, 252)',
                  }}
                >
                  💙
                </Button>
              </div>
            </div>
            {/* <div className="row">
              <Button
                onClick={() => {
                  // addMoreBusinesses();
                  //we can store a list of users businesses we've already seen
                  //and run getOneBusiness() omitting those already listed..

                  // getOneSharkTankBusiness();
                  getNextBusinessHardcoded(target_user._id);
                  setOpen(!open);
                }}
              >
                Next
              </Button>
            </div> */}

            {/* 8.10.24 */}
            {/* <Link
              to={`/user/profile/${target_user?._id}`}
              className="btn btn-info"
            >
              View Profile
            </Link> */}

            {/* {target_user._id === user._id
              ? currentUsersButtonHid
              : connectButton} */}

            {/* {renderOptions()} */}
            {/* <button
              //   onClick={handleLike}
              onClick={() => {
                handleLike();
                setLikeCount(target_user?.likes.length + 1);
              }}
              disabled={disabled}
              class="btn btn-primary my-2 my-sm-0"
              type="submit"
            >
              {target_user?.likes.length > 0
                ? `${likeCount} likes`
                : `like user`}
            </button> */}

            {/* commented out on 8.10.24 */}
            {/* {renderOptions()} */}
          </div>
        </div>
      </div>
      {/* </Fade> */}
    </div>
  );
};

export default Company3Hardcoded;
