import {
  STUDIO_ERROR,
  GET_ALL_STUDIOS,
  GET_STUDIOS_BY_REGION,
  GET_STUDIO_MEMBERS,
  GET_ONE_STUDIO,
  ADD_NEW_MEMBER,
  ADD_NEW_LEVEL,
  GET_STUDIO_LEVELS,
  UPDATE_MEMBER_LEVEL,
  GET_STUDIO_MEMBER,
  UPDATE_STUDENTS_SCORES,
  ADD_NEW_STUDIO,
  EDIT_STUDIO,
  GET_USER_STUDIOS,
  GET_COMPANY_EVENTS,
  CLAIM_STUDIO_PROFILE_REQUEST,
  JOIN_STUDIO,
  ADD_PREMIUM_SUBSCRIPTION,
  GET_TOP_STUDIOS_LIMIT_FIVE,
  GET_STUDIOS_FOR_SEARCH_CRITERIA,
  GET_STUDIOS_FOR_TIMELINE,
  GET_ALL_PERFORMANCES,
  JOIN_STUDIO_FROM_ONBOARD,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_ONE_STUDIO:
      return {
        ...state,
        studio: action.payload,
        loading: false,
      };
    case GET_STUDIOS_FOR_TIMELINE:
      return {
        ...state,
        random_studios_for_timeline: action.payload,
        loading: false,
      };

    case GET_STUDIO_MEMBERS:
      return {
        ...state,
        members: action.payload,
        loading: false,
      };
    case GET_ALL_STUDIOS:
      return {
        ...state,
        allStudios: action.payload,
        loading: false,
      };
    case GET_TOP_STUDIOS_LIMIT_FIVE:
      return {
        ...state,
        topStudiosLimitFive: action.payload,
        loading: false,
      };
    case GET_STUDIOS_FOR_SEARCH_CRITERIA:
      return {
        ...state,
        studioSearchResults: action.payload,
        loading: false,
      };

    case ADD_NEW_MEMBER:
      return {
        ...state,
        newMember: action.payload,
        loading: false,
      };
    case ADD_NEW_LEVEL:
      return {
        ...state,
        newLevel: action.payload,
        loading: false,
      };
    case GET_STUDIO_LEVELS:
      return {
        ...state,
        studio_levels: action.payload,
        loading: false,
      };
    case UPDATE_MEMBER_LEVEL:
      return {
        ...state,
        // studio_levels: action.payload,
        loading: false,
      };
    case GET_STUDIO_MEMBER:
      return {
        ...state,
        studio_member: action.payload,
        loading: false,
      };
    case UPDATE_STUDENTS_SCORES:
      return {
        ...state,
        updatedStudent: action.payload,
        loading: false,
      };
    case ADD_NEW_STUDIO:
      return {
        ...state,
        newStudio: action.payload,
        loading: false,
      };
    case EDIT_STUDIO:
      return {
        ...state,
        updatedStudio: action.payload,
        loading: false,
      };
    case GET_USER_STUDIOS:
      return {
        ...state,
        userStudios: action.payload,
        loading: false,
      };
    case GET_COMPANY_EVENTS:
      return {
        ...state,
        companyEvents: action.payload,
        loading: false,
      };
    case CLAIM_STUDIO_PROFILE_REQUEST:
      return {
        ...state,
        studioProfileRequest: action.payload,
        loading: false,
      };
    case JOIN_STUDIO:
      return {
        ...state,
        members: action.payload,
        loading: false,
      };
    case JOIN_STUDIO_FROM_ONBOARD:
      return {
        ...state,
        // members: action.payload,
        loading: false,
      };
    case ADD_PREMIUM_SUBSCRIPTION:
      return {
        ...state,
        premium_subscription: action.payload,
        loading: false,
      };
    case GET_ALL_PERFORMANCES:
      return {
        ...state,
        all_performances: action.payload,
        loading: false,
      };
    case GET_STUDIOS_BY_REGION:
      return {
        ...state,
        studiosByRegion: action.payload,
        loading: false,
      };

    case STUDIO_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
