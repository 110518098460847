import React, { useContext } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize

import { Button } from 'react-bootstrap';

// import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';

const OnboardDynamicStudioSearch = (props) => {
  const {
    userSearchText,
    dynamicStudioSearch,
    setStudioWasSelected,
    setSelectedStudio,
    setDisplay,
  } = props;
  const userContext = useContext(UserContext);
  const {} = userContext;

  const search = useLocation().search;
  const searchText = new URLSearchParams(search).get('searchText');

  const [, updateState] = React.useState();

  return (
    <div className="autoContainer2">
      <h5 style={{ fontSize: '14px' }}>Try searching for 'Que Candela'</h5>
      {dynamicStudioSearch.map((studio) => (
        <div className="option search-card">
          <h3>{studio.name}</h3>

          <Button
            onClick={() => {
              setStudioWasSelected(true);
              setSelectedStudio(studio);
              setDisplay(false);
              console.log('selected studio' + studio);
            }}
          >
            Select
          </Button>
        </div>
      ))}
    </div>
  );
};

export default OnboardDynamicStudioSearch;
