/* eslint-disable import/no-anonymous-default-export */
// import React from 'react';
// import PropTypes from 'prop-types';

import {
  GET_ALL_REACTIONS,
  REACTION_ERROR,
  GET_REACTIONS_FOR_DANCER,
  POST_REACTION_FOR_DANCER,
  ADD_DANCER,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_ALL_REACTIONS:
      return {
        ...state,
        all_reactions: action.payload,

        // loading: false,
      };
    case GET_REACTIONS_FOR_DANCER:
      return {
        ...state,
        reactionsForDancer: action.payload,

        // loading: false,
      };
    case POST_REACTION_FOR_DANCER:
      return {
        ...state,
        reactionFromUser: action.payload,

        // loading: false,
      };
    case ADD_DANCER:
      return {
        ...state,
        new_dancer: action.payload,

        // loading: false,
      };

    case REACTION_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
