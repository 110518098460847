import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import SearchEventsPopup from '../safety-tools/SearchEventsPopup';

const SafeEventsScrollMenu = () => {
  const [searchEventsClicked, setSearchEventsClicked] = useState(false);
  return (
    <div
      class="container-fluid  overflow-auto example "
      style={{ padding: '0px' }}
    >
      <h5>
        We've identified some key items that make events safe. This list is by
        no means exhaustive, but rather an indication.
      </h5>
      {/* <h5>horizontal menu of offense levels</h5> */}

      <div className="d-flex flex-row flex-nowrap center-content">
        <div
          className="card-horizontal-menu card-block mx-2"
          style={{
            width: '300px',
            background: 'yellow',

            borderStyle: 'solid',
            borderColor: 'black',
            borderWidth: '1px',
            borderRadius: '2px',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'black',
            padding: '10px',
            // justifyContent: 'center',
            // textAlign: 'center',
          }}
        >
          <h3
            style={{
              width: 'max-content',
              background: 'yellow',

              borderStyle: 'solid',
              borderColor: 'black',
              borderWidth: '1px',
              borderRadius: '10px',

              fontSize: '14px',
              backgroundColor: 'rgb(1, 126, 252)',
              color: 'black',
              padding: '10px',
              // textAlign: 'center',
            }}
          >
            The event has a Safety Officer
          </h3>
          <h5
            style={{
              width: '275px',
              //   background: 'yellow',
              //   fontSize: '14px',
              backgroundColor: 'white',
              //   color: 'black',
              padding: '10px',
            }}
            className="offense-level-content"
          >
            Safety Officers are important because they are an on the ground
            resource that can respond to incident reports in real time.
          </h5>
          {/* <ul className="dashed offense-level-content">
            <li>Excessive drinking - where one is unaware of their actions</li>
            <li>
              Refusal to end a dance after being asked to release following.
              disrespect, cursing, yelling.
            </li>
            <li>
              Inappropriate comments, Verbal assault, trying to get someone
              alone.
            </li>
          </ul> */}

          <div>
            <Button
              onClick={() => setSearchEventsClicked(!searchEventsClicked)}
            >
              {' '}
              Search Events
            </Button>
            {searchEventsClicked ? (
              <SearchEventsPopup
                searchEventsClicked={searchEventsClicked}
                setSearchEventsClicked={setSearchEventsClicked}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          className="card-horizontal-menu card-block mx-2"
          style={{
            width: '300px',
            background: 'yellow',

            borderStyle: 'solid',
            borderColor: 'black',
            borderWidth: '1px',
            borderRadius: '2px',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'black',
            padding: '10px',
            // textAlign: 'center',
          }}
        >
          <h3
            style={{
              width: 'max-content',
              background: 'rgb(1, 126, 252)',

              borderStyle: 'solid',
              borderColor: 'black',
              borderWidth: '1px',
              borderRadius: '10px',

              fontSize: '14px',

              color: 'black',
              padding: '10px',
            }}
          >
            Level 2
          </h3>
          <h5
            style={{
              width: '275px',
              //   background: 'yellow',
              //   fontSize: '14px',
              backgroundColor: 'white',
              //   color: 'black',
              padding: '10px',
            }}
            className="offense-level-content"
          >
            level 2 would be an unlawful act punishable by a 3 to 12 month
            suspension
          </h5>

          <ul className="dashed offense-level-content">
            <li>Fights (domestic or physical)</li>
            <li>Physically aggressive behavior (intentional)</li>
            <li>
              Non consensual touching or especially during dancing and trying to
              play it off as an accident.
            </li>
          </ul>
        </div>

        <div
          className="card-horizontal-menu card-block mx-2"
          style={{
            width: '300px',
            background: 'yellow',

            borderStyle: 'solid',
            borderColor: 'black',
            borderWidth: '1px',
            borderRadius: '2px',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'black',
            padding: '10px',
            // textAlign: 'center',
          }}
        >
          <h3
            style={{
              width: 'max-content',
              background: 'rgb(1, 126, 252)',

              borderStyle: 'solid',
              borderColor: 'black',
              borderWidth: '1px',
              borderRadius: '10px',

              fontSize: '14px',
              //   backgroundColor: 'yellow',
              color: 'black',
              padding: '10px',
              // textAlign: 'center',
            }}
          >
            Level 3
          </h3>
          <h5
            style={{
              width: '275px',
              //   background: 'red',
              //   fontSize: '14px',
              backgroundColor: 'white',
              //   color: 'black',
              padding: '10px',
            }}
            className="offense-level-content"
          >
            level 3 would be an unlawful act punishable by 12 months to
            indefinite suspension
          </h5>

          <ul className="dashed offense-level-content">
            <li>
              Touching, kissing, groping body parts of any sort without consent.
            </li>
            <li>Sexual assault in any form.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SafeEventsScrollMenu;
