import React, { Component, useContext, useEffect, useState } from 'react';
import LessonPlanMoves from './LessonPlanMoves';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import CurriculumContext from '../../../context/curriculum/curriculumContext';

const LessonPlan = (props) => {
  let curriculumContext = useContext(CurriculumContext);
  let { updateLessonPlan } = curriculumContext;
  //

  // props.getLessonPlanById();
  let { lesson_plan, lessonPlanObj } = props;

  console.log('lessonPlanObj');
  console.log(lessonPlanObj);
  console.log('props.lesson_plan');
  console.log(props.lesson_plan);
  useEffect(() => {
    console.log('yooo ---');
    // console.log(props.lesson_plan_id);

    // setLoading(true);

    // setLoading(false);

    // eslint-disable-next-line
  }, []);

  let {
    class_time,
    moves_to_learn,
    name,
    style,
    // _id
    // week_to_teach,
  } = lessonPlanObj;
  // const [lessonDetails, setLessonDetails] = useState({
  //   week_to_teach: lesson_plan.week_to_teach ? lesson_plan.week_to_teach : 1,
  // });
  const [lessonDetails, setLessonDetails] = useState({
    week_to_teach: lessonPlanObj?.week_to_teach
      ? lessonPlanObj.week_to_teach
      : 'set week to teach',

    // _id: lessonPlanObj?._id,
    // curriculum_id: props.curriculum?._id,

    // week_to_teach,
  });
  // const [lessonDetails, setLessonDetails] = useState(lessonPlanObj);
  console.log('lessonDetails ?= lessonPlanObj');
  console.log(lessonDetails);
  const handleWeekChange = (week) => {
    console.log('week');
    console.log(week);
    // setWorkshopTime(time);
    setLessonDetails({
      ...lessonDetails,
      week_to_teach: week,
      // _id: lessonPlanObj?._id,
    });
    if (!showUpdateLessonButton) {
      setShowUpdateLessonButton(!showUpdateLessonButton);
    }
  };
  // test
  let { week_to_teach } = lessonDetails;
  // let { week_to_teach } = lessonDetails;
  let week_to_teach_dropdown = [];
  const [showUpdateLessonButton, setShowUpdateLessonButton] = useState(false);
  const updateThisLessonPlan = () => {
    // setLessonDetails({
    //   ...lessonDetails,

    //   class_time: lessonPlanObj?.class_time,
    //   moves_to_learn: lessonPlanObj?.moves_to_learn,
    //   name: lessonPlanObj?.name,
    //   style: lessonPlanObj?.style,
    // });
    console.log('the updates');

    let updates = {
      // ...lessonDetails,

      // class_time: lessonPlanObj?.class_time,
      // moves_to_learn: lessonPlanObj?.moves_to_learn,
      // name: lessonPlanObj?.name,
      // style: lessonPlanObj?.style,

      newLessonPlan: {
        ...lessonDetails,
        lessonPlan: lessonPlanObj?._id,
        moves_to_learn: lessonPlanObj?.moves_to_learn,
        name: lessonPlanObj?.name,
        style: lessonPlanObj?.style,
      },
      curriculum_id: props?.curriculum?._id,
    };
    updateLessonPlan(updates);
    console.log({
      // ...lessonDetails,

      // class_time: lessonPlanObj?.class_time,
      // moves_to_learn: lessonPlanObj?.moves_to_learn,
      // name: lessonPlanObj?.name,
      // style: lessonPlanObj?.style,

      newLessonPlan: {
        ...lessonDetails,
        lessonPlan: lessonPlanObj?._id,
        moves_to_learn: lessonPlanObj?.moves_to_learn,
        name: lessonPlanObj?.name,
        style: lessonPlanObj?.style,
      },
      curriculum_id: props?.curriculum?._id,
    });

    console.log('lesson plannnn');
    // console.log({
    //   curriculum_index: props?.curriculum?.lesson_plans_by_week[selectedIndex],
    //   week_to_teach: week_to_teach,
    // });
    console.log('lesson_plan to update');
    console.log(lessonDetails);
    console.log('update lesson');
  };
  const [editingLessonPlan, setEditingLessonPlan] = useState(false);
  return (
    <div>
      {/* <h5>{props.lesson_plan_id}</h5> */}
      <h5>{lesson_plan.name}</h5>
      {/* <h5>{lesson_plan.name}</h5> */}

      {/* update lesson plan week */}

      {/* <div className="row">
        <div className="col">
          <label htmlFor="">Lesson Week</label>
        </div>
        <div className="col">
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="sm"
              variant="secondary"
              title={week_to_teach}
              onSelect={handleWeekChange}
            >
              {Array.from({ length: 10 }, (_, i) => i + 1).map((type) => (
                <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>
      </div> */}

      {/* changed on july 29 */}
      {/* <LessonPlanMoves moves_to_learn={lesson_plan?.moves_to_learn} /> */}
      <LessonPlanMoves moves_to_learn={lessonPlanObj?.moves_to_learn} />

      <div className="row">
        <div className="col">
          <label htmlFor="">Lesson Week yoooo</label>
        </div>
        {editingLessonPlan ? (
          <div className="col">
            {[DropdownButton].map((DropdownType, idx) => (
              <DropdownType
                as={ButtonGroup}
                key={idx}
                id={`dropdown-button-drop-${idx}`}
                size="sm"
                variant="secondary"
                title={week_to_teach}
                onSelect={handleWeekChange}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((type) => (
                  <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
                ))}
              </DropdownType>
            ))}
          </div>
        ) : (
          <div>
            <h5> week to teach {lessonPlanObj?.week_to_teach}</h5>
          </div>
        )}
      </div>

      <Button
        onClick={() => {
          setEditingLessonPlan(!editingLessonPlan);
          setShowUpdateLessonButton(true);
        }}
      >
        Edit Lesson
      </Button>

      {showUpdateLessonButton ? (
        <div>
          <div>
            <Button onClick={() => setShowUpdateLessonButton(false)}>
              Cancel
            </Button>
          </div>
          <div>
            <Button onClick={() => updateThisLessonPlan()}>
              Update Lesson
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* <h5>{props.lessonPlanFromBackend}</h5> */}
    </div>
  );
};

export default LessonPlan;
