import React, { Component, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import CurriculumContext from '../../../context/curriculum/curriculumContext';
import { useAuth } from '../../../context/auth/AuthState';

const EnrolledStudents = (props) => {
  const curriculumContext = useContext(CurriculumContext);
  const {
    enrollStudentInCurriculum,
    getEnrolledStudents,
    enrolledStudents,
  } = curriculumContext;
  const { curriculum_id, school_id } = props;

  useEffect(() => {
    console.log('running getenrolledstudents');
    getEnrolledStudents(curriculum_id);
    // eslint-disable-next-line
  }, []);

  const [authState] = useAuth();
  const { user, loading } = authState;

  const tryEnrollingStudent = () => {
    if (user) {
      enrollStudentInCurriculum({
        user_id: user._id,
        curriculum_id,
        school_id,
      });
    }
  };

  return (
    <div className="card">
      <div className="row">
        <div className="col">
          <h1>Students</h1>
        </div>
        <div className="col">
          <Button
            onClick={() => {
              tryEnrollingStudent();
            }}
          >
            Enroll
          </Button>
        </div>
      </div>
      {enrolledStudents.map((student) => (
        <div style={{ paddingBottom: '12px' }}>
          <div className="row">
            <div className="col-2">
              <img
                height="40"
                width="40"
                src={student.image.url}
                alt="student"
                className="rounded-circle"
              />
            </div>

            <div className="col">
              <h3>{student.name}</h3>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EnrolledStudents;
