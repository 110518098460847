import {
  GET_RANKINGS,
  GET_SKILLS_AND_ENDORSEMENTS,
  SUBMIT_ENDORSEMENT,
  GET_FILTERED_RANKINGS,
  GET_RANDOM_RANKINGS,
  RANKINGS_ERROR,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_RANKINGS:
      return {
        ...state,
        rankings: action.payload,
        loading: false,
      };
    case GET_SKILLS_AND_ENDORSEMENTS:
      return {
        ...state,
        skills_and_endorsements: action.payload,
        loading: false,
      };
    case SUBMIT_ENDORSEMENT:
      return {
        ...state,
        // skills_and_endorsements: action.payload,
        loading: false,
      };
    case GET_FILTERED_RANKINGS:
      return {
        ...state,
        // skills_and_endorsements: action.payload,
        rankings: action.payload,
        loading: false,
      };
    case GET_RANDOM_RANKINGS:
      return {
        ...state,
        // skills_and_endorsements: action.payload,
        randomRankingsForTimeline: action.payload.rankedUsers,
        randomStyle: action.payload.randomStyle,
        loading: false,
      };

    case RANKINGS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
