import React, { Component } from 'react';

const InvestigationSteps = () => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <h5
            style={{
              //   width: 'max-content',
              width: '180px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '22px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              borderRadius: '10px',
            }}
          >
            Determine Level of Offense
          </h5>
        </div>
        <div className="col">
          <h5
            style={{
              //   width: 'max-content',
              width: '180px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'gray',

              fontSize: '22px',
              backgroundColor: 'white',
              color: 'gray',
              textAlign: 'center',
              borderRadius: '10px',
            }}
          >
            Conduct Investigation
          </h5>
        </div>
        <div className="col">
          <h5
            style={{
              //   width: 'max-content',
              width: '180px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'gray',

              fontSize: '22px',
              backgroundColor: 'white',
              color: 'gray',
              textAlign: 'center',
              borderRadius: '10px',
            }}
          >
            Summary of Investigation
          </h5>
        </div>
        <div className="col">
          <h5
            style={{
              //   width: 'max-content',
              width: '180px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'gray',

              fontSize: '22px',
              backgroundColor: 'white',
              color: 'gray',
              textAlign: 'center',
              borderRadius: '10px',
            }}
          >
            Vote on Resolution
          </h5>
        </div>
      </div>
    </div>
  );
};

export default InvestigationSteps;
