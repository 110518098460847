/* eslint-disable import/no-anonymous-default-export */
// import React from 'react';
// import PropTypes from 'prop-types';

import {
  GET_ALL_EVENTS,
  GET_ONE_EVENT,
  DELETE_EVENT,
  EDIT_EVENT,
  CREATE_EVENT,
  JOIN_EVENT,
  REQUEST_ROOMMATE,
  EVENT_ERROR,
  GET_ATTENDEES_FOR_EVENT,
  GET_ROOMMATE_REQUESTS_FOR_EVENT,
  JOIN_EVENT_AS_VENDOR,
  GET_VENDORS_FOR_EVENT,
  UNJOIN_EVENT,
  GET_LINEUP_FOR_EVENT,
  ADD_TO_LINEUP,
  GET_DICT_LINEUP_FOR_EVENT,
  GET_ALL_ARTISTS,
  GET_ALL_ARTISTS_DICTIONARY,
  GET_WORKSHOP_SCHEDULE,
  UPDATE_WORKSHOP,
  GET_TAKEN_WORKSHOP_ROOMS,
  GET_WORKSHOP_ATTENDEES,
  GET_JOINED_WORKSHOP,
  CREATE_NEW_ARTIST,
  GET_USER_ITINERARY,
  ADD_NEW_DJ,
  GET_DJS_AND_REQUESTS,
  ADD_SONG_REQUEST,
  GET_EVENTS_BY_LOCATION,
  GET_WORKSHOP_SCHEDULE_NEW_STRUCTURE,
  ADD_EVENT_FEEDBACK,
  GET_ATTENDEE_FEEDBACK,
  GET_HIGHLIGHTED_EVENTS,
  GET_EVENTS_OF_TYPE,
  JOIN_LINEUP,
  ADD_PREMIUM_EVENT_SUBSCRIPTION,
  ADD_SAFETY_OFFICERS,
  GET_EVENTS_FOR_SEARCH_CRITERIA,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_ONE_EVENT:
      return {
        ...state,
        event: action.payload,

        loading: false,
      };
    case GET_HIGHLIGHTED_EVENTS:
      return {
        ...state,
        highlighted_events: action.payload,

        // loading: false,
      };
    case GET_ALL_EVENTS:
      return {
        ...state,
        events: action.payload,

        // loading: false,
      };
    case GET_EVENTS_OF_TYPE:
      return {
        ...state,
        allEventsWithEventType: action.payload,
        highlighted_events: [],
        // loading: false,
      };
    case GET_EVENTS_BY_LOCATION:
      return {
        ...state,
        eventsInLocation: action.payload,

        // loading: false,
      };
    case GET_ATTENDEES_FOR_EVENT:
      return {
        ...state,
        attendees: action.payload,
        loading: false,
      };
    case GET_ROOMMATE_REQUESTS_FOR_EVENT:
      return {
        ...state,
        roommate_requests: action.payload,
        // loading: false,
      };
    case JOIN_EVENT:
      return {
        ...state,
        joined_event: action.payload,
      };
    case UNJOIN_EVENT:
      return {
        ...state,

        joined_events: state.joined_events.filter(
          (event) => event._id !== action.payload
        ),
      };
    case REQUEST_ROOMMATE:
      return {
        ...state,
        roommate_request: action.payload,
      };
    case CREATE_EVENT:
      return {
        ...state,
        new_event: action.payload,
      };
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => event._id !== action.payload),
        loading: false,
      };
    case EDIT_EVENT:
      return {
        ...state,
        updatedEvent: action.payload,
        loading: false,
      };
    case EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case JOIN_EVENT_AS_VENDOR:
      return {
        ...state,
        vendor: action.payload,
        // error: action.payload,
      };

    case GET_VENDORS_FOR_EVENT:
      return {
        ...state,
        vendors: action.payload,
        // error: action.payload,
      };

    case GET_LINEUP_FOR_EVENT:
      return {
        ...state,
        lineup: action.payload,
        // error: action.payload,
      };
    case ADD_TO_LINEUP:
      return {
        ...state,
        lineup: action.payload,
        // error: action.payload,
      };
    case JOIN_LINEUP:
      return {
        ...state,
        lineup: action.payload,
        // error: action.payload,
      };
    case GET_DICT_LINEUP_FOR_EVENT:
      return {
        ...state,
        lineupObject: action.payload,
        // error: action.payload,
      };

    case GET_ALL_ARTISTS:
      return {
        ...state,
        all_artists: action.payload,
        // error: action.payload,
      };
    case GET_ALL_ARTISTS_DICTIONARY:
      return {
        ...state,
        all_artists_dict: action.payload,
        // error: action.payload,
      };
    case GET_WORKSHOP_SCHEDULE:
      return {
        ...state,
        workshop_schedule: action.payload,
        loading: false,
        // error: action.payload,
      };
    case GET_WORKSHOP_SCHEDULE_NEW_STRUCTURE:
      return {
        ...state,
        workshop_schedule_new_structure: action.payload,
        loading: false,
        // error: action.payload,
      };
    case UPDATE_WORKSHOP:
      return {
        ...state,
        updated_workshop: action.payload,
        // error: action.payload,
      };
    case GET_TAKEN_WORKSHOP_ROOMS:
      return {
        ...state,
        takenWorkshopRoomsMap: action.payload,
        // error: action.payload,
      };
    case GET_WORKSHOP_ATTENDEES:
      return {
        ...state,
        workshop_attendees: action.payload,
        // error: action.payload,
      };

    case GET_JOINED_WORKSHOP:
      return {
        ...state,
        joined_workshop: action.payload,
        // error: action.payload,
      };

    case CREATE_NEW_ARTIST:
      return {
        ...state,
        created_artist: action.payload,
      };

    case GET_USER_ITINERARY:
      return {
        ...state,
        myItinerary: action.payload,
        loading: false,
      };
    case ADD_NEW_DJ:
      return {
        ...state,
        newDJ: action.payload,
      };
    case GET_DJS_AND_REQUESTS:
      return {
        ...state,
        djs_and_requests: action.payload,
      };
    case ADD_SONG_REQUEST:
      return {
        ...state,
        djs_and_requests: action.payload,
        // newSongRequest: action.payload,
      };
    case ADD_EVENT_FEEDBACK:
      return {
        ...state,
        submitted_event_feedback: action.payload,
        // newSongRequest: action.payload,
      };
    case GET_ATTENDEE_FEEDBACK:
      return {
        ...state,
        attendee_feedback: action.payload,
        // newSongRequest: action.payload,
      };
    case ADD_PREMIUM_EVENT_SUBSCRIPTION:
      return {
        ...state,
        event: action.payload,
        // newSongRequest: action.payload,
      };
    case ADD_SAFETY_OFFICERS:
      return {
        ...state,
        safetyOfficers: action.payload,
        // newSongRequest: action.payload,
      };
    case GET_EVENTS_FOR_SEARCH_CRITERIA:
      return {
        ...state,
        eventsFromSearch: action.payload,
        // newSongRequest: action.payload,
      };

    default:
      return state;
  }
};
