import {
  GET_CURRICULUM,
  ADOPT_CURRICULUM,
  GET_DANCE_MOVES_BY_STYLE,
  GET_CURRICULA_FOR_SCHOOL,
  GET_LESSON_PLAN_BY_ID,
  GET_DANCE_MOVES_BY_STYLE2,
  ADD_LESSON_PLAN_FOR_CURRICULUM,
  ADD_CURRICULUM_AND_LESSON_PLAN,
  REMOVE_CURRICULUM,
  GET_CURRICULUMS_WITH_STYLE_AND_LEVEL,
  GET_LESSON_PLANS_FOR_CURRICULUM,
  ENROLL_STUDENT_IN_CURRICULUM,
  UPDATE_LESSON_PLAN_FOR_CURRICULUM,
  GET_ENROLLED_STUDENTS_FOR_CURRICULUM,
  CURRICULUM_ERROR,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_CURRICULUM:
      return {
        ...state,
        curriculum: action.payload,
        loading: false,
      };
    case GET_CURRICULUMS_WITH_STYLE_AND_LEVEL:
      return {
        ...state,
        curriculumsWithStyleAndLevel: action.payload,
        loading: false,
      };
    case GET_DANCE_MOVES_BY_STYLE:
      return {
        ...state,
        danceMovesByStyle: action.payload,
        loading: false,
      };
    case GET_DANCE_MOVES_BY_STYLE2:
      return {
        ...state,
        danceMovesByStyle2: action.payload,
        loading: false,
      };
    case GET_CURRICULA_FOR_SCHOOL:
      return {
        ...state,
        curriculaForSchool: action.payload,
        loading: false,
      };
    case GET_LESSON_PLANS_FOR_CURRICULUM:
      return {
        ...state,
        lessonPlansForCurriculum: action.payload,
        loading: false,
      };
    case ADOPT_CURRICULUM:
      return {
        ...state,
        // updated_studio_curriculum: action.payload,
        loading: false,
      };
    case GET_LESSON_PLAN_BY_ID:
      return {
        ...state,
        lessonPlanObj: action.payload,
        loading: false,
      };
    case ADD_LESSON_PLAN_FOR_CURRICULUM:
      return {
        ...state,
        newLessonPlan: action.payload,
        loading: false,
      };
    case UPDATE_LESSON_PLAN_FOR_CURRICULUM:
      return {
        ...state,
        // newLessonPlan: action.payload,
        loading: false,
      };
    case ADD_CURRICULUM_AND_LESSON_PLAN:
      return {
        ...state,
        newLessonPlanAndCurriculum: action.payload,
        loading: false,
      };
    case ENROLL_STUDENT_IN_CURRICULUM:
      return {
        ...state,
        // newLessonPlanAndCurriculum: action.payload,
        loading: false,
      };

    case REMOVE_CURRICULUM:
      return {
        ...state,
        // error: action.payload,
        loading: false,
      };
    case GET_ENROLLED_STUDENTS_FOR_CURRICULUM:
      return {
        ...state,
        enrolledStudents: action.payload,
        loading: false,
      };
    case CURRICULUM_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
