//consider renaming to potential_mate to differentiate btw user
import React, { useContext, useEffect, Fragment, useState } from 'react';
import EventContext from '../../../context/event/eventContext'; // might have to capitalize
import AdvertismentContext from '../../../context/advertisement/advertismentContext';
// import AdvertisementContext from '../../context/advertisement/advertisementContext'; // might have to capitalize

import { Button } from 'react-bootstrap';
import EventItem from '../EventItem';
import PropTypes from 'prop-types';

import AlertContext from '../../../context/alert/alertContext';
import { useAuth } from '../../../context/auth/AuthState';
import { Navigate } from 'react-router-dom';
import Pagination from '../Pagination';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import ListOfStates from '../ListOfStates.json';
import ListOfStatesAndCities from '../ListOfStatesAndCities.json';
import TopDancerOnMainTimeline from '../../topLists/TopDancerOnMainTimeline';
import ActivitiesWithPagination from '../../activities/ActivitiesWithPagination';
import AddEventsThisWeekFields from './AddEventsThisWeekFields';
import EventsThisWeekContext from '../../../context/eventsThisWeek/eventsThisWeekContext';
import BoostEvent from './BoostEvent';
const moment = require('moment');

const EventThisWeek = (props) => {
  const { index, event, promotion } = props;
  const [loading, setLoading] = useState(false);
  const eventsThisWeekContext = useContext(EventsThisWeekContext);
  const {
    getAllEventsThisWeek,
    eventsThisWeek,
    eventsThisWeekByLocation,
    getEventsThisWeekByLocation,
  } = eventsThisWeekContext;
  useEffect(() => {
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    setLoading(true);
    getAllEventsThisWeek();
    // getEventsThisWeekByLocation();

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const [showFields, setShowFields] = React.useState(false);
  const [boostClicked, setBoostClicked] = React.useState(false);
  const [detailsClicked, setDetailsClicked] = React.useState(false);
  console.log('event from EventThisWeek');
  console.log(event);
  // console.log('eventsThisWeekByLocation ---');
  // console.log(eventsThisWeekByLocation);
  return (
    <div>
      <div className="timelineCard bg-light smallScreenEvents bigScreenEvents">
        <br />
        <div>
          <div>
            <div className="row">
              <div className="col-1" />
              <div className="col">
                {promotion ? (
                  <div>
                    <div className="row">
                      <h5>{event.promotionText}</h5>
                      {/* <h5> */}
                      <label style={{ fontSize: '14px', color: 'red' }}>
                        {moment(event?.start_date)
                          .zone(0)
                          .format('MM/DD/YYYY')}
                      </label>
                      <label style={{ fontSize: '14px', color: 'red' }}>
                        {event?.city + ',' + event?.state}
                      </label>
                      {/* </h5> */}
                    </div>
                    <div className="row">
                      <div className="col-1">
                        <h5 style={{ fontSize: '12px' }}>{event.group}</h5>
                      </div>
                      <div className="col-2">
                        <h5 style={{ fontSize: '12px' }}> • 14 likes</h5>
                      </div>
                      {/* <div className="col">
                        <Button
                          className="searchBtn"
                          style={{
                            fontSize: '8px',
                            backgroundColor: 'white',
                            color: 'rgb(1, 126, 252)', 
                            border: '1px solid rgb(1, 126, 252)',
                          }}
                          onClick={() => setBoostClicked(!boostClicked)}
                        >
                          Boost
                        </Button>
                        <BoostEvent event={event} boostClicked={boostClicked} />
                      </div> */}
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* <div className="row"> */}
                    <div className="row">
                      <div className="col-1">
                        <h5>{index + 1}</h5>
                      </div>
                      <div className="col">
                        <div className="row">
                          <h5>{event.name}</h5>
                          {/* <h5> */}
                          <div className="row">
                            <label style={{ fontSize: '14px' }}>
                              {event?.venueName +
                                ', ' +
                                event?.city +
                                ', ' +
                                event?.state}
                            </label>
                          </div>

                          <label style={{ fontSize: '14px', color: 'red' }}>
                            {moment(event.start_date)
                              .zone(0)
                              .format('MM/DD/YYYY')}
                          </label>
                          {/* </h5> */}
                        </div>
                        {/* <h5> */}
                        {/* <label style={{ fontSize: '14px', color: 'red' }}>
                          {moment(event.start_date)
                            .zone(0)
                            .format('MM/DD/YYYY')}
                        </label> */}
                        {/* </h5> */}

                        <div className="row">
                          {/* <div className="col-1">
                            <h5 style={{ fontSize: '12px' }}>{event.group}</h5>
                          </div> */}
                          <div className="col-2">
                            <h5 style={{ fontSize: '12px' }}> • 13 likes</h5>
                          </div>
                          <div className="col-2">
                            <Button
                              className="searchBtn"
                              style={{
                                fontSize: '8px',
                                backgroundColor: 'white',
                                color: 'rgb(1, 126, 252)',
                                border: '1px solid rgb(1, 126, 252)',
                              }}
                              onClick={() => setBoostClicked(!boostClicked)}
                            >
                              Boost
                            </Button>

                            {/* <div style={{ width: '100%' }}>
                              <BoostEvent
                                event={event}
                                boostClicked={boostClicked}
                              />
                            </div> */}
                          </div>

                          <div className="col">
                            <Button
                              className="searchBtn"
                              style={{
                                fontSize: '8px',
                                backgroundColor: 'white',
                                // color: 'rgb(1, 126, 252)',
                                // border: '1px solid rgb(1, 126, 252)',
                                border: '1px solid black',
                                color: 'black',
                                // borderColor: 'black',
                              }}
                              onClick={() => setDetailsClicked(!detailsClicked)}
                            >
                              Details
                            </Button>
                          </div>
                        </div>
                        <div style={{ width: '100%' }}>
                          <BoostEvent
                            event={event}
                            boostClicked={boostClicked}
                          />
                        </div>
                        {detailsClicked && (
                          <div>
                            <h5>clicked deets</h5>
                            {event?.descr}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventThisWeek;
