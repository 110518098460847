import React, { Component, useContext, useEffect } from 'react';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import AllFeedback from './AllFeedback';

const AttendeeFeedback = (props) => {
  const eventContext = useContext(EventContext);
  const { getAttendeeFeedback, attendee_feedback } = eventContext;

  useEffect(() => {
    getAttendeeFeedback(props.event._id);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {/* <h1>AttendeeFeedback</h1> */}
      {attendee_feedback.map((feedback, index) => (
        <AllFeedback feedback={feedback} index={index} />
      ))}
    </div>
  );
};
export default AttendeeFeedback;
