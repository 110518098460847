import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ArtistsWeLove = (props) => {
  const { artist } = props;
  return (
    // <div className="col grid-container-small-screen grid-container-big-screen">
    <div className="col grid-container-small-screen grid-container-big-screen-main-timeline">
      <Link to={`/artist-profile/${artist?._id}`}>
        <div className="" style={{ width: '170px' }}>
          {/* <div className="card card-body" style={{ width: '170px' }}> */}
          <img
            src={artist.image ? artist?.image : artist?.image_url?.url}
            height="150"
            width="150"
            alt="user"
            style={{ borderRadius: '0.25rem' }}
          />
          <h5 style={{ fontSize: '18px', color: 'black' }}>{artist.name}</h5>
          <Button
            style={{
              fontSize: '10px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '2px',
              borderRadius: '6px',

              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
            }}
          >
            {artist.artist_type}
          </Button>
        </div>
      </Link>{' '}
    </div>
  );
};

export default ArtistsWeLove;
