import React, { Component, useContext, useEffect } from 'react';
import ReactionContext from '../../context/reaction/reactionContext';

const DancerReactionsObjectsByUsers = (props) => {
  // load the reactions from the db
  let { reactionByUsers } = props;
  return (
    <div>
      <div className="row">
        {/*       
      <h5>{reactionByUsers.reaction_emoji}</h5>
      <h5>{reactionByUsers.reaction_emoji}</h5> */}
      </div>

      {/* {reactionsByUsers.reactions.map((reaction) => (
        <ReactionItem
          reaction={reaction}
        />
        ))} */}
    </div>
  );
};
export default DancerReactionsObjectsByUsers;
