import {
  GET_TOURS_FOR_INSTRUCTOR,
  GET_STUDIOS_IN_STATE,
  POST_NEW_TOUR,
  TOUR_ERROR,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_TOURS_FOR_INSTRUCTOR:
      return {
        ...state,
        tours: action.payload,
        loading: false,
      };
    case GET_STUDIOS_IN_STATE:
      return {
        ...state,
        studiosInState: action.payload,
        loading: false,
      };
    case POST_NEW_TOUR:
      return {
        ...state,
        newTour: action.payload,
        loading: false,
      };

    case TOUR_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
