import { FormControl } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Text } from '@chakra-ui/layout';
// import './styles.css';
import { IconButton, Spinner, useToast } from '@chakra-ui/react';
// import { getSender, getSenderFull } from '../config/ChatLogics';
import { getSender, getSenderFull } from './chatHelperFunctions/chatHelpers';
import { useContext, useEffect, useState } from 'react';
import ChatContext from '../../../context/chat/chatContext';
import ScrollableChat from './ScrollableChat';
import ProfileModal from './ProfileModal';
import { useAuth } from '../../../context/auth/AuthState';
import { Button } from 'react-bootstrap';

// import axios from 'axios';
// import { ArrowBackIcon } from '@chakra-ui/icons';
// import ProfileModal from './miscellaneous/ProfileModal';
// import ScrollableChat from './ScrollableChat';
// import Lottie from 'react-lottie';
// import animationData from '../animations/typing.json';

// import io from 'socket.io-client';
// import UpdateGroupChatModal from './miscellaneous/UpdateGroupChatModal';
// import { ChatState } from '../Context/ChatProvider';
// const ENDPOINT = 'http://localhost:5000'; // "https://talk-a-tive.herokuapp.com"; -> After deployment
var socket, selectedChatCompare;

// const SingleChat = ({ fetchAgain, setFetchAgain }) => {
const SingleChat = (props) => {
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;
  const chatContext = useContext(ChatContext);
  const {
    getSingleChatMessages,
    singleChatMessages,
    sendMessage,
  } = chatContext;

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const toast = useToast();

  //   const defaultOptions = {
  //     loop: true,
  //     autoplay: true,
  //     animationData: animationData,
  //     rendererSettings: {
  //       preserveAspectRatio: 'xMidYMid slice',
  //     },
  //   };
  //   const {
  //     selectedChat,
  //     setSelectedChat,
  //     user,
  //     notification,
  //     setNotification,
  //   } = ChatState();

  let selectedChat = props.selectedChat;
  // const [selectedChat, setSelectedChat] = useState(props.selectedChat);

  const fetchMessages = async () => {
    if (!selectedChat) return;

    try {
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${user.token}`,
      //   },
      // };

      // setLoading(true);

      // const { data } = await axios.get(
      //   `/api/message/${selectedChat._id}`,
      //   config
      // );
      // setMessages(data);
      // setLoading(false);
      setMessages(singleChatMessages);

      // socket.emit('join chat', selectedChat._id);
    } catch (error) {
      toast({
        title: 'Error Occured!',
        description: 'Failed to Load the Messages',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const sendMsg = async (msg) => {
    let message = { content: msg, chatId: selectedChat._id };
    sendMessage(message);
    console.log('newMessage is');
    console.log(newMessage);
    //   if (event.key === 'Enter' && newMessage) {
    //     socket.emit('stop typing', selectedChat._id);
    //     try {
    //       const config = {
    //         headers: {
    //           'Content-type': 'application/json',
    //           Authorization: `Bearer ${user.token}`,
    //         },
    //       };
    //       setNewMessage('');
    //       const { data } = await axios.post(
    //         '/api/message',
    //         {
    //           content: newMessage,
    //           chatId: selectedChat,
    //         },
    //         config
    //       );
    //       socket.emit('new message', data);
    //       setMessages([...messages, data]);
    //     } catch (error) {
    //       toast({
    //         title: 'Error Occured!',
    //         description: 'Failed to send the Message',
    //         status: 'error',
    //         duration: 5000,
    //         isClosable: true,
    //         position: 'bottom',
    //       });
    //     }
    //   }
  };
  useEffect(() => {
    // getSingleChatMessages(selectedChat._id);
    console.log('selectedChat from singlechat is:');
    console.log(selectedChat);
    console.log('props .. how does the singleChatMessages look?');
    console.log(props);
    // setMessages(singleChatMessages);

    // eslint-disable-next-line
  }, []);
  //   useEffect(() => {
  //     socket = io(ENDPOINT);
  //     socket.emit('setup', user);
  //     socket.on('connected', () => setSocketConnected(true));
  //     socket.on('typing', () => setIsTyping(true));
  //     socket.on('stop typing', () => setIsTyping(false));

  //     // eslint-disable-next-line
  //   }, []);

  //   useEffect(
  //     () => {
  //       //   fetchMessages();

  //       selectedChatCompare = selectedChat;
  //       // eslint-disable-next-line
  //     },
  //     [selectedChat]
  //   );

  //   useEffect(() => {
  //     socket.on('message recieved', (newMessageRecieved) => {
  //       if (
  //         !selectedChatCompare || // if chat is not selected or doesn't match current chat
  //         selectedChatCompare._id !== newMessageRecieved.chat._id
  //       ) {
  //         if (!notification.includes(newMessageRecieved)) {
  //           setNotification([newMessageRecieved, ...notification]);
  //           setFetchAgain(!fetchAgain);
  //         }
  //       } else {
  //         setMessages([...messages, newMessageRecieved]);
  //       }
  //     });
  //   });

  const typingHandler = (e) => {
    setNewMessage(e.target.value);

    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      socket.emit('typing', selectedChat._id);
    }
    let lastTypingTime = new Date().getTime();
    var timerLength = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        socket.emit('stop typing', selectedChat._id);
        setTyping(false);
      }
    }, timerLength);
  };

  return (
    <>
      {selectedChat && selectedChat !== '' ? (
        <>
          <Text
            fontSize={{ base: '28px', md: '30px' }}
            pb={3}
            px={2}
            w="100%"
            fontFamily="Work sans"
            display="flex"
            justifyContent={{ base: 'space-between' }}
            alignItems="center"
          >
            {/* <IconButton
              display={{ base: 'flex', md: 'none' }}
              // icon={<ArrowBackIcon />}
              // onClick={() => setSelectedChat('')}
            /> */}
            <div className="col-4">
              <Button
                onClick={() => {
                  // props.setSelectedChatFromChild();
                  selectedChat = '';
                  console.log('selectedChat is:');
                  console.log(selectedChat);
                  props.setShowChatsFromChild(true);
                }}
              >
                Back
              </Button>
            </div>
            {/* {messages && */}

            <div className="col">
              {props.selectedChat &&
                (!props.selectedChat.isGroupChat ? (
                  <>
                    {/* {getSingleChatMessages(selectedChat._id)} */}
                    {/* <h5>test</h5> */}
                    {getSender(user, props.selectedChat.users)}
                    <ProfileModal
                      user={getSenderFull(user, props.selectedChat.users)}
                    />
                    <h5>{props.selectedChat.chatName} test</h5>

                    {/* {selectedChat?._id ? (
                    getSingleChatMessages(selectedChat._id)
                  ) 
                  : (
                    // <></>
                    <></>
                  )} */}
                  </>
                ) : (
                  <>
                    {/* <div> */}

                    <div className="row">
                      <div className="col">
                        <h5 style={{ fontSize: '14px' }}>
                          {props.selectedChat.chatName.toUpperCase()}{' '}
                        </h5>
                      </div>

                      {/* {!props.selectedChat.users.includes(user._id) && (
                      <div className="col">
                        <Button>join</Button>
                      </div>
                    )} */}
                    </div>

                    {/* {props.selectedChat.chatName.toUpperCase()} */}
                    {/* <h5>{props.selectedChat.chatName}</h5> */}
                    {/* </div> */}
                    {/* {getSingleChatMessages(props.selectedChat?._id)} */}
                    {/* <h5>test 2</h5> */}
                    {/* <UpdateGroupChatModal
                    fetchMessages={fetchMessages}
                    fetchAgain={fetchAgain}
                    setFetchAgain={setFetchAgain}
                  /> */}
                    {/* <UpdateGroupChatModal
                    fetchMessages={fetchMessages}
                    fetchAgain={fetchAgain}
                    setFetchAgain={setFetchAgain}
                  /> */}
                  </>
                ))}
            </div>
          </Text>
          <Box
            display="flex"
            flexDir="column"
            justifyContent="flex-end"
            p={3}
            bg="#E8E8E8"
            w="100%"
            h="100%"
            borderRadius="lg"
            overflowY="hidden"
          >
            {loading ? (
              <Spinner
                size="xl"
                w={20}
                h={20}
                alignSelf="center"
                margin="auto"
              />
            ) : (
              <div className="messages" style={{ fontSize: '8px' }}>
                <ScrollableChat messages={singleChatMessages} />
                {/* <ScrollableChat messages={messages} /> */}
              </div>
            )}
            <div className="row">
              <div className="col">
                <FormControl
                  //   onKeyDown={sendMessage}
                  id="first-name"
                  isRequired
                  mt={3}
                  onSubmit={() => {
                    console.log(newMessage);
                    setNewMessage(' ');
                  }}
                >
                  {/* {istyping ? (
                <div>
                  <Lottie
                    options={defaultOptions}
                    // height={50}
                    width={70}
                    style={{ marginBottom: 15, marginLeft: 0 }}
                  />
                </div>
              ) : (
                <></>
              )} */}
                  <Input
                    variant="filled"
                    bg="#E0E0E0"
                    placeholder="Enter a message.."
                    value={newMessage}
                    onChange={typingHandler}
                  />
                </FormControl>
              </div>
              <div className="col" style={{ alignSelf: 'center' }}>
                <Button
                  onClick={() => {
                    console.log('newMessage is here:');
                    console.log(newMessage);
                    sendMsg(newMessage);
                    setNewMessage(' ');
                  }}
                >
                  Send
                </Button>
              </div>
            </div>
          </Box>
        </>
      ) : (
        // to get socket.io on same page
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          h="100%"
          // fontSize="10px"
        >
          <Text fontSize="3xl" pb={3} fontFamily="Work sans">
            Click on a user to start chatting
          </Text>
        </Box>
      )}
    </>
  );
};

export default SingleChat;
