import React, { useReducer } from 'react';
import axios from 'axios';
import TopListContext from './topListContext'; //be sure the case is not case sensitive
import TopListReducer from './topListReducer';

import {
  GET_TOP_DANCERS_BY_LOCATION,
  GET_TOP_STUDIOS_BY_LOCATION,
  TOP_LIST_ERROR,
  LIKE_DANCER,
  ADD_NEW_DANCER,
  GET_ALL_STUDIOS,
} from '../types';

const TopListState = (props) => {
  const initialState = {
    topDancersInLocation: [],
    likedDancer: {},
    newDancer: {},
    allStudios: [],
    topStudiosInLocation: [],
  };

  const [state, dispatch] = useReducer(TopListReducer, initialState);

  const getTopDancersByLocationLimit_3 = async (city, state, artist_type) => {
    try {
      const res = await axios.get(
        `/api/topDancer/byLocationLimit_3/${city}/${state}/${artist_type}`
      );
      console.log('res below from topliststate.js byLocationLimit_3');
      console.log(res);

      dispatch({
        type: GET_TOP_DANCERS_BY_LOCATION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TOP_LIST_ERROR,
        payload: err,
      });
    }
  };
  const getTopDancersByLocation = async (city, state, artist_type) => {
    try {
      const res = await axios.get(
        `/api/topDancer/byLocation/${city}/${state}/${artist_type}`
      );
      console.log('res below from topliststate.js');
      console.log(res);

      dispatch({
        type: GET_TOP_DANCERS_BY_LOCATION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TOP_LIST_ERROR,
        payload: err,
      });
    }
  };
  const getTopStudiosByLocation = async (city, state, artist_type) => {
    try {
      const res = await axios.get(
        `/api/studio/studiosByLocationLimit_3/${city}/${state}/${artist_type}`
      );

      console.log('res below from topliststate.js');
      console.log(res);

      dispatch({
        type: GET_TOP_STUDIOS_BY_LOCATION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TOP_LIST_ERROR,
        payload: err,
      });
    }
  };

  const likeDancer = async (obj) => {
    try {
      const res = await axios.post(`/api/topDancer/likeDancer`, obj);
      console.log('res below from topliststate.js');
      console.log(res);

      dispatch({
        type: LIKE_DANCER,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: TOP_LIST_ERROR,
        payload: err,
      });
    }
  };

  const addNewDancer = async (obj) => {
    try {
      const res = await axios.post(`/api/topDancer/newDancer`, obj);
      console.log('res below from addNewDancer topliststate.js');
      console.log(res);

      dispatch({
        type: ADD_NEW_DANCER,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: TOP_LIST_ERROR,
        payload: err,
      });
    }
  };

  const getAllStudios = async () => {
    try {
      const res = await axios.get(`/api/studio`);
      console.log('res below from addNewDancer topliststate.js');
      console.log(res);

      dispatch({
        type: GET_ALL_STUDIOS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: TOP_LIST_ERROR,
        payload: err,
      });
    }
  };

  return (
    <TopListContext.Provider
      value={{
        // props -- where our state and actions will go
        topDancersInLocation: state.topDancersInLocation,
        likedDancer: state.likedDancer,
        newDancer: state.newDancer,
        allStudios: state.allStudios,
        topStudiosInLocation: state.topStudiosInLocation,

        getAllStudios,
        getTopDancersByLocation,
        likeDancer,
        addNewDancer,
        getTopDancersByLocationLimit_3,
        getTopStudiosByLocation,
      }}
    >
      {props.children}
    </TopListContext.Provider>
  );
};

export default TopListState;
