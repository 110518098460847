import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Reactions from './Reactions';
import AddDancerFields from './AddDancerFields';

const Category = (props) => {
  let [showReactions, setShowReactions] = useState(false);
  let reactionsForCategory = props.reactions;

  const [addDancerClicked, setAddDancerClicked] = useState(false);
  return (
    <div>
      <Button onClick={() => setShowReactions(!showReactions)}>
        {reactionsForCategory[0]}
      </Button>

      {showReactions && (
        <div>
          <div className="row">
            <Button
              onClick={() => {
                setAddDancerClicked(!addDancerClicked);
              }}
              style={{
                backgroundColor: 'rgb(1, 126, 252)',
                width: 120,
                marginTop: 10,
              }}
            >
              add dancer
            </Button>
          </div>
          {addDancerClicked && (
            <div>
              <AddDancerFields category={reactionsForCategory[0]} />
            </div>
          )}
          <div className="row">
            {/* <div className="col-3">
              <h5>name</h5>
            </div> */}
            <div className="col-3">
              <h5 style={{ fontSize: '16px' }}>name</h5>
            </div>
            <div className="col">
              <h5 style={{ fontSize: '16px' }}>bib #</h5>
            </div>
            <div className="col">
              <h5 style={{ fontSize: '16px' }}>reactions</h5>
            </div>
            {/* <div className="col">reactions</div> */}
          </div>
          <Reactions reactions={reactionsForCategory[1]} />
        </div>
      )}
    </div>
  );
};

Category.propTypes = {};

export default Category;
