import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

const DJRankFields = (props) => {
  let {
    artist,
    artistWorldRanking,
    rankings,
    setShowOtherRank,
    showOtherRank,
  } = props;
  console.log('props from djrankfields');
  console.log(props);

  return (
    <div className="row" style={{ marginTop: '14px' }}>
      <div className="col-4" style={{ textAlign: 'left ' }}>
        <img
          className="rounded-circle"
          // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
          src={artist?.image_url?.url}
          // src={image}
          alt=""
          height="75"
          width="75"
        />
        <h5 style={{ color: 'black', fontSize: '14px' }}>{artist.name}</h5>
      </div>
      {/* <div style={{ textAlign: 'right' }}> */}
      <div
        className="col"
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '0px 12px',
          borderWidth: '1px',
        }}
      >
        <div className="row">
          <h5
            style={{
              color: 'white',
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',
              // backgroundColor: 'white',
              backgroundColor: 'rgb(1, 126, 252)',
              // color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '0px 12px',
              borderWidth: '1px',
            }}
          >
            DJ rank
          </h5>
        </div>
        <div className="row">
          <div className="col" style={{ textAlign: 'right' }}>
            <div className="row">
              <div className="col">
                <h5
                  style={{
                    color: 'black',
                    textAlign: 'left',
                    fontSize: '16px',
                  }}
                >
                  {/* 🌎 World: #{rankings.world} */}
                  🌎 World:
                </h5>
              </div>
              <div className="col">
                <h5
                  style={{
                    color: 'black',
                    textAlign: 'right',
                    fontSize: '16px',
                  }}
                >
                  #{rankings?.world}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5
                  style={{
                    color: 'black',
                    textAlign: 'left',
                    fontSize: '16px',
                  }}
                >
                  {/* {artistWorldRanking.country}: #{rankings?.country} */}
                  {artistWorldRanking?.country}:
                </h5>
              </div>
              <div className="col-4">
                <h5
                  style={{
                    color: 'black',
                    textAlign: 'right',
                    fontSize: '16px',
                    // width: '40px',
                  }}
                >
                  #{rankings?.country}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h5
                  style={{
                    color: 'black',
                    textAlign: 'left',
                    fontSize: '16px',
                  }}
                >
                  {/* {artistWorldRanking.state}: #{rankings.state} */}
                  {artistWorldRanking?.state}:
                </h5>
              </div>
              <div className="col">
                <h5
                  style={{
                    color: 'black',
                    textAlign: 'right',
                    fontSize: '16px',
                  }}
                >
                  #{rankings?.state}
                </h5>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <h5
                  style={{
                    color: 'black',
                    textAlign: 'left',
                    fontSize: '16px',
                  }}
                >
                  {/* {artistWorldRanking.city}: #{rankings.city} */}
                  {artistWorldRanking?.city}:
                </h5>
              </div>

              <div className="col">
                <h5
                  style={{
                    color: 'black',
                    textAlign: 'right',
                    fontSize: '16px',
                  }}
                >
                  #{rankings?.city}
                </h5>
              </div>
              <div
                style={{
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => setShowOtherRank(!showOtherRank)}
                  style={{
                    // color: 'white',
                    // float: 'right',
                    width: 'max-content',
                    color: '#00b300',

                    background: 'white',

                    borderStyle: 'solid',
                    borderColor: '#00b300',
                    borderWidth: '1px',
                    borderRadius: '6px',

                    // fontSize: '14px',
                    backgroundColor: 'white',
                    alignContent: 'center',
                    marginBottom: '8px',
                  }}
                >
                  show other rankings
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DJRankFields;
