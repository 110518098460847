import React, { Component, Fragment } from 'react';
const moment = require('moment');
moment().format();

const PrivateLesson = (props) => {
  const { request } = props;
  const formattedDate = moment(request.lessonDate)
    .zone(0)
    .format('dddd MMMM Do YYYY');

  const pendingButton = (
    <Fragment>
      <h5
        style={{
          fontSize: '12px',
          width: 'max-content',
          background: 'white',
          color: 'orange',
          borderStyle: 'solid',
          borderColor: 'orange',
          padding: '3px',
          borderRadius: '6px',
        }}
      >
        pending
      </h5>
    </Fragment>
  );
  const acceptedButton = (
    <Fragment>
      <h5
        style={{
          fontSize: '12px',
          width: 'max-content',
          background: 'white',
          color: 'green',
          borderStyle: 'solid',
          borderColor: 'green',
          padding: '3px',
          borderRadius: '6px',
        }}
      >
        accepted
      </h5>
    </Fragment>
  );
  const declinedButton = (
    <Fragment>
      <h5
        style={{
          fontSize: '12px',
          width: 'max-content',
          background: 'white',
          color: 'red',
          borderStyle: 'solid',
          borderColor: 'red',
          padding: '3px',
          borderRadius: '6px',
        }}
      >
        declined
      </h5>
    </Fragment>
  );

  const renderButtonOptions = () => {
    if (request.status === 'TBD') return pendingButton;
    if (request.status === 'Accepted') return acceptedButton;
    if (request.status === 'Declined') return declinedButton;
  };

  return (
    <div className="card">
      <div className="row">
        <div className="col">
          <div className="row">
            <h5
              style={{
                fontSize: '12px',
                maxWidth: '100px',

                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {request?.requested_instructor_name
                ? request.requested_instructor_name
                : request.requested_artist_name}
            </h5>

            <h5 style={{ fontSize: '12px' }}>
              {renderButtonOptions()}
              {/* {request.status === 'TBD' ? pendingButton : acceptedButton} */}
            </h5>
          </div>
        </div>

        {/* <div className="col">
        </div> */}
        <div className="col">
          <h5 style={{ fontSize: '12px' }}>{formattedDate}</h5>
        </div>
        <div className="col">
          <h5>{request.lessonTime}</h5>
        </div>
      </div>
      <div>
        {' '}
        {request?.lessonPlan && (
          <div>
            <h1>Lesson Plan</h1>
          </div>
        )}
        {request?.lessonPlan?.map((move) => (
          <div>
            <h5>{move}</h5>
          </div>
        ))}
      </div>
      <hr />
      {request?.comments_or_special_requests && (
        <div>
          <h4>Requests or Comments</h4>
          <h5>{request?.comments_or_special_requests}</h5>
        </div>
      )}
    </div>
  );
};

export default PrivateLesson;
