import React, { Component, Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
const moment = require('moment');
moment().format();
const ReporterView = (props) => {
  let { incidentReport } = props;

  const smallText = (
    <Fragment>
      <div className="smallText">
        <p>{incidentReport.description_of_incident}</p>
      </div>
    </Fragment>
  );

  const largeText = (
    <Fragment>
      <div className="smallTextExpanded">
        <p>{incidentReport.description_of_incident}</p>
      </div>
    </Fragment>
  );
  const [expandButtonSelected, setExpandButtonSelected] = useState(false);

  return (
    <div>
      {/* <h5>ReporterView</h5> */}

      <div className="row">
        <h5>Incident Description</h5>

        {!expandButtonSelected ? smallText : largeText}
        <div className="row" style={{ paddingTop: '' }}>
          <div className="col-4">
            <Button
              variant="primary"
              onClick={() => setExpandButtonSelected(!expandButtonSelected)}
              style={{
                background: 'white',
                // color: 'blue',
                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
                padding: '0px 12px',
                borderWidth: '1px',
                width: '100px',
              }}
            >
              {!expandButtonSelected ? 'see more' : 'see less'}
            </Button>
          </div>
        </div>

        {/* <h5 style={{ fontSize: '14px' }}>

        {!expandButtonSelected ? 'see more' : 'see less'}
          {incidentReport.description_of_incident}
        </h5> */}
      </div>
      <div className="row">
        <div className="col">
          <h5>status: {incidentReport.status_of_incident_report}</h5>
        </div>
        <div className="col">
          <h5>offender name: {incidentReport.offender_name}</h5>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5>event of incident:</h5>
          {incidentReport?.event_of_incident?.event_name}
        </div>
        <div className="col">
          <h5>date of incident:</h5>
          {moment(incidentReport.date_of_incident)
            .zone(0)
            .format('ddd MM-DD-YY')}
        </div>
      </div>
    </div>
  );
};
export default ReporterView;
