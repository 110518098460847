import React, { Component, useContext, useEffect } from 'react';
import IncidentReportContext from '../../context/incidentReport/incidentReportContext';
import { Button } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import UserContext from '../../context/user/userContext';
import { useAuth } from '../../context/auth/AuthState';

const AllIncidentReports = () => {
  const incidentReportContext = useContext(IncidentReportContext);
  const { all_incident_reports, getAllIncidentReports } = incidentReportContext;
  const userContext = useContext(UserContext);
  // const { user, loading } = userContext;

  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user, loading } = authState;
  let navigate = useNavigate();

  useEffect(() => {
    // if (
    //   user?.safety_council_member === 'false' ||
    //   user?.email === 'airmeus.team@gmail.com' ||
    //   !('safety_council_member' in user)
    // ) {
    //   console.log('not granted .. user email:');
    //   console.log(user?.email);
    //   // navigate('/');
    // } else {
    //   console.log('granted...user email:');
    //   console.log(user?.email);
    // }
    getAllIncidentReports();
    // eslint-disable-next-line
  }, []);

  const accessDenied = () => {
    if (
      user?.safety_council_member === 'false' ||
      user?.email !== 'airmeus.team@gmail.com' ||
      !('safety_council_member' in user)
    ) {
      console.log('user.email');
      console.log(user.email);
      console.log('user?.safety_council_member');
      console.log(user?.safety_council_member);
      return true;
    } else {
      return false;
    }
  };

  if (loading) return <h5>loading</h5>;

  return (
    <div>
      {/* {accessDenied()  */}

      {user &&
      (user?.email === 'airmeus.team@gmail.com' ||
        user?.safety_council_member === true) ? (
        <>
          <div>
            <h5>All Incident Reports</h5>
          </div>
          {all_incident_reports.map((incidentReport, index) => (
            <div className="row" style={{ paddingBottom: '14px' }}>
              <div className="col-1">{index + 1}</div>
              {/* <div className="col">{incidentReport._id}</div> */}
              <div className="col">
                <Link to={`/incident-report/${incidentReport._id}`}>
                  <Button
                    style={{
                      width: '180px',

                      // width: '80px',
                      background: 'white',

                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',
                      borderWidth: '1px',
                      borderRadius: '2px',

                      fontSize: '14px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      textAlign: 'center',
                    }}
                  >
                    {incidentReport.reporter_name}
                  </Button>
                </Link>
              </div>
            </div>
          ))}
        </>
      ) : (
        <h5>access denied - go home</h5>
      )}
    </div>
  );
};

export default AllIncidentReports;
