import React, { Component, useContext, useEffect } from 'react';
import ArtistContext from '../../../context/artist/artistContext';
import { Link } from 'react-router-dom';

const DJsForYourNextEvent = () => {
  let artistContext = useContext(ArtistContext);

  let { randomDJsForTimeline, getRandomDJs } = artistContext;

  useEffect(() => {
    getRandomDJs();
    console.log('randomDJsForTimeline');
    console.log(randomDJsForTimeline);
    // props.setRandomStyle(randomStyle);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h5>DJs For Your Next Event 🔥</h5>
      {randomDJsForTimeline?.map((dj) => (
        <div className="col grid-container-small-screen grid-container-big-screen-main-timeline">
          <div className="" style={{ width: '170px' }}>
            <div>
              <Link
                to={
                  dj?._id
                    ? `/artist-profile/${dj?._id}`
                    : `/user/profile/${dj?.user_id}`
                }
                style={{ color: 'blue' }}
              >
                <div className="imageRankcontainer">
                  <div
                    className="bottomright"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      borderRadius: '5px',
                      margin: '2px',
                      padding: '3px',
                    }}
                  >
                    <h5
                      style={{
                        color: 'white',
                        // backgroundColor: 'black',
                        marginBottom: '0px',
                      }}
                    >
                      {/* {artist.rank}  */}
                      {dj?.rank}
                    </h5>
                  </div>

                  <img
                    src={dj?.image ? dj?.image : dj?.image_url?.url}
                    height="150"
                    width="150"
                    alt="dj"
                    style={{ borderRadius: '0.25rem' }}
                  />
                </div>
                <h5>{dj?.name}</h5>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default DJsForYourNextEvent;
