import React, { Component, useContext, useEffect } from 'react';
import RankingsContext from '../../../context/rankings/rankingsContext';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RandomRankings = (props) => {
  const rankingsContext = useContext(RankingsContext);
  const {
    rankings,
    getRankings,
    getRandomFourRankingsForTimeline,
    randomRankingsForTimeline,
    randomStyle,
  } = rankingsContext;

  const styles = ['salsa', 'bachata', 'kizomba', 'zouk'];

  const getRandomArtistsFromRankings = () => {};

  useEffect(() => {
    getRandomFourRankingsForTimeline();
    // props.setRandomStyle(randomStyle);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h5 style={{ fontSize: '24px' }}>
        {randomStyle[0]?.toUpperCase() + randomStyle?.slice(1)} Rankings
      </h5>

      <div>
        {randomRankingsForTimeline.map((user) => (
          <div className="col grid-container-small-screen grid-container-big-screen-main-timeline">
            <div className="" style={{ width: '170px' }}>
              {/* <div className="card card-body" style={{ width: '170px' }}> */}
              {/* we have this conditional option bcuz rankings can use either artist_id or user_id if the user is not an artist */}
              <Link
                to={
                  user?.artist_id
                    ? `/artist-profile/${user?.artist_id}`
                    : `/user/profile/${user?.user_id}`
                }
                style={{ color: 'blue' }}
              >
                <div className="imageRankcontainer">
                  <div
                    className="bottomright"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      borderRadius: '5px',
                      margin: '2px',
                      padding: '3px',
                    }}
                  >
                    <h5
                      style={{
                        color: 'white',
                        // backgroundColor: 'black',
                        marginBottom: '0px',
                      }}
                    >
                      {/* {artist.rank}  */}
                      {user?.rank}
                    </h5>
                  </div>

                  <img
                    src={user?.image ? user?.image : user?.image_url?.url}
                    height="150"
                    width="150"
                    alt="user"
                    style={{ borderRadius: '0.25rem' }}
                  />
                </div>
              </Link>
              <h5 style={{ fontSize: '18px' }}>{user?.name}</h5>
              <Button
                style={{
                  fontSize: '10px',
                  background: 'white',

                  borderStyle: 'solid',
                  borderColor: 'rgb(1, 126, 252)',
                  borderWidth: '2px',
                  borderRadius: '6px',

                  backgroundColor: 'white',
                  color: 'rgb(1, 126, 252)',
                  textAlign: 'center',
                }}
              >
                {user?.artist_type}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RandomRankings;
