import React, { Component, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UserNotOwnerPopup = (props) => {
  let { artist_id, showPopup, setShowPopup } = props;
  const [artistOptionsPopupSelected, setArtistOptionsPopupSelected] = useState(
    props.optionSelected
  );
  const handleClose = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div>
      <Modal show={showPopup} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props.event_name}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {/* <Form.Label>popup</Form.Label> */}
              {/* <h5 style={{ color: 'black' }}>
                If this profile belongs to you, click here to{' '}
                <Link
                  to={`/register-and-claim-artist/${artist_id}`}
                  state={{ artist_id: artist_id }}
                >
                  <span style={{ color: 'rgb(1, 126, 252)', fontSize: '18px' }}>
                    claim your page
                  </span>
                </Link>
              </h5> */}
              {/* <br /> */}
              <h5>
                This is a{' '}
                <span style={{ color: 'rgb(1, 126, 252)', fontSize: '20px' }}>
                  Premium Feature
                </span>
                . To take immediate ownership of your Artist Profile{' '}
                {/* <Link
                  to={`/register-and-claim-artist/${artist_id}`}
                  state={{ artist_id: artist_id }}
                >
                  <span style={{ color: 'rgb(1, 126, 252)', fontSize: '18px' }}>
                    claim your page
                  </span>
                </Link> */}
                <Link to={`/go-pro-artist/${artist_id}`}>
                  <button
                    // className="rounded-pill"
                    style={{
                      width: '180px',
                      background: 'white',

                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',
                      borderWidth: '1px',
                      borderRadius: '2px',

                      fontSize: '14px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      textAlign: 'center',
                    }}
                  >
                    Access Premium Artist 🚀
                  </button>
                </Link>
              </h5>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default UserNotOwnerPopup;
