import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { format } from 'date-fns';

import Table from 'react-bootstrap/Table';
import TourContext from '../../context/tour/tourContext';
import { Button } from 'react-bootstrap';
import TourCompanyPopup from '../tour/TourCompanyPopup';
import PopupButton from '../tour/PopupButton';
const moment = require('moment');
moment().format();
const CurrentUserTour = (props) => {
  let tourContext = useContext(TourContext);

  const { getToursForInstructor, tours } = tourContext;
  useEffect(() => {
    console.log('props?.instructor_id from currentusertour');
    console.log(props?.instructor_id);
    getToursForInstructor(props?.instructor_id);
    // eslint-disable-next-line
  }, []);
  console.log();
  const [companyClicked, setCompanyClicked] = useState(false);
  const [companyName, setCompanyName] = useState('');

  const renderPopup = (company_name) => {
    // <Fragment>
    //   return (
    companyClicked && (
      <TourCompanyPopup
        company_name={company_name}
        companyClicked={companyClicked}
      />
    );
  };
  //   )
  // </Fragment>
  function StripedColumnsExample(tour_stops, tour_name) {
    // {tour_stops.map((tour_stop)=>(

    // ))}

    return (
      <div>
        <h5>{tour_name}</h5>

        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>City</th>
              <th>Dance Company</th>
              <th>dates</th>
              <th>status</th>
            </tr>
          </thead>
          {/* {companyClicked && (
            <TourCompanyPopup
              company_name={companyName}
              companyClicked={companyClicked}
            />
          )} */}
          {tour_stops.map((tour_stop, index) => (
            <tbody>
              {/* <div> */}
              <tr>
                <td>{index + 1}</td>
                <td>
                  <Button
                    style={{
                      width: '70px',
                      // maxWidth: '120px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                      padding: '4px',
                      fontSize: '12px',
                      borderStyle: 'solid',
                      color: 'black',
                      borderColor: 'black',
                      backgroundColor: 'white',
                    }}
                  >
                    {tour_stop.city}
                  </Button>
                </td>
                <td>
                  <Button
                    onClick={() => {
                      setCompanyClicked(!companyClicked);
                      setCompanyName(tour_stop.company_name);
                      //   renderPopup(tour_stop.company_name);
                    }}
                    style={{
                      width: '120px',
                      // maxWidth: '220px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                      backgroundColor: 'white',
                      // color: 'black',
                      borderStyle: 'solid',
                      color: 'rgb(1, 126, 252)',
                      borderColor: 'rgb(1, 126, 252)',

                      padding: '4px',
                    }}
                  >
                    {tour_stop.company_name}
                  </Button>

                  {/* <Button>{tour_stop.company_name}</Button> */}

                  {/* <PopupButton city_name={tour_stop.company_name} /> */}
                </td>
                {/* <td>{format(tour_stop?.date, 'PP')}</td> */}
                <td
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {moment(tour_stop?.date)
                    .zone(0)
                    .format('MM-DD-YY')

                  // format(tour_stop?.date, 'PP')
                  }
                </td>

                <td>{tour_stop.status}</td>
              </tr>
            </tbody>
          ))}
        </Table>
      </div>
    );
  }

  return (
    <div>
      {companyClicked && (
        <TourCompanyPopup
          company_name={companyName}
          companyClicked={companyClicked}
          setCompanyClicked={setCompanyClicked}
        />
      )}
      {tours.map((tour) =>
        StripedColumnsExample(tour.tour_stops, tour.tour_name)
      )}
    </div>
  );
};
export default CurrentUserTour;
