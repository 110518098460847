import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';
import StudioContext from '../../context/studio/studioContext';

const AddStudioLevel = (props) => {
  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;

  const studioContext = useContext(StudioContext);
  const {
    getStudioById,
    studio,
    getStudioMembers,
    members,
    addNewMember,
    addNewLevel,
  } = studioContext;

  const userContext = useContext(UserContext);

  const { connectToUser } = userContext;
  let copyClicked = false;
  const location = useLocation();
  //   const { user } = location.state;

  // const { user, current_user_id, isCurrentUser } = props;
  const { studio_id } = useParams();

  useEffect(() => {
    // console.log('studio_id');
    // console.log(studio_id);
    // //get studio by id
    // getStudioById(studio_id);
    // getStudioMembers(studio_id);
    // console.log('studio');
    // console.log(studio);
    // eslint-disable-next-line
  }, []);
  //   let listOfSkills = '';
  const [skills, setSkills] = useState({ levelName: '', listOfSkills: '' });
  const { levelName, style, listOfSkills } = skills;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('skills');
    console.log(skills);

    let obj = {
      skills: skills,
      style: style,
      studio_id: studio_id,
      level: levelTitle,
    };
    // console.log("obj dog")
    // console.log(obj)
    addNewLevel(obj);
  };
  const handleListChange = () => {
    console.log('submit');
  };
  const [showDisallowTextPrompt, setShowDisallowTextPrompt] = useState(false);

  const textHasDisallowedCharacter = (level) => {
    // let regex = /\[^|]/;
    let regex = /\|/i;
    // let regex = /|/;
    let newReg = new RegExp('|');
    if (regex.test(level)) {
      //no char
      setShowDisallowTextPrompt(true);
      console.log(`${level} contains | character`);
    } else {
      setShowDisallowTextPrompt(false);
      console.log('all characters are fine');
    }
    return false;
  };
  const onChange = (e) => {
    textHasDisallowedCharacter(e.target.value);
    console.log(e.target.value);
    console.log(e.target);
    setSkills({ ...skills, [e.target.name]: [e.target.value] });
  };

  const handleSelectLevel = (e) => {
    // setLevel(e);
    // setDetails({ ...details, ['level']: e });
    // console.log('details is');
    // console.log(details);
    // console.log(e);
    setLevelTitle(e);
  };

  //level button
  //  const [levelButtonClicked, setLevelButtonClicked] = useState(false);
  const [levelTitle, setLevelTitle] = useState('Beginner');

  return (
    <div>
      <div className="col">
        <div className="row">
          <div className="col">
            <label>Level</label>
          </div>

          <div className="col">
            {[DropdownButton].map((DropdownType, idx) => (
              <DropdownType
                as={ButtonGroup}
                key={idx}
                id={`dropdown-button-drop-${idx}`}
                size="sm"
                variant="secondary"
                title={levelTitle}
                // onClick={() => {
                //   setLevelButtonClicked(true);
                // }}

                onSelect={handleSelectLevel}
              >
                <Dropdown.Item eventKey="Beginner">Beginner</Dropdown.Item>
                <Dropdown.Item eventKey="Intermediate">
                  Intermediate
                </Dropdown.Item>
                <Dropdown.Item eventKey="Advanced">Advanced</Dropdown.Item>
              </DropdownType>
            ))}
          </div>
        </div>
        <div className="row">
          <h4>Level Name</h4>
        </div>
        <div className="row">
          {showDisallowTextPrompt === true ? (
            <label htmlFor="" style={{ color: 'red' }}>
              this character "|" is not allowed
            </label>
          ) : (
            <></>
          )}
          <input
            id="levelName"
            type="text"
            name="levelName"
            value={levelName}
            onChange={onChange}
            placeholder="Beginner 1"
            required
          />
        </div>
      </div>
      <div className="col">
        <div className="row">
          <h4>Style</h4>
        </div>
        <div className="row">
          <input
            id="style"
            type="text"
            name="style"
            value={style}
            onChange={onChange}
            placeholder="Salsa, Bachata or Zouk"
            required
          />
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <label style={{ fontSize: '12px' }}>
          skills: (input values so each skill is separated by a comma")
          <textarea
            placeholder="Basic, Cross Body Lead, Etc"
            cols="200"
            id="listOfSkills"
            // type="text"
            name="listOfSkills"
            value={listOfSkills}
            onChange={onChange}
          />
        </label>

        {!showDisallowTextPrompt === true ? (
          <input type="submit" value="Submit" />
        ) : (
          <></>
        )}
      </form>
      {/* <Button>Add</Button> */}
    </div>
  );
};

export default AddStudioLevel;
