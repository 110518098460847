import React, { Component } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const StudioClaimOptionsPopup = (props) => {
  let { artistOptionsPopupSelected, handleClose, studio_id } = props;
  return (
    <div>
      {artistOptionsPopupSelected ? (
        <div>
          {' '}
          <Modal show={artistOptionsPopupSelected} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{props.event_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  {/* <Form.Label>popup</Form.Label> */}
                  <h5 style={{ color: 'black' }}>
                    If this profile belongs to you, click here to{' '}
                    <Link
                      to={`/register-and-claim-studio/${studio_id}`}
                      state={{ studio_id: studio_id }}
                    >
                      <span
                        style={{
                          color: 'rgb(1, 126, 252)',
                          fontSize: '18px',
                        }}
                      >
                        claim your page
                      </span>
                    </Link>{' '}
                  </h5>
                  <hr />
                  <h5>
                    {' '}
                    <span style={{ color: 'red', fontSize: '20px' }}>
                      Note:{' '}
                    </span>
                    this will not give you premium studio tooling, such as:
                  </h5>
                  <h5>✅ Custom Curriculum</h5>
                  <h5> ✅ Performer Tools</h5>

                  <h5>
                    To Access Premium go here{' '}
                    <Link to={`/go-pro-subscription/${studio_id}`}>
                      <button
                        // className="rounded-pill"
                        style={{
                          width: '180px',
                          background: 'white',

                          borderStyle: 'solid',
                          borderColor: 'rgb(1, 126, 252)',
                          borderWidth: '1px',
                          borderRadius: '2px',

                          fontSize: '14px',
                          backgroundColor: 'white',
                          color: 'rgb(1, 126, 252)',
                          textAlign: 'center',
                        }}
                      >
                        Access Premium Studio 🚀
                      </button>
                    </Link>
                  </h5>
                </Form.Group>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default StudioClaimOptionsPopup;
