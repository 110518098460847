import React, { useReducer } from 'react';
import axios from 'axios';
import InstructorContext from './instructorContext'; //be sure the case is not case sensitive
import InstructorReducer from './instructorReducer';

import {
  GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR,
  GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR_AT_EVENTS,
  SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR,
  DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR,
  SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR_AT_EVENT,
  GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_NO_EVENT,
  DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR_AT_EVENT,
  GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_AT_EVENT,
  SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_INSTRUCTOR,
  INSTRUCTOR_ERROR,
} from '../types';
const InstructorState = (props) => {
  const initialState = {
    instructor: {},

    privateLessonRequestsForInstructor: [],
    privateLessonRequestForInstructor: {},
    confirmedPrivateLessonsForInstructorNoEvent: [],
    confirmedPrivateLessonsForInstructorAtEvents: [],

    privateLessonRequestsForInstructorAtEvents: [],

    loading: true,

    // confirmedPrivateLessons: {},
  };

  const [state, dispatch] = useReducer(InstructorReducer, initialState);

  /*
  *
  * TO-DO : lesson requests with no event amd then lesson requests with event
  * 
  * 1. DONE --- sendPrivateLessonRequest 
  * 2. DONE --- getPrivateLessonRequestsForArtist
  * 3. getConfirmedLessonsForArtist - is there a distinction on lessons at and away from events? there is right? two diff. routes
  * 4. DONE --- approve/decline lesson requests
  * 
  * 
  * WITH EVENTS -----------------------------------------------
  * 
  * 5. sendPrivateLessonRequest - EVENTS
  * 6. getPrivateLessonRequestsForArtist - EVENTS
  * 7. getConfirmedLessonsForArtist - is there a distinction on lessons at and away from events? there is right? two diff. routes
  * 8. approve/decline lesson requests - EVENTS
  *
  * ------------------------------------------------------------ 
  */
  // backend match
  const getPrivateLessonRequestsForInstructor = async (instructor_id) => {
    try {
      console.log('trying getPrivateLessonRequestsForInstructor');
      const res = await axios.get(
        `/api/instructor/privateLessonRequests/${instructor_id}`
      );
      console.log(
        'res below from getPrivateLessonRequestsForInstructor artiststate.js'
      );
      console.log(res);

      dispatch({
        type: GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err,
      });
    }
  };
  //backend match
  // const createNewEvent = async (name, descr, hostId) => {

  const sendPrivateLessonRequestToInstructor = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to sendPrivateLessonRequestToInstructor backend from ArtistState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post(
        '/api/instructor/privateLessonRequest',
        form
      );

      dispatch({
        type: SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err.response,
      });
    }
  };

  const sendPrivateLessonRequestWithoutAccountToInstructor = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to sendPrivateLessonRequestWithoutAccountToInstructor backend from InstuctortState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post(
        '/api/instructor/privateLessonRequest/no-account',
        form
      );

      dispatch({
        type: SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_INSTRUCTOR,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err.response,
      });
    }
  };

  const sendPrivateLessonRequestToInstructorAtEvent = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to sendPrivateLessonRequestAtEvent backend from ArtistState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post(
        '/api/instructor/privateLessonRequestAtEvent',
        form
      );

      dispatch({
        type: SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR_AT_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err.response,
      });
    }
  };

  //DECISION A PRIVATE LESSON REQUEST
  // backend match

  const decisionPrivateLessonRequestForInstructor = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to decisionPrivateLessonRequest from InstructorState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };
      const res = await axios.post(
        '/api/instructor/approve_or_decline_private_lesson_request',
        form
      );

      dispatch({
        type: DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err.response,
      });
    }
  };
  const decisionPrivateLessonRequestForInstructorAtEvent = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to decisionPrivateLessonRequest from InstructorState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };
      const res = await axios.post(
        '/api/instructor/approve_or_decline_private_lesson_request_at_event',
        form
      );

      dispatch({
        type: DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR_AT_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err.response,
      });
    }
  };
  // backend match
  const getPrivateLessonRequestsForInstructorAtEvents = async (
    instructor_id
  ) => {
    try {
      console.log('trying getPrivateLessonRequestsForInstructorAtEvents');
      const res = await axios.get(
        `/api/instructor/privateLessonRequestsAndEvents/${instructor_id}`
      );
      console.log(
        'res below from getPrivateLessonRequestsForInstructorAtEvents instructorstate.js'
      );
      console.log(res.data);

      dispatch({
        type: GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR_AT_EVENTS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err,
      });
    }
  };
  const getConfirmedPrivateLessonsForInstructorAtEvents = async (
    instructor_id
  ) => {
    try {
      console.log('trying getConfirmedPrivateLessonsForInstructorAtEvents');
      const res = await axios.get(
        `/api/instructor/confirmedPrivateLessonsAndEvents/${instructor_id}`
      );
      console.log(
        'res below from getConfirmedPrivateLessonsForInstructorAtEvents instructorstate.js'
      );
      console.log(res.data);

      dispatch({
        type: GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_AT_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err,
      });
    }
  };
  const getConfirmedPrivateLessonsForInstructorNoEvent = async (
    instructor_id
  ) => {
    try {
      console.log('trying getConfirmedPrivateLessonsForInstructorNoEvent');
      const res = await axios.get(
        `/api/instructor/confirmedPrivateLessons/${instructor_id}`
      );
      console.log(
        'res below from getConfirmedPrivateLessonsForInstructorNoEvent instructorstate.js'
      );
      console.log(res.data);

      dispatch({
        type: GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_NO_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: INSTRUCTOR_ERROR,
        payload: err,
      });
    }
  };

  return (
    <InstructorContext.Provider
      value={{
        // props -- where our state and actions will go
        instructor: state.instructor,
        // allStudios: state.allStudios,
        privateLessonRequestsForInstructor:
          state.privateLessonRequestsForInstructor,
        privateLessonRequestForInstructor:
          state.privateLessonRequestForInstructor,
        privateLessonRequestsForInstructorAtEvents:
          state.privateLessonRequestsForInstructorAtEvents,
        confirmedPrivateLessonsForInstructorAtEvents:
          state.confirmedPrivateLessonsForInstructorAtEvents,
        confirmedPrivateLessonsForInstructorNoEvent:
          state.confirmedPrivateLessonsForInstructorNoEvent,

        sendPrivateLessonRequestToInstructor,
        getPrivateLessonRequestsForInstructor,
        decisionPrivateLessonRequestForInstructor,
        decisionPrivateLessonRequestForInstructorAtEvent,
        getPrivateLessonRequestsForInstructorAtEvents,
        sendPrivateLessonRequestToInstructorAtEvent,
        getConfirmedPrivateLessonsForInstructorAtEvents,
        getConfirmedPrivateLessonsForInstructorNoEvent,
        sendPrivateLessonRequestWithoutAccountToInstructor,
      }}
    >
      {props.children}
    </InstructorContext.Provider>
  );
};

export default InstructorState;
