//consider renaming to potential_mate to differentiate btw user
import React, { useContext, useEffect, Fragment, useState } from 'react';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import SubEventContext from '../../context/subevent/subEventContext'; // might have to capitalize
import AdvertismentContext from '../../context/advertisement/advertismentContext';
// import AdvertisementContext from '../../context/advertisement/advertisementContext'; // might have to capitalize
import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';
// import EventItem from './EventItem';
import PropTypes from 'prop-types';

import AlertContext from '../../context/alert/alertContext';
import { useAuth } from '../../context/auth/AuthState';
// import { Navigate } from 'react-router-dom';
// import Pagination from './Pagination';
// import ReactPaginate from 'react-paginate';
// import { Link } from 'react-router-dom';
// import Form from 'react-bootstrap/Form';
// import ListOfStates from './ListOfStates.json';
// import ListOfStatesAndCities from './ListOfStatesAndCities.json';
// import TopDancerOnMainTimeline from '../topLists/TopDancerOnMainTimeline';

const Activity = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [authState] = useAuth();
  const [loading, setLoading] = useState(false);

  const { user } = authState;
  // const eventContext = useContext(EventContext);
  // const { getAllEvents, events, createNewEvent } = eventContext;

  const subEventContext = useContext(SubEventContext);
  const { getAllActivities, activities } = subEventContext;

  // const adContext = useContext(AdvertismentContext);
  // const { getAllAds, advertisements } = adContext;

  useEffect(() => {
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    // setLoading(true);
    // getAllActivities();
    // setLoading(false);
    // console.log('activities');
    // console.log(activities);
    // eslint-disable-next-line
  }, []);
  const { activity } = props;

  let image;
  if (activity.image) {
    image = activity.image.url;
  } else {
    image =
      'https://www.weho.org/home/showpublishedimage/5617/635754012603070000';
  }
  return (
    <div>
      <div className="timelineCard bg-light smallScreenEvents bigScreenEvents">
        {/* <Link
          to={`/event/${props.event._id}`}
          state={{ event: event, page: 'attendees' }}
        > */}
        <div className="all-center">
          <img src={image} alt="event" />
        </div>
        {/* </Link> */}

        <div className="adjust-line-height">
          <div>
            {/* {activity?.start_date ? (
            <label style={{ fontSize: '14px' }}>
              {moment(activity.start_date)
                .zone(0)
                .format('dddd, MMMM DD')}
            </label>
          ) : (
            <></>
          )}
          <br /> */}
            <label style={{ fontSize: '20px', fontWeight: 'bold' }}>
              {activity.sub_event_name}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

Event.propTypes = {};

export default Activity;
