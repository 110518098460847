import React, {
  Fragment,
  Component,
  useState,
  useContext,
  useEffect,
} from 'react';
import { Link } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { useAuth } from '../../context/auth/AuthState';
import { Button } from 'react-bootstrap';
import TopListContext from '../../context/topList/topListContext';
import Dancer from './Dancer';
import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const TopDancer = (props) => {
  const [authState] = useAuth();
  const { user } = authState;

  const topListContext = useContext(TopListContext);
  const {
    getTopDancersByLocation,
    topDancersInLocation,
    likeDancer,
    addNewDancer,
    allStudios,
    getAllStudios,
  } = topListContext;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('ListOfStates');
    console.log(ListOfStates);
    console.log('ListOfStatesAndCities');
    console.log(ListOfStatesAndCities);
    // setLoading(true);
    let location = {
      city: 'Charlotte',
      state: 'North Carolina',
      artist_type: 'bachata',
    };
    getTopDancersByLocation(
      location.city,
      location.state,
      location.artist_type
    );
    getAllStudios();
    console.log('allStudios');
    console.log(allStudios);
    // setLoading(false);
    // eslint-disable-next-line
  }, []);
  const [details, setDetails] = useState({
    first_name: '',
    last_name: '',
    state: 'North Carolina',
    city: 'Charlotte', //default to user.city or first city from list
    artist_type: 'bachata',
    existing_studio_name: '',
    existing_studio_id: '',
    newStudioName: '',
  });
  const {
    first_name,
    last_name,
    state,
    city,
    artist_type,
    existing_studio_name,
    existing_studio_id,
    newStudioName,
  } = details;
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  console.log('topDancersInLocation');
  console.log(topDancersInLocation);

  const [disabled, setDisabled] = useState(false);

  const handleLike = async (liked_user_id) => {
    if (user) {
      //like user & disable like button
      let obj = {
        likedUserId: liked_user_id,
        likingUserId: user?._id,
        // eventId: roomie_request.event,
        // accepted_user_id: roomie_request.requestor,
      };
      console.log('obj is');
      console.log(obj);
      // likeDancer(obj);
      setDisabled(true);
    } else {
      console.log('alert user to log in to like and put login button in box');
    }
  };
  let notLiked = '🤍';
  let liked = '💙';
  const [likedBtn, setLikedBtn] = useState(notLiked);

  const onSubmit2 = (e) => {
    console.log('submitted');
  };
  const onSubmit3 = (e) => {
    e.preventDefault();
    console.log('submitted 3');
  };

  const onSubmitDancer = (e) => {
    e.preventDefault();
    console.log('details from submission');
    console.log(details);
    const data = new FormData();
    data.append('first_name', first_name);
    data.append('last_name', last_name);
    data.append('state', state);
    data.append('city', city);
    console.log('data from submission');
    console.log(data);
    console.log('details from sub');
    console.log(details);
    addNewDancer(details);
  };
  const [visible, setVisible] = React.useState(false);
  const [addDancerFieldsVisible, setAddDancerFieldsVisible] = React.useState(
    false
  );

  let artist_type_array = ['salsa', 'bachata', 'kizomba', 'zouk'];
  const handleArtistTypeChange = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    // setArtistDetails({ ...artistDetails, ['artist_type']: type });
    console.log('from handleArtistTypeChange ');
    console.log('city');
    console.log(city);
    console.log('state');
    console.log(state);
    console.log('type');
    console.log(type);

    setDetails({ ...details, ['artist_type']: type });
    getTopDancersByLocation(city, state, type);
  };
  const handleArtistTypeChangeNoReload = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    // setArtistDetails({ ...artistDetails, ['artist_type']: type });
    console.log('from handleArtistTypeChange ');
    console.log('city');
    console.log(city);
    console.log('state');
    console.log(state);
    console.log('type');
    console.log(type);

    setDetails({ ...details, ['artist_type']: type });
  };
  const splitValue = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[0];
  };
  const splitValue2 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[1];
  };
  return (
    <div>
      <h1>Top {artist_type} Dancers By Location</h1>
      {!visible && (
        <form onSubmit={onSubmit2}>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formBasicSelect">
                <Form.Label>State</Form.Label>
                <Form.Control
                  id="state"
                  name="state"
                  as="select"
                  value={state}
                  onChange={(e) => {
                    // setDetails({
                    //   ...details,
                    //   [e.target.name]: e.target.value,
                    // });
                    console.log(' e.target.value');
                    console.log(e.target.value);
                    // setSelectedState(e.target.value);
                    let cities = selectedCities(e.target.value);
                    // setSelectedCity(cities[0]);
                    console.log('details..city filled?');
                    console.log(details);

                    //run filter from backend
                    console.log(
                      `city is ${cities[0]} | state is ${
                        e.target.value
                      }  | artist_type isbachata`
                    );
                    getTopDancersByLocation(
                      cities[0],
                      e.target.value,
                      'bachata'
                    );
                  }}
                >
                  {listOfStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group controlId="formBasicSelect">
                <Form.Label>City</Form.Label>
                <Form.Control
                  id="city"
                  name="city"
                  as="select"
                  value={city}
                  required
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      [e.target.name]: e.target.value,
                    });
                    // setSelectedCity();
                    console.log(details);

                    //run filter from backend
                    //getTopDancers
                    // getTopDancersByLocation('charlotte', 'nc', 'bachata');
                    getTopDancersByLocation(e.target.value, state, 'bachata');
                  }}
                >
                  {listOfCitiesForSelectedState.map((city) => (
                    <option value={city}>{city}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="row">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={artist_type}
                  onSelect={handleArtistTypeChange}
                  // onSelect={() => {
                  //   console.log(idx);
                  //   handleArtistTypeChange();
                  // }}
                  // disabled="true"

                  // onClick={() => {
                  //   setLevelButtonClicked(!levelButtonClicked);
                  // }}
                  //   onSelect={handleSelectLevel}
                >
                  {artist_type_array.map((type) => (
                    <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
                  ))}
                </DropdownType>
              ))}
            </div>
          </div>
        </form>
      )}
      <div>
        <button onClick={() => setVisible(!visible)} class="btn btn-secondary">
          {visible ? 'Cancel' : 'Add Dancer'}
        </button>
        {visible && (
          <div>
            <form onSubmit={onSubmitDancer}>
              <input
                id="first_name"
                type="text"
                name="first_name"
                placeholder="First Name"
                value={first_name}
                onChange={onChange}
                required
              />
              <input
                id="last_name"
                type="text"
                name="last_name"
                placeholder="Last Name"
                value={last_name}
                onChange={onChange}
                required
              />
              <Form.Group controlId="formBasicSelect">
                <Form.Label>State</Form.Label>
                <Form.Control
                  id="state"
                  name="state"
                  as="select"
                  value={state}
                  onChange={(e) => {
                    // setDetails({
                    //   ...details,
                    //   [e.target.name]: e.target.value,
                    // });
                    console.log(' e.target.value');
                    console.log(e.target.value);
                    // setSelectedState(e.target.value);
                    let cities = selectedCities(e.target.value);
                    // setSelectedCity(cities[0]);
                    console.log('details..city filled?');
                    console.log(details);
                  }}
                >
                  {listOfStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicSelect">
                <Form.Label>City</Form.Label>
                <Form.Control
                  id="city"
                  name="city"
                  as="select"
                  value={city}
                  required
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      [e.target.name]: e.target.value,
                    });
                    // setSelectedCity();
                    console.log(details);
                  }}
                >
                  {listOfCitiesForSelectedState.map((city) => (
                    <option value={city}>{city}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formBasicSelect">
                {!addDancerFieldsVisible && (
                  <Form.Label>Select Studio</Form.Label>
                )}
                <div className="row">
                  {!addDancerFieldsVisible && (
                    <div className="col">
                      <Form.Control
                        id="existing_studio_name"
                        name="existing_studio_name"
                        as="select"
                        value={existing_studio_name}
                        required
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            [e.target.name]: e.target.value,
                            // [e.target.name]: splitValue(e),
                            ['existing_studio_id']: splitValue2(e),
                          });
                          // setSelectedCity();
                          console.log('e is');
                          console.log(e);
                          console.log(details);
                        }}
                      >
                        {allStudios?.map((target_studio) => (
                          <option
                            value={target_studio.name + '/' + target_studio._id}
                            // value="boom"
                            // key={target_studio._id}
                            myKey={target_studio._id}
                          >
                            {target_studio.name}
                          </option>
                        ))}
                      </Form.Control>
                      <br />
                    </div>
                  )}
                  <div className="row">
                    <label htmlFor="">Artist Type</label>
                    {[DropdownButton].map((DropdownType, idx) => (
                      <DropdownType
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop-${idx}`}
                        size="sm"
                        variant="secondary"
                        title={artist_type}
                        onSelect={handleArtistTypeChangeNoReload}
                        // onSelect={() => {
                        //   console.log(idx);
                        //   handleArtistTypeChange();
                        // }}
                        // disabled="true"

                        // onClick={() => {
                        //   setLevelButtonClicked(!levelButtonClicked);
                        // }}
                        //   onSelect={handleSelectLevel}
                      >
                        {artist_type_array.map((type) => (
                          <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
                        ))}
                      </DropdownType>
                    ))}
                  </div>

                  {user ? (
                    <div className="col">
                      <br />
                      {/* <Button onClick={()=}></Button> */}

                      <Button
                        onClick={() =>
                          setAddDancerFieldsVisible(!addDancerFieldsVisible)
                        }
                        class="btn btn-secondary"
                      >
                        {addDancerFieldsVisible ? 'cancel' : 'add new studio'}
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {addDancerFieldsVisible && (
                    // <div className="row">
                    <div className="form-container">
                      <h1>
                        {/* Event <span className="text-primary">Details</span> */}
                      </h1>
                      <form onSubmit={onSubmit3}>
                        <div className="form-group">
                          <input
                            id="newStudioName"
                            type="text"
                            name="newStudioName"
                            placeholder="Dance Company Name"
                            value={newStudioName}
                            onChange={onChange}
                            required
                          />

                          {/* venue and city */}

                          {/* <input
                  id=""
                  type="date"
                  name=""
                  placeholder="Start Date"
                  value={}
                  onChange={onChange}
                  required
                /> */}

                          <Form.Group controlId="formBasicSelect">
                            <Form.Label>State</Form.Label>
                            <Form.Control
                              id="state"
                              name="state"
                              as="select"
                              value={state}
                              onChange={(e) => {
                                // setDetails({
                                //   ...details,
                                //   [e.target.name]: e.target.value,
                                // });
                                console.log(' e.target.value');
                                console.log(e.target.value);
                                // setSelectedState(e.target.value);
                                let cities = selectedCities(e.target.value);
                                // setSelectedCity(cities[0]);
                                console.log('details..city filled?');
                                console.log(details);
                              }}
                            >
                              {listOfStates.map((state) => (
                                <option value={state}>{state}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="formBasicSelect">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              id="city"
                              name="city"
                              as="select"
                              value={city}
                              required
                              onChange={(e) => {
                                setDetails({
                                  ...details,
                                  [e.target.name]: e.target.value,
                                });
                                // setSelectedCity();
                                console.log(details);
                              }}
                            >
                              {listOfCitiesForSelectedState.map((city) => (
                                <option value={city}>{city}</option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </div>

                        {/* <input
                        type="submit"
                        value="Create Event"
                        // className="btn btn-primary btn-block"
                        class="btn btn-secondary"
                        // onClick={onSubmit2}
                      /> */}
                      </form>
                    </div>
                    // </div>
                  )}
                </div>
              </Form.Group>

              {/* <div className="row">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={artist_type}
                  onSelect={handleArtistTypeChangeNoReload}
                  // onSelect={() => {
                  //   console.log(idx);
                  //   handleArtistTypeChange();
                  // }}
                  // disabled="true"

                  // onClick={() => {
                  //   setLevelButtonClicked(!levelButtonClicked);
                  // }}
                  //   onSelect={handleSelectLevel}
                >
                  {artist_type_array.map((type) => (
                    <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
                  ))}
                </DropdownType>
              ))}
            </div> */}
              {/* <input
              type="submit"
              value="Add Dancer"
              // className="btn btn-primary btn-block"
              class="btn btn-secondary"
            /> */}

              <input
                type="submit"
                value={user ? 'Submit' : 'Sign in to add dancer'}
                // className="btn btn-primary btn-block"
                class="btn btn-secondary"
                disabled={user ? false : true}
                // onClick={onSubmit2}
              />
            </form>
            {!user ? (
              <Link to="/login">
                <a href="/login" style={{ color: '#0d6efd' }}>
                  Sign In
                  <hr />
                </a>
              </Link>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>

      {!visible &&
        topDancersInLocation.map((dancer, index) => (
          <Dancer dancer={dancer} key={dancer._id} index={index} />

          // <div>
          //   <h5>
          //     <hr />
          //     <div className="row">
          //       <div className="col-2">{index + 1}</div>
          //       <div className="col">
          //         {dancer.first_name} {dancer.last_name}
          //       </div>

          //       <div className="col">
          //         <Button
          //           onClick={() => {
          //             handleLike(dancer._id);
          //             console.log(dancer._id);
          //           }}
          //           disabled={dancer.likes.includes(user?._id)}
          //         >
          //           {dancer.likes.includes(user?._id) ? liked : notLiked}{' '}
          //         </Button>

          //         {dancer?.likeCount}
          //       </div>
          //     </div>
          //   </h5>
          // </div>
        ))}
      {/* {topDancersInLocation.map((dancer) => (
        <TopDancerItem key={dancer._id} dancer={dancer} user={user} />
      ))} */}
    </div>
  );
};
export default TopDancer;
