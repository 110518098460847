// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { useAuth } from '../../context/auth/AuthState';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import Layout from '../advertise/Layout';
import CheckoutForm from '../advertise/CheckoutForm';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import SubEventContext from '../../context/subevent/subEventContext';
import SubEvent from './SubEvent';
import Vendor from './Vendor';
// import { useHistory } from 'react-router-dom';

const DJRequest = (props) => {
  let { dj } = props;
  let reverseOrderSongs = [];
  reverseOrderSongs = [...dj?.songRequests].reverse();
  // console.log('reverseOrderSongs before rever');
  // console.log(reverseOrderSongs);
  // console.log('reverseOrderSongs after rever');
  // console.log([...reverseOrderSongs].reverse());

  let navigate = useNavigate();

  const eventContext = useContext(EventContext);
  const {
    joinEventAsVendor,
    getVendorsForEvent,
    vendors,
    addSongRequest,
  } = eventContext;

  const { event } = props;
  const [authState] = useAuth();

  const { user } = authState;

  useEffect(() => {
    console.log('event from props');
    console.log(event);
    console.log('dj from props');
    console.log(dj);
    console.log(
      'getting events from DJRequest.js but need to change component name'
    );
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    // getVendorsForEvent(event._id);

    //getSongRequestsForDJ(dj_id,event_id)

    // if (user) {
    //   setDetails({
    //     ...details,

    //     requestor_id: user?.name,
    //     requested_dj_id: dj?.dj._id,
    //     event_id: event?._id,
    //   });
    // }
    console.log('details after useeffect');
    console.log(details);

    // eslint-disable-next-line
  }, []);
  const [visible, setVisible] = React.useState(false);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [details, setDetails] = useState({
    // requestor_name: {user ? `${user.first_name}` :""},
    requestor_id: '',
    song_title: '',
    requested_dj_id: '',
    event_id: '',
    dj_verified: false,
    image_url: user?.image?.url,

    // sub_event_name: '',
    // descr: '',
    // sub_event_image: '',
  });
  const {
    requestor_name,
    requestor_id,
    song_title,
    requested_dj_id,
    event_id,
    dj_verified,

    sub_event_name,
    descr,
    sub_event_image,
  } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });
  const [file, setFile] = useState();
  const [buttonText, setButtonText] = useState('Join Vendors');

  const onSubmit2 = (e) => {
    e.preventDefault();
    if (descr === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      const data = new FormData();
      data.append('file', file);
      data.append('descr', descr);
      data.append('sub_event_name', sub_event_name);
      data.append('image', sub_event_image);
      data.append('eventId', event._id);
      data.append('hostId', user._id);
      console.log('add subevent hit');
      // addSubEventForEvent(event._id, sub_event_name, descr, user._id);
      // addSubEventForEvent(data);
    }
  };

  const nouser = (
    <Fragment>
      <Link to="/login">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          sign in to join vendors/exhibitors
        </button>
      </Link>
    </Fragment>
  );
  const [show, setShow] = useState(false);

  const handleClose = () => {
    console.log('closing ');
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    console.log(`show is: ${show}`);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving from frontend');
  };

  const handleJoin = () => {
    joinEventAsVendor(user._id, event._id);
    setButtonText('Joined');
    window.location.reload();
  };

  let djs_and_requests = [{ dj_name: 'dj sabroso' }, { dj_name: 'dj six' }];

  // let requests = [
  //   { requestor_name: 'dafne', song_name: 'quiere beber' },
  //   { requestor_name: 'courtney', song_name: 'vivir mi vida' },
  //   { requestor_name: 'claire', song_name: 'quien mas' },
  // ];

  const addRequest = (e) => {
    e.preventDefault();
    console.log('details');
    console.log(details);
    addSongRequest(details);
  };
  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  return (
    <div>
      {/* {renderOptions()} */}
      <div>
        {/* {djs_and_requests.map((dj) => ( */}
        <div className="row">
          <div className="col-2">
            <img
              className="rounded-circle"
              //   src={profile.user.avatar}
              src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
              // src={image}
              // src={user.image ? user.image.url : imgg}
              alt=""
              height="50"
            />
          </div>
          <div className="col">
            <div>{dj.dj?.dj_name}</div>
            <div>
              {/* <Button>Song Requests</Button> */}
              <Button
                onClick={() => {
                  setVisible(!visible);
                  if (user) {
                    setDetails({
                      ...details,

                      requestor_id: user?._id,
                      requestor_name: user?.name,
                      requested_dj_id: dj?.dj._id,
                      event_id: event?._id,
                    });
                  }
                  console.log('details --');
                  console.log(details);
                }}
                class="btn btn-secondary"
              >
                {visible ? 'Close' : 'Request Song'}
              </Button>
            </div>
          </div>
        </div>
        {/* // ))} */}
      </div>

      {visible && (
        <div className="form-container">
          {/* <form onSubmit={onSubmit2}> */}
          <div className="form-group">
            <h5>Requests</h5>

            <div>
              <form onSubmit={addRequest}>
                <label class="form-check-label" for={'first_name'}>
                  Song Title
                </label>
                <input
                  id="song_title"
                  name="song_title" // as="text" type="text"
                  value={song_title}
                  required
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      [e.target.name]: e.target.value,
                      //   ['existing_studio_id']: splitValue2(e),
                    });
                    // setSelectedCity();
                    console.log('e is');
                    console.log(e);
                    console.log(details);
                  }}
                />

                <input
                  type="submit"
                  value={user ? 'Submit Request' : 'Sign in to Request'}
                  // className="btn btn-primary btn-block"
                  class="btn btn-secondary"
                  disabled={user ? false : true}
                  // onClick={onSubmit2}
                />
              </form>

              {!user ? (
                <Link to="/login">
                  <a href="/login" style={{ color: '#0d6efd' }}>
                    Sign In
                    <hr />
                  </a>
                </Link>
              ) : (
                <></>
              )}
            </div>
            {/* 
            <div className="row">
              <div className="col">
                <label class="form-check-label" for={'first_name'} />
              </div>
              <div className="col">
                <label class="form-check-label" for={'first_name'}>
                  User
                </label>
              </div>

              <div className="col">
                <label class="form-check-label" for={'first_name'}>
                  Song
                </label>
              </div>
              <div className="col">
                <label class="form-check-label" for={'first_name'}>
                  Song Title
                </label>
              </div>
            </div> */}
            {/* {dj?.songRequests.map((request) => ( */}
            {reverseOrderSongs.map((request) => (
              <div className="row">
                <div className="col-2">
                  <img
                    className="rounded-circle"
                    //   src={profile.user.avatar}
                    src={request?.image_url ? request.image_url : imgg}
                    // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                    // src={image}
                    alt=""
                    height="25"
                  />
                </div>
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <h5>{request.requestor_name}</h5>
                      {/* </div> */}

                      {/* <div className="col"> */}
                      <h5 style={{ color: '#0d6efd' }}>{request.song_title}</h5>
                    </div>
                  </div>
                </div>
                {/* <div className="col">
                  <h5>coming up 👌🏾</h5>
                </div> */}
                <hr />
              </div>
            ))}
          </div>

          {/* <input type="submit" value="Create Event" class="btn btn-secondary" /> */}
          {/* </form> */}
          <br />
        </div>
      )}

      {/* {!user ? (
        // `sign in to join vendors`

        <Link to="/login">
          <button
            type="button"
            className="btn btn-dark"

            // onClick={join_event}
          >
            sign in to join vendors
          </button>
        </Link>
      ) : (
        <Button variant="primary" onClick={handleJoin}>
          {buttonText}
        </Button>
      )} */}
      {/* {vendors.map((vendor) => (
        <Vendor key={vendor._id} vendor={vendor} />
      ))} */}
    </div>
  );
};
export default DJRequest;
