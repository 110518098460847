import React, { Component, useContext, useEffect, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import TourContext from '../../context/tour/tourContext';
import DateTest from './DateTest';

const SelectDanceCompanyForTourStop = (props) => {
  const {
    setLocationList,
    locations,
    tourStops,
    setTourStops,
    date,
    datex,
  } = props;
  let tourContext = useContext(TourContext);
  let { studiosInState, getStudiosInState } = tourContext;

  useEffect(() => {
    getStudiosInState(props.stateToSearch);

    // eslint-disable-next-line
  }, []);
  // [studiosInState, getStudiosInState, props.stateToSearch]

  const [locationDetails, setLocationDetails] = useState({
    company_id: '',
    company_name: '', //load each state's dance companies in ascd order
    city: 'none',
    state: props.stateToSearch,
    country: 'United States',
    status: 'pending',
  });

  const { company_name } = locationDetails;
  //   const studios = ['studio1', 'studio2', 'studio3'];
  const studios = studiosInState;
  const [companySelected, setCompanySelected] = useState(false);
  //   const handleStudioChange = (name) => {
  const handleStudioChange = (obj) => {
    setCompanySelected(true);
    // if (date === undefined) {
    //   console.log('pick a date');
    // } else {
    const name = obj.split(',')[0];
    const id = obj.split(',')[1];
    const city = obj.split(',')[2];

    console.log('date from publish');
    console.log(date);
    setLocationDetails({
      ...locationDetails,
      company_name: name,
      company_id: id,
      city: city,
      // date: date,
      date: newDate,
    });

    //do not uncomment
    // setLocationList([...locations, locationDetails]);

    setTourStops([
      ...tourStops,
      {
        ...locationDetails,
        company_name: name,
        company_id: id,
        city: city,
        // date: date,
        date: newDate,
        // date: datex,
      },
    ]);

    console.log('locations are:');
    console.log(locations);
    // }
    //set / update parent array obj for tours
  };
  const [newDate, setNewDate] = useState();

  // const setNewDateFromChildAndUpdateTourStop(date,company)

  return (
    // <div>
    <>
      <td>
        {newDate === undefined ? (
          <>
            <Button
              style={{
                fontSize: '12px',
                padding: '4px',
                backgroundColor: 'GrayText',
              }}
            >
              select date first
            </Button>
          </>
        ) : (
          <div
            style={{
              width: '120px',
              overflow: companySelected ? 'clip' : 'visible',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {[DropdownButton].map((DropdownType, idx) => (
              <DropdownType
                // className="title-width"
                as={ButtonGroup}
                key={idx}
                id={`dropdown-button-drop-${idx}`}
                size="lg"
                variant="success"
                title={company_name === '' ? 'select company' : company_name}
                //   onSelect={() => handleStudioChange(company_name, 'test')}
                onSelect={handleStudioChange}
                onClick={() => {
                  console.log('props.stateToSearch');
                  console.log(props.stateToSearch);
                  getStudiosInState(props.stateToSearch);
                  if (companySelected) {
                    setCompanySelected(false);
                  }
                }}
                placeholder="select a company"
                // style={{ width: '120px' }}
              >
                {studiosInState.map((studio, index) => (
                  <Dropdown.Item
                    // className="title-width"
                    style={{ width: '200px' }}
                    eventKey={[studio.name, studio._id, studio.city]}
                  >
                    <div>
                      <Button
                        // className="title-width"
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          borderStyle: 'solid',
                          borderColor: 'black',
                          fontSize: '12px',
                          maxWidth: '180px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          // width: '40px',
                        }}
                      >
                        {studio.name}
                      </Button>
                    </div>
                  </Dropdown.Item>
                ))}
              </DropdownType>
            ))}
          </div>
        )}
      </td>
      <td>
        <DateTest
          // date={date}
          // setDate2={setDate2}
          // setDateFromChild={setDateFromChild}
          // setDateX={setDateX}
          setNewDate={setNewDate}
        />
      </td>
    </>

    // {/* </div> */}
  );
};
export default SelectDanceCompanyForTourStop;
