import React, { Component, useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useAuth } from '../../context/auth/AuthState';
import StudioContext from '../../context/studio/studioContext';
import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';
import StudioSelectedDisplayRoles from '../../onboarding/instructor-onboard/StudioSelectedDisplayRoles';
const AddStudio = (props) => {
  const studioContext = useContext(StudioContext);

  const {
    addNewStudio,
    newStudio,
    loading,
    joinStudioFromOnboard,
  } = studioContext;

  const [authState] = useAuth();
  const { user } = authState;

  const [details, setDetails] = useState({
    name: '',
    state: 'North Carolina',
    city: 'Charlotte', //default to user.city or first city from list
    owner_flag: false,
    owner_id: '',
    image: '',

    //add img
  });

  const onOwnerChange = () => {
    setDetails({
      ...details,
      owner_flag: !owner_flag,
      owner_id: user?._id,
      //   ['existing_studio_id']: splitValue2(e),
    });

    console.log(details);
  };
  const [showDanceDetailFields, setShowDanceDetailFields] = useState(false);

  const handleSave = () => {
    // console.log('selectedStudio');
    // console.log(selectedStudio.name);
    // console.log(selectedStudio._id);
    // console.log('roles');
    // console.log(roles);
    // console.log('primaryStyle');
    // console.log(primaryStyle);
    // console.log('secondaryStyles');
    // console.log(secondaryStyles);
    let obj = {
      studio_id: newStudioCreated ? newStudio._id : selectedStudio._id,
      roles: roles,
      primary_style: primaryStyle,
      secondary_styles: secondaryStyles,
      user_id: user?._id,
    };
    console.log('obj');
    console.log(obj);
    setStudioSaved(true);
    props.setStudioOnboardAlsoCompleted(true);
    joinStudioFromOnboard(obj);
  };

  const handleAddStudioAndSetState = (e) => {
    e.preventDefault();
    addStudio();
    runAfterAddStudio();
  };
  const addStudio = () => {
    // e.preventDefault();

    const data = new FormData();

    data.append('name', name);
    data.append('state', state);
    data.append('city', city);
    data.append('owner_flag', owner_flag);
    data.append('owner_id', owner_id);
    data.append('image', image);
    data.append('file', file); //image

    console.log('first func');
    console.log('submitted');
    console.log('details');
    console.log(details);
    // addNewStudio(details);
    addNewStudio(data);
    // setShowDanceDetailFields(true);
    // setSelectedStudio(newStudio);
    return 'success';
  };
  const [newStudioCreated, setNewStudioCreated] = useState(false);

  const runAfterAddStudio = () => {
    // e.preventDefault();

    console.log('second func');
    console.log('runAfterAddStudio');
    setSelectedStudio(newStudio);
    setShowDanceDetailFields(true);
    setNewStudioCreated(true);
  };

  const { name, city, state, owner_flag, owner_id, image } = details;

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;

  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);

  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };
  const [selectedStudio, setSelectedStudio] = useState({});
  const [primaryStyle, setPrimaryStyle] = useState('bachata');
  const [secondaryStyles, setSecondaryStyles] = useState([]);
  const [roles, setRoles] = useState(['instructor']);

  const [studioSaved, setStudioSaved] = useState(false);
  const [file, setFile] = useState('');

  if (loading) return <h5>loading</h5>;
  return (
    <div>
      <div className="row">
        {/* {addDancerFieldsVisible && ( */}
        <div className="col">
          <label class="form-check-label" for={'name'}>
            Studio Name
          </label>
          <form
            onSubmit={handleAddStudioAndSetState}
            // onSubmit={addStudio}
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   addStudio();
            //   runAfterAddStudio();
            // }}
          >
            <input
              id="name"
              name="name"
              value={name}
              required
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.name]: e.target.value,
                });
              }}
            />
            <br />
            <label class="form-check-label" for={'name'}>
              Studio Image
            </label>
            <br />
            <input
              id="image"
              name="image"
              type="file"
              value={image}
              onChange={(event) => {
                const file = event?.target?.files[0];
                setFile(file);
                setDetails({
                  ...details,
                  [event.target.name]: event.target.value,
                });
              }}
            />

            <Form.Group controlId="formBasicSelect">
              <Form.Label>State</Form.Label>
              <Form.Control
                id="state"
                name="state"
                as="select"
                value={state}
                onChange={(e) => {
                  let cities = selectedCities(e.target.value);

                  console.log('details..city filled?');
                  console.log(details);
                }}
              >
                {listOfStates.map((state) => (
                  <option value={state}>{state}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formBasicSelect">
              <Form.Label>City</Form.Label>
              <Form.Control
                id="city"
                name="city"
                as="select"
                value={city}
                required
                onChange={(e) => {
                  setDetails({
                    ...details,
                    [e.target.name]: e.target.value,
                  });
                  console.log(details);
                }}
              >
                {listOfCitiesForSelectedState.map((city) => (
                  <option value={city}>{city}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <div class="form-check">
              <input
                id="owner"
                type="checkbox"
                name="owner"
                value={'owner'}
                onChange={onOwnerChange}
              />

              <label for="owner">
                {' '}
                Are you one of the owners of this studio
              </label>
            </div>

            <input type="submit" value="Add Studio" class="btn btn-secondary" />
          </form>

          <br />
        </div>
        {/* )} */}
      </div>
      {showDanceDetailFields && (
        <StudioSelectedDisplayRoles
          selectedStudio={selectedStudio}
          setRoles={setRoles}
          roles={roles}
          setPrimaryStyle={setPrimaryStyle}
          primaryStyle={primaryStyle}
          setSecondaryStyles={setSecondaryStyles}
          secondaryStyles={secondaryStyles}
        />
      )}

      {showDanceDetailFields &&
        !studioSaved && (
          <div>
            <Button
              style={{ marginTop: '14px' }}
              onClick={() => {
                console.log('newStudio');
                console.log(newStudio);
                handleSave();
                // if (!newStudioCreated) {
                //   // handleSave();
                // } else {
                //   console.log('handleSaveWhenNewStudioCreated');
                //   // handleSaveWhenNewStudioCreated()
                // }
              }}
            >
              Save
            </Button>
          </div>
        )}

      {studioSaved && (
        <div
          style={{
            backgroundColor: 'lightgreen',
            borderWidth: '1px',
            borderRadius: '10px',
            color: '#65a765',
            margin: '10px',
            borderColor: '#65a765',
            borderStyle: 'solid',
          }}
        >
          <h5>Studio Saved, Continue your onboarding!</h5>
        </div>
      )}
    </div>
  );
};

export default AddStudio;
