import React, { Component, useContext, useState } from 'react';
import WorkshopDetailPopup from './WorkshopDetailPopup';
import { useAuth } from '../context/auth/AuthState';
import { useParams } from 'react-router-dom';
import eventContext from '../context/event/eventContext'; // might have to capitalize

const WorkshopDetails = (props) => {
  const [authState] = useAuth();
  const { user } = authState;
  const eContext = useContext(eventContext);
  const {
    getWorkshopScheduleNewStructure,
    // getWorkshop
  } = eContext;
  const returnColorBasedOnLevel = (workshopLevel) => {
    switch (workshopLevel) {
      case 'Beginner':
        return '#90EE90';
      case 'Intermediate':
        return 'orange';
      case 'Advanced':
        return 'red';

      default:
        return 'black';
    }
  };
  const { workshop } = props;
  const [workshopClicked, setWorkshopClicked] = useState(false);
  console.log('workshop here ---');
  console.log(workshop);

  const joinedWorkshop = (workshop) => {
    let attendees = workshop.attendees;
    for (let i = 0; i < attendees.length; i++) {
      if (attendees[i]._id === user?._id) {
        console.log('already joined b');
        return true;
      }
    }
    console.log('no join');
    return false;
  };
  let { id } = useParams();

  const reloadWorkshopsOnJoin = () => {
    console.log('reloading workshops');
    getWorkshopScheduleNewStructure(id);
    // getWorkshopSchedule(event._id);
  };
  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  return (
    <div>
      <div className="row">
        <div
          className="col short-vertical-line"
          style={{ color: returnColorBasedOnLevel(workshop.level) }}
        />

        <div className="col-4" style={{ width: '180px' }}>
          <div className="row">
            <h5>{workshop.time}</h5>
          </div>

          <div className="row">
            <h5
              onClick={() => setWorkshopClicked(!workshopClicked)}
              style={{
                // border: '1px',

                // borderRadius: '0.2rem',
                // borderStyle: 'solid',
                background: 'white',
                // color: 'blue',
                color: 'rgb(1, 126, 252)',

                // borderColor: returnColorBasedOnLevel(workshop.level),
                textAlign: 'left',
                maxWidth: '300px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {workshop.title}
            </h5>
          </div>
          <div className="row" style={{ fontSize: '14px' }}>
            👤 {workshop.instructor_name}
          </div>
        </div>
        <div className="col" style={{ width: '100px' }}>
          <img
            className="rounded-circle "
            src={workshop.instructor_image ? workshop?.instructor_image : imgg}
            // height="100"
            // width="100"
            alt="user"
            style={{
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '1px',
              borderRadius: '2px',
              // postion: 'absolute',
              // right: '0px',
              paddingRight: '0px',
              height: '75px',
              width: '75px',
              maxWidth: 'none',
              display: 'block',
              marginLeft: 'auto',
            }}
          />
        </div>

        {/* 
        <div className="col">
          <h5
            onClick={() => setWorkshopClicked(!workshopClicked)}
            style={{
              border: '1px',
              // backgroundColor: 'purple',
              borderRadius: '0.2rem',
              borderStyle: 'solid',
              background: 'white',
              color: 'black',
              borderColor: returnColorBasedOnLevel(workshop.level),
              textAlign: 'center',
            }}
          >
            {workshop.title}
          </h5>

        </div>
           */}

        {workshopClicked && (
          <WorkshopDetailPopup
            workshopClicked={workshopClicked}
            workshop={workshop}
            attendee_count={
              workshop?.attendees.length > 0 ? workshop.attendees.length : 0
            }
            alreadyJoined={joinedWorkshop(workshop)}
            showJoinedButton={true}
            onJoin={reloadWorkshopsOnJoin}
          />
        )}
      </div>
      <br />
    </div>
  );
};
export default WorkshopDetails;
