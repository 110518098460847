import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';

const DancerProfile = (props) => {
  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;
  const userContext = useContext(UserContext);

  const { connectToUser } = userContext;
  let copyClicked = false;
  const location = useLocation();
  const { user } = location.state;

  // const { user, current_user_id, isCurrentUser } = props;

  useEffect(() => {
    console.log('user from useeffect');
    console.log(user);
    //   alreadyConnected();
    console.log('current_user_id');
    //   console.log(current_user_id);
    //   console.log('isCurrentUserProfile');
    //   console.log(props.isCurrentUser);
    // eslint-disable-next-line
  }, []);

  const [shareButtonText, setShareButtonText] = React.useState('Share Profile');

  // const isNotConnected = () => {
  //   return (
  //     typeof current_user_id !== 'undefined' &&
  //     !alreadyConnected() &&
  //     !isCurrentUser
  //   );
  // };
  // const [connectButtonText, setConnectButtonText] = useState(
  //   isNotConnected ? `Connect` : `Connected`
  // );

  const connectToUserFunction = (e) => {
    e.preventDefault();

    let obj = {
      userIdToConnectTo: user._id,
      // current_user_id: current_user_id,
    };
    console.log(obj);
    //   setConnectButtonText('Connected');

    // let notification_obj = {
    //   current_user_id: user._id,
    //   // eventId: roomie_request.event,
    //   accepted_user_id: roomie_request.requestor,
    //   //will also need accepting users name
    // };

    connectToUser(obj);
    // sendSMSAlertForAcceptedRoommate(notification_obj);
    console.log('success????');
  };

  // const alreadyConnected = () => {
  //   return user.connections?.includes(current_user_id);
  // };

  // const { profile } = this.props;
  let image;
  if (user?.image) {
    image = user?.image.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }

  const notClicked = (
    <Fragment>
      <Button
        variant="primary"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          console.log('copied text');
          copyClicked = true;
          setShareButtonText('copied');
        }}
      >
        {shareButtonText}
      </Button>
    </Fragment>
  );
  const clicked = (
    <Fragment>
      <div className="col-3">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          Profile Link Copied
        </button>
      </div>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!copyClicked) {
      console.log('no user found');
      return notClicked;
    } else {
      return clicked;
    }
  };

  // const usersNotConnected = (
  //   <Fragment>
  //     <div className="col-3">
  //       <button
  //         type="button"
  //         // className="btn btn-dark"
  //         className="btn btn-primary my-2 my-sm-0"
  //         onClick={connectToUserFunction}
  //         disabled={connectButtonText === 'Connected'}
  //       >
  //         {connectButtonText}
  //       </button>
  //     </div>
  //   </Fragment>
  // );

  // const connected = () => {
  //   if (
  //     typeof current_user_id !== 'undefined' &&
  //     !alreadyConnected() &&
  //     !isCurrentUser
  //   ) {
  //     return usersNotConnected;
  //   } else {
  //     return <></>;
  //   }
  // };

  const [disabled, setDisabled] = useState(false);
  const [likeCount, setLikeCount] = React.useState(
    user.likeCount ? user.likeCount : 0
  );

  const handleLike = async (liked_user_id) => {
    if (user) {
      //like user & disable like button
      let obj = {
        likedUserId: liked_user_id,
        likingUserId: user?._id,
        // eventId: roomie_request.event,
        // accepted_user_id: roomie_request.requestor,
      };
      console.log('obj is');
      console.log(obj);
      likeDancer(obj);
      setDisabled(true);
    } else {
      console.log('alert user to log in to like and put login button in box');
    }
  };

  let notLiked = '🤍';
  let liked = '💙';
  const [likedBtn, setLikedBtn] = useState(notLiked);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-body bg-primary text-white mb-3">
          <div className="row text-center">
            <div className="col col-md-3 m-auto">
              <img
                className="rounded-circle"
                //   src={profile.user.avatar}
                // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                src={image}
                alt=""
                height="150"
                width="150"
              />
            </div>

            <h3 className="display-4 text-center">
              {user?.first_name + ' ' + user?.last_name}
            </h3>
            <div className="col">
              <Button
                onClick={() => {
                  handleLike(user._id);
                  setLikeCount(likeCount + 1);
                  setDisabled(true);
                  console.log(user._id);
                }}
                disabled={disabled || user.likes.includes(user?._id)}

                //   disabled={user.likes.includes(user?._id)}
              >
                {user.likes.includes(user?._id) ? liked : notLiked}{' '}
              </Button>

              {/* {dancer?.likeCount} */}
              {/* {dancer?.likeCount > 0 ? `${likeCount} likes` : ``} */}
              <label style={{ fontSize: '14px' }}>{likeCount} likes</label>
            </div>

            <h4>{user?.location}</h4>
            <Link to={`/my-connections`} state={{ target_user: user }}>
              {user?.connections?.length} Connections
            </Link>
            <div className="row">
              {/* {connected()} */}
              <div className="col">
                {/* <Button
                    variant="primary"
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      console.log('copied text');
                      copyClicked = true;
                    }}
                  >
                    Share Profile
                  </Button> */}
                {renderOptions()}
              </div>
              <div className="col">
                <Link to={`/studio/${user?.dance_company_id}`}>
                  <Button>{user?.dance_company_name}</Button>
                </Link>
              </div>
              {user?.instagram_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Instagram: {user?.instagram_name}
                </h5>
              ) : (
                <></>
              )}
              {user?.tiktok_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Tiktok: {user?.tiktok_name}
                </h5>
              ) : (
                <></>
              )}

              {user?.website_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Website: {user?.website_name}
                </h5>
              ) : (
                <></>
              )}

              {user?.website_name ||
              user?.tiktok_name ||
              user?.instagram_name ? (
                <hr />
              ) : (
                <></>
              )}
              <h5 style={{ fontSize: '16px' }}>{user?.about_you}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DancerProfile;
