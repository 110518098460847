import React, { Component, Fragment, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import WorkshopTest from '../../WorkshopTest';
import ReactGA from 'react-ga4';

const WorkshopScheduleListFormat = (props) => {
  // ReactGA.pageview(window.location.pathname + window.location.search);

  const [showWorkshopSchedule, setShowWorkshopSchedule] = useState(false);
  const handleShowWorkshopSchedule = () => {
    setShowWorkshopSchedule(!showWorkshopSchedule);
  };
  const [visible, setVisible] = React.useState(false);

  const renderStuff = (
    <Fragment>
      {!visible ? (
        <div>
          {/* <div className="row"> */}

          <WorkshopTest event={props.event} />

          {/* </div> */}
          <hr />
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );

  const workshopButton = (
    <React.Fragment>
      <div className="">
        <div className="">Workshops</div>
      </div>
    </React.Fragment>
  );

  const closeWorkshopButton = (
    <React.Fragment>
      <Button className="danger">close</Button>
    </React.Fragment>
  );
  return (
    <div>
      <div
        className="row"
        style={{ width: showWorkshopSchedule === true ? '340px' : '130px' }}
      >
        <div className="">
          <Button
            onClick={() => {
              handleShowWorkshopSchedule();

              ReactGA.event({
                category: 'Workshops',
                action: 'Workshop Button was clicked',
                label: 'workshop button',
              });
            }}
            style={{
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: showWorkshopSchedule ? 'red' : 'rgb(1, 126, 252)',

              fontSize: '14px',
              backgroundColor: showWorkshopSchedule ? 'red' : 'white',
              color: showWorkshopSchedule ? 'white' : 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '0px 12px',
              borderWidth: '1px',

              // width: '100px',
            }}
          >
            {/* {!showWorkshopSchedule ? workshopButton : `Close`} */}
            {!showWorkshopSchedule ? workshopButton : 'Close'}
          </Button>{' '}
        </div>
        <div className="col-1" style={{ width: '50px' }}>
          {/* <Badge bg="danger" style={{ width: '50px' }}>
            new
          </Badge> */}
        </div>
      </div>
      {/* <span class="badge badge-danger">New</span> */}
      {/* {<React.Fragment>
        working
        <Badge bg="danger">new</Badge>
      </React.Fragment>} */}
      {showWorkshopSchedule === true ? renderStuff : <></>}
    </div>
  );
};
export default WorkshopScheduleListFormat;
