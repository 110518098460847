import React, { Component, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import PerformanceReviewContext from '../../context/PerformanceReview/performanceReviewContext'; // might have to capitalize
import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';
// import { useAuth } from '../../context/auth/AuthState';
import Button from 'react-bootstrap/Button';
import LeaveReviewFields from './LeaveReviewFields';
import Review from './Review';
import PerformanceReviewsForGroupAndEvent from './PerformanceReviewsForGroupAndEvent';
import ReviewWasClicked from './ReviewWasClicked';

const ReviewCompanyPerformer = (props) => {
  const performanceReviewContext = useContext(PerformanceReviewContext);

  const {
    getPerformersForEvent,
    getReviewsForGroupForEvent,
    performance_reviews_for_group_and_event,
    getReviewsForCompanyForEvent,
    performance_reviews_for_company_and_event,
    performersForEvent,
  } = performanceReviewContext;

  useEffect(() => {
    console.log('props.event_id');
    console.log(props.event._id);
    // getPerformersForEvent(props.event.event_id);
    // getConnections(user?._id);
    // getUsersForSearchCriteria(searchText);
    // console.log('connections');
    // console.log(connections);
    // eslint-disable-next-line
  }, []);

  const [reviewClicked, setReviewClicked] = useState(false);
  const [previouslyClickedArtist, setPreviouslyClickedArtist] = useState(
    'none'
  );

  const reviewWasClicked = () => {
    setReviewClicked(!reviewClicked);
    // setPreviouslyClickedArtist;
    //load reviews
    //commented on july 24, 2023
    // getReviewsForGroupForEvent(props.performer.group_id, props.event._id);
    getReviewsForCompanyForEvent(props.performer.group_id, props.event._id);
  };
  console.log('props.performer in RCP');
  console.log(props.performer);
  return (
    <div>
      <div className="row">
        <div className="col-3">
          <Link
            to={`/studio/${props.performer.performer_id}`}
            style={{ color: 'black' }}
          >
            <h5>{props.performer.name}</h5>
          </Link>
        </div>
        <div className="col">
          <Button
            onClick={() => {
              setPreviouslyClickedArtist(props.performer.name);
              reviewWasClicked();
              console.log('previouslyClickedArtist');
              console.log(previouslyClickedArtist);
            }}
          >
            {reviewClicked ? ' close review' : 'leave review'}
          </Button>
        </div>
      </div>
      <br />
      {/* <div>
        {reviewClicked && (
          <div>
            <LeaveReviewFields
              performer={props.performer}
              event={props.event}
            />

            <div class="container-fluid  overflow-auto">
              <div class="d-flex flex-row flex-nowrap">
                {performance_reviews_for_group_and_event.map((review) => (
                  <div className="row">
                    <div class="card card-block mx-2">
                      <Review review={review} />
                    </div>
                  </div>
                ))}
              </div>

              {performance_reviews_for_group_and_event.length > 3 && (
                <h5>scroll right 🙂 {'--->'} </h5>
              )}
            </div>

         
            <hr />
          </div>
        )}
      </div> */}

      <div>
        <ReviewWasClicked
          key={Math.random() * 10}
          performer={props.performer}
          event={props.event}
          performance_reviews_for_group_and_event={
            performance_reviews_for_company_and_event
          }
          visible={reviewClicked}
          previouslyClickedArtist={previouslyClickedArtist}
        />
      </div>
    </div>
  );
};

export default ReviewCompanyPerformer;

/*



    <div class="container-fluid  overflow-auto">
            -- was commented out <div class="d-flex flex-row flex-nowrap">
              -- was commented out <div class="row flex-row flex-nowrap overflow-auto"> 
              <div class="d-flex flex-row flex-nowrap">
                {performance_reviews_for_group_and_event.map((review) => (
                  <div className="row">
                    ---- was commented out <div className="col">{review.reviewer_name}</div>
                    --- was commented out<div className="col">{review.stars_given}</div> 
                    <div class="card card-block mx-2">
                      <Review review={review} />
                    </div>
                  </div>
                ))}
              </div>

              {performance_reviews_for_group_and_event.length > 3 && (
                <h5>scroll right 🙂 {'--->'} </h5>
              )}
            </div>


*/
