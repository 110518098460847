import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import IncidentReportFields from './IncidentReportFields';

const FileIncidentReport = (props) => {
  const location = useLocation();
  const { event_name, event_id } = location?.state;
  return (
    <div style={{ padding: '10px' }}>
      <h4>File Incident Report</h4>
      <h5>
        Event: {event_name}
        <div style={{ paddingTop: '14px' }}>
          <IncidentReportFields
            // feedbackCategories={feedbackCategories}
            //   event={props.event}

            event_name={event_name}
            event_id={event_id}
            // handleFeedbackSubmitted={feedbackSubmitted}
          />
        </div>
      </h5>

      {/* fields here */}
    </div>
  );
};

export default FileIncidentReport;
