import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import React, { useState } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import userContext from '../../context/user/userContext'; // might have to capitalize
import Referral from './Referral';

const UserReferrals = (props) => {
  const [authState] = useAuth();
  const { user } = authState;
  useEffect(() => {
    // if (props?.user) {
    //   getUserReferrals(props?.user._id);
    // }
    if (Object.keys(user).length !== 0 && user.constructor === Object) {
      console.log('user from ur');
      console.log(user);
      console.log('tring new');
      getUserReferrals(user._id);
    }
    // eslint-disable-next-line
  }, []);

  const uContext = useContext(userContext);
  const {
    getUserReferrals,
    userReferrals,
    ambassadorRevenueShareAmount,
  } = uContext;

  const [show, setShow] = useState(false);
  console.log(`user name UserReferral is: ${user?.name}`);
  console.log(`user id UserReferral is: ${user?._id}`);
  const handleClose = () => {
    console.log('closing ');
    setShow(false);
  };
  const handleShow = () => setShow(true);

  //const { name } = user;

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    name: user?.name,
    dance_company: user?.dance_company,
    location: user?.location,
    about_you: user?.about_you,
    profile_image: '',
    instagram_name: user?.instagram_name,
    tiktok_name: user?.tiktok_name,
    website_name: user?.website_name,
  });

  const {
    name,
    dance_company,
    location,
    about_you,
    profile_image,
    instagram_name,
    tiktok_name,
    website_name,
  } = details;

  const user_id = user?._id;
  const updatedUser = {};
  updatedUser.user_id = user_id;
  // updatedUser.descr = descr;
  updatedUser.name = name;
  updatedUser.dance_company = dance_company;
  updatedUser.location = location;
  updatedUser.about_you = about_you;
  updatedUser.profile_image = profile_image;
  updatedUser.instagram_name = instagram_name;
  updatedUser.tiktok_name = tiktok_name;
  updatedUser.website_name = website_name;
  console.log('updatedUser is:');
  console.log(updatedUser);
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('saving updated user from frontend');

  //   if (details === '') {
  //     //   setAlert('Please fill in all fields', 'danger');
  //   } else {
  //     editProfile(updatedUser);
  //   }
  // };
  console.log(`user from edit is: ${user}`);
  console.log('done ---');
  const [shareButtonText, setShareButtonText] = React.useState(
    'Share Referral Link'
  );
  let copyClicked = false;

  let referralLink = '';

  if (window.location.port === '') {
    referralLink = `${window.location.hostname}/register/${user._id}`;
  } else {
    referralLink = `${window.location.hostname +
      ':' +
      window.location.port}/register/${user._id}`;
  }

  const notClicked = (
    <Fragment>
      <Button
        variant="primary"
        onClick={() => {
          navigator.clipboard.writeText(referralLink);
          console.log('copied text');
          copyClicked = true;
          setShareButtonText('copied');
        }}
      >
        {shareButtonText}
      </Button>
    </Fragment>
  );
  const clicked = (
    <Fragment>
      <div className="col-3">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          Profile Link Copied
        </button>
      </div>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!copyClicked) {
      console.log('no user found');
      return notClicked;
    } else {
      return clicked;
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
        style={{
          width: 'max-content',
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
        }}
      >
        User Referrals
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Referrals</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderOptions()}
          <div>
            <h5 style={{ color: 'green', marginTop: '8px' }}>
              Revenue Share Amount: ${ambassadorRevenueShareAmount}
            </h5>
            <h5 style={{ color: 'black', marginTop: '8px', fontSize: '14px' }}>
              (paid out at the end of each month)
            </h5>
            <h5>Number of Referred Users: {userReferrals.length}</h5>
          </div>
          {/* <button>share referral link</button>

          <Button
        variant="primary"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          console.log('copied text');
          copyClicked = true;
          setShareButtonText('copied');
        }}
      >
        {shareButtonText}
      </Button> */}
          <hr />

          <div>
            {userReferrals.map((referral) => (
              <Referral referral={referral} />
            ))}
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};
export default UserReferrals;

//   render(<Example />);
