import React, { useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { useAuth, clearErrors, register } from '../../context/auth/AuthState';
import AdvertisementContext from '../../context/advertisement/advertismentContext';
import StripeContainer from '../payment/StripeContainer';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

//new stripe tutorial imports
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import Row from './prebuilt/Row';
import DonutShop from './prebuilt/DonutShop';
import Layout from './Layout';

const AdvertiseNoRedirect = (props) => {
  const advertisementContext = useContext(AdvertisementContext);
  const { createAd, new_advertisement, createAdRequest } = advertisementContext;

  const alertContext = useContext(AlertContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;

  const { user } = authState;

  const { setAlert } = alertContext;
  const [advert, setAdvert] = useState({
    promotionText: '',
    adImage: '',
    // ownersEmail: '',
    // ownersId: '',
    // phoneNumber: '',
  });
  const [file, setFile] = useState();
  // const [location, setLocation] = useState();

  const { promotionText, ownersEmail, phoneNumber, adImage } = advert;
  let navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('file', file);
    data.append('promotionText', promotionText);
    data.append('ownersId', user._id);
    data.append('ownersEmail', user.email);
    data.append('phoneNumber', user.phoneNumber);
    data.append('adImage', adImage);

    if (promotionText === '' || ownersEmail === '' || phoneNumber === '') {
      setAlert('Please enter all fields', 'danger');
    } else {
      //   register(authDispatch, data);
      // createAd(data);
      // navigate('/payForAd', {
      //   state: {
      //     advert: advert,
      //     user: user,
      //     // promotionText: promotionText,
      //     file: file,
      //   },
      // });

      console.log('create Ad');
    }
  };
  const createAdvertisement = (e) => {
    console.log('running createAdvertisement() from front end line 66 ');
    // e.preventDefault();
    const data = new FormData();
    data.append('file', file);
    data.append('promotionText', promotionText);
    data.append('ownersId', user._id);
    data.append('ownersEmail', user.email);
    data.append('phoneNumber', user.phoneNumber);
    data.append('adImage', adImage);
    createAdRequest(data);
    // createAd(data);
    if (promotionText === '' || ownersEmail === '' || phoneNumber === '') {
      setAlert('Please enter all fields', 'danger');
    } else {
      //   register(authDispatch, data);
      // createAd(data);
      // navigate('/payForAd', {
      //   state: {
      //     advert: advert,
      //     user: user,
      //     // promotionText: promotionText,
      //     file: file,
      //   },
      // });

      console.log('createAd() running');
    }
  };
  const onChange = (e) =>
    setAdvert({ ...advert, [e.target.name]: e.target.value });
  return (
    <div>
      {/* <Event /> */}
      <div className="row">
        <div className="col">
          {/* <h3>Ads</h3> */}
          <div className="form-container">
            <h3>
              Promote Your <span className="text-primary">Business</span>
            </h3>
            {/* <h3>
              To All Of <span className="text-primary">InvestFest</span>
            </h3> */}

            {/* <div className="row"> */}
            {/* <div className="col-md-12">
              <div className="card card-body text-light bg-dark mb-3">
                <div className="row text-center">
                  <div className="col-4 col-md-3 m-auto">
                    <img
                      className="rounded-circle"
                      //   src={profile.user.avatar}
                      src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                      // src={image}
                      alt=""
                      height="200"
                    />
                  </div>
                  <h4 className="display-4 text-center">{promotionText}</h4>
                </div>
              </div>
            </div> */}
          </div>

          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="name">
                Short One Line Description For Your Audience
              </label>
              <br />
              {promotionText.length > 0 ? (
                <label style={{ color: 'red' }} htmlFor="">
                  {0}/{60 - promotionText.length} characters left
                </label>
              ) : (
                <></>
              )}
              <input
                id="promotionText"
                type="text"
                name="promotionText"
                value={promotionText}
                onChange={onChange}
                required
              />
            </div>
            <input
              id="adImage"
              name="adImage"
              type="file"
              value={adImage}
              onChange={(event) => {
                const file = event.target.files[0];
                setFile(file);
                setAdvert({
                  ...advert,
                  [event.target.name]: event.target.value,
                });
              }}
            />
            {/* <Form.Group controlId="formBasicSelect">
            <Form.Label>Location</Form.Label>
            <Form.Control
              id="location"
              name="location"
              as="select"
              value={location}
              onChange={(e) => {
                // console.log('e.target.value', e.target.value);
                // setLocation(e.target.value);

                setAdvert({ ...user, [e.target.name]: e.target.value });
              }}
            />
          </Form.Group> */}
            {/* <StripeContainer /> */}
            {/* const button = document.querySelector("button")
          button.addEventListener("click", () =>{' '}
          {fetch('http://localhost:3000/api/payments/create-checkout-session', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            //rewrite and refactor/remove below 2 lines code.. it works,
            //but is unnecessary
            body: JSON.stringify({
              items: [{ id: 1, quantity: 3 }],
            }),
          })
            .then((res) => {
              if (res.ok) return res.json();
              return res.json().then((json) => Promise.reject(json));
            })
            .then(({ url }) => {
              window.location = url;
              console.log('window.location');
              console.log(window.location);
            })
            .catch((e) => {
              console.error(e.error);
            })}
          )
          */}

            {/* <Link to={`/payForAd`} state={{ data: data }}> */}
            {/* <input
              type="submit"
              value="Promote"
              className="btn btn-primary-dark btn-block"
            /> */}
            {/* </Link> */}
          </form>

          {/* <CheckoutForm
          price={75}
          onSuccessfulCheckout={() =>
            console.log('payment successful, now run createAd(data)')
          }
          // onSuccessfulCheckout={() => Router.push("/success")}
        /> */}
        </div>
        {/* </div> */}

        {/* <div className="col"> */}
        <div className="smallScreenPayFields">
          <Layout title="Donut Shop">
            {/* <Row>
            <DonutShop
              onAddDonut={console.log('added donut')}
              onRemoveDonut={console.log('rm donut')}
              numDonuts={5}
            />
          </Row> */}
            <CheckoutForm
              price={50}
              onSuccessfulCheckout={() => {
                console.log(
                  'payment successful, now run createAdvertisement ...'
                );

                createAdvertisement();
                navigate('/user/profile', {
                  state: {
                    messageText:
                      'We have received your request and a member from our team will follow up shortly',
                  },
                  // state: {
                  //   advert: advert,
                  //   user: user,
                  //   // promotionText: promotionText,
                  //   file: file,
                  // },
                });
              }}
            />
          </Layout>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default AdvertiseNoRedirect;

// import axios from 'axios';
// import React, { useState, useContext, useEffect } from 'react';
// import { Navigate } from 'react-router-dom';
// import AlertContext from '../../context/alert/alertContext';
// import { useAuth, clearErrors, register } from '../../context/auth/AuthState';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Form from 'react-bootstrap/Form';

// import ListOfCities from './ListOfCities.json';
// import PotentialConnections from '../user/PotentialConnections';

// const Register = (props) => {
//   const alertContext = useContext(AlertContext);
//   const [authState, authDispatch] = useAuth();
//   const { error, isAuthenticated } = authState;

//   const { setAlert } = alertContext;

//   useEffect(
//     () => {
//       if (error === 'User already exists') {
//         setAlert(error, 'danger');
//         clearErrors(authDispatch);
//       }
//     },
//     [error, isAuthenticated, props.history, setAlert, authDispatch]
//   );

//   const [user, setUser] = useState({
//     name: '',
//     ownersEmail: '',
//     phoneNumber: '',
//     password: '',
//     password2: '',
//     adImage: '',
//     location: '',
//   });
//   const [file, setFile] = useState();
//   // const [location, setLocation] = useState();

//   const {
//     name,
//     ownersEmail,
//     phoneNumber,
//     password,
//     password2,
//     adImage,
//     location,
//   } = user;

//   const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });
//   const onSubmit = (e) => {
//     e.preventDefault();
//     const data = new FormData();
//     data.append('file', file);
//     data.append('name', name);
//     data.append('ownersEmail', ownersEmail);
//     data.append('phoneNumber', phoneNumber);
//     data.append('password', password);
//     data.append('adImage', adImage);
//     data.append('location', location);

//     if (name === '' || ownersEmail === '' || password === '' || phoneNumber === '') {
//       setAlert('Please enter all fields', 'danger');
//     } else if (password !== password2) {
//       setAlert('Passwords do not match', 'danger');
//     } else {
//       register(authDispatch, data);
//     }
//   };

//   // if (isAuthenticated) return <Navigate to="/events" />;
//   return (
//     <div className="form-container">
//       <h1>
//         Account <span className="text-primary">Register</span>
//       </h1>
//       <form onSubmit={onSubmit}>
//         <div className="form-group">
//           <label htmlFor="name">Short Description For Your Audience</label>
//           <input
//             id="name"
//             type="text"
//             name="name"
//             value={name}
//             onChange={onChange}
//             required
//           />
//         </div>

//         <input
//           id="adImage"
//           name="adImage"
//           type="file"
//           value={adImage}
//           onChange={(event) => {
//             const file = event.target.files[0];
//             setFile(file);
//             setUser({ ...user, [event.target.name]: event.target.value });
//           }}
//         />

//         <Form.Group controlId="formBasicSelect">
//           <Form.Label>Location</Form.Label>
//           <Form.Control
//             id="location"
//             name="location"
//             as="select"
//             value={location}
//             onChange={(e) => {
//               // console.log('e.target.value', e.target.value);
//               // setLocation(e.target.value);

//               setUser({ ...user, [e.target.name]: e.target.value });
//             }}
//           />
//         </Form.Group>

//         <input
//           type="submit"
//           value="Register"
//           className="btn btn-primary btn-block"
//         />
//       </form>
//     </div>
//   );
// };
