import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import AlertContext from '../../context/alert/alertContext';
import { useAuth, clearErrors, register } from '../../context/auth/AuthState';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import ListOfCities from './ListOfCities.json';
import PotentialConnections from '../user/PotentialConnections';
import UserVerificationAndClaim from './UserVerificationAndClaim';

const RegisterAndClaimArtist = (props) => {
  const alertContext = useContext(AlertContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated, user } = authState;

  const { setAlert } = alertContext;
  console.log('ListOfCities.list');
  console.log(ListOfCities);
  const listOfCities = ListOfCities;
  useEffect(
    () => {
      if (error === 'User already exists') {
        setAlert(error, 'danger');
        clearErrors(authDispatch);
      }
    },
    [error, isAuthenticated, props.history, setAlert, authDispatch]
  );

  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    password2: '',
    profile_image: '',
    location: '',
  });
  const [file, setFile] = useState();
  // const [location, setLocation] = useState();

  const {
    name,
    email,
    phoneNumber,
    password,
    password2,
    profile_image,
    location,
  } = userDetails;

  const onChange = (e) =>
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });

  const send = (e) => {
    e.preventDefault();

    //sending multipart data in js
    const data = new FormData();
    data.append('file', file);
    data.append('name', name);
    data.append('email', email);
    data.append('phoneNumber', phoneNumber);
    data.append('password', password);
    data.append('profile_image', profile_image);
    // data.append('location', location);
    console.log('data from register');
    console.log(data);

    axios
      .post('https://httpbin.org/anything', data)
      .then((res) => console.log(res))
      .catch((err) => {
        console.log('error from register is: ');
        console.log(err);
      });
  };

  // const onSubmit = (e) => {
  //   e.preventDefault();

  //   if (name === '' || email === '' || password === '' || phoneNumber === '') {
  //     setAlert('Please enter all fields', 'danger');
  //   } else if (password !== password2) {
  //     setAlert('Passwords do not match', 'danger');
  //   } else {
  //     register(authDispatch, {
  //       name,
  //       email,
  //       phoneNumber,
  //       password,
  //       profile_image,
  //     });
  //   }
  // };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', file);
    data.append('name', name);
    data.append('email', email);
    data.append('phoneNumber', phoneNumber);
    data.append('password', password);
    data.append('profile_image', profile_image);
    data.append('location', location);

    if (name === '' || email === '' || password === '' || phoneNumber === '') {
      setAlert('Please enter all fields', 'danger');
    } else if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else {
      register(authDispatch, data);
    }
  };

  // if (isAuthenticated) return <Navigate to="/events" />;
  // if (isAuthenticated) return <PotentialConnections city={location} />;
  let { artist_id } = useParams();

  if (isAuthenticated)
    return (
      <UserVerificationAndClaim artist_id_to_claim={artist_id} user={user} />
    );
  return (
    <div className="form-container">
      <h1>
        Account <span className="text-primary">Register and Claim</span>
      </h1>
      {/* <form onSubmit={onSubmit} encType="multipart/form-data"> */}
      <form onSubmit={onSubmit}>
        {/* <form onSubmit={onSubmit}> */}
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            type="text"
            name="name"
            value={name}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            id="phoneNumber"
            type="text"
            name="phoneNumber"
            value={phoneNumber}
            onChange={onChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            value={password}
            onChange={onChange}
            required
            minLength="6"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password2">Confirm Password</label>
          <input
            id="password2"
            type="password"
            name="password2"
            value={password2}
            onChange={onChange}
            required
            minLength="6"
          />
        </div>
        <input
          id="profile_image"
          name="profile_image"
          type="file"
          value={profile_image}
          onChange={(event) => {
            const file = event.target.files[0];
            setFile(file);
            setUserDetails({
              ...userDetails,
              [event.target.name]: event.target.value,
            });
          }}
        />
        {/* <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            Location
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>New York City, NY</Dropdown.Item>
            <Dropdown.Item>Los Angeles, CA</Dropdown.Item>
            <Dropdown.Item>Chicago, IL</Dropdown.Item>
            <Dropdown.Item>Houston, TX</Dropdown.Item>
            <Dropdown.Item>Phoenix, AZ</Dropdown.Item>
            <Dropdown.Item>Philadelphia, PA</Dropdown.Item>
            <Dropdown.Item>San Antonio, TX</Dropdown.Item>
            <Dropdown.Item>San Diego, CA</Dropdown.Item>
            <Dropdown.Item>Dallas, TX</Dropdown.Item>
            <Dropdown.Item>Charlotte, NC</Dropdown.Item>
            {listOfCities.map((city) => (
              <Dropdown.Item href="/">{city.name}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown> */}

        {/* <Form.Select aria-label="Default select example">
          <option>Open this select menu</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </Form.Select> */}

        <Form.Group controlId="formBasicSelect">
          <Form.Label>Location</Form.Label>
          <Form.Control
            id="location"
            name="location"
            as="select"
            value={location}
            onChange={(e) => {
              // console.log('e.target.value', e.target.value);
              // setLocation(e.target.value);

              setUserDetails({
                ...userDetails,
                [e.target.name]: e.target.value,
              });
            }}
          >
            {/* <option value="DICTUM">New York City, NY</option>
          <option value="CONSTANCY">Constancia</option>
          <option value="COMPLEMENT">Complemento</option> */}

            {listOfCities.map((city) => (
              <option value={city.name + ',' + city.usps}>
                {city.name + ',' + city.usps}
              </option>

              // <Dropdown.Item href="/">{city.name}</Dropdown.Item>
            ))}
          </Form.Control>
        </Form.Group>

        <input
          type="submit"
          value="Register"
          className="btn btn-primary btn-block"
        />
      </form>
    </div>
  );
};

export default RegisterAndClaimArtist;
