import React, { Component, useState } from 'react';
import { Button, Form } from 'react-bootstrap';

const ContactReporter = (props) => {
  const [
    reporterHasAlreadyBeenContacted,
    setReporterHasAlreadyBeenContacted,
  ] = useState(
    props?.incident_report?.reporter_contacted
    //   ? props.incident_report.reporter_contacted
    //   : false
  );

  if (props?.loading) {
    return <h2>Loading...</h2>;
  }
  return (
    <div>
      <div>
        <div className="col">
          <div className="row">
            <div className="col">
              <h5>
                {' '}
                <span className="incidentReportABC_Seperators">A</span> Contact
                Reporter
              </h5>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">
            <h5>{props.incident_report?.reporter_name}</h5>
          </div>
          <div className="col">
            <h5>{props.incident_report?.reporter_phone_number}</h5>
          </div>
          <div className="col">
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label={
                  // props?.witness?.witness_contacted
                  reporterHasAlreadyBeenContacted
                    ? 'Yes'
                    : //   : props?.witnessContactedLabel
                      'Not Yet'
                }
                checked={reporterHasAlreadyBeenContacted}
                onClick={(e) => {
                  props.setReporterContactedUpdateShowButton(
                    !props.reporterContactedUpdateShowButton
                  );

                  props.onReporterContactedUpdate(props?.witness, e);
                  // props.setFromChild(props?.witness, e);
                  //show update button for witness section

                  if (!reporterHasAlreadyBeenContacted) {
                    //   props?.setWitnessContactedLabel('Not Yet');
                    setReporterHasAlreadyBeenContacted(true);
                  } else {
                    //   props?.setWitnessContactedLabel('Yes');
                    setReporterHasAlreadyBeenContacted(false);
                  }
                }}
              />
            </Form>
          </div>
        </div>
      </div>

      {props.reporterContactedUpdateShowButton ? (
        <Button
          onClick={() => {
            console.log('props.details from witnesses');
            console.log(props.details);
            props.updateIncidentReport(
              props.details,
              props.incident_report._id
            );
          }}
        >
          Update
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
};
export default ContactReporter;
