import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReviewPerformer from '../performances/ReviewPerformer';
import ReviewWasClicked from '../performances/ReviewWasClicked';
import ReviewWasClickedOnCompanyPage from '../performances/ReviewWasClickedOnCompanyPage';

const CompanyPerformances = (props) => {
  const [reviewClicked, setReviewClicked] = useState(false);
  const [previouslyClickedArtist, setPreviouslyClickedArtist] = useState(
    'none'
  );

  const reviewWasClicked = () => {
    setReviewClicked(!reviewClicked);
    // setPreviouslyClickedArtist;
    //load reviews
    //commented on july 24
    // getReviewsForGroupForEvent(props.performer.group_id, props.event._id);
  };

  //   const buttonName = () => {
  //     if (reviewClicked) {return 'hide' }
  //     else{return "name"}
  //     }
  //   };
  return (
    <div>
      <Button
        onClick={() => {
          reviewWasClicked();
        }}
      >
        {!reviewClicked ? `${props.event.event_name}` : 'hide'}
      </Button>
      <ReviewWasClickedOnCompanyPage
        key={Math.random() * 10}
        performer={props.event}
        event={props.event}
        performance_reviews_for_group_and_event={props.event.reviews}
        visible={reviewClicked}
        previouslyClickedArtist={previouslyClickedArtist}
      />
      <hr />
    </div>
  );
};
export default CompanyPerformances;
