import React, { useReducer } from 'react';
import axios from 'axios';

import PerformanceReviewContext from './performanceReviewContext';
import PerformanceReviewReducer from './performanceReviewReducer';

import {
  GET_REVIEWS_FOR_GROUP_FOR_EVENT,
  GET_ALL_REVIEWS_FOR_EVENT,
  SUBMIT_PERFORMANCE_REVIEW,
  GET_PERFORMERS_FOR_EVENT,
  PERFORMANCE_REVIEW_ERROR,
  ADD_PERFORMERS,
  ADD_COMPANY_PERFORMERS,
  GET_ALL_COMPANY_REVIEWS_FOR_EVENT,
  SUBMIT_COMPANY_PERFORMANCE_REVIEW,
  GET_REVIEWS_FOR_COMPANY_FOR_EVENT,
} from '../types';

const PerformanceReviewState = (props) => {
  const initialState = {
    performersForEvent: [],
    added_performers: [],
    added_company_performers: [],
    performance_reviews_for_group_and_event: [],
    performance_reviews_for_company_and_event: [],
    all_performance_reviews_for_event: [],
    all_company_performance_reviews_for_event: [],
    submitted_performance_review: {},
    submitted_company_performance_review: {},
    error: null,
  };
  const [state, dispatch] = useReducer(PerformanceReviewReducer, initialState);

  const getAllReviewsForEvent = async (event_id) => {
    try {
      console.log('running getAllReviewsForEvent()');
      const res = await axios.get(
        `/api/performance/all-group-reviews-for-event/${event_id}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_ALL_REVIEWS_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getAllCompanyReviewsForEvent = async (event_id) => {
    try {
      console.log('running getAllCompanyReviewsForEvent()');
      const res = await axios.get(
        `/api/company-performances/all-group-reviews-for-event/${event_id}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_ALL_COMPANY_REVIEWS_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getReviewsForGroupForEvent = async (group_id, event_id) => {
    try {
      console.log('running getReviewsForGroupForEvent()');
      const res = await axios.get(
        `/api/performance/group-reviews-for-event/${group_id}/${event_id}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_REVIEWS_FOR_GROUP_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getReviewsForCompanyForEvent = async (group_id, event_id) => {
    try {
      console.log('running getReviewsForCompanyForEvent()');
      const res = await axios.get(
        `/api/company-performances/group-reviews-for-event/${group_id}/${event_id}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_REVIEWS_FOR_COMPANY_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getPerformersForEvent = async (event_id) => {
    try {
      console.log('running getReviewsForGroupForEvent()');
      const res = await axios.get(
        `/api/performance/getPerformersForEvent/${event_id}`
      );
      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: GET_PERFORMERS_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const submitReviewToBackend = async (reviewObj) => {
    try {
      console.log('trying to submit review');
      const res = await axios.post(
        '/api/performance/new-performance-review',
        reviewObj
      );
      console.log('trying to submit review- after');

      console.log('res');
      console.log(res);
      dispatch({
        type: SUBMIT_PERFORMANCE_REVIEW,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err,
      });
    }
  };
  const submitCompanyReviewToBackend = async (reviewObj) => {
    try {
      console.log('trying to submit review');
      const res = await axios.post(
        '/api/company-performances/new-performance-review',
        reviewObj
      );
      console.log('trying to submit review- after');

      console.log('res');
      console.log(res);
      dispatch({
        type: SUBMIT_COMPANY_PERFORMANCE_REVIEW,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err,
      });
    }
  };
  const addNewPerformersToEvent = async (performersObj) => {
    try {
      console.log('trying to addNewPerformersToEvent');
      const res = await axios.post(
        '/api/performance/create-performer-objects',
        performersObj
      );
      console.log('trying to addNewPerformersToEvent after');

      console.log('res');
      console.log(res);
      dispatch({
        type: ADD_PERFORMERS,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err,
      });
    }
  };
  const addNewCompanyPerformersToEvent = async (performersObj) => {
    try {
      console.log('trying to addNewPerformersToEvent');
      const res = await axios.post(
        '/api/company-performances/add-company',
        performersObj
      );
      console.log('trying to addNewPerformersToEvent after');

      console.log('res');
      console.log(res);
      dispatch({
        type: ADD_COMPANY_PERFORMERS,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: PERFORMANCE_REVIEW_ERROR,
        payload: err,
      });
    }
  };

  return (
    <PerformanceReviewContext.Provider
      value={{
        // props -- where our state and actions will go
        performance_reviews_for_group_and_event:
          state.performance_reviews_for_group_and_event,
        performance_reviews_for_company_and_event:
          state.performance_reviews_for_company_and_event,
        all_performance_reviews_for_event:
          state.all_performance_reviews_for_event,
        performersForEvent: state.performersForEvent,
        submitted_performance_review: state.submitted_performance_review,
        added_performers: state.added_performers,
        added_company_performers: state.added_company_performers,
        all_company_performance_reviews_for_event:
          state.all_company_performance_reviews_for_event,
        submitted_company_performance_review:
          state.submitted_company_performance_review,

        getPerformersForEvent,
        getReviewsForGroupForEvent,
        submitReviewToBackend,
        submitCompanyReviewToBackend,
        addNewPerformersToEvent,
        getAllReviewsForEvent,
        getAllCompanyReviewsForEvent,
        addNewCompanyPerformersToEvent,
        getAllCompanyReviewsForEvent,
        getReviewsForCompanyForEvent,
      }}
    >
      {props.children}
    </PerformanceReviewContext.Provider>
  );
};

export default PerformanceReviewState;
