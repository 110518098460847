//consider renaming to potential_mate to differentiate btw user
import React, { Fragment, useContext, useEffect } from 'react';
import UserContext from '../../context/user/userContext';
// import UserItem from './UserItem';
import PropTypes from 'prop-types';
import AuthContext from '../../context/auth/authContext'; // might have to capitalize
import AdvertisementContext from '../../context/advertisement/advertismentContext';
import { useAuth } from '../../context/auth/AuthState';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const moment = require('moment');
moment().format();

const AdvertisementApproval = () => {
  let navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;
  const { user } = authState;
  const userContext = useContext(UserContext);

  const advertisementContext = useContext(AdvertisementContext);
  const {
    getAllAdRequests,
    allAdRequests,
    approveAdRequest,
    approveBannerAdRequest,
    approveETWAdRequest,
    denyRequest,
    getBannerAdRequests,
    getEventsThisWeekAdRequests,
    bannerAdRequests,
    eventsThisWeekAdRequests,
  } = advertisementContext;

  useEffect(() => {
    getAllAdRequests();
    getBannerAdRequests();
    getEventsThisWeekAdRequests();
    console.log('user');
    console.log(user);
    if (!user || !user?.email === 'airmeus_admin@gmail.com') {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  const [sponsorAdsVisible, setSponsorAdsVisible] = React.useState(true);
  const [bannerAdsVisible, setBannerAdsVisible] = React.useState(false);

  const [clickedButton, setClickedButton] = React.useState('Sponsor');
  const [adType, setAdType] = React.useState('Sponsor');
  const [
    eventsThisWeekAdsVisible,
    setEventsThisWeekAdsVisible,
  ] = React.useState(false);

  const approveRequest = (request_id) => {
    approveAdRequest(request_id);
  };

  const buttonSelectedColor = (button) => {
    if (button === 'Events This Week') {
      return 'orange';
    }
    return 'black';
  };

  var isAllCheck = false;
  const handlePillClicked = (type) => {
    setAdType(type);
    console.log('type is:');
    console.log(type);
    if (type === 'Sponsor') {
      setSponsorAdsVisible(true);
      setBannerAdsVisible(false);
      setEventsThisWeekAdsVisible(false);
    }
    if (type === 'Banner Ads') {
      console.log('banner type selected');
      setBannerAdsVisible(true);
      setSponsorAdsVisible(false);
      setEventsThisWeekAdsVisible(false);
    }
    if (type === 'Events This Week') {
      console.log('type is ETW');
      setEventsThisWeekAdsVisible(true);
      setSponsorAdsVisible(false);
      setBannerAdsVisible(false);
    }
    // setDetails({
    //   ...details,
    //   ['workshop_type']: type,
    // });
    document
      .querySelectorAll('input[type=radio]')
      .forEach((el) => (el.checked = isAllCheck));
  };

  //dynamic buttons
  const renderSponsorButton = () => {
    if (adType === 'Sponsor') {
      console.log('ad type matches sponsor');

      console.log('should be false: ');
      console.log('eventsThisWeekAdsVisible');
      console.log(eventsThisWeekAdsVisible);
      console.log('bannerAdsVisible');
      console.log(bannerAdsVisible);

      return (
        <Button
          onClick={() => {
            handlePillClicked('Sponsor');

            // setSponsorAdsVisible(true);
            // setBannerAdsVisible(false);
            // setEventsThisWeekAdsVisible(false);
          }}
          style={{
            backgroundColor: 'rgb(1, 126, 252)',
          }}
        >
          Sponsor
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('Sponsor')}
          style={{ backgroundColor: 'black' }}
        >
          Sponsor Ads
        </Button>
      );
    }
  };
  //dynamic buttons
  const renderEventsThisWeekButton = () => {
    if (adType === 'Events This Week') {
      console.log('ad type matches ETW');

      console.log('should be false: ');
      console.log('sponsorAdsVisible');
      console.log(sponsorAdsVisible);
      console.log('bannerAdsVisible');
      console.log(bannerAdsVisible);

      return (
        <Button
          onClick={() => {
            handlePillClicked('Events This Week');

            // setEventsThisWeekAdsVisible(!eventsThisWeekAdsVisible);
            // setSponsorAdsVisible(false);
            // setBannerAdsVisible(false);
            setClickedButton('Events This Week');
          }}
          // className="rounded-pill"
          style={{
            backgroundColor: 'rgb(1, 126, 252)',
          }}
        >
          Events This Week
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('Events This Week')}
          style={{ backgroundColor: 'black' }}
        >
          Events This Week
        </Button>
      );
    }
  };
  //dynamic buttons
  const renderBannerAdButton = () => {
    if (adType === 'Banner Ads') {
      console.log('ad type matches banner');
      console.log('should be false: ');
      console.log('sponsorAdsVisible');
      console.log(sponsorAdsVisible);
      console.log('eventsThisWeekAdsVisible');
      console.log(eventsThisWeekAdsVisible);
      return (
        <Button
          // onClick = () => {

          style={{
            backgroundColor: 'rgb(1, 126, 252)',
          }}
          onClick={() => {
            handlePillClicked('Banner Ads');
            // setBannerAdsVisible(true);
            // setSponsorAdsVisible(false);
            // setEventsThisWeekAdsVisible(false);

            // setEventsThisWeekAdsVisible(!eventsThisWeekAdsVisible);
            // setSponsorAdsVisible(false);
            // setBannerAdsVisible(false);
          }}
          // className="rounded-pill"
        >
          Banner Ads
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('Banner Ads')}
          style={{ backgroundColor: 'black' }}
        >
          Banner Ads
        </Button>
      );
    }
  };

  return (
    <div>
      {user?.email === 'airmeus_admin@gmail.com' ? (
        <div>
          {/*  <div className="all-center" style={{ minWidth: '1000px' }}> */}
          {/*  <div className="approval-container"> */}
          {/* {users.map((user) => (
        <UserItem key={user.id} user={user} />
      ))} */}
          <br />
          <div className="col">
            <h1>Advertisement Requests</h1>
            <br />
            <div className="row">
              <div className="col">
                {/* <Button
                onClick={() => {
                  setEventsThisWeekAdsVisible(!eventsThisWeekAdsVisible);
                  setSponsorAdsVisible(false);
                  setBannerAdsVisible(false);
                  setClickedButton('Events This Week');
                }}
                style={{{eventsThisWeekAdsVisible? fontSize:"px":fontSize:"px"}}}

                style={{
                  backgroundColor: buttonSelectedColor(clickedButton),
                }}
                > */}
                {/* Events This Week */}
                {renderEventsThisWeekButton()}
                {/* </Button> */}
              </div>
              <div className="col">
                {/* <Button
                  onClick={() => {
                    setSponsorAdsVisible(!sponsorAdsVisible);
                    setBannerAdsVisible(false);
                    setEventsThisWeekAdsVisible(false);
                  }}
                > */}
                {/* Sponsor Ads */}
                {renderSponsorButton()}
                {/* </Button> */}
              </div>
              <div className="col">
                {/* <Button
                  onClick={() => {
                    setBannerAdsVisible(!bannerAdsVisible);
                    setSponsorAdsVisible(false);
                    setEventsThisWeekAdsVisible(false);
                  }}
                > */}
                {renderBannerAdButton()}
                {/* </Button> */}
              </div>
            </div>
            <br />
          </div>
          {eventsThisWeekAdsVisible &&
            eventsThisWeekAdRequests.map((request) => (
              <div>
                <div>
                  <div className="col">
                    <label htmlFor="">Advertisement text</label>
                    <p>{request.promotionText}</p>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <label htmlFor="">email</label>
                    <h5>{request.ownersEmail}</h5>
                  </div>

                  <div className="col">
                    <label htmlFor="">number</label>
                    <h5>{request.phoneNumber}</h5>
                  </div>
                  <div className="col">
                    <label htmlFor="">date submitted</label>
                    <h5 style={{ fontSize: '8px' }}>
                      {moment(request.date)
                        .zone(0)
                        .format('dddd MM-DD-YYYY')}
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label htmlFor="">approve</label>
                      <Button onClick={() => approveETWAdRequest(request._id)}>
                        Approve
                      </Button>
                    </div>
                    <div className="col">
                      <label htmlFor="">deny</label>
                      <Button
                        // onClick={() => denyRequest(request._id)}
                        onClick={() => denyRequest(request._id, 'etw')}
                        style={{ backgroundColor: 'red' }}
                      >
                        Deny
                      </Button>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
          {sponsorAdsVisible &&
            allAdRequests.map((request) => (
              // <div className="row">{request.promotionText}</div>
              <div>
                <div className="row">
                  <label htmlFor="">advertisement image</label>
                  <div className="col">
                    <img
                      className="rounded-circle"
                      //   src={profile.user.avatar}
                      src={request?.adImage?.url}
                      // src={image}
                      alt=""
                      height="150"
                      width="150"
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="">Advertisement text</label>
                    <p>{request.promotionText}</p>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <label htmlFor="">email</label>
                    <h5>{request.ownersEmail}</h5>
                  </div>

                  <div className="col">
                    <label htmlFor="">number</label>
                    <h5>{request.phoneNumber}</h5>
                  </div>
                  <div className="col">
                    <label htmlFor="">date submitted</label>
                    <h5 style={{ fontSize: '8px' }}>
                      {moment(request.date)
                        .zone(0)
                        .format('dddd MM-DD-YYYY')}
                    </h5>
                  </div>
                  <div className="col">
                    <label htmlFor="">approve</label>
                    <Button onClick={() => approveRequest(request._id)}>
                      Approve
                    </Button>
                  </div>
                  <div className="col">
                    <label htmlFor="">deny</label>
                    <Button
                      // onClick={() => denyRequest(request._id)}
                      onClick={() => denyRequest(request._id, 'sponsor')}
                      style={{ backgroundColor: 'red' }}
                    >
                      Deny
                    </Button>
                  </div>
                </div>
                <hr />
              </div>
            ))}

          {bannerAdsVisible &&
            bannerAdRequests.map((request) => (
              // <div>
              //   <h5>{request.promotionText}</h5>
              // </div>

              <div>
                {/* <div className="row">
                  <label htmlFor="">advertisement image</label>
                  <div className="col">
                    <img
                      className="rounded-circle"
                      //   src={profile.user.avatar}
                      src={request?.adImage?.url}
                      // src={image}
                      alt=""
                      height="150"
                      width="150"
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="">Advertisement text</label>
                    <p>{request.promotionText}</p>
                  </div>
                </div> */}
                <div>
                  <div className="col">
                    <label htmlFor="">Advertisement text</label>
                    <p>{request.promotionText}</p>
                  </div>
                </div>
                <br />
                <div className="row">
                  <div className="col">
                    <label htmlFor="">email</label>
                    <h5>{request.ownersEmail}</h5>
                  </div>

                  <div className="col">
                    <label htmlFor="">number</label>
                    <h5>{request.phoneNumber}</h5>
                  </div>
                  <div className="col">
                    <label htmlFor="">date submitted</label>
                    <h5 style={{ fontSize: '8px' }}>
                      {moment(request.date)
                        .zone(0)
                        .format('dddd MM-DD-YYYY')}
                    </h5>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label htmlFor="">approve</label>
                      <Button
                        onClick={() => approveBannerAdRequest(request._id)}
                      >
                        Approve
                      </Button>
                    </div>
                    <div className="col">
                      <label htmlFor="">deny</label>
                      <Button
                        onClick={() => denyRequest(request._id, 'banner')}
                        style={{ backgroundColor: 'red' }}
                      >
                        Deny banner
                      </Button>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}

          {/* <h2>AD REWS</h2> */}
        </div>
      ) : (
        <div>
          <h1>forbidden</h1>
          <Link to="/">
            <Button>Go Home</Button>
          </Link>
        </div>
      )}
    </div>
  );
};

// AdvertisementApproval.propTypes = {};

export default AdvertisementApproval;
