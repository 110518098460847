/* eslint-disable import/no-anonymous-default-export */
// import React from 'react';
// import PropTypes from 'prop-types';

import {
  GET_USERS_NOTIFICATIONS,
  SEND_NEW_USER_CONNECTION_NOTIF,
  LINK_MEMBER_TO_PERSONAL_ACCOUNT,
  NOTIF_ERROR,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_USERS_NOTIFICATIONS:
      return {
        ...state,
        user_notifications: action.payload,
        // loading: false,
      };

    case SEND_NEW_USER_CONNECTION_NOTIF:
      return {
        ...state,
        notification: action.payload,
        // loading: false,
      };
    case LINK_MEMBER_TO_PERSONAL_ACCOUNT:
      return {
        ...state,
        user_notifications: state.user_notifications.filter(
          (notif) => notif._id !== action.payload
        ),
        // user_notifications: action.payload,

        // loading: false,
      };

    case NOTIF_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
