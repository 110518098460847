//consider renaming to potential_mate to differentiate btw user
import React, { useContext, useEffect, Fragment, useState } from 'react';
import EventContext from '../../../context/event/eventContext'; // might have to capitalize
import AdvertismentContext from '../../../context/advertisement/advertismentContext';
// import AdvertisementContext from '../../context/advertisement/advertisementContext'; // might have to capitalize

import { Button } from 'react-bootstrap';
import EventItem from '../EventItem';
import PropTypes from 'prop-types';

import AlertContext from '../../../context/alert/alertContext';
import { useAuth } from '../../../context/auth/AuthState';
import { Navigate } from 'react-router-dom';
import Pagination from '../Pagination';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import ListOfStates from '../ListOfStates.json';
import ListOfStatesAndCities from '../ListOfStatesAndCities.json';
import TopDancerOnMainTimeline from '../../topLists/TopDancerOnMainTimeline';
import ActivitiesWithPagination from '../../activities/ActivitiesWithPagination';
import AddEventsThisWeekFields from './AddEventsThisWeekFields';
import EventsThisWeekContext from '../../../context/eventsThisWeek/eventsThisWeekContext';
import BoostEvent from './BoostEvent';
import EventThisWeek from './EventThisWeek';
import { useLocation } from 'react-router-dom';
import FilterButtons from '../../layout/FilterButtons';
import LocationFilter from '../../layout/LocationFilter';

const EventsThisWeek = () => {
  const [loading, setLoading] = useState(false);
  const eventsThisWeekContext = useContext(EventsThisWeekContext);
  const {
    getAllEventsThisWeek,
    eventsThisWeek,
    getBoostedEventsThisWeek,
    boostedEventsThisWeek,
  } = eventsThisWeekContext;
  useEffect(() => {
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    setLoading(true);
    getAllEventsThisWeek();
    getBoostedEventsThisWeek();

    setLoading(false);
    // eslint-disable-next-line
  }, []);

  const [showFields, setShowFields] = React.useState(false);
  const [boostClicked, setBoostClicked] = React.useState(false);

  const location = useLocation();
  // const { clicked_promoted_event } = location?.state;
  console.log('clicked_promoted_event:');
  console.log(location?.state?.clicked_promoted_event);

  console.log('boostedEventsThisWeek ---');
  console.log(boostedEventsThisWeek);

  const [eventsByLocation, setEventsByLocation] = useState([]);
  const handleLocationChange = (callback) => {
    console.log(callback);
    console.log('parent click');
  };
  return (
    <div>
      <div className="card">
        <h3>Events This Week</h3>

        <Button onClick={() => setShowFields(!showFields)}>
          {showFields ? 'Show Events This Week' : 'Add New Event This Week'}
        </Button>
        {/* show fields */}
        {showFields && (
          <div>
            <AddEventsThisWeekFields />
          </div>
        )}
        <br />

        <div>
          {location?.state?.clicked_promoted_event && (
            <div>
              {/* <h1>clicked event</h1> */}
              <EventThisWeek
                promotion={true}
                event={location?.state?.clicked_promoted_event}
              />
            </div>
          )}
        </div>
        <div>
          {boostedEventsThisWeek.length > 0 ? <h4>Boosted Events</h4> : <></>}
        </div>
        <div>
          {!showFields &&
            boostedEventsThisWeek?.map((event, index) => (
              <div>
                <EventThisWeek index={index} event={event} />
              </div>
            ))}
        </div>
        <hr />

        <div>
          <div>{<LocationFilter />}</div>
          <br />
          {/* <div>{<FilterButtons eventsByLocation={eventsByLocation}/>}</div> */}
          {/* <div>{<FilterButtons />}</div> */}
          {/* {!showFields &&
            eventsThisWeek?.map((event, index) => (
              // boostedEventsThisWeek?.map((event, index) => (
              <div>
                <EventThisWeek index={index} event={event} />
              </div>
            ))} */}
        </div>
      </div>
    </div>
  );
};

export default EventsThisWeek;
