import {
  GET_TOP_DANCERS_BY_LOCATION,
  TOP_LIST_ERROR,
  LIKE_DANCER,
  ADD_NEW_DANCER,
  GET_ALL_STUDIOS,
  GET_TOP_STUDIOS_BY_LOCATION,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_TOP_DANCERS_BY_LOCATION:
      return {
        ...state,
        topDancersInLocation: action.payload,
        loading: false,
      };
    case GET_TOP_STUDIOS_BY_LOCATION:
      return {
        ...state,
        topStudiosInLocation: action.payload,
        loading: false,
      };

    case LIKE_DANCER:
      return {
        ...state,
        likedDancer: action.payload,
        loading: false,
      };

    case ADD_NEW_DANCER:
      return {
        ...state,
        newDancer: action.payload,
        loading: false,
      };

    case GET_ALL_STUDIOS:
      return {
        ...state,
        allStudios: action.payload,
        loading: false,
      };

    case TOP_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
