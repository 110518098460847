import React, { Component } from 'react';

import Attendees from '../events/Attendees';

const AttendeeList = (props) => {
  let { event } = props;
  return (
    <div className="card">
      <h1>Attendees</h1>
      <div
        class="container-fluid  overflow-auto example"
        style={{ padding: '0px' }}
      >
        <Attendees event={event} event_attendees={event?.attendees} />
      </div>
    </div>
  );
};

export default AttendeeList;
