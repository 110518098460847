import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';

const ThisOrThat = (props) => {
  let question = props.question;
  let { usersAnswers, addToUsersAnswers } = props;
  //   let usersAnswers =
  //   const [usersAnswers, setUsersAnswers] = useState([]);

  //   let addToUsersAnswers = (answer) => {
  //     setUsersAnswers([...usersAnswers, answer]);
  //   };

  //to compare we will just use the trivia id and sort the answers based on answers
  //and then compare other user's sorted answers and see which matches.

  //{user 1 sorted -> usersTrivia: {trivia_id:t_123, answers:[beyonce,breakfast,dogs]}}

  //   {this_or_that: "beyonce_or_taylor-swift"}

  //{user 3  -> usersTrivia: {trivia_id:t_123,
  // questions_and_answers:[
  // { q: "beyonce or taylor swift": answer: beyonce},
  // { q: "cats or dogs": answer: cats }
  // { q: "breakfasat or dinner": answer: breakfast }

  //{user 2 sorted -> usersTrivia: {trivia_id:t_123, answers:[dinner,dogs, taylor]}}
  const [thisClicked, setThisClicked] = useState(false);
  const [thatClicked, setThatClicked] = useState(false);
  return (
    <div>
      <div className="row">
        <div className="col">
          <Button
            onClick={() => {
              addToUsersAnswers({
                question: question.this + ' or ' + question.that,
                answer: question.this,
              });
              setThisClicked(!thisClicked);
              setThatClicked(false);
            }}
            style={{
              // color: thisClicked ? 'green' : 'purple',
              // backgroundColor: thisClicked ? 'rgb(1, 126, 252)':,

              backgroundColor:
                thisClicked === true ? 'rgb(1, 126, 252)' : 'white',
              color: thisClicked === true ? 'white' : 'rgb(1, 126, 252)',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
            }}
          >
            {question.this}
          </Button>
        </div>
        <div className="col">
          <Button
            onClick={() => {
              addToUsersAnswers({
                question: question.this + ' or ' + question.that,
                answer: question.that,
              });
              setThatClicked(!thatClicked);
              setThisClicked(false);
            }}
            style={{
              // color: thatClicked ? 'green' : 'purple'

              backgroundColor:
                thatClicked === true ? 'rgb(1, 126, 252)' : 'white',
              color: thatClicked === true ? 'white' : 'rgb(1, 126, 252)',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
            }}
          >
            {/* <Button onClick={() => addToUsersAnswers(question.question,question.that)}> */}
            {question.that}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ThisOrThat;
