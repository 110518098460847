// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
import { useState } from 'react';

import UserContext from '../../context/user/userContext';
import SubEventContext from '../../context/subevent/subEventContext';
import { useAuth } from '../../context/auth/AuthState';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const SubEventAttendee = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { attendee } = props;
  console.log('subattendee is:');
  console.log(attendee);
  // let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  let image;
  if (props.attendee.image) {
    image = props.attendee.image.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }
  return (
    <div>
      <img src={image} height="50" alt="hello" />

      <h4>{props.attendee.name}</h4>
    </div>
  );
};
export default SubEventAttendee;

// const onLike = () => {
//   console.log('user liked and my info below');
//   console.log(user);

//   likeUser(user);
//   //what should happen when you like a user
//   //the user's info should go to backend and the messages>liked users should be updated (may be a premium feature)
//   //delete current first user in the loaded in array of users and replace with the next one up
// };

// const onPass = () => {
//   console.log('passed on user');
//   // passOnUser(user._id);
//   passOnUser(user);
// };
