import React, { Fragment, useContext, useEffect, useState } from 'react';

import WorkshopsTestValues from './WorkshopsTestValues';
import eventContext from '../context/event/eventContext'; // might have to capitalize
import WorkshopsTestValues2 from './WorkshopsTestValues2';
import { Button } from 'react-bootstrap';

const WorkshopTest = (props) => {
  const [dataLoading, setDataLoading] = useState(true);
  const eContext = useContext(eventContext);
  const {
    getWorkshopScheduleNewStructure,
    workshop_schedule_new_structure,
    loading,
  } = eContext;

  let friday_room1_workshops = [
    { workshop_id: 1, time: '09:00' },
    { workshop_id: 2, time: '10:00' },
    { workshop_id: 3, time: '11:00' },
  ];
  let friday_room2_workshops = [
    { workshop_id: 4, time: '09:00', name: 'sensual bachata' },
    { workshop_id: 5, time: '10:00', name: 'zouk' },
    { workshop_id: 6, time: '11:00', name: 'salsa on 2' },
  ];
  let workshop_data = {
    'friday-room1': friday_room1_workshops,
    'friday-room2': friday_room2_workshops,
  };

  // let workshop_dict = new Map(Object.entries(workshop_data));
  // console.log('mock data workshop_dict from workshoptest.js');
  // console.log(workshop_dict);
  let workshop_dict = new Map(Object.entries(workshop_schedule_new_structure));
  console.log('workshop_dict from workshoptest.js');
  console.log(workshop_dict);

  // workshop_dict.forEach((value, key, map) => {
  //   for (let i = 0; i < value.length; i++) {
  //     //   if (value[i].workshop_type === workshopType) {
  //     //     console.log('workshop type salsa');
  //     //   } else {
  //     //     console.log('! ---workshop type salsa');
  //     //   }
  //     // }
  //     console.log('key --');
  //     console.log(key);
  //     console.log('value ---');
  //     console.log(value);
  //   }
  // });

  //   const renderWorkshopsByType = (workshopType) => {
  //     let workshops = new Map(Object.entries(workshop_schedule));
  //     console.log('workshops from renderWorkshopsByType ----');
  //     console.log(workshops);
  //     workshops.forEach((value, key, map) => {
  //       for (let i = 0; i < value.length; i++) {
  //         if (value[i].workshop_type === workshopType) {
  //           console.log('workshop type salsa');
  //         } else {
  //           console.log('! ---workshop type salsa');
  //         }
  //       }
  //       // console.log('key --');
  //       // console.log(key);
  //       // console.log('value ---');
  //       // console.log(value);
  //     });
  //   };
  console.log(
    'workshop_schedule_new_structure outside of useeffect ------------------------------'
  );
  console.log(workshop_schedule_new_structure);

  const renderWorkshops = () => {
    let workshops = new Map(Object.entries(workshop_schedule_new_structure));
    console.log('workshops from renderWorkshops ----');
    console.log(workshops);
    workshops.forEach((value, key, map) => {
      // for (let i = 0; i < value.length; i++) {
      //   if (value[i].workshop_type === workshopType) {
      //     console.log('workshop type salsa');
      //   } else {
      //     console.log('! ---workshop type salsa');
      //   }
      // }
      console.log('key --');
      console.log(key);
      console.log('value ---');
      console.log(value);

      //key is < date-room# > -- render time as date as h1

      //value is <array of workshops >-- pass to child component to render out workshops and times

      <WorkshopsTestValues2 />;
    });
  };
  // renderWorkshops();
  let workshopsByDate = Object.entries(workshop_schedule_new_structure);
  console.log('workshopsByDate --- test');
  console.log(workshopsByDate);

  // utility function to grab the desired date - default to the start date of event or the first date with actual
  //default to the start date of event or the first date with actual workshops

  const formatDate = (dateArray) => {
    let dateAndRoom = dateArray[0];
    console.log('dateAndRoomx');
    console.log(dateAndRoom);
    let dateWithoutRoomNumber = dateAndRoom;
    dateWithoutRoomNumber = dateWithoutRoomNumber.slice(0, -2);
    console.log('dateWithoutRoomNumberx');
    console.log(dateWithoutRoomNumber);
    return dateWithoutRoomNumber;
  };
  const getDateNoFormat = (dateArray) => {
    let dateAndRoom = dateArray[0];
    // console.log('dateAndRoomx');
    // console.log(dateAndRoom);
    // let dateWithoutRoomNumber = dateAndRoom;
    // dateWithoutRoomNumber = dateWithoutRoomNumber.slice(0, -2);
    // console.log('dateWithoutRoomNumberx');
    // console.log(dateWithoutRoomNumber);
    return dateAndRoom;
  };

  const [workshopsToDisplay, setWorkshopsToDisplay] = useState();

  const [workshopsForDesiredDay, setWorkshopsForDesiredDay] = useState([]);
  const returnWorkshopsOnDate = (date, workshopDatesArray) => {
    //format workshopDatesArray to match date
    let allWorkshopsOnDesiredDate = [];
    for (let i = 0; i < workshopDatesArray.length; i++) {
      let formattedDate = formatDate(workshopDatesArray[i]);
      if (date === formattedDate) {
        //add workshopdatesarray[i] to arr to return
        allWorkshopsOnDesiredDate.push(workshopDatesArray[i]);
      }
    }
    console.log('trying to return allWorkshopsOnDesiredDate');
    console.log(allWorkshopsOnDesiredDate);

    //set workshopsxx
    // setWorkshopsToDisplay(allWorkshopsOnDesiredDate)
    // setWorkshopsForDesiredDay(allWorkshopsOnDesiredDate);

    return allWorkshopsOnDesiredDate;
  };
  const returnWorkshopsOnDateWithoutDateFormat = (
    desiredDate,
    workshopDatesArray
  ) => {
    //format workshopDatesArray to match date
    let allWorkshopsOnDesiredDate = [];
    for (let i = 0; i < workshopDatesArray.length; i++) {
      let formattedDate = formatDate(workshopDatesArray[i]);
      // let formattedDate = getDateNoFormat(workshopDatesArray[i]);
      if (formattedDate === desiredDate) {
        //add workshopdatesarray[i] to arr to return
        allWorkshopsOnDesiredDate.push(workshopDatesArray[i]);
      }
    }
    console.log(`allWorkshopsOnDesiredDate pp ${desiredDate}`);
    console.log(allWorkshopsOnDesiredDate);

    return allWorkshopsOnDesiredDate;
  };
  const moment = require('moment');
  moment().format();
  const formatStartDate = (date) => {
    let formattedDate = moment(date)
      .utc()
      .format('MM-DD-YYYY');
    console.log('formattedDate from formatStartDate');
    console.log(formattedDate);
    return formattedDate;
  };
  const getFirstEventDateWithWorkshops = (eventStartDate) => {
    // setDataLoading(true);
    //format start date

    let formattedDate = formatStartDate(eventStartDate);
    console.log('formattedDate z');
    console.log(formattedDate);
    // setDataLoading(false);
    return formattedDate;

    //use first date of event and if no workshps then try second date
  };

  // getFirstEventDateWithWorkshops(props.event.start_date);
  // const workshopsOnDate = () => {

  let workshopsxx = returnWorkshopsOnDate(
    getFirstEventDateWithWorkshops(props.event.start_date),
    workshopsByDate
  );

  // console.log('workshops g');
  // console.log(workshops);

  // return workshops;
  // };
  // const [workshopsForDesiredDay, setWorkshopsForDesiredDay] = useState(
  //   workshopsxx !== undefined ? workshopsxx : []
  // );
  // testing - 4.25.24
  // const [workshopsForDesiredDay, setWorkshopsForDesiredDay] = useState(
  //   workshopsxx
  // );

  // returnWorkshopsOnDateWithoutDateFormat(date.date,workshopsByDate)

  console.log('workshopsOnDatex');
  console.log(workshopsxx);

  const returnArrayForEventDuration = (startDate, endDate) => {
    let start_date = moment(startDate);
    let end_date = moment(endDate);
    let d = Math.abs(moment.duration(start_date.diff(end_date)).asDays());

    console.log('dxxx');
    console.log(d);
    let daysAndDatesOfEvent = [];
    for (let i = 0; i < d; i++) {
      //add each day to array
      let dayToAdd = moment().add(i, 'days');

      let s = moment(start_date).add(i, 'days');

      let day = moment(s)
        .utc()
        .format('dddd');

      daysAndDatesOfEvent.push({
        day: day,
        date: moment(s)
          .utc()
          .format('MM-DD-YYYY'),
      });
    }

    console.log('daysAndDatesOfEventxxx');
    console.log(daysAndDatesOfEvent);
    return daysAndDatesOfEvent;
  };

  let daysAndDatesOfEvent = returnArrayForEventDuration(
    props.event.start_date,
    props.event.end_date
  );

  useEffect(() => {
    // setDataLoading(true);
    // getWorkshopScheduleNewStructure('62cdc9d6deb4e16a31cdd6b5');
    // uncomment below 6.6.23
    // getWorkshopScheduleNewStructure('63483abf31ddb1afaa95e788');
    console.log('props.event._id is ----');
    console.log(props.event._id);

    getWorkshopScheduleNewStructure(props.event._id);
    console.log(
      'workshop_schedule_new_structure ------------------------------'
    );
    console.log(workshop_schedule_new_structure);
    console.log('props.event yo ---');
    console.log(props.event);

    // let workshopsxx = returnWorkshopsOnDate(
    setWorkshopsForDesiredDay(
      returnWorkshopsOnDate(
        getFirstEventDateWithWorkshops(props.event.start_date),
        workshopsByDate
      )
    );

    setDataLoading(false);
  }, []);

  if (loading) return <h1>Loading...</h1>;
  if (dataLoading) return <h1>Data Loading</h1>;
  return (
    // <div>
    //   <div>

    <div
      class="container-fluid  overflow-auto example"
      style={{ padding: '0px' }}
    >
      {/* <Button onClick={()=>getFirstEventDateWithWorkshops(selectedDate)}>Date goes here and maybe style filter</Button> */}

      {daysAndDatesOfEvent.map((date) => (
        // <Button onClick={()=>{getFirstEventDateWithWorkshops(date.date)}}>{date.day}</Button>
        <Button
          onClick={() => {
            console.log('date yo');
            console.log(date);
            let workshops = returnWorkshopsOnDateWithoutDateFormat(
              date.date,
              workshopsByDate
            );
            //set Workshops to send to the WorkshopsTestValues2
            console.log('workshops tt');
            console.log(workshops);
            setWorkshopsForDesiredDay(workshops);
          }}
        >
          {date.day}
        </Button>
      ))}

      <div class="d-flex flex-row flex-nowrap">
        {/* <div className="row"> */}
        {/* {Object.keys(workshopsByDate).map((value, index) => { */}
        {Object.keys(workshopsForDesiredDay).map((value, index) => {
          console.log('hi--------------');
          console.log(`value is:`);
          console.log(value);
          console.log(workshopsByDate[value]);
          console.log(
            'workshopsForDesiredDay[value] --- does this match the above??'
          );
          // console.log(workshopsForDesiredDay[value]);
          console.log(workshopsxx);

          return (
            <div
              key={index}
              className="card-horizontal-menu card-block mx-2 card"
              style={{ width: '380px' }}
            >
              {/* <div key={index}>
                <h5>htest</h5> */}
              {/* <h5>testing</h5> */}

              {/* commented on 4.19.2024 for conversion to Events 0.2 */}
              {/* <WorkshopsTestValues2 workshops={workshopsByDate[value]} /> */}

              <WorkshopsTestValues2 workshops={workshopsForDesiredDay[value]} />
              <hr />
            </div>
          );
        })}
        {/* </div> */}
      </div>
      {/* {workshop_dict.forEach((value, key, map) => {
        console.log('value from foreach');
        console.log(value); */}
      {/* <div>
        {friday_room2_workshops.map((workshops) => (
          //   <CalendarEvents key={event.id} event={event} />
          <WorkshopsTestValues workshop={workshops} />
        ))}
      </div> */}

      {/* <h1>testuuu</h1> */}
      {/*  */}
      {/* <div className="container">
        <div className="row">
          <div className="col">time</div>
          map and list out rooms in a row
          <div className="col">room 1</div>
          <div className="col">room 2</div>
          <div className="col">room 3</div>
          <div className="col">room 4</div>
        </div>
        <div className="row">
          <div className="col">10</div>
        

          {friday_room2_workshops.map((workshop) => (
            <div className="col">
              <td>{workshop.name}</td>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col">11</div>
        
          <div className="col">workshop 5</div>
          <div className="col">workshop 6</div>
          <div className="col">workshop 7</div>
          <div className="col">workshop 8</div>
        </div>
        <div className="row">
          <div className="col">12</div>
          
          <div className="col">workshop 9</div>
          <div className="col">workshop 20</div>
          <div className="col">workshop 11</div>
          <div className="col">workshop 12</div>
        </div>
        <div className="row">
          <div className="col">1</div>

          <div className="col">workshop 5</div>
          <div className="col">workshop 6</div>
          <div className="col">workshop 7</div>
          <div className="col">
            <h5 onClick={() => console.log('workshop clicked')}>workshop 8</h5>
          </div>
        </div>
      </div> */}
      {/* 
      <table>
        <tr>
          <th />
          <th scope="col">room 1</th>
          <th scope="col">room 2</th>
          <th scope="col">room 3</th>
        </tr>
        <tr>
          <th scope="row">10:00</th>

          <td>1</td>
          <td>1</td>
          <td>1</td>
        </tr>
        <tr>
          <th scope="row">11:00</th>
          <td>2</td>
          <td>2</td>
          <td>2</td>
        </tr>
        <tr>
          <th scope="row">12:00</th>
          <td>3</td>
          <td>3</td>
          <td>3</td>
        </tr>
      </table> */}
    </div>
  );
};

export default WorkshopTest;
