import React, { Component, useContext, useRef, useState } from 'react';
import Search from '@material-ui/icons/SearchRounded';
import EventsSearchResults from './EventsSearchResults';
import EventContext from '../../context/event/eventContext';
import { Button } from 'react-bootstrap';
import AddSafetyOfficerFields from './AddSafetyOfficerFields';

const EventsSearchBar = (props) => {
  let { setRegisterEventOrganizer } = props;
  let eventContext = useContext(EventContext);
  const { getEventsForSearchCriteria, eventsFromSearch } = eventContext;

  const [details, setDetails] = useState({
    searchText: '',
  });
  const { searchText } = details;
  const [studioWasSelected, setStudioWasSelected] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});

  const [dynamicText, setDynamicText] = useState('');
  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log('text is:');
    console.log(e.target.value);
    getEventsForSearchCriteria(e.target.value);
    setDynamicText(e.target.value);
  };

  const [display, setDisplay] = useState(false);

  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };

  const [showAddEvent, setShowAddEvent] = useState(false);

  const [studioSaved, setStudioSaved] = useState(false);

  return (
    <div>
      <form className="form-inline my-2 my-lg-0">
        <div className="row" style={{ marginBottom: '14px' }}>
          <div className="col">
            <input
              id="searchText"
              class="form-control mr-sm-2"
              type="search"
              placeholder="Search For Your Event"
              aria-label="Search"
              name="searchText"
              value={searchText}
              onChange={onChange}
              style={{ width: '250px' }}
              onClick={() => setDisplay(!display)}
              autoComplete="off"
            />
          </div>

          <div ref={wrapperRef} className="flex-container flex-column pos-rel">
            {display && (
              // <div className="autoContainer">
              <div>
                {/* <h5>Try searching for</h5> */}

                <EventsSearchResults
                  userSearchText={dynamicText}
                  dynamicStudioSearch={eventsFromSearch}
                  //   event_id={props.event_id}
                  setSelectedEvent={props.setSelectedEvent}
                  setStudioWasSelected={setStudioWasSelected}
                  setDisplay={setDisplay}
                />
              </div>
            )}
          </div>
          {dynamicText === '' ||
            (eventsFromSearch.length === 0 && (
              <div style={{ marginTop: '16px' }}>
                <h5>If no results:</h5>
                {/* <Button onClick={() => setShowAddStudio(!showAddStudio)}> */}
                <Button onClick={() => setShowAddEvent(!showAddEvent)}>
                  Add Event
                </Button>
              </div>
            ))}
        </div>

        {/* <Link to="/all-users"> */}
      </form>

      {studioWasSelected &&
        !studioSaved && (
          <div>
            <AddSafetyOfficerFields
              event={props.selectedEvent}
              setRegisterEventOrganizer={setRegisterEventOrganizer}
              setSafetyOfficerAndEventId={props.setSafetyOfficerAndEventId}
            />
          </div>
        )}
    </div>
  );
};

export default EventsSearchBar;
