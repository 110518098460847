import React, { Component, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import PerformanceReviewContext from '../../context/PerformanceReview/performanceReviewContext'; // might have to capitalize
import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';
// import { useAuth } from '../../context/auth/AuthState';
import Button from 'react-bootstrap/Button';
import LeaveReviewFields from './LeaveReviewFields';

const Review = (props) => {
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const [reviewClicked, setReviewClicked] = useState(false);

  //   const reviewWasClicked = () => {
  //     setReviewClicked(!reviewClicked);
  //     //load reviews
  //     // getReviewsForGroupForEvent(props.performer.group_id, props.event._id);
  //   };
  // let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  let imgg =
    'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=';
  // let star_array = ['⭐★★★★', '⭐⭐★★★', '⭐⭐⭐★★', '⭐⭐⭐⭐★', '⭐⭐⭐⭐⭐'];
  // let star_array = ['🥵', '⭐⭐★★★', '⭐⭐⭐★★', '⭐⭐⭐⭐★', '⭐⭐⭐⭐⭐'];
  let star_array = ['👏👏👏', '👍🏽👍🏽👍🏽', '🤗🥰🚀', '😱💪🏾🥶', '🔥💯🐐'];

  return (
    <div>
      {/* <LeaveReviewFields performer={performer} /> */}

      {/* <div className="form-container">
        <div className="form-group">
          <div className="container"> */}
      <div className="card bg-light">
        <div className="container">
          {/* <div className="row"> */}
          <div className="col">
            <img
              src={
                props.review.review_image
                  ? props.review?.review_image.url
                  : imgg
              }
              height="75"
              width="75"
              alt="user"
            />
          </div>
          <div className="row">
            <h5>{props.review.reviewer_name} </h5>
          </div>
          <div className="row">
            <h5 style={{ width: '100px' }}>
              {star_array[props.review.stars_given - 1]}{' '}
              {/* {props.review.stars_given}
                {'/5'} */}
            </h5>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Review;
