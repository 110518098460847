import React, { Component, useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import InstructorContext from '../../context/instructor/instructorContext';
import { useAuth } from '../../context/auth/AuthState';
import ArtistContext from '../../context/artist/artistContext';

const PrivateLessonRequestUpdated = (props) => {
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;

  const { user } = authState;
  const privatelessonRequest = props.privatelessonRequest;
  console.log('props.privatelessonRequest');
  console.log(props.privatelessonRequest);
  const moment = require('moment');
  moment().format();

  const instructorContext = useContext(InstructorContext);
  const {
    decisionPrivateLessonRequestForInstructorAtEvent,
  } = instructorContext;

  const artistContext = useContext(ArtistContext);
  const { decisionPrivateLessonRequestAtEvent } = artistContext;
  const isArtist = () => {
    // if (dancer?.artist_id && dancer?.artist_id !== '') {
    if (user?.artist_type && user?.artist_type !== '') {
      return true;
    }
    return false;
    // return dancer?.artist_id && dancer?.artist_id !== '';
    // return true
  };

  console.log('privatelessonRequest -- y');
  console.log(privatelessonRequest);
  const onSubmit = () => {
    if (isArtist()) {
      console.log('user is artist - api artist -- accepting request');
      let obj = {
        lessonDecision: 'Accepted',
        privateLessonRequestId: privatelessonRequest._id,
        artist_id: privatelessonRequest.requested_artist_id,
        event_id: props.event_id,

        event_name: props.event_name,
        // start_date,
      };
      // decisionPrivateLessonRequestForInstructorAtEvent(obj);
      decisionPrivateLessonRequestAtEvent(obj);
    } else {
      let obj = {
        lessonDecision: 'Accepted',
        privateLessonRequestId: privatelessonRequest._id,
        instructor_id: privatelessonRequest.requested_instructor_id,
        event_id: props.event_id,

        event_name: props.event_name,
        // start_date,
      };
      decisionPrivateLessonRequestForInstructorAtEvent(obj);
      console.log('obj');
      console.log(obj);
      console.log('use instr. api');
    }
  };
  return (
    <div>
      {/* <h5>requests</h5> */}

      <div className="row">
        <div className="col-md-12">
          <div
            className="card card-body mb-3"
            style={{ borderColor: 'black', borderStyle: 'solid' }}
          >
            <div className="row">
              {/* <div className="col col-md-3 m-auto"> */}
              {/* <div className="row"> */}
              <div className="row">
                <div className="col">
                  <h5
                    style={{
                      fontSize: '14px',
                      maxWidth: '150px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {privatelessonRequest.requestor_name}
                  </h5>
                </div>
                <div className="col">
                  <h5 style={{ fontSize: '14px' }}>
                    {moment(privatelessonRequest.lessonDate)
                      .zone(0)
                      .format('ddd MM.DD.YY')}
                  </h5>
                </div>
                <div className="col">
                  <h5
                    style={{
                      float: 'right',
                      fontSize: '14px',
                    }}
                  >
                    {privatelessonRequest.lessonTime}
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h5 style={{ fontSize: '14px', color: 'black' }}>
                    Student Contact{' '}
                    {privatelessonRequest?.requestor_phone_number}
                  </h5>
                </div>
              </div>
              <div>
                <h5>
                  {privatelessonRequest?.lessonPlan?.map((move) => ({ move }))}
                </h5>
              </div>
              <div className="row">
                <div className="col">
                  {/* <label htmlFor="">approve</label> */}
                  {/* <Button onClick={() => approveETWAdRequest(request._id)}> */}
                  <Button
                    onClick={() => onSubmit()}
                    style={{
                      width: '100px',
                      background: 'white',

                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',
                      borderWidth: '2px',
                      borderRadius: '6px',

                      fontSize: '14px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      textAlign: 'center',
                    }}
                  >
                    Accept
                  </Button>
                </div>
                <div className="col">
                  {/* <label htmlFor="">deny</label> */}

                  {/* onClick={() => denyRequest(request._id, 'etw')} */}
                  <Button
                    onClick={() => console.log('declined')}
                    style={{ backgroundColor: 'red' }}
                  >
                    {' '}
                    Decline
                  </Button>
                </div>{' '}
              </div>

              {/* </div> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateLessonRequestUpdated;
