import React, { Component, useEffect, useState } from 'react';
import StudioMember from './StudioMember';

const CompanyMembersByRole = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { members, studio } = props;
  useEffect(() => {
    setIsLoading(true);
    placeStudentsInRoles();
    setIsLoading(false);
  }, []);

  //take members out of array one by one displaying them in proper role

  //directors

  // instructors

  //students
  let directors = [];
  let instructors = [];
  let students = [];
  let allMembers = members;
  const ifDancerHasRole = (member) => {
    if (member?.roles?.includes('director')) {
      directors.push(member);
      allMembers.filter((thisMember) => thisMember._id !== member._id);
    }
  };

  const placeStudentsInRoles = () => {
    for (let studentIndex = 0; studentIndex < members.length; studentIndex++) {
      if (allMembers[studentIndex]?.roles.includes('director')) {
        directors.push(allMembers[studentIndex]);
      }

      if (allMembers[studentIndex]?.roles.includes('instructor')) {
        instructors.push(allMembers[studentIndex]);
      }
      if (allMembers[studentIndex]?.roles.includes('student')) {
        students.push(allMembers[studentIndex]);
      }
    }
    return '';
  };
  if (isLoading) return <h5>loading</h5>;

  return (
    <div style={{ marginTop: '16px' }}>
      {/* <h5>Director</h5> */}
      {members.length > 0 ? (
        <h5
          style={{
            backgroundColor: 'white',
            width: 'max-content',
            //   background: 'white',

            borderStyle: 'solid',
            borderColor: 'grey',
            borderWidth: '1px',
            borderRadius: '10px',

            fontSize: '30px',
            //   backgroundColor: 'white',
            color: 'grey',
            //   color: 'rgb(1, 126, 252)',
            textAlign: 'center',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
            paddingTop: '10px',
          }}
        >
          Directors
        </h5>
      ) : (
        <></>
      )}
      {members.map((member) => (
        <div>
          {member?.roles?.includes('director') ? (
            <div>
              <StudioMember member={member} studioOwners={studio?.owners} />
            </div>
          ) : (
            <></>
          )}

          {/* <StudioMember member={member} studioOwners={studio?.owners} /> */}
        </div>
      ))}
      {members.length > 0 ? (
        <h5
          style={{
            backgroundColor: 'white',
            width: 'max-content',
            //   background: 'white',

            borderStyle: 'solid',
            borderColor: 'grey',
            borderWidth: '1px',
            borderRadius: '10px',

            fontSize: '30px',
            //   backgroundColor: 'white',
            color: 'grey',
            //   color: 'rgb(1, 126, 252)',
            textAlign: 'center',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
            paddingTop: '10px',
          }}
        >
          Instructors
        </h5>
      ) : (
        <></>
      )}
      {members.map((member) => (
        <div>
          {member?.roles?.includes('instructor') ? (
            <div>
              <StudioMember member={member} studioOwners={studio?.owners} />
            </div>
          ) : (
            <></>
          )}

          {/* <StudioMember member={member} studioOwners={studio?.owners} /> */}
        </div>
      ))}
      {members.length > 0 ? (
        <h5
          style={{
            backgroundColor: 'white',
            width: 'max-content',
            //   background: 'white',

            borderStyle: 'solid',
            borderColor: 'grey',
            borderWidth: '1px',
            borderRadius: '10px',

            fontSize: '30px',
            //   backgroundColor: 'white',
            color: 'grey',
            //   color: 'rgb(1, 126, 252)',
            textAlign: 'center',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
            paddingTop: '10px',
          }}
        >
          Students
        </h5>
      ) : (
        <></>
      )}
      {members.map((member) => (
        <div>
          {member?.roles?.includes('student') ? (
            <div>
              <StudioMember member={member} studioOwners={studio?.owners} />
            </div>
          ) : (
            <></>
          )}

          {/* <StudioMember member={member} studioOwners={studio?.owners} /> */}
        </div>
      ))}

      {/* {students.map((member) => (
        <StudioMember member={member} studioOwners={studio?.owners} />
      ))} */}
    </div>
  );
};

export default CompanyMembersByRole;
