import React, { Component, useState } from 'react';
import RequiredRegistrationInput from '../registration/RequiredRegistrationInput';
import OptionalRegistrationInput from '../registration/OptionalRegistrationInput';
import UserIdentificationVerification from './UserIdentificationVerification';

const EventOrganizerRegisterAndOnboard = (props) => {
  const [requiredFieldsInputted, setRequiredFieldsInputted] = useState(false);
  const [inputtingOptionalFields, setInputtingOptionalFields] = useState(false);
  const [optionalFieldsSkipped, setOptionalFieldsSkipped] = useState(false);
  return (
    <div>
      {inputtingOptionalFields ? (
        <></>
      ) : (
        <div>
          <h5>Finish onboarding to add officer</h5>

          <RequiredRegistrationInput
            setRequiredFieldsInputted={setRequiredFieldsInputted}
            setInputtingOptionalFields={setInputtingOptionalFields}
            event={props.event}
            safetyOfficerAndEventId={props.safetyOfficerAndEventId}
          />
        </div>
      )}

      {/* username, email & pw,  */}
      {/* next screen with skip */}
      {props.registerEventOrganizer &&
      requiredFieldsInputted &&
      !optionalFieldsSkipped ? (
        <OptionalRegistrationInput
          setOptionalFieldsSkipped={setOptionalFieldsSkipped}
        />
      ) : (
        <></>
      )}

      {/* last screen - event organizer identification */}
      {/* we need event that user just submitted officer for.. */}
      {optionalFieldsSkipped ? (
        <UserIdentificationVerification event={props.event} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default EventOrganizerRegisterAndOnboard;
