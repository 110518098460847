import React, { Component } from 'react';

const ConfirmedPrivateLesson = (props) => {
  const moment = require('moment');
  moment().format();
  const { lesson, event_name } = props;
  console.log('confirmed lesson yo --');
  console.log(lesson);
  return (
    <div className="card">
      <div className="row">
        <div className="col-4">
          <div className="row">
            <h5
              style={{
                fontSize: '16px',
                maxWidth: '150px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {lesson?.requestor_name}
            </h5>
          </div>
          <div className="row">
            {event_name &&
              event_name !== '' && (
                <div className="row">
                  <div className="col">
                    <h5
                      style={{
                        fontSize: '14px',

                        maxWidth: '150px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        borderStyle: 'solid',
                        borderColor: 'rgb(1, 126, 252)',
                        borderWidth: '1px',
                        borderRadius: '2px',
                        color: 'rgb(1, 126, 252)',
                        textAlign: 'center',
                      }}
                    >
                      {event_name}
                    </h5>
                  </div>
                  {/* <div className="col">
                    <h5 style={{ fontSize: '14px' }}>
                      Student Contact {lesson?.requestor_phone_number}
                    </h5>
                  </div> */}
                </div>
              )}
          </div>
          {/* <div>
            {!event_name && (
              <div className="col">
                <h5 style={{ fontSize: '14px' }}>
                  Student Contact {lesson?.requestor_phone_number}
                </h5>
              </div>
            )}
          </div> */}
        </div>
        <div className="col">
          <h5 style={{ fontSize: '12px', color: 'black' }}>
            {moment(lesson?.lessonDate)
              .zone(0)
              .format('ddd MM.DD.YY')}
          </h5>
        </div>
        <div className="col">{lesson?.lessonTime}</div>
        {/* {event_name &&
        event_name !== '' && <div className="col">{event_name}</div>} */}
      </div>

      {/* {!event_name && ( */}
      <div className="col">
        <h5 style={{ fontSize: '14px' }}>
          Student Contact {lesson?.requestor_phone_number}
        </h5>
      </div>
      {lesson?.lessonPlan && (
        <div>
          <hr />
          <h1>Lesson Plan</h1>
        </div>
      )}
      {lesson?.lessonPlan?.map((move) => (
        <div>
          <h5>{move}</h5>
        </div>
      ))}
      {/* )} */}
      <hr />

      {lesson?.comments_or_special_requests && (
        <div>
          <h4 style={{ color: 'black' }}>Requests or Comments</h4>
          <h5 style={{ color: 'black' }}>
            {lesson?.comments_or_special_requests}
          </h5>
        </div>
      )}
    </div>
  );
};
export default ConfirmedPrivateLesson;
