import React, { Component, useState, useContext, useEffect } from 'react';
import CalendarEvents from './CalendarEvents';
import CalendarEventsUpdated from './CalendarEventsUpdated';

const Calendar = (props) => {
  //artist.events will be coming from props
  //we will have a toggle for [ workshops | confirmed private lessons | private lesson Requests ]

  let events_and_lessons1 = {
    event_id: '1',
    event_name: 'zoukheat',

    privateLessonRequests: [
      {
        req_id: '1',
        req_name: 'tom',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
      {
        req_id: '12',
        req_name: 'jen',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
      {
        req_id: '13',
        req_name: 'lenny',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
    ],
  };
  let events_and_lessons2 = {
    event_id: '1',
    event_name: 'elevation zouk',

    privateLessonRequests: [
      {
        req_id: '1',
        req_name: 'tom',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
      {
        req_id: '12',
        req_name: 'jen',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
      {
        req_id: '13',
        req_name: 'lenny',
        requested_time: '10:00',
        requested_date: '05-30-23',
      },
    ],
  };

  let calendar = [events_and_lessons1, events_and_lessons2];
  return (
    <div>
      {/* {calendar.map((event) => (
        <CalendarEvents key={event.id} event={event} />
      ))} */}
      {props.privateLessonRequestsAtEvents.map((event) => (
        <CalendarEventsUpdated key={event.event_id} event={event} />
      ))}

      {/* <div className="row">
        <div className="col-md-12">
          <div className="card card-body bgg-primary text-white mb-3">
            <div className="row text-center">
              <div className="col col-md-3 m-auto">
                <h1>calendar</h1>
                <hr />
                <h1>calendar</h1>
                <hr />
                <br />
                <h1>calendar</h1>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Calendar;
