import React from 'react';
// import User from '../user/User';
import CurrentUserProfile from '../user/CurrentUserProfile';
// import { Link } from 'react-router-dom';
// import { useAuth, clearErrors, login } from '../../context/auth/AuthState';

import { useAuth } from '../../context/auth/AuthState';

const CurrentUserProfileContainer = (props) => {
  // const [authState, authDispatch] = useAuth();
  // const { error, isAuthenticated } = authState;
  const [authState] = useAuth();

  const { user } = authState;

  return (
    <div>
      {/* <User /> */}
      <CurrentUserProfile user={user} />
    </div>
  );
};

export default CurrentUserProfileContainer;

{
  /* 

  unneeded

  
      {/* <Link to={`/user/${props.user._id}`} state={{ user: user }}>
        Profile
      </Link> */
}
