import React, { Component, useContext, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import TopListContext from '../../context/topList/topListContext';
import EventsThisWeekContext from '../../context/eventsThisWeek/eventsThisWeekContext';
// import EventsThisWeekContext from '../../;
// import LeftSideShortPanelStyle from './LeftSideShortPanelStyle';
import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';
import Form from 'react-bootstrap/Form';
import EventThisWeek from '../events/events-this-week/EventThisWeek';
import FilterButtons from './FilterButtons';
import { useAuth } from '../../context/auth/AuthState';

const LocationFilter = (props, handleLocationChange) => {
  const [authState] = useAuth();
  const { user } = authState;

  const eventsThisWeekContext = useContext(EventsThisWeekContext);
  const {
    getAllEventsThisWeek,
    eventsThisWeek,
    eventsThisWeekByLocation,
    getEventsThisWeekByLocation,
  } = eventsThisWeekContext;

  useEffect(() => {
    console.log('ListOfStates');
    console.log(ListOfStates);
    console.log('ListOfStatesAndCities');
    console.log(ListOfStatesAndCities);

    let location = {
      // city: 'Phoenix',
      // state: 'Arizona',
      // city: 'Charlotte',
      // state: 'North Carolina',

      state: user.location ? user.location.split(',')[1] : 'Georgia',
      city: user.location ? user.location.split(',')[0] : 'Atlanta',

      artist_type: 'all',
    };
    console.log('details');
    console.log(details);
    getEventsThisWeekByLocation(
      location.city,
      location.state,
      location.artist_type
    );

    // eslint-disable-next-line
  }, []);

  const [details, setDetails] = useState({
    first_name: '',
    last_name: '',
    // state: 'Phoenix',
    // city: 'Arizona', //default to user.city or first city from list

    state: user?.location ? user.location.split(',')[1] : 'Georgia',
    city: user?.location ? user.location.split(',')[0] : 'Atlanta',

    // state: user.location ? user.location.split(',')[1] : 'North Carolina',
    // city: user.location ? user.location.split(',')[0] : 'Charlotte',
    // city: 'Charlotte',
    artist_type: 'all',
    existing_studio_name: '',
    existing_studio_id: '',
    newStudioName: '',
  });
  const {
    first_name,
    last_name,
    state,
    city,
    artist_type,
    existing_studio_name,
    existing_studio_id,
    newStudioName,
  } = details;

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  console.log('eventsThisWeekByLocation ---');
  console.log(eventsThisWeekByLocation);
  const [danceType, setDanceType] = useState('all');
  const danceFilterChanged = (type) => {
    getEventsThisWeekByLocation(city, state, type);
    console.log('dance filter changing printed from parent locationFilter.js');
    console.log(`type from child ${type}--- ${city} , ${state}`);
    setDanceType(type);
    return 'updated events this week array';
  };
  return (
    <div>
      {/* send attribute down to filterbutton component to capture return of
        event type query or just send the event type back up to be queried in 
        this component
        anytime the filterbutton changes, we run this:             
         getEventsThisWeekByLocation(cities[0], e.target.value, 'type');

         and that will update the events shown in UI

        */}
      <div>{<FilterButtons onDanceFilterChange={danceFilterChanged} />}</div>
      <div className="col">
        <Form.Group controlId="formBasicSelect">
          <Form.Label>State</Form.Label>
          <Form.Control
            id="state"
            name="state"
            as="select"
            value={state}
            onChange={(e) => {
              // setDetails({
              //   ...details,
              //   [e.target.name]: e.target.value,
              // });
              console.log(' e.target.value');
              console.log(e.target.value);
              // setSelectedState(e.target.value);
              let cities = selectedCities(e.target.value);
              // setSelectedCity(cities[0]);
              console.log('details..city filled?');
              console.log(details);

              //run filter from backend
              console.log(
                `city is ${cities[0]} | state is ${
                  e.target.value
                }  | artist_type isbachata`
              );
              // we need to run a getEventsHappeningThisWeek filter
              getEventsThisWeekByLocation(cities[0], e.target.value, danceType);
            }}
          >
            {listOfStates.map((state) => (
              <option value={state}>{state}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
      <div className="col">
        <Form.Group controlId="formBasicSelect">
          <Form.Label>City</Form.Label>
          <Form.Control
            id="city"
            name="city"
            as="select"
            value={city}
            required
            onChange={(e) => {
              setDetails({
                ...details,
                [e.target.name]: e.target.value,
              });
              // setSelectedCity();
              console.log(details);

              //run filter from backend

              // we need to run a getEventsHappeningThisWeek filter

              //   getEventsThisWeekByLocation(e.target.value, state, 'bachata');
              // props.handleLocationChange(
              //   getEventsThisWeekByLocation(e.target.value, state, 'all')
              // );
              getEventsThisWeekByLocation(e.target.value, state, danceType);
            }}
          >
            {listOfCitiesForSelectedState.map((city) => (
              <option value={city}>{city}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>

      <div>
        {/* {!showFields && */}
        {eventsThisWeekByLocation?.map((event, index) => (
          // boostedEventsThisWeek?.map((event, index) => (
          <div>
            <EventThisWeek index={index} event={event} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LocationFilter;
