import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';

const SelectInterests = (props) => {
  const [clicked, setClicked] = useState(false);

  let { addOrDeleteInterest, interest } = props;

  return (
    <div>
      {' '}
      <Button
        className=""
        style={{
          //   backgroundColor: 'white',
          backgroundColor: clicked ? 'rgb(1, 126, 252)' : 'white',
          color: clicked ? 'white' : 'rgb(1, 126, 252)',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'wrap',
          background: 'white',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',
          borderWidth: '1px',
          borderRadius: '4px',
        }}
        onClick={() => {
          setClicked(!clicked);
          addOrDeleteInterest(interest);
        }}
      >
        {interest}
      </Button>
    </div>
  );
};
export default SelectInterests;
