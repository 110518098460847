import { useContext, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
} from 'react-bootstrap';

import React, { Component } from 'react';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { useAuth } from '../../context/auth/AuthState';
import { useParams } from 'react-router-dom';
import InstructorContext from '../../context/instructor/instructorContext';
import AlertContext from '../../context/alert/alertContext';
import Alerts from '../Alerts';
import ArtistContext from '../../context/artist/artistContext';
import LessonPlan from './LessonPlan';

const BookPrivatePopup = (props) => {
  const alertContext = useContext(AlertContext);

  const { setAlert, alerts } = alertContext;
  const instructorContext = useContext(InstructorContext);
  const {
    sendPrivateLessonRequestToInstructor,
    sendPrivateLessonRequestWithoutAccountToInstructor,
  } = instructorContext;

  const artistContext = useContext(ArtistContext);
  const {
    sendPrivateLessonRequest,
    sendPrivateLessonRequestWithoutAccountToArtistInstructor,
  } = artistContext;

  const [show, setShow] = useState(props.bookPrivateSelected);
  //   const handleClose = () => setShow(false);
  const handleClose = () => props.setBookPrivateSelected(false);

  const [authState] = useAuth();
  const { dancer } = props;
  // const { error, isAuthenticated } = authState;
  const { user } = authState;

  // const { target_user, joined_events, trips } = props;
  // const isCurrentUserProfile = user?._id === target_user._id;
  useEffect(() => {
    console.log('instructor_id iss....');
    console.log(props?.instructor_id);
    // getArtistById(instructor_id);
    // getPrivateLessonRequestsForArtist(instructor_id);
    // getPrivateLessonRequestsForArtistAtEvents(instructor_id);
    // console.log('artist FROM AP');
    // console.log(artist);
    // console.log('privateLessonRequests');
    // console.log(privateLessonRequests);
    // console.log('privateLessonRequestsAtEvents for silvia');
    // console.log(privateLessonRequestsAtEvents);
    // eslint-disable-next-line
  }, []);

  const [selected, setSelected] = React.useState();
  const [updateDate, setUpdateDate] = React.useState();
  const [showSelection, setShowSelection] = React.useState(false);
  const [date, setDate] = React.useState();
  const [privateLessonTime, setPrivateLessonTime] = useState('09:00');
  const [emphasisOnSetADateLabel, setEmphasisOnSetADateLabel] = useState(false);

  const isArtist = () => {
    console.log('dancer ----');
    console.log(dancer);
    // if (dancer?.artist_id && dancer?.artist_id !== '') {
    if (dancer?.artist_type && dancer?.artist_type !== '') {
      return true;
    }
    return false;
    // return dancer?.artist_id && dancer?.artist_id !== '';
    // return true
  };

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    lessonTime: privateLessonTime,
    lessonDate: selected,
    event_id: '', // might not be applicable
    // requesting_user_id: '123',

    requestor_name: user?.name,
    requestor_id: user?._id,
    requestor_phone_number: user?.phoneNumber,
    // requested_artist_id: props?.instructor_id,
    //commented below line out on may 30, 2024 bcuz error
    // requested_artist_id: isArtist() ? props.instructor_id : '',
    requested_artist_id: isArtist() ? dancer?.artist_id : '',
    requested_instructor_id: isArtist() ? '' : dancer?._id,
    requested_artist_name: isArtist() ? dancer?.name : '',
    requested_instructor_name: isArtist() ? '' : dancer?.name,
    name: '',
    email: '',
    phoneNumber: '',
    status: 'TBD',
    comments_or_special_requests: '',

    // location: 'Charlotte, NC',
  });
  const {
    lessonDate,
    name,
    email,
    phoneNumber,
    comments_or_special_requests,
  } = details;

  const instructorHasClaimedTheirPage = () => {
    // dancer.claimed... means this dancer has a user account (this is for artist-instr.)
    //dancer.email says this dancer is an instr. only and can receieve privates
    if (dancer?.claimed_by_user_id || dancer?.email) {
      console.log('instructor is claimed and able to receive private bookings');
      return true;
    }
    return false;
  };

  const renderFooter = () => {
    if (emphasisOnSetADateLabel) {
      return <p style={{ backgroundColor: 'red' }}>Please pick a day.</p>;
    } else {
      return <p>Please pick a day.</p>;
    }
  };

  //   let footer = <p>Please pick a day.</p>;
  let footer = renderFooter();

  if (selected) {
    footer = <p>You picked {format(selected, 'PP')}.</p>;
  }
  if (updateDate) {
    setDetails({
      ...details,
      lessonDate: selected,
      requestor_id: user?._id,
      requestor_name: user ? user?.name : name,
      requestor_phone_number: user ? user?.phoneNumber : phoneNumber,
    });
    setUpdateDate(false);
  }

  if (showSelection) {
    // footer = <p>You picked {format(selected, 'PP')}.</p>;
    footer = <p>You picked {format(lessonDate, 'PP')}.</p>;

    // footer = <p>You picked {format(lessonDate}.</p>;
  }
  const setDatePlease = (selected) => {
    // setDate(selected)
    console.log('truing to set date from setDateplease');
    console.log(selected);
    setDetails({ ...details, lessonDate: selected });
  };

  const handleTimeChange = (time) => {
    console.log('changed time');
    console.log(time);
    setPrivateLessonTime(time);
    setDetails({ ...details, lessonTime: time });
  };
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });
  const onNumberChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
      requestor_phone_number: e.target.value,
    });
  };
  const onNameChange = (e) => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value,
      requestor_name: e.target.value,
    });
  };

  let times_array = [
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '09:30',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '13:30',
    '14:00',
    '14:00',
    '15:00',
    '16:00',
    '16:30',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
  ];

  const sendRequest = () => {
    if (isArtist()) {
      console.log('dancer is: ');
      console.log(dancer);
      console.log('artist api');

      if (lessonDate !== undefined) {
        // sendPrivateLessonRequestAtEvent(details);
        props.onSendPrivateLessonRequest(true);

        if (user) {
          if (lessonDate !== undefined) {
            // sendPrivateLessonRequestToInstructor(details);
            console.log('details from sendPrivateLessonRequest(details)');
            console.log(details);
            sendPrivateLessonRequest(details);
            props.onSendPrivateLessonRequest(true);
          } else {
            setEmphasisOnSetADateLabel(true);
          }
        } else {
          if (lessonDate !== undefined) {
            console.log('details non account');
            console.log(details);
            sendPrivateLessonRequestWithoutAccountToArtistInstructor(details);

            props.onSendPrivateLessonRequest(true);
          } else {
            setEmphasisOnSetADateLabel(true);
          }
        }
      } else {
        setEmphasisOnSetADateLabel(true);
      }
    } else {
      console.log('inst. api');

      //this means the dacner is an artist and can be booked using
      //requested_artist_id
      console.log('teacher is not artist');
      //use /instructor api

      if (user) {
        if (lessonDate !== undefined) {
          sendPrivateLessonRequestToInstructor(details);
          props.onSendPrivateLessonRequest(true);
        } else {
          setEmphasisOnSetADateLabel(true);
        }
      } else {
        if (lessonDate !== undefined) {
          console.log('details non account');
          console.log(details);
          sendPrivateLessonRequestWithoutAccountToInstructor(details);

          props.onSendPrivateLessonRequest(true);
        } else {
          setEmphasisOnSetADateLabel(true);
        }
      }

      console.log('details non account');
      console.log(details);
    }
  };

  // constonSubmitDoNothing

  const onSubmit = (e) => {
    e.preventDefault();

    console.log('user is');
    console.log(user);
    // sendPrivateLessonRequest(details);
    console.log('details');
    console.log(details);

    if (!user && (name === '' || email === '' || phoneNumber === '')) {
      setAlert('Please enter all fields', 'danger');
    } else if (!instructorHasClaimedTheirPage()) {
      setAlert(`${dancer?.name} hasn't registered yet.`, 'danger');
    } else {
      console.log('sending request g');
      //uncomment May 14
      sendRequest();
      console.log();
    }
  };

  const [addLessonPlanSelected, setAddLessonPlanSelected] = useState(false);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Book Private</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.bookPrivateSelected && (
            <div style={{ justifyContent: 'center' }}>
              <DayPicker
                mode="single"
                selected={selected}
                onSelect={setSelected}
                footer={footer}
                onDayClick={() => {
                  // if(stateUpdated()){

                  setSelected(selected);
                  setDatePlease(selected);
                  console.log('hello');
                  console.log('selected');
                  console.log(selected);
                  setDetails({ ...details, lessonDate: selected });
                  setUpdateDate(true);
                  // }
                }}
              />

              <div className="row">
                <div className="col">
                  <label>Workshop Time</label>
                </div>

                <div className="col">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      as={ButtonGroup}
                      key={idx}
                      id={`dropdown-button-drop-${idx}`}
                      size="sm"
                      variant="secondary"
                      title={privateLessonTime}
                      // title="10:40"
                      onSelect={handleTimeChange}
                      // onChange={onChange}
                    >
                      {times_array.map((time) => (
                        <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>
                      ))}
                    </DropdownType>
                  ))}
                </div>
                {!user && (
                  <div>
                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        id="name"
                        type="text"
                        name="name"
                        value={name}
                        onChange={onNameChange}
                        // onChange={onChange}
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <input
                        id="phoneNumber"
                        type="text"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={onNumberChange}
                        // onChange={onChange}
                        placeholder="Phone Number"
                        required
                      />
                    </div>{' '}
                  </div>
                )}

                <div style={{ paddingTop: '14px' }}>
                  <Button
                    onClick={() =>
                      setAddLessonPlanSelected(!addLessonPlanSelected)
                    }
                    style={{
                      background: addLessonPlanSelected ? 'Red' : 'white',
                      color: addLessonPlanSelected
                        ? 'white'
                        : 'rgb(1, 126, 252',
                      borderStyle: 'solid',
                      borderColor: addLessonPlanSelected
                        ? 'Red'
                        : 'rgb(1, 126, 252',
                    }}
                  >
                    {addLessonPlanSelected ? `Cancel` : `Add Lesson Plan`}
                  </Button>

                  {addLessonPlanSelected ? (
                    <div>
                      <LessonPlan details={details} setDetails={setDetails} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div>
                  <form
                    onSubmit={() =>
                      console.log('adding comments for private lesson')
                    }
                  >
                    {/* <form onSubmit={onSubmitDoNothing}> */}
                    <input
                      id="comments_or_special_requests"
                      type="text"
                      name="comments_or_special_requests"
                      placeholder="Leave comment or request for your instructor"
                      value={comments_or_special_requests}
                      onChange={onChange}
                      required
                    />
                  </form>
                </div>

                <Alerts />

                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <input
                      type="submit"
                      value={
                        // user ? 'Send Lesson Request' : 'Sign in to request'
                        instructorHasClaimedTheirPage()
                          ? 'Send Lesson Request'
                          : `${dancer?.name} hasn't yet registered.`
                      }
                      class="btn btn-secondary"
                      disabled={instructorHasClaimedTheirPage ? false : true}
                      // disabled={user ? false : true}
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BookPrivatePopup;
