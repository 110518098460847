import React, { useContext, useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useAuth, clearErrors, login } from '../../context/auth/AuthState';
import { useAuth } from '../../context/auth/AuthState';

import ProfileHeader from './ProfileHeader';
import UpcomingEventsForUser from '../events/UpcomingEventsForUser';
import Skills from './Artist/Skills/Skills';
import RankingsContext from '../../context/rankings/rankingsContext';
import { useParams } from 'react-router-dom';
import CurrentUserTour from './CurrentUserTour';
import AddNewTour from '../tour/AddNewTour';

// import { Link } from 'react-router-dom';
// import EditUserProfile from './EditUserProfile';

// export default class UserProfile extends Component {
const UserProfile = (props) => {
  let { id } = useParams(); //this is the id
  const [authState] = useAuth();
  const { user } = authState;

  const { target_user, joined_events, trips } = props;
  console.log('target_user after refresh');
  console.log(target_user);
  const isCurrentUserProfile = user?._id === target_user?._id;
  const rankingsContext = useContext(RankingsContext);
  const {
    getSkillsAndEndorsementsForUser,
    skills_and_endorsements,
    loading,
  } = rankingsContext;

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log('isCurrentUserProfile');
    console.log(isCurrentUserProfile);
    console.log('target_user ---');
    console.log(target_user);
    // getOtherArtists();
    setIsLoading(true);
    console.log('id is');
    console.log(id);
    getSkillsAndEndorsementsForUser({
      artist_id: '',
      user_id: user?._id === id ? user?._id : id,
    });
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);
  if (isLoading) return <h5>loading</h5>;
  return (
    <div>
      <ProfileHeader
        isCurrentUser={isCurrentUserProfile}
        current_user_id={user?._id}
        user={target_user}
        dancerWorldRanking={props.dancerWorldRanking}
        rankings={props.rankings}
        isAdmin={user?.email === 'airmeus.team@gmail.com'}
      />

      <h5>skills</h5>
      {/* {target_user?.primary_style && !isCurrentUserProfile ? ( */}
      {target_user?.primary_style ? (
        <Skills
          // artist_id={target_user?._id}
          // artist_id={isCurrentUserProfile ? user?._id : target_user?._id}
          artist_id={target_user?._id}
          dancer_types={[target_user?.primary_style]}
          // dancer_types={['kizomba']}
          skills_and_endorsements={skills_and_endorsements}
          isArtist={false}
          isCurrentUserProfile={isCurrentUserProfile}
        />
      ) : (
        <div>
          <h5>This user has no endorsable skills yet</h5>
        </div>
      )}

      <UpcomingEventsForUser
        isCurrentUser={isCurrentUserProfile}
        joined_events={joined_events}
        trips={trips}
      />

      <div className="row">
        <div className="col-7">
          <h1>Upcoming Tours</h1>
        </div>
        {user?.email === 'airmeus.team@gmail.com' ? (
          <div className="col">
            <AddNewTour
              instructor_id={target_user?._id}
              instructor_name={target_user?.name}
            />
            {/* <Button onClick={()=>{setAddNewTourClicked(!addNewTourClicked)}}>Add Tour</Button> */}
          </div>
        ) : (
          <></>
        )}
      </div>
      <CurrentUserTour instructor_id={target_user?._id} />

      {/* <h1>user profile</h1> */}
      {/* <h2>{user.name}</h2> */}
      {/* <h2>{props.user.name}</h2> */}

      <div>
        <div className="row">
          <div className="col-md-6">
            {/* <Link to="/profiles" className="btn btn-light mb-3 float-left">
              Back To Profiles
            </Link>
            <Link to="/profiles" className="btn btn-light mb-3 float-left">
              Edit Profile
            </Link> */}
            {/* <EditUserProfile /> */}
          </div>

          <div className="col-md-6" />
        </div>

        {/* <ProfileHeader profile={profile} /> */}
        {/* <ProfileAbout profile={profile} />
        <ProfileCreds
          education={profile.education}
          experience={profile.experience}
        />
        {profile.githubusername ? (
          <ProfileGithub username={profile.githubusername} />
        ) : null} */}
      </div>
    </div>
  );
};
export default UserProfile;
