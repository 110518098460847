import React, { Component, Fragment, useContext, useState } from 'react';
import { Button, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import UserContext from '../context/user/userContext';
import { useAuth } from '../context/auth/AuthState';

const Onboarding = (props) => {
  let { city, state, country } = props;
  const [authState] = useAuth();
  const { user } = authState;
  const userContext = useContext(UserContext);

  let { saveStyle } = userContext;

  const userTypes = [
    'Artist or DJ',
    'Instructor',
    'Dance Company Owner',
    'Event Organizer',
    'Student',
  ];
  let user_types_array = [];

  const updateTypes = async (e) => {};

  const onChange = (e) => {
    console.log('changing list -- e is:');
    // console.log(e.target);
    // setMemberArray(...user_types_array, e.target.name);
    // console.log(user_types_array);

    if (user_types_array.includes(e.target.id)) {
      console.log('removing user');
      user_types_array = user_types_array.filter(
        (item) => item !== e.target.id
      );
    } else {
      console.log('adding user');
      user_types_array.push(e.target.id);
    }

    const member_set = new Set(user_types_array);
    const uniqueMembers = [...member_set];

    console.log(user_types_array);
    // if (!userTypeSelected) {
    setUserTypeSelected(true);
    // setOnboardingScreensToRender(e.target.id);
    setOnboardingScreensToRender(
      [...onboardingScreensToRender, e.target.id]
      //   ['existing_studio_id']: splitValue2(e),
    );
    // }
  };

  const [userTypeSelected, setUserTypeSelected] = useState(false);
  const [onboardingScreensToRender, setOnboardingScreensToRender] = useState(
    []
  );

  const returnNextScreen = (onboardingScreensToRender) => {
    // console.log('route should be');
    // console.log(user_types_array[0]);
    // let nextScreen = "/"
    // if (user_types_array.length > 0) {

    //force order (artist -> instructor -> studio)
    if (onboardingScreensToRender.includes('Artist or DJ')) {
      return '/artist-onboarding';
    } else if (onboardingScreensToRender.includes('Instructor')) {
      return '/instructor-onboarding';
    } else if (onboardingScreensToRender.includes('Dance Company Owner')) {
      return '/studio-onboarding';
    } else if (onboardingScreensToRender.includes('Event Organizer')) {
    } else if (onboardingScreensToRender.includes('Student')) {
      return '/student-onboarding';
    } else if (onboardingScreensToRender.includes('Event Organizer')) {
      return '/';
    }
  };
  const [showDanceStyles, setShowDanceStyles] = useState(false);
  const [primaryStyle, setPrimaryStyle] = useState('bachata');
  const stylesArray = ['bachata', 'salsa', 'kizomba', 'zouk'];

  const handleStyleChange = (style) => {
    console.log('changed style');
    console.log(style);
    setPrimaryStyle(style);
    // setSelectedStyle(style);
    // setDetails({ ...details, lessonTime: time });
  };
  const renderDanceStyles = (
    <Fragment>
      <h5>What is your primary style of dance?</h5>
      <div className="col">
        {[DropdownButton].map((DropdownType, index) => (
          <DropdownType
            as={ButtonGroup}
            key={index}
            id={`dropdown-button-drop-${index}`}
            size="sm"
            variant="secondary"
            title={primaryStyle}
            // title="10:40"
            onSelect={handleStyleChange}
            // onChange={onChange}
          >
            {stylesArray.map((style) => (
              <Dropdown.Item eventKey={style}>{style}</Dropdown.Item>
            ))}
          </DropdownType>
        ))}
      </div>
    </Fragment>
  );

  const renderDynamicRoutes = () => {
    if (userTypeSelected) {
      //   if (user_types_array.length > 0 && user_types_array[0] === 'Artist') {
      if (onboardingScreensToRender.length > 0) {
        console.log('onboardingScreensToRender is:');
        console.log(onboardingScreensToRender);
        //force order
        let nextScreen = returnNextScreen(onboardingScreensToRender);
        console.log('nextScreen is');
        console.log(nextScreen);
        return (
          <Link
            to={nextScreen}
            state={{
              onboardingScreens: onboardingScreensToRender,
              city,
              state,
              country,
            }}
          >
            <Button>
              {onboardingScreensToRender.includes('Artist or DJ')
                ? 'Set up Artist Profile'
                : 'next'}
            </Button>
          </Link>
        );
      } else {
        console.log('user_types_array');
        console.log(user_types_array);
      }
    } else {
      if (user_types_array.length === 0) {
        return (
          // <Link to={'/'}>

          <div>
            <Button
              onClick={() => setShowDanceStyles(!showDanceStyles)}
              style={{ marginTop: '12px' }}
            >
              skip
            </Button>
          </div>
        );
      }
    }
  };

  const [userPrimaryStyleSaved, setUserPrimaryStyleSaved] = useState(false);

  const handleSaveStyle = (e) => {
    e.preventDefault();

    setUserPrimaryStyleSaved(!userPrimaryStyleSaved);
    saveStyle({ user_id: user._id, primaryStyle });
  };

  return (
    <div className="card">
      {!showDanceStyles && (
        <div>
          <h5>Are you either of the below users:</h5>
          {/* checkbox for user types
        artist, instructor, event organizer, 
        dance student
        dance studio owner
        business owner
        
        /// after selecting we will show onboarding flow based on selections
      */}
          {userTypes.map((type) => (
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name={type}
                id={type}
                value="option1"
                onChange={onChange}
              />

              <label class="form-check-label" for={type}>
                {type}
              </label>
            </div>
          ))}
          {renderDynamicRoutes()}
        </div>
      )}
      {showDanceStyles && (
        <div>
          {renderDanceStyles}
          {userPrimaryStyleSaved ? (
            <Link to={'/'}>
              <Button style={{ marginTop: '12px' }}>
                Onboard Complete, Let's Dance!{' '}
              </Button>
            </Link>
          ) : (
            <Button onClick={handleSaveStyle} style={{ marginTop: '12px' }}>
              save
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Onboarding;
/*

graveyard dec 17

  const dynamicUserTypeRoute = (array) => {
    console.log('route should be');
    console.log(user_types_array[0]);
    // let nextScreen = "/"
    // if (user_types_array.length > 0) {
    switch (array[0]) {
      case 'Artist':
        //   return '/artist-onboarding';
        return '/terms-of-service';

      case 'Instructor':
        // return '/instructor-onboarding';
        return '/terms-of-service';
      case 'event organizer':
        return '/event-organizer-onboarding';
      case 'dance studio owner':
        return '/studio-onboarding';
      case 'dance student':
        return '/student-onboarding';

      default:
        console.log('defualt route running');
        return '/privacy-policy';
    }
    // } else {
    //   return '/artist-onboarding';
    //   console.log('user_types_array');
    //   console.log(user_types_array);
    // }
    // return nextScreen;
  };


*/
