//consider renaming to potential_mate to differentiate btw user
import React, { useContext, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import EventItem from './EventItem';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { useAuth } from '../../context/auth/AuthState';
import { Navigate } from 'react-router-dom';

const Event = () => {
  const [authState] = useAuth();

  const { user } = authState;
  const eventContext = useContext(EventContext);

  const { getAllEvents, events, createNewEvent } = eventContext;

  useEffect(() => {
    console.log(
      'getting events from Event.js but need to change component name'
    );
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    getAllEvents();
    // eslint-disable-next-line
  }, []);
  const [visible, setVisible] = React.useState(false);
  const alertContext = useContext(AlertContext);

  const [details, setDetails] = useState({
    descr: '',
    eventName: '',
    start_date: '',
    end_date: '',
  });

  const { setAlert } = alertContext;

  const { descr, eventName, start_date, end_date } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const onSubmit2 = (e) => {
    e.preventDefault();
    if (descr === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      // login(authDispatch, {
      //   descr,
      // });
      // request_roommate();

      createNewEvent(eventName, descr, user._id);
    }
  };

  const userLoggedIn = (
    <Fragment>
      <button onClick={() => setVisible(!visible)} class="btn btn-secondary">
        {visible ? 'Cancel' : 'Create Event'}
      </button>
      {visible && (
        <div className="form-container">
          <form onSubmit={onSubmit2}>
            <div className="form-group">
              <h1>Event Details</h1>
              <input
                id="eventName"
                type="text"
                name="eventName"
                placeholder="Event Name yo"
                value={eventName}
                onChange={onChange}
                required
              />
              <input
                id="descr"
                type="text"
                name="descr"
                placeholder="Event Description"
                value={descr}
                onChange={onChange}
                required
              />

              <input
                id="start_date"
                type="date"
                name="start_date"
                placeholder="Start Date"
                value={start_date}
                onChange={onChange}
                required
              />

              <input
                id="end_date"
                type="date"
                name="end_date"
                placeholder="End Date"
                value={end_date}
                onChange={onChange}
                required
              />

              {/* <label for="birthday">Birthday:</label>
  <input type="date" id="birthday" name="birthday"> */}
            </div>

            <input
              type="submit"
              value="Create Event"
              class="btn btn-secondary"
            />
          </form>
        </div>
      )}
    </Fragment>
  );

  const loginToCreateEvent = (
    <Fragment>
      <Link to="/login">
        <Button variant="primary">Login To Create Events</Button>
      </Link>
    </Fragment>
  );

  return (
    <div>
      <div>
        {/* <button onClick={() => setVisible(!visible)} class="btn btn-secondary">
          {visible ? 'Create Event' : 'Create Event'}
        </button> */}
        {!user && !visible ? loginToCreateEvent : userLoggedIn}
      </div>
      {events.map((event) => (
        <EventItem key={event.id} event={event} />
      ))}
      {/* <h1>hi</h1> */}
    </div>
  );
};

Event.propTypes = {};

export default Event;
