import React, { Component } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddWorkshopFields from './AddWorkshopFields';

const AddWorkshopPopup = (props) => {
  const [show, setShow] = useState(props.visible);
  const handleClose = () => props.setVisible(false);
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Workshop</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddWorkshopFields
            event={props.event}
            takenWorkshopRoomsMap={props.takenWorkshopRoomsMap}
            current_user={props.current_user}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddWorkshopPopup;
