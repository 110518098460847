import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import React, { Component } from 'react';
import EventsSearchBar from './EventsSearchBar';
import EventOrganizerRegisterAndOnboard from './EventOrganizerRegisterAndOnboard';

const SearchEventsPopup = (props) => {
  const [show, setShow] = useState(props.searchEventsClicked);
  const handleClose = () => {
    setShow(false);
    props.setSearchEventsClicked(false);
  };
  const [selectedEvent, setSelectedEvent] = useState({});

  const [registerEventOrganizer, setRegisterEventOrganizer] = useState(false);
  const [safetyOfficerAndEventId, setSafetyOfficerAndEventId] = useState([]);
  return (
    <div>
      <Modal show={props.searchEventsClicked} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Safety Officer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!registerEventOrganizer ? (
            <EventsSearchBar
              setRegisterEventOrganizer={setRegisterEventOrganizer}
              setSelectedEvent={setSelectedEvent}
              selectedEvent={selectedEvent}
              setSafetyOfficerAndEventId={setSafetyOfficerAndEventId}
            />
          ) : (
            <EventOrganizerRegisterAndOnboard
              registerEventOrganizer={registerEventOrganizer}
              event={selectedEvent}
              safetyOfficerAndEventId={safetyOfficerAndEventId}
            />
          )}

          {/* 
          {registerEventOrganizer ? (
            <div>
              <h5>event organizer onboarding</h5>
            </div>
          ) : (
            <></>
          )} */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SearchEventsPopup;
