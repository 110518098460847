import styled from '@emotion/styled';
import GlobalStyles from './prebuilt/GlobalStyles';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

//dev
// const PUBLIC_KEY = 'pk_test_S9vGbKw6oRNtRROsFtGAIJr4';

//prod
const PUBLIC_KEY = 'pk_live_sGdf3njWtyt2trZWxUD4sQjw';

// const stripePromise = loadStripe(process.env.PUBLISHABLE_KEY);
const stripePromise = loadStripe(PUBLIC_KEY);

// TIP
// call loadStripe outside of a component
// in that way there's no chance it will get
// called more times than it needs to

const Layout = ({ children, title }) => {
  return (
    <>
      <GlobalStyles />
      {/* <Head> */}
      <div>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {/* </Head> */}
      </div>
      <Elements stripe={stripePromise}>{children}</Elements>
    </>
  );
};

export default Layout;
