import React, { Component, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import IncidentReportContext from '../../context/incidentReport/incidentReportContext';

const VoteOnResolution = (props) => {
  useEffect(() => {
    allPreliminaryStepsDone();
    // eslint-disable-next-line
  }, []);

  const [uncompletedSteps, setUncompletedSteps] = useState([]);
  const incidentReportContext = useContext(IncidentReportContext);
  const {
    loading,
    getIncidentReport,
    incident_report,
    submitVote,
  } = incidentReportContext;

  let votes = [
    { vote: 'Yes' },
    { vote: 'No' },
    // { vote: 'Yes' },
    // { vote: 'Yes' },
  ];

  const getColor = (vote) => {
    console.log('vote obj');
    console.log(vote);
    if (vote.vote === 'Yes') return 'green';
    if (vote.vote === 'No') return 'red';
  };
  const getVote = (vote) => {
    if (vote.vote === 'Yes') return 'green';
    if (vote.vote === 'No') return 'red';
  };

  const userAlreadyVoted = () => {
    console.log('trying useralreadyvotged');
    let votes = props.incidentReport?.vote_of_resolution;
    console.log('votes r');
    console.log(votes);
    if (votes && votes.length > 0) {
      for (let voter = 0; voter < votes.length; voter++) {
        console.log('votes[voter].voter_user_id');
        console.log(votes[voter].voter_user_id);
        console.log('props.voter_user_id');
        console.log(props.voter_user_id);
        if (votes[voter].voter_user_id === props.voter_user_id) {
          console.log('voter already voted');

          return true;
        }
      }
    }
    console.log('user has not voted');
    return false;
  };

  const isCurrentUserVote = (vote, index) => {
    if (vote.voter_user_id === props?.voter_user_id) return 'Your Vote';
    else return `council member ${index + 1}`;
  };

  const isObjectEmpty = (objectName) => {
    for (let prop in objectName) {
      if (objectName.hasOwnProperty(prop)) {
        console.log('object not empty');
        return false;
      }
    }
    console.log('object empty');
    return true;
  };

  const allPartiesContacted = () => {
    let ir = props.incidentReport;

    if (!ir.reporter_contacted) {
      console.log('not contacted reporter');

      return false;
    }
    for (let i = 0; i < ir.witnesses.length; i++) {
      if (!ir.witnesses[i].witness_contacted) {
        console.log('not contacted witness');

        return false;
      }
    }

    if (!ir.accused_contacted) {
      console.log('not contacted accused');
      return false;
    }
    return true;
  };

  const setStateHandler = (msg) => {
    setUncompletedSteps([...uncompletedSteps, msg]);
  };

  const allPreliminaryStepsDone = () => {
    let ir = props.incidentReport;

    if (ir.incident_level === 'TBD') {
      console.log('incident level not set');
      setStateHandler('- Incident level not set');

      //   setUncompletedSteps([...uncompletedSteps, 'incident level not set']);
      return false;
    }

    if (!allPartiesContacted()) {
      //   setUncompletedSteps([...uncompletedSteps, 'all parties not contacted']);
      setStateHandler('- All parties have not been contacted');
      console.log('allPartiesContacted not contacted');
      return false;
    }

    if (!('summary_of_investigation' in ir)) {
      console.log('summary not done');

      setStateHandler('- Summary of investigation not completed');
      //   setUncompletedSteps([
      //     ...uncompletedSteps,
      //     'summary of investigation not completed',
      //   ]);

      console.log('1- testing summary_of_investigation completed or not');
      return false;
    } else {
      console.log('2- testing summary_of_investigation completed or not');
    }

    if (!('resolution_recommendation' in ir)) {
      console.log('summary not done');

      setStateHandler(
        '- A Recommendation of resolution has not been completed'
      );
      //   setUncompletedSteps([
      //     ...uncompletedSteps,
      //     'summary of investigation not completed',
      //   ]);

      console.log('1- testing summary_of_investigation completed or not');
      return false;
    }

    return true;
  };

  return (
    <div style={{}} className="card">
      {/* <h1>Vote On Resolution</h1> */}

      {/* {allPreliminaryStepsDone() ? ( */}
      {uncompletedSteps.length === 0 ? (
        <div>
          {userAlreadyVoted() ? (
            <></>
          ) : (
            <div
              className="card"
              style={{ backgroundColor: 'orange', width: '' }}
            >
              <h5 style={{ color: 'white' }}>
                Do you agree with the recommended resolution?
              </h5>

              <div className="row" style={{ alignContent: 'center' }}>
                <div className="col-3">
                  <Button
                    style={{ backgroundColor: 'white', color: 'black' }}
                    onClick={() => {
                      submitVote({
                        vote: 'Yes',
                        voter_user_id: props.voter_user_id,
                        incident_id: props.incidentReport._id,
                      });
                    }}
                  >
                    Yes
                  </Button>
                </div>
                <div className="col">
                  <Button
                    style={{ backgroundColor: 'white', color: 'black' }}
                    onClick={() => {
                      submitVote({
                        vote: 'No',
                        voter_user_id: props.voter_user_id,
                        incident_id: props.incidentReport._id,
                      });
                    }}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <h5 style={{ color: 'red' }}>
            Voting will start once steps 1-3 have been completed
          </h5>
          {uncompletedSteps.map((step) => (
            <h5 style={{ color: 'red' }}>{step}</h5>
          ))}
        </>
      )}

      {props.incidentReport?.vote_of_resolution &&
      props.incidentReport?.vote_of_resolution.length > 0 ? (
        <div className="card">
          <h1 style={{ color: 'blue' }}>Council Member Votes Below</h1>
          <br />
          {props.incidentReport?.vote_of_resolution.map((vote, index) => (
            <div className="row">
              <div className="col">
                <h5>
                  {isCurrentUserVote(vote, index)}
                  {/* council member {index + 1} */}
                </h5>
              </div>
              <div className="col">
                <h5 style={{ color: getColor(vote) }}>
                  {/* {getVote(vote)} */}
                  {vote.vote}
                </h5>
              </div>
              <hr />
            </div>
          ))}
        </div>
      ) : (
        <div className="card">
          {votes.map((vote, index) => (
            <div className="row">
              <div className="col">council member {index + 1}</div>
              <div className="col">
                <h5 style={{ color: getColor(vote) }}>
                  {/* {getVote(vote)} */}
                  {vote.vote}
                </h5>
              </div>
              <hr />
            </div>
          ))}
          <h1 style={{ color: 'gray', fontSize: '12px' }}>EXAMPLE</h1>
        </div>
      )}
    </div>
  );
};

export default VoteOnResolution;
