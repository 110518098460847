import React, { useReducer } from 'react';
import axios from 'axios';
import RankingsContext from './rankingsContext'; //be sure the case is not case sensitive
import RankingsReducer from './rankingsReducer';

import {
  GET_RANKINGS,
  GET_SKILLS_AND_ENDORSEMENTS,
  SUBMIT_ENDORSEMENT,
  GET_FILTERED_RANKINGS,
  GET_RANDOM_RANKINGS,
  RANKINGS_ERROR,
} from '../types';
const RankingsState = (props) => {
  const initialState = {
    rankings: {},
    skills_and_endorsements: [],
    loading: true,
    randomRankingsForTimeline: [],
    randomStyle: '',
    // confirmedPrivateLessons: {},
  };

  const [state, dispatch] = useReducer(RankingsReducer, initialState);

  // const getRankings = async (dance_style) => {
  const getRankings = async (style) => {
    try {
      console.log('style from rankingstate');
      console.log(style);
      console.log('trying getRankings');
      const res = await axios.get(`/api/rankings/${style}`);
      console.log('res below from getRankings rankingsstate.js');
      console.log(res);

      dispatch({
        type: GET_RANKINGS,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: RANKINGS_ERROR,
        payload: err,
      });
    }
  };

  const getSkillsAndEndorsementsForUser = async (obj) => {
    try {
      console.log('trying getSkillsAndEndorsementsForUser');
      // const res = await axios.get(`/api/skills-and-endorsements`, obj);
      let { artist_id, user_id } = obj;
      let res;

      if (obj.user_id === '') {
        res = await axios.get(
          `/api/skills-and-endorsements/artist/${artist_id}`
        );
      } else {
        res = await axios.get(`/api/skills-and-endorsements/user/${user_id}`);
      }
      console.log('res below from getSkillsAndEndorsementss');
      console.log(res);

      dispatch({
        type: GET_SKILLS_AND_ENDORSEMENTS,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: RANKINGS_ERROR,
        payload: err,
      });
    }
  };

  const submitEndorsement = async (obj) => {
    try {
      console.log('obj is z');
      console.log(obj);
      const res = await axios.post(
        // `/api/rankings/skills/endorseUserSkills`,
        `/api/skills-and-endorsements/endorseUserSkills`,
        obj
      );
      console.log('res below from submitEndorsement rankingstate.js');
      console.log(res);

      dispatch({
        type: SUBMIT_ENDORSEMENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: RANKINGS_ERROR,
        payload: err,
      });
    }
  };

  const getFilteredRankings = async (locationFilterObj) => {
    try {
      const { style, city, state, country } = locationFilterObj;
      console.log('style, city, state, country -- style should be undefined');
      console.log(style, city, state, country);
      console.log('trying getFilteredRankings');
      const res = await axios.get(
        `/api/rankings/${style}/${city}/${state}/${country}`
      );
      console.log('res below from getFilteredRankings');
      console.log(res);

      dispatch({
        type: GET_FILTERED_RANKINGS,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: RANKINGS_ERROR,
        payload: err,
      });
    }
  };
  const getRandomFourRankingsForTimeline = async () => {
    try {
      console.log('trying getRandomFour');
      const res = await axios.get(
        `/api/rankings/getFourRankingsForTimeline/random`
      );
      console.log('res below from getRandomFour');
      console.log(res);

      dispatch({
        type: GET_RANDOM_RANKINGS,
        payload: res.data,
        loading: false,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: RANKINGS_ERROR,
        payload: err,
      });
    }
  };

  return (
    <RankingsContext.Provider
      value={{
        // props -- where our state and actions will go

        loading: state.loading,
        rankings: state.rankings,
        skills_and_endorsements: state.skills_and_endorsements,
        randomRankingsForTimeline: state.randomRankingsForTimeline,
        randomStyle: state.randomStyle,
        error: state.error,

        getSkillsAndEndorsementsForUser,
        getRankings,
        submitEndorsement,
        getFilteredRankings,
        getRandomFourRankingsForTimeline,
      }}
    >
      {props.children}
    </RankingsContext.Provider>
  );
};

export default RankingsState;
