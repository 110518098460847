import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { useContext, useEffect } from 'react';
import UserSearchItem from './UserSearchItem';
import { useAuth } from '../../context/auth/AuthState';
import PillFilter from './PillFilter';

const SearchResults = (props) => {
  const userContext = useContext(UserContext);
  const {
    getUsersForSearchCriteria,
    userSearchResults,
    connections,
    // getConnectionsForUserWithId,
    getConnections,
  } = userContext;
  // send search to backend ->
  //update state with the function, reducer,
  console.log('props is:');
  console.log(props);
  // const { id } = route.params;

  const [authState] = useAuth();

  const { user } = authState;

  const search = useLocation().search;
  const searchText = new URLSearchParams(search).get('searchText');
  console.log('userSearchResults ---');
  console.log(userSearchResults);
  useEffect(() => {
    // console.log('user?._id');
    // console.log(user._id);
    getConnections(user?._id);

    getUsersForSearchCriteria(searchText);
    console.log('connections');
    console.log(connections);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {/* <div>
        <PillFilter />
      </div> */}

      <h1>search results</h1>

      {userSearchResults.map((user) => (
        <UserSearchItem
          key={user._id}
          target_user={user}
          connections={connections}
        />
      ))}
    </div>
  );
};

export default SearchResults;
