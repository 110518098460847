import React, { Component, useContext, useEffect } from 'react';
import IncidentReportContext from '../../context/incidentReport/incidentReportContext';
import { Button } from 'react-bootstrap';
import { useAuth } from '../../context/auth/AuthState';

const SuspensionList = () => {
  const incidentReportContext = useContext(IncidentReportContext);
  const { getSuspensionList, suspensionList, loading } = incidentReportContext;
  const [authState, authDispatch] = useAuth();
  const { user } = authState;
  useEffect(() => {
    getSuspensionList();
    // eslint-disable-next-line
  }, []);

  let list = [
    { name: 'tom', report_id: '123', duration: '2 months' },
    { name: 'tom', report_id: '123', duration: '2 months' },
    { name: 'tom', report_id: '123', duration: '2 months' },
  ];
  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div className="card" style={{ padding: '10px' }}>
      {user &&
      (user?.email === 'airmeus.team@gmail.com' ||
        user?.safety_council_member === true) ? (
        <div>
          <h1>Suspension List</h1>

          <div className="row">
            <div className="col-4">
              <h5>Name</h5>
            </div>

            <div className="col-4">
              <h5>duration</h5>
            </div>

            <div className="col">
              <h5>report_id</h5>
            </div>
          </div>
          {suspensionList?.map((person) => (
            <div className="row">
              <div className="col-4">
                <h5>{person.suspended_person_name}</h5>
              </div>

              <div className="col-4">
                <h5
                  style={{
                    width: '80px',
                    background: 'white',

                    borderStyle: 'solid',
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderRadius: '2px',

                    fontSize: '14px',
                    backgroundColor: 'white',
                    color: 'red',
                    textAlign: 'center',
                  }}
                >
                  {person.suspension_duration} months
                </h5>
              </div>

              <div className="col">
                <Button>
                  {/* {person.incident_report_id} */}
                  Report
                </Button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <h5>access denied. go home</h5>
        </>
      )}
    </div>
  );
};
export default SuspensionList;
