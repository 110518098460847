import React from 'react';
import userContext from '../../context/user/userContext'; // might have to capitalize
// import MyEvents from '../../components/events/MyEvents'; // might have to capitalize
// import AuthContext from '../../context/auth/authContext';
// import { useAuth, loadUser } from '../../context/auth/AuthState';
import { useAuth } from '../../context/auth/AuthState';
import spinner from '../../components/events/spinner.gif';

import { Navigate } from 'react-router-dom';

import { useContext, useEffect } from 'react';
import UserEvents from './UserEvents';

// export default class UpcomingEvents extends Component {

const UpcomingEvents = (props) => {
  const [authState] = useAuth();
  const { user, isAuthenticated, loading } = authState;
  const uContext = useContext(userContext);
  const {
    getJoinedEventsForUser,
    joined_events,
    trips,
    loading2,
    getUserEvents,
    user_events2,
  } = uContext;
  // const authContext = useContext(AuthContext);
  console.log('user_events2 --');
  console.log(user_events2);
  useEffect(
    () => {
      // authContext.loadUser();
      // loadUser();
      console.log('getting events');
      //returns all users -- we need to paginate this so we only get one at a time.
      //that comes later

      console.log('trying to getJoinedEventsForUser() from upcomingevents.js');
      // console.log(user);
      if (isAuthenticated && user != null) {
        console.log('user is authenticated');
        console.log('user._id is ---------------');
        console.log(user._id);
        // getJoinedEventsForUser(user._id);
        getUserEvents(user._id);
      }

      //there is an error thrown when we reload page
      //bcuz we are calling useEffect and getting "user" object from authState
      //figure out how to fix this or call the user._id from somewhere else..
      // getJoinedEventsForUser('6248c1337e4d6d57c43991f1'); //-this works without error on reload

      // eslint-disable-next-line
    },
    [isAuthenticated]
  );
  if (!isAuthenticated && !loading) {
    return <Navigate to="/login" />;
  }
  if (loading2) {
    return <h2>Loading...</h2>;
  }
  const showDeleteButton = props?.showDeleteButton;

  // if (loading2) {
  //   return (
  //     <img
  //       src={spinner}
  //       style={{ width: '200px', margin: 'auto', display: 'block' }}
  //       alt="Loading..."
  //     />
  //   );
  // }
  return (
    <div>
      {user_events2 && user_events2.length > 0 ? (
        <div>
          <h5>Events</h5>
          <div
            class="container-fluid  overflow-auto example"
            style={{ padding: '0px' }}
          >
            <div class="d-flex flex-row flex-nowrap">
              {user_events2?.map((event) => (
                <div className="card-horizontal-menu card-block mx-2">
                  <UserEvents event={event} />
                  {/* <h5>{event._id}</h5> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UpcomingEvents;
