import React, { Component } from 'react';
import SafeEventsScrollMenu from '../incidentReport/SafeEventsScrollMenu';
import { Button } from 'react-bootstrap';

const SafeEvents = () => {
  const mockImage =
    'https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg';

  return (
    <div style={{ padding: '10px' }}>
      <div className="card-colored-border " style={{ paddingTop: '' }}>
        <h5
          style={{
            fontSize: '32px',
            marginTop: '12px',
            marginBottom: '12px',
            // color: 'blue',
            background: 'white',

            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
            borderWidth: '2px',
            borderRadius: '6px',

            // fontSize: '14px',
            backgroundColor: 'rgb(1, 126, 252)',
            color: 'white',
            textAlign: 'center',
          }}
        >
          What Are Safe Events ?
        </h5>

        <SafeEventsScrollMenu />
        <p style={{ color: 'gray', textAlign: 'center' }}>
          we will update these as needed --
          {'>'} --
          {'>'}
        </p>
      </div>

      <div style={{ paddingTop: '16px' }}>
        <div className="row">
          <div className="col-2">
            <h1
              style={{
                fontSize: '32px',
                // marginTop: '12px',
                // marginBottom: '12px',
                // color: 'blue',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                // fontSize: '14px',
                backgroundColor: 'rgb(1, 126, 252)',
                color: 'white',
                textAlign: 'center',
                width: '40px',
              }}
            >
              1
            </h1>
          </div>
          <div className="col">
            <h1
              style={{
                fontSize: '32px',
              }}
            >
              Add Your Safety Officer
            </h1>
          </div>
        </div>
      </div>
      <div>
        <div className="card">
          {/* <h5>Add Safety Officer</h5> */}
          <div className="row">
            <div className="col-4">
              {/* <h5>image</h5> */}
              <img
                src={mockImage}
                style={{
                  height: '100px',
                  width: '100px',
                }}
                alt="user"
              />
            </div>

            <div className="col">
              <div className="row">
                <Button
                  onClick={
                    () => console.log('adding')
                    // setAddSafetyOfficerSelected(!addSafetyOfficerSelected)
                  }
                  style={{ fontSize: '24px', backgroundColor: 'lightgreen' }}
                >
                  Add Safety Officer
                </Button>
              </div>
              <hr />
              <div className="row">
                <h5 style={{ fontSize: '12px' }}>
                  Add a safety officer so you can respond to incidents in real
                  time
                </h5>
              </div>
            </div>
          </div>
          {/* {addSafetyOfficerSelected ? (
        <AddSafetyOfficerPopup
          addSafetyOfficerSelected={addSafetyOfficerSelected}
          event_id={props?.event._id}
        />
      ) : (
        <></>
      )} */}
        </div>
      </div>

      <div style={{ paddingTop: '16px' }}>
        <div className="row">
          <div className="col-2">
            <h1
              style={{
                fontSize: '32px',
                // marginTop: '12px',
                // marginBottom: '12px',
                // color: 'blue',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                // fontSize: '14px',
                backgroundColor: 'rgb(1, 126, 252)',
                color: 'white',
                textAlign: 'center',
                width: '40px',
              }}
            >
              2
            </h1>
          </div>
          <div className="col">
            <h1
              style={{
                fontSize: '32px',
              }}
            >
              Become Safe Event Verified
            </h1>
          </div>
        </div>

        <h5
          style={{
            fontSize: '32px',
            // marginTop: '12px',
            // marginBottom: '12px',
            // color: 'blue',
            background: 'white',

            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
            borderWidth: '2px',
            borderRadius: '6px',

            // fontSize: '14px',
            backgroundColor: 'rgb(1, 126, 252)',
            color: 'white',
            textAlign: 'center',
            // width: '40px',
          }}
        >
          Safe Event ✔️
        </h5>
      </div>
    </div>
  );
};

export default SafeEvents;
