import styled from 'styled-components';

const BlogSidePanelStyle = styled.div`
  body {
    font-family: 'Lato', sans-serif;
  }

  .sidenav {
    width: 300px;
    position: fixed;
    z-index: 1;
    top: 150px;
    right: 50px;
    background: #eee;
    overflow-x: hidden;
    padding: 8px 0;
  }

  .sidenav2 {
    width: 230px;
    position: fixed;
    z-index: 1;
    top: 500px;
    right: 60px;
    background: #eee;
    overflow-x: hidden;
    padding: 8px 0;
  }

  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: black;
    display: block;
  }

  .sidenav2 a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #2196f3;
    display: block;
  }

  .sidenav a:hover {
    color: #064579;
  }

  .main {
    margin-right: 140px; /* Same width as the sidebar + left position in px */
    font-size: 28px; /* Increased text to enable scrolling */
    padding: 0px 10px;
  }

  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }
`;

export default BlogSidePanelStyle;
