import React, { Component, useState } from 'react';
import { Modal } from 'react-bootstrap';
import AddNewArtist from './AddNewArtist';

const AddNewArtistPopup = (props) => {
  const [show, setShow] = useState(props.addNewArtistClicked);
  const handleClose = () => setShow(false);
  return (
    <div>
      {/* <h5>AddNewArtistPopup</h5> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Dancer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddNewArtist
            setShowNewArtistCreatedMsg={props?.setShowNewArtistCreatedMsg}
            setShow={setShow}
            userIsOnboarding={props?.userIsOnboarding}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddNewArtistPopup;
