import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import IncidentReportContext from '../../context/incidentReport/incidentReportContext';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
} from 'react-bootstrap';
import Witnesses from './Witnesses';
import ContactReporter from './ContactReporter';
import ContactAccused from './ContactAccused';
import VoteOnResolution from './VoteOnResolution';
import authContext from '../../context/auth/authContext';
import { useAuth } from '../../context/auth/AuthState';
import InvestigationSteps from './InvestigationSteps';
import ReporterView from './ReporterView';

const IncidentReport2Updated = () => {
  // const [loading, setLoading] = useState(false);
  let { incident_report_id } = useParams();
  let incidentReportContext = useContext(IncidentReportContext);
  const {
    loading,
    getIncidentReport,
    incident_report,
    updateIncidentReport,
    suspendPerson,
  } = incidentReportContext;

  const [authState] = useAuth();

  const { user } = authState;

  useEffect(() => {
    // setLoading(true);
    console.log('{incident_report_id}');
    console.log(incident_report_id);
    getIncidentReport(incident_report_id);
    // setLoading(false);
    // getIncidentReportForReporter(user_id); -- //only certain things should be available to reporter
    // eslint-disable-next-line
  }, []);

  const [details, setDetails] = useState({
    // summary_of_investigation: incident_report?.summary_of_investigation
    //   ? incident_report?.summary_of_investigation
    //   : '',
    // recommendation_of_duration_of_suspension_in_months: 0,
    // resolution_recommendation: {},
    // incident_level: 'Level 1',
  });

  const {
    // summary_of_investigation,
    recommendation_of_duration_of_suspension_in_months,
    incident_level,
  } = details;

  const [investigationSummary, setInvestigationSummary] = useState(
    incident_report?.summary_of_investigation
      ? incident_report?.summary_of_investigation
      : ''
  );

  const [suspensionSelected, setSuspensionSelected] = useState(false);
  const [offenseLevel, setOffenseLevel] = useState('Level 1');

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log('e.target.value fromn summary');
    console.log(e.target.value);
    setInvestigationSummary(e.target.value);
  };

  const [
    witnessContactedUpdateShowButton,
    setWitnessContactedUpdateShowButton,
  ] = useState(false);

  const [witnessList, setWitnessList] = useState([]);

  const setFromChild = (w, e) => {
    console.log('working');
    onContactedPersonChange(w, e);
  };
  const [
    reporterContactedUpdateShowButton,
    setReporterContactedUpdateShowButton,
  ] = useState(false);
  const [
    accusedContactedUpdateShowButton,
    setAccusedContactedUpdateShowButton,
  ] = useState(false);
  //sent to child
  const onReporterContactedUpdate = (incidentReporter, e) => {
    //add to state
    //check if person already contacted

    setDetails({
      ...details,
      reporter_contacted: e.target.checked,
    });
  };
  const onAccusedContactedUpdate = (incidentReporter, e) => {
    //add to state
    //check if person already contacted

    setDetails({
      ...details,
      accused_contacted: e.target.checked,
    });
  };

  const onContactedPersonChange = (witness, e) => {
    console.log('e is');
    console.log(e.target.checked);
    // setDetails({ ...details, [e.target.name]: e.target.value });
    // console.log('e.target.value fromn summary');
    // console.log(e.target.value);
    // setInvestigationSummary(e.target.value);

    //add contacted person to obj to send thru to backend
    // setDetails({ ...details, [e.target.name]: e.target.value });

    //only in local state add witnesses from incident_report from backend
    if (witnessList.length === 0) {
      let updatedWitness = {
        witness_contacted: e.target.checked,
        witness_name: witness.witness_name,
        witness_phone_number: witness.witness_phone_number,
      };

      console.log('updatedWitness');
      console.log(updatedWitness);

      //add updatedWitness to obj to send to backedn

      // setDetails({ ...details, [e.target.name]: e.target.value });

      let newListWithoutUpdatedWitness =
        //   ...incident_report.witnesses,

        incident_report.witnesses.filter(
          (witness) => witness.witness_name !== updatedWitness.witness_name
        );

      console.log('newListWithoutUpdatedWitness');
      console.log(newListWithoutUpdatedWitness);

      let newWitnessListWithUpdatedWitness = [
        ...newListWithoutUpdatedWitness,
        updatedWitness,
      ];
      console.log('final updated list ');
      console.log(newWitnessListWithUpdatedWitness);

      //update state

      setWitnessList(newWitnessListWithUpdatedWitness);

      //add witness list to state to update

      setDetails({ ...details, witnesses: newWitnessListWithUpdatedWitness });
    } else {
      //this means we have already updated our local state for witnesses,so we can use
      //witnesslist in local state to update instead of backend incident_report.witnesses
      console.log('second time hit else updating properly??');
      let updatedWitness = {
        witness_contacted: e.target.checked,
        witness_name: witness.witness_name,
        witness_phone_number: witness.witness_phone_number,
      };

      console.log('updatedWitness');
      console.log(updatedWitness);

      //add updatedWitness to obj to send to backedn

      // setDetails({ ...details, [e.target.name]: e.target.value });

      let newListWithoutUpdatedWitness =
        //   ...incident_report.witnesses,

        witnessList.filter(
          (witness) => witness.witness_name !== updatedWitness.witness_name
        );

      console.log('newListWithoutUpdatedWitness');
      console.log(newListWithoutUpdatedWitness);

      let newWitnessListWithUpdatedWitness = [
        ...newListWithoutUpdatedWitness,
        updatedWitness,
      ];
      console.log('final updated list -- propert??');
      console.log(newWitnessListWithUpdatedWitness);

      //update state

      setWitnessList(newWitnessListWithUpdatedWitness);

      //add witness list to state to update
      setDetails({ ...details, witnesses: newWitnessListWithUpdatedWitness });
    }
    //auto update
    // updateIncidentReport({witnesses:newWitnessListWithUpdatedWitness})
  };

  const onRecommendationChange = (e) => {
    console.log('e.target.value');
    console.log(e.target.value);
    // setDetails({
    //   ...details,
    //   police_report_filed_or_will_be_filed: e.target.value,
    // });

    console.log('e.target.value');
    console.log(e.target.value);

    if (e.target.value === 'Suspension') {
      setSuspensionSelected(true);
      console.log('show suspension duration:');
      setDetails({
        ...details,
        resolution_recommendation: {
          suspension: true,
          warning_given: false,
          no_action: false,
          //   recommendation_of_duration_of_suspension_in_months: numberOfMonths,
        },
      });
    }
    if (e.target.value === 'Warning Given') {
      console.log('hit 1');
      setDetails({
        ...details,
        resolution_recommendation: {
          warning_given: true,
          suspension: false,
          no_action: false,
        },
      });
      setSuspensionSelected(false);
    }
    if (e.target.value === 'No Action') {
      console.log('hit 2');
      setDetails({
        ...details,
        resolution_recommendation: {
          no_action: true,
          warning_given: false,
          suspension: false,
        },
      });
      setSuspensionSelected(false);
    }
  };
  let suspensionMonthsArray = Array.from({ length: 24 }, (_, i) => i + 1);
  const [suspensionMonths, setSuspensionMonths] = useState(1);
  const handleSuspensionDurationChange = (numberOfMonths) => {
    console.log('changed numberOfMonths');
    console.log(numberOfMonths);
    setSuspensionMonths(numberOfMonths);
    if (suspensionSelected) {
      setDetails({
        ...details,
        resolution_recommendation: {
          suspension: true,
          recommendation_of_duration_of_suspension_in_months: numberOfMonths,
        },
      });
    }
  };
  const handleOffenseLevelChange = (level) => {
    console.log('changed level');
    console.log(level);
    setOffenseLevel(level);
    setDetails({
      ...details,
      incident_level: level,
    });
  };

  const isObjectEmpty = (objectName) => {
    for (let prop in objectName) {
      if (objectName.hasOwnProperty(prop)) {
        console.log('object not empty');
        return false;
      }
    }
    console.log('object empty');
    return true;
  };

  const [editIsActive, setEditIsActive] = useState(false);
  const [editSummaryIsActive, setEditSummaryIsActive] = useState(false);
  const [editOffenseLevelIsActive, setEditOffenseLevelIsActive] = useState(
    false
  );

  const getIncidentLevel = (incidentReport) => {
    console.log('boom');
    if (incidentReport?.incident_level) {
      console.log('12');
      return incidentReport.incident_level;
    } else {
      console.log('13');
      return incident_level;
    }
  };

  const [witnessContactedLabel, setWitnessContactedLabel] = useState('Not Yet');
  const getWitnessContacted = (witnessContacted) => {
    if (witnessContacted) return 'Yes';
  };

  const updateIncidentReportButton = (
    <Fragment>
      <Button
        style={{ width: '180px', height: '40px' }}
        onClick={() => {
          console.log('details');
          console.log(details);
          updateIncidentReport(details, incident_report._id);

          setEditIsActive(false);
          setEditOffenseLevelIsActive(false);
        }}
      >
        Update
      </Button>
    </Fragment>
  );

  if (loading) {
    return <h2>Loading...</h2>;
  }

  const returnResolution = (ir) => {
    let resolution_recommendation = ir?.resolution_recommendation;
    if (resolution_recommendation.suspension) {
      return 'Suspension';
    }
    if (resolution_recommendation.indefinite_suspension) {
      return 'Indefinite Suspension';
    }
    if (resolution_recommendation.warning_given) {
      return 'Warning Given';
    }
    if (resolution_recommendation.no_action) {
      return 'No Action';
    }
  };
  // const [seeMoreSelected, setSeeMoreSelected] = useState(false);

  const safetyCouncilAccessGranted = () => {
    if (
      user?.safety_council_member === 'false' ||
      user?.email !== 'airmeus.team@gmail.com' ||
      !('safety_council_member' in user)
    ) {
      console.log('user.email');
      console.log(user?.email);
      console.log('user?.safety_council_member');
      console.log(user?.safety_council_member);
      return false;
    } else {
      return true;
    }
  };
  const safetyCouncilOrReporterAccessGranted = () => {
    if (!user) {
      console.log('no user');
      return false;
    }

    if (
      user?.safety_council_member === 'true' ||
      user?.email === 'airmeus.team@gmail.com' ||
      user?._id === incident_report?.reporter_id
    ) {
      console.log('user.email');
      console.log(user?.email);
      console.log('user?.safety_council_member');
      console.log(user?.safety_council_member);
      return true;
    } else {
      return false;
    }
  };

  return (
    <div style={{ padding: '10px' }}>
      <h3>Incident Report</h3>

      {/* <h5>Incident Description</h5>
      <h5>{incident_report.description_of_incident}</h5> */}
      {/* reporter view */}

      {/* council member & admin view */}
      {/* {safetyCouncilOrReporterAccessGranted()  */}
      {(user &&
        (user?.email === 'airmeus.team@gmail.com' ||
          user?.safety_council_member === true)) ||
      user?._id === incident_report?.reporter_id ? (
        <ReporterView incidentReport={incident_report} />
      ) : (
        <>
          <h5>access not granted</h5>
        </>
      )}
      <div style={{ paddingTop: '14px' }}>
        <InvestigationSteps incidentReport={incident_report} />
      </div>
      <hr />

      {/* {safetyCouncilAccessGranted()  */}

      {user &&
      (user?.email === 'airmeus.team@gmail.com' ||
        user?.safety_council_member === true) ? (
        <div>
          <h3>Safety Council Actions</h3>
          <div className="card">
            <h5>Step 1</h5>
            <h3 style={{ fontWeight: '900', fontSize: '32px' }}>
              Determine the level of offense
            </h3>
            {/* <h5>Level of Offense</h5> */}
            {!incident_report?.incident_level || editOffenseLevelIsActive ? (
              <div>
                {[DropdownButton].map((DropdownType, idx) => (
                  <DropdownType
                    as={ButtonGroup}
                    key={idx}
                    id={`dropdown-button-drop-${idx}`}
                    size="sm"
                    variant="secondary"
                    title={offenseLevel}
                    //   title={() => getIncidentLevel(incident_report)}
                    onSelect={handleOffenseLevelChange}
                  >
                    {['Level 1', 'Level 2', 'Level 3'].map((level) => (
                      <Dropdown.Item eventKey={level}>{level}</Dropdown.Item>
                    ))}
                  </DropdownType>
                ))}
              </div>
            ) : (
              <>
                {' '}
                <div>
                  <div className="row">
                    <div className="col">
                      <h5>Offense Level</h5>
                    </div>
                    <div className="col">
                      <Button
                        onClick={() => {
                          setEditOffenseLevelIsActive(true);

                          setDetails({
                            ...details,
                            incident_level: incident_report?.incident_level
                              ? incident_report?.incident_level
                              : '',
                          });
                        }}
                      >
                        Update Offense Level
                      </Button>
                    </div>
                  </div>
                  <h5>{incident_report?.incident_level}</h5>
                </div>
              </>
            )}
            {editOffenseLevelIsActive ? (
              <>{updateIncidentReportButton}</>
            ) : (
              <></>
            )}
          </div>
          <div className="card">
            <h3 style={{ fontWeight: 'bolder' }}>
              Step 2 - Conduct Investigation
            </h3>

            <h5>Contact All Parties</h5>
            <br />
            <ContactReporter
              incident_report={incident_report}
              onReporterContactedUpdate={onReporterContactedUpdate}
              reporterContactedUpdateShowButton={
                reporterContactedUpdateShowButton
              }
              setReporterContactedUpdateShowButton={
                setReporterContactedUpdateShowButton
              }
              details={details}
              updateIncidentReport={updateIncidentReport}
              loading={loading}
            />
            <Witnesses
              incident_report={incident_report}
              onContactedPersonChange={onContactedPersonChange}
              // setFromChild={setFromChild}
              witnessContactedLabel={witnessContactedLabel}
              setWitnessContactedLabel={setWitnessContactedLabel}
              witnessContactedUpdateShowButton={
                witnessContactedUpdateShowButton
              }
              setWitnessContactedUpdateShowButton={
                setWitnessContactedUpdateShowButton
              }
              details={details}
              setDetails={setDetails}
              updateIncidentReport={updateIncidentReport}
            />
            <ContactAccused
              incident_report={incident_report}
              onAccusedContactedUpdate={onAccusedContactedUpdate}
              accusedContactedUpdateShowButton={
                accusedContactedUpdateShowButton
              }
              setAccusedContactedUpdateShowButton={
                setAccusedContactedUpdateShowButton
              }
              details={details}
              updateIncidentReport={updateIncidentReport}
              loading={loading}
            />
          </div>
          <div className="card">
            <h3 style={{ fontWeight: 'bolder' }}>
              Step 3 - Summary of Investigation
            </h3>

            <div className="row">
              {!incident_report?.summary_of_investigation ||
              editSummaryIsActive ? (
                <div className="col">
                  <div>
                    {/* <label htmlFor="summary_of_investigation">
                      Summary Of Investigation
                    </label> */}
                    <br />
                    <textarea
                      id="summary_of_investigation"
                      type="textarea"
                      name="summary_of_investigation"
                      rows="4"
                      cols="50"
                      placeholder="Summary of Investigation"
                      // value={summary_of_investigation}
                      value={investigationSummary}
                      // value={ {!seeMoreSelected ? 'see more' : 'see less'}}
                      onChange={onChange}
                      required
                    />
                    <br />
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className="row">
                      <div className="col">
                        <h5>Summary</h5>
                      </div>
                      <div className="col">
                        <Button
                          onClick={() => {
                            setEditSummaryIsActive(true);

                            setDetails({
                              ...details,
                              summary_of_investigation: incident_report?.summary_of_investigation
                                ? incident_report?.summary_of_investigation
                                : '',
                            });
                            setInvestigationSummary(
                              incident_report?.summary_of_investigation
                                ? incident_report?.summary_of_investigation
                                : ''
                            );
                          }}
                        >
                          Edit summary
                        </Button>
                      </div>
                    </div>
                    <h5>{incident_report?.summary_of_investigation}</h5>
                  </div>
                </>
              )}
            </div>
            {!('summary_of_investigation' in incident_report) ? (
              <div>
                <Button
                  onClick={() => {
                    console.log('details');
                    console.log(details);
                    updateIncidentReport(details, incident_report._id);
                  }}
                >
                  Save Summary
                </Button>
              </div>
            ) : (
              <></>
            )}
            {editSummaryIsActive ? (
              <div className="row">
                <div className="col">
                  <Button
                    style={{
                      width: '180px',
                      height: '40px',
                      backgroundColor: 'rgb(1, 126, 252)',
                    }}
                    onClick={() => {
                      console.log('details');
                      console.log(details);
                      updateIncidentReport(details, incident_report._id);

                      setEditSummaryIsActive(false);
                      setEditOffenseLevelIsActive(false);
                    }}
                  >
                    Update Summary
                  </Button>
                </div>
                <div className="col">
                  <Button
                    style={{
                      width: 'max-content',
                      height: '40px',
                      backgroundColor: 'red',
                    }}
                    onClick={() => {
                      // console.log('details');
                      // console.log(details);
                      // updateIncidentReport(details, incident_report._id);

                      setEditSummaryIsActive(false);
                      setEditOffenseLevelIsActive(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
            <hr className="new2" />
            <div className="col">
              {/* <h5>Recommendations</h5> */}

              {isObjectEmpty(incident_report?.resolution_recommendation) ||
              editIsActive ? (
                <div>
                  <h5>What is your recommendation for a resolution?</h5>
                  {['Warning Given', 'Suspension', 'No Action'].map(
                    (selection) => (
                      <div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="selection"
                            id={selection}
                            value={`${selection}`}
                            // onChange={onChangeRoles}
                            onChange={onRecommendationChange}
                            dance_style={selection}
                          />

                          <label
                            class="form-check-label"
                            for="police report filed"
                          >
                            {selection}
                          </label>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col">
                      <h5>Resolution Recommendation</h5>

                      <h5 style={{ color: 'red', fontSize: '14px' }}>
                        {returnResolution(incident_report)}{' '}
                        {incident_report?.resolution_recommendation
                          .suspension ? (
                          incident_report?.resolution_recommendation
                            .recommendation_of_duration_of_suspension_in_months +
                          ' Months'
                        ) : (
                          <></>
                        )}
                      </h5>
                    </div>
                    <div className="col">
                      <Button
                        onClick={() => {
                          setEditIsActive(true);

                          // setDetails({
                          //   ...details,
                          //   resolution_recommendation: incident_report?.resolution_recommendation
                          //     ? incident_report?.resolution_recommendation
                          //     : '',
                          // });
                          // setInvestigationSummary(
                          //   incident_report?.summary_of_investigation
                          //     ? incident_report?.summary_of_investigation
                          //     : ''
                          // );
                        }}
                      >
                        Edit Recommendation
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {suspensionSelected ? (
                <div>
                  <h5>Suspension Duration in months</h5>

                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      as={ButtonGroup}
                      key={idx}
                      id={`dropdown-button-drop-${idx}`}
                      size="sm"
                      variant="secondary"
                      title={suspensionMonths}
                      // title="10:40"
                      onSelect={handleSuspensionDurationChange}
                      // onChange={onChange}
                    >
                      {[1, 2, 3, 6, 12, 24, 36, 'Indefinite'].map((time) => (
                        <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>
                      ))}
                    </DropdownType>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
            {isObjectEmpty(incident_report?.resolution_recommendation) ? (
              <Button
                onClick={() => {
                  updateIncidentReport(details, incident_report._id);
                }}
              >
                Save recommendation
              </Button>
            ) : (
              <></>
            )}
            {editIsActive ? (
              <div className="row">
                <div className="col">
                  <Button
                    style={{
                      width: 'max-content',
                      height: '40px',
                      backgroundColor: 'rgb(1, 126, 252)',
                      // color: 'rgb(1, 126, 252)',
                    }}
                    onClick={() => {
                      console.log('details');
                      console.log(details);
                      updateIncidentReport(details, incident_report._id);

                      setEditIsActive(false);
                      setEditOffenseLevelIsActive(false);
                    }}
                  >
                    Update Recommendation
                  </Button>
                </div>

                <div className="col">
                  <Button
                    style={{
                      width: 'max-content',
                      height: '40px',
                      backgroundColor: 'red',
                    }}
                    onClick={() => {
                      // console.log('details');
                      // console.log(details);
                      // updateIncidentReport(details, incident_report._id);

                      setEditIsActive(false);
                      setEditOffenseLevelIsActive(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="card" style={{ paddingTop: '20px' }}>
            <h3>Step 4 - Vote on Resolution</h3>
            <h2 style={{ color: 'red', fontSize: '14px' }}>
              The recommended resolution is:{' '}
              {
                incident_report?.resolution_recommendation
                  ?.recommendation_of_duration_of_suspension_in_months
              }{' '}
              month(s)
              {incident_report?.resolution_recommendation?.suspension ? (
                ' Suspension'
              ) : (
                <></>
              )}
            </h2>

            <div>
              <VoteOnResolution
                incidentReport={incident_report}
                voter_user_id={user?._id}
              />
              <br />
              {/* <h4>Votes</h4>

        <div className="row">
          <div className="col-5">council member 1</div>
          <div className="col">Yes</div>
          <div className="col">No</div>
        </div>
        <div className="row">
          <div className="col-5">council member 2</div>
          <div className="col">Yes</div>
          <div className="col">No</div>
        </div>
        <div className="row">
          <div className="col-5">council member 3</div>
          <div className="col">Yes</div>
          <div className="col">No</div>
        </div> */}
            </div>
            {incident_report?.resolution?.suspension ? (
              <h5 style={{ color: 'red' }}>Person Suspended</h5>
            ) : (
              <div>
                <Button
                  onClick={() => {
                    console.log(
                      'user was suspended and added to suspended list, update status of IR to closed or something, '
                    );

                    suspendPerson({
                      incident_report_id: incident_report._id,
                      suspended_person_name: incident_report.offender_name,
                      suspension_duration:
                        incident_report.resolution_recommendation
                          .recommendation_of_duration_of_suspension_in_months,
                    });
                  }}
                  style={{
                    marginTop: '12px',
                    marginBottom: '12px',
                    // color: 'blue',
                    // background: 'white',

                    borderStyle: 'solid',
                    borderColor: 'white',
                    borderWidth: '2px',
                    borderRadius: '6px',

                    // fontSize: '14px',
                    backgroundColor: 'red',
                    color: 'white',
                    textAlign: 'center',
                    fontSize: '16px',
                  }}
                >
                  Suspend Accused
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default IncidentReport2Updated;
