import React, { Component, Fragment, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Modal,
} from 'react-bootstrap';
import StudioListForUser from './StudioListForUser';
import authState, { useAuth } from '../../context/auth/AuthState';
import { Link } from 'react-router-dom';
import { red } from '@material-ui/core/colors';

const BuildCurriculumPopup3 = (props) => {
  const [authState] = useAuth();

  const { user, isAuthenticated } = authState;

  const [show, setShow] = useState(props.buildCurriculumClicked);
  const handleClose = () => setShow(false);
  let { addNewCurriculum } = props;

  //get list of studios to add curriculum to

  const [studioId, setStudioId] = useState('');

  const [list, setList] = useState(['']);

  const [value, setValue] = useState('');

  const deleteItem = (index) => {
    let temp = list.filter((item, i) => i !== index);

    setList(temp);
  };

  let fruits2 = [];
  let [moves, setMovesList] = useState(fruits2);

  const addFruit = (fruit) => {
    setMovesList([...moves, fruit]);
    setFruitToAdd('');
  };
  const createFruit = (e) => {
    e.preventDefault();
    if (typeof fruitToAdd === 'string' && fruitToAdd.length > 0) {
      addFruit(fruitToAdd);
    }
  };

  //later we add --> heels, reggaeton, swing, hiphop, etc
  const dance_styles_list = ['Bachata', 'Salsa on 2', 'Zouk', 'Kizomba'];

  const [curriculum, setCurriculum] = useState({
    curriculum_name: '', //other random generaated
    dance_style: 'Select A Dance Style',
    dance_level: 'Select A Dance Level',
    // curriculum_name: 'Bachata Footwork', //other random generaated
    // dance_style: 'Bachata',
    // dance_level: 'Beginner 1',
  });

  const { curriculum_name, dance_style, dance_level } = curriculum;
  const onCurriculumChange = (e) =>
    setCurriculum({
      ...curriculum,
      [e.target.name]: e.target.value,
    });

  let [fruitToAdd, setFruitToAdd] = useState('');
  const onChange = (e) => setFruitToAdd(e.target.value);
  const danceMoveName = (
    <Fragment>
      <div className="form-group">
        {/* <label for="fruitItem">
          Dance Move Name */}
        <input
          type="text"
          id="fruitItem"
          placeholder="..Enter Dance Move"
          className="form-control"
          value={fruitToAdd}
          onChange={onChange}
        />
        {/* </label> */}
      </div>
    </Fragment>
  );

  const handleSelectLevel = (e) => {
    setCurriculum({
      ...curriculum,
      dance_level: e,
    });
  };
  const handleSelectStyle = (e) => {
    setCurriculum({
      ...curriculum,
      dance_style: e,
    });
  };

  const fields = (
    <Fragment>
      {/* <form onSubmit={console.log('hi')}> */}
      <div className="form-group" style={{ padding: '0px' }}>
        {/* <div className="row"> */}
        {/* <div className="" style={{ padding: '0px' }}>
          <h5 htmlFor="curriculum_name" style={{ fontSize: '14px' }}>
            Curriculum Name
          </h5>
        </div> */}
        <div className="">
          <input
            id="curriculum_name"
            type="text"
            name="curriculum_name"
            placeholder="Enter Curriculum Name"
            value={curriculum_name}
            onChange={(e) => {
              console.log(curriculum_name);
              onCurriculumChange(e);
            }}
            required
            style={{
              margin: '6px',
              borderRadius: '10px',
              backgroundColor: 'white',
            }}
          />
        </div>

        {/* <input
          id="dance_style"
          type="text"
          name="dance_style"
          // placeholder="Enter Curriculum Name"

          value={dance_style}
          onChange={(e) => {
            console.log(dance_style);
            onCurriculumChange(e);
          }}
          required
          style={{
            margin: '6px',
            borderRadius: '10px',
          }}
        /> */}

        <div className="col">
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              style={{
                height: '35px',
                width: '300px',
                margin: '6px',
                // borderWidth: '1px',
                borderRadius: '10px',
              }}
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="sm"
              variant="secondary"
              title={dance_style}
              // onClick={() => {
              //   setLevelButtonClicked(true);
              // }}
              onSelect={handleSelectStyle}
            >
              <Dropdown.Item eventKey="Bachata">Bachata</Dropdown.Item>
              <Dropdown.Item eventKey="Salsa">Salsa</Dropdown.Item>
              <Dropdown.Item eventKey="Kizomba">Kizomba</Dropdown.Item>
              <Dropdown.Item eventKey="Zouk">Zouk</Dropdown.Item>
            </DropdownType>
          ))}
        </div>

        <div className="col">
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              style={{
                height: '35px',
                width: '300px',
                margin: '6px',
                // borderWidth: '1px',
                borderRadius: '10px',
              }}
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="sm"
              variant="secondary"
              title={dance_level}
              // onClick={() => {
              //   setLevelButtonClicked(true);
              // }}
              onSelect={handleSelectLevel}
            >
              <Dropdown.Item eventKey="Beginner">Beginner</Dropdown.Item>
              <Dropdown.Item eventKey="Intermediate">
                Intermediate
              </Dropdown.Item>
              <Dropdown.Item eventKey="Advanced">Advanced</Dropdown.Item>
            </DropdownType>
          ))}
        </div>
      </div>
      {/* </form> */}
    </Fragment>
  );
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [loginAlert, setLoginAlert] = useState(false);

  const saveCurriculum = (e) => {
    e.preventDefault();

    if (!user) {
      setLoginAlert(true);
    }

    if (
      dance_style === 'Select A Dance Style' ||
      dance_level === 'Select A Dance Level' ||
      curriculum_name === ''
    ) {
      setShowAlertMessage(true);
    } else {
      let usersName = user?.name;
      let usersId = user?._id;
      addNewCurriculum({
        curriculum_name,
        dance_style,
        dance_level,
        creator_name: usersName,
        creator_id: usersId,
        moves,
      });
      console.log({ moves: moves, curriculum: curriculum });
    }
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Build Curriculum</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fields}
          <div>
            {moves.map((fruit) => (
              <div>
                <li className="list-group-item list-group-item-info">
                  {fruit}
                </li>
              </div>
            ))}
          </div>

          <hr />
          {danceMoveName}
          <div className="row">
            <div className="col">
              <form className="form-inline" onSubmit={createFruit}>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: 'white',
                    // width: 'max-content',
                    // width: '120px',
                    width: 'max-content',

                    background: 'white',
                    color: 'rgb(1, 126, 252)',
                    borderStyle: 'solid',
                    // borderColor: 'rgb(1, 126, 252)',
                    padding: '6px',
                    marginTop: '10px',
                    border: '0.5px solid rgb(1, 126, 252)',
                  }}
                >
                  Add Move
                </Button>
              </form>
            </div>

            {loginAlert === true ? (
              <div style={{ paddingTop: '10px' }}>
                <Link to="/login">
                  <h5
                    style={{
                      color: 'rgb(1, 126, 252)',
                      fontSize: '12px',
                    }}
                  >
                    login to save curriculum
                  </h5>
                </Link>
              </div>
            ) : (
              <></>
            )}
            {showAlertMessage === true ? (
              <div>
                <h5 style={{ color: 'red', fontSize: '12px' }}>
                  select style,level and input a curriculum name
                </h5>
              </div>
            ) : (
              <></>
            )}
            <div className="col">
              <form className="form-inline" onSubmit={saveCurriculum}>
                <Button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: 'white',
                    // width: 'max-content',
                    // width: '120px',
                    width: 'max-content',

                    background: 'white',
                    color: 'rgb(1, 126, 252)',
                    borderStyle: 'solid',
                    // borderColor: 'rgb(1, 126, 252)',
                    padding: '6px',
                    marginTop: '10px',
                    border: '0.5px solid rgb(1, 126, 252)',
                  }}
                >
                  Save Curriculum
                </Button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default BuildCurriculumPopup3;

{
  /*

graveyard 


        <input
          id="dance_level"
          type="text"
          name="dance_level"
          value={dance_level}
          onChange={(e) => {
            console.log(dance_level);
            onCurriculumChange(e);
          }}
          required
          style={{
            margin: '6px',

            // borderWidth: '1px',
            borderRadius: '10px',
          }}
        />


*/
}
