import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
import CheckoutForm from '../../advertise/CheckoutForm';
import Layout from '../../advertise/Layout';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../context/auth/AuthState';
import { Button } from 'react-bootstrap';

import giphy from '../../../../src/images/giphy.gif';
import eventContext from '../../../context/event/eventContext';

// import StudioContext from '../../context/studio/studioContext';

const GoProEventPayment = (props) => {
  const [authState] = useAuth();

  const { user } = authState;
  let navigate = useNavigate();
  const { event_id } = useParams();

  // const studioContext = useContext(StudioContext);
  // const {
  // convertAccountToPremiumSubscription,
  // premium_subscriber,
  // } = studioContext;

  const eContext = useContext(eventContext);
  const { addPremiumEventSubscription } = eContext;

  console.log('event id is:');
  console.log(event_id);
  return (
    <div>
      <h1
        className="all-center"
        style={{
          fontSize: '30px',
          fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',

          paddingBottom: '10px',
          paddingTop: '10px',
          color: 'rgb(1, 126, 252)',
        }}
      >
        Premium Events
        {/* <span className="text-primary">Premium Events</span> */}
      </h1>

      <div
        className="smallScreenText"
        style={{
          backgroundColor: '',

          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        {/* <h4>What you can expect</h4> */}

        {/* <h3
          style={{
            color: 'rgb(1, 126, 252)',
          }}
        >
          Super Power Your Event 🚀🚀🚀
        </h3> */}
        <div className="row" style={{ paddingBottom: '30px' }}>
          <div className="">
            {/* <h1
              style={{
                fontSize: '30px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
              }}
            >
              Event Chat
            </h1>
            <h3
              style={{
                fontSize: '18px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
                fontWeight: '300',
                lineHeight: '1.5',
              }}
            >
              A revolutionary Event Chat experience that will keep your
              attendees engaged
            </h3> */}

            <h1
              style={{
                fontSize: '30px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
              }}
            >
              Interactive Workshop Schedule
            </h1>
            <h3
              style={{
                fontSize: '18px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
                fontWeight: '300',
                lineHeight: '1.5',
              }}
            >
              Next level Workshop Schedule that makes logistics simple and fun
            </h3>
            <h1
              style={{
                fontSize: '30px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
              }}
            >
              Free Booking Tools For Your Instructors
            </h1>
            <h3
              style={{
                fontSize: '18px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
                fontWeight: '300',
                lineHeight: '1.5',
              }}
            >
              Provide your instructors and attendees with a streamlined private
              lesson software
            </h3>
            <h1
              style={{
                fontSize: '30px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
              }}
            >
              Performance Reactions
            </h1>
            <h3
              style={{
                fontSize: '18px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
                fontWeight: '300',
                lineHeight: '1.5',
              }}
            >
              that allow your audience to give live reactions to your artist’s
              performances
            </h3>
          </div>

          {/* <Button
            style={{
              width: 'fit-content',
              backgroundColor: 'rgb(1, 126, 252)',
            }}
          >
            Create Your Event
          </Button> */}
        </div>
      </div>
      <h3
        className="all-center"
        style={{
          // color: 'rgb(1, 126, 252)',
          color: '#0062ff',
          // backgroundColor: '#f542cb',
          fontSize: '40px',
          fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',

          borderRadius: '5px',
          // width:
        }}
      >
        Super Power Your Event 🚀
      </h3>

      <div>
        <div className="smallScreenPayFields">
          <Layout title="Donut Shop">
            {/* <Row>
            <DonutShop
              onAddDonut={console.log('added donut')}
              onRemoveDonut={console.log('rm donut')}
              numDonuts={5}
            />
          </Row> */}
            <CheckoutForm
              price={250}
              onSuccessfulCheckout={() => {
                console.log(
                  'payment successful, now run convertAccountToPremiumSubscription ...'
                );
                // console.log(`studio id is : ${studio_id}`);
                // console.log(`user id is : ${user._id}`);
                // convertAccountToPremiumSubscription({
                //   studio_id: studio_id,
                //   user_id: user._id,
                // });

                addPremiumEventSubscription({
                  event_id: event_id,
                  user_id: user._id,
                });

                navigate(`/event/${event_id}`, {
                  state: {
                    messageText: `Welcome To Premium!`,
                  },

                  // });
                });
              }}
            />
          </Layout>
        </div>
      </div>
    </div>
  );
};

export default GoProEventPayment;
