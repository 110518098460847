import React, { useState, Component } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { useContext } from 'react';

import {
  Button,
  DropdownButton,
  SplitButton,
  ButtonGroup,
  Dropdown,
  Row,
  Col,
  Container,
} from 'react-bootstrap';

// import DropdownButton from 'react-bootstrap/DropdownButton'

import Modal from 'react-bootstrap/Modal';
const MyRoommate = (props) => {
  const [authState] = useAuth();

  const { user } = authState;
  const userContext = useContext(UserContext);

  const { addRoomNumber, room_number_added } = userContext;

  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { roommate, event } = props;
  const handleSave = () => {
    //action from userState
    console.log(`saving room ${value.room_number}`);

    let obj = {
      user_id: user._id,
      roommate_id: roommate._id,
      room_number: value.room_number,
      event_id: event._id,
    };

    addRoomNumber(obj);
    // window.location.reload();
  };

  const [value, setValue] = useState({
    room_number: 'Room 1',
  });

  let image;
  if (roommate.image) {
    image = roommate.image.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }

  return (
    <div>
      <div className="form-container">
        <div className="form-group">
          <div className="container">
            <div className="card bg-light">
              <div className="container">
                <div className="card bg-light">
                  <img style={{ width: '50px' }} src={image} alt="roommate" />
                  <h5>{roommate.name}</h5>
                  {/* <h5>(704) 488-0987</h5> */}
                  {/* <Button variant="primary" onClick={handleShow}>
                    Add Room
                  </Button> */}

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Add Room For {roommate.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        <div>
                          <form class="form-inline my-2 my-lg-0">
                            <div>
                              <Row>
                                <Col>
                                  {[DropdownButton].map((DropdownType, idx) => (
                                    <DropdownType
                                      as={ButtonGroup}
                                      key={idx}
                                      id={`dropdown-button-drop-${idx}`}
                                      size="sm"
                                      variant="secondary"
                                      title={value.room_number}
                                    >
                                      <Dropdown.Item
                                        eventKey="1"
                                        onClick={(e) =>
                                          setValue({
                                            ...value,
                                            room_number: 'Room 1',
                                          })
                                        }
                                      >
                                        Room 1
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        eventKey="2"
                                        onClick={(e) =>
                                          setValue({
                                            ...value,
                                            room_number: 'Room 2',
                                          })
                                        }
                                      >
                                        Room 2
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey="3"
                                        onClick={(e) =>
                                          setValue({
                                            ...value,
                                            room_number: 'Room 3',
                                          })
                                        }
                                      >
                                        Room 3
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey="4"
                                        onClick={(e) =>
                                          setValue({
                                            ...value,
                                            room_number: 'Room 4',
                                          })
                                        }
                                      >
                                        Room 4
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey="5"
                                        onClick={(e) =>
                                          setValue({
                                            ...value,
                                            room_number: 'Room 5',
                                          })
                                        }
                                      >
                                        Room 5
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey="6"
                                        onClick={(e) =>
                                          setValue({
                                            ...value,
                                            room_number: 'Room 6',
                                          })
                                        }
                                      >
                                        Room 6
                                      </Dropdown.Item>
                                      <Dropdown.Divider />
                                    </DropdownType>
                                  ))}
                                </Col>
                                <Col>
                                  <Button
                                    variant="primary"
                                    onClick={handleSave}
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </form>
                        </div>
                        <br />
                      </div>
                    </Modal.Body>
                  </Modal>

                  {/* <h5>{roommate.number}</h5> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRoommate;
