// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
import { useState } from 'react';

import UserContext from '../../context/user/userContext';
import SubEventContext from '../../context/subevent/subEventContext';
import { useAuth } from '../../context/auth/AuthState';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SubEventAttendees from './SubEventAttendees';
import { csonParser } from 'config/parser';

const SubEvent = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { subevent } = props;
  // console.log('-------------- user is from Attendee');
  // console.log(user);
  const userContext = useContext(UserContext);

  const [authState] = useAuth();
  const { user } = authState;

  const { passOnUser, likeUser } = userContext;
  const subEventContext = useContext(SubEventContext);
  const { joinSubEvent, joined_sub_event } = subEventContext;
  //helper for submit button
  const join_sub_event = () => {
    joinSubEvent(subevent._id, user._id);
    // console.log("joining subevent")
  };

  let alreadyJoined = false;

  const userAlreadyAttending = () => {
    console.log('subevent attendees');
    console.log(subevent.attendees);
    for (var i = 0; i < subevent.attendees.length; i++) {
      if (user && subevent.attendees[i] === user._id) {
        console.log(`${user._id} - ${user.name}  already in attendees list`);
        alreadyJoined = true;
        return true;
      }
    }
    console.log('user not in attendees yet');
    return false;
  };

  const joinButton = (
    <Fragment>
      {/* <div className="col-3"> */}
      <div>
        <button
          type="button"
          className="btn"
          onClick={join_sub_event}
          style={{
            background: 'white',
            // color: 'blue',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            textAlign: 'center',
            padding: '0px 12px',
            width: '120px',
            borderWidth: '1px',
          }}
        >
          Join Event
        </button>
      </div>
    </Fragment>
  );

  const alreadyJoinedButton = (
    <Fragment>
      {/* <div className="col-3"> */}
      <div>
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          Joined
        </button>
      </div>
    </Fragment>
  );
  const nouser = (
    <Fragment>
      {/* <div className="col-3"> */}
      <div>
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          sign in to join
        </button>
      </div>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!user) {
      console.log('no user found');
      return nouser;
    }
    if (user && userAlreadyAttending()) {
      return alreadyJoinedButton;
    } else {
      return joinButton;
    }
  };
  let image;
  if (subevent.image) {
    image = subevent.image.url;
  } else {
    image =
      'https://www.weho.org/home/showpublishedimage/5617/635754012603070000';
  }

  const switchOnColor = () => {
    let activity_type = subevent?.activity_type;
    switch (activity_type) {
      case 'pregame':
        return '#0d6efd';
      case 'afterparty':
        return 'purple';
      case 'secret santa':
        return 'red';
      case 'game night':
        return 'green';
      default:
        return 'black';
    }
  };
  return (
    <div className="">
      <div className="card bg-light">
        <div className="bigScreenAttendee smallScreenAttendee">
          <div className="row">
            <div className="col">
              <img
                // style={{ width: '150px' }}
                src={image}
                // className="rounded-circle"
                alt=""
              />
            </div>
            {subevent?.activity_type ? (
              <div className="col">
                <label
                  style={{
                    color: switchOnColor(),
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderRadius: '10px',
                    padding: '0px 15px',
                  }}
                  htmlFor=""
                >
                  {subevent?.activity_type}
                </label>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div>
            <h5 style={{ fontSize: '14px' }}>{subevent.sub_event_name}</h5>
            <h5 style={{ fontSize: '14px' }}>{subevent.descr}</h5>
            <div className="row">
              {/* <div className="col-3"> */}
              <div className="row">
                <div className="col">
                  <SubEventAttendees subevent={subevent} />
                </div>
                <div className="col">{renderOptions()}</div>
              </div>
              {/* <div className="col-3">
              <h5>{subevent.attendees.length} going</h5>
            </div> */}

              {/* <div className="col-6">
              <button
                type="button"
                className="btn btn-dark btn-block"
                onClick={join_sub_event}
              >
                Join Event
              </button>
            </div> */}
            </div>
          </div>
          {/* <div className="bg-dark p">
          <button onClick={onPass}>pass</button>
          <button onClick={onLike}>like</button>
        </div> */}
        </div>
      </div>
    </div>
  );
};
export default SubEvent;

// const onLike = () => {
//   console.log('user liked and my info below');
//   console.log(user);

//   likeUser(user);
//   //what should happen when you like a user
//   //the user's info should go to backend and the messages>liked users should be updated (may be a premium feature)
//   //delete current first user in the loaded in array of users and replace with the next one up
// };

// const onPass = () => {
//   console.log('passed on user');
//   // passOnUser(user._id);
//   passOnUser(user);
// };
