import React, { useReducer } from 'react';
import axios from 'axios';
import SubEventContext from './subEventContext'; //be sure the case is not case sensitive
import SubEventReducer from './subEventReducer';
// import GET_USERS_ONE_AT_A_TIME from '../types';
import {
  CREATE_SUB_EVENT,
  GET_SUB_EVENTS_FOR_EVENT,
  GET_ATTENDEES_FOR_SUB_EVENT,
  JOIN_SUB_EVENT,
  EVENT_ERROR,
  GET_ALL_SUB_EVENTS,

  // GET_ONE_EVENT,
  // DELETE_EVENT,
  CREATE_EVENT,
  JOIN_EVENT,
} from '../types';

const SubEventState = (props) => {
  const initialState = {
    sub_events: [],
    new_sub_event: null,
    attendees: [],
    activities: [],
    // current: null,
    // filtered: null,
    error: null,
    joined_event: null,
    joined_sub_event: {},
    sub_event_attendees: [],
  };
  const [state, dispatch] = useReducer(SubEventReducer, initialState);

  // Get Users
  const getSubEventsForEvent = async (event_id) => {
    try {
      console.log('running getSubEventsForEvent()');
      const res = await axios.get(`/api/subevents/${event_id}`);
      // console.log('results should be filtered');
      console.log('subevents are:');
      console.log(res.data);
      dispatch({
        type: GET_SUB_EVENTS_FOR_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // const addSubEventForEvent = async (eventId, name, descr, hostId) => {
  const addSubEventForEvent = async (form) => {
    // const requestObject = {
    //   eventId: eventId,
    //   sub_event_name: name,
    //   descr: descr,
    //   hostId: hostId,
    // };
    try {
      console.log('running addSubEventForEvent()');
      // const res = await axios.post('/api/subevents', requestObject);
      const res = await axios.post('/api/subevents', form);

      // console.log('results should be filtered');
      // console.log(res.data);
      dispatch({
        type: CREATE_SUB_EVENT,
        payload: res.data,
      });
      // console.log('form.eventId is: ');
      console.log('res.data.event_id');
      console.log(res.data.event_id);
      getSubEventsForEvent(res.data.event_id);
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const joinSubEvent = async (sub_event_id, joining_user_id) => {
    const requestObject = {
      sub_event_id: sub_event_id,
      joining_user_id: joining_user_id,
    };
    try {
      console.log('running joinSubEvent()');
      const res = await axios.post(
        `/api/subevents/join-sub-event`,
        requestObject
      );
      // console.log('results should be filtered');
      console.log('joinSubEvent is:');
      console.log(res.data);
      dispatch({
        type: JOIN_SUB_EVENT,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const getAttendeesForSubEvent = async (sub_event_id) => {
    try {
      console.log('running getAttendeesForSubEvent()');
      const res = await axios.get(`/api/subevents/${sub_event_id}/attendees`);
      // console.log('results should be filtered');
      console.log(
        'subevent attendees from getAttendeesForSubEvent ------------ :'
      );
      console.log(res.data);
      dispatch({
        type: GET_ATTENDEES_FOR_SUB_EVENT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getAllActivities = async () => {
    try {
      console.log('running getAllActivities()');
      const res = await axios.get(`/api/subEvents/allActivities`);

      console.log(res.data);
      dispatch({
        type: GET_ALL_SUB_EVENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENT_ERROR,
        payload: err.response.msg,
      });
    }
  };

  return (
    <SubEventContext.Provider
      value={{
        // props -- where our state and actions will go
        sub_events: state.sub_events,
        joined_sub_event: state.joined_sub_event,
        attendees: state.attendees,
        sub_event_attendees: state.sub_event_attendees,
        activities: state.activities,

        getSubEventsForEvent,
        addSubEventForEvent,
        joinSubEvent,
        getAttendeesForSubEvent,
        getAllActivities,
      }}
    >
      {props.children}
    </SubEventContext.Provider>
  );
};

export default SubEventState;
