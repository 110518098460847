import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';

const DanceMoves = (props) => {
  const [buttonClicked, setButtonClicked] = useState(
    props?.alreadySelectedMoves && props?.alreadySelectedMoves.length > 0
      ? props?.alreadySelectedMoves.includes(props.move?.move_name)
      : false
  );

  return (
    <div>
      {' '}
      <Button
        onClick={
          () => {
            setButtonClicked(!buttonClicked);
            props.addMoveToLessonPlan(props.move?.move_name);
            props.addMoveIDToLessonPlan(props.move?._id);
          }
          //add to list to send to backend (function from parent)
        }
        style={{
          width: '100px',
          background: buttonClicked ? 'rgb(1, 126, 252' : 'white',
          color: buttonClicked ? 'white' : 'rgb(1, 126, 252',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252',
        }}
      >
        {props.move?.move_name}
      </Button>
    </div>
  );
};
export default DanceMoves;
