import React, { Component, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import UserContext from '../../../context/user/userContext';

export default function UserClaim(props) {
  const userContext = useContext(UserContext);
  const { getUser, user, decisionClaim } = userContext;
  useEffect(() => {
    getUser(claim.requesting_user_id);
    console.log('user from userclaim');
    console.log(user);

    // eslint-disable-next-line
  }, []);
  const { claim } = props;
  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const approveClaim = (claim_id) => {
    console.log('approved');
    //build decisionObject
    const decisionObject = {
      decision: 'approved',
      claim_id: claim_id,
    };
    decisionClaim(decisionObject);
  };

  const denyClaim = (claim_id) => {
    console.log('deny');
    const decisionObject = {
      decision: 'denied',
      claim_id: claim_id,
    };
    decisionClaim(decisionObject);
  };
  return (
    <div>
      <div className="row">
        <h5>
          {claim?.requesting_user_name ? claim?.requesting_user_name : 'user'}{' '}
          wants to claim {claim?.claim_type}{' '}
          {claim?.acccount_name_being_claimed}
        </h5>
      </div>
      <div className="row">
        <label htmlFor="">Photo Identification for user verification</label>
        <div className="col">
          <div className="row">
            <h5>user: {claim?.requesting_user_name}</h5>
            <h5>id: {claim?.requesting_user_id}</h5>
            <span style={{ fontSize: '12px', color: 'grey' }}>
              image enlarged for visibility
            </span>
          </div>
          <div className="">
            <img
              src={claim?.image ? claim.image.url : imgg}
              // src={
              //   claim?.requesting_user_image_url
              //     ? claim.requesting_user_image_url
              //     : imgg
              // }
              height="300"
              width="300"
              alt="claim"
              className="rounded-circle"
            />
          </div>
        </div>
        <div className="col">
          <div className="row">
            {claim?.acccount_name_being_claimed ? (
              <div>
                {/* <h5>account to claim:</h5> */}
                <h5>account to claim: {claim?.acccount_name_being_claimed}</h5>

                <h5>id: {claim?.account_id_to_claim}</h5>
              </div>
            ) : (
              <h5>account to claim</h5>
            )}
          </div>
          <div className="">
            <img
              src={
                claim.acccount_image_being_claimed
                  ? claim.acccount_image_being_claimed.url
                  : imgg
              }
              height="100"
              width="100"
              alt="claim"
              className="rounded-circle"
            />
          </div>
        </div>
      </div>
      <div className="row" style={{ paddingTop: '10px' }}>
        <div className="col">
          {/* <label htmlFor="">approve</label> */}
          <Button
            onClick={() => approveClaim(claim._id)}
            style={{ backgroundColor: 'rgb(1, 126, 252)' }}
          >
            Approve
          </Button>
        </div>
        <div className="col">
          {/* <label htmlFor="">deny</label> */}
          <Button
            // onClick={() => denyRequest(request._id)}
            onClick={() => denyClaim(claim._id, 'artist_claim')}
            style={{ backgroundColor: 'red' }}
          >
            Deny
          </Button>
        </div>
      </div>
      {/* <h1>{props.claim._id}</h1> */}
      <hr />
    </div>
  );
}
