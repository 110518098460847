import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import StudioContext from '../../../context/studio/studioContext';
import DynamicStudioSearch from '../DynamicStudioSearch';
import TopCompanies from '../../performances/TopCompanies';
import DynamicArtistSearch from '../DynamicArtistSearch';

import ArtistContext from '../../../context/artist/artistContext'; // might have to capitalize
import { Button } from 'react-bootstrap';
import AddNewArtistPopup from '../../artist/AddNewArtistPopup';

const ArtistSearchTest = (props) => {
  const [display, setDisplay] = useState(false);
  let { setSearchResultsEmpty, searchResultsEmpty } = props;

  const [userIsAddingArtist, setUserIsAddingArtist] = useState(false);
  const [showArtistClaimedMsg, setShowArtistClaimedMsg] = useState(false);
  const wrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target) && userIsAddingArtist !== true) {
      console.log('userIsAddingArtist');
      console.log(userIsAddingArtist);
      setDisplay(false);
    }
  };
  // 1.load studios ---- 2.load artists
  //   const studioContext = useContext(StudioContext);
  //   const {
  //     topStudiosLimitFive,
  //     getTopStudiosWithLimitOfFive,
  //     getStudiosForSearchCriteria,
  //     studioSearchResults,
  //   } = studioContext;

  const artistContext = useContext(ArtistContext);
  const {
    getArtistsForSearchCriteria,
    artistSearchResults,
    // topArtistsLimitFive,
    // getTopArtistsWithLimitOfFive,
    loading,
  } = artistContext;

  // console.log('topArtistsLimitFive ---');
  // console.log(topArtistsLimitFive);
  console.log('artistSearchResults');
  console.log(artistSearchResults);
  useEffect(() => {
    // getTopStudiosWithLimitOfFive();

    // getTopArtistsWithLimitOfFive();
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);
  const [details, setDetails] = useState({
    searchText: '',
  });
  const [dynamicText, setDynamicText] = useState('');
  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log('text is:');
    console.log(e.target.value);
    // if (searchText.length > 0) {
    if (e.target.value !== '') {
      getArtistsForSearchCriteria(e.target.value);
      setDynamicText(e.target.value);
    }
    if (loading) return <h5>loading</h5>;

    // if (artistSearchResults.length === 0 && dynamicText.length === 0) {
    if (e.target.value === '' || artistSearchResults.length === 0) {
      // console.log('e.target.value empty ------ z');
      // console.log("results")
      console.log('artistSearchResults inside');
      console.log(artistSearchResults);
      // if (artistSearchResults > 0) {
      setSearchResultsEmpty(true);
      // }
      //setprops
    }
    // }
    // getStudiosForSearchCriteria(e.target.value);
  };
  const { searchText } = details;

  return (
    <div>
      <div className="row">
        {/* <div className="row">
          <h3>Artists Search</h3>
        </div> */}

        {/* <div ref={wrapperRef} className="flex-container flex-column pos-rel"> */}
        {/* <div ref={wrapperRef} className="flex-container flex-column pos-rel"> */}
        {/* <div ref={wrapperRef} className="row"> */}
        <div className="row">
          <div className="col">
            <input
              id="searchText"
              class="form-control mr-sm-2"
              type="search"
              placeholder="Artist Search"
              aria-label="Search"
              name="searchText"
              value={searchText}
              onChange={onChange}
              style={{ width: '150px' }}
              onClick={() => setDisplay(!display)}
              autoComplete="off"
            />
          </div>
          {/* {display && (
              <div>
                <DynamicSearch
                  userSearchText={dynamicText}
                  dynamicSearchResults={studioSearchResults}
                />
              </div>
            )} */}

          <div ref={wrapperRef} className="flex-container flex-column pos-rel">
            {display && (
              // <div className="autoContainer">
              <div>
                {/* <h5>Try searching for</h5> */}
                {/* {options.map((person) => (
                    <div className="option">
                      <h5 style={{ color: 'black' }}>{person}</h5>
                    </div>
                  ))} */}

                {!showArtistClaimedMsg && (
                  <DynamicArtistSearch
                    // onChangeValue={handleSearch}
                    userSearchText={dynamicText}
                    dynamicArtistSearch={artistSearchResults}
                    onboarding={true}
                    setShowArtistClaimedMsg={setShowArtistClaimedMsg}

                    // userIsAddingArtist={userIsAddingArtist}
                    // setUserIsAddingArtist={setUserIsAddingArtist}
                  />
                )}

                {/* {artistSearchResults.length === 0 && (
                  <div>

                    <Button
                      onClick={() => {
                        console.log('setting userIsAddingArtist');
                        setUserIsAddingArtist(!userIsAddingArtist);
                        console.log('clicked2');
                      }}
                    >
                      add new artists2
                    </Button>

                    {userIsAddingArtist && (
                      <div>
                        <AddNewArtistPopup
                          addNewArtistClicked={userIsAddingArtist}
                        />
                        
                      </div>
                    )}
                  </div>
                )} */}
              </div>
            )}
          </div>
          {showArtistClaimedMsg && (
            <div
              style={{
                backgroundColor: 'lightgreen',
                borderWidth: '1px',
                borderRadius: '10px',
                color: 'white',
              }}
            >
              <h5>Artist Claim sent! An Admin will follow up shortly</h5>
            </div>
          )}
        </div>

        {/* {!display && (
          <div className="row" style={{ marginLeft: '3em' }}>
            {topArtistsLimitFive.map((artist) => (
              <div>{artist.name}</div>
            ))}
          </div>
        )} */}
        {/* {!display && (
          <div className="row" style={{ marginLeft: '3em' }}>
            {topStudiosLimitFive.map((company) => (
              <TopCompanies
                key={company._id}
                company={company}
                event_id={props.event_id}
              />
            ))}
          </div>
        )} */}

        <hr />
      </div>
    </div>
  );
};
export default ArtistSearchTest;
