//consider renaming to potential_mate to differentiate btw user
import React, { Fragment, useContext, useEffect } from 'react';
import NotificationContext from '../../context/notification/notificationContext';
import Notification from './Notification';
import PropTypes from 'prop-types';
import { useAuth } from '../../context/auth/AuthState';
import { propTypes } from 'react-bootstrap/esm/Image';
const moment = require('moment');
moment().format();
const MyNotifications = (props) => {
  const [authState] = useAuth();
  const { user } = authState;

  const notificationContext = useContext(NotificationContext);

  const {
    getUsersNotifications,
    user_notifications,
    acceptStudioProfileClaim,
  } = notificationContext;
  const { notif } = props;
  useEffect(() => {
    console.log('getting users from useEffect from User Component');
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    // if (user) {
    //   getUsersNotifications(user._id);
    // }
    // eslint-disable-next-line
  }, []);

  const newConnectionNotifType = (
    <Fragment>
      <div className="row">
        <div className="col-3">
          <img
            src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
            alt=""
            height="40"
            width="40"
          />
        </div>
        <div className="col">
          <h5>{notif?.notif_msg}</h5>
        </div>
      </div>

      {/* <button
        type="button"
        className="btn btn-light btn-block"
        onClick={}
      >
     Accept
      </button> */}
    </Fragment>
  );
  const newEventAttendee = (
    <Fragment>
      <div className="row">
        <div className="col-3">
          <img
            src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
            alt=""
            height="40"
            width="40"
          />
        </div>
        <div className="col">
          <h5>{notif?.notif_msg}</h5>
        </div>
      </div>

      {/* <button
        type="button"
        className="btn btn-light btn-block"
        onClick={}
      >
     Accept
      </button> */}
    </Fragment>
  );

  const acceptClaim = () => {
    let claimObj = {
      studio_id: notif.studio_id,
      sender_id: notif.sender_id,
      requested_profile_id: notif.requested_profile_id,
      notif_id: notif._id,
    };
    console.log('claimObj');
    console.log(claimObj);
    acceptStudioProfileClaim(claimObj);
  };

  const newStudioProfileClaim = (
    <Fragment>
      <div className="row">
        <div className="col-3">
          <img
            src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
            alt=""
            height="40"
            width="40"
          />
        </div>
        <div className="col">
          <h5>{notif?.notif_msg}</h5>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-light btn-block"
            onClick={() => {
              console.log(notif);
              acceptClaim();
            }}
          >
            Accept
          </button>
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => console.log('declined')}
          >
            Decline
          </button>
        </div>
      </div>
    </Fragment>
  );
  const studentUpdatedNotification = (
    <Fragment>
      <div className="row">
        <div className="col-3">
          <img
            // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
            src={
              notif?.image
                ? notif?.image.url
                : 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png'
            }
            alt=""
            height="40"
            width="40"
          />
        </div>
        <div className="col">
          <h5 style={{ fontSize: '14px' }}>{notif?.notif_msg}</h5>
        </div>
        <div>
          <h5 style={{ fontSize: '12px' }}>
            {moment(notif.date).format('hh:mm a')}
          </h5>
        </div>
      </div>

      {/* <div className="row">
        <div className="col">
          <button
            type="button"
            className="btn btn-light btn-block"
            onClick={() => {
              console.log(notif);
              acceptClaim();
            }}
          >
            Accept
          </button>
        </div>
        <div className="col">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => console.log('declined')}
          >
            Decline
          </button>
        </div>
      </div> */}
    </Fragment>
  );

  const newTripAddition = (
    <Fragment>
      <div className="row">
        <div className="col-3">
          <img
            src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
            alt=""
            height="40"
            width="40"
          />
        </div>
        <div className="col">
          <h5>{notif?.notif_msg}</h5>
        </div>
      </div>

      {/* <button
        type="button"
        className="btn btn-light btn-block"
        onClick={}
      >
     Accept
      </button> */}
    </Fragment>
  );
  // const newConnectionNotifType2 = (

  // )

  const renderOptions = () => {
    console.log('trying to render');
    if (notif.notif_type === 'new_connection') {
      console.log('new connection type');
      return newConnectionNotifType;
    }
    if (notif.notif_type === 'new_event_attendee') {
      console.log('new event attendee type');
      return newEventAttendee;
    }
    if (notif.notif_type === 'user_added_to_trip') {
      console.log('user_added_to_trip type');
      return newEventAttendee;
    }
    if (notif.notif_type === 'claim_studio_profile') {
      console.log('"claim_studio_profile" type');
      return newStudioProfileClaim;
    }
    if (notif.notif_type === 'STUDENT_GRADES_UPDATED') {
      console.log('"STUDENT_GRADES_UPDATED" type');
      return studentUpdatedNotification;
    }
    // if (user && userAlreadyAttending()) {
    //   return alreadyJoinedButton;
    // } else {
    //   return joinButton;
    // }
  };

  return (
    <div className="container">
      <div className="card bg-light">{renderOptions()}</div>
    </div>
  );
};

// UseMyNotificationsr.propTypes = {};

export default MyNotifications;
