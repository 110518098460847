import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import AddSafetyOfficer from './AddSafetyOfficer';

const SafetyOfficer = (props) => {
  const mockImage =
    'https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg';

  let { event } = props;

  const eventHasSafetyOfficers = () => {};

  return (
    <div>
      {/* <div className="card">
        <div className="row">
          <div className="col-3">
            
            <img
              src={mockImage}
              style={{
                height: '100px',
                width: '100px',
              }}
              alt="user"
            />
          </div>

          <div className="col">
            <div className="row">
              {event?.safety_officers.length > 0 ? (
                <h5>{event?.safety_officers[0].name}</h5>
              ) : (
                <h5>Officer Name</h5>
              )}
            </div>

            <div className="row">
              {event?.safety_officers.length > 0 ? (
                <h5>{event?.safety_officers[0].phone_number}</h5>
              ) : (
                <h5>Contact Number</h5>
              )}
            </div>

            {event?.safety_officers.length > 0 ? (
              <div className="row" style={{ paddingLeft: '14px' }}>
                <h5
                  style={{
                    
                    marginBottom: '12px',
                    background: 'white',
                    
                    borderStyle: 'solid',
                    borderColor: 'gray',
                    borderRadius: 4,
                    fontSize: '14px',
                    backgroundColor: 'white',
                    color: 'gray',
                    textAlign: 'center',
                    padding: '6px',
                    width: 'max-content',
                  }}
                >
                  Safety Officer{' '}
                </h5>
              </div>
            ) : (
              <div>
                <Button onClick={() => console.log('popup')}>
                  Add Safety Officer
                </Button>
              </div>
            )}
          </div>
        </div>

      </div> */}

      {event?.safety_officers?.length > 0 ? (
        <div className="card">
          <div className="row">
            <div className="col-3">
              <img
                src={mockImage}
                style={{
                  height: '100px',
                  width: '100px',
                }}
                alt="user"
              />
            </div>

            <div className="col">
              <div className="row">
                <h5>{event?.safety_officers[0].name}</h5>
              </div>

              <div className="row">
                <h5>{event?.safety_officers[0].phone_number}</h5>
              </div>

              <div className="row" style={{ paddingLeft: '14px' }}>
                <h5
                  style={{
                    marginBottom: '12px',
                    background: 'white',

                    borderStyle: 'solid',
                    borderColor: 'gray',
                    borderRadius: 4,
                    fontSize: '14px',
                    backgroundColor: 'white',
                    color: 'gray',
                    textAlign: 'center',
                    padding: '6px',
                    width: 'max-content',
                  }}
                >
                  Safety Officer{' '}
                </h5>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AddSafetyOfficer event={event} />
      )}

      {/* if no safety officer */}
    </div>
  );
};
export default SafetyOfficer;
