import React, { Component, useContext, useEffect } from 'react';
import UserContext from '../../context/user/userContext';
import UserClaim from './user-claims/UserClaim';

const Claims = (props) => {
  // load in user claims
  const userContext = useContext(UserContext);
  const { getUserClaims, user_claims } = userContext;
  useEffect(() => {
    getUserClaims();
    console.log('user_claims');
    console.log(user_claims);

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h1>User Claims</h1>
      <div>
        {user_claims.map((claim) => (
          <UserClaim key={claim._id} claim={claim} />
        ))}
      </div>
    </div>
  );
};
export default Claims;
