import React, { Component, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserAgenda from './UserAgenda';

const JoinedEventOptionsPopup = (props) => {
  const [show, setShow] = useState(props.optionsClicked);

  const handleClose = () => setShow(false);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>{props.workshop.title}</Modal.Title> */}
          <Modal.Title>{props.event_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {/* <Form.Label>{props.workshop.title}</Form.Label> */}
              <Form.Label>popup</Form.Label>
              {/* <UserAgenda joined_event={props.joined_event} id={id} user={user} /> */}

              {/* <div className="row">
                <div className="col-4">
                  <h5>instructor:</h5>
                </div>
                <div className="col">
                  <Link
                    to={`/artist-profile/${props.workshop.instructor}`}
                    style={{ color: 'rgb(1, 126, 252)' }}
                  >
                    <h5>{props.workshop.instructor_name}</h5>
                  </Link>
                </div>
              </div> */}
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default JoinedEventOptionsPopup;
