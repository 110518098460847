import React, { Component, useState, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import TabContainer from 'react-bootstrap/TabContainer';
// import TabPane from 'react-bootstrap/TabPane';

import { Link } from 'react-router-dom';

// import userContext from '../../context/user/userContext'; // might have to capitalize
import eventContext from '../../context/event/eventContext'; // might have to capitalize
import blogContext from '../../context/blog/blogContext'; // might have to capitalize

// export function EventDetail() {
const PostBlog = (props) => {
  //   const getIdFromParams = (param) => {
  //     //be sure blog titles don't contain -.. or check for it
  //     let arr = param.split('-'); //be sure blog titles don't contain -

  //     // ["blog1","id1"]
  //     // ["blog1","subtitle","id1"]
  //     return arr[arr.length - 1];
  //   };

  //   let { title } = useParams();
  //   let blog_id = getIdFromParams(title);
  const bContext = useContext(blogContext);
  const { postBlogPost, blog_post } = bContext;
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  // const [key, setKey] = useState('attendees');

  // const [key, setKey] = useState(props.page);
  // const uContext = useContext(userContext);
  // const { } = uContext;

  const [show, setShow] = useState(props.showModal);

  const handleClose = () => {
    console.log('closing ');
    setShow(false);
    // showModal = false;
  };
  const handleShow = () => setShow(true);

  const eContext = useContext(eventContext);
  const { editEvent } = eContext;

  const location = useLocation();
  // const { event, page } = location.state;
  const {} = props;
  // useEffect(() => {
  //   getHost(event.hostId);
  //   console.log('event host: ');
  //   console.log(host);
  // }, []);

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    blog_title: '',
    blog_image: '',
    text: '',
    // location: 'Charlotte, NC',
  });

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });
  const { blog_title, blog_image, text } = details;

  const [file, setFile] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving updated user from frontend');

    if (details === '') {
      //   setAlert('Please fill in all fields', 'danger');
    } else {
      const data = new FormData();
      data.append('file', file);
      data.append('title', blog_title);
      data.append('text', text);
      data.append('blog_image', blog_image);

      postBlogPost(data);
    }
  };

  const { showModal } = props;
  console.log(`showModal is ${showModal}`);
  return (
    <div>
      <div className="form-container">
        <h1>{/* Event <span className="text-primary">Details</span> */}</h1>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <h1>Title</h1>
            {/* <label htmlFor="descr">Details</label> */}
            <input
              id="blog_title"
              type="text"
              name="blog_title"
              placeholder="Title"
              value={blog_title}
              onChange={onChange}
              required
            />
            <textarea
              id="text"
              type="textarea"
              name="text"
              placeholder="Blog text"
              value={text}
              onChange={onChange}
              required
              rows="4"
              cols="50"
            />
            <input
              id="blog_image"
              name="blog_image"
              type="file"
              value={blog_image}
              required
              onChange={(event) => {
                const file = event.target.files[0];
                setFile(file);
                setDetails({
                  ...details,
                  [event.target.name]: event.target.value,
                });
              }}
            />

            <input
              type="submit"
              value="Create Post"
              // className="btn btn-primary btn-block"
              class="btn btn-secondary"
              // onClick={onSubmit2}
            />
          </div>
        </form>
      </div>
      {/* render attendees under attendees h1 or bring the attendees to a child component */}
    </div>
  );
};
export default PostBlog;
