import React, { Component, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
// const moment = require('moment');
// moment().format();
const TourDatePopup = (props) => {
  const {
    setDateClicked,
    setDate3,
    setDateFromChild,
    setDateX,
    setNewDate,
  } = props;
  const [date, setDate] = useState();
  const [selected, setSelected] = React.useState();
  const [updateDate, setUpdateDate] = React.useState();
  const setDatePlease = (selected) => {
    console.log('truing to set date from setDateplease');
    console.log(selected);
    // setDetails({ ...details, lessonDate: selected });
    setDate(selected);
  };
  const [emphasisOnSetADateLabel, setEmphasisOnSetADateLabel] = useState(false);

  const renderFooter = () => {
    if (emphasisOnSetADateLabel) {
      return <p style={{ backgroundColor: 'red' }}>Please pick a day.</p>;
    } else {
      return <p>Please pick a day.</p>;
    }
  };
  let footer = renderFooter();

  const [show, setShow] = useState(props.dateClicked);
  const handleClose = () => setShow(false);

  const [details, setDetails] = useState({
    lessonDate: selected,
  });
  if (selected) {
    footer = <p>You picked {format(selected, 'PP')}.</p>;
    // props.setDate2(format(selected, 'PP'));
    setDate3(format(selected, 'PP'));
    // setDateFromChild(format(selected, 'PP'));
    // setDateX(format(selected, 'PP'));
    setNewDate(format(selected, 'PP'));
    // setNewDate(format(selected, 'MM-dd-yy'));
    // setNewDate(moment(selected).format('MM-DD-YYYY'));

    console.log('was selected');
  }
  if (updateDate) {
    setDetails({
      ...details,
      lessonDate: selected,
    });
    console.log('setting date -- selected');
    console.log(selected);
    // props.setDate2(selected);
    setDate3(selected);
    // setDateFromChild(selected);
    // setDateX(selected);
    // setNewDate(selected);

    // setNewDate(format(selected, 'MM-DD-YYYY'));
    setNewDate(format(selected, 'PP'));

    let formatteed = format(selected, 'PP');
    console.log('formatted ' + formatteed);
    setUpdateDate(false);
    setDateClicked(false);
  }
  const { lessonDate } = details;

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DayPicker
            mode="single"
            selected={selected}
            onSelect={setSelected}
            footer={footer}
            onDayClick={() => {
              setSelected(selected);
              setDatePlease(selected);
              console.log('hello');
              console.log('selected');
              console.log(selected);
              setDetails({ ...details, lessonDate: selected });
              setDate(selected);
              //   props.setDate2(selected);
              //   let date = format(selected, 'PP');
              console.log('date x');
              console.log(date);
              //   props.setDate2(format(selected, 'PP'));
              setUpdateDate(true);
              //   setDateClicked(false);
            }}
          />
          {/* <div>
            <Button onClick={setDateClicked(false)}>add date</Button>
          </div> */}
        </Modal.Body>
      </Modal>{' '}
    </div>
  );
};
export default TourDatePopup;
