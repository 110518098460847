import React, { useContext, useEffect, Fragment, useState } from 'react';
import { useAuth } from '../../../context/auth/AuthState';
import AdvertisementContext from '../../../context/advertisement/advertismentContext';
import { useNavigate } from 'react-router-dom';

// import AdvertisementContext from'../../../
const AddEventsThisWeekFields = (props) => {
  const [authState] = useAuth();
  const { isAuthenticated, user } = authState;
  const { event, boostClicked } = props;

  //   const [boostClicked, setBoostClicked] = React.useState(false);

  const advertisementContext = useContext(AdvertisementContext);
  const { createGeneralAdRequest, generalAdRequest } = advertisementContext;

  const [file, setFile] = useState();
  let navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', file);
    data.append('promotionText', event.name);
    data.append('ownersId', user?._id);
    data.append('ownersEmail', user?.email);
    data.append('phoneNumber', user?.phoneNumber);
    data.append('sponsorship_types', sponsorship_array);

    const details = {
      ownersEmail: user?.email,
      promotionText: event.name,
      start_date: event.start_date,
      ownersId: user?._id,
      phoneNumber: user?.phoneNumber,
      promotion_types: sponsorship_array,
    };
    console.log('details from frontend');
    console.log(details);
    createGeneralAdRequest(details);

    // data.append('adImage', adImage);

    // if (promotionText === '' || ownersEmail === '' || phoneNumber === '') {
    //   setAlert('Please enter all fields', 'danger');
    // } else {
    //   register(authDispatch, data);
    // createAd(data);

    // navigate('/', {
    //   state: {
    //     messageText:
    //       'Thank you for your promotion request, a member of our team will follow up shortly',
    //     // advert: advert,
    //     // user: user,

    //     // file: file,
    //     // },
    //   },
    // });

    console.log('create boost Ad');
  };
  let sponsorship_array = [];

  const onChange = (e) => {
    console.log('changing list -- e is:');
    // console.log(e.target);

    if (sponsorship_array.includes(e.target.id)) {
      console.log('removing user');
      sponsorship_array = sponsorship_array.filter(
        (item) => item !== e.target.id
      );
    } else {
      console.log('adding user');
      sponsorship_array.push(e.target.id);
    }

    const member_set = new Set(sponsorship_array);
    const uniqueMembers = [...member_set];

    console.log('sponsorship_array');
    console.log(sponsorship_array);
  };

  return (
    <div>
      {boostClicked && (
        <div>
          <form onSubmit={onSubmit}>
            {/* <h5>boost {event.name}</h5> */}
            <h5 style={{ fontSize: '12px' }}>
              Boosting your event helps it rank higher in the results{' '}
            </h5>
            <div className="row">
              <div className="col-2">$75</div>
              <div className="col">
                <h5>Banner Ad</h5>
              </div>
              <div className="form-check col">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="banner"
                  id="banner"
                  value="banner"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-2">$50</div>
              <div className="col">
                <h5>Sponsored Ad</h5>
              </div>
              <div className="form-check col">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="sponsor"
                  id="sponsor"
                  value="sponsor"
                  onChange={onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-2">$25</div>
              <div className="col">
                <h5>Happening This Week Ad</h5>
              </div>
              <div className="form-check col">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="events_this_week"
                  id="events_this_week"
                  value="events_this_week"
                  onChange={onChange}
                />
              </div>
            </div>
            <div>
              <hr />
              <h5 style={{ fontSize: '12px' }}>
                {' '}
                1) Submit payment via zelle - gb3hundred@gmail.com or cashapp :{' '}
              </h5>
              <h5 style={{ fontSize: '12px' }}> 2) Hit "Boost" button below</h5>
              <h5 style={{ fontSize: '12px' }}> 3) Await email confirmation</h5>
            </div>
            <input
              type="submit"
              value="Boost"
              className="btn btn-primary-dark btn-block"
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default AddEventsThisWeekFields;
