import React, { Component, useEffect, useState } from 'react';
import { Country, State, City } from 'country-state-city';
import { ButtonGroup, Dropdown, DropdownButton, Form } from 'react-bootstrap';
// console.log(State.getAllStates())
import Countries from './Countries.json';
const AddLocation = (props) => {
  let { setCountry, setState, setCity } = props;

  let Country = require('country-state-city').Country;
  let State = require('country-state-city').State;

  const countries = Countries;
  const [selectedCountry, setSelectedCountry] = useState('Select Country');
  //   const [countries, setCountries] = useState(Country.getAllCountries());
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  //   console.log('Country.getAllCountries()');
  //   console.log(Country.getAllCountries());
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [citiesOfState, setCitiesOfState] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const handleCountryChange = (index) => {
    console.log('changed country countryCode');
    // console.log(countryCode);
    // get country from api
    let country = countries[index];
    let selectedCountry = Country.getCountryByCode(country.code);
    // if(selectedCountry === "something we dont want, do extra processing")
    setCountry(country.name);
    console.log('selectedCountry from api');
    console.log(selectedCountry);
    setSelectedCountry(country.name);
    setSelectedCountryCode(country.code);
    //get states by country code

    let statesOfCountry = State.getStatesOfCountry(country.code);
    setStates(statesOfCountry);

    setSelectedState(statesOfCountry[statesOfCountry.length - 1]?.name);
    if (selectedState !== '') {
      console.log('hi');
    }

    // setCityData();
  };

  //   const setCityData = () => {
  //     console.log('selectedCountryCode, selectedState.isoCode');
  //     console.log('selectedCountryCode');
  //     console.log(selectedCountryCode);
  //     console.log('selectedState.isoCode');
  //     console.log(selectedState.isoCode);
  //     let selectedCities = City.getCitiesOfState(
  //       selectedCountryCode,
  //       selectedState.isoCode
  //     );
  //     console.log('selectedCities from selected state');
  //     console.log(selectedCities);
  //   };
  const handleStateChange = (index) => {
    console.log('changed country countryCode');
    // console.log(countryCode);
    // get cities from api
    let state = states[index];
    console.log('state is');
    console.log(state);
    let selectedCities = City.getCitiesOfState(
      selectedCountryCode,
      state.isoCode
    );
    console.log('selectedCities from api');
    console.log(selectedCities);
    setCitiesOfState(selectedCities);
    setSelectedState(state.name);
    setState(state.name);
    // setSelectedCountry(country.name);

    //get states by country code

    // let statesOfCountry = State.getStatesOfCountry(country.code);
    // setStates(statesOfCountry);

    setSelectedCity(selectedCities[0]?.name);

    console.log('selectedCities');
    console.log(selectedCities);
    if (props?.setLocation) {
      props.setLocation(selectedCity + ',' + selectedState);
    }
  };

  const handleCityChange = (index) => {
    console.log('changed country countryCode');
    // console.log(countryCode);
    // get cities from api

    setSelectedCity(citiesOfState[index].name);
    setCity(citiesOfState[index].name);
    if (props?.setLocation) {
      props.setLocation(citiesOfState[index].name + ',' + selectedState);
    }
  };
  return (
    <div>
      <h5 style={{ fontSize: '32px', fontWeight: 'bold' }}>Location</h5>
      <div className="row">
        <div className="col">
          <h5>Country</h5>
        </div>

        <div className="col">
          {[DropdownButton].map((DropdownType, index) => (
            <DropdownType
              as={ButtonGroup}
              key={index}
              id={`dropdown-button-drop-${index}`}
              size="sm"
              variant="secondary"
              title={selectedCountry}
              // title="10:40"
              onSelect={handleCountryChange}
              style={{ width: '180px' }}
              // onChange={onChange}
            >
              {countries.map((country, index) => (
                <Dropdown.Item eventKey={index}>{country.name}</Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h5>State</h5>
        </div>
        <div className="col">
          {[DropdownButton].map((DropdownType, index) => (
            <DropdownType
              as={ButtonGroup}
              key={index}
              id={`dropdown-button-drop-${index}`}
              size="sm"
              variant="secondary"
              title={selectedState}
              onSelect={handleStateChange}
              style={{ width: '180px' }}
            >
              {states.map((state, index) => (
                <Dropdown.Item eventKey={index}>{state.name}</Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h5>City</h5>
        </div>
        <div className="col">
          {[DropdownButton].map((DropdownType, index) => (
            <DropdownType
              as={ButtonGroup}
              key={index}
              id={`dropdown-button-drop-${index}`}
              size="sm"
              variant="secondary"
              title={selectedCity}
              onSelect={handleCityChange}
              style={{ width: '180px' }}
              placeholder="Enter City"
            >
              {citiesOfState.map((city, index) => (
                <Dropdown.Item eventKey={index}>{city.name}</Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>
      </div>
    </div>
  );
};
export default AddLocation;
