import React, { Component, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import eventContext from '../../context/event/eventContext'; // might have to capitalize
import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';
import UserAgenda from './UserAgenda';

const Trip = (props) => {
  const { trip, user } = props;
  const eContext = useContext(eventContext);
  const { getMyItinerary, myItinerary, getOneEvent, event } = eContext;
  const { id } = useParams();

  useEffect(() => {
    getOneEvent(trip.event_id);
    console.log('event from ue');
    console.log(event);
  }, []);

  console.log('event from class');
  console.log(event);

  const handleAgendaClicked = () => {
    if (user) {
      getMyItinerary(trip.event_id, user._id);
    }
    console.log('myItinerary after click is:');
    console.log(myItinerary);
  };
  return (
    <div>
      <div className="form-container">
        <div className="form-group">
          <div className="container">
            <div className="card bg-light">
              <h5 style={{ fontSize: '14px' }}>{trip.event_name}</h5>

              <div>
                <div className="row">
                  <div className="col">
                    <Link
                      to={`/trip/my-trips/${props.trip._id}`}
                      state={{ trip: trip, user: user }}
                    >
                      <Button variant="btn-info">Trip Details</Button>
                    </Link>
                  </div>
                  <div className="col">
                    <Button variant="primary">Room With Me</Button>
                  </div>
                  <div className="col">
                    {/* <Button
                      variant="primary"
                      onClick={() => {
                        handleAgendaClicked();
                        // console.log(
                        //   `trip event id is : ${
                        //     trip.event_id
                        //   } and user id is: ${user._id}`
                        // );
                      }}
                    >
                      Agenda
                    </Button> */}
                    <UserAgenda
                      isTrip={true}
                      trip={trip}
                      joined_event={event}
                      id={id}
                      user={user}
                    />
                  </div>
                  {/* <div className="col-2">
                    <Button
                      variant="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);

                        //the below is breaking when pasting in new window

                        // navigator.clipboard.writeText(
                        //   `localhost:3000/trip/my-trips/${trip._id}`
                        // );
                        console.log('copied text');
                      }}
                    >
                      Share
                    </Button>
                  </div> */}
                  <div className="col">
                    <h5>{trip.roommates_for_event.length} going</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trip;
