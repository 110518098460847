import React, { Component, useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import LessonPlan from './LessonPlan';
import CurriculumContext from '../../../context/curriculum/curriculumContext';
import AddLessonPlan from './AddLessonPlan';
import WeekButton from './WeekButton';
import EnrolledStudents from './EnrolledStudents';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

const CurriculumPopup2 = (props) => {
  const [targetWeek, setTargetWeek] = useState(0);
  const [lessonPlanFromBackend, setLessonPlanFromBackend] = useState({});
  // const getLessonPlanById = () => {
  //   setLessonPlanFromBackend({ lesson_plan_id: '555', moves: 'move1,move2' });
  // };

  let curriculumContext = useContext(CurriculumContext);

  // curriculaForSchool will be list of curriculums
  const {
    getLessonPlanById,
    lessonPlanObj,
    removeCurriculum,
  } = curriculumContext;
  const [showAddLessonPlanComponent, setShowAddLessonPlanComponent] = useState(
    false
  );
  console.log('targetWeek from popup2');
  console.log(targetWeek);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lessonDetails, setLessonDetails] = useState({
    week_to_teach: props?.curriculum?.lesson_plans_by_week[selectedIndex]
      ?.week_to_teach
      ? props?.curriculum?.lesson_plans_by_week[selectedIndex].week_to_teach
      : 1,
  });
  const [showUpdateLessonButton, setShowUpdateLessonButton] = useState(false);
  const handleWeekChange = (week) => {
    console.log('week from popup2');
    console.log(week);
    // setWorkshopTime(time);
    setLessonDetails({ ...lessonDetails, week_to_teach: week });

    //show update button and update backend
    setShowUpdateLessonButton(!showUpdateLessonButton);
  };

  const updateLessonPlan = () => {
    console.log('props?.curriculum?.lesson_plans_by_week[selectedIndex]');
    console.log({
      curriculum_index: props?.curriculum?.lesson_plans_by_week[selectedIndex],
      week_to_teach: week_to_teach,
    });
    console.log('update lesson');
  };
  let { week_to_teach } = lessonDetails;

  return (
    <div className="card">
      {props.curriculum.name}

      {props.showCurriculumPopup && (
        <div className="card">
          <Modal show={props.setShowCurriculumPopup} onHide={props.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {props.curriculum.curriculum_name} - Curriculum
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div>
                
                {props.curriculum.curriculum_name}
              </div> */}
              {!showAddLessonPlanComponent ? (
                <div
                  class="container-fluid  overflow-auto example"
                  style={{ padding: '0px' }}
                >
                  <div className="row">
                    <div className="col">
                      <h1>Weeks</h1>
                    </div>
                    <div className="col">
                      <div>
                        <Button
                          onClick={() =>
                            setShowAddLessonPlanComponent(
                              !showAddLessonPlanComponent
                            )
                          }
                        >
                          {showAddLessonPlanComponent
                            ? `cancel`
                            : `add lesson plan`}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-row flex-nowrap">
                    {props.curriculum?.lesson_plans_by_week.map(
                      (lesson_plan, index) => (
                        <WeekButton
                          getLessonPlanById={getLessonPlanById}
                          setTargetWeek={setTargetWeek}
                          lessonPlanObj={lessonPlanObj}
                          // weekButtonClicked={weekButtonClicked}
                          // setWeekButtonClicked={setWeekButtonClicked}
                          lesson_plan={lesson_plan}
                          lesson_plan_target_id={lesson_plan._id}
                          selectedIndex={selectedIndex}
                          setSelectedIndex={setSelectedIndex}
                          index={index}
                          curriculum={props?.curriculum}
                          lessonDetails={lessonDetails}
                          setLessonDetails={setLessonDetails}
                        />

                        // <div className="row">

                        // <div
                        //   className="curriculum-week-card bg-light"
                        //   style={{ paddingRight: '40px' }}
                        // >

                        // </div>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <Button
                    onClick={() =>
                      setShowAddLessonPlanComponent(!showAddLessonPlanComponent)
                    }
                  >
                    {showAddLessonPlanComponent ? `cancel` : `add lesson plan`}
                  </Button>
                </div>
              )}
              {/* <Curriculum curriculum={props.curriculum._id} /> */}
            </Modal.Body>
            <div className="card">
              {!showAddLessonPlanComponent ? (
                <div>
                  {/* <h1>Current Weeks Lesson Plan</h1> */}

                  {selectedIndex ? (
                    <h5>
                      date :{' '}
                      {
                        props?.curriculum?.lesson_plans_by_week[selectedIndex]
                          .lesson_date
                      }
                      <hr />
                      {/* <div className="row">
                        <div className="col">
                          <label htmlFor="">Lesson Week yoooo</label>
                        </div>
                        <div className="col">
                          {[DropdownButton].map((DropdownType, idx) => (
                            <DropdownType
                              as={ButtonGroup}
                              key={idx}
                              id={`dropdown-button-drop-${idx}`}
                              size="sm"
                              variant="secondary"
                              title={week_to_teach}
                              onSelect={handleWeekChange}
                            >
                              {Array.from({ length: 10 }, (_, i) => i + 1).map(
                                (type) => (
                                  <Dropdown.Item eventKey={type}>
                                    {type}
                                  </Dropdown.Item>
                                )
                              )}
                            </DropdownType>
                          ))}
                        </div>
                      </div> */}
                    </h5>
                  ) : (
                    <></>
                  )}
                  {/* {showUpdateLessonButton ? (
                    <div>
                      <Button>Update Lesson</Button>
                    </div>
                  ) : (
                    <></>
                  )} */}

                  {/* pass week and lesson_planto new component */}
                  <LessonPlan
                    // weekNumber={weekNumber}
                    //before:
                    // lesson_plan_id={targetWeek}
                    lesson_plan={targetWeek}
                    lessonPlanObj={lessonPlanObj}
                    curriculum={props?.curriculum}

                    // getLessonPlanById={getLessonPlanById}
                    // lessonPlanFromBackend={lessonPlanFromBackend}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
            {showUpdateLessonButton ? (
              <div>
                <Button onClick={() => updateLessonPlan()}>
                  Update Lesson
                </Button>
              </div>
            ) : (
              <></>
            )}
            {/* <h1>{targetWeek}</h1> */}
            {showAddLessonPlanComponent && (
              <AddLessonPlan
                curriculum_id={props.curriculum._id}
                curriculum_dance_style={props.curriculum.dance_style}
              />
            )}

            <EnrolledStudents
              curriculum_id={props.curriculum._id}
              school_id={props?.school_id}
            />

            <Button
              onClick={() => {
                removeCurriculum(props.curriculum._id, props?.school_id);
              }}
              className="btn-danger"
              style={{ width: '300px' }}
            >
              Delete Curriculum
            </Button>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default CurriculumPopup2;

{
  /* <div> */
}
{
  /* <h1>Current Weeks Lesson Plan</h1>; */
}
{
  /* pass week and lesson_planto new component */
}
{
  /* <LessonPlan
  // weekNumber={weekNumber}
  lesson_plan={lesson_plan}
/>
</div> */
}
