import { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled from '@emotion/styled';
// import styled from 'styled';
import axios from 'axios';

import Row from './prebuilt/Row';
import BillingDetailsFields from './prebuilt/BillingDetailsFields';
import SubmitButton from './prebuilt/SubmitButton';
import CheckoutError from './prebuilt/CheckoutError';
import { useAuth } from '../../context/auth/AuthState';
import { Link } from 'react-router-dom';

// const CardElementContainer = styled.div`
const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

const StudioSubscriptionCheckoutForm = ({ price, onSuccessfulCheckout }) => {
  const [authState, authDispatch] = useAuth();
  const { user } = authState;

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const stripe = useStripe();
  const elements = useElements();

  // TIP
  // use the cardElements onChange prop to add a handler
  // for setting any errors:

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async (ev) => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value,
      },
    };

    setProcessingTo(true);

    const cardElement = elements.getElement('card');

    try {
      // const { data: clientSecret } = await axios.post('/api/payment_intents', {
      //   amount: price * 100,
      // });

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      // if (paymentMethodReq.error) {
      //   setCheckoutError(paymentMethodReq.error.message);
      //   setProcessingTo(false);
      //   return;
      // }
      //subscription code

      // const res = await axios.post(
      //   '/api/payment_intents/subscribe-to-premium-artist',
      //   {
      //     // amount: price * 100,
      //     payment_method: paymentMethodReq.paymentMethod.id,
      //     email: billingDetails.email,
      //   }
      // );

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        console.log(paymentMethodReq.error.message);
        return;
      } else {
        const res = await axios.post(
          '/api/payment_intents/subscribe-to-premium-studio',
          {
            payment_method: paymentMethodReq.paymentMethod.id,
            email: billingDetails.email,
          }
        );
        // eslint-disable-next-line camelcase
        const { client_secret, status } = res.data;

        if (status === 'requires_action') {
          stripe.confirmCardPayment(client_secret).then(function(result) {
            if (result.error) {
              console.log('There was an issue!');
              console.log(result.error);
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
            } else {
              console.log('You got the money!');
              // Show a success message to your customer
            }
          });
        } else {
          console.log('You got the money!');
          // No additional information was needed
          // Show a success message to your customer

          //we could run the convertArtistToPremiumSubscription() here
          onSuccessfulCheckout();
        }
      }

      // const { error } = await stripe.confirmCardPayment(clientSecret, {
      //   payment_method: paymentMethodReq.paymentMethod.id,
      // });

      // if (error) {
      //   setCheckoutError(error.message);
      //   setProcessingTo(false);
      //   return;
      // }
      // commented out on 10.12.23
      // onSuccessfulCheckout();
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  // Learning
  // A common ask/bug that users run into is:
  // How do you change the color of the card element input text?
  // How do you change the font-size of the card element input text?
  // How do you change the placeholder color?
  // The answer to all of the above is to use the `style` option.
  // It's common to hear users confused why the card element appears impervious
  // to all their styles. No matter what classes they add to the parent element
  // nothing within the card element seems to change. The reason for this is that
  // the card element is housed within an iframe and:
  // > styles do not cascade from a parent window down into its iframes

  const iframeStyles = {
    base: {
      color: 'black',
      fontSize: '16px',
      iconColor: '#fff',
      '::placeholder': {
        color: 'gray',
      },
    },
    invalid: {
      iconColor: '#red',
      color: '#red',
    },
    complete: {
      iconColor: '#cbf4c9',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <div className="smallScreenPayFields">
      {!user ? (
        <Link to="/login">
          <label style={{ color: 'red' }}>sign in to checkout</label>
        </Link>
      ) : (
        <></>
      )}
      <form onSubmit={handleFormSubmit}>
        {/* <div className="smallScreenPayFields"> */}
        <Row>
          <BillingDetailsFields />
        </Row>
        <label>input card info and screen will auto-scroll</label>

        {/* </div> */}
        <Row>
          <CardElementContainer>
            <CardElement
              options={cardElementOpts}
              onChange={handleCardDetailsChange}
            />
          </CardElementContainer>
        </Row>
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        <Row>
          {/* TIP always disable your submit button while processing payments */}
          {!user ? (
            <Link to="/login">
              <label style={{ color: 'red' }}>sign in to checkout</label>
            </Link>
          ) : (
            <></>
          )}
          <SubmitButton disabled={isProcessing || !stripe || !user}>
            {isProcessing ? 'Processing...' : `Pay $${price} / month`}
          </SubmitButton>
        </Row>
      </form>
    </div>
  );
};

export default StudioSubscriptionCheckoutForm;
