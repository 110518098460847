import React from 'react';
import userContext from '../../context/user/userContext'; // might have to capitalize
import JoinedEvent from '../../components/events/JoinedEvent'; // might have to capitalize
// import MyEvents from '../../components/events/MyEvents'; // might have to capitalize
// import AuthContext from '../../context/auth/authContext';
// import { useAuth, loadUser } from '../../context/auth/AuthState';
import { useAuth } from '../../context/auth/AuthState';
import spinner from './spinner.gif';

import { Navigate } from 'react-router-dom';

import { useContext, useEffect } from 'react';
import Trip from './Trip';
// export default class UpcomingEvents extends Component {

const UpcomingEventsForUser = (props) => {
  const [authState] = useAuth();
  const { user, isAuthenticated, loading } = authState;
  const uContext = useContext(userContext);
  // const { getJoinedEventsForUser, joined_events, trips, loading2 } = uContext;
  const { joined_events, trips, isCurrentUser } = props;
  // const authContext = useContext(AuthContext);
  useEffect(
    () => {
      // authContext.loadUser();
      // loadUser();
      //returns all users -- we need to paginate this so we only get one at a time.
      //that comes later
      // if (isAuthenticated && user != null) {
      //   console.log('user is authenticated');
      //   console.log('user._id is ---------------');
      //   console.log(user._id);
      //   getJoinedEventsForUser(user._id);
      // }
      //there is an error thrown when we reload page
      //bcuz we are calling useEffect and getting "user" object from authState
      //figure out how to fix this or call the user._id from somewhere else..
      // getJoinedEventsForUser('6248c1337e4d6d57c43991f1'); //-this works without error on reload
      // eslint-disable-next-line
    },
    [isAuthenticated]
  );
  // if (!isAuthenticated && !loading) {
  //   return <Navigate to="/login" />;
  // }
  // if (loading2) {
  //   return <h2>Loading...</h2>;
  // }

  if (loading) {
    return (
      <img
        src={spinner}
        style={{ width: '200px', margin: 'auto', display: 'block' }}
        alt="Loading..."
      />
    );
  }
  console.log('isCurrentUser from UpcomingEventsForUser');
  console.log(isCurrentUser);
  return (
    <div>
      {/* <div>
        <MyEvents events_and_roommates={user.events_with_roommates} />
      </div> */}

      {/* <div>
        {user.events_with_roommates.map((event_and_roommates) => (
          <MyEvents
            key={event_and_roommates.id}
            event_and_roommates={event_and_roommates}
          />
        ))}
      </div> */}
      <h3>Events</h3>
      {joined_events.map((joined_event) => (
        <JoinedEvent
          key={joined_event.id}
          joined_event={joined_event}
          user={user}
          isCurrentUser={isCurrentUser}
        />
      ))}

      <h3>Trips</h3>
      {trips.map((trip) => (
        <Trip key={trip._id} trip={trip} user={user} />
      ))}
    </div>
  );
};

export default UpcomingEventsForUser;
