import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import OnboardStudioSearch from './OnboardStudioSearch';

const StudioOnboard = (props) => {
  const returnNextScreen = (array) => {
    switch (array[0]) {
      case 'Instructor':
        return '/instructor-onboarding';
      case 'event organizer':
        return '/';
      // return '/event-organizer-onboarding';

      case 'dance student':
        return '/student-onboarding';

      default:
        console.log('defualt route running');
        return '/';
    }
  };

  let remainingScreens = '';
  const location = useLocation();

  let { onboardingScreens } = location.state;
  if (onboardingScreens.length > 1) {
    remainingScreens = onboardingScreens.filter(
      (screen) => screen !== 'Dance Company Owner'
      // (screen) => screen !== onboardingScreens[0]
    );
  }

  const renderDynamicRoutes = () => {
    if (remainingScreens.length > 0) {
      console.log('remaining screens --:');
      console.log(remainingScreens);
      let nextScreen = returnNextScreen(remainingScreens);
      console.log('nextScreen is');
      console.log(nextScreen);
      return (
        <Link to={nextScreen} state={{ onboardingScreens: remainingScreens }}>
          <Button>next</Button>
        </Link>
      );
    } else {
      console.log('we should have a onboard complete button');

      console.log(remainingScreens);
      return (
        <Link to={'/'}>
          <Button>Onboard Complete, Let's Dance! </Button>
        </Link>
      );
    }
  };

  // while we arent using  studioOnboardAlsoCompleted, for the studioonboard.js
  //bcuz there is a shared child component using setstudioOnboardAlsoCompleted so
  //we need to keep setstudioOnboardAlsoCompleted and pass to props bcuz
  //its currently breaking and is a bit more complex than i want to think about
  //fixing right now - dec. 16 2023
  const [studioOnboardAlsoCompleted, setStudioOnboardAlsoCompleted] = useState(
    false
  );

  return (
    <div>
      <h5>StudioOnboard onboard</h5>
      <OnboardStudioSearch
        setStudioOnboardAlsoCompleted={setStudioOnboardAlsoCompleted}
      />
      {renderDynamicRoutes()}
    </div>
  );
};

export default StudioOnboard;
