export const GET_USERS_ONE_AT_A_TIME = 'GET_USERS_ONE_AT_A_TIME';
export const GET_USER_EVENTS = 'GET_USER_EVENTS';
export const GET_USER_REFERRALS = 'GET_USER_REFERRALS';
export const LIKE_USER = 'LIKE_USER';
export const LIKE_COMPANY = 'LIKE_COMPANY';
export const PASS_ON_USER = 'PASS_ON_USER';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_USER = 'GET_USER';
export const GET_ONE_USER = 'GET_ONE_USER';
export const SAVE_STYLE = 'SAVE_STYLE';
export const GET_MY_PRIVATE_LESSONS = 'GET_MY_PRIVATE_LESSONS';
export const EDIT_USER_PROFILE = 'EDIT_USER_PROFILE';
export const GET_JOINED_EVENTS = 'GET_JOINED_EVENTS';
export const ACCEPT_ROOMMATES_FOR_JOINED_EVENTS =
  'ACCEPT_ROOMMATES_FOR_JOINED_EVENTS';
export const ADD_BUSINESS_DETAILS = 'ADD_BUSINESS_DETAILS';

export const SEND_ROOMMATE_REQUEST_ACCEPTED_SMS =
  'SEND_ROOMMATE_REQUEST_ACCEPTED_SMS';

export const SEND_VERIFICATION_AND_CLAIM_ARTIST =
  'SEND_VERIFICATION_AND_CLAIM_ARTIST';
export const SEND_VERIFICATION_AND_CLAIM_STUDIO =
  'SEND_VERIFICATION_AND_CLAIM_STUDIO';
export const GET_USER_CLAIMS = 'GET_USER_CLAIMS';
export const ADD_TO_SAFETY_COUNCIL = 'ADD_TO_SAFETY_COUNCIL';
export const SEND_VERIFICATION_FOR_EVENT_OWNERSHIP =
  'SEND_VERIFICATION_FOR_EVENT_OWNERSHIP';
export const USER_ERROR = 'USER_ERROR';

export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_EVENTS_OF_TYPE = 'GET_EVENTS_OF_TYPE';
export const GET_HIGHLIGHTED_EVENTS = 'GET_HIGHLIGHTED_EVENTS';
export const GET_EVENTS_BY_LOCATION = 'GET_EVENTS_BY_LOCATION';
export const GET_ONE_EVENT = 'GET_ONE_EVENT';
export const GET_MAIN_EVENT = 'GET_MAIN_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const EDIT_EVENT = 'EDIT_EVENT';
export const CREATE_EVENT = 'CREATE_EVENT';
export const JOIN_EVENT = 'JOIN_EVENT';
export const UNJOIN_EVENT = 'UNJOIN_EVENT';
export const ADD_NEW_DJ = 'ADD_NEW_DJ';
export const GET_DJS_AND_REQUESTS = 'GET_DJS_AND_REQUESTS';
export const ADD_SONG_REQUEST = 'ADD_SONG_REQUEST';
export const ADD_EVENT_FEEDBACK = 'ADD_EVENT_FEEDBACK';
export const GET_ATTENDEE_FEEDBACK = 'GET_ATTENDEE_FEEDBACK';
export const GET_EVENTS_FOR_SEARCH_CRITERIA = 'GET_EVENTS_FOR_SEARCH_CRITERIA';

export const EVENT_ERROR = 'EVENT_ERROR';
export const GET_ATTENDEES_FOR_EVENT = 'GET_ATTENDEES_FOR_EVENT';
export const GET_ROOMMATE_REQUESTS_FOR_EVENT =
  'GET_ROOMMATE_REQUESTS_FOR_EVENT';
export const GET_EVENTS_AND_ROOMMATES_FOR_USER =
  'GET_EVENTS_AND_ROOMMATES_FOR_USER';
export const ADD_PREMIUM_EVENT_SUBSCRIPTION = 'ADD_PREMIUM_EVENT_SUBSCRIPTION';
export const ADD_SAFETY_OFFICERS = 'ADD_SAFETY_OFFICERS';

export const REQUEST_ROOMMATE = 'REQUEST_ROOMMATE';
export const GET_USERS_FOR_SEARCH_CRITERIA = 'GET_USERS_FOR_SEARCH_CRITERIA';

// export const SEARCH_USERS = 'SEARCH_USERS';
// export const CLEAR_USERS = 'CLEAR_USERS';
// export const GET_REPOS = 'GET_REPOS';
// export const SET_LOADING = 'SET_LOADING';
// export const SET_ALERT = 'SET_ALERT';
// export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOADED = 'USER_LOADED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_ALERT = 'SET_ALERT';

//Connections
export const ACCEPT_CONNECTION_REQUEST = 'ACCEPT_CONNECTION_REQUEST';
export const CONNECT_TO_USER = 'CONNECT_TO_USER';
export const GET_CURRENT_USER_CONNECTIONS = 'GET_CURRENT_USER_CONNECTIONS';

export const GET_CONNECTIONS_FOR_USER = 'GET_CONNECTIONS_FOR_USER';

export const ADD_USERS_AS_ROOMMATES = 'ADD_USERS_AS_ROOMMATES';

//ADD ROOM NUMBER
export const ADD_ROOM_NUMBER = 'ADD_ROOM_NUMBER';

export const CREATE_NEW_TRIP_AND_ADD_ROOMMATES =
  'CREATE_NEW_TRIP_AND_ADD_ROOMMATES';

export const EDIT_USER_PROFILE_IMAGE = 'EDIT_USER_PROFILE_IMAGE';

//SUB EVENTS

export const CREATE_SUB_EVENT = 'CREATE_SUB_EVENT';
export const GET_SUB_EVENTS_FOR_EVENT = 'GET_SUB_EVENTS_FOR_EVENT';
export const GET_ATTENDEES_FOR_SUB_EVENT = 'GET_ATTENDEES_FOR_SUB_EVENT';
export const JOIN_SUB_EVENT = 'JOIN_SUB_EVENT';
export const GET_ALL_SUB_EVENTS = 'GET_ALL_SUB_EVENTS';

export const GET_HOST = 'GET_HOST';

export const GET_USERS_FROM_CITY = 'GET_USERS_FROM_CITY';

// ADS

export const CREATE_AD_REQUEST = 'CREATE_AD_REQUEST';
export const GET_AD_REQUESTS = 'GET_AD_REQUESTS';
export const GET_BANNER_AD_REQUESTS = 'GET_BANNER_AD_REQUESTS';
export const GET_EVENTS_THIS_WEEK_AD_REQUESTS =
  'GET_EVENTS_THIS_WEEK_AD_REQUESTS';
export const APPROVE_AD_REQUEST = 'APPROVE_AD_REQUEST';
export const APPROVE_BANNER_AD_REQUEST = 'APPROVE_BANNER_AD_REQUEST';
export const APPROVE_ETW_AD_REQUEST = 'APPROVE_ETW_AD_REQUEST';
export const CREATE_AD = 'CREATE_AD';
export const GET_ONE_AD = 'GET_ONE_AD';
export const GET_SIDE_AD = 'GET_SIDE_AD';
export const GET_ALL_ADS = 'GET_ALL_ADS';
export const GET_BANNER_ADS = 'GET_BANNER_ADS';
export const AD_ERROR = 'AD_ERROR';
export const GET_MAIN_AD = 'GET_MAIN_AD';
export const DENY_AD_REQUEST = 'DENY_AD_REQUEST';

export const CREATE_GENERAL_AD_REQUEST = 'CREATE_GENERAL_AD_REQUEST';

//SHARKTANK
export const GET_SHARKTANK_PARTICIPANTS = 'GET_SHARKTANK_PARTICIPANTS';
export const GET_ONE_SHARKTANK_PARTICIPANT = 'GET_ONE_SHARKTANK_PARTICIPANT';

export const JOIN_SHARKTANK = 'JOIN_SHARKTANK';

export const JOIN_EVENT_AS_VENDOR = 'JOIN_EVENT_AS_VENDOR';

export const GET_VENDORS_FOR_EVENT = 'GET_VENDORS_FOR_EVENT';

// NOTIFICATIONS -----------------

export const GET_USERS_NOTIFICATIONS = 'GET_USERS_NOTIFICATIONS';
export const SEND_NEW_USER_CONNECTION_NOTIF = 'SEND_NEW_USER_CONNECTION_NOTIF';
export const SEND_NEW_USER_ADDED_TO_TRIP_NOTIF =
  'SEND_NEW_USER_ADDED_TO_TRIP_NOTIF';
export const NOTIF_ERROR = 'NOTIF_ERROR';

//ARTIST LINEUP

export const ADD_TO_LINEUP = 'ADD_TO_LINEUP';
export const JOIN_LINEUP = 'JOIN_LINEUP';
export const GET_LINEUP_FOR_EVENT = 'GET_LINEUP_FOR_EVENT';
export const GET_DICT_LINEUP_FOR_EVENT = 'GET_DICT_LINEUP_FOR_EVENT';
export const GET_ALL_ARTISTS = 'GET_ALL_ARTISTS';
export const GET_ALL_ARTISTS_DICTIONARY = 'GET_ALL_ARTISTS_DICTIONARY';

//ARTISTS
export const GET_ONE_ARTIST = 'GET_ONE_ARTIST';
export const GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST =
  'GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST';
export const SEND_PRIVATE_LESSON_REQUEST = 'SEND_PRIVATE_LESSON_REQUEST';
export const DECISION_PRIVATE_LESSON_REQUEST =
  'DECISION_PRIVATE_LESSON_REQUEST';

export const GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST_AT_EVENTS =
  'GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST_AT_EVENTS';
export const GET_ALL_ARTISTS_FOR_DIRECTORY = 'GET_ALL_ARTISTS_FOR_DIRECTORY';
export const GET_OTHER_ARTISTS = 'GET_OTHER_ARTISTS';
export const GET_ONE_RANDOM_ARTIST = 'GET_ONE_RANDOM_ARTIST';
export const GET_TOP_ARTISTS_LIMIT_FIVE = 'GET_TOP_ARTISTS_LIMIT_FIVE';
export const GET_ARTISTS_FOR_SEARCH_CRITERIA =
  'GET_ARTISTS_FOR_SEARCH_CRITERIA';
export const CONVERT_TO_PREMIUM_ARTIST = 'CONVERT_TO_PREMIUM_ARTIST';
export const GET_ONE_ARTISTS_RANKING = 'GET_ONE_ARTISTS_RANKING';
export const GET_RANDOM_ARTISTS_FOR_TIMELINE =
  'GET_RANDOM_ARTISTS_FOR_TIMELINE';
export const GET_ALL_DJS = 'GET_ALL_DJS';
export const GET_RANDOM_DJS_FOR_TIMELINE = 'GET_RANDOM_DJS_FOR_TIMELINE';
export const SEND_PRIVATE_LESSON_REQUEST_AT_EVENT =
  'SEND_PRIVATE_LESSON_REQUEST_AT_EVENT';
export const ADD_NEW_ARTIST = 'ADD_NEW_ARTIST';

export const GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_NO_EVENT =
  'GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_NO_EVENT';
export const GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_AT_EVENT =
  'GET_CONFIRMED_LESSONS_FOR_ARTIST_INSTRUCTOR_AT_EVENT';

export const DECISION_PRIVATE_LESSON_REQUEST_AT_EVENT =
  'DECISION_PRIVATE_LESSON_REQUEST_AT_EVENT';
export const SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_ARTIST_INSTRUCTOR =
  'SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_ARTIST_INSTRUCTOR';
export const ARTIST_ERROR = 'ARTIST_ERROR';

//INSTRUCTORS

export const GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR =
  'GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR';
export const GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR_AT_EVENTS =
  'GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR_AT_EVENTS';
export const SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR =
  'SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR';
export const DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR =
  'DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR';
export const SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR_AT_EVENT =
  'SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR_AT_EVENT';

export const GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_NO_EVENT =
  'GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_NO_EVENT';
export const GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_AT_EVENT =
  'GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_AT_EVENT';
export const DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR_AT_EVENT =
  'DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR_AT_EVENT';
export const SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_INSTRUCTOR =
  'SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_INSTRUCTOR';
export const INSTRUCTOR_ERROR = 'INSTRUCTOR_ERROR';

//WORKSHOP SCHEDULE
export const GET_WORKSHOP_SCHEDULE = 'GET_WORKSHOP_SCHEDULE';
export const GET_WORKSHOP_SCHEDULE_NEW_STRUCTURE =
  'GET_WORKSHOP_SCHEDULE_NEW_STRUCTURE';
export const UPDATE_WORKSHOP = 'UPDATE_WORKSHOP';
export const GET_TAKEN_WORKSHOP_ROOMS = 'GET_TAKEN_WORKSHOP_ROOMS';
export const GET_WORKSHOP_ATTENDEES = 'GET_WORKSHOP_ATTENDEES';
export const GET_JOINED_WORKSHOP = 'GET_JOINED_WORKSHOP';
export const CREATE_NEW_ARTIST = 'CREATE_NEW_ARTIST';
export const GET_USER_ITINERARY = 'GET_USER_ITINERARY';

//top list

export const GET_TOP_DANCERS_BY_LOCATION = 'GET_TOP_DANCERS_BY_LOCATION';
export const GET_TOP_STUDIOS_BY_LOCATION = 'GET_TOP_STUDIOS_BY_LOCATION';
export const TOP_LIST_ERROR = 'TOP_LIST_ERROR';
export const LIKE_DANCER = 'LIKE_DANCER';
export const ADD_NEW_DANCER = 'ADD_NEW_DANCER';

//STUDIO
export const GET_ALL_STUDIOS = 'GET_ALL_STUDIOS';
export const GET_TOP_STUDIOS_LIMIT_FIVE = 'GET_TOP_STUDIOS_LIMIT_FIVE';
export const GET_STUDIOS_BY_REGION = 'GET_STUDIOS_BY_REGION';
export const GET_STUDIOS_FOR_SEARCH_CRITERIA =
  'GET_STUDIOS_FOR_SEARCH_CRITERIA';
export const GET_ONE_STUDIO = 'GET_ONE_STUDIO';
export const STUDIO_ERROR = 'STUDIO_ERROR';
export const GET_STUDIO_MEMBERS = 'GET_STUDIO_MEMBERS';
export const ADD_NEW_MEMBER = 'ADD_NEW_MEMBER';
export const ADD_NEW_LEVEL = 'ADD_NEW_LEVEL';
export const GET_STUDIO_LEVELS = 'GET_STUDIO_LEVELS';
export const UPDATE_MEMBER_LEVEL = 'UPDATE_MEMBER_LEVEL';
export const GET_STUDIO_MEMBER = 'GET_STUDIO_MEMBER';
export const UPDATE_STUDENTS_SCORES = 'UPDATE_STUDENTS_SCORES';
export const ADD_NEW_STUDIO = 'ADD_NEW_STUDIO';
export const GET_USER_STUDIOS = 'GET_USER_STUDIOS';
export const GET_COMPANY_EVENTS = 'GET_COMPANY_EVENTS';
export const JOIN_STUDIO = 'JOIN_STUDIO';
export const ADD_PREMIUM_SUBSCRIPTION = 'ADD_PREMIUM_SUBSCRIPTION';
export const GET_ALL_PERFORMANCES = 'GET_ALL_PERFORMANCES';
export const ENROLL_STUDENT = 'ENROLL_STUDENT';
export const GET_STUDIOS_FOR_TIMELINE = 'GET_STUDIOS_FOR_TIMELINE';
export const JOIN_STUDIO_FROM_ONBOARD = 'JOIN_STUDIO_FROM_ONBOARD';
export const EDIT_STUDIO = 'EDIT_STUDIO';

//claims
export const CLAIM_STUDIO_PROFILE_REQUEST = 'CLAIM_STUDIO_PROFILE_REQUEST';
export const LINK_MEMBER_TO_PERSONAL_ACCOUNT =
  'LINK_MEMBER_TO_PERSONAL_ACCOUNT';

export const DECISION_CLAIM = 'DECISION_CLAIM';

//BLOGS

export const GET_BLOG_POST = 'GET_BLOG_POST';
export const POST_BLOG_POST = 'POST_BLOG_POST';
export const BLOG_ERROR = 'BLOG_ERROR';

//EVENTS_THIS_WEEK

export const GET_EVENTS_THIS_WEEK = 'GET_EVENTS_THIS_WEEK';
export const GET_BOOSTED_EVENTS_THIS_WEEK = 'GET_BOOSTED_EVENTS_THIS_WEEK';
export const CREATE_EVENT_THIS_WEEK = 'CREATE_EVENT_THIS_WEEK';
export const EVENTS_THIS_WEEK_ERROR = 'EVENTS_THIS_WEEK_ERROR';
export const GET_EVENTS_THIS_WEEK_BY_LOCATION =
  'GET_EVENTS_THIS_WEEK_BY_LOCATION';

//PERFORMANCE REVIEWS
export const GET_PERFORMERS_FOR_EVENT = 'GET_PERFORMERS_FOR_EVENT';
export const GET_REVIEWS_FOR_GROUP_FOR_EVENT =
  'GET_REVIEWS_FOR_GROUP_FOR_EVENT';
export const GET_REVIEWS_FOR_COMPANY_FOR_EVENT =
  'GET_REVIEWS_FOR_COMPANY_FOR_EVENT';
export const GET_ALL_REVIEWS_FOR_EVENT = 'GET_ALL_REVIEWS_FOR_EVENT';
export const SUBMIT_COMPANY_PERFORMANCE_REVIEW =
  'SUBMIT_COMPANY_PERFORMANCE_REVIEW';
export const GET_ALL_COMPANY_REVIEWS_FOR_EVENT =
  'GET_ALL_COMPANY_REVIEWS_FOR_EVENT';
export const SUBMIT_PERFORMANCE_REVIEW = 'SUBMIT_PERFORMANCE_REVIEW';
export const ADD_PERFORMERS = 'ADD_PERFORMERS';
export const ADD_COMPANY_PERFORMERS = 'ADD_COMPANY_PERFORMERS';
export const PERFORMANCE_REVIEW_ERROR = 'PERFORMANCE_REVIEW_ERROR';

//Reactions
export const GET_ALL_REACTIONS = 'GET_ALL_REACTIONS';
export const GET_REACTIONS_FOR_DANCER = 'GET_REACTIONS_FOR_DANCER';
export const POST_REACTION_FOR_DANCER = 'POST_REACTION_FOR_DANCER';
export const ADD_DANCER = 'ADD_DANCER';
export const REACTION_ERROR = 'REACTION_ERROR';

//Chats
export const GET_CHATS_FOR_EVENT_AND_USER = 'GET_CHATS_FOR_EVENT_AND_USER';
export const GET_SINGLE_CHAT_MESSAGES = 'GET_SINGLE_CHAT_MESSAGES';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const ADD_NEW_GROUP_CHAT = 'ADD_NEW_GROUP_CHAT';
export const JOIN_CHAT = 'JOIN_CHAT';
export const CHAT_ERROR = 'CHAT_ERROR';

//Rankings

export const GET_RANKINGS = 'GET_RANKINGS';
export const GET_SKILLS_AND_ENDORSEMENTS = 'GET_SKILLS_AND_ENDORSEMENTS';
export const SUBMIT_ENDORSEMENT = 'SUBMIT_ENDORSEMENT';
export const GET_FILTERED_RANKINGS = 'GET_FILTERED_RANKINGS';
export const GET_RANDOM_RANKINGS = 'GET_RANDOM_RANKINGS';
export const RANKINGS_ERROR = 'RANKINGS_ERROR';

//CURRICULUM
export const GET_CURRICULUM = 'GET_CURRICULUM';
export const ADD_CURRICULUM = 'ADD_CURRICULUM';
export const ADOPT_CURRICULUM = 'ADOPT_CURRICULUM';
export const GET_DANCE_MOVES_BY_STYLE = 'GET_DANCE_MOVES_BY_STYLE';
export const GET_DANCE_MOVES_BY_STYLE2 = 'GET_DANCE_MOVES_BY_STYLE2';
export const GET_CURRICULA_FOR_SCHOOL = 'GET_CURRICULA_FOR_SCHOOL';
export const GET_LESSON_PLAN_BY_ID = 'GET_LESSON_PLAN_BY_ID';
export const ADD_LESSON_PLAN_FOR_CURRICULUM = 'ADD_LESSON_PLAN_FOR_CURRICULUM';
export const ADD_CURRICULUM_AND_LESSON_PLAN = 'ADD_CURRICULUM_AND_LESSON_PLAN';
export const REMOVE_CURRICULUM = 'REMOVE_CURRICULUM';
export const GET_CURRICULUMS_WITH_STYLE_AND_LEVEL =
  'GET_CURRICULUMS_WITH_STYLE_AND_LEVEL';
export const GET_LESSON_PLANS_FOR_CURRICULUM =
  'GET_LESSON_PLANS_FOR_CURRICULUM';
export const ENROLL_STUDENT_IN_CURRICULUM = 'ENROLL_STUDENT_IN_CURRICULUM';
export const UPDATE_LESSON_PLAN_FOR_CURRICULUM =
  'UPDATE_LESSON_PLAN_FOR_CURRICULUM';
export const CURRICULUM_ERROR = 'CURRICULUM_ERROR';
export const GET_ENROLLED_STUDENTS_FOR_CURRICULUM =
  'GET_ENROLLED_STUDENTS_FOR_CURRICULUM';

//TOUR

export const GET_TOURS_FOR_INSTRUCTOR = 'GET_TOURS_FOR_INSTRUCTOR';
export const GET_STUDIOS_IN_STATE = 'GET_STUDIOS_IN_STATE';
export const POST_NEW_TOUR = 'POST_NEW_TOUR';
export const TOUR_ERROR = 'TOUR_ERROR';

//INCIDENT REPORT

export const GET_INCIDENT_REPORT = 'GET_INCIDENT_REPORT';
export const GET_ALL_INCIDENT_REPORTS = 'GET_ALL_INCIDENT_REPORTS';
export const SUBMIT_INCIDENT_REPORT = 'SUBMIT_INCIDENT_REPORT';
export const GET_USER_INCIDENT_REPORTS = 'GET_USER_INCIDENT_REPORTS';
export const UPDATE_INCIDENT_REPORT = 'UPDATE_INCIDENT_REPORT';
export const VOTE_ON_INCIDENT_REPORT = 'VOTE_ON_INCIDENT_REPORT';
export const SUSPEND_PERSON = 'SUSPEND_PERSON';
export const GET_SUSPENSION_LIST = 'GET_SUSPENSION_LIST';
export const INCIDENT_REPORT_ERROR = 'INCIDENT_REPORT_ERROR';

//Trivia
export const ADD_TRIVIA_TO_EVENT = 'ADD_TRIVIA_TO_EVENT';
export const POST_NEW_TRIVIA = 'POST_NEW_TRIVIA';
export const GET_ALL_TRIVIA = 'GET_ALL_TRIVIA';
export const GET_TRIVIA_FOR_EVENT = 'GET_TRIVIA_FOR_EVENT';
export const GENERATE_CONNECTIONS = 'GENERATE_CONNECTIONS';
export const TRIVIA_ERROR = 'TRIVIA_ERROR';
