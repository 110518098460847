import React, { Component, useState } from 'react';
// import isEmpty from '../../validation/is-empty';
import { useLocation } from 'react-router-dom';

const CurrentUserProfileHeader = (props) => {
  const [pagePromoted, setPagePromoted] = useState(false);
  const location = useLocation();

  console.log('location.state');
  console.log(location?.state);

  // const { profile } = this.props;
  const { user } = props;
  let image;

  if (user?.image) {
    image = user.image.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }

  console.log('user from CurrentUserProfileHeader');
  console.log(user);
  console.log('done ------');
  console.log('user?.advertisementIds.length');

  console.log(user?.advertisementIds?.length);
  return (
    <div className="row">
      <div
        className="promotionText"
        style={{ backgroundColor: '#00b300', color: 'white' }}
      >
        {location?.state?.messageText ? (
          <div>
            {/* <br /> */}
            <h4>{location.state.messageText}</h4>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* we need to make a note to the user when their ad request is approved.. send email and confimration in app - 7.13.23  */}
      {/* <div className="promotionText">
        {user?.advertisementIds?.length > 0 ? (
          <h4>Your page has been promoted!</h4>
        ) : (
          <></>
        )}
      </div> */}
      <div className="col-md-12">
        <div className="card card-body bg-dark text-white mb-3">
          <div className="row">
            <div className="col-4 col-md-3 m-auto">
              <div className="all-center">
                <img
                  className="rounded-circle"
                  //   src={profile.user.avatar}
                  // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                  src={image}
                  alt=""
                  height="150"
                  width="150"
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            {/* <h1>{user?.name}</h1> */}
            {/* <h1 className="display-4 text-center">{profile.user.name}</h1> */}

            <h3 className="display-12">{user.name}</h3>
            <h5 className="display-12">{user?.location}</h5>
            <h5 className="display-12">{user?.dance_company}</h5>
            <hr />
            {user?.instagram_name ? (
              <h5 style={{ fontSize: '16px' }}>
                {' '}
                Instagram: {user?.instagram_name}
              </h5>
            ) : (
              <></>
            )}
            {user?.tiktok_name ? (
              <h5 style={{ fontSize: '16px' }}> Tiktok: {user?.tiktok_name}</h5>
            ) : (
              <></>
            )}

            {user?.website_name ? (
              <h5 style={{ fontSize: '16px' }}>
                {' '}
                Website: {user?.website_name}
              </h5>
            ) : (
              <></>
            )}

            {user?.website_name || user?.tiktok_name || user?.instagram_name ? (
              <hr />
            ) : (
              <></>
            )}

            <h5 style={{ fontSize: '16px' }}>{user?.about_you}</h5>

            {/* <h5 className="text-left">{user.dance_company}</h5>
              <h5 className="text-left">{user.location}</h5> */}

            {/* <p className="lead text-center">
                {profile.status}{' '}
                {isEmpty(profile.company) ? null : (
                  <span>at {profile.company}</span>
                )}
              </p> */}
            {/* {isEmpty(profile.location) ? null : <p>{profile.location}</p>}
              <p>
                {isEmpty(profile.website) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.website}
                    target="_blank"
                  >
                    <i className="fas fa-globe fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.twitter) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.twitter}
                    target="_blank"
                  >
                    <i className="fab fa-twitter fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.facebook) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.facebook}
                    target="_blank"
                  >
                    <i className="fab fa-facebook fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.linkedin) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.linkedin}
                    target="_blank"
                  >
                    <i className="fab fa-linkedin fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.youtube) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.youtube}
                    target="_blank"
                  >
                    <i className="fab fa-youtube fa-2x" />
                  </a>
                )}

                {isEmpty(profile.social && profile.social.instagram) ? null : (
                  <a
                    className="text-white p-2"
                    href={profile.social.instagram}
                    target="_blank"
                  >
                    <i className="fab fa-instagram fa-2x" />
                  </a>
                )} */}
            {/* </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentUserProfileHeader;
