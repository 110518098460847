import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

const FilterButtons = (props) => {
  const [eventType, setEventType] = useState('all');
  var isAllCheck = false;

  const handlePillClicked = (type) => {
    setEventType(type);
    //query backend for events with this type - salsa, bachata, etc
    props.onDanceFilterChange(type);

    document
      .querySelectorAll('input[type=checkbox]')
      .forEach((el) => (el.checked = isAllCheck));
  };

  //dynamic buttons
  const renderAllButton = () => {
    if (eventType === 'all') {
      return (
        <Button
          onClick={() => handlePillClicked('all')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            // color: 'blue',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
          }}
        >
          all
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('all')}
          className="bg-primary rounded-pill"
          style={{
            width: '100px',
            fontSize: '14px',
            // backgroundColor: 'white',
            // color: 'rgb(1, 126, 252)',
          }}
        >
          all
        </Button>
      );
    }
  };
  const renderSalsaButton = () => {
    if (eventType === 'salsa') {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderColor: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            fontSize: '14px',
          }}
        >
          salsa
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="bg-primary rounded-pill"
          style={{ width: '100px', fontSize: '14px' }}
        >
          salsa
        </Button>
      );
    }
  };
  const renderBachataButton = () => {
    if (eventType === 'bachata') {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderColor: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            fontSize: '14px',
          }}
        >
          bachata
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="bg-primary rounded-pill"
          style={{ width: '100px', fontSize: '14px' }}
        >
          bachata
        </Button>
      );
    }
  };

  const renderKizombaButton = () => {
    if (eventType === 'kizomba') {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderColor: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            fontSize: '14px',
          }}
        >
          kizomba
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="bg-primary rounded-pill"
          style={{ width: '100px', fontSize: '14px' }}
        >
          kizomba
        </Button>
      );
    }
  };

  const renderZoukButton = () => {
    if (eventType === 'zouk') {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderColor: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            fontSize: '14px',
          }}
        >
          zouk
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="bg-primary rounded-pill"
          style={{ width: '100px', fontSize: '14px' }}
        >
          zouk
        </Button>
      );
    }
  };

  return (
    <div className="row">
      {renderAllButton()}
      {renderSalsaButton()}
      {renderBachataButton()}

      {renderKizombaButton()}
      {renderZoukButton()}
    </div>
  );
};

export default FilterButtons;
