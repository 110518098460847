import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import AddSafetyOfficerPopup from './AddSafetyOfficerPopup';

const AddSafetyOfficer = (props) => {
  const mockImage =
    'https://t4.ftcdn.net/jpg/03/59/58/91/360_F_359589186_JDLl8dIWoBNf1iqEkHxhUeeOulx0wOC5.jpg';

  const [addSafetyOfficerSelected, setAddSafetyOfficerSelected] = useState(
    false
  );
  return (
    <div className="card">
      {/* <h5>Add Safety Officer</h5> */}
      <div className="row">
        <div className="col-4">
          {/* <h5>image</h5> */}
          <img
            src={mockImage}
            style={{
              height: '100px',
              width: '100px',
            }}
            alt="user"
          />
        </div>

        <div className="col">
          <div className="row">
            <Button
              onClick={() =>
                setAddSafetyOfficerSelected(!addSafetyOfficerSelected)
              }
              style={{ fontSize: '24px', backgroundColor: 'lightgreen' }}
            >
              Add Safety Officer
            </Button>
          </div>
          <hr />
          <div className="row">
            <h5 style={{ fontSize: '12px' }}>
              Add a safety officer so you can respond to incidents in real time
            </h5>
          </div>
        </div>
      </div>
      {addSafetyOfficerSelected ? (
        <AddSafetyOfficerPopup
          addSafetyOfficerSelected={addSafetyOfficerSelected}
          event_id={props?.event._id}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddSafetyOfficer;
