import React, { Component, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';
import eventContext from '../../context/event/eventContext'; // might have to capitalize
import UserAgenda from './UserAgenda';
import JoinedEventOptionsPopup from './JoinedEventOptionsPopup';
const JoinedEvent = (props) => {
  const { id } = useParams();

  const { joined_event, user } = props;

  const eContext = useContext(eventContext);
  const { getMyItinerary, myItinerary } = eContext;
  const handleAgendaClicked = () => {
    if (id) {
      getMyItinerary(joined_event?._id, id);
    }
    console.log('myItinerary after click is:');

    console.log(myItinerary);
  };

  console.log('joined_event from joinedEvent.js :');
  console.log(joined_event);
  const [optionsClicked, setOptionsClicked] = useState(false);

  return (
    <div>
      <div className="form-container">
        <div className="form-group">
          <div className="container">
            <div className="card bg-light">
              <h4 style={{ fontSize: '14px' }}>{joined_event.name}</h4>
              <div>
                <Link
                  to={`/event/my-events/${props.joined_event._id}`}
                  state={{
                    event: joined_event,
                    user: user,
                    isCurrentUser: props.isCurrentUser,
                    showDeleteButton: props.showDeleteButton,
                  }}
                >
                  <Button variant="btn-info">Event Details</Button>
                </Link>
                <Button variant="primary">Room With Me</Button>
                {/* <Button variant="primary">Share Link</Button> */}
                <div className="col">
                  {/* <Button
                    variant="primary"
                    onClick={() => {
                      handleAgendaClicked();
                      // console.log(
                      //   `trip event id is : ${
                      //     trip.event_id
                      //   } and user id is: ${user._id}`
                      // );
                    }}
                  >
                    Agenda
                  </Button> */}
                  <UserAgenda joined_event={joined_event} id={id} user={user} />
                  <Button onClick={() => setOptionsClicked(!optionsClicked)}>
                    pop up button list
                  </Button>

                  {optionsClicked && (
                    <JoinedEventOptionsPopup
                      event_id={props.joined_event._id}
                      event_name={props.joined_event.name}
                      optionsClicked={optionsClicked}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinedEvent;
