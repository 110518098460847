import React, { Component } from 'react';
import LeaveReviewFields from './LeaveReviewFields';
import PerformanceReviewsForGroupAndEvent from './PerformanceReviewsForGroupAndEvent';
import Review from './Review';

const ReviewWasClickedOnCompanyPage = (props) => {
  console.log('props.previouslyClickedArtist from reviewwasclicked');
  console.log(props.previouslyClickedArtist);

  console.log('props.performer.reviews -----');
  console.log(props.performer.reviews);
  return (
    <div>
      {props.visible && (
        <div>
          {/* <LeaveReviewFields
            performer={props.performer}
            event={props.event}
            key={Math.random()}
          /> */}

          <div class="container-fluid  overflow-auto">
            <div class="d-flex flex-row flex-nowrap">
              {props.performer.reviews.map((review, index) => (
                <div className="row">
                  <div class="card card-block mx-2">
                    <Review review={review} key={index} />
                  </div>
                </div>
              ))}
            </div>

            {/* {props.performance_reviews_for_group_and_event.length > 3 && (
              <h5>scroll right 🙂 {'--->'} </h5>
            )} */}
          </div>

          {/* <PerformanceReviewsForGroupAndEvent
          performance_reviews_for_group_and_event={
            props.performance_reviews_for_group_and_event
          }
        /> */}
          <hr />
        </div>
      )}
    </div>
  );
};
export default ReviewWasClickedOnCompanyPage;
