import React, { useContext, useEffect, Fragment, useState } from 'react';
import ArtistContext from '../../context/artist/artistContext'; // might have to capitalize

import { Link, useLocation, useParams } from 'react-router-dom';
// import { useAuth, clearErrors, login } from '../../context/auth/AuthState';
import { useAuth } from '../../context/auth/AuthState';

import ProfileHeader from './ProfileHeader';
import UpcomingEventsForUser from '../events/UpcomingEventsForUser';
import { Button, Form, Modal } from 'react-bootstrap';

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Calendar from '../artist/Calendar';
import PrivateLessonsWithNoEvent from '../artist/PrivateLessonsWithNoEvent';
import PrivateLessonRequest from '../artist/PrivateLessonRequest';
import PrivateLessonRequestNoEvent from '../artist/PrivateLessonRequestNoEvent';
import OtherArtists from './Artist/OtherArtists';
import PremiumPopoverDetail from '../artist/PremiumPopoverDetail';
import Skills from './Artist/Skills/Skills';
import RankingsContext from '../../context/rankings/rankingsContext';
import RankFields from './rank/RankFields';
import DJRankFields from './rank/DJRankFields';
import BookPrivatePopup from '../instructor/BookPrivatePopup';
import UserEvents from './UserEvents';
import AddNewTour from '../tour/AddNewTour';
import CurrentUserTour from '../user/CurrentUserTour';

// import { Link } from 'react-router-dom';
// import EditUserProfile from './EditUserProfile';

// export default class UserProfile extends Component {
const ArtistProfile = (props) => {
  const rankingsContext = useContext(RankingsContext);
  const {
    getSkillsAndEndorsementsForUser,
    skills_and_endorsements,
    // loading,
  } = rankingsContext;

  let { instructor_id } = useParams(); //this is the id
  console.log(`instructor_id id is ${instructor_id}`);

  const artistContext = useContext(ArtistContext);
  const {
    getArtistById,
    artist,
    sendPrivateLessonRequest,
    privateLessonRequest,
    getPrivateLessonRequestsForArtist,

    privateLessonRequests,
    getPrivateLessonRequestsForArtistAtEvents,
    privateLessonRequestsAtEvents,
    other_artists,
    getOtherArtists,
    rankings,
    artistWorldRanking,
    djRankings,
    djRankDetailsObj,
    getUserEvents,
    user_events,
  } = artistContext;

  // console.log('location.state is:-----------');
  // const location = useLocation();

  // console.log(location.state);

  // const [authState, authDispatch] = useAuth();
  const [authState] = useAuth();

  // const { error, isAuthenticated } = authState;
  const { user } = authState;

  // const { target_user, joined_events, trips } = props;
  // const isCurrentUserProfile = user?._id === target_user._id;
  useEffect(() => {
    getArtistById(instructor_id);
    getPrivateLessonRequestsForArtist(instructor_id);
    getPrivateLessonRequestsForArtistAtEvents(instructor_id);
    getOtherArtists();
    getSkillsAndEndorsementsForUser({ artist_id: instructor_id, user_id: '' });
    // eslint-disable-next-line
  }, []);

  const [selected, setSelected] = React.useState();
  const [updateDate, setUpdateDate] = React.useState();
  const [showSelection, setShowSelection] = React.useState(false);
  const [date, setDate] = React.useState();
  const [privateLessonTime, setPrivateLessonTime] = useState('09:00');

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    lessonTime: privateLessonTime,
    lessonDate: selected,
    event_id: '', // might not be applicable
    // requesting_user_id: '123',

    requestor_name: user?.name,
    requestor_id: user?._id,
    requestor_phone_number: user?.phoneNumber,
    requested_artist_id: instructor_id,
    status: 'TBD',
    comments_or_special_requests: 'none',

    // location: 'Charlotte, NC',
  });
  const { lessonDate } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const setFunction = (selected) => {
    console.log('selected fropm func');
    console.log(selected);
    // setDetails({ ...details, lessonDate: selected });
  };
  let detailsNotUpdated = false;

  const updatedDate = (selected, hasBeenUpdated) => {
    // detailsNotUpdated = true;
    if (!hasBeenUpdated) {
      console.log('selected from sr');
      console.log(selected);
      setDetails({ ...details, lessonDate: selected });
      // hasBeenUpdated = ;
    }
    return false;
  };
  let footer = <p>Please pick a day.</p>;
  // if (selected && updatedDate(selected, false)) {
  if (selected) {
    // setDate('today');
    footer = <p>You picked {format(selected, 'PP')}.</p>;
    // console.log('dud ut');
    // if (notUpdated) {
    //   setFunction(selected);
    //   notUpdated = false;
    // }

    // setDate(selected);
    // setShowSelection(true);
    // setSelected(false);
  }
  if (updateDate) {
    setDetails({
      ...details,
      lessonDate: selected,
      requestor_id: user?._id,
      requestor_name: user?.name,
      requestor_phone_number: user?.phoneNumber,
    });
    setUpdateDate(false);
  }

  if (showSelection) {
    // footer = <p>You picked {format(selected, 'PP')}.</p>;
    footer = <p>You picked {format(lessonDate, 'PP')}.</p>;

    // footer = <p>You picked {format(lessonDate}.</p>;
  }
  const [calendarSelected, setCalendarSelected] = React.useState(false);
  const [bookPrivateSelected, setBookPrivateSelected] = React.useState(false);
  const handleTimeChange = (time) => {
    console.log('changed time');
    console.log(time);
    setPrivateLessonTime(time);
    setDetails({ ...details, lessonTime: time });

    // setDetails({ ...details, ['time']: time });

    // let date_time = workshopDate + ' ' + time;
    // filterRoomsByDateAndTime(date_time);
  };

  let times_array = [
    '05:00',
    '06:00',
    '07:00',
    '08:00',
    '09:00',
    '09:30',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '13:30',
    '14:00',
    '14:00',
    '15:00',
    '16:00',
    '16:30',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    // setDetails({ ...details, lessonDate: selected });

    console.log('user is');
    console.log(user);
    sendPrivateLessonRequest(details);
    console.log('details');
    console.log(details);
    // if (details === '') {
    //   //   setAlert('Please fill in all fields', 'danger');
    // } else {
    //   const data = new FormData();
    //   data.append('file', file);
    //   data.append('title', blog_title);
    //   data.append('text', text);
    //   data.append('blog_image', blog_image);

    //   postBlogPost(data);
    // }
  };

  const setDatePlease = (selected) => {
    // setDate(selected)
    console.log('truing to set date from setDateplease');
    console.log(selected);
    setDetails({ ...details, lessonDate: selected });
  };

  const [artistOptionsPopupSelected, setArtistOptionsPopupSelected] = useState(
    false
  );
  const handleClose = () => {
    setArtistOptionsPopupSelected(!artistOptionsPopupSelected);
  };

  const popupArtistOptions = () => {
    setArtistOptionsPopupSelected(!artistOptionsPopupSelected);
  };

  // const routeBasedOnLoggedInStatus = (instructor_id) => {
  //   if (user) {
  //     return `/verify-and-claim-artist/${instructor_id}`;
  //   } else {
  //     return `/register-and-claim-artist/${instructor_id}`;
  //   }
  // };

  const updateSelectedArtist = (artist_id) => {
    getArtistById(artist_id).then(() => {
      window.location.reload();
    });
  };
  const location = useLocation();

  const [showOtherRank, setShowOtherRank] = useState(false);
  const [privateLessonRequestSent, setPrivateLessonRequestSent] = useState(
    false
  );
  const setPrivateLessonRequestSentFromChild = (privateLessonRequestSent) => {
    setPrivateLessonRequestSent(privateLessonRequestSent);
    // setBookPrivateWasClicked(!bookPrivateWasClicked);
    setBookPrivateSelected(false);
    console.log('privateLessonRequestSent from parent');
    console.log(privateLessonRequestSent);
  };
  return (
    <div>
      {privateLessonRequestSent && (
        <div className="row">
          <Button
            style={{ color: 'white', backgroundColor: '#53cf00' }}
            className="all-center"
          >
            private lesson request sent !
          </Button>
        </div>
      )}
      <div>
        {location?.state?.messageText ? (
          <div
            className="promotionText all-center"
            style={{ backgroundColor: '#00b300', color: 'white' }}
          >
            {/* <br /> */}
            <h4>{location.state.messageText}</h4>
          </div>
        ) : (
          <></>
        )}
        <br />
      </div>
      {/* <ProfileHeader
        isCurrentUser={isCurrentUserProfile}
        current_user_id={user?._id}
        user={target_user}
      />
      <UpcomingEventsForUser
        isCurrentUser={isCurrentUserProfile}
        joined_events={joined_events}
        trips={trips}
      /> */}

      <div>
        <div className="row">
          <div className="col-md-6">
            {/* <Link to="/profiles" className="btn btn-light mb-3 float-left">
              Back To Profiles
            </Link>
            <Link to="/profiles" className="btn btn-light mb-3 float-left">
              Edit Profile
            </Link> */}
            {/* <EditUserProfile /> */}
          </div>

          <div className="col-md-6" />
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card card-body bg3-primary text-white mb-3">
              <div className="row text-center">
                {!artist.claimed ? (
                  <div className="row">
                    <Button
                      onClick={() => {
                        popupArtistOptions();
                      }}
                      style={{
                        width: 'max-content',
                        alignContent: 'center',
                        backgroundColor: 'white',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: 'black',
                        padding: 'inherit',
                        color: 'black',
                      }}
                      className="all-center"
                    >
                      Claim
                    </Button>
                  </div>
                ) : (
                  <></>
                )}

                {artistOptionsPopupSelected ? (
                  <div>
                    {' '}
                    <Modal
                      show={artistOptionsPopupSelected}
                      onHide={handleClose}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{props.event_name}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            {/* <Form.Label>popup</Form.Label> */}
                            <h5 style={{ color: 'black' }}>
                              If this profile belongs to you, click here to{' '}
                              <Link
                                to={`/register-and-claim-artist/${instructor_id}`}
                                state={{ instructor_id: instructor_id }}
                              >
                                <span
                                  style={{
                                    color: 'rgb(1, 126, 252)',
                                    fontSize: '18px',
                                  }}
                                >
                                  claim your page
                                </span>
                              </Link>{' '}
                            </h5>
                            <hr />
                            <h5>
                              {' '}
                              <span style={{ color: 'red', fontSize: '20px' }}>
                                Note:{' '}
                              </span>
                              this will not give you premium artist tooling,
                              such as:
                            </h5>
                            <h5>
                              ✅ Booking private lessons directly from event
                              pages
                            </h5>
                            <h5>
                              {' '}
                              ✅ Listing your workshops on the event page, even
                              if the event is not premium
                            </h5>

                            <h5>
                              To Access Premium go here{' '}
                              <Link to={`/go-pro-artist/${artist?._id}`}>
                                <button
                                  // className="rounded-pill"
                                  style={{
                                    width: '180px',
                                    background: 'white',

                                    borderStyle: 'solid',
                                    borderColor: 'rgb(1, 126, 252)',
                                    borderWidth: '1px',
                                    borderRadius: '2px',

                                    fontSize: '14px',
                                    backgroundColor: 'white',
                                    color: 'rgb(1, 126, 252)',
                                    textAlign: 'center',
                                  }}
                                >
                                  Access Premium Artist 🚀
                                </button>
                              </Link>
                            </h5>
                          </Form.Group>
                        </Form>
                      </Modal.Body>
                    </Modal>
                  </div>
                ) : (
                  <></>
                )}

                {artist.claimed ? (
                  <div>
                    <h5
                      style={{
                        float: 'right',
                        width: '60px',
                        color: '#00b300',

                        background: 'white',

                        borderStyle: 'solid',
                        borderColor: '#00b300',
                        borderWidth: '1px',
                        borderRadius: '6px',

                        fontSize: '14px',
                        backgroundColor: 'white',

                        textAlign: 'center',
                      }}
                    >
                      verified
                    </h5>
                  </div>
                ) : (
                  <></>
                )}

                {/* <div className="col col-md-3 m-auto"> */}

                {/* rankings go here  */}
                {artist?.artist_is_also_a_dj && !showOtherRank ? (
                  <DJRankFields
                    artist={artist}
                    artistWorldRanking={djRankDetailsObj}
                    rankings={djRankings}
                    setShowOtherRank={setShowOtherRank}
                    showOtherRank={showOtherRank}
                    // setRankingsToShow = {setRankingsToShow}
                  />
                ) : (
                  <div>
                    <RankFields
                      artist={artist}
                      artistWorldRanking={artistWorldRanking}
                      rankings={rankings}
                      setShowOtherRank={setShowOtherRank}
                      showOtherRank={showOtherRank}
                      // setRankingsToShow = {setRankingsToShow}
                    />
                  </div>
                )}

                <div className="row" style={{ marginTop: '14px' }}>
                  <div className="col">
                    <Button
                      onClick={() => {
                        setBookPrivateSelected(!bookPrivateSelected);
                        if (calendarSelected) {
                          setCalendarSelected(false);
                        }
                        console.log(`setbook is ${bookPrivateSelected}`);
                      }}
                      style={{
                        background: 'white',
                        // color: 'blue',
                        borderStyle: 'solid',
                        // borderColor: 'rgb(1, 126, 252)',
                        width: 'max-content',

                        fontSize: '12px',
                        backgroundColor: 'white',
                        color: 'rgb(1, 126, 252)',
                        textAlign: 'center',
                        padding: '6px',
                        border: '0.5px solid rgb(1, 126, 252)',
                      }}
                    >
                      {bookPrivateSelected ? 'Hide' : 'Book Private 👍🏽'}
                    </Button>
                  </div>

                  {bookPrivateSelected && (
                    <div>
                      <BookPrivatePopup
                        bookPrivateSelected={bookPrivateSelected}
                        setBookPrivateSelected={setBookPrivateSelected}
                        instructor_id={instructor_id}
                        // dancer={user}
                        dancer={artist}
                        onSendPrivateLessonRequest={
                          setPrivateLessonRequestSentFromChild
                        }
                      />
                    </div>
                  )}

                  {/* getting rid of the calendar on the public ArtistProfile page bcuz its showing the privte lesson requests which should only
                     be available to that instructor.. we can add a gif to demo it to the public and entice instr. to go pro */}
                  {/* <div className="col">
                    <Button
                      onClick={() => {
                        setCalendarSelected(!calendarSelected);
                        if (bookPrivateSelected) {
                          setBookPrivateSelected(false);
                        }
                      }}
                    >
                      {calendarSelected ? 'Hide' : 'Calendar'}
                    </Button>
                  </div> */}

                  <div className="col" style={{ paddingBottom: '10px' }}>
                    {artist.premium_subscription === true ? (
                      <div className="col">
                        <PremiumPopoverDetail />
                      </div>
                    ) : (
                      <div className="col">
                        <Link to={`/go-pro-artist/${artist?._id}`}>
                          <button
                            // className="rounded-pill"
                            style={{
                              width: '180px',
                              background: 'white',

                              borderStyle: 'solid',
                              borderColor: 'rgb(1, 126, 252)',
                              borderWidth: '1px',
                              borderRadius: '2px',

                              fontSize: '14px',
                              backgroundColor: 'white',
                              color: 'rgb(1, 126, 252)',
                              textAlign: 'center',
                            }}
                          >
                            Access Premium Artist 🚀
                          </button>
                        </Link>
                      </div>
                    )}
                  </div>

                  {/* <div className="col">
                    <Button>Book Private</Button>
                  </div>
                  <div className="col">
                    <Button>Book Private</Button>
                  </div> */}
                </div>

                {/* <h3 className="display-4 text-center">{user.name}</h3>

                <h4>{user?.location}</h4>
                <Link to={`/my-connections`} state={{ target_user: user }}>
                  {user.connections?.length} Connections
                </Link>
                <div className="row">
                
                  {connected()}
                  
                  <div className="col">
                    
                    {renderOptions()}
                  </div>
                  {user?.instagram_name ? (
                    <h5 style={{ fontSize: '16px' }}>
                      {' '}
                      Instagram: {user?.instagram_name}
                    </h5>
                  ) : (
                    <></>
                  )}
                  {user?.tiktok_name ? (
                    <h5 style={{ fontSize: '16px' }}>
                      {' '}
                      Tiktok: {user?.tiktok_name}
                    </h5>
                  ) : (
                    <></>
                  )}

                  {user?.website_name ? (
                    <h5 style={{ fontSize: '16px' }}>
                      {' '}
                      Website: {user?.website_name}
                    </h5>
                  ) : (
                    <></>
                  )}

                  {user?.website_name ||
                  user?.tiktok_name ||
                  user?.instagram_name ? (
                    <hr />
                  ) : (
                    <></>
                  )}
                  <h5 style={{ fontSize: '16px' }}>{user?.about_you}</h5>
                </div> */}
              </div>

              <div className="text-center" />
            </div>

            {/* {bookPrivateSelected && (
              <div style={{ justifyContent: 'center' }}>
                <DayPicker
                  mode="single"
                  selected={selected}
                  onSelect={setSelected}
                  footer={footer}
                  onDayClick={() => {
                    // if(stateUpdated()){

                    setSelected(selected);
                    setDatePlease(selected);
                    console.log('hello');
                    console.log('selected');
                    console.log(selected);
                    setDetails({ ...details, lessonDate: selected });
                    setUpdateDate(true);
                    // }
                  }}
                />

                <div className="row">
                  <div className="col">
                    <label>Workshop Time</label>
                  </div>

                  <div className="col">
                    {[DropdownButton].map((DropdownType, idx) => (
                      <DropdownType
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop-${idx}`}
                        size="sm"
                        variant="secondary"
                        title={privateLessonTime}
                        // title="10:40"
                        onSelect={handleTimeChange}
                        // onChange={onChange}
                      >
                        {times_array.map((time) => (
                          <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>
                        ))}
                      </DropdownType>
                    ))}
                  </div>

                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <input
                        type="submit"
                        value={
                          user ? 'Send Lesson Request' : 'Sign in to request'
                        }
                        class="btn btn-secondary"
                        disabled={user ? false : true}
                      />
                    </div>
                  </form>
                </div>
              </div>
            )} */}

            {user_events && user_events.length > 0 ? (
              <div>
                <h5>Upcoming Events</h5>
                <div
                  class="container-fluid  overflow-auto example"
                  style={{ padding: '0px' }}
                >
                  <div class="d-flex flex-row flex-nowrap">
                    {user_events?.map((event) => (
                      <div className="card-horizontal-menu card-block mx-2">
                        <UserEvents event={event} />
                        {/* <h5>{event._id}</h5> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* we will need to use artist_type here and also need to update
our backend artist/user models to be able to have more than one dance type */}
            {!bookPrivateSelected &&
              !calendarSelected && (
                <div>
                  <Skills
                    artist_id={artist._id}
                    dancer_types={[artist.artist_type]}
                    skills_and_endorsements={skills_and_endorsements}
                  />
                </div>
              )}

            {!bookPrivateSelected &&
              !calendarSelected && (
                <div style={{ marginTop: '12px' }}>
                  <div>
                    <hr />
                    <h3>Other Artists</h3>
                  </div>
                  <div className="col grid-container">
                    {/* <div className="col grid-container"> */}
                    {other_artists.map((artist) => (
                      <div>
                        <OtherArtists
                          artist={artist}
                          updateSelectedArtist={updateSelectedArtist}
                        />
                      </div>
                    ))}
                  </div>
                  <Link to="/artist-directory">
                    <Button
                      style={{
                        background: 'white',
                        color: 'rgb(1, 126, 252)',
                        borderStyle: 'solid',
                        borderColor: 'rgb(1, 126, 252)',
                      }}
                    >
                      see all
                    </Button>
                  </Link>
                </div>
              )}
          </div>
        </div>
        <div />

        <div>
          {calendarSelected && (
            <div>
              <h5>calendar</h5>
              <Calendar
                privateLessonRequestsAtEvents={privateLessonRequestsAtEvents}
              />
            </div>
          )}
        </div>

        {/* 
        
        */}
      </div>

      {/* getting rid of the calendar on the public ArtistProfile page bcuz its showing the privte lesson requests which should only
                     be available to that instructor.. we can add a gif to demo it to the public and entice instr. to go pro -- jan 28,2024 */}
      {/* {!bookPrivateSelected &&
        !calendarSelected && (
          <div>
            <h5>
              {privateLessonRequests.length > 0
                ? `Private Lesson Requests`
                : `Private Lesson Requests Will Be Shown Here`}
            </h5>
            {privateLessonRequests.map((lesson) => (
              <PrivateLessonRequestNoEvent
                key={lesson._id}
                lesson={lesson}
                user={user}
                artist={artist}
              />
              // {lesson.requested_artist_id}
            ))}
          </div>
        )} */}
      <div className="row">
        <div className="col-7">
          <h1>Upcoming Tours</h1>
        </div>
        {user?.email === 'airmeus.team@gmail.com' ? (
          <div className="col">
            <AddNewTour
              instructor_id={instructor_id}
              instructor_name={artist?.name}
            />
            {/* <Button onClick={()=>{setAddNewTourClicked(!addNewTourClicked)}}>Add Tour</Button> */}
          </div>
        ) : (
          <></>
        )}
      </div>
      <CurrentUserTour instructor_id={instructor_id} />
    </div>
  );
};
export default ArtistProfile;

/*


  <ProfileHeader profile={profile} /> */
/* <ProfileAbout profile={profile} />
        <ProfileCreds
          education={profile.education}
          experience={profile.experience}
        />
        {profile.githubusername ? (
          <ProfileGithub username={profile.githubusername} />
        ) : null}

*/
