import React, { Component, useContext, useEffect } from 'react';
import StudioContext from '../../../context/studio/studioContext';
import { Link } from 'react-router-dom';

const RandomStudios = (props) => {
  const studioContext = useContext(StudioContext);
  const {
    get4RandomStudiosForTimeline,
    random_studios_for_timeline,
  } = studioContext;

  useEffect(() => {
    get4RandomStudiosForTimeline();

    // console.log(user);

    // eslint-disable-next-line
  }, []);
  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const getStudioInitials = (studio_name) => {
    console.log('trying get studio inits... ');
    let nameArray = studio_name.split(' ');
    console.log('nameArray -----');
    console.log(nameArray);
    let initials = '';
    for (let i = 0; i < nameArray.length; i++) {
      initials = initials + nameArray[i].charAt(0).toUpperCase();
    }
    console.log('initials --');
    console.log(initials);
    return initials;
  };

  const returnRandomColor = () => {
    var colorArray = [
      '#FF6633',
      '#FFB399',
      '#FF33FF',
      '#FFFF99',
      '#00B3E6',
      '#E6B333',
      '#3366E6',
      '#999966',
      '#99FF99',
      '#B34D4D',
      '#80B300',
      '#809900',
      '#E6B3B3',
      '#6680B3',
      '#66991A',
      '#FF99E6',
      '#CCFF1A',
      '#FF1A66',
      '#E6331A',
      '#33FFCC',
      '#66994D',
      '#B366CC',
      '#4D8000',
      '#B33300',
      '#CC80CC',
      '#66664D',
      '#991AFF',
      '#E666FF',
      '#4DB3FF',
      '#1AB399',
      '#E666B3',
      '#33991A',
      '#CC9999',
      '#B3B31A',
      '#00E680',
      '#4D8066',
      '#809980',
      '#E6FF80',
      '#1AFF33',
      '#999933',
      '#FF3380',
      '#CCCC00',
      '#66E64D',
      '#4D80CC',
      '#9900B3',
      '#E64D66',
      '#4DB380',
      '#FF4D4D',
      '#99E6E6',
      '#6666FF',
    ];
    let index = Math.floor(Math.random() * colorArray.length);
    console.log(index);
    return colorArray[index];
  };
  return (
    <div>
      <h5 style={{ fontSize: '24px' }}>Explore Studios</h5>
      {random_studios_for_timeline.map((studio) => (
        <div className="col grid-container-small-screen grid-container-big-screen-main-timeline">
          <div className="" style={{ width: '170px' }}>
            <Link to={`/studio/${studio?._id}`} style={{ color: 'black' }}>
              <div>
                <div className="imageRankcontainer">
                  {/* <div
                className="bottomright"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  borderRadius: '5px',
                  margin: '2px',
                  padding: '3px',
                }}
              >
                <h5
                  style={{
                    color: 'white',

                    marginBottom: '0px',
                  }}
                >
                  {studio?.rank}
                </h5>
              </div> */}

                  {/* <img
                    // src={studio?.image_url ? studio?.image_url.url : studio?.image}
                    src={studio?.image ? studio?.image.url : imgg}
                    height="150"
                    width="150"
                    alt="studio"
                    style={{ borderRadius: '0.25rem' }}
                  /> */}

                  {studio?.image || studio.image_url ? (
                    <img
                      //  src={studio?.image_url ? studio?.image_url.url : studio?.image}
                      src={studio?.image ? studio?.image.url : imgg}
                      height="150"
                      width="150"
                      alt="studio"
                      style={{ borderRadius: '0.25rem' }}
                    />
                  ) : (
                    <div
                      className="square"
                      style={{ backgroundColor: returnRandomColor() }}
                    >
                      <h5
                        style={{
                          paddingTop: '60px',
                          fontSize: 30,
                        }}
                      >
                        {getStudioInitials(studio?.name)}
                      </h5>
                    </div>
                  )}
                </div>
                {/* <h5>{studio.name}</h5> */}
                {/* <Link to={`/studio/${studio?._id}`} style={{ color: 'black' }}> */}
                <h3>{studio?.name}</h3>
              </div>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};
export default RandomStudios;
