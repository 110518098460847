import React from 'react';
import User from '../user/User';
import Event from '../events/Event';
import EventWithPagination from '../events/EventWithPagination';
import { LeftSidePanel } from '../side-panel/LeftSidePanel';
import { BlogSidePanel } from '../side-panel/BlogSidePanel';
import AdPanel from '../side-panel/AdPanel';
import TopStudioLeftSidePanel from '../side-panel/TopStudioLeftSidePanel';
import { Link } from 'react-router-dom';
import AutoSlidingAdBanner from './AutoSlidingAdBanner';
import HappeningThisWeekPanel from '../side-panel/HappeningThisWeekPanel';
import { useLocation } from 'react-router-dom';
import Navbar from '../layout/Navbar';

const Home = (props) => {
  const location = useLocation();
  console.log(location?.state);

  return (
    <div>
      <div style={{ padding: '0px' }}>
        <Navbar />
      </div>

      <div className="container">
        {/* <AutoSlidingAdBanner /> */}

        {/* <Event /> */}
        {/* <LeftSidePanel /> */}
        <TopStudioLeftSidePanel />
        {/* <BlogSidePanel /> */}
        <AdPanel />
        <HappeningThisWeekPanel />

        <div
          className="promotionText"
          style={{ backgroundColor: 'rgb(1, 126, 252)', color: 'white' }}
        >
          {location?.state?.messageText ? (
            <div>
              {/* <br /> */}
              <h4>{location.state.messageText}</h4>
            </div>
          ) : (
            <></>
          )}
        </div>

        <EventWithPagination />
      </div>
    </div>
  );
};

export default Home;
{
  /* <User /> */
}
