//consider renaming to potential_mate to differentiate btw user
import React, { useContext, useEffect, Fragment, useState } from 'react';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import SubEventContext from '../../context/subevent/subEventContext'; // might have to capitalize
import AdvertismentContext from '../../context/advertisement/advertismentContext';
// import AdvertisementContext from '../../context/advertisement/advertisementContext'; // might have to capitalize

import { Button } from 'react-bootstrap';
// import EventItem from './EventItem';
import PropTypes from 'prop-types';

import AlertContext from '../../context/alert/alertContext';
import { useAuth } from '../../context/auth/AuthState';
import Activity from './Activity';
import SubEvent from '../events/SubEvent';
// import { Navigate } from 'react-router-dom';
// import Pagination from './Pagination';
// import ReactPaginate from 'react-paginate';
// import { Link } from 'react-router-dom';
// import Form from 'react-bootstrap/Form';
// import ListOfStates from './ListOfStates.json';
// import ListOfStatesAndCities from './ListOfStatesAndCities.json';
// import TopDancerOnMainTimeline from '../topLists/TopDancerOnMainTimeline';

const ActivitiesWithPagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [authState] = useAuth();
  const [loading, setLoading] = useState(false);

  const { user } = authState;
  // const eventContext = useContext(EventContext);
  // const { getAllEvents, events, createNewEvent } = eventContext;

  const subEventContext = useContext(SubEventContext);
  const { getAllActivities, activities } = subEventContext;

  // const adContext = useContext(AdvertismentContext);
  // const { getAllAds, advertisements } = adContext;

  useEffect(() => {
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    setLoading(true);
    getAllActivities();
    setLoading(false);
    console.log('activities');
    console.log(activities);
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {activities.map((activity) => (
        // <h1>{activity.sub_event_name}</h1>

        // <Activity activity={activity} />
        <SubEvent subevent={activity} />
      ))}
    </div>
  );
};

Event.propTypes = {};

export default ActivitiesWithPagination;
