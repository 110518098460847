import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';
import StudioContext from '../../context/studio/studioContext';
import ClassPopup from './classes/ClassPopup';

const Level = (props) => {
  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;

  const studioContext = useContext(StudioContext);
  const { addNewLevel } = studioContext;

  //   const { user } = location.state;

  const { level } = props;
  const { studio_id } = useParams();

  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const [skills, setSkills] = useState({ levelName: '', listOfSkills: '' });
  const { levelName, listOfSkills } = skills;

  const onChange = (e) => {
    console.log(e.target.value);
    console.log(e.target);
    setSkills({ ...skills, [e.target.name]: [e.target.value] });
  };

  const [skillsVisible, setSkillsVisible] = React.useState(false);

  return (
    <div style={{ paddingBottom: '14px' }}>
      <Button
        onClick={() => setSkillsVisible(!skillsVisible)}
        style={{
          width: '170px',
          borderStyle: 'solid',
          borderColor: 'black',
          fontWeight: 'bold',
          backgroundColor: 'white',
          // backgroundColor: skillsVisible ? 'rgb(1, 126, 252)' : 'white',
          color: 'black',
        }}
      >
        {/* {!skillsVisible && ( */}
        <div>
          <div className="row">
            <h5
              style={{
                fontSize: '16px',
              }}
            >
              {level?.class_name}
            </h5>
          </div>

          <div className="row">
            <h5
              style={{
                fontSize: '16px',
              }}
            >
              {level?.style}
            </h5>
          </div>
        </div>

        {/* {skillsVisible ? 'Hide' : level.style + ' ' + level?.class_name} */}
      </Button>
      {/* {skillsVisible &&
        level?.skills.map((skill, index) => (
          <div className="row">{`${index + 1} ${skill}`}</div>
        ))} */}
      {skillsVisible && (
        <ClassPopup studioClass={level} showClass={skillsVisible} />
      )}
    </div>
  );
};

export default Level;
