import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import User from './User';

const ConnectionItem = (props) => {
  let { user } = props;

  let image;
  if (user?.image) {
    image = user?.image?.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }
  return (
    <div>
      {/* <h1>hello</h1> */}
      {/* <h1>{user.name}</h1> */}

      <div className="card card-body bg-light mb-3">
        <div className="row">
          <div className="col-3">
            {/* <div> */}
            <img
              src={image}
              alt=""
              className="rounded-circle"
              width="50"
              height="50"
            />
            {/* </div> */}
          </div>
          <div className="col">
            <h3>{user?.name}</h3>
            {/* <p>
              <span>Rumbao</span>
            </p> */}
            {/* <p>
            {isEmpty(target_user.location) ? null : <span>{target_user.location}</span>}
          </p> */}
            <p>{/* <span>{target_user.location}</span> */}</p>

            {/* <button
              onClick={connectToUserFunction}
              class="btn btn-primary my-2 my-sm-0"
              type="submit"
            >
              Add Connection
            </button> */}
          </div>
          <Link to={`/user/profile/${user?._id}`} className="btn btn-info">
            View Profile
          </Link>
          {/* <div className="col-md-4 d-none d-md-block">
          <h4>Skill Set</h4>
          <ul className="list-group">
            {profile.skills.slice(0, 4).map((skill, index) => (
              <li key={index} className="list-group-item">
                <i className="fa fa-check pr-1" />
                {skill}
              </li>
            ))}
          </ul>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default ConnectionItem;
