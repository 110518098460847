/* eslint-disable import/no-anonymous-default-export */
// import React from 'react';
// import PropTypes from 'prop-types';

import {
  GET_REVIEWS_FOR_GROUP_FOR_EVENT,
  SUBMIT_PERFORMANCE_REVIEW,
  GET_PERFORMERS_FOR_EVENT,
  PERFORMANCE_REVIEW_ERROR,
  GET_ALL_REVIEWS_FOR_EVENT,
  ADD_PERFORMERS,
  ADD_COMPANY_PERFORMERS,
  GET_ALL_COMPANY_REVIEWS_FOR_EVENT,
  SUBMIT_COMPANY_PERFORMANCE_REVIEW,
  GET_REVIEWS_FOR_COMPANY_FOR_EVENT,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_REVIEWS_FOR_GROUP_FOR_EVENT:
      return {
        ...state,
        performance_reviews_for_group_and_event: action.payload,

        // loading: false,
      };
    case GET_REVIEWS_FOR_COMPANY_FOR_EVENT:
      return {
        ...state,
        performance_reviews_for_company_and_event: action.payload,

        // loading: false,
      };
    case GET_ALL_REVIEWS_FOR_EVENT:
      return {
        ...state,
        all_performance_reviews_for_event: action.payload,

        // loading: false,
      };
    case GET_ALL_COMPANY_REVIEWS_FOR_EVENT:
      return {
        ...state,
        all_company_performance_reviews_for_event: action.payload,

        // loading: false,
      };
    case SUBMIT_PERFORMANCE_REVIEW:
      return {
        ...state,
        submitted_performance_review: action.payload,

        // loading: false,
      };
    case SUBMIT_COMPANY_PERFORMANCE_REVIEW:
      return {
        ...state,
        submitted_company_performance_review: action.payload,

        // loading: false,
      };
    case GET_PERFORMERS_FOR_EVENT:
      return {
        ...state,
        performersForEvent: action.payload,

        // loading: false,
      };
    case ADD_PERFORMERS:
      return {
        ...state,
        added_performers: action.payload,

        // loading: false,
      };
    case ADD_COMPANY_PERFORMERS:
      return {
        ...state,
        added_company_performers: action.payload,

        // loading: false,
      };

    case PERFORMANCE_REVIEW_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
