/* eslint-disable import/no-anonymous-default-export */
import {
  GET_ALL_ADS,
  GET_BANNER_ADS,
  AD_ERROR,
  GET_MAIN_AD,
  GET_SIDE_AD,
  CREATE_AD,
  CREATE_AD_REQUEST,
  CREATE_GENERAL_AD_REQUEST,
  GET_AD_REQUESTS,
  GET_BANNER_AD_REQUESTS,
  GET_EVENTS_THIS_WEEK_AD_REQUESTS,
  DENY_AD_REQUEST,
  APPROVE_BANNER_AD_REQUEST,
  APPROVE_ETW_AD_REQUEST,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case CREATE_AD_REQUEST:
      return {
        ...state,
        adRequest: action.payload,
      };
    case CREATE_GENERAL_AD_REQUEST:
      return {
        ...state,
        generalAdRequest: action.payload,
      };
    case CREATE_AD:
      return {
        ...state,
        new_advertisement: action.payload,
      };
    case GET_MAIN_AD:
      return {
        ...state,
        main_advertisement: action.payload,
      };
    case GET_SIDE_AD:
      return {
        ...state,
        side_advertisement: action.payload,
      };
    case GET_ALL_ADS:
      return {
        ...state,
        advertisements: action.payload,
      };
    case GET_BANNER_ADS:
      return {
        ...state,
        bannerAdvertisements: action.payload,
      };
    case GET_AD_REQUESTS:
      return {
        ...state,
        allAdRequests: action.payload,
      };
    case GET_BANNER_AD_REQUESTS:
      return {
        ...state,
        bannerAdRequests: action.payload,
      };
    case GET_EVENTS_THIS_WEEK_AD_REQUESTS:
      return {
        ...state,
        eventsThisWeekAdRequests: action.payload,
      };
    case DENY_AD_REQUEST:
      return {
        ...state,
      };
    case APPROVE_BANNER_AD_REQUEST:
      //remove banner ad by filtering out id

      return {
        ...state,
        bannerAdRequests: state.bannerAdRequests.filter(
          (bannerAdRequest) => bannerAdRequest._id !== action.payload
        ),
      };
    case APPROVE_ETW_AD_REQUEST:
      //remove etw ad by filtering out id

      return {
        ...state,
        etw_AdRequests: state.etw_AdRequests.filter(
          (etw_AdRequest) => etw_AdRequest._id !== action.payload
        ),
      };

    case AD_ERROR:
      return {
        ...state,
        advertisement_error: action.payload,
      };

    default:
      return state;
  }
};
