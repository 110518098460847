import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
import StudioContext from '../../context/studio/studioContext';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const ShowStudiosByRegion = () => {
  const studioContext = useContext(StudioContext);
  const { getStudiosByRegion, studiosByRegion } = studioContext;

  useEffect(() => {
    getStudiosByRegion();
    console.log('studiosByRegion --');
    console.log(studiosByRegion);
    // eslint-disable-next-line
  }, []);
  const linkStyle = {
    margin: '1rem',
    textDecoration: 'none',
    color: 'blue',
  };
  let defaultImage =
    'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  return (
    <div>
      {studiosByRegion.map((region, index) => (
        <div>
          <h5 style={{ color: '#333' }}>{region.region}</h5>
          {region.studiosInRegion.map((studio) => (
            <div style={{ paddingLeft: '10px' }}>
              <Link to={`/studio/${studio._id}`} style={{ linkStyle }}>
                {/* <Button className="btn-clickable-with-hover:hover"> */}
                <div className="row">
                  <div className="col-2">
                    <img
                      className="rounded-circle"
                      src={studio.image ? studio.image.url : defaultImage}
                      alt=""
                      height="30"
                      width="30"
                    />
                  </div>

                  <div className="col">
                    {/* <Link to={`/studio/${studio._id}`}> */}
                    <h5 style={{ color: '#595959c0' }}>{studio.name}</h5>
                    {/* </Link> */}
                  </div>
                </div>
                {/* <div className="row">
                  <h5 style={{ fontSize: '10px' }}>{`${studio.city}, ${
                    studio.state
                  }`}</h5>
                </div> */}
                {/* <div className="row">
                <h5>{studio.name}</h5>
              </div> */}
                {/* </Button> */}
              </Link>
            </div>
          ))}
          <hr />
        </div>
      ))}
    </div>
  );
};

export default ShowStudiosByRegion;
