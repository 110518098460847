import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import EventDetail from './components/events/EventDetail';
import MyEventsDetail from './components/events/MyEventsDetail';
import MyTripDetails from './components/events/MyTripDetails';

import './App.css';
import UserState from './context/user/UserState';
import EventState from './context/event/EventState';
import SubEventState from './context/subevent/SubEventState';
import AuthState from './context/auth/AuthState';
import AlertState from './context/alert/AlertState';

import Events from './components/events/Event';
import UpcomingEvents from './components/events/UpcomingEvents';
import UpcomingEventsNew from './components/user/UpcomingEventsNew';

import Register from './components/auth/Register';
import Login from './components/auth/Login';
// import UserProfile from './components/user/UserProfile';
import CurrentUserProfileContainer from './components/pages/CurrentUserProfile';
import UserProfileContainer from './components/pages/UserProfileContainer';

import SearchResultsPage from './components/search/SearchResultsPage';

import MyConnections from './components/user/MyConnections';
import AllUsers from './components/user/AllUsers';
import setAuthToken from './utils/setAuthToken';
import Advertise from './components/advertise/Advertise';
import AdvertismentState from './context/advertisement/AdvertismentState';
import StripeCheckout from './components/payment/StripeCheckout';
import AdvertiseNoRedirect from './components/advertise/AdvertiseNoRedirect';
import Sharktank from './components/sharktank/Sharktank';
import NotificationState from './context/notification/NotificationState';
import MyNotifications from './components/notifications/MyNotifications';
import { MuiBottomNavigation } from './components/MuiBottomNavigation';
import Box from '@material-ui/core/Box';
import TopListState from './context/topList/TopListState';
import TopDancer from './components/topLists/TopDancer';
import SubmitAdRequest from './components/advertise/SubmitAdRequest';
import AdvertisementApproval from './components/admin/AdvertisementApproval';
import DancerProfile from './components/topLists/DancerProfile';
import DancerSkills from './components/studio/DancerSkills';

import Company from './components/studio/Company';
import StudioState from './context/studio/StudioState';
import Studio from './components/studio/Studio';
import BlogDetail from './components/blog/BlogDetail';
import BlogState from './context/blog/BlogState';
import PostBlog from './components/blog/PostBlog';
import EventsThisWeek from './components/events/events-this-week/EventsThisWeek';
import EventsThisWeekState from './context/eventsThisWeek/EventsThisWeekState';
import PerformanceReviewState from './context/PerformanceReview/PerformanceReviewState';
import ArtistProfile from './components/user/ArtistProfile';
import ArtistState from './context/artist/ArtistState';
import WorkshopTest from './components/WorkshopTest';
import GoProSubscriptionPayment from './components/studio/GoProSubscriptionPayment';
import ShowStudiosByRegion from './components/studio/ShowStudiosByRegion';
import RegisterAndClaimArtist from './components/auth/RegisterAndClaimArtist';
import Claims from './components/admin/Claims';
import ArtistTools from './components/user/ArtistTools';
import PrivacyPolicy from './business-documents/PrivacyPolicy';
import Terms from './business-documents/Terms';
import ReactionState from './context/reaction/ReactionState';
import BrazilianZoukWorldChampionships from './components/events/BrazilianZoukWorldChampionships';
import ChatState from './context/chat/ChatState';
import ArtistDirectory from './components/artist/ArtistDirectory';
import WhatIsAirmeus from './components/landing-page/WhatIsAirmeus';
import EventDetailWithCategorySpecificMenu from './components/events/EventDetailWithCategorySpecificMenu';
import Onboarding from './onboarding/Onboarding';
import ArtistOnboard from './onboarding/ArtistOnboard';
import InstructorOnboard from './onboarding/InstructorOnboard';
import StudioOnboard from './onboarding/StudioOnboard';
import GoProEventPayment from './components/events/payments/GoProEventPayment';
import GoProArtistPayment from './components/events/payments/GoProArtistPayment';

import ReactGA from 'react-ga4';
import RegisterAndClaimStudio from './components/auth/RegisterAndClaimStudio';
import AirmeusAmbassador from './components/landing-page/AirmeusAmbassador';
import Rankings from './components/rankings/Rankings';
import RankingsState from './context/rankings/RankingsState';
import CurriculumMarketplace from './components/curriculum/CurriculumMarketplace';
import CurriculumState from './context/curriculum/CurriculumState';
import AddLocation from './components/location/AddLocation';
import InstructorState from './context/instructor/InstructorState';
import Alerts from './components/Alerts';
import TourState from './context/tour/TourState';
import StudentOnboard from './onboarding/StudentOnboard';
import FileIncidentReport from './components/incidentReport/FileIncidentReport';
import IncidentReportState from './context/incidentReport/IncidentReportState';
import AllIncidentReports from './components/incidentReport/AllIncidentReports';
import MyIncidentReports from './components/incidentReport/MyIncidentReports';
import IncidentReport from './components/incidentReport/IncidentReport';
import IncidentReport2Updated from './components/incidentReport/IncidentReport2Updated';
import SuspensionList from './components/incidentReport/SuspensionList';
import SafetyToolsLandingPage from './components/incidentReport/safety-landing-page/SafetyToolsLandingPage';
import SafeEvents from './components/events/SafeEvents';
import GeneratedConnections from './components/trivia-connect/GeneratedConnections';
import PotentialActivities from './components/trivia-connect/PotentialActivities';
import TriviaState from './context/trivia/TriviaState';
import ThisOrThatHome from './components/trivia-connect/Home/ThisOrThatHome';
import BusinessOnboard from './components/business-onboard/BusinessOnboard';

// import TourState from './context/tour/tourState';
ReactGA.initialize('G-W1KYN04T4X');

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  // ReactGA.send({ hitType: 'pageview', page: '/', title: 'home page' });
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
    // title: 'Custom Title2',
  });

  // ReactGA.pageview(window.location.pathname);
  return (
    <AuthState>
      <AlertState>
        <UserState>
          <EventState>
            <SubEventState>
              <AdvertismentState>
                <NotificationState>
                  <TopListState>
                    <StudioState>
                      <BlogState>
                        <EventsThisWeekState>
                          <PerformanceReviewState>
                            <ReactionState>
                              <ArtistState>
                                <ChatState>
                                  <RankingsState>
                                    <CurriculumState>
                                      <InstructorState>
                                        <TourState>
                                          <IncidentReportState>
                                            <TriviaState>
                                              <Alerts />
                                              {/* <Box> */}
                                              <Router>
                                                {/* <Box paddingBottom={56}> */}
                                                <Fragment>
                                                  {/* <Navbar /> */}
                                                  <div
                                                    className="container"
                                                    style={{
                                                      overflowX: 'hidden',
                                                    }}
                                                  >
                                                    <Routes>
                                                      <Route
                                                        exact
                                                        path="/register/:user_id"
                                                        element={<Register />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/register-and-claim-artist/:artist_id"
                                                        element={
                                                          <RegisterAndClaimArtist />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/register-and-claim-studio/:studio_id"
                                                        element={
                                                          <RegisterAndClaimStudio />
                                                        }
                                                      />
                                                      {/* <Route
                                                exact
                                                path="/verify-and-claim-artist/:artist_id"
                                                element={<RegisterAndClaimArtist />}
                                              /> */}

                                                      {/* <Route exact path="/landing" element={<Landing />} /> */}

                                                      <Route
                                                        exact
                                                        path="/about"
                                                        element={<About />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/upcoming-events/"
                                                        element={
                                                          <UpcomingEventsNew />
                                                        }
                                                      />
                                                      {/* below route we may return to at some point to add dance trip logic */}
                                                      {/* <Route
                                                  exact
                                                  path="/upcoming-events/"
                                                  element={<UpcomingEvents />}
                                                /> */}

                                                      <Route
                                                        exact
                                                        path="/events"
                                                        element={<Events />}
                                                      />
                                                      {/* <Route path="/event/:id" component={EventDetail} /> */}
                                                      <Route
                                                        exact
                                                        path="/event/:id"
                                                        element={
                                                          <EventDetailWithCategorySpecificMenu />
                                                        }
                                                        // below is the normal way without event specific menus
                                                        // element={<EventDetail />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/event/brazilian-zouk-world-championships"
                                                        element={
                                                          <BrazilianZoukWorldChampionships />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/event/my-events/:id"
                                                        element={
                                                          <MyEventsDetail />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/trip/my-trips/:id"
                                                        element={
                                                          <MyTripDetails />
                                                        }
                                                      />
                                                      {/* <Route exact path="/user/:id" element={<UserProfile />} /> */}
                                                      <Route
                                                        exact
                                                        path="/user/profile"
                                                        element={
                                                          <CurrentUserProfileContainer />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/user/profile/:id"
                                                        element={
                                                          <UserProfileContainer />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/search"
                                                        element={
                                                          <SearchResultsPage />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/all-users"
                                                        element={<AllUsers />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/my-connections"
                                                        element={
                                                          <MyConnections />
                                                        }
                                                      />
                                                      {/* <Route
                                                exact
                                                path="/advertise"
                                                element={<Advertise />}
                                              /> */}
                                                      <Route
                                                        exact
                                                        path="/advertise"
                                                        element={
                                                          <AdvertiseNoRedirect />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/adRequest"
                                                        element={
                                                          <SubmitAdRequest />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/approvals"
                                                        element={
                                                          <AdvertisementApproval />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/claims"
                                                        element={<Claims />}
                                                      />

                                                      <Route
                                                        exact
                                                        path="/payForAd"
                                                        element={
                                                          <StripeCheckout />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/sharktank"
                                                        element={<Sharktank />}
                                                      />
                                                      {/* <Route
                                                        exact
                                                        path="/business-onboard"
                                                        element={
                                                          <BusinessOnboard />
                                                        }
                                                      /> */}

                                                      <Route
                                                        exact
                                                        path="/notifications"
                                                        element={
                                                          <MyNotifications />
                                                        }
                                                      />

                                                      <Route
                                                        exact
                                                        path="/event/:event_id/artist-lineup"
                                                        element={
                                                          <MyNotifications />
                                                        }
                                                      />
                                                      {/* <div className="containerDropDownNotHidden"> */}
                                                      <Route
                                                        exact
                                                        path="/topDancer"
                                                        element={<TopDancer />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/dancer-profile/:dancer_id"
                                                        element={
                                                          <DancerProfile />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/update/dancer/:dancer_id"
                                                        element={
                                                          <DancerSkills />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/studio/:studio_id"
                                                        element={<Company />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/studio"
                                                        element={<Studio />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/blog"
                                                        element={<PostBlog />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/news/:title"
                                                        element={<BlogDetail />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/events-this-week"
                                                        element={
                                                          <EventsThisWeek />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/artist-profile/:instructor_id"
                                                        element={
                                                          <ArtistProfile />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/test"
                                                        element={
                                                          <WorkshopTest />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/go-pro-subscription/:studio_id"
                                                        element={
                                                          <GoProSubscriptionPayment />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/go-pro-event/:event_id"
                                                        element={
                                                          <GoProEventPayment />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/go-pro-artist/:artist_id"
                                                        element={
                                                          <GoProArtistPayment />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/studios-by-region"
                                                        element={
                                                          <ShowStudiosByRegion />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/artist/tools/:instructor_id"
                                                        element={
                                                          <ArtistTools />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/artist-directory"
                                                        element={
                                                          <ArtistDirectory />
                                                        }
                                                      />

                                                      <Route
                                                        exact
                                                        path="/privacy-policy"
                                                        element={
                                                          <PrivacyPolicy />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/terms-of-service"
                                                        element={<Terms />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/onboarding"
                                                        element={<Onboarding />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/artist-onboarding"
                                                        element={
                                                          <ArtistOnboard />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/studio-onboarding"
                                                        element={
                                                          <StudioOnboard />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/instructor-onboarding"
                                                        element={
                                                          <InstructorOnboard />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/student-onboarding"
                                                        element={
                                                          <StudentOnboard />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/rankings"
                                                        element={<Rankings />}
                                                      />
                                                      <Route
                                                        exact
                                                        path="/curriculum-marketplace"
                                                        element={
                                                          <CurriculumMarketplace />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/location"
                                                        element={
                                                          <AddLocation />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/generated-potential-connections"
                                                        element={
                                                          <GeneratedConnections />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/potential-activities"
                                                        element={
                                                          <PotentialActivities />
                                                        }
                                                      />
                                                      <Route
                                                        exact
                                                        path="/this-or-that-home"
                                                        element={
                                                          <ThisOrThatHome />
                                                        }
                                                      />

                                                      {/* </div> */}
                                                      {/* <Route
                                                                exact
                                                                path="/invest"
                                                                element={<Invest />}
                                                              /> */}
                                                      {/* <Route exact path="/about" component={About} />
                                                  <Route exact path="/register" component={Register} />
                                                  <Route exact path="/login" component={Login} /> */}
                                                      {/* <React.Fragment>
                                            
                                            <Route
                                              exact
                                              path="/what-is-airmeus"
                                              element={<WhatIsAirmeus />}
                                            />
                                            
                                          </React.Fragment> */}
                                                    </Routes>
                                                  </div>

                                                  <Routes>
                                                    <Route
                                                      exact
                                                      path="/"
                                                      element={<Home />}
                                                    />
                                                    <Route
                                                      exact
                                                      path="/login"
                                                      element={<Login />}
                                                    />

                                                    <Route
                                                      exact
                                                      path="/register"
                                                      element={<Register />}
                                                    />

                                                    <Route
                                                      exact
                                                      path="/what-is-airmeus"
                                                      element={
                                                        <WhatIsAirmeus />
                                                      }
                                                    />
                                                    <Route
                                                      exact
                                                      path="/ambassador"
                                                      element={
                                                        <AirmeusAmbassador />
                                                      }
                                                    />
                                                    <Route
                                                      exact
                                                      path="/incident-report"
                                                      element={
                                                        <FileIncidentReport />
                                                      }
                                                    />
                                                    <Route
                                                      exact
                                                      path="/all-incident-reports"
                                                      element={
                                                        <AllIncidentReports />
                                                      }
                                                    />
                                                    <Route
                                                      exact
                                                      path="/my-incident-reports/:id"
                                                      element={
                                                        <MyIncidentReports />
                                                      }
                                                    />
                                                    <Route
                                                      exact
                                                      path="/incident-report/:incident_report_id"
                                                      element={
                                                        <IncidentReport2Updated />
                                                      }
                                                    />
                                                    <Route
                                                      exact
                                                      path="/suspension-list"
                                                      element={
                                                        <SuspensionList />
                                                      }
                                                    />
                                                    <Route
                                                      exact
                                                      path="/safety"
                                                      element={
                                                        <SafetyToolsLandingPage />
                                                      }
                                                    />
                                                    <Route
                                                      exact
                                                      path="/safe-events"
                                                      element={<SafeEvents />}
                                                    />

                                                    <Route
                                                      exact
                                                      path="/business-onboard"
                                                      element={
                                                        <BusinessOnboard />
                                                      }
                                                    />
                                                  </Routes>
                                                </Fragment>

                                                {/* <MuiBottomNavigation className="sticky" /> */}
                                                {/* </Box> */}
                                              </Router>
                                            </TriviaState>
                                          </IncidentReportState>
                                        </TourState>
                                      </InstructorState>
                                    </CurriculumState>
                                  </RankingsState>
                                  {/* </Box> */}
                                </ChatState>
                              </ArtistState>
                            </ReactionState>
                          </PerformanceReviewState>
                        </EventsThisWeekState>
                      </BlogState>
                    </StudioState>
                  </TopListState>
                </NotificationState>
              </AdvertismentState>
            </SubEventState>
          </EventState>
        </UserState>
      </AlertState>
    </AuthState>
  );
};

export default App;
