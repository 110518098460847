import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import PerformanceReviewContext from '../../context/PerformanceReview/performanceReviewContext'; // might have to capitalize
import { useContext, useEffect } from 'react';
const TopCompanies = (props) => {
  const performanceReviewContext = useContext(PerformanceReviewContext);

  const {
    addNewCompanyPerformersToEvent,
    // getAllCompanyReviewsForEvent,
    // all_company_performance_reviews_for_event,
  } = performanceReviewContext;

  useEffect(() => {
    // console.log(
    //   'props from performer.js -- should not be emptyy.. shouild be event'
    // );
    // console.log(props);
    // console.log('props.event_id from performer.js');
    // console.log(props.event._id);
    // getPerformersForEvent(props.event._id);
    // getAllReviewsForEvent(props.event._id);
    // eslint-disable-next-line
  }, []);
  let image;
  if (props.company?.image) {
    image = props.company?.image.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }

  //handle add button : add group to performances and add msg saying
  //group added and refresh
  const addCompanyToEventPerformers = (obj) => {
    // e.preventDefault();
    addNewCompanyPerformersToEvent(obj);
    // console.log();
  };

  return (
    <div>
      <div className="row">
        <div className="col-2">
          <img
            className="rounded-circle"
            //   src={profile.user.avatar}
            // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
            src={image}
            alt=""
            height="35"
            width="35"
          />
        </div>
        <div className="col">
          <h5>{props.company.name}</h5>
        </div>
        <div className="col">
          <Button
            onClick={() =>
              addCompanyToEventPerformers({
                studio_id: props.company._id,
                event_id: props.event_id,
              })
            }
          >
            add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TopCompanies;
