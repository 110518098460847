import React, { Component, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import AdPanelStyle from './AdPanelStyle';
import AdvertisementContext from '../../context/advertisement/advertismentContext';
import { Link } from 'react-router-dom';

const AdPanel = (props) => {
  const advertisementContext = useContext(AdvertisementContext);
  const { getSideAd, side_advertisement } = advertisementContext;

  useEffect(() => {
    console.log(
      'getting events from Event.js but need to change component name'
    );
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    getSideAd();
    console.log(side_advertisement);
    console.log('side_advertisement');
    // eslint-disable-next-line
  }, []);

  return (
    <AdPanelStyle>
      {/* <div> */}
      {/* <h5>Ad</h5> */}

      <div class="sidenav blog">
        <Link
          to={`/advertise`}
          // className="btn btn-info"
          style={{ fontSize: '10px' }}
        >
          <h5 style={{ fontSize: '12px' }}>Sponsored</h5>
        </Link>
        <div className="all-center">
          <div className="row">
            <div className="col">
              <img
                className="rounded-circle"
                //   src={profile.user.avatar}
                src={side_advertisement.adImage?.url}
                // src={image}
                alt=""
                height="100"
                width="100"
              />
            </div>
            <div className="col">
              <h5 style={{ fontSize: '12px' }}>
                {side_advertisement.promotionText}
              </h5>
              {/* </div>
            <div className="col"> */}
              <Button className="text-center" variant="btn btn-info">
                {/* Like */}
                <Link
                  to={`/user/profile/${side_advertisement.ownersId}`}
                  className="btn btn-info"
                  style={{ fontSize: '10px' }}
                >
                  View Profile
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AdPanelStyle>
  );
};
export default AdPanel;
