import React, {
  Fragment,
  Component,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import TabContainer from 'react-bootstrap/TabContainer';
// import TabPane from 'react-bootstrap/TabPane';
import Attendees from './Attendees';
import Roommates from './Roommates';
// import Feed from './Feed';
import SubEvents from './SubEvents';
import { Link } from 'react-router-dom';
import EditEventDetail from './EditEventDetail';
import userContext from '../../context/user/userContext'; // might have to capitalize
import eventContext from '../../context/event/eventContext'; // might have to capitalize
import Vendors from './Vendors';
import { useAuth } from '../../context/auth/AuthState';
// import ArtistLineup from './artist-lineup/ArtistLineup';
import EditArtistLineup from './artist-lineup/EditArtistLineup';
import SeeArtistLineup from './artist-lineup/SeeArtistLineup';
// import WorkshopSchedule from './artist-lineup/WorkshopSchedule';
import WorkshopScheduleNoPopup from './artist-lineup/WorkshopScheduleNoPopup';
import MyItinerary from './MyItinerary';
import DJRequests from './DJRequests';
import Performer from '../performances/Performer';
import WorkshopScheduleListFormat from './artist-lineup/WorkshopScheduleListFormat';
import DanceReactions from '../reactions/DanceReactions';
import EventFeedback from './EventFeedback';
import AddFeedBackForm from '../events/AddFeedBackForm';

const moment = require('moment');
moment().format();
// export function EventDetail() {
const EventDetailBZWC = (props) => {
  const [authState] = useAuth();
  const { user } = authState;

  const [show, setShow] = useState(false);

  const handleClose = () => {
    console.log('closing ');
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    console.log(`show is: ${show}`);
  };

  const handleCloseLineup = () => {
    console.log('closing ');
    // setShowLineup(false);
  };
  const handleShowLineup = () => {
    // setShowLineup(true);
    // console.log(`showCreateLineup is: ${showLineup}`);
  };

  // const handleShowLineup = () => {
  //   setShowLineup(true);
  //   console.log(`showLineup is: ${showLineup}`);
  // };

  // const [key, setKey] = useState('attendees');

  // const [key, setKey] = useState(props.page);
  // const uContext = useContext(userContext);
  // const { } = uContext;
  const eContext = useContext(eventContext);
  const {
    editEvent,
    deleteEvent,
    addToEventLineup,
    getWorkshopSchedule,
    workshop_schedule,
    getMyItinerary,
    myItinerary,
    // addNewDJ,
    // newDJ,
  } = eContext;

  const location = useLocation();
  const { event, page } = props;
  const [key, setKey] = useState(page);
  useEffect(() => {
    getWorkshopSchedule(event._id);
    if (user) {
      getMyItinerary(event._id, user?._id);
    }
    console.log('testing g');
    // getHost(event.hostId);
    // console.log('event host: ');
    // console.log(host);
  }, []);
  console.log('testing how many re-renders');

  let workshop_dict = new Map(Object.entries(workshop_schedule));

  let image;
  if (event.image) {
    image = event.image.url;
  } else {
    image =
      'https://www.weho.org/home/showpublishedimage/5617/635754012603070000';
  }
  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    name: event.name,
    descr: event.descr,
    // location: 'Charlotte, NC',
  });

  const { name, descr } = details;

  const updatedEvent = {};
  updatedEvent.event_id = event._id;

  // updatedEvent.descr = descr;
  updatedEvent.name = name;
  updatedEvent.descr = descr;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving updated user from frontend');

    if (details === '') {
      //   setAlert('Please fill in all fields', 'danger');
    } else {
      editEvent(updatedEvent);

      console.log('updatedEvent from edit is: ---');
      console.log(updatedEvent);
      console.log('saving updated user from frontend');
    }
  };
  const onDelete = (e) => {
    e.preventDefault();
    console.log('deleting event from frontend');
    deleteEvent(event._id);
  };

  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);

  const editingEvent = (
    <Fragment>
      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Event Name</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  value={name}
                  onChange={onChange}
                  autoFocus
                />
                <Form.Label>Event Description</Form.Label>
                <Form.Control
                  id="descr"
                  name="descr"
                  type="text"
                  value={descr}
                  onChange={onChange}
                  autoFocus
                />
                {/* <Form.Label>Dance Company</Form.Label>
              <Form.Control
                id="dance_company"
                name="dance_company"
                type="text"
                value={dance_company}
                onChange={onChange}
                autoFocus
              />
              <Form.Label>Location</Form.Label>
              <Form.Control
                id="location"
                name="location"
                type="text"
                value={location}
                onChange={onChange}
                autoFocus
              /> */}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setDeleteButtonClicked(true)}
            >
              Delete
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              Save Changes
            </Button>
            {deleteButtonClicked ? (
              <div>
                <div>
                  <label htmlFor="">
                    Are you sure you want to delete this event?
                  </label>
                </div>
                <div className="row">
                  <div className="col">
                    <Link to="/">
                      <Button
                        onClick={() => {
                          deleteEvent(event._id);
                        }}
                        className="col btn btn-danger"
                      >
                        Delete
                      </Button>
                    </Link>
                  </div>
                  <div className="col">
                    <Button onClick={() => setDeleteButtonClicked(false)}>
                      no
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Modal.Footer>
        </Modal>
        {/* render attendees under attendees h1 or bring the attendees to a child component */}
      </div>
    </Fragment>
  );

  const notEditingRenderNothing = <Fragment />;
  const dontShowArtistLineup = <Fragment />;

  const smallText = (
    <Fragment>
      <div className="smallText">
        <p>{event.descr}</p>
      </div>
    </Fragment>
  );

  const largeText = (
    <Fragment>
      <div className="smallTextExpanded">
        <p>{event.descr}</p>
      </div>
    </Fragment>
  );
  // let expandButtonSelected = false;
  const [expandButtonSelected, setExpandButtonSelected] = useState(false);

  const attendeeButtonNumberTitle = event.attendees?.length
    ? event.attendees?.length
    : '';

  // const showArtistLineup = (
  //   <Fragment>
  //     <div>
  //       <ArtistLineup />
  //     </div>
  //   </Fragment>
  // );

  let myItineraryMap = new Map(Object.entries(myItinerary));
  const [myItineraryClicked, setMyItineraryClicked] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => {
    console.log('closing ');
    setShowDelete(false);
  };
  const handleShowDelete = () => {
    setShowDelete(true);
    // console.log(`show is: ${show}`);
  };

  const deletingEvent = (
    <Fragment>
      <div>
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you want to delete this event
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Cancel
            </Button>
            {/* <Button variant="primary" onClick={onDelete}> */}
            <Button
              variant="primary"
              onClick={() => {
                console.log('hi');
              }}
            >
              Delete B
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );

  const [showAddFeedBackForm, setShowAddFeedBackForm] = useState(false);

  return (
    <div>
      <div className="all-center">
        <div className="bigScreen smallScreenEventDetail">
          <img
            // style={{ width: '350px' }}
            src={image}
            alt="event"
            // src="https://static.wixstatic.com/media/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg/v1/fit/w_2500,h_1330,al_c/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg"
          />
        </div>
      </div>
      {/* <img
        style={{ width: '350px' }}
        src={image}
        alt="event"
        // src="https://static.wixstatic.com/media/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg/v1/fit/w_2500,h_1330,al_c/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg"
      /> */}
      <div className="adjust-line-height">
        {event?.start_date ? (
          <label style={{ fontSize: '14px', color: 'red' }}>
            {moment(event.start_date)
              .zone(0)
              .format('dddd MMMM Do, YYYY')}
          </label>
        ) : (
          <></>
        )}
        <br />
        <label style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {event.name}
        </label>
        <br />
        {event?.venueName ? (
          <label style={{ fontSize: '14px' }}>
            {' '}
            {event.venueName + ' - ' + event.city + ',' + event.state}
          </label>
        ) : (
          <></>
        )}
      </div>
      {!expandButtonSelected ? smallText : largeText}
      <Button
        variant="primary"
        onClick={() => setExpandButtonSelected(!expandButtonSelected)}
      >
        {!expandButtonSelected ? 'see more' : 'see less'}
      </Button>
      {event?.premium_subscription === true ? (
        <Button
          onClick={() => {
            // getMyItinerary(event._id, user?._id);
            console.log('myItinerary');
            console.log(myItinerary);
            setMyItineraryClicked(!myItineraryClicked);
          }}
        >
          {myItineraryClicked ? 'Close Itinerary' : 'My Itinerary'}
        </Button>
      ) : (
        <></>
      )}
      {myItineraryClicked && (
        // <div className="vertical-artist-container">
        <div>
          <div className="col">
            <MyItinerary
              itinerary={myItinerary}
              date={'10-30-2022'}
              event={event}
            />

            {/* <h1>hmm</h1> */}
          </div>
        </div>
      )}
      <hr />
      <div className="row">
        {/* <Link to={`artist-lineup`}> */}

        {/* <Button className="btn btn-info" onClick={handleShowLineup}>
        See Lineup
      </Button> */}
        <div>
          <SeeArtistLineup event={event} />
        </div>
        {event.hostId === user?._id ? (
          <div className="col">
            <EditArtistLineup event={event} />
          </div>
        ) : (
          <></>
        )}
      </div>
      {event?.premium_subscription === true ? (
        <div className="col">
          {/* <WorkshopSchedule event={event} /> */}
          <WorkshopScheduleNoPopup event={event} />
        </div>
      ) : (
        <></>
      )}
      <div>
        <WorkshopScheduleListFormat event={event} />
      </div>
      <div>
        <DanceReactions dance_reaction_elected={props.dance_reaction_elected} />
      </div>
      <div>
        <Button onClick={() => setShowAddFeedBackForm(!showAddFeedBackForm)}>
          Event Feedback
        </Button>

        {showAddFeedBackForm && (
          <AddFeedBackForm
            event={props.event}
            dance_reaction_elected={showAddFeedBackForm}
          />
        )}
      </div>

      {/* </Link> */}
      {/* <div className="smallText">
        <p>{event.descr}</p>
      </div> */}
      <hr />
      {/* <h2 htmlFor="">Host</h2> */}
      <div className="row">
        {/* <div className="col"> */}
        <div className="col">
          <h2 htmlFor="">Host</h2>
        </div>
        <div className="col">
          <div className="bigScreenHost smallScreenHost">
            <Link to={`/user/profile/${event.hostId}`}>
              <img
                src={event?.hostImage ? event.hostImage.url : imgg}
                alt="user"
              />
            </Link>
          </div>
        </div>
        <div className="col">
          <label>{event?.hostName}</label>
        </div>
        {/* </div> */}
        {/* <div className="col-3">
          <h4>{host.name}</h4>
        </div> */}
        <div className="col">
          {/* <Link to={`/user/profile/${event.hostId}`} className="btn btn-info">
            {event?.hostName}
          </Link> */}
          {/* <label>{event?.hostName}</label> */}
          {typeof event?.hostId !== 'undefined' &&
          event?.hostId === user?._id ? (
            <button className="btn btn-info" onClick={handleShow}>
              Edit
            </button>
          ) : (
            <></>
          )}
          {/* {typeof event?.hostId !== 'undefined' &&
          event?.hostId === user?._id ? (
            <button
              type="button"
              className="btn btn-danger"
              // onClick={(e) => deleteEvent(event._id)}
              onClick={handleShowDelete}
            >
              Delete
            </button>
          ) : (
            <></>
          )} */}
        </div>
      </div>
      <hr />
      {/* <button type="button" class="btn btn-secondary">
        Add Attendee
      </button> */}
      <div className="smallScreen">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="roommates" title="Room Share">
            <Roommates event={event} />
          </Tab>
          <Tab
            eventKey="attendees"
            title={`${attendeeButtonNumberTitle} Attendees`}
          >
            <Attendees event={event} event_attendees={event.attendees} />
          </Tab>
          <Tab eventKey="subevent" title="Activities">
            <SubEvents event={event} />
          </Tab>
          {/* <Tab eventKey="vendor" title="Vendors">
            <Vendors event={event} />
          </Tab> */}
          <Tab eventKey="djs" title="DJ Requests">
            <DJRequests event={event} />
          </Tab>
          <Tab eventKey="performances" title="Performances">
            {/* <DJRequests event={event} /> */}
            <Performer event={event} />
          </Tab>
        </Tabs>
      </div>
      {show ? editingEvent : notEditingRenderNothing}
      {/* {showDelete ? deletingEvent : notEditingRenderNothing} */}
      {/* {showCreateLineup ? showArtistLineup : dontShowArtistLineup} */}
      {/* render attendees under attendees h1 or bring the attendees to a child component */}
    </div>
  );
};
export default EventDetailBZWC;
// import React, { Component } from 'react';
// import { useLocation } from 'react-router-dom';

// export default class EventDetail extends Component {
//   render() {
//     const location = useLocation();
//     const { event } = location.state;
//     console.log(event);
//     return (
//       <div>
//         <h1>event detail page.. add tab components below</h1>
//         <br />
//         <h1>Feed</h1>
//         <h1>Roommates</h1>
//         <h1>Attendees</h1>
//       </div>
//     );
//   }
// }
