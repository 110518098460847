import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { useState, Redirect } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PerformanceReviewContext from '../../context/PerformanceReview/performanceReviewContext'; // might have to capitalize
import { Button } from 'react-bootstrap';

// import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';

const DynamicStudioSearch = (props) => {
  const performanceReviewContext = useContext(PerformanceReviewContext);

  const {
    addNewCompanyPerformersToEvent,
    // getAllCompanyReviewsForEvent,
    // all_company_performance_reviews_for_event,
  } = performanceReviewContext;
  // useEffect(() => {
  // });
  const { userSearchText, dynamicStudioSearch } = props;
  const userContext = useContext(UserContext);

  const {
    getUsersForSearchCriteria,
    userSearchResults,
    // connections,
    // // getConnectionsForUserWithId,
    // getConnections,
  } = userContext;

  const search = useLocation().search;
  const searchText = new URLSearchParams(search).get('searchText');

  const [, updateState] = React.useState();

  const addCompanyToEventPerformers = (obj) => {
    // e.preventDefault();
    addNewCompanyPerformersToEvent(obj);
  };
  return (
    <div className="autoContainer2">
      <h5 style={{ fontSize: '14px' }}>Try searching for 'Que Candela'</h5>
      {dynamicStudioSearch.map((studio) => (
        <div className="option search-card">
          <h3>{studio.name}</h3>

          <Button
            onClick={() =>
              addCompanyToEventPerformers({
                studio_id: studio._id,
                event_id: props.event_id,
              })
            }
          >
            Add
          </Button>
        </div>
      ))}
    </div>
  );
};

export default DynamicStudioSearch;

/*
graveyard 



------------------dec 14 , 2023
  const onChange = (input) => {
    //this will run every time a user types
    //getSearchParametersDynamically(input)
    getUsersForSearchCriteria(userSearchText);
  };


  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [display, setDisplay] = useState(false);
  const options = ['gil', 'billlllll', 'chillllll'];
  const possibleSearches = ['gil', 'billlllll', 'chillllll'];
  let navigate = useNavigate();



  const selectUser = (user_id) => {
    navigate(`/user/profile/${user_id}`, {
      // state: {
      //   advert: advert,
      //   user: user,
      //   // promotionText: promotionText,
      //   file: file,
      // },
    });
  };




     {options.map((person) => (
        <div className="option">
          <h5 style={{ color: 'black' }}>{person}</h5>
        </div>
      ))} 





<Link
            to={`/user/profile/${user._id}`}
            className="btn btn-info"
            state={{ reloadState: true }}
          >
            <h5
              // onClick={() => this.forceUpdate}
              // onClick={() => window.location.reload()}
              style={{ color: 'black' }}
            >
              {user.name}
            </h5>
          </Link> 

          ------------------

*/
