import React, { useContext, useState } from 'react';
import AlertContext from '../context/alert/alertContext';
import { Modal } from 'react-bootstrap';

const Alerts = () => {
  const alertContext = useContext(AlertContext);
  const [show, setShow] = useState(alertContext.alerts.length > 0);
  const handleClose = () => setShow(false);

  return (
    alertContext.alerts.length > 0 &&
    alertContext.alerts.map((alert) => (
      // <Modal show={show} onHide={handleClose}>
      //   <Modal.Header closeButton>
      //     <Modal.Title>Error</Modal.Title>
      //   </Modal.Header>
      //   <Modal.Body>
      <div key={alert.id} className={`alert alert-${alert.type}`}>
        <i className="fas fa-info-circle" /> {alert.msg}
      </div>
      //   </Modal.Body>
      // </Modal>
    ))
  );
};

export default Alerts;
{
  /* <Modal show={show} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Filter</Modal.Title>
  </Modal.Header>
  <Modal.Body>{}</Modal.Body>
</Modal>; */
}
