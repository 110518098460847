import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import React, { useState } from 'react';
import { useAuth } from '../../../../context/auth/AuthState';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import userContext from '../../../../context/user/userContext'; // might have to capitalize
import eventContext from '../../../../context/event/eventContext'; // might have to capitalize
import BookPrivate from '../BookPrivate';
import { Link } from 'react-router-dom';
import ArtistFromLineup from '../ArtistFromLineup';
import ArtistContext from '../../../../context/artist/artistContext'; // might have to capitalize
import BookPrivatePopup from '../../../instructor/BookPrivatePopup';
import HorizontalArtistFromLineup from './HorizontalArtistFromLineup';

// function ArtistLineup() {
const HorizontalLineupNonModalWithPrivateLessonPopup = (props) => {
  const eContext = useContext(eventContext);
  const {
    editEvent,
    deleteEvent,
    addToEventLineup,
    getLineupForEvent,
    lineup,
    getDictLineupForEvent,
    lineupObject,
    premiumArtistJoinEventLineup,
  } = eContext;

  const artistContext = useContext(ArtistContext);
  const { artist, getArtistById } = artistContext;

  const { event, user } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('running ------------');
    setLoading(true);

    getLineupForEvent(event._id);
    getDictLineupForEvent(event._id);
    //this is asking if the currently logged in user is an artist then return that artist.
    //why?
    if (user?.artist_id) {
      console.log('getting artist id from user');
      getArtistById(user.artist_id);
    }

    // else {
    //   //get user that is instructor bcuz only artists and insturctors can be on
    //   // a lineup
    //   GET_USER(user._id);
    // }
    setLoading(false);
  }, []);
  console.log('artist is');
  console.log(artist);

  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const [showLineup, setShowLineup] = useState(false);
  const [artistType, setArtistType] = useState('zouk');

  const handleCloseLineup = () => {
    console.log('closing ');
    setShowLineup(false);
  };
  const handleShowLineup = () => {
    setShowLineup(true);
    console.log(`showLineup is: ${showLineup}`);
  };

  var isAllCheck = false;
  const handlePillClicked = (type) => {
    setArtistType(type);
    document
      .querySelectorAll('input[type=checkbox]')
      .forEach((el) => (el.checked = isAllCheck));
  };

  const salsa_artists = lineup;

  const bachata_artists = [
    {
      name: 'bachata aliana',
      _id: '62d2dc09c2f72ab2d6354aa4',
    },
    {
      name: ' bachata alfred',
      _id: '62d6d4c044e57e3bd7a87697',
    },

    {
      name: 'bachata  anon',
      _id: '62d6d5b77b0d052aa37e6e71',
    },
  ];

  const renderArtists = () => {
    console.log('lineup is ------');
    console.log(lineup);
    console.log('trying to render');
    if (artistType === 'salsa') {
      return salsa_artists;
    }
    if (artistType === 'bachata') {
      return bachata_artists;
    }

    return salsa_artists;
  };

  const renderArtists2 = (artistTypeVar) => {
    let artists_dict = new Map(Object.entries(lineupObject));

    // if (artistType === 'salsa') {
    //   return salsa_artists;
    // }
    // if (artistType === 'bachata') {
    //   return bachata_artists;
    // }

    console.log('artists_dict ----------');
    console.log(artists_dict);
    console.log('artist array based on selection');
    console.log(artists_dict.get(artistTypeVar));
    return artists_dict.get(artistTypeVar);
  };

  console.log('typeof lineupObject should be dict ------');
  console.log(typeof Object.entries(lineupObject));

  console.log('lineupObject yo');
  console.log(lineupObject);

  const event_lineup_categories = ['salsa', 'bachata'];

  const artistFromDict = (
    <Fragment>
      {renderArtists().map((user) => (
        <div>
          <img
            // src={user?.image ? user?.image.url : imgg}
            src={user?.image ? user?.image.url : imgg}
            height="100"
            alt="user_avi"
          />

          <label for={user?.id}>{user?.name}</label>
        </div>
      ))}
    </Fragment>
  );
  let artists_dict = new Map(Object.entries(lineupObject));
  const renderArtistsFromDict = () => {
    for (var i = 0; i < event_lineup_categories.length; i++) {
      artists_dict.get(event_lineup_categories[i]);

      artists_dict.get('user selection');

      //map over array and display html

      //   for (
      //     var artist_array = 0;
      //     artist_array < event_lineup_categories.length;
      //     artist_array++
      //   ) {
      //     console.log('artists in dict on frontend');
      //     console.log(artists_dict.get(event_lineup_categories[i]));
      //   }
    }
  };

  //dynamic buttons
  const renderSalsaButton = () => {
    if (artistType === 'salsa') {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          salsa
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          salsa
        </Button>
      );
    }
  };
  const renderBachataButton = () => {
    if (artistType === 'bachata') {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          bachata
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          bachata
        </Button>
      );
    }
  };

  const renderKizombaButton = () => {
    if (artistType === 'kizomba') {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          kizomba
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          kizomba
        </Button>
      );
    }
  };

  const renderZoukButton = () => {
    if (artistType === 'zouk') {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          zouk
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          zouk
        </Button>
      );
    }
  };

  const [bookPrivateWasClicked, setBookPrivateWasClicked] = useState(false);
  const [artistToBook, setArtistToBook] = useState('');
  const [artistNameToBook, setArtistNameToBook] = useState('');
  const [artistImage, setArtistImage] = useState('');

  const [privateLessonRequestSent, setPrivateLessonRequestSent] = useState(
    false
  );
  const setPrivateLessonRequestSentFromChild = (privateLessonRequestSent) => {
    // this.setState({ language: privateLessonRequestSent });
    setPrivateLessonRequestSent(privateLessonRequestSent);
    setBookPrivateWasClicked(!bookPrivateWasClicked);

    console.log('privateLessonRequestSent from parent');
    console.log(privateLessonRequestSent);
  };

  const handleJoinLineup = () => {
    console.log('trying to handleCreateTripAndAddRoommates ');
    //action from userState
    let obj = {
      event_id: event._id,
      lineup_array: user?.artist_id ? [user.artist_id] : [],
      artist_type: user?.artistType ? user?.artistType : 'bachata',
    };
    premiumArtistJoinEventLineup(obj);
  };

  const renderLineupButtons = () => {
    if (event?.lineup?.includes(artist?._id)) {
      return (
        <Button
          // onClick={() => {
          //   if (artist.premium_subscription) {
          //     handleJoinLineup();
          //   } else {
          //     console.log(
          //       'this is a premium subscription, upsell to go premium'
          //     );
          //   }
          // }}
          style={{
            width: '180px',
            background: 'white',

            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
            borderWidth: '1px',
            borderRadius: '4px',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            textAlign: 'center',
          }}
        >
          You've been added
        </Button>
      );
    }
    // if (artist !== {} && !event?.lineup.includes(artist?._id)) {
    if (artist && !event?.lineup?.includes(artist?._id)) {
      console.log('compare x');
      console.log(event.lineup);
      console.log(artist._id);
      return (
        <Button
          onClick={() => {
            if (artist.premium_subscription) {
              handleJoinLineup();
            } else {
              console.log(
                'this is a premium subscription, upsell to go premium'
              );
            }
          }}
          style={{
            width: '120px',
            background: 'white',

            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
            borderWidth: '1px',
            borderRadius: '4px',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            textAlign: 'center',
          }}
        >
          Join Lineup
        </Button>
      );
    }
  };

  const [dancer, setDancer] = useState({});
  if (loading) {
    return <h2>Loading...</h2>;
  }
  return (
    <div className="card">
      <h1>Lineup</h1>
      {/* <Button
        onClick={() => handleShowLineup()}
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '0px 12px',
          borderWidth: '1px',
        }}
      >
        Lineup
      </Button> */}
      {/* <Modal show={showLineup} onHide={handleCloseLineup}> */}
      {/* <Modal.Header closeButton>
          <Modal.Title>
            Event Lineup
            <h5 style={{ fontSize: '12px' }}>
              {props?.event?.premium_subscription
                ? `This Event is premium - enjoy airmeus for free (:`
                : `This Event is not premium yet, but premium instructors and artists still have access to exclusive tools`}
            </h5>
          </Modal.Title>
        </Modal.Header> */}

      {bookPrivateWasClicked ? (
        <BookPrivatePopup
          bookPrivateSelected={bookPrivateWasClicked}
          setBookPrivateSelected={setBookPrivateWasClicked}
          instructor_id={artistToBook}
          // dancer={user}
          // dancer={artist}
          dancer={dancer} // works for artists + instr.
          onSendPrivateLessonRequest={setPrivateLessonRequestSentFromChild}
        />
      ) : (
        // <div>
        //   <div className="row">
        //     <div className="row" style={{ paddingLeft: '40px' }}>
        //       <Button
        //         onClick={() => {
        //           setBookPrivateWasClicked(!bookPrivateWasClicked);
        //         }}
        //         style={{ backgroundColor: 'grey', width: '70px' }}
        //       >
        //         <h5 style={{ fontSize: '12px' }}>Back</h5>
        //       </Button>
        //       <br />
        //     </div>

        //     <div className="col-3">
        //       <img
        //         src={artistImage ? artistImage : imgg}
        //         height="75"
        //         width="75"
        //         alt="user"
        //         className="rounded-circle"
        //       />
        //     </div>
        //     <div className="col">
        //       <h5>Private Lesson w/ {artistNameToBook}</h5>
        //     </div>
        //   </div>
        //   <hr />
        //   <BookPrivate
        //     instructor_id={artistToBook}
        //     event_id={event._id}
        //     event_name={event.name}
        //     onSendPrivateLessonRequest={setPrivateLessonRequestSentFromChild}
        //     dancer={dancer} //this helps us determine if the dancer is artist or insturctor only

        //     //artist is the currently auth user..idky
        //   />
        // </div>

        <div>
          <Modal.Body>
            {/* <div className="row"> */}

            <div
              class="container-fluid  overflow-auto example"
              style={{ padding: '0px' }}
            >
              <div class="d-flex flex-row flex-nowrap">
                {/* <div className="card-horizontal-menu card-block mx-2"> */}
                {renderSalsaButton()}
                {renderBachataButton()}

                {renderKizombaButton()}
                {renderZoukButton()}
                {/* {renderZoukButton()}
                {renderZoukButton()} */}
                {/* </div> */}
              </div>
            </div>
            <hr />
            {privateLessonRequestSent && (
              <div className="row">
                <Button
                  style={{ color: 'white', backgroundColor: '#53cf00' }}
                  className="all-center"
                >
                  private lesson request sent !
                </Button>
              </div>
            )}
            {/* <div className="vertical-artist-container"> */}

            <div
              class="container-fluid  overflow-auto example"
              style={{ padding: '0px' }}
            >
              <div class="d-flex flex-row flex-nowrap">
                {/* <div className="col"> */}
                {renderArtists2(artistType)?.map((artist) => (
                  // <div>
                  <div className="card-horizontal-menu card-block mx-2">
                    {/* <ArtistFromLineup -- 4.19.24*/}
                    <HorizontalArtistFromLineup
                      artist={artist}
                      setBookPrivateWasClicked={setBookPrivateWasClicked}
                      setArtistToBook={setArtistToBook}
                      setArtistNameToBook={setArtistNameToBook}
                      setArtistImage={setArtistImage}
                      setDancer={setDancer}
                      bookPrivateWasClicked={bookPrivateWasClicked}
                      imgg={imgg}
                      event={event}
                    />
                  </div>
                ))}
                {/* </div> */}
              </div>
            </div>
            <div style={{ paddingTop: '10px' }}>
              {/* <Button
                  onClick={() => {
                    if (artist.premium_subscription) {
                      handleJoinLineup();
                    } else {
                      console.log(
                        'this is a premium subscription, upsell to go premium'
                      );
                    }
                  }}
                  style={{
                    width: '120px',
                    background: 'white',

                    borderStyle: 'solid',
                    borderColor: 'rgb(1, 126, 252)',
                    borderWidth: '1px',
                    borderRadius: '4px',

                    fontSize: '14px',
                    backgroundColor: 'white',
                    color: 'rgb(1, 126, 252)',
                    textAlign: 'center',
                  }}
                >
                  {event.lineup.includes(artist._id)
                    ? `You've been added `
                    : `join lineup`}
                </Button> */}

              {renderLineupButtons()}
            </div>
          </Modal.Body>
          {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseLineup}>
                Close
              </Button>
            </Modal.Footer> */}
        </div>
      )}
      {/* </Modal> */}
      {/* {renderArtistsFromDict()} */}
      {/* render attendees under attendees h1 or bring the attendees to a child component */}
    </div>
  );
};
export default HorizontalLineupNonModalWithPrivateLessonPopup;

{
  /* search bar below.. maybe bring back later
<div>
            <form className="form-inline my-2 my-lg-0">
              <div className="row">
                <div className="col">
                  <input
                    id="searchText"
                    class="form-control mr-sm-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    name="searchText"
                    
                  />
                </div>
                <div className="col-2" style={{ margin: 'auto' }}>
                  <button
                    onClick={onSubmit2}
                    class="btn btn-primary my-2 my-sm-0"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div>

           */
}
