import React, { Component, useContext, useEffect, useState } from 'react';
import ReactionContext from '../../context/reaction/reactionContext';

import DancerReactionsByUsers from './DancerReactionsByUsers';
import DancerReactionsObjectsByUsers from './DancerReactionsObjectsByUsers';
import { Button } from 'react-bootstrap';
import { useAuth } from '../../context/auth/AuthState';
import EmojiButton from './EmojiButton';

const DancerItem = (props) => {
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated, user } = authState;
  //get dancer.reactions
  const reactionContext = useContext(ReactionContext);
  const {
    getReactionsForDancer,
    reactionsForDancer,
    reactionFromUser,
    postReactionForDancer,
  } = reactionContext;
  let { dancer } = props;
  let reactionObj = { reaction_ids: dancer?.reactions };
  useEffect(() => {
    console.log('dancer.reactions');
    console.log(dancer?.reactions);
    getReactionsForDancer(reactionObj);
    // eslint-disable-next-line
  }, []);
  let emojis = ['🔥', '😍', '💪🏼'];

  //loop thru  dancer.reactionsCountArray
  //if emoji exists, then we can add it to the array to display
  //else create the obj with emoji and count===1

  //   const constructEmojiCountArray = () => {
  //     if (dancer?.reactionsCountArray.length > 0) {
  //       for (let i = 0; i < dancer?.reactionsCountArray.length; i++) {
  //         dancer.reactionsCountArray[i].emoji;
  //       }
  //     }
  //   };
  const [fireEmojiCount, setFireEmojiCount] = useState(0);
  const [heartEyesEmojiCount, setHeartEyesEmojiCount] = useState(0);
  const [muscleEmojiCount, setMuscleEmojiCount] = useState(0);

  const setCountStateForEmoji = (emoji, count) => {
    switch (emoji) {
      case '🔥':
        setFireEmojiCount(fireEmojiCount + count);
        break;
      case '😍':
        setHeartEyesEmojiCount(heartEyesEmojiCount + count);
        break;
      case '💪🏼':
        setMuscleEmojiCount(muscleEmojiCount + count);
        break;
      default:
      // code block
    }
  };

  const matchEmoji = (emoji) => {
    let emoji_and_count = '';
    let emojiFound = false;
    if (dancer?.reactionsCountArray.length > 0) {
      for (let i = 0; i < dancer?.reactionsCountArray.length; i++) {
        if (dancer.reactionsCountArray[i].emoji === emoji) {
          emoji_and_count =
            dancer.reactionsCountArray[i].numberOfReactions + ' ' + emoji;
          emojiFound = true;
          //set state to 1+dancer.reactionsCountArray[i].numberOfReactions
          //   setCountStateForEmoji(
          //     emoji,
          //     dancer.reactionsCountArray[i].numberOfReactions
          //   );
        }
      }

      //check if  emoji was found and if not add with 0
      if (!emojiFound) {
        //set state to 1
        // setCountStateForEmoji(emoji, 0);

        emoji_and_count = `0 ${emoji}`;
      }
    } else {
      //set state to 1
      //   setCountStateForEmoji(emoji, 0);
      emoji_and_count = `0 ${emoji}`;
    }

    return emoji_and_count;
  };
  console.log('reactionsForDancer  from DancerItem ---');
  console.log(reactionsForDancer);
  const reactToDancer = (selectedEmoji) => {
    // dancer_reaction_id_to_react_to,
    //   reacting_user_id,
    //   reacting_user_name,
    //   reaction_emoji,
    //   comment,

    // let  reacting_user_id = user !== null ? "" : "";
    let obj = {
      dancer_reaction_id_to_react_to: dancer._id,
      reacting_user_id: isAuthenticated ? user._id : '',
      reacting_user_name: isAuthenticated ? user.name : '',
      //   reacting_user_name: {user ? user.name:""},
      reaction_emoji: selectedEmoji,
      comment: '',
    };

    console.log(obj);
    postReactionForDancer(obj);
  };
  const [buttonClicked, setButtonClicked] = useState(false);
  return (
    <div>
      <div className="row">
        <div className="col">
          {dancer.dancer_name !== '' ? (
            <h5>{dancer.dancer_name}</h5>
          ) : (
            <h5>dancer</h5>
          )}
        </div>
        <div className="col">
          <h5>{dancer.bib_number}</h5>{' '}
        </div>

        {emojis.map((emoji) => (
          <div className="col-2">
            <EmojiButton emoji={emoji} dancer={dancer} />
            {/* <Button
              onClick={() => {
                console.log(`clicked ${emoji} ---`);
                console.log(dancer);
                reactToDancer(emoji);
                setButtonClicked(!buttonClicked);
              }}
              style={{
                fontSize: '16px',
                background: 'transparent',

                color: 'black',
                display: 'inline',
                width: '50px',
                padding: 0,
                borderStyle: 'solid',
                borderRadius: '1rem',
                borderColor: 'rgb(1, 126, 252)',

                backgroundColor:
                  buttonClicked === true ? 'rgb(1, 126, 252)' : 'transparent',
                borderWidth: '1px',
              }}
            >
              {matchEmoji(emoji)}
            </Button> */}
          </div>
        ))}
      </div>

      {/* {dancer.reactions.map((reactionsByUsers) => (
        <DancerReactionsByUsers reactionsByUsers={reactionsByUsers} />
      ))} */}
      {dancer.reactionsObject.map((reaction) => (
        <DancerReactionsObjectsByUsers reactionByUsers={reaction} />
      ))}
      <hr />
    </div>
  );
};
export default DancerItem;
