import React, { Component } from 'react';
import { useAuth } from '../../context/auth/AuthState';
import ConnectionItem from './ConnectionItem';
import { useContext, useEffect } from 'react';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const MyConnections = (props) => {
  const [authState] = useAuth();

  const { user } = authState;
  const userContext = useContext(UserContext);
  const {
    getConnections,
    connections,
    getConnectionsForUserWithId,
    user_connections,
  } = userContext;

  //   const { id } = useParams();
  //   console.log('id from myconnections params:');
  //   console.log(id);
  const location = useLocation();

  const { target_user } = location.state;

  console.log('target user is:');
  console.log(target_user);
  useEffect(() => {
    // console.log('getting users from useEffect from User Component');
    // getConnections(obj);
    getConnectionsForUserWithId(target_user._id);
  }, []);

  console.log('user_connections from myconnections');
  console.log(user_connections);
  //   console.log('user.connections');
  //   console.log(user.connections);
  return (
    <div>
      <h1>
        {target_user?._id === user?._id
          ? 'My Connections'
          : target_user.name + "'s Connections"}
      </h1>
      {user_connections.map((user) => (
        <ConnectionItem key={user?._id} user={user} />
      ))}
    </div>
  );
};

export default MyConnections;
