import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { useState, Redirect } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PerformanceReviewContext from '../../context/PerformanceReview/performanceReviewContext'; // might have to capitalize
import { Button } from 'react-bootstrap';
import AddNewArtistPopup from '../artist/AddNewArtistPopup';
import UserVerificationAndClaim from '../auth/UserVerificationAndClaim';
import { useAuth } from '../../context/auth/AuthState';

// import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';

const DynamicArtistSearch = (props) => {
  const performanceReviewContext = useContext(PerformanceReviewContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated, user } = authState;
  const {
    addNewCompanyPerformersToEvent,
    // getAllCompanyReviewsForEvent,
    // all_company_performance_reviews_for_event,
  } = performanceReviewContext;
  // useEffect(() => {
  // });
  const {
    userSearchText,
    dynamicArtistSearch,
    // userIsAddingArtist,
    // setUserIsAddingArtist,
  } = props;
  const userContext = useContext(UserContext);

  const {
    getUsersForSearchCriteria,
    userSearchResults,
    // connections,
    // // getConnectionsForUserWithId,
    // getConnections,
  } = userContext;

  const search = useLocation().search;
  const searchText = new URLSearchParams(search).get('searchText');
  console.log('userSearchResults ---');
  console.log(userSearchResults);
  // console.log('userSearchText');
  // console.log(userSearchText);
  const onChange = (input) => {
    //this will run every time a user types
    //getSearchParametersDynamically(input)
    getUsersForSearchCriteria(userSearchText);
  };
  const [, updateState] = React.useState();

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [display, setDisplay] = useState(false);
  const options = ['gil', 'billlllll', 'chillllll'];
  const possibleSearches = ['gil', 'billlllll', 'chillllll'];
  let navigate = useNavigate();
  const selectUser = (user_id) => {
    navigate(`/user/profile/${user_id}`, {
      // state: {
      //   advert: advert,
      //   user: user,
      //   // promotionText: promotionText,
      //   file: file,
      // },
    });
  };
  const [addNewArtistClicked, setAddNewArtistClicked] = useState(false);

  const addCompanyToEventPerformers = (obj) => {
    // e.preventDefault();
    addNewCompanyPerformersToEvent(obj);
    // console.log();
  };
  const [claimWasClicked, setClaimWasClicked] = useState(false);
  const [artistClicked, setArtistClicked] = useState({});
  return (
    <div className="autoContainer2">
      <h5 style={{ fontSize: '14px' }}>Try searching for 'De'jon and Clo'</h5>
      {dynamicArtistSearch.length > 0 &&
        userSearchText.length > 0 &&
        !claimWasClicked &&
        dynamicArtistSearch.map((artist) => (
          <div className="option search-card">
            {/* <Link
            to={`/user/profile/${user._id}`}
            className="btn btn-info"
            state={{ reloadState: true }}
          >
            <h5
              // onClick={() => this.forceUpdate}
              // onClick={() => window.location.reload()}
              style={{ color: 'black' }}
            >
              {user.name}
            </h5>
          </Link> */}
            <h3>{artist.name}</h3>

            <Button
              onClick={() => {
                setClaimWasClicked(true);
                setArtistClicked(artist);
                console.log('claiming artist');
              }}
              // addCompanyToEventPerformers({
              //   studio_id: studio._id,
              //   event_id: props.event_id,
              // })
            >
              Claim
            </Button>
          </div>
        ))}

      <div>
        {claimWasClicked && (
          <UserVerificationAndClaim
            artist_id_to_claim={artistClicked._id}
            user={user}
            onboarding={props?.onboarding ? props.onboarding : false}
            setShowArtistClaimedMsg={props.setShowArtistClaimedMsg}
          />
        )}
      </div>

      {/* {claimWasClicked && <UserVerificationAndClaim artist_id_to_claim={artist._id} user={user}  />} */}

      {/* {dynamicArtistSearch.length === 0 &&
        userSearchText.length > 0 && (
          <div>
            <h5>Artist not found, create artist</h5>
            <Button
              onClick={() => {
                console.log('clicked');
                setAddNewArtistClicked(!addNewArtistClicked);
                setUserIsAddingArtist(true);
                console.log('clicked2');
              }}
            >
              add new artists
            </Button>
            {addNewArtistClicked && (
              <div>
                <AddNewArtistPopup addNewArtistClicked={addNewArtistClicked} />
              </div>
            )}
          </div>
        )} */}
      {/* {options.map((person) => (
        <div className="option">
          <h5 style={{ color: 'black' }}>{person}</h5>
        </div>
      ))} */}
    </div>
  );
};

export default DynamicArtistSearch;
