import React, { Component } from 'react';

const MemberRoles = (props) => {
  let { roles } = props;
  return (
    <div className="row">
      {roles?.map((role) => (
        <div className="col-3">
          <h5
            className="rounded-pill"
            style={{
              width: '100px',
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
            }}
          >
            {role}
          </h5>
        </div>
      ))}
    </div>
  );
};
export default MemberRoles;
