import React, { Component, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';
// import { useAuth } from '../../context/auth/AuthState';
import Button from 'react-bootstrap/Button';
import { Rating } from 'react-simple-star-rating';
import PerformanceReviewContext from '../../context/PerformanceReview/performanceReviewContext'; // might have to capitalize
import { useAuth } from '../../context/auth/AuthState';

const LeaveReviewFields = (props) => {
  const [authState, authDispatch] = useAuth();
  const { user } = authState;
  const performanceReviewContext = useContext(PerformanceReviewContext);

  const {
    getReviewsForGroupForEvent,
    submitReviewToBackend,
    submitCompanyReviewToBackend,
  } = performanceReviewContext;

  useEffect(() => {
    // getConnections(user?._id);
    // getUsersForSearchCriteria(searchText);
    // console.log('connections');
    // console.log(connections);
    // eslint-disable-next-line
  }, []);

  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleReset = () => {
    // Set the initial value
    setRating(0);
  };
  const onPointerEnter = () => console.log('Enter');
  const onPointerLeave = () => console.log('Leave');
  //   const onPointerMove = (value: number, index: number) =>
  //   console.log(value, index);
  const onPointerMove = (value, index) => console.log(value, index);
  const tooltipArray = [
    // 'Okay',
    '👏👏👏',
    // 'Decent',
    '👍🏽👍🏽👍🏽',
    // 'Fuego', //Chefs kiss
    '🤗🥰🚀',
    '😱💪🏾🥶', //Fuego

    '🔥💯🐐', //Masterpiece
  ];

  const { event, performer } = props;

  const submitReview = () => {
    const reviewObj = {
      event_id: event._id,
      event_name: event.name,
      reviewer_id: user?._id,
      reviewer_name: user?.name,
      reviewer_img: user?.image?.url,
      stars_given: rating,
      group_id: performer.performer_id,
      // group_id: performer.group_id,
    };

    // console.log('sending info to backend');
    // console.log(reviewObj);

    console.log('performer---------');
    console.log(performer);
    console.log('reviewObj ---------');
    console.log(reviewObj);

    // uncomment wip 6.21.23
    // submitReviewToBackend(reviewObj);
    submitCompanyReviewToBackend(reviewObj);
  };
  return (
    // <div style={{ '-webkit-writing-mode': 'vertical-lr' }}>
    // <div>
    <div>
      <div style={{ 'writing-mode': 'vertical-lr', size: '10px' }}>
        {/* <div style={{ 'writing-mode': 'horizontal-tb' }}> */}
        <Rating
          onClick={handleRating}
          onPointerEnter={onPointerEnter}
          onPointerLeave={onPointerLeave}
          onPointerMove={onPointerMove}
          tooltipArray={tooltipArray}
          transition
          size={35}
          showTooltip
          tooltipDefaultText="😈"
          tooltipStyle={{ 'writing-mode': 'horizontal-tb' }}
          // size="40px"
          /* Available Props */
          // style={{ 'writing-mode': 'horizontal-tb' }}
        />
      </div>
      <div className="row" style={{ paddingTop: '10px' }}>
        <Button onClick={() => submitReview()} style={{ width: '100px' }}>
          submit
        </Button>
      </div>
    </div>
  );
};

export default LeaveReviewFields;
