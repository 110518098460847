import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import AlertContext from '../../context/alert/alertContext';
import { useAuth, clearErrors, register } from '../../context/auth/AuthState';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import ListOfCities from './ListOfCities.json';
import PotentialConnections from '../user/PotentialConnections';
import LocationFilterForRegister from './LocationFilterForRegister';
import Onboarding from '../../onboarding/Onboarding';
import AddLocation from '../location/AddLocation';
import Alerts from '../Alerts';
import Navbar from '../layout/Navbar';
import GeneratedConnections from '../trivia-connect/GeneratedConnections';

const Register = (props) => {
  const alertContext = useContext(AlertContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;

  const { setAlert } = alertContext;
  console.log('ListOfCities.list');
  console.log(ListOfCities);
  const listOfCities = ListOfCities;

  let { user_id } = useParams();

  const locationObj = useLocation();
  let trivia_answers = locationObj?.state?.trivia_answers;
  let event_id_from_trivia = locationObj?.state?.event_id_from_trivia;
  let event = locationObj?.state?.event;

  if (trivia_answers) {
    console.log('trivia_answers available but no user regstered');
    console.log(trivia_answers);
  } else {
    console.log('trivia_answers not available and no user regstered');
  }

  useEffect(
    () => {
      if (error === 'User already exists') {
        setAlert(error, 'danger');
        clearErrors(authDispatch);
      } else if (error === 'User with this phone number already exists') {
        setAlert(error, 'danger');
        clearErrors(authDispatch);
      }
    },
    [error, isAuthenticated, props.history, setAlert, authDispatch]
  );
  const [location, setLocation] = useState('Raleigh, North Carolina');

  const [user, setUser] = useState({
    name: '',
    email: '',
    instagram: '',
    phoneNumber: '',
    password: '',
    password2: '',
    referral_code: user_id ? user_id : '',
    profile_image: '',
    location: location,
  });
  const [file, setFile] = useState();

  const {
    name,
    email,
    instagram,
    phoneNumber,
    password,
    password2,
    referral_code,
    profile_image,
    // location,
  } = user;

  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });

  // const onSubmit = (e) => {
  //   e.preventDefault();

  //   if (name === '' || email === '' || password === '' || phoneNumber === '') {
  //     setAlert('Please enter all fields', 'danger');
  //   } else if (password !== password2) {
  //     setAlert('Passwords do not match', 'danger');
  //   } else {
  //     register(authDispatch, {
  //       name,
  //       email,
  //       phoneNumber,
  //       password,
  //       profile_image,
  //     });
  //   }
  // };

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', file);
    data.append('name', name);
    data.append('email', email);
    data.append('instagram', instagram);
    data.append('phoneNumber', phoneNumber);
    data.append('password', password);
    data.append('profile_image', profile_image);
    data.append('location', location);
    data.append('country', country);
    data.append('state', state);
    data.append('city', city);
    // if (user_id) {
    data.append('referral_code', referral_code);
    // const obj = Object.fromEntries(trivia_answers);
    console.log('trivia_answers before appending');
    console.log(trivia_answers);
    if (trivia_answers) {
      let trivia_answers_encoded = JSON.stringify(trivia_answers);

      data.append('trivia_answers', trivia_answers_encoded);
    }
    if (event_id_from_trivia) {
      // let trivia_answers_encoded = JSON.stringify(trivia_answers);
      data.append('event_id_from_trivia', event_id_from_trivia);
    }

    // }

    if (name === '' || email === '' || password === '' || phoneNumber === '') {
      setAlert('Please enter all fields', 'danger');
    }
    //file === image
    else if (file === undefined) {
      setAlert('Add an image to register', 'danger');
    } else if (password !== password2) {
      setAlert('Passwords do not match', 'danger');
    } else if (country === 'Select Country' || country === '') {
      setAlert('Select A Country', 'danger');
    } else {
      console.log('city,state,country');
      console.log(city, state, country);
      register(authDispatch, data);
      console.log('location is from new component');
      console.log(location);
    }
  };

  // if (isAuthenticated) return <Navigate to="/events" />;
  if (isAuthenticated && !trivia_answers)
    return <Onboarding city={city} state={state} country={country} />;

  if (isAuthenticated && trivia_answers)
    return (
      <GeneratedConnections trivia_answers={trivia_answers} event={event} />
    );

  // if (isAuthenticated) return <PotentialConnections city={location} />;
  return (
    <div>
      <div style={{ padding: '0px' }}>
        <Navbar />
      </div>
      <div className="container form-container">
        {trivia_answers ? (
          <div>
            <h1>Register and See Other Trivia Responses</h1>
            <br />
          </div>
        ) : (
          <h1>
            Account <span className="text-primary">Register</span>
          </h1>
        )}

        {/* <form onSubmit={onSubmit} encType="multipart/form-data"> */}
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              name="name"
              value={name}
              onChange={onChange}
              required
              // required
              // onInvalid="add name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="instagram">Instagram</label>
            <input
              id="instagram"
              type="text"
              name="instagram"
              value={instagram}
              onChange={onChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              id="phoneNumber"
              type="text"
              name="phoneNumber"
              value={phoneNumber}
              onChange={onChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              required
              minLength="6"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password2">Confirm Password</label>
            <input
              id="password2"
              type="password"
              name="password2"
              value={password2}
              onChange={onChange}
              required
              minLength="6"
            />
          </div>

          <div className="form-group">
            <label htmlFor="referral_code">Referral Code</label>
            <input
              id="referral_code"
              type="text"
              name="referral_code"
              value={referral_code}
              onChange={onChange}
              disabled="true"
            />
          </div>

          <div className="row">
            <label htmlFor="profile_image">Profile Image</label>
          </div>

          <input
            id="profile_image"
            name="profile_image"
            type="file"
            value={profile_image}
            onChange={(event) => {
              const file = event.target.files[0];
              setFile(file);
              setUser({ ...user, [event.target.name]: event.target.value });
            }}
          />
          {/* commented out on oct 15, 2023 to use <LocationFilterForRegister /> */}
          {/* <Form.Group controlId="formBasicSelect">
          <Form.Label>Location x</Form.Label>
          <Form.Control
            id="location"
            name="location"
            as="select"
            value={location}
            onChange={(e) => {
              setUser({ ...user, [e.target.name]: e.target.value });
            }}
          >
            {listOfCities.map((city) => (
              // <option value={city.name + ',' + city.usps}>
              <option value={city.name + ',' + city.state}>
                {city.name + ',' + city.usps}
              </option>
            ))}
          </Form.Control>
          
        </Form.Group> */}

          {/* <div>
          <LocationFilterForRegister setLocation={setLocation} />
        </div> */}
          <hr />
          <div>
            <AddLocation
              setCountry={setCountry}
              setState={setState}
              setCity={setCity}
              setLocation={setLocation}
            />
          </div>
          <hr />
          <br />
          <p>
            By signing up, you agree to the
            <Link to="/terms-of-service">
              <span style={{ fontSize: '14px', color: 'rgb(1, 126, 252)' }}>
                {' '}
                Terms of Service{' '}
              </span>
            </Link>
            and
            <Link to="/privacy-policy">
              <span style={{ fontSize: '14px', color: 'rgb(1, 126, 252)' }}>
                {' '}
                Privacy Policy{' '}
              </span>
            </Link>
          </p>
          <Alerts />
          <input
            type="submit"
            value="Register"
            className="btn btn-primary btn-block"
          />
        </form>
      </div>
    </div>
  );
};

export default Register;
