import React, { Component, Fragment } from 'react';
import userContext from '../../context/user/userContext'; // might have to capitalize
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../context/auth/AuthState';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import { csonParser } from 'config/parser';
import MyRoommate from '../../components/events/MyRoommate'; // might have to capitalize
import Example from './Example';

const MyEvents = (props) => {
  let navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [carpoolShow, setCarpoolShow] = React.useState(false);

  // member_array : [],
  // const [member_array, setMember_array] = React.useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCarpoolClose = () => setCarpoolShow(false);
  const handleCarpoolShow = () => setCarpoolShow(true);
  // const handleMemberArray = () => setMember_array([]);
  // const { user } = props;
  const [authState] = useAuth();
  // const { user } = authState;
  const uContext = useContext(userContext);

  //send user object to backend and let server do filtering for you?
  // const { events_and_roommates } = props;
  // const { event } = props;

  const location = useLocation();
  const { event, user, isCurrentUser, showDeleteButton } = location.state;
  // const { user_id } = useParams();
  console.log('isCurrentUser');
  console.log(isCurrentUser);
  const {
    getJoinedEventsForUser,
    // joined_events,
    // removed on 8.17.22 bcuz it looks like it
    //has no purpose in events.. only in trips
    // getEventsAndRoommatesForUser,
    // users_roommates_for_events,
    getConnectionsForUserWithId,
    user_connections,
    addUsersAsRoommates,
    users_added_to_roommates,
    createTripAndAddRoommates,
    unjoinEvent,
  } = uContext;

  // let member_array = [];

  // const [member_array, setMemberArray] = useState({
  //   // descr: '',
  //   // members: [],
  // });

  let member_array = [];

  const onChange = (e) => {
    console.log('changing list -- e is:');
    // console.log(e.target);
    // setMemberArray(...member_array, e.target.name);
    // console.log(member_array);

    if (member_array.includes(e.target.id)) {
      console.log('removing user');
      member_array = member_array.filter((item) => item !== e.target.id);
    } else {
      console.log('adding user');
      member_array.push(e.target.id);
    }

    const member_set = new Set(member_array);
    const uniqueMembers = [...member_set];

    console.log(member_array);
  };

  // const {event}=state;
  // const events_array = Object.values(events_and_roommates);

  // console.log(typeof events_array);
  // let eventID_array = [];
  // for (let i = 0; i < events_and_roommates.length; i++) {
  //   console.log('event id:');
  //   console.log(events_and_roommates[i].event_id);
  //   eventID_array.push(events_and_roommates[i].event_id);
  // }

  //query eventbyid and userbyid
  //do query for finding usersbyid and render the returned users to the sub-component

  useEffect(() => {
    // this.setState({
    //   member_array: [...this.state, []],
    // });
    // this.setState({
    //   ...state,
    //   member_array: [],
    // });

    console.log('getting myeventdetail');

    //what data are you trying to send back?

    // handleMemberArray();
    // getEventsAndRoommatesForUser(my_obj);
    console.log('in MyEventsDetails before getEventsAndRoommatesForUser');
    // removed on 8.17.22 bcuz it looks like it
    //has no purpose in events.. only in trips
    // getEventsAndRoommatesForUser(user?._id, event._id);
    getConnectionsForUserWithId(user?._id);
    console.log('in MyEventsDetails after getEventsAndRoommatesForUser');

    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later

    // console.log('user from upcoming');
    // console.log(user);
    // getJoinedEventsForUser(user._id);

    //there is an error thrown when we reload page
    //bcuz we are calling useEffect and getting "user" object from authState
    //figure out how to fix this or call the user._id from somewhere else..
    // getJoinedEventsForUser('6248c1337e4d6d57c43991f1'); //-this works without error on reload

    // eslint-disable-next-line
  }, []);

  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  //send mem

  // const handleAddUsersAsRoommates = () => {
  //   //action from userState
  //   let obj = {
  //     current_user_id: user._id,
  //     event_id: event._id,
  //     member_array: member_array,
  //     trip_owner_id: event.trip_owner_id,
  //   };
  //   console.log('event from addUsersAsRoommates');
  //   console.log(event);
  //   // addUsersAsRoommates(obj);

  //   //createTripAndAddRoommates()
  // };

  const handleCreateTripAndAddRoommates = () => {
    console.log('trying to handleCreateTripAndAddRoommates ');
    //action from userState
    let obj = {
      user_id: user._id,
      event_id: event._id,
      member_array: member_array,
    };
    console.log('event from addUsersAsRoommates');
    console.log(event);
    // addUsersAsRoommates(obj);

    createTripAndAddRoommates(obj);
    // navigate('/upcoming-events');
  };
  // const handleChange = (e) => {
  //   console.log('handling changing');
  //   // const { name, type, value } = e.target;
  //   // const val = type === 'number' ? parseFloat(value) : value;
  //   // this.setState({ [name]: val });

  //   // if(this.memberSelected(this.state.member_array,e.target.id)){
  //   // 	console.log("already selected")
  //   // }

  //   // this.setState({
  //   //   member_array: [...this.state.member_array, e.target.id],
  //   // });
  //   console.log('member_array after schange');

  //   console.log(member_array);
  // };

  // console.log('member array is:');
  // console.log(member_array);
  const smallText = (
    <Fragment>
      <div className="smallText">
        <p>{event.descr}</p>
      </div>
    </Fragment>
  );

  const largeText = (
    <Fragment>
      <div className="smallTextExpanded">
        <p>{event.descr}</p>
      </div>
    </Fragment>
  );
  const [expandButtonSelected, setExpandButtonSelected] = useState(false);

  return (
    <div>
      <div className="all-center">
        <div className="bigScreen smallScreen">
          <img
            // style={{ width: '350px' }}
            src={event?.image ? event.image.url : imgg}
            alt="event"
            // src="https://static.wixstatic.com/media/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg/v1/fit/w_2500,h_1330,al_c/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg"
          />
        </div>
      </div>
      <h1>{event.name}</h1>
      {/* <h3>{event.descr}</h3> */}
      {!expandButtonSelected ? smallText : largeText}
      <Button
        variant="primary"
        onClick={() => setExpandButtonSelected(!expandButtonSelected)}
      >
        {!expandButtonSelected ? 'see more' : 'see less'}
      </Button>

      <hr />
      <Button variant="primary" onClick={handleShow}>
        Add Members
      </Button>
      {/* <Example /> */}

      {/* <Button variant="primary" onClick={handleCarpoolShow}>
        Add Carpool
      </Button> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Roommates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>
              <form class="form-inline my-2 my-lg-0">
                <input
                  class="form-control mr-sm-2"
                  type="search"
                  placeholder="Search members"
                  aria-label="Search"
                />

                <button class="btn btn-outline-info my-2 my-sm-0" type="submit">
                  Search
                </button>
              </form>
            </div>
            <br />

            {user_connections.map((user) => (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name={user.name}
                  id={user._id}
                  value="option1"
                  onChange={onChange}
                />
                <img
                  src={user.image ? user.image.url : imgg}
                  height="75"
                  width="75"
                  alt="user"
                  className="rounded-circle"
                />

                <label class="form-check-label" for={user.id}>
                  {user.name}
                </label>
              </div>
            ))}
            {!user ? (
              <Link to="/login">
                <Button variant="primary">Login to add members</Button>
              </Link>
            ) : (
              <Link to="/upcoming-events">
                <Button
                  variant="primary"
                  onClick={handleCreateTripAndAddRoommates}
                >
                  Save Changes
                </Button>
              </Link>
            )}
          </div>
          {/* <div>
            <form class="form-inline my-2 my-lg-0">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Search members"
                aria-label="Search"
              />

              <button class="btn btn-outline-info my-2 my-sm-0" type="submit">
                Search
              </button>
            </form>
          </div> */}

          <br />
          <hr />
          {/* <MembersToAddToBuild buildId={props.buildId} /> */}
        </Modal.Body>
      </Modal>

      <Modal show={carpoolShow} onHide={handleCarpoolClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Carpool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form class="form-inline my-2 my-lg-0">
              <input
                class="form-control mr-sm-2"
                type="search"
                placeholder="Search members"
                aria-label="Search"
              />
            </form>
          </div>
          <br />
          <hr />
        </Modal.Body>
      </Modal>
      {!isCurrentUser && !showDeleteButton ? (
        <></>
      ) : (
        <Button
          variant="danger"
          onClick={() => {
            unjoinEvent(user?._id, event._id);

            navigate('/user/profile', {
              // state: {
              //   // promotionText: promotionText,
              // },
            });
            window.location.reload();
          }}
        >
          Unjoin Event
        </Button>
      )}

      <h4>Roommates</h4>
      {/* {event_and_roommates.event_id} */}

      {/* removed on 8.17.22 bcuz it looks like it
    has no purpose in events.. only in trips */}
      {/* <div>
        {users_roommates_for_events.map((roommate) => (
          <MyRoommate key={roommate.id} roommate={roommate} event={event} />
        ))}
      </div> */}
      <hr />
      {/* <div>
        {events_with_roommates.roommates_for_event.map((roommates) => (
          <MyEvents
            key={roommates.id}
            roommates={roommates}
          />
        ))}
      </div> */}
    </div>
  );
};

export default MyEvents;
