import React, { useReducer, useContext, useEffect } from 'react';
import axios from 'axios';
import authContext from './authContext';
import authReducer from './authReducer';
import setAuthToken from '../../utils/setAuthToken';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from '../types';

// Create a custom hook to use the auth context

export const useAuth = () => {
  const { state, dispatch } = useContext(authContext);
  return [state, dispatch];
};

// Action creators
// NOTE: These could be moved to a separate file like in redux
// but they remain here for ease of students transitioning

// Load User
export const loadUser = async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get('/api/auth/user');
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

// Register User
export const register = async (dispatch, formData) => {
  try {
    // const res = await axios.post('/api/users', formData);
    const res = await axios.post('/api/newAuth', formData);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    console.log('after dispatch of register');

    loadUser(dispatch);
  } catch (err) {
    dispatch({
      type: REGISTER_FAIL,
      payload: err.response.data.msg,
    });
  }
};

// Login User
export const login = async (dispatch, formData) => {
  try {
    const res = await axios.post('/api/auth', formData);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    loadUser(dispatch);
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: err.response.data.msg,
    });
  }
};

// Logout
export const logout = (dispatch) => {
  console.log('inside authstate');
  dispatch({ type: LOGOUT });
};

// export const logout = (dispatch) => {
//   console.log('logging out ');
// };

// Clear Errors
export const clearErrors = (dispatch) => dispatch({ type: CLEAR_ERRORS });

// AuthState Provider Component

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    loading: true,
    user: {},
    // user: null,
    error: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // const logout = () => {
  //   console.log('inside authstate');
  //   dispatch({ type: LOGOUT });
  // };

  // set token on initial app loading
  setAuthToken(state.token);

  // load user on first run or refresh
  if (state.loading) {
    console.log('state is loading');
    loadUser(dispatch);
  } else {
    console.log('state is not loading');
  }

  // 'watch' state.token and set headers and local storage on any change
  useEffect(
    () => {
      setAuthToken(state.token);
    },
    [state.token]
  );

  return (
    <authContext.Provider value={{ state: state, dispatch }}>
      {props.children}
    </authContext.Provider>
  );
};

export default AuthState;
