import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import EventTypeButton from './EventTypeButton';
import dance_event_icon from '../../images/dance_event_icon.png';
import concert_icon from '../../images/concerts.png';
import guitar_icon from '../../images/guitar3.png';
import festival_icon from '../../images/festival2.png';
import homecoming_icon from '../../images/football.png';
import other_icon from '../../images/other.png';

const EventTypeHorizontalMenu = (props) => {
  let menuItems = [
    'Dance Events',
    'City Festivals',
    'Concerts',
    'Homecoming',
    'events',
    'festivals',
  ];
  let menuItemsAndImages = [
    { type: 'Dance Events', image: dance_event_icon },
    { type: 'City Festivals', image: festival_icon },
    { type: 'Concerts', image: guitar_icon },
    { type: 'Homecoming', image: homecoming_icon },
    { type: 'Other', image: other_icon },
  ];
  let menuImages = [
    dance_event_icon,
    festival_icon,
    guitar_icon,
    homecoming_icon,
    other_icon,
  ];

  // const [eventType, setEventType] = useState('Dance Events');
  const [eventType, setEventType] = useState('Concerts');

  return (
    <div
      class="container-fluid  overflow-auto example card"
      style={{ padding: '0px' }}
    >
      <div class="d-flex flex-row flex-nowrap">
        {/* <div class="d-flex flex-row flex-nowrap" style={{ overflowX: 'hidden' }}> */}

        {/* {props.performance_reviews_for_group_and_event.map( */}
        {menuItemsAndImages.map((item, index) => (
          <EventTypeButton
            item={item}
            // returnButtonClicked={returnButtonClicked}
            // itemClicked={itemClicked}
            // setItemClicked={setItemClicked}
            eventType={eventType}
            setEventType={setEventType}
            getEventsHavingEventType={props.getEventsWithEventType}
            menuImages={menuImages}
            index={index}
          />
        ))}
      </div>

      {/* {props.performance_reviews_for_group_and_event.length > 3 && (
        <h5>scroll right 🙂 {'--->'} </h5>
      )} */}
    </div>
  );
};
export default EventTypeHorizontalMenu;
