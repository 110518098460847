import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import React, { useState } from 'react';
import { useAuth } from '../../../context/auth/AuthState';
import React, { useContext, useEffect, useState } from 'react';
import userContext from '../../../context/user/userContext'; // might have to capitalize
import eventContext from '../../../context/event/eventContext'; // might have to capitalize
import Workshop from './Workshop';
import WorkshopDate from './WorkshopDate';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import LessonPlan from '../../instructor/LessonPlan';

const moment = require('moment');
moment().format();

const AddWorkshopFields = (props) => {
  const { event, current_user } = props;
  const eContext = useContext(eventContext);
  const {
    getArtistsDictionary,
    all_artists_dict,
    createWorkshopScheduleFromScratch,
    getOneEvent,
    event: refetchedEvent,
  } = eContext;

  const { getTakenWorkshopRooms, takenWorkshopRoomsMap } = props;
  const [addLessonPlanSelected, setAddLessonPlanSelected] = useState(false);

  let v_rooms = [];
  useEffect(() => {
    console.log('array range');

    let ignore = false;
    getArtistsDictionary();
    // getTakenWorkshopRooms(event._id).then(

    //     setVacantRooms(filterRoomsByDateAndTime("date_time")) => {}
    // );

    // getTakenWorkshopRooms(props.event._id).then((res) => {
    //   if (!ignore) {
    //     // console.log('filtering once');
    //     // console.log(new Map(Object.entries(takenWorkshopRoomsMap)));
    //     // ignore = true;
    //   }

    // });
    let event_date = moment(event.start_date).format('MM-DD-YYYY');
    let event_start_time = moment(event.start_date).format('HH:mm');
    console.log('event ----');
    console.log(event);
    console.log(`ev date : ${event_date} ---- event time ${event_start_time}`);
    let date_time = workshopDate + ' ' + workshopTime;
    console.log('date_time from eff');
    console.log(date_time);

    console.log('props.event?.maxTrainingRoomsPerDay');
    console.log(props.event?.maxTrainingRoomsPerDay);
    if (!isNotFirstWorkshop) {
      console.log('def first time');
      // let arr = Array.from({ length: maxTrainingRoomsPerDay }, (_, i) => i + 1);
      let arr = Array.from({ length: numberOfTraingRooms }, (_, i) => i + 1);

      console.log('arr');
      console.log(arr);
      setVacantRooms(arr);
    } else {
      setVacantRooms(filterRoomsByDateAndTime(date_time));
    }
    return () => {
      ignore = true;
    };

    // eslint-disable-next-line
  }, []);

  const filterRoomsTaken = (rooms_taken) => {
    let available_rooms = [];
    //pass in total workshop training rooms
    let total_possible_available_rooms = Array.from(
      { length: maxTrainingRoomsPerDay },
      // { length: 6 },
      (_, i) => i + 1
    );
    console.log('total_possible_available_rooms');
    console.log(total_possible_available_rooms);

    console.log('rooms_taken --');
    console.log(rooms_taken);

    console.log('maxTrainingRoomsPerDay from filterRoomsTaken --');
    console.log(maxTrainingRoomsPerDay);

    for (let i = 0; i < total_possible_available_rooms.length; i++) {
      //   for (let room = 0; room < rooms_taken.length; room++) {
      //i needs to be your rooms_taken array

      if (!rooms_taken?.includes(total_possible_available_rooms[i])) {
        console.log(`${total_possible_available_rooms[i]} is available`);
        available_rooms.push(total_possible_available_rooms[i]);
      } else {
        console.log('rooms_taken');
        console.log(rooms_taken);

        console.log(`${total_possible_available_rooms[i]} is not available`);
      }
    }
    return available_rooms;
  };

  //   console.log('filterRoomsTaken()');
  //   console.log(filterRoomsTaken());

  const filterRoomsByDateAndTime = (date_time) => {
    //   const filterRoomsByDateAndTime = (
    //     workshopTimeVar,
    //     workshopTypeVar,
    //     workshopDateVar
    //   ) => {
    // let artists_dict = new Map(Object.entries(all_artists));
    let date_time_dict = new Map(Object.entries(takenWorkshopRoomsMap));
    console.log('date_time_dict ----');
    console.log(date_time_dict);

    //returns an array of rooms_taken
    // let date = moment('2022-09-01T09:39:28.787Z').format('MM-DD-YYYY HH:mm');
    let date = moment(date_time).format('MM-DD-YYYY HH:mm');
    console.log('date ---');
    console.log(date);
    //only has times that have been assigned
    let rooms_taken = date_time_dict.get(date);
    // console.log(`rooms_taken at ---------- ${workshopTimeVar}`);
    console.log(
      'rooms_taken value array comes from the selected date_time ----'
    );
    console.log(rooms_taken);

    let workshops = [];

    //filter rooms_taken
    let vacant_rooms = filterRoomsTaken(rooms_taken);
    console.log('vacant_rooms ---');
    console.log(vacant_rooms);
    //i put this optional here bcuz the useeffect is not setting the vacantRooms
    // properly bcuz the takenWorkshopRoomsMap hasnt yet been set yet.

    //vacantRooms is not updting when a new time is avaialble
    if (rooms_taken?.length > 0) {
      console.log('rooms_taken');
      console.log(rooms_taken);
      setVacantRooms(vacant_rooms);
    }
    if (typeof rooms_taken === 'undefined') {
      console.log('rooms_taken');
      console.log(rooms_taken);
      //vacant rooms should be the total number of training rooms
      setVacantRooms(filterRoomsTaken([0]));
    }

    //if rooms_taken len ===0 then all rooms available for that time
    // if no time has been assigned yet for that date then all rooms available

    console.log('vacant_rooms -----');
    console.log(vacant_rooms);
    return vacant_rooms;
    // for (let i = 0; i < rooms_taken?.length; i++) {
    //   if (rooms_taken[i].workshop_type === workshopTypeVar) {
    //     let date = moment(rooms_taken[i].date);
    //     const workshopOnSelectedDate = moment(workshopDateVar).isSame(date);
    //     // console.log('workshop type salsa');
    //     if (workshopOnSelectedDate) {
    //       workshops.push(rooms_taken[i]);
    //     }
    //   } else {
    //     console.log('! right type');
    //   }
    // }
    // console.log(
    //   `${workshopTypeVar} workshops at ${workshopTimeVar} -- renderWorkshopsByTimeAndStyle `
    // );
    // console.log(workshops);
    // return workshops;
  };
  let event_date = moment(event.start_date).format('MM-DD-YYYY');
  let event_start_time = moment(event.start_date).format('HH:mm');

  const [vacantRooms, setVacantRooms] = useState([0]);

  //   filterRoomsByDateAndTime();
  let [selectedArtist, setSelectedArtist] = useState('');
  const [artistType, setArtistType] = useState('bachata');
  const [instructorName, setInstructorName] = useState('');
  const [instructorImg, setInstructorImg] = useState('');

  const [numberOfTraingRooms, setNumberOfTraingRooms] = useState(2);
  let totalPossibleRoomNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const handleNumberOfTraingRoomsChange = (e) => {
    setNumberOfTraingRooms(e);

    //array
    let arr = Array.from({ length: e }, (_, i) => i + 1);

    setVacantRooms(arr);
    setDetails({
      ...details,
      ['maxTrainingRoomsPerDay']: e,
    });
  };

  const [details, setDetails] = useState({
    date: moment(event.start_date)
      .zone(0)
      .format('MM-DD-YYYY'),
    time: '09:00',
    location: vacantRooms[0] ? vacantRooms[0] : '',
    title: '',
    level: 'Beginner',
    artist_id: selectedArtist,
    instructor_only_id: '',
    event_id: props.event._id,
    workshop_type: artistType,
    instructor_name: instructorName,
    instructor_image: '',
    maxTrainingRoomsPerDay: props.event?.maxTrainingRoomsPerDay
      ? props.event.maxTrainingRoomsPerDay
      : numberOfTraingRooms,
    maxTrainingHoursPerDay: '',
  });

  const {
    title,
    time,
    location,
    level,
    artist_id,
    instructor_only_id,
    instructor_image,
    maxTrainingRoomsPerDay,
    maxTrainingHoursPerDay,
  } = details;

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });

    console.log(level);
    console.log('details');
    console.log(details);
  };
  const onTitleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log(e);
  };
  //level button
  const [levelButtonClicked, setLevelButtonClicked] = useState(false);
  const [levelTitle, setLevelTitle] = useState('Beginner');
  // const [level, setLevel] = useState('');

  const handleSelectLevel = (e) => {
    // setLevel(e);
    setDetails({ ...details, ['level']: e });
    console.log('details is');
    console.log(details);
    console.log(e);
    setLevelTitle(e);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('details from submit');
    console.log(details);
    //commented both on j.16.24 -- not sure we need getoneevent..we might have used that to
    //get the updated event after the schedule operation runs

    createWorkshopScheduleFromScratch(details);

    // getOneEvent(event._id);

    //createWorkshop(details)
    //getEvent or could we update the event in the reducer??
    //write parent function to refetch the event when workshop submitted?
    //props.refetchEvent()
    //call it here
    //this issue is that the event we're loading in here from props
    //is different than the new event from getoneevent
    //also we wont be able to have both of these event
  };
  let times_array = [
    '05:00',
    '05:30',
    '06:00',
    '07:00',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '12:45',
    '13:00',
    '13:30',
    '13:45',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
  ];
  let vacant_rooms_array = ['1', '2', '3'];
  //   const [vacantRooms, setVacantRooms] = useState(vacantRooms);

  const [workshopRoom, setWorkshopRoom] = useState('1');

  const handleRoomChange = (room) => {
    console.log('changed room');
    console.log(room);
    setWorkshopRoom(room);
    setDetails({ ...details, ['location']: room });
  };
  //   let event_start_time = moment(event.start_time).format('HH:mm');
  //   let event_start_date = moment(event.start_time).format('HH:mm');

  const [workshopTime, setWorkshopTime] = useState('09:00');
  // const [workshopDate, setWorkshopDate] = useState(event.start_date);
  const handleTimeChange = (time) => {
    console.log('changed time');
    console.log(time);
    setWorkshopTime(time);
    setDetails({ ...details, ['time']: time });

    let date_time = workshopDate + ' ' + time;
    filterRoomsByDateAndTime(date_time);
  };

  const [workshopDate, setWorkshopDate] = useState(
    moment(event.start_date)
      .zone(0)
      .format('MM-DD-YYYY')
  );

  const handleDateChange = (date) => {
    console.log('date from handleDateChange');
    console.log(date);
    let formattedDate = moment(date).format('MM-DD-YYYY');

    console.log('formattedDate');
    console.log(formattedDate);
    setWorkshopDate(formattedDate);
    setDetails({ ...details, ['date']: formattedDate });
    //refilter rooms
    let date_time = formattedDate + ' ' + workshopTime;

    // setWorkshopDate(date);
    // setDetails({ ...details, ['date']: date });
    // //refilter rooms
    // let date_time = date + ' ' + workshopTime;

    filterRoomsByDateAndTime(date_time);
  };

  //   let event = { start_date: '09-02-2022', end_date: '09-05-2022' };

  let start_date = moment(event?.start_date).zone(0);
  let end_date = moment(event?.end_date).zone(0);

  let workshop_date_array = [];
  let d = Math.abs(moment.duration(start_date.diff(end_date)).asDays());
  console.log('days long:');
  console.log(d);

  for (let day = 0; day < d + 1; day++) {
    // start_date.add(day, 'days');
    let s = moment(start_date)
      .zone(0)
      .add(day, 'days');
    console.log(s);
    workshop_date_array.push(moment(s).format('dddd MM-DD-YYYY'));
  }
  //   filterRoomsByDateAndTime();
  let isNotFirstWorkshop = event?.workshops && event?.workshops.length > 0;
  console.log('isNotFirstWorkshop -----');
  console.log(isNotFirstWorkshop);
  const [showWorkshopFields, setShowWorkshopFields] = useState(
    isNotFirstWorkshop
  );

  const handleNextClicked = (e) => {
    console.log('next clicked');
    setShowWorkshopFields(true);
  };

  const [numberOfTraingHours, setNumberOfTraingHours] = useState(8);
  let maxTrainingHoursPerDayList = [...Array(25).keys()];

  const handleMaxTrainingChange = (e) => {
    setNumberOfTraingHours(e);
    setDetails({
      ...details,
      ['maxTrainingHoursPerDay']: e,
    });
  };

  //   const [artistType, setArtistType] = useState('bachata');
  var isAllCheck = false;
  const handlePillClicked = (type) => {
    setArtistType(type);

    setDetails({
      ...details,
      ['workshop_type']: type,
    });
    document
      .querySelectorAll('input[type=radio]')
      .forEach((el) => (el.checked = isAllCheck));
  };

  //dynamic buttons
  const renderSalsaButton = () => {
    if (artistType === 'salsa') {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          salsa
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          salsa
        </Button>
      );
    }
  };
  const renderBachataButton = () => {
    if (artistType === 'bachata') {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          bachata
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          bachata
        </Button>
      );
    }
  };

  const renderKizombaButton = () => {
    if (artistType === 'kizomba') {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          kizomba
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          kizomba
        </Button>
      );
    }
  };

  const renderZoukButton = () => {
    if (artistType === 'zouk') {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          zouk
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          zouk
        </Button>
      );
    }
  };
  const renderArtistsDynamically = (artistTypeVar) => {
    // let artists_dict = new Map(Object.entries(all_artists));

    let artists_dict = new Map(Object.entries(all_artists_dict));

    console.log('all_artists_dict ---- ');
    console.log(all_artists_dict);
    console.log('artists_dict -----');
    console.log(artists_dict);

    return artists_dict.get(artistTypeVar);
  };

  //   let lineup_array = []; //just [] of ids
  const onLineupChange = (e) => {
    let lineup_array = []; //just [] of ids
    // console.log('attr');
    // console.log(attr);
    console.log('changing lineup list -- e is:');
    // console.log(e.target);
    // setMemberArray(...lineup_array, e.target.name);
    // console.log(lineup_array);
    console.log('e.target is ---');
    console.log(e.target);

    console.log('e.target.image_url is ---');
    console.log(e.target?.image_url);

    if (lineup_array.includes(e.target.id)) {
      console.log('removing user');
      lineup_array = lineup_array.filter((item) => item !== e.target.id);
    } else {
      console.log('adding user');
      lineup_array.push(e.target.id);
    }
    // const member_set = new Set(lineup_array);
    // const uniqueMembers = [...member_set];
    setSelectedArtist(e.target.id); // prob unnecessary
    setDetails({
      ...details,
      ['artist_id']: e.target.id,
      ['instructor_image']: 'hi',
      ['instructor_name']: e.target.value,
    });
    // console.log('e.target');
    // console.log(e.target);

    // console.log('user');
    // console.log(user);

    console.log('selectedArtist');
    console.log(selectedArtist);
    console.log(lineup_array);
    console.log('details ---');
    console.log(details);
  };

  //   let lineup_array = []; //just [] of ids
  const onLineupChange2 = (user) => {
    let lineup_array = []; //just [] of ids
    // console.log('attr');
    // console.log(attr);
    // console.log('changing lineup list -- e is:');
    // console.log(e.target);
    // setMemberArray(...lineup_array, e.target.name);
    // console.log(lineup_array);
    // console.log('e.target is ---');
    // console.log(e.target);

    // console.log('e.target.image_url is ---');
    // console.log(e.target?.image_url);

    if (lineup_array.includes(user._id)) {
      console.log('removing user');
      lineup_array = lineup_array.filter((item) => item !== user._id);
    } else {
      console.log('adding user');
      lineup_array.push(user._id);
    }
    // const member_set = new Set(lineup_array);
    // const uniqueMembers = [...member_set];
    setSelectedArtist(user._id); // prob unnecessary
    setDetails({
      ...details,
      ['artist_id']: user._id,
      ['instructor_image']: user?.image_url?.url,
      ['instructor_name']: user.name,
    });
    // console.log('e.target');
    // console.log(e.target);

    // console.log('user');
    // console.log(user);

    console.log('selectedArtist');
    console.log(selectedArtist);
    console.log(lineup_array);
    console.log('details ---');
    console.log(details);
  };

  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const [
    dancerAddedSelfAsInstructor,
    setDancerAddedSelfAsInstructor,
  ] = useState(false);

  const userAddedSelfAsInstructor = () => {
    console.log('current_user from self add');
    console.log(current_user);
    if (current_user?.artist_id && current_user?.artist_id !== '') {
      setDetails({
        ...details,
        // artist_id: current_user?._id,
        artist_id: current_user?.artist_id,
        // instructor_only_id: current_user?._id,
        instructor_image: current_user?.image?.url,
        // instructor_image: current_user?.image_url?.url,
        instructor_name: current_user.name,
      });
    } else {
      setDetails({
        ...details,
        // ['artist_id']: user._id,
        instructor_only_id: current_user?._id,
        instructor_image: current_user?.image?.url,
        instructor_name: current_user.name,
      });
    }
  };
  return (
    <div>
      <div className="form-container">
        {/* <form> */}

        {isNotFirstWorkshop ? (
          <></>
        ) : (
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <h5>
                Enter the number of training rooms and training times per day
              </h5>
              <br />
              <div className="row">
                <div className="col">
                  <label>Max number of training rooms</label>
                </div>
                <div className="col">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      as={ButtonGroup}
                      key={idx}
                      id={`dropdown-button-drop-${idx}`}
                      size="sm"
                      variant="secondary"
                      title={numberOfTraingRooms}
                      onSelect={handleNumberOfTraingRoomsChange}

                      // onClick={() => {
                      //   setLevelButtonClicked(!levelButtonClicked);
                      // }}
                      // onSelect={handleSelectLevel}
                    >
                      {totalPossibleRoomNumber.map((training_room) => (
                        <Dropdown.Item eventKey={training_room}>
                          {training_room}
                        </Dropdown.Item>
                      ))}
                    </DropdownType>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>maximum number of training hours each day</label>
                </div>

                <div className="col">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      as={ButtonGroup}
                      key={idx}
                      id={`dropdown-button-drop-${idx}`}
                      size="sm"
                      variant="secondary"
                      title={numberOfTraingHours}
                      onSelect={handleMaxTrainingChange}

                      // onClick={() => {
                      //   setLevelButtonClicked(!levelButtonClicked);
                      // }}
                      // onSelect={handleSelectLevel}
                    >
                      {maxTrainingHoursPerDayList.map((hours) => (
                        <Dropdown.Item eventKey={hours}>{hours}</Dropdown.Item>
                      ))}
                    </DropdownType>
                  ))}
                </div>
              </div>

              <Button onClick={handleNextClicked}>
                {/* <Button onClick={handleNextClicked} disabled={reqInfoInput}> */}
                Next
              </Button>
            </div>
          </form>
        )}
        {showWorkshopFields ? (
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <div className="row" style={{ marginBottom: '14px' }}>
                <div className="col">
                  <Button
                    onClick={() => {
                      setDancerAddedSelfAsInstructor(
                        !dancerAddedSelfAsInstructor
                      );
                      // onLineupChange2(current_user);

                      userAddedSelfAsInstructor();
                    }}
                    style={{
                      background:
                        dancerAddedSelfAsInstructor === true
                          ? `rgb(1, 126, 252)`
                          : `white`,
                      // color: 'blue',
                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',

                      fontSize: '14px',
                      backgroundColor:
                        dancerAddedSelfAsInstructor === true
                          ? `rgb(1, 126, 252)`
                          : `white`,
                      color:
                        dancerAddedSelfAsInstructor === true
                          ? `white`
                          : `rgb(1, 126, 252)`,
                      textAlign: 'center',
                      padding: '0px 12px',
                      width: 'max-content',
                      borderWidth: '1px',
                    }}
                  >
                    {dancerAddedSelfAsInstructor
                      ? `Added`
                      : `Add Yourself as Instructor`}
                  </Button>
                </div>

                {dancerAddedSelfAsInstructor && (
                  <div className="col">
                    <Button
                      onClick={() => {
                        setDancerAddedSelfAsInstructor(
                          !dancerAddedSelfAsInstructor
                        );
                      }}
                      style={{
                        background: 'white',
                        // color: 'blue',
                        borderStyle: 'solid',
                        borderColor: 'rgb(1, 126, 252)',

                        fontSize: '14px',
                        backgroundColor: 'white',
                        color: 'rgb(1, 126, 252)',
                        textAlign: 'center',
                        padding: '0px 12px',
                        width: 'max-content',
                        borderWidth: '1px',
                      }}
                    >
                      Search Instructors
                    </Button>
                  </div>
                )}
              </div>

              {!dancerAddedSelfAsInstructor && (
                <div>
                  <div
                    className="row"
                    style={{ marginTop: '14px', marginBottom: '14px' }}
                  >
                    {renderSalsaButton()}
                    {renderBachataButton()}
                    {renderKizombaButton()}
                    {renderZoukButton()}
                  </div>
                  <div>
                    {/* <form className="form-inline my-2 my-lg-0">
                  <div className="row">
                    <div className="col">
                      <input
                        id="searchText"
                        class="form-control mr-sm-2"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        name="searchText"
                        
                      />
                    </div>
                    <div className="col-2" style={{ margin: 'auto' }}>
                      <button
                        class="btn btn-primary my-2 my-sm-0"
                        type="submit"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </form> */}
                  </div>
                  <div className="vertical-artist-container">
                    <div className="col">
                      {/* <div> */}
                      {renderArtistsDynamically(artistType)?.map((user) => (
                        // {renderArtists().map((user) => (
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            // type="checkbox"
                            type="radio"
                            // name={user.name}
                            name="radio_button"
                            // id={user._id}
                            id={user._id}
                            value={user.name}
                            image_url={user?.image_url?.url}
                            // onChange={onLineupChange}
                            onChange={() => {
                              onLineupChange2(user);
                              // setDetails({
                              //   ...details,
                              //   // ['artist_id']: e.target.id,
                              //   // ['instructor_name']: e.target.value,
                              //   ['instructor_image']: user.image_url.url,
                              // });
                            }}
                            onClick={() => {
                              // setInstructorName(user.name);
                              // setInstructorImg(user.image_url.url);
                              console.log('user');
                              console.log(user);
                              // setDetails({
                              //   ...details,
                              //   ['artist_id']: user._id,
                              //   // ['instructor_name']: user.name,
                              // });
                            }}
                            required
                          />
                          {/* <img
                    src={user.image ? user?.image.url : imgg}
                    height="100"
                    alt="user"
                  /> */}
                          <img
                            src={
                              user.image ? user?.image : user?.image_url?.url
                            }
                            height="100"
                            width="100"
                            alt="user"
                            style={{ borderRadius: '0.25rem' }}
                          />

                          <label class="form-check-label" for={user._id}>
                            {user.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <input
                id="title"
                type="text"
                name="title"
                placeholder="Workshop Title"
                value={title}
                onChange={onChange}
                // onChange={onTitleChange}
                required
              />
              {/* <input
              id="level"
              type="text"
              name="level"
              placeholder="Workshop Level"
              value={level}
              onChange={onChange}
              required
            /> */}
              <hr />
              <div className="card">
                <Button
                  onClick={() =>
                    setAddLessonPlanSelected(!addLessonPlanSelected)
                  }
                >
                  {addLessonPlanSelected ? `Cancel` : `Add Lesson Plan`}
                </Button>

                {addLessonPlanSelected ? (
                  <div>
                    <LessonPlan
                      addingWorkshop={true}
                      details={details}
                      setDetails={setDetails}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col">
                <div className="row">
                  <div className="col">
                    <label>Level</label>
                  </div>

                  <div className="col">
                    {[DropdownButton].map((DropdownType, idx) => (
                      <DropdownType
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop-${idx}`}
                        size="sm"
                        variant="secondary"
                        // title={workshop?.level ? workshop.level : 'select a level'}
                        title={levelTitle}
                        onClick={() => {
                          setLevelButtonClicked(true);
                        }}
                        // onChange={onChange}
                        onSelect={handleSelectLevel}
                        // name="level"
                      >
                        <Dropdown.Item eventKey="Beginner">
                          Beginner
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Intermediate">
                          Intermediate
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="Advanced">
                          Advanced
                        </Dropdown.Item>
                        {/* <Dropdown.Divider />
              <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
                      </DropdownType>
                    ))}
                  </div>
                </div>

                {/* <div className="col">
                <WorkshopDate onChangeValue={handleDateChange} event={event} />
              </div> */}

                <div className="row">
                  <div className="col">
                    <label>Date</label>
                  </div>
                  <div className="col">
                    {[DropdownButton].map((DropdownType, idx) => (
                      <DropdownType
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop-${idx}`}
                        size="sm"
                        variant="secondary"
                        title={workshopDate}
                        onSelect={handleDateChange}

                        // onClick={() => {
                        //   setLevelButtonClicked(!levelButtonClicked);
                        // }}
                        // onSelect={handleSelectLevel}
                      >
                        {workshop_date_array.map((date) => (
                          <Dropdown.Item eventKey={date}>{date}</Dropdown.Item>
                        ))}
                      </DropdownType>
                    ))}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <label>Workshop Time</label>
                  </div>

                  <div className="col">
                    {[DropdownButton].map((DropdownType, idx) => (
                      <DropdownType
                        as={ButtonGroup}
                        key={idx}
                        id={`dropdown-button-drop-${idx}`}
                        size="sm"
                        variant="secondary"
                        title={workshopTime}
                        onSelect={handleTimeChange}
                        // disabled="true"

                        // onClick={() => {
                        //   setLevelButtonClicked(!levelButtonClicked);
                        // }}
                        //   onSelect={handleSelectLevel}
                      >
                        {times_array.map((time) => (
                          <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>
                        ))}
                      </DropdownType>
                    ))}
                  </div>
                </div>
                {!vacantRooms.length > 0 ? (
                  <div className="col">
                    <label style={{ color: 'red' }}>
                      no rooms available for this date and time
                    </label>
                  </div>
                ) : (
                  <></>
                )}
                {vacantRooms.length > 0 ? (
                  <div className="col">
                    <label style={{ color: 'green' }}>
                      {vacantRooms.length} rooms available for this date and
                      time
                    </label>
                  </div>
                ) : (
                  <></>
                )}
                {location === '' ? (
                  <div className="col">
                    <label style={{ color: 'red' }}>select a room</label>
                  </div>
                ) : (
                  <></>
                )}
                <div className="row">
                  <div className="col">
                    <label>Room</label>
                  </div>
                  <div className="col">
                    <div>
                      {[DropdownButton].map((DropdownType, idx) => (
                        <DropdownType
                          as={ButtonGroup}
                          key={idx}
                          id={`dropdown-button-drop-${idx}`}
                          size="sm"
                          variant="secondary"
                          title={
                            !vacantRooms.length > 0
                              ? 'no rooms for this date and time'
                              : location
                          }
                          //   disabled={vacantRooms.length > 0}
                          //   title={
                          //     vacantRooms.length > 0
                          //       ? location[0]
                          //       : 'no vacant rooms for this time and date'
                          //   }
                          //what happens if
                          onSelect={handleRoomChange}

                          // onClick={() => {
                          //   setLevelButtonClicked(!levelButtonClicked);
                          // }}
                          // onSelect={handleSelectLevel}
                        >
                          {/* {vacant_rooms_array.map((room) => ( */}
                          {vacantRooms.map((room) => (
                            <Dropdown.Item eventKey={room}>
                              {room}
                            </Dropdown.Item>
                          ))}
                        </DropdownType>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <input
              type="submit"
              value="Add Workshop"
              disabled={
                vacantRooms.length === 0 ||
                location === '' ||
                (artist_id === '' && instructor_only_id === '')
              }
              class="btn btn-secondary"
            />
          </form>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AddWorkshopFields;
