import React, { Fragment, useContext, useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import WorkshopAttendees from './WorkshopAttendees';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/auth/AuthState';
import eventContext from '../context/event/eventContext'; // might have to capitalize
import LessonPlan from './instructor/LessonPlan';
import EditWorkshopLessonPlan from './instructor/EditWorkshopLessonPlan';

const WorkshopDetailPopup = (props) => {
  const [authState] = useAuth();
  const { user } = authState;

  const eContext = useContext(eventContext);
  const {
    updateWorkshop,
    joinWorkshop,
    workshop_attendees,
    getWorkshopAttendees,
    getWorkshopSchedule,
    // getWorkshop
  } = eContext;
  const {
    workshop,
    event,
    attendee_count,
    workshopJoined,
    showAttendees,
    alreadyJoined,
    // showTime,
    showJoinedButton,
  } = props;

  const returnInstructorId = (workshop) => {
    if (workshop?.instructor) return workshop?.instructor;
    if (workshop?.instructor_only_id) return workshop?.instructor_only_id;
  };

  let updatedWorkshop = workshop;
  const [levelButtonClicked, setLevelButtonClicked] = useState(false);
  const [roomButtonClicked, setRoomButtonClicked] = useState(false);
  const [workshopTitleButtonClicked, setWorkshopTitleButtonClicked] = useState(
    false
  );
  const [show, setShow] = useState(props.workshopClicked);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log('props.workshop -- attendees??');
  console.log(props.workshop);

  const [disabled, setDisabled] = useState(false);
  const [joinButtonStyle, setjoinButtonStyle] = useState('');
  const [joinButtonTitle, setJoinButtonTitle] = useState('Join');
  const [editWorkshopClicked, setEditWorkshopClicked] = useState(false);
  let [attendees, setAttendees] = useState(props.workshop?.attendees.length);

  let navigate = useNavigate();
  const handleJoinWorkshop = () => {
    // workshopJoined();
    if (!user) {
      navigate('/login');
    } else {
      let obj = { workshop_id: workshop._id, user_id: user._id };
      joinWorkshop(obj);
      //   setAttendees(workshop_attendees); //after we join, we request workshop_attendees
      setAttendees(attendees + 1);
      console.log('attendees is');
      console.log(attendee_count);
      setDisabled(true); //?
      setJoinButtonTitle('Joined');
      setjoinButtonStyle('bg-primary');
      setJoined(true);
      props.onJoin();
      // getWorkshopSchedule(event._id);
      // getWorkshopAttendees(workshop._id);
      // workshop.attendees = workshop_attendees;
    }
  };

  const joinButton = (
    <Fragment>
      {alreadyJoined === true ? (
        <Button className="bg-primary">Joined</Button>
      ) : (
        <Button
          onClick={handleJoinWorkshop}
          disabled={disabled}
          className={joinButtonStyle}
          style={{
            width: '80px',
            background: 'white',

            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
            borderWidth: '1px',
            borderRadius: '2px',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            textAlign: 'center',
          }}
        >
          {joinButtonTitle}
        </Button>
      )}
    </Fragment>
  );
  const renderJoinButton = () => {
    if (showJoinedButton === true) {
      console.log('show button true');

      //regular render
      return joinButton;
    } else {
      console.log('showJoinedButton should be false');
      console.log(showJoinedButton);

      return <></>;
    }
  };
  const onSubmit = (e) => {
    e.preventDefault();
    console.log(e);
  };
  const [joined, setJoined] = useState(false);

  let [user2, setUser2] = useState({
    name2: 'hi',
  });
  const onChange = (e) => {
    console.log('click');
    setUser2({ ...user2, [e.target.name]: e.target.value });
    console.log(e.target.value);
    updatedWorkshop.title = e.target.value;
  };
  const setNewMoves = (e) => {
    console.log('click');
    console.log(e.target.value);

    //its an array....
    updatedWorkshop.lessonPlan = e.target.value;
  };

  const showEditButtons = (
    <Fragment>
      <div>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            {/* <label htmlFor="name2">Name--</label> */}
            <input
              id="name2"
              type="text"
              name="name2"
              value={workshop?.title ? workshop.title : ''}
              onChange={onChange}
              placeholder={workshop?.title ? workshop.title : ''}
              required
            />
          </div>

          <div>
            <EditWorkshopLessonPlan
              alreadySelectedMoves={
                workshop?.lessonPlan && workshop?.lessonPlan?.length > 0
                  ? workshop.lessonPlan
                  : []
              }
              setNewMoves={setNewMoves}
              updatedWorkshop={updatedWorkshop}
              // setDetails={setDetails}
              //old
              // details={details}
              // setDetails={setDetails}
            />
          </div>

          {/* <input
            type="submit"
            value="Save"
            className="btn btn-primary btn-block"
          /> */}
        </form>
      </div>
      <div className="row">
        <div className="col">
          <Button
            className="btn-cancel"
            style={{
              bottom: '10px',
              position: 'absolute',
            }}
            onClick={() => {
              //   h = '150px';
              // console.log(h);
              setLevelButtonClicked(false);
              setRoomButtonClicked(false);
              setWorkshopTitleButtonClicked(false);
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="col">
          <Button
            className="btn-save"
            style={{
              bottom: '10px',
              position: 'absolute',
            }}
            onClick={() => {
              //   h = '150px';
              console.log('updatedWorkshop ----');
              console.log(updatedWorkshop);
              setLevelButtonClicked(false);
              setRoomButtonClicked(false);
              setWorkshopTitleButtonClicked(false);

              console.log(' updated workshop');
              console.log(workshop);
              updateWorkshop(workshop);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Fragment>
  );

  const isHost = () => {
    return event?.hostId === user?._id;
  };
  const instructorIsTheCurrentlySignedInUser = () => {
    if (
      returnInstructorId(workshop) === user?._id ||
      returnInstructorId(workshop) === user?.artist_id
    ) {
      console.log('instructorIsTheCurrentlySignedInUser');
      return true;
    } else {
      console.log('user?._id');
      console.log(user?._id);
      console.log('workshop?.instructor');
      console.log(workshop?.instructor);
      console.log('workshop?.instructor_only_id');
      console.log(workshop?.instructor_only_id);
      // console.log(workshop?.instructor)
    }
    return false;
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      {/* <Modal show={show} onHide={handleClose}> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.workshop.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{props.workshop.title}</Form.Label>

              <div className="row">
                <div className="col-4">
                  <h5>instructor:</h5>
                </div>
                <div className="col">
                  <Link
                    to={
                      props?.workshop?.instructor
                        ? `/artist-profile/${props.workshop.instructor}`
                        : `/user/profile/${props.workshop.instructor_only_id}`
                    }
                    style={{ color: 'rgb(1, 126, 252)' }}
                  >
                    <h5>{props.workshop.instructor_name}</h5>
                  </Link>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">{renderJoinButton()}</div>

                {isHost() === true ||
                user?.email === 'airmeus.team@gmail.com' ||
                instructorIsTheCurrentlySignedInUser() ? (
                  <div className="col">
                    <Button
                      onClick={() =>
                        setEditWorkshopClicked(!editWorkshopClicked)
                      }
                    >
                      Edit
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div>
                {' '}
                {/* {showEditButtons} */}
                {editWorkshopClicked ? showEditButtons : <></>}
                {/* {roomButtonClicked ||
                levelButtonClicked ||
                workshopTitleButtonClicked ? (
                  showEditButtons
                ) : (
                  <></>
                )} */}
              </div>
              <br />
              <div>
                {' '}
                {workshop?.lessonPlan && (
                  <div>
                    <h1>Lesson Plan</h1>
                  </div>
                )}
                {workshop?.lessonPlan?.map((move) => (
                  <div>
                    <h5>{move}</h5>
                  </div>
                ))}
              </div>
              <hr />
              <WorkshopAttendees
                workshopAttendees={props.workshop?.attendees}
              />
              {/* <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
              /> */}
            </Form.Group>
            {/* <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Example textarea</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group> */}
          </Form>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default WorkshopDetailPopup;
