// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
// import { withRouter } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import Roommate_Request from './Roommate_Request';
import { useAuth, clearErrors, login } from '../../context/auth/AuthState';
import { useState } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

// import EventContext from '../../context/event/eventContext';
// import { useHistory } from 'react-router-dom';

const Roommates = (props) => {
  // const [authState, authDispatch] = useAuth();
  // const { error, isAuthenticated } = authState;
  const [authState] = useAuth();

  const { user } = authState;

  const eventContext = useContext(EventContext);

  const {
    getRoommateRequestsForEvent,
    roommate_requests,
    requestRoommate,
    joinEvent,
  } = eventContext;
  const { event } = props;

  //   const eventContext = useContext(EventContext);

  useEffect(() => {
    console.log('getting events from useEffect');
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    //get the users who requested this event ?
    getRoommateRequestsForEvent(event._id);
    console.log('roommate reqs');
    console.log(roommate_requests);

    let RRA = false;

    for (let i = 0; i < roommate_requests.length; i++) {
      if (roommate_requests[i].requestor === user?._id) {
        // setRoommateRequestButtonTitle('')
        console.log('roommate_requests[i].requestor ---');
        console.log(roommate_requests[i].requestor);
        RRA = true;
      }
    }
    // eslint-disable-next-line
  }, []);

  const roommateAlreadyRequested = () => {
    for (let i = 0; i < roommate_requests.length; i++) {
      if (roommate_requests[i].requestor === user._id) {
        // setRoommateRequestButtonTitle('')
        console.log('roommate_requests[i].requestor ---');
        console.log(roommate_requests[i].requestor);
        return true;
      }
    }
    return false;
  };

  //   const onPass = () => {
  //     console.log('passed on user');
  //     // passOnUser(user._id);
  //     passOnUser(user);
  //   };

  // const [visible, setVisible] = useState(false); // visibility state
  const [visible, setVisible] = React.useState(false);
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [details, setDetails] = useState({
    descr: '',
  });
  const { descr } = details;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (details === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      // login(authDispatch, {
      //   descr,
      // });
      console.log('roomie req sent');
    }
  };

  const onSubmit2 = (e) => {
    e.preventDefault();
    if (descr === '') {
      setAlert('Please fill in all fields', 'danger');
    } else {
      // login(authDispatch, {
      //   descr,
      // });
      // request_roommate();
      requestRoommate(user._id, event._id, descr);
      //join event after roommate is requested -- we can also do this in the requestroommate function on the backend
      joinEvent(user._id, event._id);
      console.log('roomie req sent');
    }
  };

  const nouser = (
    <Fragment>
      <Link to="/login">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          sign in to request a roommate
        </button>
      </Link>
    </Fragment>
  );

  const RR = (
    <Fragment>
      <form onSubmit={onSubmit2}>
        <div className="form-group">
          <label htmlFor="descr">Details</label>
          <input
            id="descr"
            type="text"
            name="descr"
            // placeholder="tell us what you need"
            value={descr}
            onChange={onChange}
            required
          />
        </div>

        <input
          type="submit"
          value="Submit Request"
          // className="btn btn-primary btn-block"
          class="btn btn-secondary"
          // onClick={onSubmit2}
        />
      </form>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!user) {
      console.log('no user found');
      return nouser;
    }
    return RR;
  };

  return (
    // <div className="container">
    <div>
      {props?.back_button_active && (
        <Button
          onClick={() => {
            // selectedChat = '';
            console.log('selectedChat is:');
            // console.log(selectedChat);
            props.setShowChatsFromChild(true);
          }}
        >
          Back
        </Button>
      )}
      <div>
        {/* {roommateAlreadyRequested === true ? (
          <label>you've already requested a roommate</label>
        ) : (
          <button
            onClick={() => setVisible(!visible)}
            class="btn btn-secondary"
          >
            {visible ? 'Close' : 'Roommate Request'}
          </button>
        )} */}
        <button onClick={() => setVisible(!visible)} class="btn btn-secondary">
          {visible ? 'Close' : 'Roommate Request'}
        </button>
        {visible && (
          <div className="form-container">
            <h1>
              {/* Request <span className="text-primary">Details</span> */}
            </h1>
            {renderOptions()}
          </div>
        )}
      </div>

      {/* <div className="card bg-light"> */}
      {/* <div className="bg-dark p"> */}
      {/*  call getRoommateRequestsForEvent(event._id) */}
      {/* <Button /> */}
      {/* <button>push</button> */}
      {/* <Link to={`/event/${props.event._id}`} state={{ event: event }}>
            Event Details
          </Link> */}

      <div class="d-flex flex-row flex-nowrap">
        {roommate_requests.map((roomie_request) => (
          <div className="card-horizontal-menu card-block mx-2">
            <Roommate_Request
              key={roomie_request.id}
              roomie_request={roomie_request}
            />
          </div>
        ))}
      </div>
      {/* </div> */}
    </div>
    // </div>
  );
};
export default Roommates;

/*
unneeded helper



<button
        type="button"
        class="btn btn-secondary"
        onClick={request_roommate}
      >
        New Roommate Request
      </button>


      

  //helper for submit button
  const request_roommate = () => {
    // clearCurrent(contactDispatch);
    console.log('requesting roommate from helper function');
    console.log('user._id');
    console.log(user._id);
    //joinEvent(current_user_id, event._id)
    requestRoommate(user._id, event._id, descr);
  };
*/
