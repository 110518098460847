import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
import CheckoutForm from '../../advertise/CheckoutForm';
import Layout from '../../advertise/Layout';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../context/auth/AuthState';
import { Button } from 'react-bootstrap';

import giphy from '../../../../src/images/giphy.gif';
import ArtistSubscriptionCheckoutForm from '../../advertise/ArtistSubscriptionCheckoutForm';
import ArtistContext from '../../../context/artist/artistContext';

// import StudioContext from '../../context/studio/studioContext';

const GoProArtistPayment = (props) => {
  const artistContext = useContext(ArtistContext);
  const { convertAccountToPremiumArtistSubscription } = artistContext;
  const [authState] = useAuth();

  const { user } = authState;
  let navigate = useNavigate();
  const { artist_id } = useParams();

  // const studioContext = useContext(StudioContext);
  // const {
  // convertAccountToPremiumSubscription,
  // premium_subscriber,
  // } = studioContext;

  console.log('artist id is:');
  console.log(artist_id);
  return (
    <div>
      <h1
        className="all-center"
        style={{
          fontSize: '30px',
          fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',

          paddingBottom: '10px',
          paddingTop: '10px',
          color: 'rgb(1, 126, 252)',
        }}
      >
        Premium Artist
        {/* <span className="text-primary">Premium Events</span> */}
      </h1>

      <div
        className="smallScreenText"
        style={{
          backgroundColor: '',

          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        {/* <h4>What you can expect</h4> */}

        {/* <h3
            style={{
              color: 'rgb(1, 126, 252)',
            }}
          >
            Super Power Your Event 🚀🚀🚀
          </h3> */}
        <div className="row" style={{ paddingBottom: '30px' }}>
          <div className="">
            <h1
              style={{
                fontSize: '30px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
              }}
            >
              Private Lesson Booking
            </h1>
            <h3
              style={{
                fontSize: '18px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
                fontWeight: '300',
                lineHeight: '1.5',
              }}
            >
              You can be booked for private lessons directly from all event
              pages and from your profile
            </h3>
            {/* <div>
                <img
                  src={giphy}
                  alt="Computer man"
                  style={{ width: 300, height: 500 }}
                />
              </div> */}
            <h1
              style={{
                fontSize: '30px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
              }}
            >
              Interactive Workshop Schedule
            </h1>
            <h3
              style={{
                fontSize: '18px',
                fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',
                fontWeight: '300',
                lineHeight: '1.5',
              }}
            >
              List your event workshops on the event page, even if the event is
              not premium{' '}
            </h3>
          </div>

          {/* <Button
              style={{
                width: 'fit-content',
                backgroundColor: 'rgb(1, 126, 252)',
              }}
            >
              Create Your Event
            </Button> */}
        </div>
      </div>
      <h3
        className="all-center"
        style={{
          // color: 'rgb(1, 126, 252)',
          color: '#0062ff',
          backgroundColor: 'white',
          fontSize: '40px',
          fontFamily: '"sohne-var","Helvetica Neue","Arial",sans-serif',

          borderRadius: '5px',

          // width:
        }}
      >
        Super Power Your Artistry 🚀
      </h3>

      <div>
        <div className="smallScreenPayFields">
          <Layout title="Donut Shop">
            <ArtistSubscriptionCheckoutForm
              // <CheckoutForm
              price={50}
              onSuccessfulCheckout={() => {
                console.log(
                  'payment successful, now run convertAccountToPremiumSubscription ...'
                );
                // console.log(`studio id is : ${studio_id}`);
                // console.log(`user id is : ${user._id}`);
                convertAccountToPremiumArtistSubscription({
                  artist_id: artist_id,
                  user_id: user._id,
                });

                navigate(`/artist-profile/${artist_id}`, {
                  state: {
                    messageText: `Welcome To Premium!`,
                  },

                  // });
                });
              }}
            />
          </Layout>
        </div>
      </div>
    </div>
  );
};

export default GoProArtistPayment;
