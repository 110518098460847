import React, { Component, useContext, useEffect, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import CurriculumContext from '../../context/curriculum/curriculumContext';
import SelectedCurriculum from './SelectedCurriculum';

const AdoptCurriculum = (props) => {
  let curriculumContext = useContext(CurriculumContext);
  let {
    getCurriculumsWithStyleAndLevel,
    curriculumsWithStyleAndLevel,
  } = curriculumContext;

  useEffect(() => {
    getCurriculumsWithStyleAndLevel('brazilian-zouk', 'int 2');
    // eslint-disable-next-line
  }, []);
  const [filter, setFilter] = useState({
    style: 'brazilian-zouk',
    level: 'all',
  });
  console.log(curriculumsWithStyleAndLevel);

  const { style, level } = filter;

  const all_curriculums = ['c1', 'c2', 'c3'];
  let dance_type_array = ['salsa', 'bachata', 'kizomba', 'zouk'];
  let dance_level_array = ['beginner 1', 'intermediate 1', 'advanced 1'];
  const handleDanceTypeChange = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    setFilter({ ...filter, style: type });
    console.log('filter');
    console.log(filter);
  };
  const handleLevelChange = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    setFilter({ ...filter, level: type });
    console.log('filter');
    console.log(filter);
  };

  return (
    <div>
      <div className="row">
        <div className="col">
          <h5>Style</h5>
          <div className="col">
            {[DropdownButton].map((DropdownType, idx) => (
              <DropdownType
                as={ButtonGroup}
                key={idx}
                id={`dropdown-button-drop-${idx}`}
                size="sm"
                variant="secondary"
                title={style}
                onSelect={handleDanceTypeChange}
              >
                {dance_type_array.map((type) => (
                  <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
                ))}
              </DropdownType>
            ))}
          </div>
        </div>
        <div className="col">
          <h5>Level</h5>
          <div className="col">
            {[DropdownButton].map((DropdownType, idx) => (
              <DropdownType
                as={ButtonGroup}
                key={idx}
                id={`dropdown-button-drop-${idx}`}
                size="sm"
                variant="secondary"
                title={level}
                onSelect={handleLevelChange}
              >
                {dance_level_array.map((type) => (
                  <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
                ))}
              </DropdownType>
            ))}
          </div>
        </div>
      </div>
      <h5>Adopt Curriculum</h5>
      {curriculumsWithStyleAndLevel.map((curriculum) => (
        <SelectedCurriculum
          // setCurriculumSelected={setCurriculumSelected}
          // curriculumSelected={curriculumSelected}
          curriculum={curriculum}
          school_id={props.school_id}
        />
      ))}
    </div>
  );
};

export default AdoptCurriculum;
