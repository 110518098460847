import { blue } from '@material-ui/core/colors';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

const WhatIsAirmeus = () => {
  return (
    <div
      className="smallScreenText"
      style={{ backgroundColor: '', paddingLeft: '20px', paddingRight: '20px' }}
    >
      <h1>Airmeus Events</h1>

      <h3
        style={{
          color: 'rgb(1, 126, 252)',
        }}
      >
        Super Power Your Event 🚀🚀🚀
      </h3>
      <div className="row" style={{ paddingBottom: '30px' }}>
        <div className="">
          <h5>
            💬 A revolutionary Event Chat experience that will keep your
            attendees engaged
          </h5>
          <h5>
            ⚡️ Next level Workshop Schedule that makes logistics simple and fun
          </h5>
          <h5>
            😻 Performance Reactions that allow your audience to give live
            reactions to your artist’s performances
          </h5>
        </div>
        <Button
          style={{ width: 'fit-content', backgroundColor: 'rgb(1, 126, 252)' }}
        >
          Create Your Event
        </Button>
      </div>

      <h4
        style={{
          color: 'rgb(236, 49, 236)',
        }}
      >
        Studio Software
      </h4>
      <div className="row" style={{ paddingBottom: '30px' }}>
        <div className="">
          <h5>
            <span
              style={{
                color: 'rgb(236, 49, 236 )',
              }}
            >
              Enroll
            </span>{' '}
            students in custom curriculums
          </h5>
          <h5>
            {' '}
            Real time grades to{' '}
            <span
              style={{
                color: 'rgb(236, 49, 236 )',
              }}
            >
              assess proficiency
            </span>{' '}
          </h5>
          <h5>
            <span
              style={{
                color: 'rgb(236, 49, 236 )',
              }}
            >
              Analytics
            </span>{' '}
            that help you understand how your company is performing
          </h5>
        </div>
        <Button
          style={{
            width: 'fit-content',
            backgroundColor: 'rgb(236, 49, 236 )',
          }}
        >
          Build Your Studio
        </Button>
      </div>
      <div>
        <h4
          style={{
            color: 'rgb(242, 114, 29 )',
          }}
        >
          Vendor Solution
        </h4>
        <div className="row" style={{ paddingBottom: '30px' }}>
          <div className="">
            <h5>Make your event a launching pad for local businesses</h5>
            <h5>
              Stay connected with your customers long after the event has ended
            </h5>
            <h5>Extend your reach</h5>
          </div>
          <Button
            style={{
              width: 'fit-content',
              backgroundColor: 'rgb(242, 114, 29 )',
            }}
          >
            Reach Your Community
          </Button>
        </div>
      </div>
      <div>
        <h4
          style={{
            color: 'rgb(87, 240, 16 )',
          }}
        >
          Authentic Promotion
        </h4>
        <div className="row">
          <div className="">
            <h5>Reach the people you care about</h5>
            <h5>
              Utilize cross brand collaboration to deepen your network and
              bottom line
            </h5>
            <h5>Grow your company around the values you believe</h5>
          </div>
          <Button
            style={{
              width: 'fit-content',
              backgroundColor: 'rgb(87, 240, 16 )',
            }}
          >
            Reach Your Community
          </Button>
        </div>
      </div>
    </div>
  );
};
export default WhatIsAirmeus;
