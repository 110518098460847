import React, { Component, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ArtistContext from '../../context/artist/artistContext'; // might have to capitalize
import AddNewArtistPopup from './AddNewArtistPopup';

import { Link } from 'react-router-dom';

const ArtistDirectory = () => {
  const artistContext = useContext(ArtistContext);
  const { artist_directory, getArtistDirectory, DJs, getDJs } = artistContext;

  useEffect(() => {
    getArtistDirectory();
    // getOtherArtists();
    getDJs();
    // eslint-disable-next-line
  }, []);

  var isAllCheck = false;
  const handlePillClicked = (type) => {
    setArtistType(type);

    // setDetails({
    //   ...details,
    //   ['workshop_type']: type,
    // });
    document
      .querySelectorAll('input[type=radio]')
      .forEach((el) => (el.checked = isAllCheck));
  };

  //dynamic buttons
  const renderSalsaButton = () => {
    if (artistType === 'salsa') {
      return (
        <Button
          onClick={() => {
            handlePillClicked('salsa');
            setDjSelected(false);
          }}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          salsa
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handlePillClicked('salsa');
            setDjSelected(false);
          }}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          salsa
        </Button>
      );
    }
  };
  const renderBachataButton = () => {
    if (artistType === 'bachata') {
      return (
        <Button
          onClick={() => {
            handlePillClicked('bachata');
            setDjSelected(false);
          }}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          bachata
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handlePillClicked('bachata');
            setDjSelected(false);
          }}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          bachata
        </Button>
      );
    }
  };

  const renderKizombaButton = () => {
    if (artistType === 'kizomba') {
      return (
        <Button
          onClick={() => {
            handlePillClicked('kizomba');
            setDjSelected(false);
          }}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          kizomba
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handlePillClicked('kizomba');
            setDjSelected(false);
          }}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          kizomba
        </Button>
      );
    }
  };

  const renderZoukButton = () => {
    if (artistType === 'zouk') {
      return (
        <Button
          onClick={() => {
            handlePillClicked('zouk');
            setDjSelected(false);
          }}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          zouk
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handlePillClicked('zouk');
            setDjSelected(false);
          }}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          zouk
        </Button>
      );
    }
  };
  const handleDJClicked = () => {
    setArtistType('DJ');
    handlePillClicked('DJ');
    setDjSelected(true);
  };
  const renderDJButton = () => {
    if (artistType === 'DJ') {
      return (
        <Button
          onClick={() => {
            handlePillClicked('DJ');
            setDjSelected(false);
          }}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          DJ
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handlePillClicked('DJ');
            setDjSelected(true);
          }}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          DJ
        </Button>
      );
    }
  };

  const [artistType, setArtistType] = useState('bachata');

  const renderArtistsDynamically = (artistTypeVar) => {
    let artists_dict = new Map(Object.entries(artist_directory));
    console.log('artist_directory ---- ');
    console.log(artist_directory);
    console.log('artists_dict -----');
    console.log(artists_dict);

    return artists_dict.get(artistTypeVar);
  };

  const [addNewArtistClicked, setAddNewArtistClicked] = useState(false);
  const [djSelected, setDjSelected] = useState(false);
  return (
    <div>
      <div className="row">
        {renderSalsaButton()}
        {renderBachataButton()}
        {renderKizombaButton()}
        {renderZoukButton()}
        {renderDJButton()}
      </div>
      <div>
        <div className="row">
          <div className="col">
            <Button
              onClick={() => setAddNewArtistClicked(!addNewArtistClicked)}
              style={{
                marginTop: '12px',
                marginBottom: '12px',
                background: 'white',
                // color: 'blue',
                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
                padding: '6px',
              }}
            >
              Add New Dancers
            </Button>
          </div>
          <div className="col">
            <Link to={'/rankings'}>
              <h5
                style={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  // color: 'blue',
                  background: 'white',

                  borderStyle: 'solid',
                  borderColor: 'rgb(1, 126, 252)',
                  borderWidth: '2px',
                  borderRadius: '6px',

                  // fontSize: '14px',
                  backgroundColor: 'white',
                  color: 'rgb(1, 126, 252)',
                  textAlign: 'center',
                }}
              >
                See Rankings
              </h5>
            </Link>
          </div>
        </div>
      </div>
      {addNewArtistClicked && (
        <div>
          <AddNewArtistPopup addNewArtistClicked={addNewArtistClicked} />
        </div>
      )}
      <h5>
        {/* <div className="vertical-artist-container"> */}

        {!djSelected && (
          <div className="col grid-container-small-screen grid-container-big-screen">
            {renderArtistsDynamically(artistType)?.map((artist) => (
              <div>
                <Link
                  to={`/artist-profile/${artist._id}`}
                  style={{ color: 'blue' }}
                >
                  <img
                    src={artist.image ? artist?.image : artist?.image_url?.url}
                    height="100"
                    width="100"
                    alt="user"
                    style={{ borderRadius: '0.25rem' }}
                  />

                  <h5
                    style={{
                      color: 'rgb(1, 126, 252)',
                    }}
                  >
                    {artist.name}
                  </h5>
                </Link>
              </div>
            ))}
          </div>
        )}

        {djSelected && (
          <div className="col grid-container-small-screen grid-container-big-screen">
            {DJs?.map((artist) => (
              <div>
                <Link
                  to={`/artist-profile/${artist._id}`}
                  style={{ color: 'blue' }}
                >
                  <img
                    src={artist.image ? artist?.image : artist?.image_url?.url}
                    height="100"
                    width="100"
                    alt="user"
                    style={{ borderRadius: '0.25rem' }}
                  />

                  <h5
                    style={{
                      color: 'rgb(1, 126, 252)',
                    }}
                  >
                    {artist.name}
                  </h5>
                </Link>
              </div>
            ))}
          </div>
        )}
        {/* </div> */}
      </h5>
    </div>
  );
};
export default ArtistDirectory;
