import React, { Component, Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import City from './City';

const CityFilterButtons = (props) => {
  // const [citySelected, setCitySelected] = useState('New York City');
  const [citySelected, setCitySelected] = useState('');
  const handleCitySelected = (city) => {
    setCitySelected(city);
    console.log(city);
  };

  const renderLocationFilterButtons = (city) => {
    if (citySelected === city) {
      return (
        <Button
          onClick={() => {
            handleCitySelected(city);
            // setDjSelected(false);
          }}
          className="rounded-pill"
          style={{
            width: 'max-content',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          {city}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handleCitySelected(city);
            // setDjSelected(true);
          }}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          {city}
        </Button>
      );
    }
  };

  // const [currentCity, setCurrentCity] = useState('New York City');
  const [currentCity, setCurrentCity] = useState('');

  const [region, setSelectedRegion] = useState('World');

  const returnCitiesOrRegionsBasedOnRegion = (region) => {
    switch (region) {
      case 'World':
        // return ['USA', 'Europe'];
        // return ['NYC', 'Barcelona', 'italy'];
        return [];
      case 'USA':
        return [
          'San Diego-United States',
          'New York City-United States',
          'Los Angeles-United States',
          'Miami-United States',
          'Chicago-United States',
          'Houston-United States',
          'Dallas-United States',
          'Washington DC-United States',
          'Philadelphia-United States',
          'Phoenix-United States',
          'Atlanta-United States',
        ];
      case 'Europe':
        return [
          'Madrid-Spain',
          'Barcelona-Spain',
          'Paris-France',
          'London-United Kingson',
          'Rome-Italy',
          'Berlin-Germany',
          'Hamburg-Germany',
          'Munich-Germany',
          'Milan-Germany',
        ];

      default:
        return ['New York City', 'Los Angeles', 'Miami'];
    }
  };
  const [citiesBasedOnRegion, setCitiesBasedOnRegion] = useState(
    returnCitiesOrRegionsBasedOnRegion(region)
  );

  const handleRegionChange = (region) => {
    console.log(region);
    setSelectedRegion(region);
    setCitiesBasedOnRegion(returnCitiesOrRegionsBasedOnRegion(region));
    //getFilteredRankings({...})
  };

  const renderWorldButton = () => {
    if (region === 'World') {
      return (
        <Button
          onClick={() => {
            handleRegionChange('World');
            props.getRankings(props.style);
            props.setCityFilter('');
            props.setCountryFilter('');
            // if (props.user?.primary_style) {
            //   props.getRankings(props.user.primary_style);
            // } else {
            //   props.getRankings('bachata');
            // }
          }}
          className="rounded-pill"
          style={{
            width: 'max-content',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          World
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handleRegionChange('World');
            props.getRankings(props.style);
            props.setCityFilter('');
            props.setCountryFilter('');
            // if (props.user?.primary_style) {
            //   props.getRankings(props.user.primary_style);
            // } else {
            //   props.getRankings('bachata');
            // }
            // setDjSelected(true);
          }}
          className="bg-primary rounded-pill"
          style={{
            width: 'max-content',
          }}
        >
          World
        </Button>
      );
    }
  };
  const renderUnitedStatesButton = () => {
    if (region === 'USA') {
      return (
        <Button
          onClick={() => {
            handleRegionChange('USA');
            props.setCityFilter('none');
            props.setCountryFilter('United States');
            props.getFilteredRankings({
              country: 'United States',
              state: 'none',
              city: 'none',
              style: props.style,

              // style: props?.user?.primary_style
              //   ? props.user.primary_style
              //   : 'bachata',
            });
          }}
          className="rounded-pill"
          style={{
            width: 'max-content',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          USA
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handleRegionChange('USA');
            props.setCityFilter('none');
            props.setCountryFilter('United States');
            props.getFilteredRankings({
              country: 'United States',
              state: 'none',
              city: 'none',
              style: props.style,

              // style: props?.user?.primary_style
              //   ? props.user.primary_style
              //   : 'bachata',
            });
            // setDjSelected(true);
          }}
          className="bg-primary rounded-pill"
          style={{
            width: 'max-content',
          }}
        >
          USA
        </Button>
      );
    }
  };
  const renderEuropeButton = () => {
    if (region === 'Europe') {
      return (
        <Button
          onClick={() => {
            handleRegionChange('Europe');
            setCitySelected('Madrid');
            props.setCityFilter('Madrid');
            props.setCountryFilter('Spain');
            props.getFilteredRankings({
              country: 'Spain',
              state: 'none',
              city: 'Madrid',
              style: props.style,
              // style: props?.user?.primary_style
              //   ? props.user.primary_style
              //   : 'bachata',
            });
          }}
          className="rounded-pill"
          style={{
            width: 'max-content',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          Europe
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handleRegionChange('Europe');
            setCitySelected('Madrid');
            setCurrentCity('Madrid');
            props.setCityFilter('Madrid');
            props.setCountryFilter('Spain');
            props.getFilteredRankings({
              country: 'Spain',
              state: 'none',
              city: 'Madrid',
              style: props.style,
              // style: props?.user?.primary_style
              //   ? props.user.primary_style
              //   : 'bachata',
            });
            // setDjSelected(true);
          }}
          className="bg-primary rounded-pill"
          style={{
            width: 'max-content',
          }}
        >
          Europe
        </Button>
      );
    }
  };

  const renderButtons = (
    <Fragment>
      <div
        class="container-fluid  overflow-auto example"
        style={{ padding: '0px' }}
      >
        <div class="d-flex flex-row flex-nowrap">
          {renderWorldButton()}
          {renderUnitedStatesButton()}
          {renderEuropeButton()}

          {/* {['New York City', 'Los Angeles', 'Miami'].map((city) => ( */}
          {citiesBasedOnRegion.map((city) => (
            <City
              city={city}
              currentCity={currentCity}
              setCurrentCity={setCurrentCity}
              style={props.style}
              setCityFilter={props.setCityFilter}
              setCountryFilter={props.setCountryFilter}
            />
            //   <div class="d-flex flex-row flex-nowrap">
            //     {renderLocationFilterButtons(city)}
            //   </div>
          ))}
        </div>
      </div>

      {/* {renderLocationFilterButtons()} */}
      {/* {renderLocationFilterButtons()} */}
    </Fragment>
  );

  return (
    <div>
      {/* <div className="row">{renderLocationFilterButtons()}</div> */}
      {renderButtons}
    </div>
  );
};
export default CityFilterButtons;
