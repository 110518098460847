import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  // Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
} from 'react-bootstrap';
import Witness from './Witness';
const Witnesses = (props) => {
  const isObjectEmpty = (objectName) => {
    for (let prop in objectName) {
      if (objectName.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  };
  const [witnessList, setWitnessList] = useState([]);

  const onContactedPersonChange = (witness, e) => {
    console.log('e is');
    console.log(e.target.checked);
    // setDetails({ ...details, [e.target.name]: e.target.value });
    // console.log('e.target.value fromn summary');
    // console.log(e.target.value);
    // setInvestigationSummary(e.target.value);

    //add contacted person to obj to send thru to backend
    // setDetails({ ...details, [e.target.name]: e.target.value });

    //only in local state add witnesses from incident_report from backend
    if (witnessList.length === 0) {
      let updatedWitness = {
        witness_contacted: e.target.checked,
        witness_name: witness.witness_name,
        witness_phone_number: witness.witness_phone_number,
      };

      console.log('updatedWitness');
      console.log(updatedWitness);

      //add updatedWitness to obj to send to backedn

      // setDetails({ ...details, [e.target.name]: e.target.value });

      let newListWithoutUpdatedWitness =
        //   ...incident_report.witnesses,

        props.incident_report.witnesses.filter(
          (witness) => witness.witness_name !== updatedWitness.witness_name
        );

      console.log('newListWithoutUpdatedWitness');
      console.log(newListWithoutUpdatedWitness);

      let newWitnessListWithUpdatedWitness = [
        ...newListWithoutUpdatedWitness,
        updatedWitness,
      ];
      console.log('final updated list ');
      console.log(newWitnessListWithUpdatedWitness);

      //update state

      setWitnessList(newWitnessListWithUpdatedWitness);

      //add witness list to state to update

      props.setDetails({
        ...props.details,
        witnesses: newWitnessListWithUpdatedWitness,
      });
    } else {
      //this means we have already updated our local state for witnesses,so we can use
      //witnesslist in local state to update instead of backend incident_report.witnesses
      console.log('second time hit else updating properly??');
      let updatedWitness = {
        witness_contacted: e.target.checked,
        witness_name: witness.witness_name,
        witness_phone_number: witness.witness_phone_number,
      };

      console.log('updatedWitness');
      console.log(updatedWitness);

      //add updatedWitness to obj to send to backedn

      // setDetails({ ...details, [e.target.name]: e.target.value });

      let newListWithoutUpdatedWitness =
        //   ...incident_report.witnesses,

        witnessList.filter(
          (witness) => witness.witness_name !== updatedWitness.witness_name
        );

      console.log('newListWithoutUpdatedWitness');
      console.log(newListWithoutUpdatedWitness);

      let newWitnessListWithUpdatedWitness = [
        ...newListWithoutUpdatedWitness,
        updatedWitness,
      ];
      console.log('final updated list -- propert??');
      console.log(newWitnessListWithUpdatedWitness);

      //update state

      setWitnessList(newWitnessListWithUpdatedWitness);

      //add witness list to state to update
      props.setDetails({
        ...props.details,
        witnesses: newWitnessListWithUpdatedWitness,
      });
    }
    //auto update
    // updateIncidentReport({witnesses:newWitnessListWithUpdatedWitness})
  };

  return (
    <div>
      <div>
        <br />

        <div className="row">
          {/* <div className="col"><h5>{props.incident_report?.reporter_name}</h5></div> */}

          <div className="col">
            <h5>
              {' '}
              <span className="incidentReportABC_Seperators">B</span> Contact
              Witnesses
            </h5>
          </div>

          {!isObjectEmpty(props.incident_report) ? (
            <div>
              {props.incident_report?.witnesses.map((witness, index) => (
                <Witness
                  key={index}
                  witness={witness}
                  setWitnessContactedUpdateShowButton={
                    props.setWitnessContactedUpdateShowButton
                  }
                  // witnessContactedLabel={props.witnessContactedLabel}
                  setwitnessContactedLabel={props.setWitnessContactedLabel}
                  onContactedPersonChange={props.onContactedPersonChange}
                />
                // <div className="row" style={{ paddingTop: '10px' }}>
                //   <div className="col">
                //     <h5>{witness.witness_name}</h5>
                //   </div>
                //   <div className="col">
                //     <h5>{witness.witness_phone_number}</h5>
                //   </div>
                //   <div className="col">
                //     <Form>
                //       <Form.Check // prettier-ignore
                //         type="switch"
                //         id="custom-switch"
                //         label={
                //           witness.witness_contacted
                //             ? 'Yes'
                //             : props.witnessContactedLabel
                //         }
                //         // label={getWitnessContacted(witness.witness_contacted)}
                //         // value
                //         // checked={witness.witness_contacted}
                //         // onChange={(e) => {
                //         //   onContactedPersonChange(witness, e);
                //         //   //show update button for witness section
                //         //   setWitnessContactedUpdateShowButton(true);

                //         //   console.log('e.target from insdie');
                //         //   console.log(e.target);
                //         //   if (e.target.name === 'Yes') {
                //         //     setWitnessContactedLabel('Not Yet');
                //         //   } else {
                //         //     setWitnessContactedLabel('Yes');
                //         //   }
                //         // }}
                //         onClick={(e) => {
                //           props.onContactedPersonChange(witness, e);
                //           //show update button for witness section
                //           props.setWitnessContactedUpdateShowButton(true);
                //           if (e.target.label === 'Yes') {
                //             props.setWitnessContactedLabel('Not Yet');
                //           } else {
                //             props.setWitnessContactedLabel('Yes');
                //           }
                //         }}
                //       />
                //     </Form>
                //   </div>
                // </div>
              ))}
              {props.witnessContactedUpdateShowButton ? (
                <Button
                  onClick={() => {
                    console.log('props.details from witnesses');
                    console.log(props.details);
                    props.updateIncidentReport(
                      props.details,
                      props.incident_report._id
                    );
                  }}
                >
                  Update Witnesses
                </Button>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <br />
      </div>
    </div>
  );
};
export default Witnesses;
