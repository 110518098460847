import React, { Component, Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import SelectDanceCompaniesFromTourLocations from './SelectDanceCompaniesFromTourLocations';

const ListSelectedLocations = (props) => {
  const { instructor_id, instructor_name, tour_name } = props;

  let fruits2 = [];
  //   let [locations, setLocationList] = useState(fruits2);
  //   let [locations, setLocationList] = useState([
  //     {
  //       company_id: '',
  //       company_name: '', //load each state's dance companies in ascd order
  //       city: 'none',
  //       state: 'none',
  //       country: 'United States',
  //       status: 'pending',
  //     },
  //   ]);
  let [locations, setLocationList] = useState([]);
  let [tourStops, setTourStops] = useState([]);

  //   let [fruitToAdd, setFruitToAdd] = useState('');
  let [fruitToAdd, setFruitToAdd] = useState(props.state);
  const onChange = (e) => setFruitToAdd(e.target.value);
  const addFruit = (fruit) => {
    // setLocationList([...locations, fruit]);
    setLocationList([
      ...locations,

      {
        company_id: '',
        company_name: '', //load each state's dance companies in ascd order
        city: 'none',
        state: fruit,
        country: 'United States',
        status: 'pending',
      },
      // state: fruit
    ]);
    setFruitToAdd('');
  };
  const createFruit = (e) => {
    e.preventDefault();
    addFruit(props.state);
    // if (typeof fruitToAdd === 'string' && fruitToAdd.length > 0) {
    //   addFruit(fruitToAdd);
    //   console.log('props from if');
    //   console.log(props);
    // } else {
    //   console.log('props frpm else');
    //   console.log(props);
    //   console.log(fruitToAdd);
    //   // addFruit(fruitToAdd);
    //   addFruit(props.state);
    //   //   addFruit('fruitToAdd');
    //   console.log('not string --');
    // }
  };

  const danceMoveName = (
    <Fragment>
      <div className="form-group">
        {/* <label for="fruitItem">
              Dance Move Name */}
        <input
          type="text"
          id="fruitItem"
          placeholder="..Enter Dance Move"
          className="form-control"
          value={fruitToAdd}
          onChange={onChange}
        />
        {/* </label> */}
      </div>
    </Fragment>
  );
  return (
    <div>
      {/* <div>
        {locations.map((location) => (
          <div style={{ paddingBottom: '8px', paddingTop: '8px' }}>
            <li className="list-group-item list-group-item-info">
              {location.state}
            </li>
          </div>
        ))}
      </div> */}
      <div className="row">
        <div className="col">
          <form className="form-inline" onSubmit={createFruit}>
            <Button
              type="submit"
              className="btn btn-primary"
              style={{
                backgroundColor: 'white',
                // width: 'max-content',
                // width: '120px',
                width: 'max-content',

                background: 'white',
                color: 'rgb(1, 126, 252)',
                borderStyle: 'solid',
                // borderColor: 'rgb(1, 126, 252)',
                padding: '6px',
                marginTop: '10px',
                border: '0.5px solid rgb(1, 126, 252)',
              }}
            >
              Add Location
            </Button>
          </form>
        </div>
        <div className="col">
          {/* <Button></Button> */}

          <Button
            type="submit"
            className="btn btn-primary"
            style={{
              backgroundColor: 'white',
              // width: 'max-content',
              // width: '120px',
              width: 'max-content',

              background: 'white',
              color: 'rgb(1, 126, 252)',
              borderStyle: 'solid',
              // borderColor: 'rgb(1, 126, 252)',
              padding: '6px',
              marginTop: '10px',
              border: '0.5px solid rgb(1, 126, 252)',
            }}
          >
            Select Dance Companies
          </Button>
        </div>

        <SelectDanceCompaniesFromTourLocations
          locations={locations}
          setLocationList={setLocationList}
          tourStops={tourStops}
          setTourStops={setTourStops}
          instructor_id={instructor_id}
          instructor_name={instructor_name}
          tour_name={tour_name}
        />
      </div>{' '}
    </div>
  );
};

export default ListSelectedLocations;
