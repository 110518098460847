import React, {
  Fragment,
  Component,
  useState,
  useContext,
  useEffect,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
// import TabContainer from 'react-bootstrap/TabContainer';
// import TabPane from 'react-bootstrap/TabPane';
import Attendees from './Attendees';
import Roommates from './Roommates';
// import Feed from './Feed';
import SubEvents from './SubEvents';
import { Link } from 'react-router-dom';
import EditEventDetail from './EditEventDetail';
import userContext from '../../context/user/userContext'; // might have to capitalize
import eventContext from '../../context/event/eventContext'; // might have to capitalize
import Vendors from './Vendors';
import { useAuth } from '../../context/auth/AuthState';
// import ArtistLineup from './artist-lineup/ArtistLineup';
import EditArtistLineup from './artist-lineup/EditArtistLineup';
import SeeArtistLineup from './artist-lineup/SeeArtistLineup';
// import WorkshopSchedule from './artist-lineup/WorkshopSchedule';
import WorkshopScheduleNoPopup from './artist-lineup/WorkshopScheduleNoPopup';
import MyItinerary from './MyItinerary';
import DJRequests from './DJRequests';
import Performer from '../performances/Performer';
import WorkshopScheduleListFormat from './artist-lineup/WorkshopScheduleListFormat';
import DanceReactions from '../reactions/DanceReactions';
import EventFeedback from './EventFeedback';
import Chat from '../events/chat/Chat';
import { Badge } from 'react-bootstrap';
import TabMenuItems from './TabMenuItems';
import SeeArtistLineupWithPrivateLessonPopup from './artist-lineup/SeeArtistLineupWithPrivateLessonPopup.js';
import SafetyOfficer from './SafetyOfficer.js';
import LineupHorizontal from './artist-lineup/LineupHorizontal.js';
import HorizontalLineupWithPrivateLessonPopup from './artist-lineup/Event0.2/HorizontalLineupNonModalWithPrivateLessonPopup.js';
import HorizontalLineupNonModalWithPrivateLessonPopup from './artist-lineup/Event0.2/HorizontalLineupNonModalWithPrivateLessonPopup.js';
import WorkshopScheduleListFormatNewLayout from './artist-lineup/Event0.2/WorkshopSchedule/WorkshopScheduleListFormatNewLayout.js';
import RoomShare from './artist-lineup/Event0.2/main-components/RoomShare.js';
import AttendeeList from '../Event0.2/AttendeeList.js';
import WorkshopTest from '../WorkshopTest.js';
import EventFeedback_v02 from './EventFeedback_v02.js';
import Trivia from '../trivia-connect/Trivia.js';

const moment = require('moment');
moment().format();
// export function EventDetail() {
const EventDetailWithCategorySpecificMenu = (props) => {
  const [authState] = useAuth();
  const { user } = authState;
  console.log('user from event detail wit cat');
  console.log(user);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    console.log('closing ');
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    console.log(`show is: ${show}`);
  };

  const handleCloseLineup = () => {
    console.log('closing ');
    // setShowLineup(false);
  };
  const handleShowLineup = () => {
    // setShowLineup(true);
    // console.log(`showCreateLineup is: ${showLineup}`);
  };

  // const handleShowLineup = () => {
  //   setShowLineup(true);
  //   console.log(`showLineup is: ${showLineup}`);
  // };

  // const [key, setKey] = useState('attendees');

  // const [key, setKey] = useState(props.page);
  // const uContext = useContext(userContext);
  // const { } = uContext;
  const eContext = useContext(eventContext);
  const {
    editEvent,
    deleteEvent,
    // addToEventLineup,
    getWorkshopSchedule,
    workshop_schedule,
    getMyItinerary,
    myItinerary,
    loading,
    getOneEvent,
    // addNewDJ,
    event,
    // newDJ,
  } = eContext;

  const location = useLocation();
  // let event = {};
  let page = 1;
  // const { event, page } = location.state;
  let { id } = useParams();
  console.log('event id --- ');
  console.log(id);
  console.log('done--');
  if (location.state) {
    // console.log('location.state is defined');
    // event = location.state.event;
    // getOneEvent(id);
    page = location.state.page;
  } else {
    console.log('id  (event id) from fresh session:');
    console.log(id);
    // getOneEvent(id);
  }
  const [key, setKey] = useState(page);

  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('running useeffect from eventdetail');
    // setLoading(true);
    if (location.state) {
      console.log('location.state');
      console.log(location?.state);
      getOneEvent(id);
      // getWorkshopSchedule(event._id);
      getWorkshopSchedule(id);
      // getMyItinerary(event?._id, user?._id);
      if (user) {
        getMyItinerary(location?.state?.event?._id, user?._id);
      }
    } else {
      getOneEvent(id);
      getWorkshopSchedule(id);
      if (user?._id) {
        console.log('user from 117');
        console.log(user);
        if (user) {
          getMyItinerary(id, user?._id);
        }
      }
    }
    console.log('testing g');
    console.log('event from 132');
    console.log(event);
    // getHost(event.hostId);
    // console.log('event host: ');
    // console.log(host);
    // setLoading(false);
  }, []);

  console.log('testing how many re-renders');

  let workshop_dict = new Map(Object.entries(workshop_schedule));

  let image;
  if (event?.image) {
    image = event.image.url;
  } else {
    image =
      'https://www.weho.org/home/showpublishedimage/5617/635754012603070000';
  }
  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    name: event?.name,
    descr: event?.descr,
    // location: 'Charlotte, NC',
  });

  const { name, descr } = details;

  const updatedEvent = {};
  updatedEvent.event_id = event?._id;

  // updatedEvent.descr = descr;
  updatedEvent.name = name;
  updatedEvent.descr = descr;

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving updated user from frontend');

    if (details === '') {
      //   setAlert('Please fill in all fields', 'danger');
    } else {
      editEvent(updatedEvent);

      console.log('updatedEvent from edit is: ---');
      console.log(updatedEvent);
      console.log('saving updated user from frontend');
    }
  };
  const onDelete = (e) => {
    e.preventDefault();
    console.log('deleting event from frontend');
    deleteEvent(event._id);
  };

  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);

  const editingEvent = (
    <Fragment>
      <div>
        <EditEventDetail
          deleteButtonClicked={deleteButtonClicked}
          setDeleteButtonClicked={setDeleteButtonClicked}
          deleteEvent={deleteEvent}
          event={event}
          showModal={show}
          setShow={setShow}
        />
        {/* render attendees under attendees h1 or bring the attendees to a child component */}
      </div>
    </Fragment>
  );

  const notEditingRenderNothing = <Fragment />;
  const dontShowArtistLineup = <Fragment />;

  const smallText = (
    <Fragment>
      <div className="smallText smallTextBigScreen">
        <p>{event?.descr}</p>
      </div>
    </Fragment>
  );

  const largeText = (
    <Fragment>
      <div className="smallTextExpanded expandedTextBigScreen">
        <p>{event?.descr}</p>
      </div>
    </Fragment>
  );
  // let expandButtonSelected = false;

  const [expandButtonSelected, setExpandButtonSelected] = useState(false);

  const attendeeButtonNumberTitle = event?.attendees?.length
    ? event.attendees?.length
    : '';

  // const showArtistLineup = (
  //   <Fragment>
  //     <div>
  //       <ArtistLineup />
  //     </div>
  //   </Fragment>
  // );

  let myItineraryMap = new Map(Object.entries(myItinerary));
  const [myItineraryClicked, setMyItineraryClicked] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => {
    console.log('closing ');
    setShowDelete(false);
  };
  const handleShowDelete = () => {
    setShowDelete(true);
    // console.log(`show is: ${show}`);
  };
  // if (loading) {
  //   return <h2>Loading...</h2>;
  // }
  const deletingEvent = (
    <Fragment>
      <div>
        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>
              Are you sure you want to delete this event
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Cancel
            </Button>
            {/* <Button variant="primary" onClick={onDelete}> */}
            <Button
              variant="primary"
              onClick={() => {
                console.log('hi');
              }}
            >
              Delete B
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Fragment>
  );
  const getEventType = async (event_type) => {
    // let activity_type = subevent?.activity_type;
    // let event_type = 'Dance Event';
    switch (event_type) {
      case 'Dance Event':
        return 'dance_event';
      case 'City Festival':
        return 'city_festival';
      case 'Concert':
        return 'concert';
      case 'Homecoming':
        return 'homecoming';
      case 'Other':
        return 'other';
      case 'dance_event':
        return 'dance_event';
      case 'city_festival':
        return 'city_festival';
      case 'concert':
        return 'concert';
      case 'homecoming':
        return 'homecoming';

      // case 'game night':
      //   return 'green';
      default:
        return 'dance_event';
    }
  };

  const renderAddWorkshopButton = () => {
    if (
      (event?.premium_subscription === true &&
        event.hostId === user?._id &&
        event?.event_type === 'dance_event') ||
      user?.email === 'airmeus.team@gmail.com'
    ) {
      return (
        <div className="card-horizontal-menu card-block mx-2">
          <WorkshopScheduleNoPopup event={event} />
        </div>
      );
    }
  };

  const renderComponents = (
    <Fragment>
      <div>{renderAddWorkshopButton()}</div>
    </Fragment>
  );

  // return (
  if (loading) {
    return <h2>Loading...</h2>;
  }
  return (
    <div>
      {location?.state?.messageText && (
        <div>
          {location?.state?.messageText ? (
            <div
              className="promotionText all-center"
              style={{ backgroundColor: '#6495ED', color: 'white' }}
            >
              <h4>{location.state.messageText}</h4>
            </div>
          ) : (
            <></>
          )}
          <br />
        </div>
      )}
      <div
        className="all-center image-container-for-safe-events-tag"
        style={{ paddingBottom: '16px' }}
      >
        <div className="bigScreen smallScreenEventDetail">
          <img
            // style={{ width: '350px' }}
            src={image}
            alt="event"
            // src="https://static.wixstatic.com/media/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg/v1/fit/w_2500,h_1330,al_c/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg"
          />
        </div>

        {/* <div class="top-left">
          <h5
            style={{
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '0px 12px',
              borderWidth: '1px',
            }}
          >
            Top Left
          </h5>
        </div> */}
      </div>
      {/* <img
        style={{ width: '350px' }}
        src={image}
        alt="event"
        // src="https://static.wixstatic.com/media/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg/v1/fit/w_2500,h_1330,al_c/95e935_1baf622cdef44763b4500ee8774b7c69~mv2.jpeg"
      /> */}
      <div className="adjust-line-height all-center-2-for-text">
        {event?.start_date ? (
          <label style={{ fontSize: '14px', color: 'red' }}>
            {moment(event.start_date)
              .zone(0)
              .format('dddd MMMM Do, YYYY')}
          </label>
        ) : (
          <></>
        )}
        <br />
        <label style={{ fontSize: '20px', fontWeight: 'bold' }}>
          {event?.name}
        </label>
        <br />
        {event?.venueName ? (
          <label style={{ fontSize: '14px' }}>
            {' '}
            {event.venueName + ' - ' + event.city + ',' + event.state}
          </label>
        ) : (
          <></>
        )}
        {!expandButtonSelected ? smallText : largeText}
      </div>
      <div className="row" style={{ paddingTop: '14px' }}>
        <div className="col-4">
          <Button
            variant="primary"
            onClick={() => setExpandButtonSelected(!expandButtonSelected)}
            style={{
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '0px 12px',
              borderWidth: '1px',
              width: '100px',
            }}
          >
            {!expandButtonSelected ? 'see more' : 'see less'}
          </Button>
        </div>

        <div className="col">
          {event?.premium_subscription === true ? (
            <Button
              onClick={() => {
                // getMyItinerary(event._id, user?._id);
                console.log('myItinerary');
                console.log(myItinerary);
                setMyItineraryClicked(!myItineraryClicked);
              }}
              style={{
                background: 'white',
                // color: 'blue',
                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
                padding: '0px 12px',
                borderWidth: '1px',
              }}
            >
              {myItineraryClicked ? 'Close Itinerary' : 'My Itinerary'}
            </Button>
          ) : (
            <></>
          )}
          {myItineraryClicked && (
            // <div className="vertical-artist-container">
            <div>
              <div className="col">
                <MyItinerary
                  itinerary={myItinerary}
                  date={'10-30-2022'}
                  event={event}
                />

                {/* <h1>hmm</h1> */}
              </div>
            </div>
          )}
        </div>
        {event.event_type === 'concert' ? (
          <></>
        ) : (
          <div
            className="col"
            style={{ paddingTop: '10px', paddingBottom: '10px' }}
          >
            {event.premium_subscription === true ? (
              <div className="col">
                {/* <Link to={`/go-pro-subscription/${event?._id}`}> */}
                <button
                  // className="rounded-pill"
                  style={{
                    width: '150px',
                    // background: 'white',
                    // color: 'blue',
                    borderStyle: 'solid',
                    borderColor: 'rgb(1, 126, 252)',
                    borderRadius: '2px',
                    borderWidth: '1px',
                    fontSize: '14px',
                    backgroundColor: 'green',
                    color: 'white',

                    textAlign: 'center',
                  }}
                >
                  Premium Event 💪🏼
                </button>
              </div>
            ) : (
              <div className="col">
                <Link to={`/go-pro-event/${event?._id}`}>
                  <button
                    // className="rounded-pill"
                    style={{
                      width: '100px',
                      background: 'white',

                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',
                      borderWidth: '1px',
                      borderRadius: '2px',

                      fontSize: '14px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      textAlign: 'center',
                    }}
                  >
                    Go Pro 🚀
                  </button>
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      <div
        class="container-fluid  overflow-auto example"
        style={{ padding: '0px' }}
      >
        <div class="d-flex flex-row flex-nowrap">
          {/* <div style={{ paddingTop: '10px', paddingBottom: '10px' }}> */}

          {event?.event_type === 'dance_event' && (
            <div className="card-horizontal-menu card-block mx-2">
              {/* commented on 4.19.24 for conversion to Event 0.2 */}
              {/* <WorkshopScheduleListFormat event={event} /> */}

              {/* 
              commenting on 4.30.24 bcuz for event0.2 we are auto showing this without user action
              <WorkshopScheduleListFormatNewLayout event={event} />
            
              */}

              {/* <div>
        <DanceReactions dance_reaction_elected={false} />
      </div> */}
            </div>
          )}

          {event?.event_type === 'dance_event' && (
            // <div className="col-3">
            // <div>
            <div className="card-horizontal-menu card-block mx-2">
              {/* <div> */}
              {/* <div className="card-horizontal-menu card-block mx-2"> */}
              {/* <Link to={`artist-lineup`}> */}

              {/* <Button className="btn btn-info" onClick={handleShowLineup}>
        See Lineup
      </Button> */}

              {/* <div className="card-horizontal-menu card-block mx-2"> */}

              {/* changes from jan 28 2024 */}
              {/* <SeeArtistLineup event={event} user={user} /> */}

              {/* commented on 4.19.20 to implement HorizontalLineupWithPrivateLessonPopup.js - a part of Events 0.2 */}
              {/* <SeeArtistLineupWithPrivateLessonPopup
                event={event}
                user={user}
              /> */}
              {/* most up to date lineup - 4.19.20 */}
              {/* <HorizontalLineupWithPrivateLessonPopup
                event={event}
                user={user}
              /> */}
              {/* <HorizontalLineupNonModalWithPrivateLessonPopup
                event={event}
                user={user}
              /> */}
            </div>
          )}

          {event?.event_type === 'dance_event' &&
          (event.hostId === user?._id ||
            user?.email === 'airmeus.team@gmail.com') ? (
            // <div className="col">
            // <div>
            <div className="card-horizontal-menu card-block mx-2">
              <EditArtistLineup event={event} />
            </div>
          ) : (
            <></>
          )}

          {/* <WorkshopSchedule /> was within the following event.premium code, but 
      wasnt being used at the time */}
          {/* <WorkshopSchedule event={event} /> */}
          {(event?.premium_subscription === true &&
            event.hostId === user?._id &&
            event?.event_type === 'dance_event') ||
          user?.email === 'airmeus.team@gmail.com' ||
          user?.roles?.includes('instructor') ||
          (user?.artist_id && user?.artist_id !== '') ? (
            <div className="card-horizontal-menu card-block mx-2">
              <WorkshopScheduleNoPopup event={event} />
            </div>
          ) : (
            <></>
          )}

          {/* we commented out the chat bcuz it's too buggy - april 2024 */}
          {/* <div className="card-horizontal-menu card-block mx-2">
            <Chat event={event} selectedChat={false} />
          </div> */}
          {/* commenting for event0.2 

          {event?.event_type === 'dance_event' && (
            <div className="card-horizontal-menu card-block mx-2">
              <EventFeedback event={event} />
            </div>
          )}
            */}
          {/* im not sure when this was commented out or if this is needed anymore.. i dont think it is
            
            <WorkshopScheduleNoPopup event={event} /> */}

          {/* <div className="card-horizontal-menu card-block mx-2">
            <Link
              to="/incident-report"
              state={{ event_name: event?.name, event_id: event?._id }}
            >
              <Button
                style={{
                  background: 'white',
                  // color: 'blue',
                  borderStyle: 'solid',
                  borderColor: 'rgb(1, 126, 252)',

                  fontSize: '14px',
                  backgroundColor: 'white',
                  color: 'rgb(1, 126, 252)',
                  textAlign: 'center',
                  padding: '0px 12px',
                  borderWidth: '1px',
                  width: 'max-content',
                }}
              >
                Incident Report
              </Button>
            </Link>
          </div> */}
        </div>
      </div>
      {event?.event_type === 'dance_event' ? (
        <div className="row" style={{ paddingBottom: '10px' }}>
          <SafetyOfficer event={event} />
        </div>
      ) : (
        <></>
      )}
      {/* <div>
        <LineupHorizontal />
      </div> */}
      {/* Event0.2 */}
      {event?.event_type === 'dance_event' ? (
        <div>
          <div className="card">
            <h1>Workshops</h1>
            <div
              class="container-fluid  overflow-auto example"
              style={{ padding: '0px' }}
            >
              <div class="d-flex flex-row flex-nowrap">
                <div className="card-horizontal-menu card-block mx-2">
                  {/* <WorkshopScheduleListFormatNewLayout event={event} /> */}

                  <WorkshopTest event={event} />
                </div>
              </div>
            </div>
          </div>

          <HorizontalLineupNonModalWithPrivateLessonPopup
            event={event}
            user={user}
          />
        </div>
      ) : (
        <></>
      )}
      {event?.event_type === 'concert' ? (
        <div className="card">
          <h1
            style={{
              fontSize: '24px',

              backgroundColor: 'grey',
              borderStyle: 'solid',
              borderColor: 'grey',
              borderWidth: '2px',
              borderRadius: '6px',
              color: 'white',
              textAlign: 'center',
              // width: '300px',
            }}
          >
            Trivia Connect
          </h1>

          <Trivia
            eventTrivia={event?.trivia}
            event_id={event?._id}
            event={event}
          />
        </div>
      ) : (
        <></>
      )}
      {event?.event_type === 'concert' ? (
        <></>
      ) : (
        <div>
          <div className="row card all-center">
            <div className="col">
              <h1 htmlFor="">Meet Your Host</h1>
            </div>
            <div className="col">
              <div className="bigScreenHost smallScreenHost">
                <Link to={`/user/profile/${event?.hostId}`}>
                  <img
                    src={event?.hostImage ? event.hostImage.url : imgg}
                    alt="user"
                    style={{
                      height: '100px',
                      width: '100px',
                    }}
                    className="rounded-circle"
                  />
                </Link>
              </div>
            </div>
            <div className="col">
              <h3 style={{ paddingTop: '16px' }}>{event?.hostName}</h3>
            </div>

            <div className="col">
              {/* <Link to={`/user/profile/${event.hostId}`} className="btn btn-info">
            {event?.hostName}
          </Link> */}
              {/* <label>{event?.hostName}</label> */}
              {typeof event?.hostId !== 'undefined' &&
              event?.hostId === user?._id ? (
                <button className="btn btn-info" onClick={handleShow}>
                  Edit
                </button>
              ) : (
                <></>
              )}
              {/* {typeof event?.hostId !== 'undefined' &&
          event?.hostId === user?._id ? (
            <button
              type="button"
              className="btn btn-danger"
              // onClick={(e) => deleteEvent(event._id)}
              onClick={handleShowDelete}
            >
              Delete
            </button>
          ) : (
            <></>
          )} */}
            </div>
          </div>
        </div>
      )}

      {/* <hr /> */}
      {/* commented out on 4.23.24 for Event0.2 upgrade */}
      {/* <TabMenuItems
        event={event}
        attendeeButtonNumberTitle={attendeeButtonNumberTitle}
        page={page}
      /> */}
      <AttendeeList event={event} />
      {event?.event_type === 'concert' ? (
        <></>
      ) : (
        <div>
          <RoomShare event={event} />

          {/* feedback */}
          <hr />
          <div className="card">
            <EventFeedback_v02 event={event} />
          </div>

          <div className="card">
            <h1>Report An Incident</h1>
            <div className="card-horizontal-menu card-block mx-2">
              {/* <WorkshopScheduleNoPopup event={event} /> */}
              <Link
                to="/incident-report"
                state={{ event_name: event?.name, event_id: event?._id }}
              >
                <Button
                  style={{
                    background: 'white',
                    // color: 'blue',
                    borderStyle: 'solid',
                    borderColor: 'rgb(1, 126, 252)',

                    fontSize: '20px',
                    backgroundColor: 'white',
                    color: 'rgb(1, 126, 252)',
                    textAlign: 'center',
                    padding: '0px 12px',
                    borderWidth: '1px',
                    width: 'max-content',
                  }}
                >
                  Incident Report
                </Button>
              </Link>
            </div>
          </div>

          {/* test june 21 2024 */}

          <div className="card">
            <SubEvents event={event} />
          </div>
        </div>
      )}
      {/* <div className="smallScreen">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="roommates" title="Room Share">
            <Roommates event={event} />
          </Tab>
          <Tab
            eventKey="attendees"
            title={`${attendeeButtonNumberTitle} Attendees`}
          >
            <Attendees event={event} event_attendees={event.attendees} />
          </Tab>
          <Tab eventKey="subevent" title="Activities">
            <SubEvents event={event} />
          </Tab>
          <Tab eventKey="vendor" title="Vendors">
            <Vendors event={event} />
          </Tab>

          <Tab
            eventKey="performances"
            title={
              <React.Fragment>
                Performances
                <Badge bg="danger">new</Badge>
              </React.Fragment>
            }
          >
            <Performer event={event} />
          </Tab>
        </Tabs>
      </div> */}
      {show ? editingEvent : notEditingRenderNothing}
      {/* {showDelete ? deletingEvent : notEditingRenderNothing} */}
      {/* {showCreateLineup ? showArtistLineup : dontShowArtistLineup} */}
      {/* render attendees under attendees h1 or bring the attendees to a child component */}
    </div>
  );
  // }
  // );
};
export default EventDetailWithCategorySpecificMenu;

/*
graveyard jan 9 2024 


 <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Event</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={onSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Event Name</Form.Label>
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  value={name}
                  onChange={onChange}
                  autoFocus
                />
                <Form.Label>Event Description</Form.Label>
                <Form.Control
                  id="descr"
                  name="descr"
                  type="text"
                  value={descr}
                  onChange={onChange}
                  autoFocus
                />
             
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => setDeleteButtonClicked(true)}
            >
              Delete
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={onSubmit}>
              Save Changes
            </Button>
            {deleteButtonClicked ? (
              <div>
                <div>
                  <label htmlFor="">
                    Are you sure you want to delete this event?
                  </label>
                </div>
                <div className="row">
                  <div className="col">
                    <Link to="/">
                      <Button
                        onClick={() => {
                          deleteEvent(event._id);
                        }}
                        className="col btn btn-danger"
                      >
                        Delete
                      </Button>
                    </Link>
                  </div>
                  <div className="col">
                    <Button onClick={() => setDeleteButtonClicked(false)}>
                      no
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </Modal.Footer>
        </Modal>

*/

// import React, { Component } from 'react';
// import { useLocation } from 'react-router-dom';

// export default class EventDetail extends Component {
//   render() {
//     const location = useLocation();
//     const { event } = location.state;
//     console.log(event);
//     return (
//       <div>
//         <h1>event detail page.. add tab components below</h1>
//         <br />
//         <h1>Feed</h1>
//         <h1>Roommates</h1>
//         <h1>Attendees</h1>
//       </div>
//     );
//   }
// }
