import React, { Component, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import AdPanelStyle from '../currentUserProfile/AdPanelStyle';
import AdvertisementContext from '../../../context/advertisement/advertismentContext';
import { Link } from 'react-router-dom';

const AdPanel = (props) => {
  const advertisementContext = useContext(AdvertisementContext);
  const { getSideAd, side_advertisement } = advertisementContext;

  useEffect(() => {
    console.log(
      'getting events from Event.js but need to change component name'
    );
    //returns all users -- we need to paginate this so we only get one at a time.
    //that comes later
    getSideAd();
    console.log(side_advertisement);
    console.log('side_advertisement');
    // eslint-disable-next-line
  }, []);

  return (
    <AdPanelStyle>
      <div class="sidenav">
        <h5>Ad</h5>
        <div className="all-center">
          {/* <div className="col-4 col-md-3 m-auto"> */}
          <img
            className="rounded-circle"
            //   src={profile.user.avatar}
            src={side_advertisement.adImage?.url}
            // src={image}
            alt=""
            height="150"
            width="150"
          />
          {/* </div> */}
          <h5>{side_advertisement.promotionText}</h5>
          <Button className="text-center" variant="btn btn-info">
            {/* Like */}
            <Link
              to={`/user/profile/${side_advertisement.ownersId}`}
              className="btn btn-info"
            >
              View Profile
            </Link>
          </Button>
        </div>
      </div>
    </AdPanelStyle>
  );
};
export default AdPanel;
