import React, { useReducer } from 'react';
import axios from 'axios';
import StudioContext from './studioContext'; //be sure the case is not case sensitive
import StudioReducer from './studioReducer';

import {
  GET_ONE_STUDIO,
  GET_ALL_STUDIOS,
  GET_STUDIOS_BY_REGION,
  GET_STUDIO_MEMBERS,
  STUDIO_ERROR,
  ADD_NEW_MEMBER,
  ADD_NEW_LEVEL,
  GET_STUDIO_LEVELS,
  UPDATE_MEMBER_LEVEL,
  GET_STUDIO_MEMBER,
  UPDATE_STUDENTS_SCORES,
  ADD_NEW_STUDIO,
  GET_USER_STUDIOS,
  GET_COMPANY_EVENTS,
  CLAIM_STUDIO_PROFILE_REQUEST,
  JOIN_STUDIO,
  ADD_PREMIUM_SUBSCRIPTION,
  GET_TOP_STUDIOS_LIMIT_FIVE,
  GET_STUDIOS_FOR_SEARCH_CRITERIA,
  GET_ALL_PERFORMANCES,
  ENROLL_STUDENT,
  GET_STUDIOS_FOR_TIMELINE,
  JOIN_STUDIO_FROM_ONBOARD,
  EDIT_STUDIO,
} from '../types';
const StudioState = (props) => {
  let initialState = {
    studio: {},
    studiosByRegion: [],
    members: [],
    newMember: {},
    allStudios: [],
    topStudiosLimitFive: [],
    studioSearchResults: [],
    newLevel: {},
    studio_levels: [],
    studio_member: {},
    updatedStudent: {},
    newStudio: {},
    userStudios: [],
    companyEvents: [],
    studioProfileRequest: {},
    premium_subscription: false,
    all_performances: [],
    random_studios_for_timeline: [],
    loading: true,
  };

  const [state, dispatch] = useReducer(StudioReducer, initialState);

  const getStudioById = async (id) => {
    try {
      const res = await axios.get(`/api/studio/${id}`);
      console.log('res below from getStudioById studiostate.js');
      console.log(res);

      dispatch({
        type: GET_ONE_STUDIO,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const getStudioMembers = async (id) => {
    try {
      const res = await axios.get(`/api/studio/members/${id}`);
      console.log('res below from getStudioMembers studiostate.js');
      console.log(res);

      dispatch({
        type: GET_STUDIO_MEMBERS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const getAllStudios = async () => {
    try {
      const res = await axios.get(`/api/studio`);
      console.log('res below from addNewDancer studiostate.js');
      console.log(res);

      dispatch({
        type: GET_ALL_STUDIOS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const getTopStudiosWithLimitOfFive = async () => {
    try {
      const res = await axios.get(`/api/studio/getStudiosLimitFiveResults`);
      console.log('res below from getStudiosWithLimitOfFive studiostate.js');
      console.log(res);

      dispatch({
        type: GET_TOP_STUDIOS_LIMIT_FIVE,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const get4RandomStudiosForTimeline = async () => {
    try {
      const res = await axios.get(`/api/studio/random/studios`);
      console.log('res below from get4RandomStudiosForTimeline studiostate.js');
      console.log(res);

      dispatch({
        type: GET_STUDIOS_FOR_TIMELINE,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const getStudiosByRegion = async () => {
    try {
      const res = await axios.get(`/api/studio/studiosByRegion`);
      console.log('res below from getStudiosByRegion studiostate.js');
      console.log(res);

      dispatch({
        type: GET_STUDIOS_BY_REGION,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const getStudiosForSearchCriteria = async (searchParams) => {
    try {
      console.log('custom STUDIO search from frontend');
      // let event_id = '624c8b4f236daaa095ba62e7';
      const res = await axios.get(`/api/studio/search/${searchParams}`);
      // const res = await axios.get('/api/events/all-my-events', obj);
      console.log('res.data');
      console.log(res.data);

      dispatch({
        type: GET_STUDIOS_FOR_SEARCH_CRITERIA,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const addNewMember = async (obj) => {
    try {
      const res = await axios.post(`/api/studio/add-member`, obj);
      console.log('res below from addNewmember studiostate.js');
      console.log(res);

      dispatch({
        type: ADD_NEW_MEMBER,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const addNewLevel = async (obj) => {
    try {
      const res = await axios.post(`/api/studio/addNewLevel`, obj);
      console.log('res below from addNewLevel studiostate.js');
      console.log(res);

      dispatch({
        type: ADD_NEW_LEVEL,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const getStudioLevels = async (id) => {
    try {
      const res = await axios.get(`/api/studio/studioLevels/${id}`);
      console.log('res below from getStudioLevels studiostate.js');
      console.log(res);

      dispatch({
        type: GET_STUDIO_LEVELS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  //updateOrAssignMemberLevel(member_id, user?.dance_company_id)

  const assignOrUpdateMemberLevel = async (obj) => {
    try {
      const res = await axios.post(
        `/api/studio/assignOrUpdateMemberLevel`,
        obj
      );
      console.log('res below from assignOrUpdateMemberLevel studiostate.js');
      console.log(res);

      dispatch({
        type: UPDATE_MEMBER_LEVEL,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const enrollMemberToClass = async (obj) => {
    try {
      const res = await axios.post(`/api/studio/enrollMemberToClass`, obj);
      console.log('res below from enrollMemberToClass studiostate.js');
      console.log(res);

      dispatch({
        type: ENROLL_STUDENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const getStudioMember = async (studio_id, member_id) => {
    try {
      const res = await axios.get(
        `/api/studio/member/${studio_id}/${member_id}`
      );

      console.log('res below from getStudioMember studiostate.js');
      console.log(res);

      dispatch({
        type: GET_STUDIO_MEMBER,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const updateStudentsScores = async (obj) => {
    try {
      const res = await axios.post(`/api/studio/updateStudentsScores`, obj);
      console.log('res below from updateStudentsScores studiostate.js');
      console.log(res);

      dispatch({
        type: UPDATE_STUDENTS_SCORES,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const addNewStudio = async (obj) => {
    try {
      initialState = {
        ...initialState,
        loading: true,
      };
      const res = await axios.post(`/api/studio`, obj);
      console.log('res below from addnewStudio studiostate.js');
      console.log(res);
      dispatch({
        type: ADD_NEW_STUDIO,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const editStudio = async (studio) => {
    try {
      console.log('trying to edit studio');
      const res = await axios.post('/api/studio/edit-studio', studio);
      console.log('editing studio');
      console.log(res);
      dispatch({
        type: EDIT_STUDIO,
        payload: res.data, //the response was
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const getUserStudios = async (user_id) => {
    try {
      const res = await axios.get(`/api/studio/allCompanies/${user_id}`);
      console.log('res below from getUserStudios studiostate.js');
      console.log(res);

      dispatch({
        type: GET_USER_STUDIOS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const getUpcomingEvents = async (studio_id) => {
    try {
      const res = await axios.get(`/api/studio/upcomingEvents/${studio_id}`);
      console.log('res below from getUpcomingEvents studiostate.js');
      console.log(res);

      dispatch({
        type: GET_COMPANY_EVENTS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const sendClaimProfileRequest = async (obj) => {
    try {
      const res = await axios.post(`/api/studio/claimProfileRequest`, obj);
      console.log('res below from claimProfileRequest studiostate.js');
      console.log(res);

      dispatch({
        type: CLAIM_STUDIO_PROFILE_REQUEST,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  //updates state.member
  const joinStudio = async (obj) => {
    try {
      const res = await axios.post(`/api/studio/join`, obj);
      console.log('res below from joinStudio studiostate.js');
      console.log(res);

      dispatch({
        type: JOIN_STUDIO,
        payload: res.data,
      });
      window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const joinStudioFromOnboard = async (obj) => {
    try {
      const res = await axios.post(`/api/studio/join-studio-from-onboard`, obj);
      console.log('res below from joinStudioFromOnboard studiostate.js');
      console.log(res);

      dispatch({
        type: JOIN_STUDIO_FROM_ONBOARD,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };

  const convertAccountToPremiumSubscription = async (obj) => {
    try {
      console.log(
        'trying to convertAccountToPremiumSubscription from studiostate'
      );
      const res = await axios.post(`/api/studio/add-subscription`, obj);
      console.log(
        'res below from convertAccountToPremiumSubscription studiostate.js'
      );
      console.log(res);

      dispatch({
        type: ADD_PREMIUM_SUBSCRIPTION,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  const getAllPerformances = async (studio_id) => {
    try {
      const res = await axios.get(
        `/api/studio/getAllPerformancesForStudio/${studio_id}`
      );
      console.log('res below from getAllPerformances studiostate.js');
      console.log(res);

      dispatch({
        type: GET_ALL_PERFORMANCES,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: STUDIO_ERROR,
        payload: err,
      });
    }
  };
  return (
    <StudioContext.Provider
      value={{
        // props -- where our state and actions will go
        studio: state.studio,
        allStudios: state.allStudios,
        members: state.members,
        newMember: state.newMember,
        newLevel: state.newLevel,
        studio_levels: state.studio_levels,
        studio_member: state.studio_member,
        updatedStudent: state.updatedStudent,
        newStudio: state.newStudio,
        userStudios: state.userStudios,
        companyEvents: state.companyEvents,
        studioProfileRequest: state.studioProfileRequest,
        premium_subscription: state.premium_subscription,
        topStudiosLimitFive: state.topStudiosLimitFive,
        studioSearchResults: state.studioSearchResults,
        all_performances: state.all_performances,
        studiosByRegion: state.studiosByRegion,
        random_studios_for_timeline: state.random_studios_for_timeline,

        loading: state.loading,

        addNewMember,
        getStudioMembers,
        getStudioById,
        getAllStudios,
        getTopStudiosWithLimitOfFive,
        getStudiosForSearchCriteria,
        addNewLevel,
        getStudioLevels,
        assignOrUpdateMemberLevel,
        getStudioMember,
        updateStudentsScores,
        addNewStudio,
        getUserStudios,
        getUpcomingEvents,
        sendClaimProfileRequest,
        joinStudio,
        joinStudioFromOnboard,
        convertAccountToPremiumSubscription,
        getAllPerformances,
        getStudiosByRegion,
        enrollMemberToClass,
        get4RandomStudiosForTimeline,
        editStudio,
      }}
    >
      {props.children}
    </StudioContext.Provider>
  );
};

export default StudioState;
