import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import imagee from '../../images/dance_event_icon.png';
const EventTypeButton = (props) => {
  const [itemClicked, setItemClicked] = useState(false);

  //   const returnButtonClicked = () => {
  //     console.log('button clicked');
  //     return itemClicked ? '1px solid black' : '';
  //   };

  const getEventType = () => {
    // let activity_type = subevent?.activity_type;
    // let event_type = 'Dance Event';
    switch (props.item.type) {
      case 'Dance Events':
        return 'dance_event';
      case 'City Festivals':
        return 'city_festival';
      case 'Concerts':
        return 'concert';
      case 'Homecoming':
        return 'homecoming';
      // case 'game night':
      //   return 'green';
      default:
        return 'other';
    }
  };
  return (
    <div
      class="card-horizontal-menu card-block mx-2"
      //   style={{ overflowX: 'hidden' }}
      style={{ textAlign: 'center', padding: '0px 5px 0px 0px' }}
    >
      <Button
        style={{
          backgroundColor: 'transparent',
          color: 'black',
          textAlign: 'center',
          padding: '0px',
          margin: '0px',
          //   width: 'fit-content',
          width: 'max-content',
          //   border-bottom 1px solid black
          borderBottom:
            itemClicked && props.eventType === props.item.type
              ? '1px solid black'
              : '',
          //   borderBottom: 'solid',
        }}
        onClick={() => {
          setItemClicked(!itemClicked);
          console.log('clicked yo');
          props.setEventType(props.item.type);
          props.getEventsHavingEventType(getEventType(props.item.type));
        }}
      >
        <div>
          {' '}
          <img
            // src="https://www.svgrepo.com/show/21736/house-outline.svg"
            // src={props.item.image}
            src={props.menuImages[props.index]}
            height="50"
            width="50"
            alt="user"
            //   style={{ borderRadius: '0.25rem' }}
            style={{ paddingBottom: '5px' }}
          />
        </div>

        {props.item.type}
      </Button>
    </div>
  );
};

export default EventTypeButton;
