import React, { Component } from 'react';
import SkillSection from './SkillSection';

const Skills = (props) => {
  let { dancer_types, artist_id, skills_and_endorsements, isArtist } = props;

  let bachataSkills = ['timing,musicality,connection,creativity'];
  let salsaSkills = ['salsa timing, s musicality, s connection,s creativity'];
  console.log('props from skills');
  console.log(props);
  return (
    <div>
      {dancer_types.map((dance_type) => (
        <SkillSection
          artist_id={artist_id}
          dance_type={dance_type}
          skills_and_endorsements={skills_and_endorsements}
          isArtist={isArtist}
          isCurrentUserProfile={props?.isCurrentUserProfile}
        />
      ))}
    </div>
  );
};
export default Skills;
