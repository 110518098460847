import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import TourDatePopup from './TourDatePopup';

const DateTest = (props) => {
  const { setDate2, setDateFromChild, datex, setDateX, setNewDate } = props;
  const [date3, setDate3] = useState(props.dateTest);
  const [dateClicked, setDateClicked] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setDateClicked(!dateClicked)}
        style={{
          fontSize: '12px',
          padding: '4px',
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',

          // width: '120px',
          borderWidth: '1px',
        }}
      >
        {date3 === undefined ? 'select date' : date3}
      </Button>

      {dateClicked ? (
        <div>
          <TourDatePopup
            dateClicked={dateClicked}
            setDateClicked={setDateClicked}
            date={date3}
            // setDate2={setDate3}
            setDate3={setDate3}
            // setDate2={setDate2}
            // setDateFromChild={setDateFromChild}
            // setDateX={setDateX}
            setNewDate={setNewDate}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DateTest;
