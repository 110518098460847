import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import React, { useState } from 'react';
import { useAuth } from '../../../context/auth/AuthState';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import userContext from '../../../context/user/userContext'; // might have to capitalize
import eventContext from '../../../context/event/eventContext'; // might have to capitalize
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

// function EditArtistLineup() {
const EditArtistLineup = (props) => {
  const eContext = useContext(eventContext);
  const {
    editEvent,
    deleteEvent,
    addToEventLineup,
    getAllArtists,
    all_artists,
    // getDictLineupForEvent,
    // lineupObject,
    getArtistsDictionary,
    all_artists_dict,
    addNewArtist,
  } = eContext;

  useEffect(() => {
    getAllArtists();
    // getDictLineupForEvent();
    getArtistsDictionary();
  }, []);

  const { event } = props;

  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const [showLineup, setShowLineup] = useState(false);
  const [artistType, setArtistType] = useState('bachata');

  const handleCloseLineup = () => {
    console.log('closing ');
    setShowLineup(false);
  };
  const handleShowLineup = () => {
    setShowLineup(true);
    console.log(`showLineup is: ${showLineup}`);
  };

  var isAllCheck = false;
  const handlePillClicked = (type) => {
    setArtistType(type);
    document
      .querySelectorAll('input[type=checkbox]')
      .forEach((el) => (el.checked = isAllCheck));
  };

  let lineup_array = []; //just [] of ids

  const onLineupChange = (e) => {
    console.log('changing lineup list -- e is:');
    // console.log(e.target);
    // setMemberArray(...lineup_array, e.target.name);
    // console.log(lineup_array);
    console.log('e.target');
    console.log(e.target);

    if (lineup_array.includes(e.target.id)) {
      console.log('removing user');
      lineup_array = lineup_array.filter((item) => item !== e.target.id);
    } else {
      console.log('adding user');
      lineup_array.push(e.target.id);
    }
    // const member_set = new Set(lineup_array);
    // const uniqueMembers = [...member_set];

    console.log(lineup_array);
  };

  const handleAddToEventLineup = () => {
    console.log('trying to handleAddToEventLineup ');
    //action from userState
    let obj = {
      event_id: event._id,
      lineup_array: lineup_array,
      artist_type: artistType,
    };
    addToEventLineup(obj);
  };

  const salsa_artists = [
    {
      name: 'aliana',
      _id: '62cdc9c2deb4e16a31cdd6b1',
    },
    {
      name: 'alfred',
      _id: '62cdca56deb4e16a31cdd6da',
    },

    {
      name: 'anon',
      _id: '62d2d60ac2f72ab2d63549d4',
    },
  ];

  const bachata_artists = [
    {
      name: 'bachata aliana',
      _id: '62d2dc09c2f72ab2d6354aa4',
    },
    {
      name: ' bachata alfred',
      _id: '62d6d4c044e57e3bd7a87697',
    },

    {
      name: 'bachata  anon',
      _id: '62d6d5b77b0d052aa37e6e71',
    },
  ];

  const renderArtists = () => {
    console.log('trying to render');
    if (artistType === 'salsa') {
      return salsa_artists;
    }
    if (artistType === 'bachata') {
      return bachata_artists;
    }

    return salsa_artists;
  };

  //dynamic buttons
  const renderSalsaButton = () => {
    if (artistType === 'salsa') {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          salsa
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          salsa
        </Button>
      );
    }
  };
  const renderBachataButton = () => {
    if (artistType === 'bachata') {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          bachata
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          bachata
        </Button>
      );
    }
  };

  const renderKizombaButton = () => {
    if (artistType === 'kizomba') {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          kizomba
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          kizomba
        </Button>
      );
    }
  };

  const renderZoukButton = () => {
    if (artistType === 'zouk') {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          zouk
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          zouk
        </Button>
      );
    }
  };

  console.log('all_artists[2]].bachata frontend');
  // console.log(all_artists[2].bachata);
  const renderArtistsDynamically = (artistTypeVar) => {
    // let artists_dict = new Map(Object.entries(all_artists));

    let artists_dict = new Map(Object.entries(all_artists_dict));

    console.log('all_artists_dict ---- ');
    console.log(all_artists_dict);
    console.log('artists_dict -----');
    console.log(artists_dict);

    return artists_dict.get(artistTypeVar);
  };

  const [artistDetails, setArtistDetails] = useState({
    name: '',
    image_url: '',
    artist_type: 'salsa',
  });
  const { name, image_url, artist_type } = artistDetails;

  const [showAddNewArtist, setShowAddNewArtist] = useState(false);
  const [file, setFile] = useState();

  const onChange = (e) =>
    setArtistDetails({ ...artistDetails, [e.target.name]: e.target.value });

  let artist_type_array = ['salsa', 'bachata', 'kizomba', 'zouk'];
  const [selectedArtistType, setSelectedArtistType] = useState('sasla');
  const handleArtistTypeChange = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    setArtistDetails({ ...artistDetails, ['artist_type']: type });
  };

  const onSubmitNewArtist = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', file); //image
    data.append('name', name);
    data.append('artist_type', artist_type);
    console.log('data--');
    console.log(artistDetails);
    addNewArtist(data);
  };

  const renderAddNewArtist = (
    <Fragment>
      <div>
        <h1>new artist</h1>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Artist Name"
          value={name}
          onChange={onChange}
          required
        />
        {/* <input
          id="descr"
          type="text"
          name="descr"
          placeholder="Event Description"
          value={descr}
          onChange={onChange}
          required
        /> */}

        <div className="col">
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="sm"
              variant="secondary"
              title={artist_type}
              onSelect={handleArtistTypeChange}
              // onSelect={() => {
              //   console.log(idx);
              //   handleArtistTypeChange();
              // }}
              // disabled="true"

              // onClick={() => {
              //   setLevelButtonClicked(!levelButtonClicked);
              // }}
              //   onSelect={handleSelectLevel}
            >
              {artist_type_array.map((type) => (
                <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>

        <div>
          <label for="image_url">Artist Image:</label>
        </div>

        <input
          id="image_url"
          name="image_url"
          type="file"
          value={image_url}
          required
          onChange={(event) => {
            const file = event.target.files[0];
            setFile(file);
            setArtistDetails({
              ...artistDetails,
              [event.target.name]: event.target.value,
            });
          }}
        />
        <div>
          <Button onClick={onSubmitNewArtist}>Add</Button>
        </div>
      </div>
    </Fragment>
  );
  const handleShowAddNewArtist = () => {
    setShowAddNewArtist(!showAddNewArtist);
    // setShowAddNewArtist(true);
  };
  return (
    <div>
      <Button
        onClick={() => handleShowLineup()}
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '0px 12px',
          width: '120px',
          borderWidth: '1px',
        }}
      >
        Edit Lineup
      </Button>
      <Modal show={showLineup} onHide={handleCloseLineup}>
        <Modal.Header closeButton>
          <Modal.Title>Add To Your Lineup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {renderSalsaButton()}
            {renderBachataButton()}
            {renderKizombaButton()}
            {renderZoukButton()}
            {/* <Button
              onClick={() => handlePillClicked('salsa')}
              className="bg-primary rounded-pill"
              style={{ width: '100px' }}
            >
              salsa
            </Button>
            <Button
              onClick={() => handlePillClicked('bachata')}
              className="bg-primary rounded-pill"
              style={{ width: '100px' }}
            >
              bachata
            </Button>
            <Button
              onClick={() => handlePillClicked('kizomba')}
              className="bg-primary rounded-pill"
              style={{ width: '100px' }}
            >
              kizomba
            </Button>
            <Button
              onClick={() => handlePillClicked('zouk')}
              className="bg-primary rounded-pill"
              style={{ width: '100px' }}
            >
              zouk
            </Button> */}
          </div>
          <div>
            <form className="form-inline my-2 my-lg-0">
              {/* <div className="row">
                <div className="col">
                  <input
                    id="searchText"
                    class="form-control mr-sm-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    name="searchText"
                  
                  />
                </div>
                <div className="col-2" style={{ margin: 'auto' }}>
                  <button
                    class="btn btn-primary my-2 my-sm-0"
                    type="submit"
                  >
                    Search
                  </button>
                </div>
              </div> */}

              {/* <Link to="/all-users"> */}
            </form>
            <div className="row">
              {!showAddNewArtist ? (
                <div className="col">
                  <label>Popular Artists</label>
                </div>
              ) : (
                <></>
              )}

              <div className="col" onClick={handleShowAddNewArtist}>
                <Button>
                  {showAddNewArtist === false ? `Add New Artist` : `close`}
                </Button>
              </div>
              {showAddNewArtist ? renderAddNewArtist : <></>}
            </div>
          </div>

          {!showAddNewArtist ? (
            <div className="vertical-artist-container">
              <div className="col">
                {/* <div> */}
                {renderArtistsDynamically(artistType)?.map((user) => (
                  // {renderArtists().map((user) => (
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name={user.name}
                      // id={user._id}
                      id={user._id}
                      value="option1"
                      onChange={onLineupChange}
                    />
                    {/* <img
                    src={user.image ? user?.image.url : imgg}
                    height="100"
                    alt="user"
                  /> */}

                    {/* <div className="artistStyle"> */}
                    <img
                      src={user?.image_url ? user?.image_url.url : imgg}
                      height="100"
                      width="100"
                      alt="user"
                      style={{ borderRadius: '0.25rem' }}
                    />
                    {/* </div> */}

                    <label class="form-check-label" for={user._id}>
                      {user.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLineup}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddToEventLineup}>
            Save Lineup
          </Button>
        </Modal.Footer>
      </Modal>
      {/* render attendees under attendees h1 or bring the attendees to a child component */}
    </div>
  );
};
export default EditArtistLineup;
