import React, { Component, useState, useContext, useEffect } from 'react';
import PrivateLessonRequest from './PrivateLessonRequest';
import PrivateLessonRequestUpdated from './PrivateLessonRequestUpdated';

const PrivateLessonRequests = (props) => {
  const privatelessonRequests = props.privatelessonRequests;
  console.log('privatelessonRequests from PLR.js');
  console.log(privatelessonRequests);
  console.log('props from PrivateLessonRequests.js');
  console.log(props);
  return (
    <div>
      {/* <h5>requests</h5> */}

      {privatelessonRequests?.map((request) => (
        // <div>{request.req_name}</div>
        // commented on 6.8.23
        // <PrivateLessonRequest privatelessonRequest={request} />
        <PrivateLessonRequestUpdated
          privatelessonRequest={request}
          event_id={props.event_id}
          event_name={props.event_name}
        />
      ))}
    </div>
  );
};

export default PrivateLessonRequests;
