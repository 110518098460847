import React, { Component, useEffect } from 'react';

const CompletedTriviaResponses = (props) => {
  // const [authState] = useAuth();

  // const { user } = authState;

  let { user, eventTrivia, matchedUser } = props;

  if (matchedUser) {
    user = matchedUser;
  }
  // let eventTriviaId = eventTrivia?.trivia_id;
  // let usersTriviasPlayed = user?.trivias_played;
  useEffect(() => {
    // if (eventTrivia) {

    // }

    if (matchedUser) {
      // user = matchedUser;
      getUsersTriviaResponses(matchedUser, eventTrivia);
    } else {
      getUsersTriviaResponses(user, eventTrivia);
    }

    // eslint-disable-next-line
  }, []);

  const getUsersTriviaResponses = (user, eventTrivia) => {
    let triviaResponses = [];
    console.log('eventTrivia from subr');
    console.log(eventTrivia);
    let eventTriviaId = eventTrivia?.trivia_id;
    let usersTriviasPlayed = user?.trivias_played;

    if (usersTriviasPlayed && usersTriviasPlayed.length > 0) {
      for (
        let userTriviaIndex = 0;
        userTriviaIndex < usersTriviasPlayed.length;
        userTriviaIndex++
      ) {
        console.log('usersTriviasPlayed[userTriviaIndex].trivia_id.toString()');
        console.log(usersTriviasPlayed[userTriviaIndex].trivia_id.toString());
        console.log('eventTriviaId');
        console.log(eventTriviaId);

        if (
          usersTriviasPlayed[userTriviaIndex].trivia_id.toString() ===
          eventTriviaId
        ) {
          //add trivia to []

          triviaResponses =
            usersTriviasPlayed[userTriviaIndex].questions_and_users_answers;
        }
      }
    }
    console.log('triviaResponses are:');
    console.log(triviaResponses);
    return triviaResponses;
  };

  return (
    <div>
      {matchedUser ? (
        <h5>{user.name + 's '} Completed Responses: </h5>
      ) : (
        <h5>Your Completed Responses: </h5>
      )}

      <div className="row">
        {/* <h5></h5> */}

        {/* {triviaResponses.map((r) => ( */}
        {getUsersTriviaResponses(user, eventTrivia).map((r) => (
          <div>
            <h5>{r.question}</h5>
            <h5
              style={{
                backgroundColor: 'rgb(1, 126, 252)',
                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',
                color: 'white',
                textAlign: 'center',
                width: '240px',
              }}
            >
              {r.answer}
            </h5>
            <hr />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompletedTriviaResponses;
