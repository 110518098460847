import React, { Component, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

const EventType = (props) => {
  let event_type_array = [
    'Dance Event',
    'City Festival',
    'Homecoming',
    'Concert',
    'Other',
  ];
  let [etype, setEType] = useState(props.event_type);
  let details = props.details;

  const getEventType = (event_type) => {
    // let activity_type = subevent?.activity_type;
    // let event_type = 'Dance Event';
    switch (event_type) {
      case 'Dance Event':
        return 'dance_event';
      case 'City Festival':
        return 'city_festival';
      case 'Concert':
        return 'concert';
      case 'Homecoming':
        return 'homecoming';
      case 'Other':
        return 'other';
      // case 'game night':
      //   return 'green';
      default:
        return 'dance_event';
    }
  };
  const handleSelectEventType = (event_type) => {
    //send request to backend to update level
    //updateWorkshopLevel(level)
    // props.setEventType(e);
    console.log('e is');
    console.log(event_type);
    setEType(event_type);

    let event_type_name_for_backend = getEventType(event_type);
    console.log('event_type_name_for_backend');
    console.log(event_type_name_for_backend);
    props.setDetails({ ...details, event_type: event_type_name_for_backend });
    // setLevelTitle(e);
    // updatedWorkshop.level = e;
  };
  return (
    <div>
      <div className="form-container">
        {[DropdownButton].map((DropdownType, idx) => (
          <DropdownType
            as={ButtonGroup}
            key={idx}
            id={`dropdown-button-drop-${idx}`}
            size="sm"
            variant="secondary"
            title={etype}
            onSelect={
              handleSelectEventType
              //   setEType(event_type_array[idx]);
            }

            // onSelect={props.onChangeValue}
          >
            {event_type_array.map((event_type) => (
              <Dropdown.Item eventKey={event_type}>{event_type}</Dropdown.Item>
            ))}
            {/* <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
          </DropdownType>
        ))}
      </div>
    </div>
  );
};

export default EventType;
