import React, { Component, useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import EventFeedbackCategory from './EventFeedbackCategory';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import { useAuth } from '../../context/auth/AuthState';
import FeedbackForm from './FeedbackForm';
import AttendeeFeedback from './AttendeeFeedback';

const AddFeedBackForm = (props) => {
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;
  const eventContext = useContext(EventContext);
  const { addEventFeedback, submitted_event_feedback } = eventContext;
  const showDancers = () => {
    // let showDancers = false;

    if (props.dance_reaction_elected === true) {
      console.log('props from event AddFeedBackForm');
      console.log(props);
      return props.dance_reaction_elected;
    } else {
      return false;
    }
  };
  const [showDanceReactions, setShowDanceReactions] = useState(showDancers);
  const handleCloseDanceReactions = () => {
    console.log('closing ');
    setShowDanceReactions(false);
  };

  const [details, setDetails] = useState({
    event_id: '',
    feedback_giver_id: '',
    feedback_giver_name: '',
    feedback_categories: [],
    general_comment: '',
    how_can_this_event_improve: '',
    what_did_you_love_about_this_event: '',
  });

  const {
    event_id,
    feedback_giver_id,
    feedback_giver_name,
    feedback_categories,
    general_comment,
    how_can_this_event_improve,
    what_did_you_love_about_this_event,
  } = details;

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  let feedbackCategories = [
    {
      category: 'Music/DJs',
      categoryScore: '1',
    },
    {
      category: 'Instructors',
      categoryScore: '1',
    },
    {
      category: 'Workshops',
      categoryScore: '1',
    },
    {
      category: 'Facilities',
      categoryScore: '1',
    },

    {
      category: 'Value',
      categoryScore: '1',
    },
  ];
  const [
    feedbackCategoriesAndScores,
    setFeedbackCategoriesAndScores,
  ] = useState(feedbackCategories);

  const setFieldsFromChildComponent = (score, indexToChange) => {
    console.log('score,indexToChange from child');
    console.log(score, indexToChange);
    const key = 'categoryScore';
    setFeedbackCategoriesAndScores([
      ...feedbackCategoriesAndScores.slice(0, indexToChange),
      {
        // here update data value
        ...feedbackCategoriesAndScores[indexToChange],
        [key]: score,
      },
      ...feedbackCategoriesAndScores.slice(indexToChange + 1),
    ]);
    console.log('feedbackCategoriesAndScores after update ??');
    console.log(feedbackCategoriesAndScores);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let obj = {
      // dancer_reaction_id_to_react_to: dancer._id,
      // reacting_user_id: isAuthenticated ? user._id : '',
      // reacting_user_name: isAuthenticated ? user.name : '',
      // //   reacting_user_name: {user ? user.name:""},
      // reaction_emoji: selectedEmoji,
      // comment: '',

      event_id: props.event._id,
      feedback_giver_id: isAuthenticated ? user._id : '',
      feedback_giver_name: isAuthenticated ? user.name : '',
      feedback_categories: feedbackCategoriesAndScores,
      general_comment,
      how_can_this_event_improve,
      what_did_you_love_about_this_event,
    };

    // if (dancer_name === '') {
    //   //   setAlert('Please fill in all fields', 'danger');
    //   console.log('type in dancer name');
    // } else {
    // setDetails({
    //   ...details,
    //   [dance_company_id]: 'help',
    // });

    console.log(
      'feedbackCategories from eventfeedback.js --- should be updated'
    );
    console.log(feedbackCategoriesAndScores);
    console.log('----------------');
    console.log('obj from eventfeedback');
    console.log(obj);
    addEventFeedback(obj);
    // console.log('e is');
    // console.log(e);

    // const newObj = {
    //   bib_number: bib_number,
    //   dancer_name: dancer_name,

    //   category: category,
    // };
    // console.log('newObj to pass to backend');
    // console.log(newObj);

    // addDancer(newObj);
  };
  const [showFields, setShowFields] = useState(false);
  const [showAttendeeFeedback, setShowAttendeeFeedback] = useState(true);

  const [
    showFeedBackSubmittedMessage,
    setShowFeedBackSubmittedMessage,
  ] = useState(false);

  const feedbackSubmitted = () => {
    setShowFeedBackSubmittedMessage(!showFeedBackSubmittedMessage);
    setShowFields(!showFields);
    setShowAttendeeFeedback(true);
  };
  const messageText = 'Your feedback was submitted, Thank you!';
  return (
    <div>
      <Modal show={showDanceReactions} onHide={handleCloseDanceReactions}>
        <Modal.Header closeButton>
          <Modal.Title>Event Feedback</Modal.Title>
        </Modal.Header>
        <div>
          <Modal.Body>
            <Button
              onClick={() => {
                setShowFields(!showFields);
                setShowAttendeeFeedback(!showAttendeeFeedback);
              }}
              style={{
                background: 'white',
                // color: 'blue',
                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
              }}
            >
              {showFields ? 'Show Other Feedback' : 'Add Feedback'}
            </Button>

            <div
              className="promotionText"
              style={{ backgroundColor: 'green', color: 'white' }}
            >
              {showFeedBackSubmittedMessage ? (
                <div>
                  <h4>{messageText}</h4>
                </div>
              ) : (
                <></>
              )}
            </div>

            {showFields && (
              <FeedbackForm
                feedbackCategories={feedbackCategories}
                event={props.event}
                handleFeedbackSubmitted={feedbackSubmitted}
              />
            )}

            {!showFields &&
              showAttendeeFeedback && <AttendeeFeedback event={props.event} />}

            {/* // <form onSubmit={onSubmit}>
            //   <div className="row">
            //     <div className="row">
            //       {feedbackCategories.map((category, index) => (
            //         <div style={{ paddingBottom: '10px' }}>
            //           <EventFeedbackCategory
            //             category={category}
            //             indexToChange={index}
            //             setFieldsFromChildComponent={
            //               setFieldsFromChildComponent
            //             }
            //           />
            //         </div>
            //       ))}
            //     </div> 
            //   </div>
            //   <input
            //     type="submit"
            //     value="Add Feedback"
            //     class="btn btn-secondary"
            //   />
            // </form> */}
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDanceReactions}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

export default AddFeedBackForm;
