import {
  GET_CHATS_FOR_EVENT_AND_USER,
  GET_SINGLE_CHAT_MESSAGES,
  SEND_MESSAGE,
  ADD_NEW_GROUP_CHAT,
  JOIN_CHAT,
  CHAT_ERROR,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_CHATS_FOR_EVENT_AND_USER:
      return {
        ...state,
        event_chat_for_user: action.payload,
        loading: false,
      };
    case GET_SINGLE_CHAT_MESSAGES:
      return {
        ...state,
        singleChatMessages: action.payload,
        loading: false,
      };
    case SEND_MESSAGE:
      return {
        ...state,
        // singleChatMessages: action.payload,
        loading: false,
      };
    case ADD_NEW_GROUP_CHAT:
      return {
        ...state,
        // singleChatMessages: action.payload,
        loading: false,
      };
    case JOIN_CHAT:
      return {
        ...state,
        // singleChatMessages: action.payload,
        loading: false,
      };

    case CHAT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
