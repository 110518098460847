import React, { useReducer } from 'react';
import axios from 'axios';
// import EventContext from './eventContext'; //be sure the case is not case sensitive
// import EventReducer from './eventReducer';
import EventsThisWeekContext from './eventsThisWeekContext'; //be sure the case is not case sensitive
import EventsThisWeekReducer from './eventsThisWeekReducer';
// import GET_USERS_ONE_AT_A_TIME from '../types';
import {
  GET_EVENTS_THIS_WEEK,
  CREATE_EVENT_THIS_WEEK,
  GET_BOOSTED_EVENTS_THIS_WEEK,
  EVENTS_THIS_WEEK_ERROR,
  GET_EVENTS_THIS_WEEK_BY_LOCATION,
} from '../types';

const EventsThisWeekState = (props) => {
  const initialState = {
    eventsThisWeek: [],
    eventsThisWeekByLocation: [],
    event: {},
    boostedEventsThisWeek: [],
    error: null,
  };
  const [state, dispatch] = useReducer(EventsThisWeekReducer, initialState);

  // Get Users
  const getAllEventsThisWeek = async () => {
    try {
      console.log('running getAllEvents()');
      const res = await axios.get('/api/eventsThisWeek');
      console.log('results should be:');
      console.log(res.data);
      dispatch({
        type: GET_EVENTS_THIS_WEEK,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENTS_THIS_WEEK_ERROR,
        payload: err.response.msg,
      });
    }
  };
  const getBoostedEventsThisWeek = async () => {
    try {
      console.log('running getBoostedEventsThisWeek()');
      const res = await axios.get('/api/eventsThisWeek/boostedEventsThisWeek/');
      console.log('results should be:');
      console.log(res.data);
      dispatch({
        type: GET_BOOSTED_EVENTS_THIS_WEEK,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENTS_THIS_WEEK_ERROR,
        payload: err.response.msg,
      });
    }
  };

  // const createNewEvent = async (name, descr, hostId) => {
  const createNewEvent = async (form) => {
    try {
      console.log(` form is ${form}`);
      console.log(
        'trying to createNewEventThisWeek backend from EventThisWeekState'
      );
      // const res = await axios.post('/api/events/join', name, hostId);

      // const requestObject = {
      //   name: name,
      //   descr: descr,
      //   hostId: hostId,
      // };

      const res = await axios.post('/api/eventsThisWeek', form);

      dispatch({
        type: CREATE_EVENT_THIS_WEEK,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: EVENTS_THIS_WEEK_ERROR,
        payload: err.response,
      });
    }
  };

  const getEventsThisWeekByLocation = async (city, state, dance_type) => {
    try {
      console.log('trying to getEventsThisWeekByLocation ');
      const res = await axios.get(
        `/api/eventsThisWeek/eventsThisWeekByLocation/${city}/${state}/${dance_type}`
      );

      console.log('res below from topliststate.js');
      console.log(res);

      dispatch({
        type: GET_EVENTS_THIS_WEEK_BY_LOCATION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: EVENTS_THIS_WEEK_ERROR,
        payload: err,
      });
    }
  };

  return (
    <EventsThisWeekContext.Provider
      value={{
        // props -- where our state and actions will go
        eventsThisWeek: state.eventsThisWeek,
        eventsThisWeekByLocation: state.eventsThisWeekByLocation,
        event: state.event,
        boostedEventsThisWeek: state.boostedEventsThisWeek,

        getAllEventsThisWeek,
        createNewEvent,
        getBoostedEventsThisWeek,
        getEventsThisWeekByLocation,
      }}
    >
      {props.children}
    </EventsThisWeekContext.Provider>
  );
};

export default EventsThisWeekState;
