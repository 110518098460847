import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';
import CalendarEventsUpdated from '../artist/CalendarEventsUpdated';
import PrivateLessonRequestNoEvent from '../artist/PrivateLessonRequestNoEvent';
import { useAuth } from '../../context/auth/AuthState';
import ConfirmedPrivateLessons from '../instructor/ConfirmedPrivateLessons';
import ConfirmedPrivateLesson from '../instructor/ConfirmedPrivateLesson';
// following the example of Calendar2 (which is the Instructor private lesson info)
const ArtistInstructorPrivateLessons = (props) => {
  const [authState] = useAuth();

  const { user } = authState;
  const [showPrivateLessonRequests, setShowPrivateLessonRequests] = useState(
    false
  );
  const [
    showConfirmedPrivateLessons,
    setShowConfirmedPrivateLessons,
  ] = useState(false);
  return (
    <div>
      {/* <h5>ArtistInstructorPrivateLessons</h5> */}

      <Button
        onClick={() => {
          setShowConfirmedPrivateLessons(!showConfirmedPrivateLessons);
          setShowPrivateLessonRequests(false);
        }}
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '6px',
        }}
      >
        Confirmed Lessons
      </Button>
      <Button
        onClick={() => {
          setShowPrivateLessonRequests(!showPrivateLessonRequests);
          setShowConfirmedPrivateLessons(false);
        }}
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '6px',
        }}
      >
        Private Lesson Requests
      </Button>

      {showPrivateLessonRequests && (
        <div>
          {props.privateLessonRequestsAtEvents.map((event) => (
            <CalendarEventsUpdated key={event.event_id} event={event} />
          ))}
          <hr />
          <h5>Other Requests</h5>
          {!props.isNotArtistButInstructorOnlyAccount() && (
            <div>
              <h5>
                {props.privateLessonRequestsForInstructor.length > 0
                  ? `Private Lesson Requests`
                  : `Private Lesson Requests Will Be Shown Here`}
              </h5>
              {props.privateLessonRequestsForInstructor.map((lesson) => (
                <PrivateLessonRequestNoEvent
                  key={lesson._id}
                  lesson={lesson}
                  user={user}
                  isNotArtistButInstructorOnlyAccount={
                    props.isNotArtistButInstructorOnlyAccount
                  }
                  // artist={artist}
                />
              ))}
            </div>
          )}
        </div>
      )}
      {/* 
{showConfirmedPrivateLessons && (

  <div>
    
  </div>

)} */}

      {showConfirmedPrivateLessons && (
        <div>
          {props.confirmedPrivateLessonsAtEvents.map(
            (one_event_with_lessons) => (
              // <CalendarEventsUpdated key={event.event_id} event={event} />
              <ConfirmedPrivateLessons
                one_event_with_lessons={one_event_with_lessons}
              />
            )
          )}

          <h5
            style={{
              fontSize: '24px',
              marginBottom: '20px',
              marginTop: '20px',
            }}
          >
            Other Confirmed Lessons
          </h5>

          {props.confirmedPrivateLessonsNoEvent.map((lesson) => (
            <ConfirmedPrivateLesson lesson={lesson} />
          ))}
        </div>
      )}
    </div>
  );
};
export default ArtistInstructorPrivateLessons;
