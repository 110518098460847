import React, { Component, useContext, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import SelectDanceCompanyForTourStop from './SelectDanceCompanyForTourStop';
import TourDatePopup from './TourDatePopup';
import DateTest from './DateTest';
import TourContext from '../../context/tour/tourContext';

const SelectDanceCompaniesFromTourLocations = (props) => {
  const {
    setLocationList,
    locations,
    tourStops,
    setTourStops,
    instructor_id,
    instructor_name,
    tour_name,
  } = props;

  const tourContext = useContext(TourContext);
  const { publishTourRequest } = tourContext;

  const [companyClicked, setCompanyClicked] = useState(false);
  const [dateClicked, setDateClicked] = useState(false);
  const [date, setDate2] = useState();
  //   function StripedColumnsExample(tour_stop2, tour_name, index, tour_stops) {
  const [newDateTest, setNewDateTest] = useState();

  const setDateFromChild = (dateFromChild) => {
    console.log('setting date value from child');
    //setDate2 sets value for date
    console.log(dateFromChild);
    setDate2(dateFromChild);

    return dateFromChild;
  };

  function StripedColumnsExample(tour_stops) {
    // console.log(tour_stop2);

    // {tour_stops.map((tour_stop)=>(

    // ))}
    const [datex, setDateX] = useState();
    const [stateToSearch, setStateToSearch] = useState('');
    return (
      <div>
        <Table striped>
          <thead>
            <tr>
              <th>#</th>
              {/* <th>City</th> */}
              <th>Location</th>
              <th>Dance Company</th>
              <th>Dates</th>
              {/* <th>Status</th> */}
            </tr>
          </thead>
          {/* {companyClicked && (
                <TourCompanyPopup
                  company_name={companyName}
                  companyClicked={companyClicked}
                />
              )} */}
          {tour_stops.map((tour_stop, index) => (
            <tbody>
              {/* <div> */}
              <tr>
                <td>{index + 1}</td>
                {/* <td>{tour_stop.city}</td> */}
                <td>{tour_stop.state}</td>
                {/* <td> */}
                {/* <Button
                    onClick={() => {
                      setCompanyClicked(!companyClicked);

                      setStateToSearch(tour_stop.state);
                      // setCompanyName(tour_stop.company_name);
                    }}
                  >
                    {tour_stop.company_name === ''
                      ? 'Add Company'
                      : tour_stop.company_name}
                  </Button> */}
                <SelectDanceCompanyForTourStop
                  stateToSearch={tour_stop.state}
                  tourStops={tourStops}
                  setTourStops={setTourStops}
                  date={date}
                  datex={datex}
                />

                {/* <td>{tour_stop.status}</td> */}
              </tr>
            </tbody>
          ))}
        </Table>
        {companyClicked ? (
          <SelectDanceCompanyForTourStop
            stateToSearch={stateToSearch}
            setStateToSearch={setStateToSearch}
            setLocationList={setLocationList}
            locations={locations}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <div>
      <hr />
      {/* <h5>companies</h5> */}

      {/* {dummyTour.map((tour_stop, index) =>
        StripedColumnsExample(tour_stop, 'dummy tour name', index,tour_stops)
      )} */}
      {/* {dummyTour.map((tour_stop, index) => */}
      {/* {StripedColumnsExample(dummyTour)} */}
      {StripedColumnsExample(props.locations)}

      <Button
        onClick={() => {
          //add tour_name,instructor_id and instructor_name
          let tourObj = {
            instructor_id: instructor_id,
            dancer_name: instructor_name,
            tour_name: tour_name,
            tour_stops: tourStops,
          };

          console.log('tourObj updated');
          console.log(tourObj);
          publishTourRequest(tourObj);
        }}
        style={{
          backgroundColor: 'white',
          background: 'rgb(1, 126, 252)',
          color: 'white',

          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',
        }}
      >
        Publish Tour
      </Button>
      {/* )} */}
    </div>
  );
};

export default SelectDanceCompaniesFromTourLocations;

// graveyard feb 28 2024

{
  /* </td> */
}
{
  /* <td> */
}
{
  /* {tour_stop?.dates} */
}
{
  /* <Button onClick={() => setDateClicked(!dateClicked)}>
                    {date === undefined ? 'select date' : date}
                  </Button> */
}

{
  /* <DateTest
                    date={date}
                    setDate2={setDate2}
                    setDateFromChild={setDateFromChild}
                    setDateX={setDateX}
                  /> */
}

{
  /* {dateClicked ? (
                    <div>
                      <TourDatePopup
                        dateClicked={dateClicked}
                        setDateClicked={setDateClicked}
                        date={date}
                        setDate2={setDate2}
                      />
                    </div>
                  ) : (
                    <></>
                  )} */
}
{
  /* </td> */
}
