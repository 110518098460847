import React, { Component, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import PerformanceReviewContext from '../../context/PerformanceReview/performanceReviewContext'; // might have to capitalize
import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';
// import { useAuth } from '../../context/auth/AuthState';
import Button from 'react-bootstrap/Button';
import LeaveReviewFields from './LeaveReviewFields';
import Review from './Review';
import PerformanceReviewsForGroupAndEvent from './PerformanceReviewsForGroupAndEvent';
import ReviewWasClicked from './ReviewWasClicked';

const ReviewPerformer = (props) => {
  const performanceReviewContext = useContext(PerformanceReviewContext);

  const {
    getPerformersForEvent,
    getReviewsForGroupForEvent,
    performance_reviews_for_group_and_event,
    performersForEvent,
  } = performanceReviewContext;

  useEffect(() => {
    console.log('props.event_id');
    console.log(props.event._id);
    // getPerformersForEvent(props.event.event_id);
    // getConnections(user?._id);
    // getUsersForSearchCriteria(searchText);
    // console.log('connections');
    // console.log(connections);
    // eslint-disable-next-line
  }, []);

  const [reviewClicked, setReviewClicked] = useState(false);
  const [previouslyClickedArtist, setPreviouslyClickedArtist] = useState(
    'none'
  );

  const reviewWasClicked = () => {
    setReviewClicked(!reviewClicked);
    // setPreviouslyClickedArtist;
    //load reviews
    getReviewsForGroupForEvent(props.performer.group_id, props.event._id);
  };

  return (
    <div>
      <div className="row">
        <div className="col-7">
          <h5>{props.performer.name}</h5>
        </div>
        <div className="col">
          <Button
            onClick={() => {
              setPreviouslyClickedArtist(props.performer.name);
              reviewWasClicked();
              console.log('previouslyClickedArtist');
              console.log(previouslyClickedArtist);
            }}
            style={{
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '0px 12px',
              borderWidth: '1px',
              width: '100px',
            }}
          >
            {reviewClicked ? ' close review' : 'review'}
          </Button>
        </div>
      </div>
      <br />
      {/* <div>
        {reviewClicked && (
          <div>
            <LeaveReviewFields
              performer={props.performer}
              event={props.event}
            />

            <div class="container-fluid  overflow-auto">
              <div class="d-flex flex-row flex-nowrap">
                {performance_reviews_for_group_and_event.map((review) => (
                  <div className="row">
                    <div class="card card-block mx-2">
                      <Review review={review} />
                    </div>
                  </div>
                ))}
              </div>

              {performance_reviews_for_group_and_event.length > 3 && (
                <h5>scroll right 🙂 {'--->'} </h5>
              )}
            </div>

         
            <hr />
          </div>
        )}
      </div> */}

      <div>
        <ReviewWasClicked
          key={Math.random() * 10}
          performer={props.performer}
          event={props.event}
          performance_reviews_for_group_and_event={
            performance_reviews_for_group_and_event
          }
          visible={reviewClicked}
          previouslyClickedArtist={previouslyClickedArtist}
        />
      </div>
    </div>
  );
};

export default ReviewPerformer;

/*



    <div class="container-fluid  overflow-auto">
            -- was commented out <div class="d-flex flex-row flex-nowrap">
              -- was commented out <div class="row flex-row flex-nowrap overflow-auto"> 
              <div class="d-flex flex-row flex-nowrap">
                {performance_reviews_for_group_and_event.map((review) => (
                  <div className="row">
                    ---- was commented out <div className="col">{review.reviewer_name}</div>
                    --- was commented out<div className="col">{review.stars_given}</div> 
                    <div class="card card-block mx-2">
                      <Review review={review} />
                    </div>
                  </div>
                ))}
              </div>

              {performance_reviews_for_group_and_event.length > 3 && (
                <h5>scroll right 🙂 {'--->'} </h5>
              )}
            </div>


*/
