import React, { useReducer } from 'react';
import axios from 'axios';

import NotificationContext from './notificationContext';
import NotificationReducer from './notificationReducer';
import {
  GET_USERS_NOTIFICATIONS,
  NOTIF_ERROR,
  SEND_NEW_USER_CONNECTION_NOTIF,
  SEND_NEW_USER_ADDED_TO_TRIP_NOTIF,
  LINK_MEMBER_TO_PERSONAL_ACCOUNT,
} from '../types';

const NotificationState = (props) => {
  const initialState = {
    user_notifications: [],
    notification: {},
  };

  const [state, dispatch] = useReducer(NotificationReducer, initialState);

  const getUsersNotifications = async (user_id) => {
    try {
      console.log('running getUsersNotifications()');
      const res = await axios.get(`/api/notifications/${user_id}`);
      console.log('results should be notifs');
      // console.log(res.data);

      console.log(res.data);
      dispatch({
        type: GET_USERS_NOTIFICATIONS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: NOTIF_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const sendNewConnectionNotification = async (
    current_user_id,
    target_user_id,
    sender_name
  ) => {
    try {
      const obj = {
        sender_id: current_user_id,
        sender_name: sender_name,
        to_id: target_user_id,
        notif_msg: `you and ${sender_name} are now friends`,
        notif_type: 'new_connection',
        seen: false,
      };

      const res = await axios.post(`/api/notifications/new-connection`, obj);
      console.log('notification is:');
      console.log(res.data);

      console.log(res.data);
      dispatch({
        type: SEND_NEW_USER_CONNECTION_NOTIF,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: NOTIF_ERROR,
        payload: err.response.msg,
      });
    }
  };

  const sendUserAddedToTripNotification = async (
    current_user_id,
    target_user_id,
    sender_name,
    event_name
  ) => {
    try {
      const obj = {
        sender_id: current_user_id,
        sender_name: sender_name,
        to_id: target_user_id,
        notif_msg: `${sender_name} just added you to ${event_name}`,
        notif_type: 'user_added_to_trip',
        seen: false,
      };

      const res = await axios.post(`/api/notifications/new-trip-roommate`, obj);
      console.log('notification is:');
      console.log(res.data);

      console.log(res.data);
      dispatch({
        type: SEND_NEW_USER_ADDED_TO_TRIP_NOTIF,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: NOTIF_ERROR,
        payload: err.response.msg,
      });
    }
  };

  //   const createAd = async (obj) => {
  //     try {
  //       console.log('running createAd()');
  //       const res = await axios.post('/api/advertisements/', obj);
  //       // console.log('results should be filtered');
  //       // console.log(res.data);
  //       console.log(obj);
  //       dispatch({
  //         type: CREATE_AD,
  //         payload: res.data,
  //       });
  //     } catch (err) {
  //       dispatch({
  //         type: AD_ERROR,
  //         payload: err.response.msg,
  //       });
  //     }
  //   };

  const acceptStudioProfileClaim = async (obj) => {
    try {
      const res = await axios.post(
        `/api/notifications/linkMemberProfileToPersonalAccount`,
        obj
      );
      console.log('deleted notification_id is:');
      console.log(res.data);

      console.log(res.data);
      dispatch({
        type: LINK_MEMBER_TO_PERSONAL_ACCOUNT,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: NOTIF_ERROR,
        payload: err.response.msg,
      });
    }
  };
  return (
    <NotificationContext.Provider
      value={{
        user_notifications: state.user_notifications,
        notification: state.notification,

        getUsersNotifications,
        sendNewConnectionNotification,
        acceptStudioProfileClaim,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationState;
