import {
  GET_INCIDENT_REPORT,
  GET_ALL_INCIDENT_REPORTS,
  SUBMIT_INCIDENT_REPORT,
  GET_USER_INCIDENT_REPORTS,
  UPDATE_INCIDENT_REPORT,
  VOTE_ON_INCIDENT_REPORT,
  SUSPEND_PERSON,
  GET_SUSPENSION_LIST,
  INCIDENT_REPORT_ERROR,
} from '../types';

const incidentReportReducer = (state, action) => {
  switch (action.type) {
    case GET_INCIDENT_REPORT:
      return {
        ...state,
        incident_report: action.payload,
        loading: false,
      };
    case GET_USER_INCIDENT_REPORTS:
      return {
        ...state,
        user_incident_reports: action.payload,
        loading: false,
      };
    case GET_ALL_INCIDENT_REPORTS:
      return {
        ...state,
        all_incident_reports: action.payload,
        loading: false,
      };
    case SUBMIT_INCIDENT_REPORT:
      return {
        ...state,
        submitted_incident_report: action.payload,
        loading: false,
      };
    case UPDATE_INCIDENT_REPORT:
      return {
        ...state,
        incident_report: action.payload,
        loading: false,
      };
    case VOTE_ON_INCIDENT_REPORT:
      return {
        ...state,
        incident_report: action.payload,
        loading: false,
      };
    case SUSPEND_PERSON:
      return {
        ...state,
        suspended_person: action.payload,
        loading: false,
      };
    case GET_SUSPENSION_LIST:
      return {
        ...state,
        suspensionList: action.payload,
        loading: false,
      };
    case INCIDENT_REPORT_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default incidentReportReducer;
