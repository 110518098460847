import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';

const WeekButton = (props) => {
  const {
    lesson_plan,
    getLessonPlanById,
    setTargetWeek,
    lessonPlanObj,
    // weekButtonClicked,
    // setWeekButtonClicked,
  } = props;
  const [weekButtonClicked, setWeekButtonClicked] = useState(false);

  return (
    <div
      className="card-horizontal-menu card-block mx-2"
      //   style={{ height: '140px' }}
      style={{
        width: '120px',
        height: '55px',
        paddingRight: '20px',
      }}
    >
      <div
      // onClick={(e) => {
      //   console.log('e');
      //   console.log(e);
      //   console.log('e.target');
      //   console.log(e.target);
      //   console.log('lesson_plan ---');
      //   console.log(lesson_plan);
      //   // setTargetWeek(
      //   //   props.curriculum.lesson_plans_by_week[index]
      //   //     .lessonPlan
      //   // );
      //   //load LessonPlan from backend and settargetweek to it
      //   //note : this will be an obj

      //   //   getLessonPlanById(lesson_plan.lessonPlan); //the id
      //   //   setTargetWeek(lessonPlanObj); //the obj
      //   // setTargetWeek(lesson_plan.lessonPlan);
      // }}
      >
        <div
          className="curriculum-week-card bg-light"
          // style={{
          //   borderStyle: 'solid',
          //   borderColor: 'rgb(1, 126, 252)',
          //   borderWidth: '1px',
          //   borderRadius: '10px',
          // }}
        >
          <Button
            // classname="all-center"
            style={{
              width: 'max-content',
              textAlign: 'center',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              color: 'rgb(1, 126, 252)',
              borderWidth: '1px',
              borderRadius: '10px',
              backgroundColor: weekButtonClicked ? 'blue' : 'white',
            }}
            onClick={() => {
              console.log('testing lesson_plan ------');
              console.log(lesson_plan);
              // getLessonPlanById(lesson_plan.lessonPlan); //the id
              getLessonPlanById(lesson_plan._id); //the id
              setTargetWeek(lessonPlanObj); //the obj

              props.setSelectedIndex(props.index);
              if (props.selectedIndex === props.index) {
                setWeekButtonClicked(!weekButtonClicked);
              } else {
                setWeekButtonClicked(false);
              }

              //set the week_to_teach

              props.setLessonDetails({
                ...props.lessonDetails,
                week_to_teach:
                  props?.curriculum?.lesson_plans_by_week[props?.selectedIndex]
                    .week_to_teach,
              });
            }}
          >
            {/* week {lesson_plan?.week_to_teach} */}
            lesson {props?.index + 1}
          </Button>
        </div>

        {/* <h5>week {lesson_plan?.week_to_teach}</h5> */}
      </div>
    </div>
  );
};

export default WeekButton;
