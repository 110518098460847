import React, { Component, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
// import ArtistContext from '../../context/artist/artistContext'; // might have to capitalize

import RankingsContext from '../../context/rankings/rankingsContext';

import { Link } from 'react-router-dom';
import PopupFilter from '../Filter/PopupFilter';
import AuthContext from '../../context/auth/authContext';
import { useAuth } from '../../context/auth/AuthState';
import CityFilterButtons from '../Filter/CityFilterButtons';

const Rankings = () => {
  // const authContext = useContext(AuthContext);
  const [authState] = useAuth();
  const { user, loading } = authState;
  //   const artistContext = useContext(ArtistContext);
  //   const { artist_directory, getArtistDirectory } = artistContext;

  const rankingsContext = useContext(RankingsContext);
  const { rankings, getRankings, getFilteredRankings } = rankingsContext;
  const [isLoading, setIsLoading] = useState(false);
  const [cityFilter, setCityFilter] = useState('');
  const [countryFilter, setCountryFilter] = useState('');
  useEffect(() => {
    // getArtistDirectory();
    console.log('user?.primary_style');
    console.log(user?.primary_style);
    setIsLoading(true);
    if (user?.primary_style) {
      getRankings(user.primary_style);
    } else {
      getRankings('bachata');
    }
    setIsLoading(false);
    // getRankings(user?.primary_style ? user.primary_style : 'bachata');
    // getOtherArtists();
    // eslint-disable-next-line
  }, []);
  console.log('rankings yo');
  console.log(rankings);
  var isAllCheck = false;
  const handlePillClicked = (type) => {
    console.log('hey2');

    if (cityFilter === '' && countryFilter === '') {
      console.log('getting world rankings, no city filter selected');
      getRankings(type);
    } else {
      console.log('getting getFilteredRankings');
      console.log('cityFilter');
      console.log(cityFilter);
      console.log('countryFilter');
      console.log(countryFilter);
      getFilteredRankings({
        country: countryFilter,
        state: 'none',
        city: cityFilter,
        style: type,
      });
    }
    setArtistType(type);

    // setDetails({
    //   ...details,
    //   ['workshop_type']: type,
    // });
    document
      .querySelectorAll('input[type=radio]')
      .forEach((el) => (el.checked = isAllCheck));
  };

  //dynamic buttons
  const renderSalsaButton = () => {
    if (artistType === 'salsa') {
      return (
        <Button
          onClick={() => {
            handlePillClicked('salsa');
          }}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          salsa
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          salsa
        </Button>
      );
    }
  };
  const renderBachataButton = () => {
    if (artistType === 'bachata') {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          bachata
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          bachata
        </Button>
      );
    }
  };

  const renderKizombaButton = () => {
    if (artistType === 'kizomba') {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          kizomba
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          kizomba
        </Button>
      );
    }
  };

  const renderZoukButton = () => {
    if (artistType === 'zouk') {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          zouk
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          zouk
        </Button>
      );
    }
  };

  const renderDJButton = () => {
    if (artistType === 'DJ') {
      return (
        <Button
          onClick={() => {
            handlePillClicked('DJ');
            // setDjSelected(false);
          }}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          DJ
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handlePillClicked('DJ');
            // setDjSelected(true);
          }}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          DJ
        </Button>
      );
    }
  };
  console.log('user?.primary_style --2');
  console.log(user?.primary_style);

  // const returnArtistType =()=>{
  //   if(user?.primary_style)
  //   return 'bachata'
  // }

  const [artistType, setArtistType] = useState(
    user?.primary_style ? user.primary_style : 'bachata'
  );

  //   const renderArtistsDynamically = (artistTypeVar) => {
  //     let artists_dict = new Map(Object.entries(artist_directory));
  //     console.log('artist_directory ---- ');
  //     console.log(artist_directory);
  //     console.log('artists_dict -----');
  //     console.log(artists_dict);

  //     return artists_dict.get(artistTypeVar);
  //   };

  const [filterClicked, setFilterClicked] = useState(false);
  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const returnViableImage = (artist) => {
    if (artist?.image) {
      return artist.image;
    } else if (artist?.image_url) {
      return artist?.image_url?.url;
    }

    return imgg;

    // artist?.image ? artist.image : artist?.image_url?.url
  };
  if (isLoading) return <h5>loading</h5>;
  if (loading) return <h5>loading</h5>;

  return (
    <div>
      {/* <h5>Jared</h5> */}
      <div>
        <CityFilterButtons
          style={artistType}
          getRankings={getRankings}
          getFilteredRankings={getFilteredRankings}
          user={user}
          setCityFilter={setCityFilter}
          setCountryFilter={setCountryFilter}
          cityFilter={cityFilter}
        />
        <hr />
        <div className="row">
          {renderSalsaButton()}
          {renderBachataButton()}
          {renderKizombaButton()}
          {renderZoukButton()}
          {renderDJButton()}
        </div>
        <Button onClick={() => setFilterClicked(!filterClicked)}>Filter</Button>

        {filterClicked && (
          <PopupFilter style={artistType} filterClicked={filterClicked} />
        )}
      </div>
      {/* <h5> */}
      <div
        className="col grid-container-small-screen grid-container-big-screen"
        style={{ paddingTop: '10px' }}
      >
        {/* {renderArtistsDynamically(artistType)?.map((artist) => ( */}
        {rankings.length > 0 &&
          rankings?.map((artist, index) => (
            <div>
              <Link
                to={
                  artist?.artist_id
                    ? `/artist-profile/${artist.artist_id}`
                    : `/user/profile/${artist.user_id}`
                }
                style={{ color: 'blue' }}
              >
                <div className="imageRankcontainer">
                  <div
                    className="topright"
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      borderRadius: '5px',
                      margin: '2px',
                      padding: '3px',
                    }}
                  >
                    <h5
                      style={{
                        color: 'white',
                        // backgroundColor: 'black',
                        marginBottom: '0px',
                      }}
                    >
                      {/* {artist.rank}  */}
                      {index + 1}
                    </h5>
                  </div>
                  <img
                    // src={artist?.image ? artist.image : artist?.image_url?.url}
                    src={returnViableImage(artist)}
                    height="150"
                    width="150"
                    alt="user"
                    style={{ borderRadius: '0.25rem' }}
                  />
                </div>
                {/* <div className="rankingImageText"> */}
                <h5
                  className="rankingImageText"
                  // style={{
                  //   color: 'black',
                  //   // backgroundColor: 'black',
                  //   // marginBottom: '0px',
                  //   // fontSize: '14px',
                  //   // maxInLineSize: '12ch',
                  //   // // max-inline-size: 12ch,
                  //   // overflow: 'hidden',
                  //   // whiteSpace: 'nowrap',
                  //   // textOverflow: 'ellipsis',
                  // }}
                >
                  {artist.name}
                </h5>
                {/* </div> */}
              </Link>
            </div>
          ))}
      </div>
      {/* </div> */}
      {/* </h5> */}
    </div>
  );
};
export default Rankings;
