import styled from 'styled-components';

const TopStudioLeftSidePanelStyle = styled.div`
  body {
    font-family: 'Lato', sans-serif;
  }

  .sidenav {
    width: 230px;
    position: fixed;
    z-index: 1;
    top: 150px;
    left: 60px;
    background: #eee;
    overflow-x: hidden;
    padding: 8px 0;
    /* z-index: -1; */
  }

  .sidenav2 {
    width: 230px;
    position: fixed;
    z-index: 1;
    top: 500px;
    left: 60px;
    background: #eee;
    overflow-x: hidden;
    padding: 8px 0;
  }

  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #2196f3;
    display: block;
  }

  .sidenav2 a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #2196f3;
    display: block;
  }

  .sidenav a:hover {
    color: #064579;
  }

  .main {
    margin-left: 140px; /* Same width as the sidebar + left position in px */
    font-size: 28px; /* Increased text to enable scrolling */
    padding: 0px 10px;
  }

  /* @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  } */

  /* @media screen and (max-height: 450px) and (max-width: 700px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  } */
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export default TopStudioLeftSidePanelStyle;
