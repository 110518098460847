import React, { Component, Fragment, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PremiumPopoverUpsell from '../PremiumPopoverUpsell';

const HorizontalArtistFromLineup = (props) => {
  let {
    artist,
    setBookPrivateWasClicked,
    setArtistToBook,
    setArtistNameToBook,
    setArtistImage,
    setDancer,
    bookPrivateWasClicked,
    imgg,
    event,
  } = props;
  useEffect(() => {
    console.log('artist from lineup');
    console.log(artist);
  }, []);

  const bookPrivateButtonForPremiumEvent = (
    // 3 options for this button are:
    //the event is premium and the button just works normally
    //the event is not premium BUT the artist is premium and the button will be available / highlighted
    // this should help with upsell
    //neither the event nor the artist is premium and thus the button will serve as an upsell for both
    //--we will show a new component that shows that booking is available when premium and then nudge them
    <Fragment>
      {event?.premium_subscription && event.premium_subscription === true ? (
        <div className="col">
          <div>{/* <h5>event is premium</h5> */}</div>
          <Button
            onClick={() => {
              setBookPrivateWasClicked(!bookPrivateWasClicked);
              setArtistToBook(artist._id);
              setArtistNameToBook(artist.name);
              setDancer(artist); //this helps us determine if the dancer is artist or insturctor only
              // if(artist?.image_url)
              setArtistImage(
                artist?.image_url ? artist.image_url.url : artist.image.url
              );
            }}
            style={{
              // backgroundColor: 'grey',
              backgroundColor: 'white',
              width: 'max-content',

              background: 'white',
              color: 'rgb(1, 126, 252)',
              borderStyle: 'solid',
              // borderColor: 'rgb(1, 126, 252)',
              padding: '6px',
              border: '0.5px solid rgb(1, 126, 252)',
            }}
          >
            Book Private 👍🏽 - yoi
          </Button>
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );
  const bookPrivateButtonForPremiumArtistButNotPremiumEvent = (
    <Fragment>
      {event.premium_subscription === false &&
      artist?.premium_subscription &&
      (artist.premium_subscription === true ||
        artist.premium_instructor_subscription) ? (
        // <div className="col">
        <div className="">
          {/* <div>
            <h5>artist/instr. is premium but event is not </h5>
          </div> */}
          <Button
            onClick={() => {
              setBookPrivateWasClicked(!bookPrivateWasClicked);
              setArtistToBook(artist._id);
              setArtistNameToBook(artist.name);
              setDancer(artist);
              // setArtistImage(artist.image_url.url);
              setArtistImage(
                artist?.image_url ? artist.image_url.url : artist.image.url
              );
            }}
            style={{
              // backgroundColor: 'grey',
              // width: '150px',
              backgroundColor: 'white',
              // width: '120px',
              width: 'max-content',

              background: 'white',
              color: 'rgb(1, 126, 252)',
              borderStyle: 'solid',
              // borderColor: 'rgb(1, 126, 252)',
              padding: '6px',
              marginTop: '10px',
              border: '0.5px solid rgb(1, 126, 252)',
            }}
          >
            Book Private 👍🏽
          </Button>
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );
  const [showPopover, setShowPopover] = useState(false);
  const bookPrivateButtonForNonPremiumEventAndNonPremiumArtist = (
    // 3 options for this button are:
    //the event is premium and the button just works normally
    //the event is not premium BUT the artist is premium and the button will be available / highlighted
    // this should help with upsell
    //neither the event nor the artist is premium and thus the button will serve as an upsell for both
    //--we will show a new component that shows that booking is available when premium and then nudge them
    <Fragment>
      {/* {event.premium_subscription === false &&
      artist.premium_subscription === false ? ( */}
      <div className="col">
        <div>
          {/* <h5 style={{ fontSize: '12px' }}>
            neither artist nor event is premium{' '}
          </h5> */}
        </div>

        <div>
          <PremiumPopoverUpsell event={event} artist={artist} />
        </div>
        {/* <Button
          onClick={() => {
            setShowPopover(!showPopover);
            console.log('upsell go premium component popup');
          }}
          style={{
            backgroundColor: 'grey',
            width: '150px',
          }}
        >
          Go Pro For Booking Capability
        </Button> */}
      </div>
      {/* ) : (
        <></>
      )} */}
    </Fragment>
  );

  const renderBookingButton = () => {
    if (event.premium_subscription === true) {
      return bookPrivateButtonForPremiumEvent;
    } else if (
      event.premium_subscription === false &&
      artist?.premium_subscription === true
    ) {
      return bookPrivateButtonForPremiumArtistButNotPremiumEvent;
    } else {
      return bookPrivateButtonForNonPremiumEventAndNonPremiumArtist;
    }
  };
  return (
    <div
      className="row card"
      style={{ width: '180px', height: '240px', padding: '2px' }}
    >
      <div className="row all-center">
        <img
          // src={artist.image ? artist?.image.url : imgg}
          // src={artist.image ? artist?.image : imgg}
          src={artist?.image_url ? artist?.image_url.url : artist?.image.url}
          // height="35"
          // width="35"
          alt="user"
          className="rounded-circle lineup-image"
        />
        {/* </div>
      <div className="row"> */}
        <div className="all-center">
          <div className="row all-center">
            {/* <Link
              to={`/artist-profile/${user._id}`}
              style={{ color: 'rgb(1, 126, 252)' }}
            > */}
            <label
              className="ellipsisText all-center"
              for={artist._id}
              style={{ paddingTop: '14px' }}
            >
              {artist.name}
            </label>
            {/* </Link> */}
          </div>
          <div className="row">
            {renderBookingButton()}
            {showPopover && (
              <div>
                <PremiumPopoverUpsell event={event} artist={artist} />
              </div>
            )}
            {/* {event?.premium_subscription &&
            event.premium_subscription === true && (
              <div className="col">
                <div>
                  <h5>event is premium</h5>
                </div>
                <Button
                  onClick={() => {
                    setBookPrivateWasClicked(!bookPrivateWasClicked);
                    setArtistToBook(artist._id);
                    setArtistNameToBook(artist.name);
                    setArtistImage(artist.image_url.url);
                  }}
                  style={{
                    backgroundColor: 'grey',
                    width: '150px',
                  }}
                >
                  book private
                </Button>
              </div>
            )} */}
            <div className="col">
              <Link
                to={
                  // artist?.artist_id && artist?.artist_id !== ''
                  artist?.artist_type && artist?.artist_type !== ''
                    ? // ? `/artist-profile/${artist.artist_id}`
                      `/artist-profile/${artist._id}`
                    : `/user/profile/${artist._id}`
                }
              >
                <h5
                  style={{
                    fontSize: '12px',
                    color: 'rgb(1, 126, 252)',
                    paddingTop: '14px',
                    width: '180px',
                  }}
                >
                  See Profile
                </h5>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalArtistFromLineup;
