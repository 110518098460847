import React, { Component, useState } from 'react';
import ReferralSubscriptionPurchase from './ReferralSubscriptionPurchase';
import { Button } from 'react-bootstrap';
const moment = require('moment');
moment().format();
const Referral = (props) => {
  let { referral } = props;
  let [seeDetailsClicked, setSeeDetailsClicked] = useState(false);
  let subscriptionPurchased = referral.subscription_purchased;
  return (
    <div className="card bg-light">
      <div className="row">
        {/* <h5>{referral.referredUser}</h5> */}
        <h5>{referral?.referredUsername}</h5>
      </div>

      <div
        className="row"
        style={{ color: subscriptionPurchased ? 'green' : 'black' }}
      >
        <h5>Subscription Purchased : {subscriptionPurchased ? `yes` : `no`}</h5>
      </div>

      {subscriptionPurchased && (
        <Button onClick={() => setSeeDetailsClicked(!seeDetailsClicked)}>
          {seeDetailsClicked ? 'hide' : 'See Details'}
        </Button>
      )}

      {subscriptionPurchased &&
        seeDetailsClicked && (
          <div>
            {referral.subscriptions_purchased_by_user.map((subscription) => (
              <ReferralSubscriptionPurchase subscription={subscription} />
            ))}
          </div>
        )}

      <div className="row">
        <h5>
          revenue share expiry:
          {moment(referral.revenue_share_expiration).format('MM.DD.YY')}{' '}
        </h5>
      </div>
      <div className="row" />
    </div>
  );
};
export default Referral;
