import React, { Component, useState } from 'react';
import ArtistSearchTest from '../components/layout/dynamic-search/ArtistSearchTest';
import { Button } from 'react-bootstrap';
import AddNewArtistPopup from '../components/artist/AddNewArtistPopup';

const ArtistOnboardingDetails = () => {
  //  const[userNotAddingArtist,setuserNotAddingArtist]=useState(false)
  const [addNewArtistClicked, setAddNewArtistClicked] = useState(false);
  const [searchResultsEmpty, setSearchResultsEmpty] = useState(false);
  const [showNewArtistCreatedMsg, setShowNewArtistCreatedMsg] = useState(false);
  return (
    <div className="card">
      {/* <h5>artistOnboard onboard</h5> */}
      {showNewArtistCreatedMsg === false && (
        <div>
          <h5 style={{ fontSize: '16px' }}>
            Search our artist database and see if your profile already exists :
          </h5>
          <ArtistSearchTest
            searchResultsEmpty={searchResultsEmpty}
            setSearchResultsEmpty={setSearchResultsEmpty}
          />
          {/* search */}

          {/* if not found, create Artist */}

          {searchResultsEmpty && (
            // {userSearchText.length > 0 && (
            <div>
              <h5>If artist not found:</h5>
              <Button
                onClick={() => {
                  console.log('clicked');
                  setAddNewArtistClicked(!addNewArtistClicked);
                  // setUserIsAddingArtist(true);
                  console.log('clicked2');
                }}
              >
                Add New Artist
              </Button>
              {addNewArtistClicked && (
                <div>
                  <AddNewArtistPopup
                    setShowNewArtistCreatedMsg={setShowNewArtistCreatedMsg}
                    addNewArtistClicked={addNewArtistClicked}
                    userIsOnboarding={true}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {showNewArtistCreatedMsg === true && (
        <div
          style={{
            backgroundColor: 'lightgreen',
            borderWidth: '1px',
            borderRadius: '10px',
            color: '#65a765',
            margin: '10px',
            borderColor: '#65a765',
            borderStyle: 'solid',
          }}
        >
          <h5>New Artist Created Successfully, Continue your onboarding!</h5>
        </div>
      )}
    </div>
  );
};
export default ArtistOnboardingDetails;
