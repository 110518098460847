import React, { Component, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import TabContainer from 'react-bootstrap/TabContainer';
// import TabPane from 'react-bootstrap/TabPane';
import Attendees from './Attendees';
import Roommates from './Roommates';
// import Feed from './Feed';
import SubEvents from './SubEvents';
import { Link } from 'react-router-dom';

import userContext from '../../context/user/userContext'; // might have to capitalize
import eventContext from '../../context/event/eventContext'; // might have to capitalize

// export function EventDetail() {
const EditEventDetail = (props) => {
  // const [key, setKey] = useState('attendees');

  // const [key, setKey] = useState(props.page);
  // const uContext = useContext(userContext);
  // const { } = uContext;

  const [show, setShow] = useState(props.showModal);

  const handleClose = () => {
    console.log('closing ');
    setShow(false);
    props.setShow(false);
    // showModal = false;
  };
  const handleShow = () => setShow(true);

  const eContext = useContext(eventContext);
  const { editEvent } = eContext;

  const location = useLocation();
  // const { event, page } = location.state;
  const { event } = props;
  // useEffect(() => {
  //   getHost(event.hostId);
  //   console.log('event host: ');
  //   console.log(host);
  // }, []);

  //form inputs
  const [details, setDetails] = useState({
    // descr: '',
    name: event.name,
    descr: 'fun event',
    // location: 'Charlotte, NC',
  });

  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });
  const { name, descr } = details;

  const updatedEvent = {};
  updatedEvent.event_id = event._id;
  // updatedEvent.descr = descr;
  updatedEvent.name = name;
  updatedEvent.descr = descr;
  const onSubmit = (e) => {
    e.preventDefault();
    console.log('saving updated user from frontend');

    if (details === '') {
      //   setAlert('Please fill in all fields', 'danger');
    } else {
      editEvent(updatedEvent);

      console.log('updatedEvent from edit is: ---');
      console.log(updatedEvent);
      console.log('saving updated user from frontend');
    }
  };

  const { showModal } = props;
  console.log(`showModal is ${showModal}`);
  return (
    <div>
      {/* <Button variant="primary" onClick={handleShow}>
        Edit Event
      </Button> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Event Name</Form.Label>
              <Form.Control
                id="name"
                name="name"
                type="text"
                value={name}
                onChange={onChange}
                autoFocus
              />
              <Form.Label>Event Description</Form.Label>
              <Form.Control
                id="descr"
                name="descr"
                type="text"
                value={descr}
                onChange={onChange}
                autoFocus
              />
              {/* <Form.Label>Dance Company</Form.Label>
              <Form.Control
                id="dance_company"
                name="dance_company"
                type="text"
                value={dance_company}
                onChange={onChange}
                autoFocus
              />
              <Form.Label>Location</Form.Label>
              <Form.Control
                id="location"
                name="location"
                type="text"
                value={location}
                onChange={onChange}
                autoFocus
              /> */}
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save Changes
          </Button> */}

          <Button
            variant="primary"
            onClick={() => props.setDeleteButtonClicked(true)}
          >
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Save Changes
          </Button>
          {props.deleteButtonClicked ? (
            <div>
              <div>
                <label htmlFor="">
                  Are you sure you want to delete this event?
                </label>
              </div>
              <div className="row">
                <div className="col">
                  <Link to="/">
                    <Button
                      onClick={() => {
                        props.deleteEvent(event._id);
                      }}
                      className="col btn btn-danger"
                    >
                      Delete
                    </Button>
                  </Link>
                </div>
                <div className="col">
                  <Button onClick={() => props.setDeleteButtonClicked(false)}>
                    no
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal.Footer>
      </Modal>
      {/* render attendees under attendees h1 or bring the attendees to a child component */}
    </div>
  );
};
export default EditEventDetail;
