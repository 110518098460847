import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import authState, { useAuth } from '../../context/auth/AuthState';
import { Form, Link, useNavigate } from 'react-router-dom';
import StudioContext from '../../context/studio/studioContext';
import { Button } from 'react-bootstrap';
import CurriculumContext from '../../context/curriculum/curriculumContext';

const StudioListForUser = (props) => {
  //load user's studios
  const [authState, authDispatch] = useAuth();

  const { user, isAuthenticated } = authState;
  const studioContext = useContext(StudioContext);

  const curriculumContext = useContext(CurriculumContext);
  const { adoptCurriculum } = curriculumContext;

  const { userStudios, getUserStudios, loading } = studioContext;
  let navigate = useNavigate();

  let { curriculum, setStudioId } = props;
  const [adoptCurriculumObj, setAdoptCurriculumObj] = useState({
    curriculum: props.curriculum,
    adoptingCompany:
      userStudios && userStudios.length > 0 ? userStudios[0]?._id : '',
  });
  const [optionSelected, setOptionSelected] = useState(false);
  const [selectedStudio, setSelectedStudio] = useState({});
  useEffect(() => {
    getUserStudios(props?.user?._id);
    // eslint-disable-next-line
  }, []);

  const onSubmit2 = (e) => {
    console.log(adoptCurriculumObj);
    e.preventDefault();
  };

  const [
    loadingAfterCurriculumAdoption,
    setLoadingAfterCurriculumAdoption,
  ] = useState(false);
  const handleAdoptCurriculum = (e) => {
    setLoadingAfterCurriculumAdoption(true);
    console.log(adoptCurriculumObj);
    adoptCurriculum(adoptCurriculumObj).then(() => {
      setLoadingAfterCurriculumAdoption(false);
      navigate(`/studio/${selectedStudio._id}`, {
        state: {
          messageText: 'Curriculum Added!',
        },
      });
    });
    // e.preventDefault();
  };
  if (loadingAfterCurriculumAdoption) {
    return <h2>Adding Curriculum...</h2>;
  }
  const { adoptingCompany } = adoptCurriculumObj;

  const splitValue1 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);

    return arr[0];
  };
  const splitValue2 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    return arr[1];
  };
  if (loading) {
    return <h2>Loading...</h2>;
  }

  //   const []

  const onStudioChange = (e) => {
    console.log('index pf selcted');
    setOptionSelected(!optionSelected);
    console.log('e.target.name');
    console.log(e.target.name);
    console.log('e.target.value');
    console.log(e.target.value);
    setAdoptCurriculumObj({
      ...adoptCurriculumObj,
      adoptingCompany: e.target.id,
    });
    setStudioId(e.target.id);
    getSelectedStudio(e.target.id);
    // setSelectedStudio()
  };

  const getSelectedStudio = (studio_id) => {
    for (let i = 0; i < userStudios?.length; i++) {
      if (userStudios[i]._id === studio_id) {
        setSelectedStudio(userStudios[i]);
      }
    }
  };
  const selectedStudioIsPremium = (studio) => {
    return studio?.premium_subscription?.premium_subscriber;
    // return false
  };

  // const ownerCheck = (
  //   <Fragment>
  //     {userStudios && userStudios?.length > 0 ? (
  //       <Button
  //         variant="primary"
  //         onClick={handleAdoptCurriculum}
  //         style={{
  //           width: '170px',
  //           margin: '12px',
  //           backgroundColor: 'white',
  //           color: 'rgb(1, 126, 252)',
  //           borderStyle: 'solid',
  //           borderColor: 'rgb(1, 126, 252)',
  //           borderWidth: '1px',
  //           borderRadius: '10px',
  //         }}
  //       >
  //         Adopt Curriculum
  //       </Button>
  //     ) : (
  //       <h5>Create a studio to adopt curriculums</h5>
  //     )}
  //   </Fragment>
  // );

  const userIsStudioOwner = (
    <Fragment>
      <Button
        variant="primary"
        onClick={handleAdoptCurriculum}
        style={{
          width: '170px',
          margin: '12px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',
          borderWidth: '1px',
          borderRadius: '10px',
        }}
      >
        Adopt Curriculum
      </Button>
    </Fragment>
  );

  const userIsNotAnOwner = (
    <Fragment>
      <h5 style={{ color: 'red', fontSize: '14px' }}>
        Create a studio to adopt curriculums
      </h5>
    </Fragment>
  );

  const isOwner = () => {
    for (let i = 0; i < user?.companies.length; i++) {
      if (user.companies[i].isOwner === true) {
        console.log('user.companies[i]');
        console.log(user.companies[i]);
        return true;
      }
    }
    return false;
  };

  const renderButtonOptions = () => {
    // if (user?.companies?.length > 0) {
    if (isOwner()) {
      return userIsStudioOwner;
    } else {
      console.log('user is not an owner --');
      console.log(user);
      return userIsNotAnOwner;
    }
  };

  return (
    <div style={{ margin: '10px' }}>
      {userStudios &&
        userStudios.length > 0 && (
          <div>
            <h5>Which Studio to add curriculum to?</h5>

            {/* <form onSubmit={onSubmit2}>
              <div class="form-check">
                <input
                  id="associate_with_company"
                  type="checkbox"
                  name="associate_with_company"
                  value={'associate_with_company'}
                  onChange={onAssociationChange}
                />

                <Form.Group controlId="formBasicSelect">
                  <Form.Label>Which Company</Form.Label>
                  <Form.Control
                    id="adoptingCompany"
                    name="adoptingCompany"
                    as="select"
                    value={adoptingCompany}
                    onChange={(e) => {
                      setAdoptCurriculumObj({
                        ...adoptCurriculumObj,
                        [e.target.name]: splitValue1(e),
                        company_id: splitValue2(e),
                      });

                      console.log('adoptCurriculumObj..company filled?');
                      console.log(adoptCurriculumObj);
                    }}
                  > */}
            {userStudios?.map((company, index) => (
              //   <option value={company.name + '/' + company._id}>

              <div class="form-check">
                <input
                  style={{ color: 'black' }}
                  class="form-check-input"
                  type="radio"
                  name={company.name}
                  // id={user._id}
                  id={company._id}
                  value={adoptingCompany}
                  onChange={onStudioChange}
                  onSelect={() => {
                    console.log('hi');
                  }}
                  //   onChange={onLineupChange}
                />
                <label class="form-check-label" for={company._id}>
                  {company.name}
                </label>
              </div>
            ))}
            {/* </option> */}
            {/* </Form.Control>
                </Form.Group>
              </div>

              <input
                type="submit"
                value="Adopt Curriculum"
                class="btn btn-secondary"
              />
            </form> */}
          </div>
        )}

      {optionSelected && (
        <div>
          {selectedStudioIsPremium(selectedStudio) ? (
            renderButtonOptions()
          ) : (
            // <Button
            //   variant="primary"
            //   onClick={handleAdoptCurriculum}
            //   style={{
            //     width: '170px',
            //     margin: '12px',
            //     backgroundColor: 'white',
            //     color: 'rgb(1, 126, 252)',
            //     borderStyle: 'solid',
            //     borderColor: 'rgb(1, 126, 252)',
            //     borderWidth: '1px',
            //     borderRadius: '10px',
            //   }}
            // >
            //   Adopt Curriculum
            // </Button>
            <h5>
              this is a premium feature. Go Pro with{' '}
              <a
                href={`/go-pro-subscription/${selectedStudio._id}`}
                style={{ color: 'blue' }}
              >
                Premium Studio
              </a>
            </h5>
          )}
        </div>
      )}
      {!optionSelected && (
        <div>
          {userStudios?.length > 0 && (
            <h5 style={{ color: 'red', fontSize: '14px' }}>select a studio</h5>
          )}
          {!user?.companies &&
            !userStudios && (
              <div>
                <Link to="/studio" state={{ loggedInUser: user }}>
                  <h5 style={{ color: 'red', fontSize: '14px' }}>
                    create a studio
                  </h5>
                </Link>
              </div>
            )}
        </div>
      )}

      {/* {userStudios &&
        userStudios.length > 0 && (
          <div>
            <form onSubmit={onSubmit2}>
              <div class="form-check">
                <input
                  id="associate_with_company"
                  type="checkbox"
                  name="associate_with_company"
                  value={'associate_with_company'}
                  //   onChange={onAssociationChange}
                />

                <Form.Group controlId="formBasicSelect">
                  <Form.Label>Which Company</Form.Label>
                  <Form.Control
                    id="adoptingCompany"
                    name="adoptingCompany"
                    as="select"
                    value={adoptingCompany}
                    onChange={(e) => {
                      setAdoptCurriculumObj({
                        ...adoptCurriculumObj,
                        [e.target.name]: splitValue1(e),
                        company_id: splitValue2(e),
                      });

                      console.log('adoptCurriculumObj..company filled?');
                      console.log(adoptCurriculumObj);
                    }}
                  >
                    
                    {userStudios?.map((company, index) => (
                      <option
                        value={company.company_name + '/' + company.company_id}
                      >
                        {company.company_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>

              <input
                type="submit"
                value="Adopt Curriculum"
                class="btn btn-secondary"
              />
            </form>
          </div>
        )} */}

      {/* {user?.companies &&
        user?.companies.length > 0 && (
          <div>
            <h5>hi</h5>
          </div>
        )} */}
    </div>
  );
};
export default StudioListForUser;
