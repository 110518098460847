import React, { Component, useContext, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import ReactionContext from '../../context/reaction/reactionContext';
// const { director, student, performer, instructor } = roles;

const AddDancerFields = (props) => {
  const reactionContext = useContext(ReactionContext);
  const { addDancer, new_dancer } = reactionContext;
  const [details, setDetails] = useState({
    bib_number: '',
    dancer_name: '',
  });
  const { bib_number, dancer_name } = details;

  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };
  const [category, setCategory] = useState(props.category);

  const onSubmit2 = (e) => {
    e.preventDefault();
    // if (dancer_name === '') {
    //   //   setAlert('Please fill in all fields', 'danger');
    //   console.log('type in dancer name');
    // } else {
    // setDetails({
    //   ...details,
    //   [dance_company_id]: 'help',
    // });

    console.log('details from adddancerfields');
    console.log(details);

    console.log('e is');
    console.log(e);

    const newObj = {
      bib_number: bib_number,
      dancer_name: dancer_name,

      category: category,
    };
    console.log('newObj to pass to backend');
    console.log(newObj);

    addDancer(newObj);
  };
  //   };
  const onChange3 = (e) => {
    console.log('changing list -- e is:');
    // console.log(e.target);
    // setMemberArray(...member_array, e.target.name);
    // console.log(member_array);

    setCategory(e.target.id);

    console.log('e.target.id and category is:');
    console.log(e.target.id);
  };

  const handleSelectLevel = (e) => {
    // setLevel(e);
    setDetails({ ...details, ['category']: e });
    console.log('details is');
    console.log(details);
    console.log(e);
    setCategory(e);
  };
  return (
    <div>
      <div className="form-container">
        <form onSubmit={onSubmit2}>
          <div className="form-group">
            <h5>Add New Dancer</h5>
            <input
              id="dancer_name"
              type="text"
              name="dancer_name"
              placeholder="Dancer Name"
              value={dancer_name}
              onChange={onChange}
              //   required
            />
            <input
              id="bib_number"
              type="text"
              name="bib_number"
              placeholder="bib Number"
              value={bib_number}
              onChange={onChange}
              //   required
            />

            <label htmlFor="">Category</label>

            {/* <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="novice"
                id="novice"
                value="novice"
                onChange={onChange3}
                // checked={roles.includes('novice')}
              />

              <label class="form-check-label" for="novice">
                novice
              </label>
            </div> */}

            <div className="col">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  // title={workshop?.level ? workshop.level : 'select a level'}
                  title={category}
                  // onClick={() => {
                  //   setLevelButtonClicked(true);
                  // }}
                  // onChange={onChange}
                  onSelect={handleSelectLevel}
                  // name="level"
                >
                  <Dropdown.Item eventKey="JnJ - Novice">
                    JnJ - Novice
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="JnJ - Intermediate">
                    JnJ - Intermediate
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="JnJ - Advanced">
                    JnJ - Advanced
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="JnJ - All Star / Champions">
                    JnJ - All Star / Champions
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Solo | Originals">
                    Solo | Originals
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="Performances">
                    Performances
                  </Dropdown.Item>
                  {/* <Dropdown.Divider />
              <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
                </DropdownType>
              ))}
            </div>

            {/* <label for="birthday">Birthday:</label>
<input type="date" id="birthday" name="birthday"> */}
          </div>

          <input type="submit" value="Add Dancer" class="btn btn-secondary" />
        </form>
      </div>
    </div>
  );
};

export default AddDancerFields;
