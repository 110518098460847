import React, { useContext, useEffect } from 'react';
// import isEmpty from '../../validation/is-empty';
import TopListContext from '../../context/topList/topListContext';
import StudioContext from '../../context/studio/studioContext';
import Level from './Level';

const Levels = (props) => {
  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;

  const studioContext = useContext(StudioContext);
  const { addNewLevel } = studioContext;

  const { studio_levels } = props;

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col grid-container-small-screen grid-container-big-screen">
      {/* <div className="card card-body"> */}
      {studio_levels.map((level) => (
        <Level level={level} />
      ))}
      {/* </div> */}
    </div>
  );
};

export default Levels;
