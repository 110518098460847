import React, { Component, useContext, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import IncidentReportContext from '../../context/incidentReport/incidentReportContext';
const moment = require('moment');
moment().format();
const MyIncidentReports = (props) => {
  const location = useLocation();
  //   const { user_id } = location?.state;

  let incidentReportContext = useContext(IncidentReportContext);
  let { getUserIncidentReports, user_incident_reports } = incidentReportContext;
  let { id } = useParams();

  useEffect(() => {
    getUserIncidentReports(id);
    // eslint-disable-next-line
  }, []);
  return (
    <div style={{ padding: '10px' }}>
      {location?.state?.messageText ? (
        <div
          className="promotionText all-center"
          style={{ backgroundColor: '#6495ED', color: 'white' }}
        >
          <h4>
            {location.state.messageText} - if no reports appear, refresh your
            page
          </h4>
          <br />
        </div>
      ) : (
        <></>
      )}

      <h5>My Incident Reports</h5>

      {user_incident_reports.map((incident_report, index) => (
        <Link to={`/incident-report/${incident_report._id}`}>
          <div className="row">
            <div className="col">
              <h5
                style={{
                  // width: '80px',
                  maxWidth: '300px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  background: 'white',

                  borderStyle: 'solid',
                  borderColor: 'rgb(1, 126, 252)',
                  borderWidth: '1px',
                  borderRadius: '2px',

                  fontSize: '14px',
                  backgroundColor: 'white',
                  color: 'rgb(1, 126, 252)',
                  textAlign: 'center',
                }}
              >
                {/* {incident_report.description_of_incident} */}
                incident report {index + 1} -{' '}
                {moment(incident_report.date_of_incident)
                  .zone(0)
                  .format('ddd MM-DD-YY')}
              </h5>
            </div>
            {/* <div className="col">
              <h5 style={{ color: 'black' }}>
                {' '}
                {moment(incident_report.date_of_incident)
                  .zone(0)
                  .format('ddd MM-DD-YY')}
              </h5>
            </div> */}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default MyIncidentReports;
