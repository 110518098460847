import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// import React, { useState } from 'react';
import { useAuth } from '../../../context/auth/AuthState';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import userContext from '../../../context/user/userContext'; // might have to capitalize
import eventContext from '../../../context/event/eventContext'; // might have to capitalize
import Workshop from './Workshop';
import WorkshopDate from './WorkshopDate';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import AddWorkshopFields from './AddWorkshopFields';
import AddWorkshopPopup from './AddWorkshopPopup';

const moment = require('moment');
moment().format();
// function ArtistLineup() {

const WorkshopScheduleNoPopup = (props) => {
  const [authState] = useAuth();
  const { user } = authState;

  const [visible, setVisible] = React.useState(false);

  const eContext = useContext(eventContext);
  const {
    getWorkshopSchedule,
    workshop_schedule,
    getTakenWorkshopRooms,
    takenWorkshopRoomsMap,
  } = eContext;
  const { event } = props;
  console.log('event from schedule');
  console.log(event);
  useEffect(() => {
    getWorkshopSchedule(event._id);
    getTakenWorkshopRooms(event._id);
  }, []);

  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const [showLineup, setShowLineup] = useState(false);
  const [workshopType, setWorkshopType] = useState('salsa');
  const [workshopTime, setWorkshopTime] = useState('09:00');
  // const [workshopDate, setWorkshopDate] = useState('2022-09-01');
  let date_formatted = moment(event.start_date)
    .zone(0)
    .format('MM-DD-YYYY');
  const [workshopDate, setWorkshopDate] = useState(date_formatted);
  const handleDateChange = (date) => {
    console.log('changed date');
    let d = moment(date).format('MM-DD-YYYY');
    console.log('date should be formatted like MM-DD-YYYY');
    console.log(d);
    setWorkshopDate(d);
  };
  const handleCloseLineup = () => {
    console.log('closing ');
    setShowLineup(false);
  };
  const handleShowLineup = () => {
    setShowLineup(true);
    console.log(`showLineup is: ${showLineup}`);
  };

  var isAllCheck = false;
  const handlePillClicked = (type) => {
    setWorkshopType(type);
    document
      .querySelectorAll('input[type=checkbox]')
      .forEach((el) => (el.checked = isAllCheck));
  };

  const renderWorkshopsByType = (workshopType) => {
    let workshops = new Map(Object.entries(workshop_schedule));
    console.log('workshops from renderWorkshopsByType ----');
    console.log(workshops);
    workshops.forEach((value, key, map) => {
      for (let i = 0; i < value.length; i++) {
        if (value[i].workshop_type === workshopType) {
          console.log('workshop type salsa');
        } else {
          console.log('! ---workshop type salsa');
        }
      }
      // console.log('key --');
      // console.log(key);
      // console.log('value ---');
      // console.log(value);
    });
  };
  renderWorkshopsByType('salsa');
  let lineup_array = []; //just [] of ids

  const onLineupChange = (e) => {
    console.log('changing lineup list -- e is:');
    // console.log(e.target);
    // setMemberArray(...lineup_array, e.target.name);
    // console.log(lineup_array);
    console.log('e.target');
    console.log(e.target);

    if (lineup_array.includes(e.target.id)) {
      console.log('removing user');
      lineup_array = lineup_array.filter((item) => item !== e.target.id);
    } else {
      console.log('adding user');
      lineup_array.push(e.target.id);
    }
    // const member_set = new Set(lineup_array);
    // const uniqueMembers = [...member_set];

    console.log(lineup_array);
  };

  // const renderArtists = () => {
  //   console.log('trying to render');
  //   if (artistType === 'salsa') {
  //     return salsa_artists;
  //   }
  //   if (artistType === 'bachata') {
  //     return bachata_artists;
  //   }

  //   return salsa_artists;
  // };

  //dynamic buttons
  const renderSalsaButton = () => {
    if (workshopType === 'salsa') {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
          }}
        >
          salsa
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('salsa')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          salsa
        </Button>
      );
    }
  };
  const renderBachataButton = () => {
    if (workshopType === 'bachata') {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
          }}
        >
          bachata
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('bachata')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          bachata {workshopTypeTotal > 0 ? workshopTypeTotal : ``}
        </Button>
      );
    }
  };

  const renderKizombaButton = () => {
    if (workshopType === 'kizomba') {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
          }}
        >
          kizomba
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('kizomba')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          kizomba
        </Button>
      );
    }
  };

  const renderZoukButton = () => {
    if (workshopType === 'zouk') {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="rounded-pill"
          style={{
            width: '100px',
            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',
          }}
        >
          zouk
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => handlePillClicked('zouk')}
          className="bg-primary rounded-pill"
          style={{ width: '100px' }}
        >
          zouk
        </Button>
      );
    }
  };

  console.log('all_artists[2]].bachata frontend');
  // console.log(all_artists[2].bachata);
  // const renderArtistsDynamically = (artistTypeVar) => {
  //   // let artists_dict = new Map(Object.entries(all_artists));
  //   let artists_dict = new Map(Object.entries(all_artists_dict));
  //   return artists_dict.get(artistTypeVar);
  // };
  const renderWorkshopsDynamically = (WorkshopTypeVar) => {
    // let artists_dict = new Map(Object.entries(all_artists));
    let workshop_dict = new Map(Object.entries(workshop_schedule));

    return workshop_dict.get(WorkshopTypeVar);
  };
  const renderWorkshopsByTime = (workshopTimeVar) => {
    // let artists_dict = new Map(Object.entries(all_artists));
    let workshop_dict = new Map(Object.entries(workshop_schedule));
    return workshop_dict.get(workshopTimeVar);
  };
  const [workshopTypeTotal, setWorkshopTypeTotal] = useState(0);
  const renderWorkshopsByTimeAndStyle = (
    workshopTimeVar,
    workshopTypeVar,
    workshopDateVar
  ) => {
    // let artists_dict = new Map(Object.entries(all_artists));
    let workshop_dict = new Map(Object.entries(workshop_schedule));
    let workshopsAtTime = workshop_dict.get(workshopTimeVar);
    console.log('wayy too much data -- find a way to reduce');
    console.log(`type workshopsAtTime at ---------- ${workshopTimeVar}`);
    console.log(workshopsAtTime);

    let arr = workshopTimeVar.split(':');
    let halfHourTime = arr[0] + ':30';
    let workshopsAtHalfHourTime = workshop_dict.get(halfHourTime);
    console.log('workshopsAtHalfHourTime---------------');
    console.log(workshopsAtHalfHourTime);

    // let allWorkshopsForSelectedHour = [
    //   ...workshopsAtTime,
    //   ...workshopsAtHalfHourTime,
    // ];

    let allWorkshopsForSelectedHour = [].concat(
      workshopsAtTime,
      workshopsAtHalfHourTime
    );
    let all = allWorkshopsForSelectedHour.filter((item) => item !== undefined);

    console.log('allWorkshopsForSelectedHour--------------');
    console.log(all);

    let workshops = [];
    //on 10.13.22 i changed the var from workshopsAtTime to allWorkshopsForSelectedHour
    //so we could load in wshops on the hour and at the halfhour time
    //if error, just change back below for loop to workshopsAtTime

    for (let i = 0; i < allWorkshopsForSelectedHour?.length; i++) {
      if (allWorkshopsForSelectedHour[i]?.workshop_type === workshopTypeVar) {
        // setWorkshopTypeTotal(workshopTypeTotal + 1);
        // console.log('workshopTypeTotal');
        // console.log(workshopTypeTotal);
        let date = moment(allWorkshopsForSelectedHour[i]?.date);
        const workshopOnSelectedDate = moment(workshopDateVar).isSame(date);
        // console.log('workshop type salsa');
        if (workshopOnSelectedDate) {
          workshops.push(allWorkshopsForSelectedHour[i]);
        }
      } else {
        console.log('! right type');
      }
    }

    // setWorkshopNumberOnDate()

    return workshops;
  };

  let times_array = [
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
  ];

  const [workshopAtTime, setWorkshopAtTime] = useState('');

  const dateEquals = (date, map, time, workshopType) => {
    // console.log('running dateEquals');
    // console.log('workshopType');
    // console.log(workshopType);
    // console.log(date);
    // console.log(map);
    let count = 0;
    // let timemap = new Map(Object.entries(map));

    let wshops = map.get(time);
    console.log(`wshops at ${time} ---`);
    console.log(wshops);

    // let count=0
    for (let i = 0; i < wshops?.length; i++) {
      // console.log('wshops[i].date');
      // console.log(wshops[i].date);
      // console.log('wshops[i].workshop_type');
      // console.log(wshops[i].workshop_type);
      console.log('lots of data - can we reduce?');
      if (wshops[i].date === date && wshops[i].workshop_type === workshopType) {
        count++;
      }
    }

    // console.log('date');
    // console.log(date);
    // console.log('count from dateEquals');
    // console.log(count);
    // console.log('wshops');
    // console.log(wshops);
    // map.forEach((value, key, map) => {
    //   // console.log('value---');
    //   // console.log(value);
    //   for (let i = 0; i < value.length; i++) {
    //     if (value[i].date === date) {
    //       console.log('is true');
    //       // return true;
    //       // return map.get(time).length;
    //       console.log('value---');
    //       console.log(value);

    //       return value.length;

    //       // return 'w';
    //     } else {
    //       console.log('dates ----');
    //       console.log(date);
    //       console.log(value[i].date);
    //       // return false
    //       return 'y';
    //     }
    //   }
    // });

    return count > 0 ? count : '';
  };

  const workshopsForTime = (time, map, date) => {
    console.log(
      'hit workshopsForTime -- too much data -- hit 143 times.. why?'
    );
    // let map = new map;
    // console.log('workshopsForTime time ---- ');
    // console.log(time);

    // console.log('map is ---');
    // console.log(map);
    let timemap = new Map(Object.entries(map));
    // console.log('converted map to timemap  -----');
    // console.log(timemap);
    // let count = 0
    // timemap.forEach((value, key, map) => {
    //   for (let i = 0; i < value.length; i++) {
    //     if (value[i].date === date) {
    //       count++
    //     } else {

    //     }
    //   }

    // });

    // if (timemap.has(time)) {
    //   return dateEquals(date, timemap, time);
    // }

    let arr = time.split(':');
    let halfHourTime = arr[0] + ':30';
    // console.log('halfHourTime---');
    // console.log(halfHourTime);
    if (timemap.has(time) || timemap.has(halfHourTime)) {
      // if (timemap.has(time)) {
      // setWorkshopAtTime(timemap.get(time).length);
      // console.log('timemap.get(time).length');
      // console.log(timemap.get(time).length);
      // return timemap.get(time).length;

      // if (dateEquals(date, timemap, time)) {
      //   console.log('inside if timemap.get(time).length');
      //   console.log(timemap.get(time).length);

      let hourCount = dateEquals(date, timemap, time, workshopType);
      let halfHourCount = dateEquals(date, timemap, halfHourTime, workshopType);
      // console.log('hourCount----');
      // console.log(hourCount);
      // console.log('halfHourCount----');
      // console.log(halfHourCount);
      return hourCount + halfHourCount;
      // return dateEquals(date, timemap, time, workshopType);
      // return timemap.get(time).length;
      // }
    }

    if (timemap.has(halfHourTime)) {
      return dateEquals(date, timemap, halfHourTime, workshopType);
    } else {
      console.log('is false g');
      return '';
    }
  };
  console.log('workshop_schedule from load------');
  console.log(workshop_schedule);

  // const renderWorkshopsByTimeAndStyle2 = (
  //   // workshopTimeVar,
  //   // workshopTypeVar,
  //   workshopDateVar
  // ) => {
  //   let workshop_dict = new Map(Object.entries(workshop_schedule));
  // };
  const isHost = () => {
    return event.hostId === user?._id;
  };

  const workshopJoined = (workshop_attendee_count) => {
    console.log(workshop_attendee_count);
  };
  const [showAttendees, setShowAttendees] = useState(false);

  const isEqualTo = (id, workshop_name, workshop) => {
    // console.log(`insturctor id for title ${workshop_name} is: ------`);
    // console.log(id);

    // console.log('user._id is ---------');
    // console.log(user._id);
    console.log('workshop --');
    console.log(workshop);
    return id === user._id;
  };

  // let wshops = renderWorkshopsByTimeAndStyle(
  //   workshopTime,
  //   workshopType,
  //   workshopDate
  // );
  // console.log('wshops');
  // console.log(wshops);

  const joinedWorkshop = (workshop) => {
    let attendees = workshop.attendees;
    for (let i = 0; i < attendees.length; i++) {
      if (attendees[i]._id === user?._id) {
        console.log('already joined b');
        return true;
      }
    }
    console.log('no join');
    return false;
  };

  const reloadWorkshopsOnJoin = () => {
    console.log('reloading workshops');
    getWorkshopSchedule(event._id);
  };

  // const renderStuff = (
  //   <Fragment>
  //     {!visible ? (
  //       <div>
  //         <div className="row">
  //           {renderSalsaButton()}
  //           {renderBachataButton()}
  //           {renderKizombaButton()}
  //           {renderZoukButton()}
  //         </div>
  //         <hr />
  //       </div>
  //     ) : (
  //       <></>
  //     )}
  //     {!visible ? (
  //       <div>
  //         {times_array.map((e, i) => (
  //           <Button
  //             className="workshop-times"
  //             onClick={() => {
  //               setWorkshopTime(times_array[i]);
  //               setShowAttendees(false);
  //             }}
  //             style={
  //               workshopTime === e
  //                 ? {
  //                     color: 'rgb(1, 126, 252)',
  //                     borderStyle: 'solid',
  //                     borderColor: 'rgb(1, 126, 252)',
  //                     background: 'white',
  //                   }
  //                 : {}
  //             }
  //             // active={t === chosen}
  //           >
  //             {/* {times_array[i] +
  //                   ' ' +
  //                   workshopsForTime(times_array[i], workshop_schedule)} */}

  //             {workshopsForTime(
  //               times_array[i],
  //               workshop_schedule,
  //               workshopDate
  //             ) !== ''
  //               ? times_array[i] +
  //                 ' (' +
  //                 workshopsForTime(
  //                   times_array[i],
  //                   workshop_schedule,
  //                   workshopDate
  //                 ) +
  //                 ')'
  //               : // workshopAtTime
  //                 times_array[i]}
  //           </Button>
  //         ))}

  //         <div className="col">
  //           <WorkshopDate
  //             onChangeValue={handleDateChange}
  //             event={event}
  //             workshopDate={workshopDate}
  //           />
  //         </div>
  //         <hr />
  //       </div>
  //     ) : (
  //       <></>
  //     )}

  //     {!visible ? (
  //       <div className="vertical-artist-container">
  //         {/* <hr /> */}
  //         <div className="col">
  //           {renderWorkshopsByTimeAndStyle(
  //             workshopTime,
  //             workshopType,
  //             workshopDate
  //           )?.map((workshop) => (
  //             <Workshop
  //               // showAttendees={showAttendees}
  //               showAttendees={false}
  //               workshop={workshop}
  //               event={event}
  //               attendee_count={
  //                 workshop?.attendees.length > 0 ? workshop.attendees.length : 0
  //               }
  //               // alreadyJoined={workshop.attendees.includes(user?._id)}
  //               alreadyJoined={joinedWorkshop(workshop)}
  //               onJoin={reloadWorkshopsOnJoin}
  //               showJoinedButton={true}
  //             />
  //           ))}
  //         </div>
  //       </div>
  //     ) : (
  //       <></>
  //     )}
  //   </Fragment>
  // );

  const [showWorkshopSchedule, setShowWorkshopSchedule] = useState(false);
  const handleShowWorkshopSchedule = () => {
    setShowWorkshopSchedule(!showWorkshopSchedule);
  };
  return (
    <div>
      {/* commented below code to prioritize newer workshop schedule.. but we may use it again 
for free / unpaid events 
<Button onClick={() => handleShowWorkshopSchedule()}>
        {!showWorkshopSchedule ? `Workshop Schedule` : `Close`}
      </Button>
{showWorkshopSchedule === true ? renderStuff : <></>} 

*/}
      {/* {showWorkshopSchedule === true ? renderStuff : <></>} */}

      {/* {!visible ? (
        <div>
          <div className="row">
            {renderSalsaButton()}
            {renderBachataButton()}
            {renderKizombaButton()}
            {renderZoukButton()}
          </div>
          <hr />
        </div>
      ) : (
        <></>
      )}
      {!visible ? (
        <div>
          {times_array.map((e, i) => (
           

            <Button
              className="workshop-times"
              onClick={() => {
                setWorkshopTime(times_array[i]);
                setShowAttendees(false);
              }}
              style={
                workshopTime === e
                  ? {
                      color: 'rgb(1, 126, 252)',
                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',
                      background: 'white',
                    }
                  : {}
              }
            >
             

              {workshopsForTime(
                times_array[i],
                workshop_schedule,
                workshopDate
              ) !== ''
                ? times_array[i] +
                  ' (' +
                  workshopsForTime(
                    times_array[i],
                    workshop_schedule,
                    workshopDate
                  ) +
                  ')'
                :
                  times_array[i]}
            </Button>
          ))}

          <div className="col">
            <WorkshopDate
              onChangeValue={handleDateChange}
              event={event}
              workshopDate={workshopDate}
            />
          </div>
          <hr />
        </div>
      ) : (
        <></>
      )} */}
      {/* {isHost() === true ? ( */}
      {/* {1 === 1 ? ( */}
      {/* {isHost() === true || user?.email === 'airmeus.team@gmail.com' ? ( */}

      <div className="row">
        <div className="col">
          {/* <Button onClick={() => setVisible(!visible)}>Add Workshop</Button> */}
          <button
            onClick={() => {
              setVisible(!visible);
              console.log('show');
            }}
            style={{ width: 'max-content' }}
            class="btn btn-secondary"
          >
            {visible ? 'Cancel' : 'Add Workshop'}
          </button>

          {visible && (
            <AddWorkshopPopup
              event={event}
              takenWorkshopRoomsMap={takenWorkshopRoomsMap}
              setVisible={setVisible}
              visible={visible}
              current_user={user}
            />
            // <AddWorkshopFields
            //   event={event}
            //   takenWorkshopRoomsMap={takenWorkshopRoomsMap}
            // />
          )}
        </div>

        {/* <div className="col">
              <WorkshopDate onChangeValue={handleDateChange} event={event} />
            </div> */}
      </div>

      {/* ) : (
        <></>
      )} */}

      {/* {!visible ? (
        <div className="vertical-artist-container">
          <hr />
          <div className="col">
            {renderWorkshopsByTimeAndStyle(
              workshopTime,
              workshopType,
              workshopDate
            )?.map((workshop) => (
              <Workshop
                showAttendees={showAttendees}
                workshop={workshop}
                event={event}
                attendee_count={
                  workshop?.attendees.length > 0 ? workshop.attendees.length : 0
                }
                alreadyJoined={workshop.attendees.includes(user?._id)}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )} */}
    </div>
  );
};
export default WorkshopScheduleNoPopup;
