import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';
import StudioContext from '../../context/studio/studioContext';
import AddStudioLevel from './AddStudioLevel';
import Levels from './Levels';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

const Score = (props) => {
  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;

  const studioContext = useContext(StudioContext);
  const {
    getStudioById,
    studio,
    getStudioMembers,
    members,
    addNewMember,
    getStudioLevels,
    studio_levels,
  } = studioContext;

  const userContext = useContext(UserContext);

  const { connectToUser } = userContext;
  let copyClicked = false;
  const location = useLocation();
  //   const { user } = location.state;

  // const { user, current_user_id, isCurrentUser } = props;
  //   const { scores } = props;
  const { studio_id } = useParams();

  useEffect(() => {
    // console.log('scores from score.js');
    // console.log(scores);
    // console.log('studio_id');
    // console.log(studio_id);
    // //get studio by id
    // getStudioById(studio_id);
    // getStudioMembers(studio_id);
    // getStudioLevels(studio_id);
    // console.log('studio from company');
    // console.log(studio);
    // eslint-disable-next-line
  }, []);

  const { skill, visible, handleScoreUpdate, isStudentAccount } = props;
  let possible_scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [score, setScore] = useState(skill.score);

  const handleScoreChange = (e) => {
    console.log('score changed');
    setScore(e);
    let moveName = skill.move_name;
    handleScoreUpdate(e, moveName);
    // setDetails({
    //   ...details,
    //   ['maxTrainingHoursPerDay']: e,
    // });
  };

  const renderGradeChangeButton = (score) => {
    if (score < 0) {
      return (
        <Button
          onClick={() => {
            //perhaps add a popup with a note from instuctor
            //or a hard-coded/dynamic note like "with a bit of practice your scores will increase!"
            //or "nice, your score went up! we see you working hard!"
          }}
          style={{
            backgroundColor: 'red',
            width: '70px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {score}
        </Button>
      );
    }
    if (score > 0) {
      return (
        <Button
          onClick={() => {
            //perhaps add a popup with a note from instuctor
            //or a hard-coded/dynamic note like "with a bit of practice your scores will increase!"
            //or "nice, your score went up! we see you working hard!"
          }}
          style={{
            backgroundColor: 'green',
            width: '70px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          +{score}
        </Button>
      );
    }
    if (score === 0) {
      return (
        <Button
          onClick={() => {
            //perhaps add a popup with a note from instuctor
            //or a hard-coded/dynamic note like "with a bit of practice your scores will increase!"
            //or "nice, your score went up! we see you working hard!"
          }}
          style={{
            backgroundColor: 'yellow',
            width: '70px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {score}
        </Button>
      );
    }
  };

  return (
    <div>
      {visible &&
        !isStudentAccount && (
          <div className="row">
            <div className="col-6">
              <h5
                style={{
                  maxWidth: '220px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {skill.move_name}
              </h5>
            </div>

            <div className="col">
              <div className="col">
                {[DropdownButton].map((DropdownType, idx) => (
                  <DropdownType
                    as={ButtonGroup}
                    key={idx}
                    id={`dropdown-button-drop-${idx}`}
                    size="sm"
                    variant="secondary"
                    title={score}
                    onSelect={handleScoreChange}
                  >
                    {possible_scores.map((score) => (
                      <Dropdown.Item eventKey={score}>{score}</Dropdown.Item>
                    ))}
                  </DropdownType>
                ))}
              </div>
            </div>
            <div className="col">
              {renderGradeChangeButton(skill.change_since_last_update)}
            </div>
          </div>
        )}

      {visible &&
        isStudentAccount && (
          <div className="row">
            <div className="col">
              <h5>{skill.move_name}</h5>
            </div>

            <div className="col">
              <div className="col">
                <label htmlFor="">{score}</label>
              </div>
            </div>
            <div className="col">
              {renderGradeChangeButton(skill.change_since_last_update)}
            </div>
          </div>
        )}

      {/* ))} */}
    </div>
  );
};

export default Score;
