import React, { Component } from 'react';

const LessonPlanMoves = (props) => {
  return (
    <div>
      <h5 style={{ fontSize: '28px' }}>LessonPlanMoves</h5>
      {props?.moves_to_learn?.map((move) => (
        <div>
          <h5>-- {move.move_name}</h5>
        </div>
      ))}
    </div>
  );
};

export default LessonPlanMoves;
