import React, { Component, useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
// import { Form } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import Countries from './countries.json';
import RankingsContext from '../../context/rankings/rankingsContext';
import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';

const PopupFilter = (props) => {
  const rankingsContext = useContext(RankingsContext);
  const { rankings, getRankings, getFilteredRankings } = rankingsContext;

  const [show, setShow] = useState(props.filterClicked);
  const handleClose = () => setShow(false);
  const countries = Countries;
  let { style } = props;
  const [filterDetails, setFilterDetails] = useState({
    // country: countries[0],
    country: 'United States',
    state: 'none',
    city: 'none',
    style: style,
  });

  const { city, state, country } = filterDetails;
  const styles = ['bachata', 'salsa', 'kizomba', 'zouk'];

  const handleFilter = () => {
    let obj = filterDetails;
    console.log('obj fdd');
    console.log(obj);
    getFilteredRankings(obj);
    setShow(false);
  };

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState(['']);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setFilterDetails({
      ...filterDetails,
      city: cities ? cities[0] : '',
      state: state,
    });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="col"> */}
          <Form.Group controlId="formBasicSelect">
            <Form.Label>Dance Style</Form.Label>
            <Form.Control
              id="style"
              name="style"
              as="select"
              value={style}
              required
              onChange={(e) => {
                setFilterDetails({
                  ...filterDetails,
                  [e.target.name]: e.target.value,
                });
                console.log(filterDetails);
                //   props.handleLocationChange(e.target.value, state, 'all');
              }}
            >
              {styles?.map((style) => <option value={style}>{style}</option>)}
            </Form.Control>

            <Form.Label>Country</Form.Label>
            <Form.Control
              id="country"
              name="country"
              as="select"
              value={country}
              required
              onChange={(e) => {
                setFilterDetails({
                  ...filterDetails,
                  [e.target.name]: e.target.value,
                });
                console.log(filterDetails);
                //   props.handleLocationChange(e.target.value, state, 'all');
              }}
            >
              {countries?.map((country) => (
                <option value={country}>{country}</option>
              ))}
            </Form.Control>
            <Form.Label>State</Form.Label>
            <Form.Control
              id="state"
              name="state"
              as="select"
              value={state}
              required
              onChange={(e) => {
                setFilterDetails({
                  ...filterDetails,
                  [e.target.name]: e.target.value,
                });
                let cities = selectedCities(e.target.value);

                console.log(filterDetails);
                //   props.handleLocationChange(e.target.value, state, 'all');
              }}
            >
              {listOfStates.map((state) => (
                <option value={state}>{state}</option>
              ))}
              {/* {countries?.map((state) => (
                <option value={state}>{state}</option>
              ))} */}
            </Form.Control>
            <Form.Label>City</Form.Label>
            <Form.Control
              id="city"
              name="city"
              as="select"
              value={city}
              required
              onChange={(e) => {
                setFilterDetails({
                  ...filterDetails,
                  [e.target.name]: e.target.value,
                });
                console.log(filterDetails);
                //   props.handleLocationChange(e.target.value, state, 'all');
              }}
            >
              {/* {countries?.map((city) => <option value={city}>{city}</option>)} */}
              {listOfCitiesForSelectedState?.map((city) => (
                <option value={city}>{city}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <div style={{ paddingTop: '10px' }}>
            {/* <Button onClick={() => console.log(filterDetails)}>Filter</Button> */}
            <Button onClick={handleFilter}>Filter</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PopupFilter;
