/* eslint-disable import/no-anonymous-default-export */
// import React from 'react';
// import PropTypes from 'prop-types';

import {
  CREATE_SUB_EVENT,
  GET_SUB_EVENTS_FOR_EVENT,
  EVENT_ERROR,
  JOIN_SUB_EVENT,
  GET_ATTENDEES_FOR_SUB_EVENT,
  GET_ALL_SUB_EVENTS,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_SUB_EVENTS_FOR_EVENT:
      return {
        ...state,
        sub_events: action.payload,

        // loading: false,
      };
    case GET_ATTENDEES_FOR_SUB_EVENT:
      return {
        ...state,
        sub_event_attendees: action.payload,
        // loading: false,
      };
    case CREATE_SUB_EVENT:
      return {
        ...state,
        new_sub_event: action.payload,
      };
    case JOIN_SUB_EVENT:
      return {
        ...state,
        joined_sub_event: action.payload,
      };
    case GET_ALL_SUB_EVENTS:
      return {
        ...state,
        activities: action.payload,
      };
    case EVENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
