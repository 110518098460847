import {
  GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR,
  GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR_AT_EVENTS,
  SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR,
  DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR,
  SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR_AT_EVENT,
  GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_AT_EVENT,
  GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_NO_EVENT,
  DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR_AT_EVENT,
  SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_INSTRUCTOR,
  INSTRUCTOR_ERROR,
  //   ARTIST_ERROR,
  //   GET_ONE_ARTIST,
  //   GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST,
  //   GET_PRIVATE_LESSON_REQUESTS_FOR_ARTIST_AT_EVENTS,
  //   SEND_PRIVATE_LESSON_REQUEST,
  //   DECISION_PRIVATE_LESSON_REQUEST,
  //   GET_ARTISTS_FOR_SEARCH_CRITERIA,

  //   GET_ALL_ARTISTS_FOR_DIRECTORY,
  //   GET_OTHER_ARTISTS,
  //   GET_ONE_RANDOM_ARTIST,
  //   GET_TOP_ARTISTS_LIMIT_FIVE,

  // CONVERT_TO_PREMIUM_ARTIST,
  // GET_RANDOM_ARTISTS_FOR_TIMELINE,
  // ADD_NEW_ARTIST,
  // GET_ALL_DJS,
  // GET_RANDOM_DJS_FOR_TIMELINE,
} from '../types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => {
  switch (action.type) {
    case GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR:
      return {
        ...state,
        privateLessonRequestsForInstructor: action.payload,
        loading: false,
      };
    case GET_PRIVATE_LESSON_REQUESTS_FOR_INSTRUCTOR_AT_EVENTS:
      return {
        ...state,
        privateLessonRequestsForInstructorAtEvents: action.payload,
        loading: false,
      };
    case GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_AT_EVENT:
      return {
        ...state,
        confirmedPrivateLessonsForInstructorAtEvents: action.payload,
        loading: false,
      };
    case GET_CONFIRMED_LESSONS_FOR_INSTRUCTOR_NO_EVENT:
      return {
        ...state,
        confirmedPrivateLessonsForInstructorNoEvent: action.payload,
        loading: false,
      };
    case SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR:
      return {
        ...state,
        privateLessonRequestForInstructor: action.payload,
        loading: false,
      };
    case SEND_PRIVATE_LESSON_REQUEST_WITHOUT_ACCOUNT_TO_INSTRUCTOR:
      return {
        ...state,
        // privateLessonRequestForInstructor: action.payload,
        loading: false,
      };
    case SEND_PRIVATE_LESSON_REQUEST_TO_INSTRUCTOR_AT_EVENT:
      return {
        ...state,
        // privateLessonRequestForInstructor: action.payload,
        loading: false,
      };

    case DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR:
      return {
        ...state,
        // privateLessonRequests: action.payload,
        loading: false,

        //return the id of the plr that was removed and filter it out
        privateLessonRequestsForInstructor: state.privateLessonRequestsForInstructor.filter(
          (request) => request._id !== action.payload
        ),
      };
    case DECISION_PRIVATE_LESSON_REQUEST_FOR_INSTRUCTOR_AT_EVENT:
      return {
        ...state,
        // privateLessonRequests: action.payload,
        loading: false,

        //return the id of the plr that was removed and filter it out
        confirmedPrivateLessonRequestsForInstructorAtEvents: state?.confirmedPrivateLessonRequestsForInstructorAtEvents?.filter(
          (request) => request._id !== action.payload
        ),
      };

    //   case GET_ARTISTS_FOR_SEARCH_CRITERIA:
    //     return {
    //       ...state,
    //       artistSearchResults: action.payload,
    //       loading: false,
    //     };

    // case GET_TOP_ARTISTS_LIMIT_FIVE:
    //     return {
    //       ...state,
    //       topArtistsLimitFive: action.payload,
    //       loading: false,
    //     };

    //   case GET_ONE_ARTIST:
    //     return {
    //       ...state,
    //       artist: action.payload[0],
    //       rankings: action.payload[1],
    //       artistWorldRanking: action.payload[2],
    //       djRankings: action?.payload[3],
    //       djRankDetailsObj: action?.payload[4],
    //       loading: false,
    //     };
    //   case GET_ALL_ARTISTS_FOR_DIRECTORY:
    //     return {
    //       ...state,
    //       artist_directory: action.payload,
    //       loading: false,
    //     };
    //   case GET_ALL_DJS:
    //     return {
    //       ...state,
    //       DJs: action.payload,
    //       loading: false,
    //     };
    //   case GET_RANDOM_DJS_FOR_TIMELINE:
    //     return {
    //       ...state,
    //       randomDJsForTimeline: action.payload,
    //       loading: false,
    //     };
    //   case ADD_NEW_ARTIST:
    //     return {
    //       ...state,
    //       new_artist: action.payload,
    //       loading: false,
    //     };
    //   case GET_OTHER_ARTISTS:
    //     return {
    //       ...state,
    //       other_artists: action.payload,
    //       loading: false,
    //     };
    //   case GET_RANDOM_ARTISTS_FOR_TIMELINE:
    //     return {
    //       ...state,
    //       artists_we_love: action.payload,
    //       loading: false,
    //     };
    //   case CONVERT_TO_PREMIUM_ARTIST:
    //     return {
    //       ...state,
    //       artist: action.payload,
    //       loading: false,
    //     };
    //   case GET_ONE_RANDOM_ARTIST:
    //     return {
    //       ...state,
    //       random_artist: action.payload,
    //       loading: false,
    //     };

    case INSTRUCTOR_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
