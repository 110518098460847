import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import StudioContext from '../../context/studio/studioContext';
import Scores from './Scores';

const DancerSkills = (props) => {
  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;
  const userContext = useContext(UserContext);

  const { connectToUser } = userContext;
  let copyClicked = false;
  const location = useLocation();
  const { user, studio_id } = location.state;

  // const { user, current_user_id, isCurrentUser } = props;
  const studioContext = useContext(StudioContext);
  const {
    getStudioById,
    studio,
    getStudioMembers,
    members,
    addNewMember,
    getStudioLevels,
    studio_levels,
    assignOrUpdateMemberLevel,
    getStudioMember,
    studio_member,
  } = studioContext;
  useEffect(() => {
    // getStudioLevels(user?.dance_company_id);
    //changes 8.2.23 bcuz we changed the Studio model for user.dance_company_id
    //search codebase for .dance_company_id and replace where needed
    // getStudioMember(user?.dance_company_id, user._id);
    getStudioMember(studio_id, user._id);
    console.log('user from useeffect');
    console.log(user);
    //   alreadyConnected();
    console.log('current_user_id');
    //   console.log(current_user_id);
    //   console.log('isCurrentUserProfile');
    //   console.log(props.isCurrentUser);
    console.log('studio_member');
    console.log(studio_member);
    // eslint-disable-next-line
  }, []);

  const [shareButtonText, setShareButtonText] = React.useState('Share Profile');

  // const isNotConnected = () => {
  //   return (
  //     typeof current_user_id !== 'undefined' &&
  //     !alreadyConnected() &&
  //     !isCurrentUser
  //   );
  // };
  // const [connectButtonText, setConnectButtonText] = useState(
  //   isNotConnected ? `Connect` : `Connected`
  // );

  const connectToUserFunction = (e) => {
    e.preventDefault();

    let obj = {
      userIdToConnectTo: user._id,
      // current_user_id: current_user_id,
    };
    console.log(obj);
    //   setConnectButtonText('Connected');

    // let notification_obj = {
    //   current_user_id: user._id,
    //   // eventId: roomie_request.event,
    //   accepted_user_id: roomie_request.requestor,
    //   //will also need accepting users name
    // };

    connectToUser(obj);
    // sendSMSAlertForAcceptedRoommate(notification_obj);
    console.log('success????');
  };

  // const alreadyConnected = () => {
  //   return user.connections?.includes(current_user_id);
  // };

  // const { profile } = this.props;
  let image;
  if (user?.image) {
    image = user?.image.url;
  } else {
    image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  }

  const notClicked = (
    <Fragment>
      <Button
        variant="primary"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          console.log('copied text');
          copyClicked = true;
          setShareButtonText('copied');
        }}
        style={{
          width: 'max-content',
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
        }}
      >
        {shareButtonText}
      </Button>
    </Fragment>
  );
  const clicked = (
    <Fragment>
      <div className="col-3">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          Profile Link Copied
        </button>
      </div>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!copyClicked) {
      console.log('no user found');
      return notClicked;
    } else {
      return clicked;
    }
  };

  // const usersNotConnected = (
  //   <Fragment>
  //     <div className="col-3">
  //       <button
  //         type="button"
  //         // className="btn btn-dark"
  //         className="btn btn-primary my-2 my-sm-0"
  //         onClick={connectToUserFunction}
  //         disabled={connectButtonText === 'Connected'}
  //       >
  //         {connectButtonText}
  //       </button>
  //     </div>
  //   </Fragment>
  // );

  // const connected = () => {
  //   if (
  //     typeof current_user_id !== 'undefined' &&
  //     !alreadyConnected() &&
  //     !isCurrentUser
  //   ) {
  //     return usersNotConnected;
  //   } else {
  //     return <></>;
  //   }
  // };

  const [disabled, setDisabled] = useState(false);
  const [likeCount, setLikeCount] = React.useState(
    user.likeCount ? user.likeCount : 0
  );

  const handleLike = async (liked_user_id) => {
    if (user) {
      //like user & disable like button
      let obj = {
        likedUserId: liked_user_id,
        likingUserId: user?._id,
        // eventId: roomie_request.event,
        // accepted_user_id: roomie_request.requestor,
      };
      console.log('obj is');
      console.log(obj);
      likeDancer(obj);
      setDisabled(true);
    } else {
      console.log('alert user to log in to like and put login button in box');
    }
  };

  let notLiked = '🤍';
  let liked = '💙';
  const [likedBtn, setLikedBtn] = useState(notLiked);

  let possible_scores = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [score, setScore] = useState(1);

  const handleScoreChange = (e) => {
    console.log('score changed');
    setScore(e);
    // setDetails({
    //   ...details,
    //   ['maxTrainingHoursPerDay']: e,
    // });
  };

  const [visible, setVisible] = React.useState(false);

  const onChange = (e) => {
    console.log('e.target');
    console.log(e.target);
    console.log('e.target.value');
    console.log(e.target.value);
    setLevel(e.target.value);

    console.log('changing list -- e is:');
    // console.log(e.target);
    // setMemberArray(...member_array, e.target.name);
    // console.log(member_array);

    // if (role_array.includes(e.target.id)) {
    //   console.log('removing role');
    //   role_array = role_array.filter((item) => item !== e.target.id);
    //   setRoles({ role_array });
    // } else {
    //   console.log('adding role');
    //   role_array.push(e.target.id);
    //   setRoles({ role_array });
    // }

    // const member_set = new Set(role_array);
    // const uniqueMembers = [...member_set];
    // console.log('role_array');
    // console.log(role_array);
  };

  const [level, setLevel] = React.useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('user level');
    console.log(e);
    console.log('level --');
    console.log(level);
    //assignOrUpdateMemberLevel(member_id, company_id)

    //upcomment below 11.15.22
    // console.log(level);

    assignOrUpdateMemberLevel({
      user_id: user._id,
      // company_id: user?.dance_company_id,
      company_id: studio_id,
      new_level: level,
    });

    console.log('obj here');

    console.log({
      user_id: user._id,
      // company_id: user?.dance_company_id,
      company_id: studio_id,
      new_level: level,
    });
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-body bg-primary text-white mb-3">
          <div className="row text-center">
            <div className="col col-md-3 m-auto">
              <img
                className="rounded-circle"
                //   src={profile.user.avatar}
                // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                src={image}
                // src="https://media.npr.org/assets/img/2015/12/21/aaron-burr-full-b-w-535d3f769e43324513760db821859e465748dbb0.jpg"
                alt=""
                height="150"
                width="150"
              />
            </div>

            <h3 className="display-4 text-center">
              {/* {user?.first_name + ' ' + user?.last_name} */}

              {user?.first_name ? (
                <h5>{user?.first_name + ' ' + user?.last_name}</h5>
              ) : (
                <h5>{user?.name}</h5>
              )}
            </h3>
            <div className="col">
              <Button
                onClick={() => {
                  handleLike(user._id);
                  setLikeCount(likeCount + 1);
                  setDisabled(true);
                  console.log(user._id);
                }}
                disabled={disabled || user.likes.includes(user?._id)}

                //   disabled={user.likes.includes(user?._id)}
              >
                {user.likes.includes(user?._id) ? liked : notLiked}{' '}
              </Button>

              {/* {dancer?.likeCount} */}
              {/* {dancer?.likeCount > 0 ? `${likeCount} likes` : ``} */}
              <label style={{ fontSize: '14px' }}>{likeCount} likes</label>
            </div>

            <h4>{user?.location}</h4>
            <Link to={`/my-connections`} state={{ target_user: user }}>
              {user?.connections?.length} Connections
            </Link>
            <div className="row">
              {/* {connected()} */}
              <div className="col">
                {/* <Button
                      variant="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        console.log('copied text');
                        copyClicked = true;
                      }}
                    >
                      Share Profile
                    </Button> */}
                {renderOptions()}
              </div>
              <div className="col">
                {/* <Link to={`/studio/${user?.dance_company_id}`}> */}
                <Link to={`/studio/${studio_id}`}>
                  <Button>{user?.dance_company_name}</Button>
                </Link>
              </div>
              {user?.instagram_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Instagram: {user?.instagram_name}
                </h5>
              ) : (
                <></>
              )}
              {user?.tiktok_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Tiktok: {user?.tiktok_name}
                </h5>
              ) : (
                <></>
              )}

              {user?.website_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Website: {user?.website_name}
                </h5>
              ) : (
                <></>
              )}

              {user?.website_name ||
              user?.tiktok_name ||
              user?.instagram_name ? (
                <hr />
              ) : (
                <></>
              )}
              <h5 style={{ fontSize: '16px' }}>{user?.about_you}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Button
            onClick={() => setVisible(!visible)}
            style={{
              width: 'max-content',
              background: 'white',
              // color: 'blue',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
            }}
          >
            {visible ? 'Cancel' : 'Update Student Level'}
          </Button>
          <hr />
          {visible && (
            <div>
              <h3>levels</h3>

              <form onSubmit={onSubmit}>
                {studio_levels.map((level) => (
                  <div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="level"
                        id={level.class_name}
                        value={`${level?.style} | ${level.class_name}`}
                        // onChange={onChangeRoles}
                        onChange={onChange}
                        dance_style={level?.style}
                      />

                      <label class="form-check-label" for="student">
                        {level?.style + ' ' + level.class_name}
                      </label>
                    </div>
                  </div>
                ))}
                <input type="submit" value="Update" class="btn btn-secondary" />
              </form>
            </div>
          )}
        </div>
        {/* <div className="col">
          <h3>skills</h3>
        </div> */}
      </div>

      {studio_member?.level_progression?.scores_and_styles.map((scores) => (
        <Scores
          scores={scores}
          member_id={user._id}
          // studio_id={user?.dance_company_id}
          studio_id={studio_id}
          isStudentAccount={false}
        />
      ))}

      {/* <div className="row">
        <div className="col">
          <h4>skill name</h4>
        </div>
        <div className="col">
          <h4>score</h4>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h5>cross body lead</h5>
        </div>
        <div className="col">3</div>
      </div>
      <div className="row">
        <div className="col">
          <h5>cross body lead</h5>
        </div>
        <div className="col">3</div>
      </div>
      <div className="row">
        <div className="col">
          <h5>cross body lead</h5>
        </div>
        <div className="col">
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="sm"
              variant="secondary"
              title={score}
              onSelect={handleScoreChange}

              // onClick={() => {
              //   setLevelButtonClicked(!levelButtonClicked);
              // }}
              // onSelect={handleSelectLevel}
            >
              {possible_scores.map((score) => (
                <Dropdown.Item eventKey={score}>{score}</Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default DancerSkills;
