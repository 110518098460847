import React, { Component, useState } from 'react';
import { Modal } from 'react-bootstrap';

const TourCompanyPopup = (props) => {
  const [show, setShow] = useState(props.companyClicked);
  const handleClose = () => setShow(props.setCompanyClicked(false));
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Dance Companies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <div>
              <h5>{props?.company_name}</h5>
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TourCompanyPopup;
