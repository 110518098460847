import React, { useReducer } from 'react';
import axios from 'axios';
// import ArtistContext from './artistContext'; //be sure the case is not case sensitive
// import ArtistReducer from './artistReducer';
import ChatContext from './chatContext'; //be sure the case is not case sensitive
import ChatReducer from './chatReducer';

import {
  GET_CHATS_FOR_EVENT_AND_USER,
  GET_SINGLE_CHAT_MESSAGES,
  SEND_MESSAGE,
  ADD_NEW_GROUP_CHAT,
  JOIN_CHAT,
  CHAT_ERROR,
} from '../types';
const ChatState = (props) => {
  const initialState = {
    event_chat_for_user: [],
    singleChatMessages: [],
    // privateLessonRequests: [],
    // privateLessonRequest: {},
    // privateLessonRequestsAtEvents: [],
    // confirmedPrivateLessons: {},
  };

  const [state, dispatch] = useReducer(ChatReducer, initialState);

  const getChats = async (event_id) => {
    try {
      console.log('trying getartistbyid');
      const res = await axios.get(`/api/chats/${event_id}`);
      console.log('res below from getChats() in chatstate.js');
      console.log(res);

      dispatch({
        type: GET_CHATS_FOR_EVENT_AND_USER,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CHAT_ERROR,
        payload: err,
      });
    }
  };
  const getSingleChatMessages = async (chat_id) => {
    try {
      console.log('trying getSingleChatMessages');
      const res = await axios.get(`/api/messages/${chat_id}`);
      console.log('res below from getSingleChatMessages() in chatstate.js');
      console.log(res);

      dispatch({
        type: GET_SINGLE_CHAT_MESSAGES,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: CHAT_ERROR,
        payload: err,
      });
    }
  };
  const sendMessage = async (obj) => {
    try {
      console.log('trying sendMessage');
      const res = await axios.post(`/api/messages`, obj);
      console.log('res below from sendMessage() in chatstate.js');
      console.log(res);

      dispatch({
        type: SEND_MESSAGE,
        payload: res.data,
      });
      // window.location.reload();
      getSingleChatMessages(obj.chatId);
    } catch (err) {
      dispatch({
        type: CHAT_ERROR,
        payload: err,
      });
    }
  };
  const addNewGroupChat = async (obj) => {
    try {
      console.log('trying addNewGroupChat');
      const res = await axios.post(`/api/chats/group`, obj);
      console.log('res below from addNewGroupChat() in chatstate.js');
      console.log(res);

      dispatch({
        type: ADD_NEW_GROUP_CHAT,
        payload: res.data,
      });
      // window.location.reload();
      getChats(obj.event_id);
    } catch (err) {
      dispatch({
        type: CHAT_ERROR,
        payload: err,
      });
    }
  };
  const joinChat = async (obj) => {
    try {
      console.log('trying joinChat');
      const res = await axios.post(`/api/chats/join-chat`, obj);
      console.log('res below from joinChat() in chatstate.js');
      console.log(res);

      dispatch({
        type: JOIN_CHAT,
        payload: res.data,
      });
      // window.location.reload();
      getChats(obj.event_id);
    } catch (err) {
      dispatch({
        type: CHAT_ERROR,
        payload: err,
      });
    }
  };

  return (
    <ChatContext.Provider
      value={{
        // s = state.s;
        event_chat_for_user: state.event_chat_for_user,
        singleChatMessages: state.singleChatMessages,

        sendMessage,
        getSingleChatMessages,
        getChats,
        addNewGroupChat,
        joinChat,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

export default ChatState;
