import React, { Component, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import RankingsContext from '../../context/rankings/rankingsContext';

const City = (props) => {
  let cityCountry = props.city;
  let city = props?.city?.split('-')[0];
  let country = props?.city?.split('-')[1];

  const rankingsContext = useContext(RankingsContext);
  const { rankings, getRankings, getFilteredRankings } = rankingsContext;

  const [citySelected, setCitySelected] = useState('New York City');
  const handleCitySelected = (city) => {
    props.setCityFilter(city);
    props.setCountryFilter(country);
    setCitySelected(city);
    props.setCurrentCity(city);
    getFilteredRankings({
      country: country,
      state: 'none',
      city: city,
      style: props.style,
    });

    console.log(city);
  };
  const renderLocationFilterButtons = (city) => {
    if (props.currentCity === city) {
      return (
        <Button
          onClick={() => {
            handleCitySelected(city);
            // setDjSelected(false);
          }}
          className="rounded-pill"
          style={{
            width: 'max-content',
            background: 'white',
            color: 'blue',
            borderStyle: 'solid',
            borderColor: 'blue',
          }}
        >
          {city}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            handleCitySelected(city);
            // setDjSelected(true);
          }}
          className="bg-primary rounded-pill"
          style={{
            width: 'max-content',
          }}
        >
          {city}
        </Button>
      );
    }
  };
  return (
    <div
      class="card-horizontal-menu card-block mx-2"
      //   style={{ overflowX: 'hidden' }}
      style={{ textAlign: 'center', padding: '0px 5px 0px 0px' }}
    >
      {renderLocationFilterButtons(city)}
    </div>
  );
};

export default City;
