import React, { useReducer } from 'react';
import axios from 'axios';
import TriviaContext from './triviaContext'; //be sure the case is not case sensitive
import TriviaReducer from './triviaReducer';

import {
  GET_ALL_TRIVIA,
  POST_NEW_TRIVIA,
  ADD_TRIVIA_TO_EVENT,
  GET_TRIVIA_FOR_EVENT,
  GENERATE_CONNECTIONS,
  // ADD_NEW_TRIVIA,
  TRIVIA_ERROR,
} from '../types';
const TriviaState = (props) => {
  const initialState = {
    // instructor: {},
    trivia: [],
    allTrivias: [],
    potentialConnections: [],
    triviaForThisEvent: {},

    loading: true,
  };

  const [state, dispatch] = useReducer(TriviaReducer, initialState);

  // const addNewTrivia = async (tourObj) => {
  //   try {
  //     console.log('trying addNewTrivia');
  //     const res = await axios.post(`/api/tours/new-tour`, tourObj);
  //     console.log('res below from addNewTrivia triviastate.js');
  //     console.log(res);

  //     dispatch({
  //       type: ADD_NEW_TRIVIA,
  //       payload: res.data,
  //     });
  //     // window.location.reload();
  //   } catch (err) {
  //     dispatch({
  //       type: TRIVIA_ERROR,
  //       payload: err,
  //     });
  //   }
  // };
  const addTriviaToEvent = async (tourObj) => {
    try {
      console.log('trying addNewTrivia');
      const res = await axios.post(`/api/tours/new-tour`, tourObj);
      console.log('res below from addNewTrivia triviastate.js');
      console.log(res);

      dispatch({
        type: ADD_TRIVIA_TO_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: TRIVIA_ERROR,
        payload: err,
      });
    }
  };
  // const addNewTriviaQuestion = async (tourObj) => {
  //   try {
  //     console.log('trying addNewTriviaQuestion');
  //     const res = await axios.post(
  //       `/api/trivia/add-new-trivia-question`,
  //       tourObj
  //     );
  //     console.log('res below from addNewTriviaQuestion triviastate.js');
  //     console.log(res);

  //     dispatch({
  //       type: ADD_NEW_TRIVIA_QUESTION,
  //       payload: res.data,
  //     });
  //     // window.location.reload();
  //   } catch (err) {
  //     dispatch({
  //       type: TRIVIA_ERROR,
  //       payload: err,
  //     });
  //   }
  // };
  const getAllTrivias = async () => {
    try {
      console.log('trying getAllTrivia');
      const res = await axios.get(`/api/trivia/all-trivias`);
      console.log('res below from getAllTrivia triviastate.js');
      console.log(res);

      dispatch({
        type: GET_ALL_TRIVIA,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: TRIVIA_ERROR,
        payload: err,
      });
    }
  };
  const getTriviaForThisEvent = async (trivia_id) => {
    try {
      console.log('trying getAllTrivia');
      const res = await axios.get(
        `/api/trivia/triviasForThisEvent/${trivia_id}`
      );
      console.log('res below from getAllTrivia triviastate.js');
      console.log(res);

      dispatch({
        type: GET_TRIVIA_FOR_EVENT,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: TRIVIA_ERROR,
        payload: err,
      });
    }
  };
  const getPotentialConnectionsFromTrivia = async (
    trivia_id,
    current_user_id
  ) => {
    try {
      console.log('trying getPotentialConnectionsFromTrivia');
      const res = await axios.get(
        `/api/trivia/generate-connections/${trivia_id}/${current_user_id}`
      );
      console.log(
        'res below from getPotentialConnectionsFromTrivia triviastate.js'
      );
      console.log(res);

      dispatch({
        type: GENERATE_CONNECTIONS,
        payload: res.data,
      });
      // window.location.reload();
    } catch (err) {
      dispatch({
        type: TRIVIA_ERROR,
        payload: err,
      });
    }
  };
  // const getStudiosInState = async (state) => {
  //   try {
  //     console.log('trying getStudiosInState');
  //     const res = await axios.get(`/api/studio/getStudiosByState/${state}`);
  //     console.log('res below from getStudiosInState triviastate.js');
  //     console.log(res);

  //     dispatch({
  //       type: GET_STUDIOS_IN_STATE,
  //       payload: res.data,
  //     });
  //     // window.location.reload();
  //   } catch (err) {
  //     dispatch({
  //       type: TRIVIA_ERROR,
  //       payload: err,
  //     });
  //   }
  // };

  return (
    <TriviaContext.Provider
      value={{
        // props -- where our state and actions will go
        trivia: state.trivia,
        allTrivias: state.allTrivias,
        triviaForThisEvent: state.triviaForThisEvent,
        potentialConnections: state.potentialConnections,

        getAllTrivias,
        addTriviaToEvent,
        getTriviaForThisEvent,
        getPotentialConnectionsFromTrivia,
      }}
    >
      {props.children}
    </TriviaContext.Provider>
  );
};

export default TriviaState;
