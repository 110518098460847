import React, { useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { useAuth, clearErrors, register } from '../../context/auth/AuthState';
import AdvertisementContext from '../../context/advertisement/advertismentContext';
import StripeContainer from '../payment/StripeContainer';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

//new stripe tutorial imports
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import Row from './prebuilt/Row';
import DonutShop from './prebuilt/DonutShop';
import Layout from './Layout';
import { Button } from 'react-bootstrap';

const SubmitAdRequest = (props) => {
  const advertisementContext = useContext(AdvertisementContext);
  const { createAd, new_advertisement, createAdRequest } = advertisementContext;

  const alertContext = useContext(AlertContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;

  const { user } = authState;

  const { setAlert } = alertContext;
  const [advert, setAdvert] = useState({
    promotionText: '',
    adImage: '',
    // ownersEmail: '',
    // ownersId: '',
    // phoneNumber: '',
  });
  const [file, setFile] = useState();
  // const [location, setLocation] = useState();

  const { promotionText, ownersEmail, phoneNumber, adImage } = advert;
  let navigate = useNavigate();
  const onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('file', file);
    data.append('promotionText', promotionText);
    data.append('ownersId', user._id);
    data.append('ownersEmail', user.email);
    data.append('phoneNumber', user.phoneNumber);
    data.append('adImage', adImage);

    if (promotionText === '' || ownersEmail === '' || phoneNumber === '') {
      setAlert('Please enter all fields', 'danger');
    } else {
      //   register(authDispatch, data);
      // createAd(data);
      // navigate('/payForAd', {
      //   state: {
      //     advert: advert,
      //     user: user,
      //     // promotionText: promotionText,
      //     file: file,
      //   },
      // });
      //   createAdvertisementRequest();
      createAdRequest(data);
      navigate('/user/profile', {
        state: {
          messageText:
            'We have received your request and a member from our team will follow up shortly',
        },
      });
      console.log('create Ad');
    }
  };
  const createAdvertisementRequest = (e) => {
    // e.preventDefault();
    const data = new FormData();
    data.append('file', file);
    data.append('promotionText', promotionText);
    data.append('ownersId', user._id);
    data.append('ownersEmail', user.email);
    data.append('phoneNumber', user.phoneNumber);
    data.append('adImage', adImage);
    createAdRequest(data);
    if (promotionText === '' || ownersEmail === '' || phoneNumber === '') {
      setAlert('Please enter all fields', 'danger');
    } else {
      //   register(authDispatch, data);
      // createAd(data);
      // navigate('/payForAd', {
      //   state: {
      //     advert: advert,
      //     user: user,
      //     // promotionText: promotionText,
      //     file: file,
      //   },
      // });

      navigate('/user/profile', {
        // state: {
        //   advert: advert,
        //   user: user,
        //   // promotionText: promotionText,
        //   file: file,
        // },
      });

      console.log('createAdRequest() running');
    }
  };
  const onChange = (e) =>
    setAdvert({ ...advert, [e.target.name]: e.target.value });
  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="form-container">
            <h3>
              Promote Your <span className="text-primary">Business</span>
            </h3>
          </div>

          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="name">
                Short One Line Description For Your Audience
              </label>
              <br />
              {promotionText.length > 0 ? (
                <label style={{ color: 'red' }} htmlFor="">
                  {0}/{60 - promotionText.length} characters left
                </label>
              ) : (
                <></>
              )}
              <input
                id="promotionText"
                type="text"
                name="promotionText"
                value={promotionText}
                onChange={onChange}
                required
              />
            </div>
            <input
              id="adImage"
              name="adImage"
              type="file"
              value={adImage}
              required
              onChange={(event) => {
                const file = event.target.files[0];
                setFile(file);
                setAdvert({
                  ...advert,
                  [event.target.name]: event.target.value,
                });
              }}
            />
            {user ? (
              <input
                type="submit"
                value="Submit"
                className="btn btn-primary btn-block"
              />
            ) : (
              <Link to="/login">
                <Button>Log in to submit</Button>
              </Link>
            )}
          </form>
        </div>

        {/* <div className="smallScreenPayFields">
          <Layout title="Donut Shop">
            <CheckoutForm
              price={50}
              onSuccessfulCheckout={() => {
                console.log('payment successful, now run createAd');

                createAdvertisementRequest();
                navigate('/user/profile', {
                  // state: {
                  //   advert: advert,
                  //   user: user,
                  //   // promotionText: promotionText,
                  //   file: file,
                  // },
                });
              }}
            />
          </Layout>
        </div> */}
      </div>
    </div>
  );
};

export default SubmitAdRequest;
