import React, { Component, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ArtistContext from '../../../context/artist/artistContext';
import { Button } from 'react-bootstrap';

const OtherArtists = (props) => {
  const artistContext = useContext(ArtistContext);
  const { getRandomArtist, random_artist } = artistContext;
  let { artist, updateSelectedArtist } = props;

  let [artistSelected, setArtistSelected] = useState(false);
  let [targetArtist, setTargetArtist] = useState(artist);

  //   useEffect(
  //     () => {
  //       // action on update of movies
  //       console.log('targetArtist updating:');
  //       console.log(targetArtist);
  //       setTargetArtist(artist);
  //     },
  //     [random_artist, setTargetArtist]
  //   );

  console.log('targetArtist is: -----');
  console.log(targetArtist);
  return (
    <div
      className="card2 bg2-primary text-white mb-3"
      style={{
        backgroundColor:
          targetArtist.premium_subscription === true ? '#00b300' : 'white',
      }}
    >
      {!artistSelected && (
        <Link
          to={`/artist-profile/${targetArtist._id}`}
          style={{
            color:
              targetArtist.premium_subscription === true ? '#white' : 'black',
          }}
          onClick={() => {
            updateSelectedArtist(targetArtist._id);
            setArtistSelected(!artistSelected);
            getRandomArtist();

            // setTargetArtist(random_artist);

            setArtistSelected(false);
          }}
        >
          <img
            src={
              targetArtist.image
                ? targetArtist?.image
                : targetArtist?.image_url?.url
            }
            height="95"
            width="95"
            alt="user"
            style={{ borderRadius: '0.25rem' }}
          />

          <h5
            style={{
              fontSize: '16px',
              paddingTop: '10px',
              textAlign: 'center',
            }}
          >
            {targetArtist.name}
          </h5>
        </Link>
      )}
    </div>
  );
};
export default OtherArtists;
