import React, { useState, useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { useAuth, clearErrors, register } from '../../context/auth/AuthState';
import AdvertisementContext from '../../context/advertisement/advertismentContext';

import { useLocation } from 'react-router-dom';

const StripeCheckout = (props) => {
  const advertisementContext = useContext(AdvertisementContext);
  const { createAd, new_advertisement } = advertisementContext;

  const alertContext = useContext(AlertContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;

  //   const { user } = authState;

  const location = useLocation();
  const { advert, user, promotionText, file } = location.state;
  return (
    <div>
      const button = document.querySelector("button")
      button.addEventListener("click", () =>{' '}
      {fetch('http://localhost:3000/api/payments/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        //rewrite and refactor/remove below 2 lines code.. it works,
        //but is unnecessary
        body: JSON.stringify({
          items: [{ id: 1, quantity: 3 }],
        }),
      })
        .then((res) => {
          if (res.ok) {
            const data = new FormData();
            data.append('file', file);
            data.append('promotionText', advert.promotionText);
            data.append('ownersId', user._id);
            data.append('ownersEmail', user.email);
            data.append('phoneNumber', user.phoneNumber);
            data.append('adImage', advert.adImage);
            // createAd(data);
            return res.json();
          }
          return res.json().then((json) => Promise.reject(json));
        })
        .then(({ url }) => {
          //   if (url.textOf('success=true') > -1) {
          //     //stripe success
          //     window.location = 'http://localhost:3000/user/profile';
          //   }
          window.location = url;
          //   console.log('window.location');
          //   console.log(window.location);

          //   else {
          //     //stripe failure
          //     window.location = 'http://localhost:3000/events';
          //   }
        })
        .catch((e) => {
          console.error(e.error);
        })}
      )
      <input
        type="submit"
        value="Promote"
        className="btn btn-primary-dark btn-block"
      />
    </div>
  );
};

export default StripeCheckout;
