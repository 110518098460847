import React, { Component, useContext, useState } from 'react';
import AlertContext from '../../context/alert/alertContext';
import { useAuth } from '../../context/auth/AuthState';
import UserContext from '../../context/user/userContext';

const UserIdentificationVerification = (props) => {
  const alertContext = useContext(AlertContext);
  const { setAlert, alerts } = alertContext;
  const [authState] = useAuth();
  const { user } = authState;
  const userContext = useContext(UserContext);
  const { sendUserVerificationForEventOwnership } = userContext;

  const [verificationDetails, setVerificationDetails] = useState({
    verification_image: '',
    requesting_user_id: user?._id,

    claim_type: 'event',
    account_id_to_claim: props.event._id,
    requesting_user_name: user?.name,
    requesting_user_image: user?.image,
  });

  const {
    verification_image,
    requesting_user_id,

    claim_type,
    account_id_to_claim,
    requesting_user_name,
    requesting_user_image,
  } = verificationDetails; //?
  const [file, setFile] = useState();

  const onSubmit = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', file);
    data.append('image_for_verification', verification_image);
    console.log('props.event');
    console.log(props.event);
    data.append('requesting_user_id', requesting_user_id);
    data.append('claim_type', claim_type);
    data.append('account_id_to_claim', account_id_to_claim);
    data.append('requesting_user_name', requesting_user_name);
    data.append('requesting_user_image_url', requesting_user_image);
    //uncomment 4.11.24

    if (verification_image === '') {
      setAlert('Please enter all fields', 'danger');
    } else {
      console.log('user is ---');
      console.log(user);
      console.log('verificationDetails ----------------');
      console.log(verificationDetails);
      sendUserVerificationForEventOwnership(data);

      // navigate('/', {
      //   state: {
      //     messageText:
      //       'We have received your claim request and a member from our team will follow up shortly',
      //   },
      // });
    }
  };

  return (
    <div>
      <h5
        style={{
          width: '220px',
          background: 'white',
          fontSize: '36px',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',
          borderWidth: '2px',
          borderRadius: '6px',

          // fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
        }}
      >
        Last Step !
      </h5>

      <h3>Verification</h3>

      <div className="form-container">
        <label htmlFor="verification_image">Photo Identification</label>

        <form onSubmit={onSubmit}>
          <input
            id="verification_image"
            name="verification_image"
            type="file"
            value={verification_image}
            onChange={(event) => {
              const file = event.target.files[0];
              setFile(file);
              setVerificationDetails({
                ...verificationDetails,
                [event.target.name]: event.target.value,
              });
            }}
          />

          <input
            type="submit"
            value="Submit"
            className="btn btn-primary btn-block"
          />
        </form>
      </div>
    </div>
  );
};

export default UserIdentificationVerification;
