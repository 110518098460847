import React, { Component, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CompletedTriviaResponses from './CompletedTriviaResponses';

const PotentialMatches = (props) => {
  let { userMatch, trivia_id } = props;
  let obj = { trivia_name: '', trivia_id: trivia_id };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  console.log('props from genereratedconnections / potentialmatches');
  console.log(props);
  return (
    <div className="card-body ">
      <img
        src={userMatch.user.image.url}
        alt="frog"
        // height="50"
        className="rounded-circle"
        height="75"
        width="75"
      />
      <div className="row">
        <div className="col-5">
          <h5>{userMatch.user.name}</h5>
        </div>
        <div className="col">
          <h5>{Math.trunc(userMatch.matchPercentage)} % match</h5>
        </div>
      </div>
      <Button
        onClick={() => {
          setShow(!show);
        }}
        style={{
          fontSize: '16px',
          backgroundColor: 'rgb(1, 126, 252)',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',
          borderWidth: '2px',
          borderRadius: '6px',
          color: 'white',
          textAlign: 'center',
          width: '240px',
        }}
      >
        Show Responses
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{userMatch?.user?.name + 's responses'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CompletedTriviaResponses
            matchedUser={userMatch.user}
            eventTrivia={obj}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PotentialMatches;
