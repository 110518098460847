import React, { Component, useContext, useState } from 'react';
import EventContext from '../../context/event/eventContext'; // might have to capitalize
import { useAuth } from '../../context/auth/AuthState';

import IncidentReportContext from '../../context/incidentReport/incidentReportContext';
import { useNavigate } from 'react-router-dom';

const IncidentReportFields = (props) => {
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;

  let incidentReportContext = useContext(IncidentReportContext);
  const {
    submitIncidentReport,
    submitted_incident_report,
  } = incidentReportContext;
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState({
    reporter_id: '',
    reporter_name: '',
    reporter_phone_number: '',

    victim_name: '',
    victim_phone_number: '',
    witness_name: '',
    witness_phone_number: '',
    offender_name: '',
    offender_phone_number: '',
    date_of_incident: '',
    description_of_incident: '',
    event_of_incident: {
      event_id: props?.event?._id ? props?.event?._id : '',
      event_name: props?.event?.name ? props?.event?.name : '',
    },
    location_of_incident: 'charlotte,nc',
    police_report_filed_or_will_be_filed: 'No',

    //
    //
    event_id: '',
    feedback_giver_id: '',
    feedback_giver_name: '',
    feedback_categories: [],
    how_can_this_event_improve: '',
    what_did_you_love_about_this_event: '',
  });

  const {
    reporter_id,
    reporter_name,
    reporter_phone_number,
    victim_name,
    victim_phone_number,
    witness_name,
    witness_phone_number,
    offender_name,
    offender_phone_number,
    date_of_incident,
    description_of_incident,
    location_of_incident,
    police_report_filed_or_will_be_filed,
    event_of_incident,

    how_can_this_event_improve,
    what_did_you_love_about_this_event,
  } = details;

  const onSubmit = (e) => {
    e.preventDefault();
    let event_of_incident_obj = {
      event_id: props?.event_id ? props?.event_id : '',
      event_name: props?.event_name ? props?.event_name : '',
    };

    let witnessesObj = { witness_name, witness_phone_number };
    let obj = {
      reporter_id: user?._id,
      reporter_name,
      reporter_phone_number,

      victim_name,
      victim_phone_number,
      offender_name,
      offender_phone_number,
      date_of_incident,
      event_of_incident: event_of_incident_obj,
      location_of_incident,
      description_of_incident: description_of_incident,
      police_report_filed_or_will_be_filed,

      witnesses: witness_name !== '' ? [witnessesObj] : [],
      //
      //
      //   event_id: props?.event?._id,
      //   feedback_giver_id: isAuthenticated ? user._id : '',
      //   feedback_giver_name: isAuthenticated ? user.name : '',
      //   feedback_categories: feedbackCategoriesAndScores,
      //   how_can_this_event_improve: how_can_this_event_improve,
      //   what_did_you_love_about_this_event: what_did_you_love_about_this_event,
    };

    // addEventFeedback(obj);
    console.log('IR obj ');
    console.log(obj);
    setLoading(true);
    submitIncidentReport(obj);
    setLoading(false);

    //spinner while we wait for request to resolve and update the submitted_incident_report
    //once resolved we'll navigate user to user/profile/IR/submitted_incident_report._id
    // if (!isObjectEmpty(submitted_incident_report)) {
    //   navigate(`/user/profile/${submitted_incident_report._id}`, {
    navigate(`/my-incident-reports/${user?._id}`, {
      state: {
        user_id: user?._id,
        messageText:
          'We have received your Incident Report and a member from our team will follow up shortly',
      },
    });
    // }
    // props.handleFeedbackSubmitted();
  };
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const onReportFiledChange = (e) => {
    console.log('e.target.value');
    console.log(e.target.value);
    setDetails({
      ...details,
      police_report_filed_or_will_be_filed: e.target.value,
    });
  };

  const isObjectEmpty = (objectName) => {
    for (let prop in objectName) {
      if (objectName.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  };

  //state updation
  //   const

  //   if(!isObjectEmpty(submitted_incident_report)){

  //   }

  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div>
      <form onSubmit={onSubmit}>
        {/* <div className="row">
          <div className="row">
            <h5>list stuff</h5>
          </div>
        </div> */}

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Reporter Name</label>
              <input
                id="reporter_name"
                type="text"
                name="reporter_name"
                value={reporter_name}
                onChange={onChange}
                required
                // required
                // onInvalid="add name"
              />
            </div>
          </div>

          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Reporter phone number</label>
              <input
                id="reporter_phone_number"
                type="text"
                name="reporter_phone_number"
                value={reporter_phone_number}
                onChange={onChange}
                required
                // required
                // onInvalid="add name"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Victim name</label>
              <input
                id="victim_name"
                type="text"
                name="victim_name"
                value={victim_name}
                onChange={onChange}
                required
                // required
                // onInvalid="add name"
              />
            </div>
          </div>

          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Victim phone number</label>
              <input
                id="victim_phone_number"
                type="text"
                name="victim_phone_number"
                value={victim_phone_number}
                onChange={onChange}
                required
                // required
                // onInvalid="add name"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Offender name</label>
              <input
                id="offender_name"
                type="text"
                name="offender_name"
                value={offender_name}
                onChange={onChange}
                required
                // required
                // onInvalid="add name"
              />
            </div>
          </div>

          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Offender phone number</label>
              <input
                id="offender_phone_number"
                type="text"
                name="offender_phone_number"
                value={offender_phone_number}
                onChange={onChange}
                required
                // required
                // onInvalid="add name"
              />
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="name">Date of incident</label>

          <input
            id="date_of_incident"
            type="date"
            name="date_of_incident"
            placeholder="date_of_incident"
            value={date_of_incident}
            onChange={onChange}
            required
          />
        </div>

        <div className="col">
          <div className="form-group">
            <label htmlFor="name">Location of incident</label>
            <input
              id="location_of_incident"
              type="text"
              name="location_of_incident"
              value={location_of_incident}
              onChange={onChange}
              required
              // required
              // onInvalid="add name"
            />
          </div>
        </div>

        <div>
          <h5>Has there been or will there be a police report filed?</h5>
          {['Yes', 'No'].map((selection) => (
            <div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="selection"
                  id={selection}
                  value={`${selection}`}
                  // onChange={onChangeRoles}
                  onChange={onReportFiledChange}
                  dance_style={selection}
                />

                <label class="form-check-label" for="police report filed">
                  {selection}
                </label>
              </div>
            </div>
          ))}
        </div>

        {/* 


...

...

...

*/}
        <div>
          <br />
          <textarea
            id="description_of_incident"
            type="textarea"
            name="description_of_incident"
            rows="4"
            cols="50"
            placeholder="Describe the incident."
            value={description_of_incident}
            onChange={onChange}
            required
          />

          <br />
          {/* </textarea> */}
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Witness 1</label>
              <input
                id="witness_name"
                type="text"
                name="witness_name"
                value={witness_name}
                onChange={onChange}
                // required
                // required
                // onInvalid="add name"
              />
            </div>
          </div>

          <div className="col">
            <div className="form-group">
              <label htmlFor="name">Witness 1 phone number</label>
              <input
                id="witness_phone_number"
                type="text"
                name="witness_phone_number"
                value={witness_phone_number}
                onChange={onChange}
                // required
                // required
                // onInvalid="add name"
              />
            </div>
          </div>
        </div>

        <input
          type="submit"
          value="Submit Incident Report"
          class="btn btn-secondary"
        />
      </form>
    </div>
  );
};
export default IncidentReportFields;
