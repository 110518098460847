import React, { Component, useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Skill from './Skill';
import RankingsContext from '../../../../context/rankings/rankingsContext';
import DanceSkills from './DanceSkills';
import { useAuth } from '../../../../context/auth/AuthState';
import { Link, useParams } from 'react-router-dom';

const SkillSection = (props) => {
  const [authState, authDispatch] = useAuth();
  const { isAuthenticated, user } = authState;

  let { dance_type, artist_id, skills_and_endorsements } = props;
  const [danceTypeToEndorse, setDanceTypeToEndorse] = useState(dance_type);

  const rankingsContext = useContext(RankingsContext);
  const {
    // getSkillsAndEndorsementsForUser,
    // skills_and_endorsements,
    submitEndorsement,
    loading,
  } = rankingsContext;

  let { instructor_id } = useParams();
  useEffect(() => {
    console.log('user?.artist_id from useeffect');
    console.log(user?.artist_id);
    console.log('instructor_id');
    console.log(instructor_id);
    // getSkillsAndEndorsementsForUser(artist_id);
    // eslint-disable-next-line
  }, []);
  console.log('skills_and_endorsements here');
  console.log(skills_and_endorsements);

  let bachataSkills = ['timing,musicality,connection,creativity'];
  let salsaSkills = ['salsa timing, s musicality, s connection,s creativity'];

  let switchOnSkillHelper = require('./Helper');

  // const returnSkillsBasedOnType = (dance_type) => {
  //   switch (dance_type) {
  //     case 'bachata':
  //       return ['timing', 'musicality', 'connection', 'creativity'];
  //     case 'salsa':
  //       return ['salsa timing', 's musicality', 's connection', 's creativity'];
  //     case 'kizomba':
  //       return [
  //         'kizomba timing',
  //         'kizomba musicality',
  //         'kizomba connection',
  //         'kizomba creativity',
  //       ];
  //     case 'zouk':
  //       return [
  //         'zouk timing',
  //         'zouk musicality',
  //         'zouk connection',
  //         'zouk creativity',
  //       ];

  //     default:
  //       return ['timing', 'musicality', 'connection', 'creativity'];
  //   }
  // };

  let [skillsToEndorse, setSkillsToEndorse] = useState([]);
  // let skillsToEndorse = [];
  if (loading) {
    return <h2>Loading...</h2>;
  }

  const handleEndorse = () => {
    // backend operation
    if (user) {
      console.log('endorsing user :');

      // console.log({
      //   endorser_id: user._id,
      //   endorsed_id: artist_id,
      //   skillsToEndorse: skillsToEndorse,
      //   isArtistFlag: props?.isArtist === false ? false : true,
      //   // dance_style: dance_type,
      //   dance_style: danceTypeToEndorse,
      //   endorser_name: user.name,
      // });
      submitEndorsement({
        endorser_id: user._id,
        endorsed_id: artist_id,
        skillsToEndorse: skillsToEndorse,
        isArtistFlag: props?.isArtist === false ? false : true,
        // dance_style: dance_type,
        dance_style: danceTypeToEndorse,

        endorser_name: user.name,
      });
    } else {
      console.log('no user');
    }
  };
  return (
    <div>
      {/* <div
        class="container-fluid  overflow-auto example"
        style={{ padding: '0px' }}
      >
        <div class="d-flex flex-row flex-nowrap"> */}
      {skills_and_endorsements?.dance_styles?.map((style) => (
        <div>
          <br />
          {/* <h5>test</h5> */}
          <h1>
            {style.dance_style_name[0]?.toUpperCase() +
              style.dance_style_name?.slice(1)}{' '}
            skills
          </h1>
          <DanceSkills
            style={style}
            skillsToEndorse={skillsToEndorse}
            setSkillsToEndorse={setSkillsToEndorse}
            loading={loading}
            saveEndorsement={handleEndorse}
            user={user}
            setDanceTypeToEndorse={setDanceTypeToEndorse}
            danceTypeToEndorse={danceTypeToEndorse}
          />
        </div>
      ))}
      {/* </div> */}{' '}
      {/* {user ? (
        <Button
          style={{ marginTop: '12px', marginBottom: '12px' }}
          onClick={() =>
            props?.isCurrentUserProfile === true
              ? console.log('you cannot endorse yourself')
              : handleEndorse()
          }
        >
          {props?.isCurrentUserProfile === true
            ? `Other dancers can now endorse you`
            : `Endorse`}
        </Button>
      ) : (
        <div>
          <Link to={'/login'}>
            <Button style={{ marginTop: '12px', marginBottom: '12px' }}>
              login to endorse user
            </Button>
          </Link>
        </div>
      )} */}
    </div>
  );
};
export default SkillSection;

/*


  graveyard dec. 7 2023
  
{dance_type && (
        <div>
          <h1>{dance_type[0]?.toUpperCase() + dance_type?.slice(1)} skills</h1>
         
          <div
            class="container-fluid  overflow-auto example"
            style={{ padding: '0px' }}
          >
            <div class="d-flex flex-row flex-nowrap">
              {switchOnSkillHelper
                .returnSkillsBasedOnType(dance_type)
                ?.map((skill) => (
                  <Skill
                    skill={skill}
                    skillsToEndorse={skillsToEndorse}
                    setSkillsToEndorse={setSkillsToEndorse}
                    // updateSkillsToEndorse={updateSkillsToEndorse}
                    // clicked={clicked}
                    // setClicked={setClicked}
                  />
                ))}
            </div>
          </div>
          <Button onClick={() => console.log(skillsToEndorse)}>Endorse</Button>
        </div>
      )}
      <hr />
*/
