import React, { Component, useContext, useState } from 'react';
import EventContext from '../../context/event/eventContext';

const AddSafetyOfficerFields = (props) => {
  const eventContext = useContext(EventContext);
  const { addSafetyOfficers } = eventContext;

  const [details, setDetails] = useState({
    image: '',
    safety_officer_name: '',
    safety_officer_phone_number: '',
  });

  console.log('selected event yo');
  console.log(props);

  const { safety_officer_name, safety_officer_phone_number } = details;
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    let safety_officer_obj = {
      name: safety_officer_name,
      phone_number: safety_officer_phone_number,
    };
    let obj = {
      event_id: props.event._id,
      safety_officers: [safety_officer_obj],
    };
    console.log(' safety officer obj');
    console.log(obj);
    // we will need to check if user is logged in, maybe on next screen
    props.setRegisterEventOrganizer(true);
    //set safetyOfficers in parent of parent
    props.setSafetyOfficerAndEventId(safety_officer_obj);
    // addSafetyOfficers(obj);
  };
  return (
    <div>
      <div>
        <form onSubmit={onSubmit}>
          <div className="row">
            <h1
              style={{
                fontSize: '32px',
                // marginTop: '12px',
                // marginBottom: '12px',
                // color: 'blue',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '2px',
                borderRadius: '6px',

                // fontSize: '14px',
                backgroundColor: 'rgb(1, 126, 252)',
                color: 'white',
                textAlign: 'center',
              }}
            >
              Adding officer for : {props.event.name}
            </h1>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="name">Safety Officer Name</label>
                <input
                  id="safety_officer_name"
                  type="text"
                  name="safety_officer_name"
                  value={safety_officer_name}
                  onChange={onChange}
                  required
                  // required
                  // onInvalid="add name"
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label htmlFor="name">Safety Officer Phone Number</label>
                <input
                  id="safety_officer_phone_number"
                  type="text"
                  name="safety_officer_phone_number"
                  value={safety_officer_phone_number}
                  onChange={onChange}
                  required
                  // required
                  // onInvalid="add name"
                />
              </div>
            </div>
          </div>

          <input type="submit" value="Add Officer" class="btn btn-secondary" />
        </form>
      </div>
    </div>
  );
};
export default AddSafetyOfficerFields;
