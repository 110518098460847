import React, { Component, useContext, useEffect, useState } from 'react';
import CurriculumContext from '../../context/curriculum/curriculumContext';
import { Button } from 'react-bootstrap';
import DanceMoves from './DanceMoves';

// this is the lessonplan for private lessons and workshops v01
const LessonPlan = (props) => {
  //go to backend and grab moves by style_name
  let curriculumContext = useContext(CurriculumContext);
  const { getDanceMovesByStyle, danceMovesByStyle } = curriculumContext;
  useEffect(() => {
    console.log('style is....');

    getDanceMovesByStyle('zouk');

    // eslint-disable-next-line
  }, []);

  console.log('danceMovesByStyle');
  console.log(danceMovesByStyle[0]);

  let levels_and_move = danceMovesByStyle[0]?.levelsAndMoves; //arr with diff levels
  let movesForTheLessonPlan = [];
  const [movesForLessonPlan, setMovesForLessonPlan] = useState([]);
  const addMoveToLessonPlan = (move) => {
    console.log('adding move from parent');
    setMovesForLessonPlan([...movesForLessonPlan, move]);
    //add to details from BookPRivatePopup.js

    if (movesForLessonPlan.includes(move)) {
      console.log('removing move');
      // movesForLessonPlan = movesForLessonPlan.filter((item) => item !== move);
      setMovesForLessonPlan(movesForLessonPlan.filter((item) => item !== move));
      props.setDetails({
        ...props.details,
        lessonPlan: movesForLessonPlan.filter((item) => item !== move),
      });
    } else {
      console.log('adding move');
      // movesForLessonPlan.push(move);
      setMovesForLessonPlan([...movesForLessonPlan, move]);
      props.setDetails({
        ...props.details,
        lessonPlan: [...movesForLessonPlan, move],
      });
    }

    // props.setDetails({
    //   ...props.details,
    //   lessonPlan: [...movesForLessonPlan, move],
    // });

    console.log('movesForLessonPlan after adding');
    console.log(movesForLessonPlan);
  };

  //to-do: we need to be able to remove an unwanted move from the list as well.

  return (
    <div>
      <h5 style={{ paddingTop: '14px' }}>
        {props?.addingWorkshop
          ? `What moves would you like to teach?`
          : `What moves would you like to learn?`}
      </h5>
      {/* {danceMovesByStyle[0]?.moves?.map((move) => (
        <div
          className="col grid-container-small-screen grid-container-big-screen"
          style={{ padding: '2px' }}
        >
          <Button
            style={{
              width: '100px',
              background: 'white',
              color: 'rgb(1, 126, 252',
              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252',
            }}
          >
            {move?.move_name}
          </Button>
        </div>
      ))} */}

      {levels_and_move?.map((levels, index) => (
        <div>
          <div className="row">
            <h5>{levels.level}</h5>
          </div>
          <div>
            {levels?.moves?.map((move) => (
              <div
                className="col grid-container-small-screen grid-container-big-screen"
                style={{ padding: '2px' }}
              >
                <DanceMoves
                  move={move}
                  addMoveToLessonPlan={addMoveToLessonPlan}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default LessonPlan;
