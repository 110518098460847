import React, {
  Fragment,
  Component,
  useState,
  useContext,
  useEffect,
} from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import WorkshopDate from './artist-lineup/WorkshopDate';
import Workshop from './artist-lineup/Workshop';
import { useAuth } from '../../context/auth/AuthState';

const moment = require('moment');
moment().format();

const MyItinerary = (props) => {
  const [authState] = useAuth();
  const { user } = authState;
  const { event, isTrip, currentUsersItinerary } = props;

  useEffect(() => {
    // console.log('isTrip---');
    // console.log(isTrip);
    console.log('event is ---');
    console.log(event);
    // eslint-disable-next-line
  }, []);

  const [workshopDate, setWorkshopDate] = useState(
    moment(event.start_date)
      .zone(0)
      .format('MM-DD-YYYY')
  );
  const handleDateChange = (date) => {
    console.log('date from handleDateChange');
    console.log(date);
    setWorkshopDate(moment(date).format('MM-DD-YYYY'));
    // setDetails({ ...details, ['date']: date });
    //refilter rooms
    // let date_time = date + ' ' + workshopTime;
    //? filterRoomsByDateAndTime(date_time);
  };

  let start_date = moment(event?.start_date);
  let end_date = moment(event?.end_date);

  let workshop_date_array = [];
  let d = Math.abs(moment.duration(start_date.diff(end_date)).asDays());

  for (let day = 0; day < d; day++) {
    // start_date.add(day, 'days');
    let s = moment(start_date).add(day, 'days');
    console.log(s);
    workshop_date_array.push(moment(s).format('dddd MM-DD-YYYY'));
  }

  const dateOfWorkshops = (
    <Fragment>
      <div className="row">
        <div className="col">
          <label>Date</label>
        </div>
        <div className="col">
          {[DropdownButton].map((DropdownType, idx) => (
            <DropdownType
              as={ButtonGroup}
              key={idx}
              id={`dropdown-button-drop-${idx}`}
              size="sm"
              variant="secondary"
              title={workshopDate}
              onSelect={handleDateChange}

              // onClick={() => {
              //   setLevelButtonClicked(!levelButtonClicked);
              // }}
              // onSelect={handleSelectLevel}
            >
              {workshop_date_array.map((date) => (
                <Dropdown.Item eventKey={date}>{date}</Dropdown.Item>
              ))}
            </DropdownType>
          ))}
        </div>
      </div>
    </Fragment>
  );

  const dateOfWorkshops2 = <Fragment />;

  const { itinerary, date } = props;
  //   console.log('workshop from MyItinerary ');
  //   console.log(workshop);

  console.log('itinerary --');
  console.log(itinerary);

  let workshop_dict = new Map(Object.entries(itinerary));
  console.log('workshop_dict from MyItinerary ');
  console.log(workshop_dict.get('09-22-2022'));
  let workshopsForDate = workshop_dict.get(workshopDate);
  console.log('workshopsForDate');
  console.log(workshopsForDate);
  const reloadWorkshopsOnJoin = () => {
    console.log('clicked');
  };

  const renderWorkshopsByDate = (date) => {
    let workshop_dict = new Map(Object.entries(itinerary));
    let workshopsForDate = workshop_dict.get(date);
    return workshopsForDate;
  };

  const renderWorkshops = (
    <Fragment>
      {renderWorkshopsByDate(workshopDate)?.map((workshop) => (
        <Workshop
          showAttendees={false}
          workshop={workshop}
          event={event}
          attendee_count={
            workshop?.attendees.length > 0 ? workshop.attendees.length : 0
          }
          onJoin={reloadWorkshopsOnJoin}
          alreadyJoined={true}
          showTime={true}
          showJoinedButton={false}
        />
      ))}
    </Fragment>
  );
  const renderWorkshopsIfExists = () => {
    let workshops = renderWorkshopsByDate(workshopDate);
    if (workshops?.length > 0) {
      return renderWorkshops;
    } else {
      return (
        <Fragment>
          {currentUsersItinerary || user ? (
            <label htmlFor="">
              you have not joined any workshops for this date
            </label>
          ) : (
            <label htmlFor="">
              user has not joined any workshops for this date
            </label>
          )}
        </Fragment>
      );
    }
  };

  return (
    <div>
      <hr />
      {/* <h1>{workshop.title}</h1> */}
      {/* workshop component */}
      <div className="col">
        <WorkshopDate
          onChangeValue={handleDateChange}
          event={event}
          workshopDate={workshopDate}
          onSelect={() => {
            console.log('pushed');
          }}
        />
        <hr />
        {event.name}
      </div>
      <div className="vertical-artist-container">
        <div className="col">
          {/* {workshopsForDate.map((workshop) => ( */}

          {renderWorkshopsIfExists()}

          {/* {renderWorkshopsByDate(workshopDate)?.map((workshop) => (
            <Workshop
              showAttendees={false}
              workshop={workshop}
              event={event}
              attendee_count={
                workshop?.attendees.length > 0 ? workshop.attendees.length : 0
              }
              onJoin={reloadWorkshopsOnJoin}
              alreadyJoined={true}
              showTime={true}
              showJoinedButton={false}
            />
          ))} */}
        </div>
      </div>
    </div>
  );
};

export default MyItinerary;
