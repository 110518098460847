import React, { useContext, useEffect, Fragment, useState } from 'react';
import { useAuth } from '../../../context/auth/AuthState';
import Form from 'react-bootstrap/Form';
import ListOfStates from '../ListOfStates.json';
import ListOfStatesAndCities from '../ListOfStatesAndCities.json';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import EventsThisWeekContext from '../../../context/eventsThisWeek/eventsThisWeekContext';

const AddEventsThisWeekFields = () => {
  const eventsThisWeekContext = useContext(EventsThisWeekContext);
  const { createNewEvent } = eventsThisWeekContext;

  const [authState] = useAuth();
  const { user } = authState;

  const [details, setDetails] = useState({
    descr: '',
    eventName: '',
    event_type: 'social',
    dance_type: [],
    start_date: '',
    end_date: '',
    event_image: '',
    state: '',
    city: '',
    venueName: '',
    company_association: '',
    company_id: user?.companies ? user?.companies[0]?.company_id : '',
    poster_id: user ? user?._id : '',
  });

  const {
    descr,
    eventName,
    event_type,
    dance_type,
    event_image,
    start_date,
    end_date,
    state,
    city,
    venueName,
    company_association,
    company_id,
  } = details;

  const onSubmit2 = (e) => {
    e.preventDefault();
    console.log('dance_type_array');
    console.log(dance_type_array);
    console.log('details');
    console.log(details);
    createNewEvent(details);
  };
  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log('details');
    console.log(details);
  };

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  // let listOfStates = ['NC', 'SC'];
  const [companiesVisible, setCompaniesVisible] = React.useState(false);

  const onAssociationChange = () => {
    setCompaniesVisible(!companiesVisible);
    if (user?.companies) {
      setDetails({ ...details, company_id: user?.companies[0].company_id });
    }
    console.log(details);
  };
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    // console.log('cities');
    // console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  const event_type_array = ['class', 'social', 'party', 'other'];
  const handleEventTypeChange = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    // setArtistDetails({ ...artistDetails, ['artist_type']: type });
    console.log('from handleEventTypeChange ');
    console.log('city');
    console.log(city);
    console.log('state');
    console.log(state);
    console.log('type');
    console.log(type);

    setDetails({ ...details, event_type: type });
    // getTopDancersByLocation(city, state, type);
  };
  const handleDanceTypeChange = (type) => {
    console.log('type');
    console.log(type);

    console.log('type');
    console.log(type);

    setDetails({ ...details, dance_type: type });
    // getTopDancersByLocation(city, state, type);
  };
  let dance_type_array = []; //just [] of ids
  const [danceTypes, setDanceTypes] = useState(['']);

  const onDanceTypeChange = (e) => {
    console.log('changing lineup list -- e is:');
    // console.log(e.target);
    // setMemberArray(...dance_type_array, e.target.name);
    // console.log(dance_type_array);
    console.log('e.target');
    console.log(e.target);

    // if (dance_type_array.includes(e.target.id)) {
    //   console.log('removing user');
    //   dance_type_array = dance_type_array.filter(
    //     (item) => item !== e.target.id
    //   );
    // } else {
    //   console.log('adding user');
    //   dance_type_array.push(e.target.id);
    // }
    if (dance_type.includes(e.target.id)) {
      console.log('removing user');
      // dance_type = dance_type.filter((item) => item !== e.target.id);
      // setDetails
      setDetails({
        ...details,
        dance_type: dance_type.filter((item) => item !== e.target.id),
      });
    } else {
      console.log('adding user');
      dance_type.push(e.target.id);
      // setDetails({ ...details, dance_type: e.target.id });
    }
    // const member_set = new Set(dance_type_array);
    // const uniqueMembers = [...member_set];

    console.log('dance_type_array');
    console.log(dance_type_array);
    // setDanceTypes(dance_type_array);
    console.log('danceTypes');
    console.log(danceTypes);
    // setDetails({ ...details, [dance_type]: dance_type_array });
    console.log('details');
    console.log(details);
  };

  const splitValue1 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[0];
  };
  const splitValue2 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[1];
  };

  let imgg = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  return (
    <div>
      <div className="form-container">
        <form onSubmit={onSubmit2}>
          {/* <div className="form-group"> */}
          <h4>Details</h4>
          {/* <label htmlFor="descr">Details</label> */}
          <input
            id="eventName"
            type="text"
            name="eventName"
            placeholder="Event Name"
            value={eventName}
            onChange={onChange}
            required
          />

          <textarea
            id="descr"
            type="textarea"
            name="descr"
            placeholder="Event Description"
            value={descr}
            onChange={onChange}
            required
            rows="4"
            cols="50"
          />
          <Form.Group controlId="formBasicSelect">
            <Form.Label>State</Form.Label>
            <Form.Control
              id="state"
              name="state"
              as="select"
              value={state}
              onChange={(e) => {
                // setDetails({
                //   ...details,
                //   [e.target.name]: e.target.value,
                // });
                console.log(' e.target.value');
                console.log(e.target.value);
                // setSelectedState(e.target.value);
                let cities = selectedCities(e.target.value);
                // setSelectedCity(cities[0]);
                console.log('details..city filled?');
                console.log(details);
              }}
            >
              {listOfStates.map((state) => (
                <option value={state}>{state}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formBasicSelect">
            <Form.Label>City</Form.Label>
            <Form.Control
              id="city"
              name="city"
              as="select"
              value={city}
              required
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.name]: e.target.value,
                });
                // setSelectedCity();
                console.log(details);
              }}
            >
              {listOfCitiesForSelectedState.map((city) => (
                <option value={city}>{city}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <br />

          <div className="row">
            <h4>Venue</h4>
            {/* <label htmlFor="descr">Details</label> */}
            <input
              id="venueName"
              type="text"
              name="venueName"
              placeholder="Venue"
              value={venueName}
              onChange={onChange}
              required
            />
            <div className="col-2">
              <label htmlFor="">Event Type</label>
            </div>
            <div className="col">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={event_type}
                  onSelect={handleEventTypeChange}
                  // onSelect={() => {
                  //   console.log(idx);
                  //   handleArtistTypeChange();
                  // }}
                  // disabled="true"

                  // onClick={() => {
                  //   setLevelButtonClicked(!levelButtonClicked);
                  // }}
                  //   onSelect={handleSelectLevel}
                >
                  {event_type_array.map((type) => (
                    <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
                  ))}
                </DropdownType>
              ))}
            </div>

            {['salsa', 'bachata', 'kizomba', 'zouk'].map((dance_type) => (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name={dance_type}
                  id={dance_type}
                  value="option1"
                  // onChange={onChange}
                  onChange={onDanceTypeChange}
                />

                <label class="form-check-label" for={dance_type}>
                  {dance_type}
                </label>
              </div>
            ))}
          </div>

          <input
            id="start_date"
            type="date"
            name="start_date"
            placeholder="Start Date"
            value={start_date}
            onChange={onChange}
            required
          />

          <label for="end_date">End Date:</label>

          <input
            id="end_date"
            type="date"
            name="end_date"
            placeholder="End Date"
            value={end_date}
            onChange={onChange}
            required
          />

          <input
            //  id="name"
            //  name="name" // as="text" type="text"
            //  value={name}
            id="associate_with_company"
            type="checkbox"
            name="associate_with_company"
            value={'associate_with_company'}
            onChange={onAssociationChange}
          />
          <label for="associate_with_company">
            {' '}
            Would you like to link this event with an existing company?
          </label>

          <Form.Group controlId="formBasicSelect">
            <Form.Label>Which Company</Form.Label>
            <Form.Control
              id="company_association"
              name="company_association"
              as="select"
              value={company_association}
              onChange={(e) => {
                setDetails({
                  ...details,
                  [e.target.name]: splitValue1(e),
                  company_id: splitValue2(e),
                });

                console.log('details..company filled?');
                console.log(details);
              }}
            >
              {companiesVisible &&
                user.companies.map((company, index) => (
                  <option
                    value={company.company_name + '/' + company.company_id}
                  >
                    {company.company_name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          <input
            type="submit"
            value={user ? 'Submit' : 'Sign in to add new event'}
            // className="btn btn-primary btn-block"
            class="btn btn-secondary"
            disabled={user ? false : true}
            // onClick={onSubmit2}
          />
        </form>
      </div>
    </div>
  );
};

export default AddEventsThisWeekFields;
