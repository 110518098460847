import React, { Component, useState } from 'react';
import { Button } from 'react-bootstrap';

const EndorseSkill = (props) => {
  let {
    skill,
    skillsToEndorse,
    setSkillsToEndorse,
    skillEndorsementsTotal,
    skillArray,
    setSkillArray,
    cancelledEndorsment,
    setCancelledEndorsment,
  } = props;
  const [clicked, setClicked] = useState('false');

  let [
    currentSkillEndorsementsTotal,
    setCurrentSkillEndorsementsTotal,
  ] = useState(skillEndorsementsTotal);

  const updateSkillsToEndorse = (list, skill) => {
    props.setDanceTypeToEndorse(props.style.dance_style_name);

    if (list?.includes(skill)) {
      console.log('boom');
      console.log(list, skill);
      console.log('removing skill ' + skill);
      let newList = list.filter((skillInList) => skillInList !== skill);

      setSkillsToEndorse(newList);
      setSkillArray(newList);
      return list.filter((skillInList) => skillInList !== skill);
    } else {
      console.log(list, skill);
      let newList = [...list, skill];
      console.log('newList');
      console.log(newList);
      setSkillsToEndorse(newList);
      setSkillArray(newList);
      return newList;
    }
  };

  const handleClick = () => {
    // if (!cancelledEndorsment) {
    // }
    // if (cancelledEndorsment) {
    //   setCancelledEndorsment(false);
    //   setCurrentSkillEndorsementsTotal(skillEndorsementsTotal);
    // }
    setCurrentSkillEndorsementsTotal(currentSkillEndorsementsTotal + 1);

    console.log('skillsToEndorse from 29');
    console.log(skillsToEndorse);
    updateSkillsToEndorse(skillsToEndorse, skill);
    updateSkillsToEndorse(skillArray, skill);
    setClicked(true);
    console.log(skill);
    console.log('clicked');
    console.log(clicked);
  };
  // if (cancelledEndorsment) {
  //   setCurrentSkillEndorsementsTotal(currentSkillEndorsementsTotal);
  // }
  return (
    <div style={{ paddingTop: '10px' }}>
      {' '}
      <Button
        onClick={handleClick}
        style={{
          backgroundColor:
            clicked === true && !cancelledEndorsment
              ? 'rgb(1, 126, 252)'
              : 'white',
          color:
            clicked === true && !cancelledEndorsment
              ? 'white'
              : 'rgb(1, 126, 252)',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',
          borderWidth: '1px',
          borderRadius: '10px',
          width: 'max-content',
        }}
        disabled={clicked === true && !cancelledEndorsment ? true : false}
      >
        {/* {skill + ' 99 <3'} */}
        {skill +
          ` | ${
            cancelledEndorsment
              ? skillEndorsementsTotal
              : currentSkillEndorsementsTotal
          } ♡`}
      </Button>
    </div>
  );
};
export default EndorseSkill;
