import React, { Component, useContext, useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import TopListContext from '../../context/topList/topListContext';

import LeftSideShortPanelStyle from './LeftSideShortPanelStyle';
import ListOfStates from '../events/ListOfStates.json';
import ListOfStatesAndCities from '../events/ListOfStatesAndCities.json';
import Form from 'react-bootstrap/Form';
import MenuIcon from '@material-ui/icons/Menu';
import { Button } from 'react-bootstrap';
import { logout, useAuth } from '../../context/auth/AuthState';
import TopStudioLeftSidePanelStyle from './TopStudioLeftSidePanelStyle';

// export class TopStudioLeftSidePanel extends Component {
const TopStudioLeftSidePanel = (props) => {
  const topListContext = useContext(TopListContext);
  const [authState, authDispatch] = useAuth();

  const { isAuthenticated, user } = authState;

  const {
    getTopStudiosByLocation,
    topStudiosInLocation,
    topDancersInLocation,
    allStudios,
    getAllStudios,
  } = topListContext;

  useEffect(() => {
    console.log('ListOfStates');
    console.log(ListOfStates);
    console.log('ListOfStatesAndCities');
    console.log(ListOfStatesAndCities);

    let location = {
      city: 'Charlotte',
      state: 'North Carolina',
      artist_type: 'bachata',
    };
    getTopStudiosByLocation(
      location.city,
      location.state,
      location.artist_type
    );
    // getAllStudios();
    // console.log('topStudiosInLocation');
    // console.log(topStudiosInLocation);

    // eslint-disable-next-line
  }, []);

  const [details, setDetails] = useState({
    first_name: '',
    last_name: '',
    state: 'North Carolina',
    city: 'Charlotte', //default to user.city or first city from list
    artist_type: 'bachata',
    existing_studio_name: '',
    existing_studio_id: '',
    newStudioName: '',
  });
  const {
    first_name,
    last_name,
    state,
    city,
    artist_type,
    existing_studio_name,
    existing_studio_id,
    newStudioName,
  } = details;
  //load studios for default state and city
  let studios = ['a', 'b', 'c'];
  // let studios = topStudiosInLocation;
  let styles = ['bachata', 'zouk', 'kizomba'];
  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  let artist_type_array = ['salsa', 'bachata', 'kizomba', 'zouk'];
  const handleArtistTypeChange = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    // setArtistDetails({ ...artistDetails, ['artist_type']: type });
    console.log('from handleArtistTypeChange ');
    console.log('city');
    console.log(city);
    console.log('state');
    console.log(state);
    console.log('type');
    console.log(type);

    setDetails({ ...details, ['artist_type']: type });
    getTopStudiosByLocation(city, state, type);
  };

  return (
    <TopStudioLeftSidePanelStyle>
      <div class="sidenav">
        {/* <a href="/studio">Top Studios</a> */}

        <Link
          to="/studio"
          // style={{ color: 'white' }}
          state={{ loggedInUser: user }}
        >
          {' '}
          Top Studios
        </Link>

        <div className="col">
          {/* <div className="row">
            <h5>Top Studios</h5>
          </div> */}
          {/* <div className="row">
            <h5>Location</h5>
          </div> */}
          <div>
            <div className="col">
              <Form.Group controlId="formBasicSelect">
                <Form.Label>State</Form.Label>
                <Form.Control
                  id="state"
                  name="state"
                  as="select"
                  value={state}
                  onChange={(e) => {
                    // setDetails({
                    //   ...details,
                    //   [e.target.name]: e.target.value,
                    // });
                    console.log(' e.target.value');
                    console.log(e.target.value);
                    // setSelectedState(e.target.value);
                    let cities = selectedCities(e.target.value);
                    // setSelectedCity(cities[0]);
                    console.log('details..city filled?');
                    console.log(details);

                    //run filter from backend
                    console.log(
                      `city is ${cities[0]} | state is ${
                        e.target.value
                      }  | artist_type isbachata`
                    );
                    getTopStudiosByLocation(
                      cities[0],
                      e.target.value,
                      'bachata'
                    );
                  }}
                >
                  {listOfStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group controlId="formBasicSelect">
                <Form.Label>City</Form.Label>
                <Form.Control
                  id="city"
                  name="city"
                  as="select"
                  value={city}
                  required
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      [e.target.name]: e.target.value,
                    });
                    // setSelectedCity();
                    console.log(details);

                    //run filter from backend
                    //getTopDancers
                    getTopStudiosByLocation(e.target.value, state, 'bachata');
                  }}
                >
                  {listOfCitiesForSelectedState.map((city) => (
                    <option value={city}>{city}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h5 style={{ fontSize: '16px' }}>Style</h5>
            </div>
            <div className="col">
              {[DropdownButton].map((DropdownType, idx) => (
                <DropdownType
                  as={ButtonGroup}
                  key={idx}
                  id={`dropdown-button-drop-${idx}`}
                  size="sm"
                  variant="secondary"
                  title={artist_type}
                  onSelect={handleArtistTypeChange}

                  // onSelect={handleNumberOfTraingRoomsChange}
                >
                  {artist_type_array.map((style) => (
                    // <Dropdown.Item eventKey={style}>
                    <div>
                      <Dropdown.Item
                        eventKey={style}
                        style={{ fontSize: '12px' }}
                      >
                        {style}
                      </Dropdown.Item>
                    </div>
                  ))}
                </DropdownType>
              ))}
            </div>
          </div>
        </div>
        <br />
        {topStudiosInLocation.map((studio, index) => (
          // {studios.map((studio) => (

          <Link to={`/studio/${studio?._id}`} style={{ color: 'black' }}>
            {/* <h3>{studio?.name}</h3> */}
            <h5 value={studio.name}>{index + 1 + ' ' + studio.name}</h5>
          </Link>
        ))}
        {/* <br />
          <a href="/vanguard">Volunteer</a>
          <br />
          <h2>tribes</h2>
          <a href="/g1">rumbao village</a>
          <br /> */}
      </div>
    </TopStudioLeftSidePanelStyle>
  );
};

export default TopStudioLeftSidePanel;
