import React, {
  Component,
  useState,
  useContext,
  useEffect,
  Fragment,
} from 'react';
import Button from 'react-bootstrap/Button';
import ArtistContext from '../../context/artist/artistContext';
import UserNotOwnerPopup from '../user/Artist/UserNotOwnerPopup';
import InstructorContext from '../../context/instructor/instructorContext';

const PrivateLessonRequestNoEvent = (props) => {
  const privatelessonRequest = props.lesson;

  const artistContext = useContext(ArtistContext);
  const { decisionPrivateLessonRequest } = artistContext;

  const instructorContext = useContext(InstructorContext);
  const { decisionPrivateLessonRequestForInstructor } = instructorContext;

  //form inputs
  const [details, setDetails] = useState({
    lessonDecision: 'TBD',
    privateLessonRequestId: privatelessonRequest._id,
    artist_id: privatelessonRequest.requested_artist_id,
  });

  // may 29, 2024 work around because we had a recurring issue accepting private lessons
  //also i dont think we have implemented decline yet.. we can decline and send email.

  const isArtist = () => {
    console.log('props?.user');
    console.log(props?.user);
    // if (dancer?.artist_id && dancer?.artist_id !== '') {
    if (
      (props?.user?.artist_type && props?.user?.artist_type !== '') ||
      props?.user?.artist_id !== null
    ) {
      return true;
    }
    return false;
    // return dancer?.artist_id && dancer?.artist_id !== '';
    // return true
  };
  console.log('privatelessonRequest -- x');
  console.log(privatelessonRequest);

  const onAccept = () => {
    if (isArtist()) {
      console.log('user is artist');
      console.log('props?.user');
      console.log(props?.user);
      console.log('decision details-accepted');

      let obj = {
        lessonDecision: 'Accepted',
        privateLessonRequestId: privatelessonRequest._id,
        artist_id: privatelessonRequest.requested_artist_id,
      };
      console.log(obj);
      decisionPrivateLessonRequest(obj);
    } else {
      let obj = {
        lessonDecision: 'Accepted',
        privateLessonRequestId: privatelessonRequest._id,
        instructor_id: privatelessonRequest.requested_instructor_id,
      };
      console.log('obj is: ');
      console.log(obj);

      console.log('user is not artist');
      decisionPrivateLessonRequestForInstructor(obj);
      // decisionPrivateLessonRequest(obj);
    }
  };
  const onDecline = () => {
    console.log('decision details - declined');
    if (isArtist()) {
      let obj = {
        lessonDecision: 'Decline',
        privateLessonRequestId: privatelessonRequest._id,
        artist_id: privatelessonRequest.requested_artist_id,
      };
      // console.log(obj);
      decisionPrivateLessonRequest(obj);
    } else {
      let obj = {
        lessonDecision: 'Decline',
        privateLessonRequestId: privatelessonRequest._id,
        instructor_id: privatelessonRequest.requested_instructor_id,
      };
      // console.log(obj);
      decisionPrivateLessonRequest(obj);
    }
  };

  // const renderBasedOnOwnership =()=>{
  //   if()
  // }
  let [optionSelected, setOptionSelected] = useState(false);
  const renderUserNotAble = (
    // console.log('render hit');

    <Fragment>
      <h5>who</h5>
    </Fragment>
    // <UserNotOwnerPopup
    //   artist_id={privatelessonRequest.requested_artist_id}
    //   optionSelected={optionSelected}
    // />
  );

  const renderButtonsForElevatedUser = () => {
    return (
      <div className="row">
        <div className="col">
          {/* <label htmlFor="">approve</label> */}
          {/* <Button onClick={() => approveETWAdRequest(request._id)}> */}
          <Button
            onClick={() => {
              console.log('accepted');
              onAccept();
            }}
            style={{
              width: '100px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '2px',
              borderRadius: '6px',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
            }}
          >
            Accept
          </Button>
        </div>
        <div className="col">
          {/* <label htmlFor="">deny</label> */}

          {/* onClick={() => denyRequest(request._id, 'etw')} */}
          <Button
            onClick={() => {
              onDecline();
            }}
            // onClick={() => {
            //   console.log('declined for privileged user');
            // }}
            // onClick={() => {
            //   props.user._id === props.artist?.claimed_by_user_id
            //     ? console.log('user permitted')
            //     : console.log('user not permitted');
            // }}
            style={{ backgroundColor: 'red' }}
          >
            {' '}
            Decline
          </Button>
        </div>{' '}
      </div>
    );
  };

  const renderButtonsForElevatedInstructor = () => {
    return (
      <div className="row">
        <div className="col">
          {/* <label htmlFor="">approve</label> */}
          {/* <Button onClick={() => approveETWAdRequest(request._id)}> */}
          <Button
            onClick={() => {
              console.log('accepted');
              onAccept();
            }}
            style={{
              width: '100px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '2px',
              borderRadius: '6px',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
            }}
          >
            Accept
          </Button>
        </div>
        <div className="col">
          {/* <label htmlFor="">deny</label> */}

          {/* onClick={() => denyRequest(request._id, 'etw')} */}
          <Button
            onClick={() => {
              onDecline();
            }}
            // onClick={() => {
            //   console.log('declined for privileged user');
            // }}
            // onClick={() => {
            //   props.user._id === props.artist?.claimed_by_user_id
            //     ? console.log('user permitted')
            //     : console.log('user not permitted');
            // }}
            style={{ backgroundColor: 'red' }}
          >
            {' '}
            Decline
          </Button>
        </div>{' '}
      </div>
    );
  };

  let [showPopup, setShowPopup] = useState(false);
  const renderButtonsForNonPrivilegedUser = () => {
    return (
      <div className="row">
        <div className="col">
          {/* <label htmlFor="">approve</label> */}
          {/* <Button onClick={() => approveETWAdRequest(request._id)}> */}
          <Button
            onClick={() => {
              console.log('pop up for non privileged user');
              setShowPopup(!showPopup);

              // onAccept();
            }}
            style={{
              width: '100px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '2px',
              borderRadius: '6px',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
            }}
          >
            Accept
          </Button>
        </div>
        <div className="col">
          {/* <label htmlFor="">deny</label> */}

          {/* onClick={() => denyRequest(request._id, 'etw')} */}
          <Button
            // onClick={() => {props.user._id===props.artist?.claimed_by_user_id ? : onDecline()}}
            onClick={() => {
              setShowPopup(!showPopup);
            }}
            style={{
              width: '100px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'red',
              borderWidth: '2px',
              borderRadius: '6px',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'red',
              textAlign: 'center',
            }}
          >
            {' '}
            Decline pop
          </Button>
        </div>{' '}
      </div>
    );
  };

  const renderButtonsForNonPrivilegedInstructor = () => {
    return (
      <div className="row">
        <div className="col">
          {/* <label htmlFor="">approve</label> */}
          {/* <Button onClick={() => approveETWAdRequest(request._id)}> */}
          <Button
            onClick={() => {
              console.log('pop up for non privileged instrtuctor');
              setShowPopup(!showPopup);

              // onAccept();
            }}
            style={{
              width: '100px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'rgb(1, 126, 252)',
              borderWidth: '2px',
              borderRadius: '6px',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
            }}
          >
            Accept
          </Button>
        </div>
        <div className="col">
          {/* <label htmlFor="">deny</label> */}

          {/* onClick={() => denyRequest(request._id, 'etw')} */}
          <Button
            // onClick={() => {props.user._id===props.artist?.claimed_by_user_id ? : onDecline()}}
            onClick={() => {
              setShowPopup(!showPopup);
            }}
            style={{
              width: '100px',
              background: 'white',

              borderStyle: 'solid',
              borderColor: 'red',
              borderWidth: '2px',
              borderRadius: '6px',

              fontSize: '14px',
              backgroundColor: 'white',
              color: 'red',
              textAlign: 'center',
            }}
          >
            {' '}
            Decline
          </Button>
        </div>{' '}
      </div>
    );
  };
  console.log('props?.user?._id from privatelesson');
  console.log(props?.user?._id);
  console.log('props.artist?.claimed_by_user_id');
  console.log(props?.artist?.claimed_by_user_id);
  const moment = require('moment');
  moment().format();
  return (
    <div>
      {/* <h5>requests</h5> */}

      <div className="row">
        <div className="col-md-12">
          <div className="card card-body bg3-primary text-white mb-3">
            <div className="row">
              {/* <div className="col col-md-3 m-auto"> */}
              <div className="row">
                <div className="row">
                  <div className="col">
                    <h5
                      style={{
                        fontSize: '14px',
                        color: 'black',
                        maxWidth: '150px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {privatelessonRequest.requestor_name}
                    </h5>
                  </div>
                  <div className="col">
                    <h5 style={{ fontSize: '12px', color: 'black' }}>
                      {moment(privatelessonRequest.lessonDate)
                        .zone(0)
                        .format('ddd MM.DD.YY')}
                    </h5>
                  </div>
                  <div className="col">
                    <h5 style={{ fontSize: '14px', color: 'black' }}>
                      {privatelessonRequest.lessonTime}
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <h5 style={{ fontSize: '14px', color: 'black' }}>
                      Student Contact{' '}
                      {privatelessonRequest?.requestor_phone_number}
                    </h5>
                  </div>
                </div>

                {privatelessonRequest?.lessonPlan &&
                  privatelessonRequest?.lessonPlan.length > 0 && (
                    <div style={{ paddingTop: '10px' }}>
                      <hr style={{ color: 'black' }} />

                      <h5 style={{ color: 'black' }}>Lesson Plan</h5>
                    </div>
                  )}

                {privatelessonRequest?.lessonPlan &&
                  privatelessonRequest?.lessonPlan.length > 0 &&
                  privatelessonRequest?.lessonPlan?.map((move) => (
                    <div>
                      <h5 style={{ color: 'black' }}>{move}</h5>
                    </div>
                  ))}

                {privatelessonRequest?.comments_or_special_requests && (
                  <div>
                    <hr style={{ color: 'black' }} />
                    <h4 style={{ color: 'black' }}>Requests or Comments</h4>
                    <h5 style={{ color: 'black' }}>
                      {privatelessonRequest?.comments_or_special_requests}
                    </h5>
                  </div>
                )}
                <div className="row">
                  {/* props? */}
                  <hr style={{ color: 'black' }} />
                  {props.isNotArtistButInstructorOnlyAccount ? (
                    <div className="col">
                      {props.isNotArtistButInstructorOnlyAccount
                        ? renderButtonsForElevatedInstructor()
                        : renderButtonsForNonPrivilegedInstructor()}
                    </div>
                  ) : (
                    <div className="col">
                      {props?.user?._id === props?.artist?.claimed_by_user_id ||
                      props?.artist?.premium_subscription === true
                        ? renderButtonsForElevatedUser()
                        : renderButtonsForNonPrivilegedUser()}
                    </div>
                  )}
                </div>

                {showPopup && (
                  <UserNotOwnerPopup
                    artist_id={privatelessonRequest.requested_artist_id}
                    optionSelected={optionSelected}
                    setShowPopup={setShowPopup}
                    showPopup={showPopup}
                  />
                )}

                {/* <div className="row">
                  <div className="col-2">
           
                    <Button
                      onClick={() => {
                        console.log('accepted');
                        onAccept();
                      }}
                    >
                      Accept
                    </Button>
                  </div>
                  <div className="col">
                    <Button
                      onClick={() => {
                        setOptionSelected(!optionSelected);
                        props.user._id === props.artist?.claimed_by_user_id
                          ? console.log('user permitted')
                          : renderUserNotAble();
                      }}
                      style={{ backgroundColor: 'red' }}
                    >
                      {' '}
                      Decline
                    </Button>
                  </div>{' '}
                </div> */}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivateLessonRequestNoEvent;
