import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const UserEvents = (props) => {
  const { event } = props;
  return (
    <div>
      <Link to={`/event/${event?._id}`}>
        {/* {props?.user_events?.map((event) => ( */}
        <div className="card-horizontal-menu card-block mx-2 card card-body">
          <img
            // className="rounded-circle"
            //   src={profile.user.avatar}
            src={event?.image?.url}
            // src={image}
            alt=""
            height="150"
            width="150"
          />
          <h5 style={{ color: 'black' }}>{event?.name}</h5>
        </div>
        {/* ))} */}
      </Link>
    </div>
  );
};
export default UserEvents;
