import React, { Component, useContext, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Form,
} from 'react-bootstrap';
import ArtistContext from '../../context/artist/artistContext';
import AuthContext from '../../context/auth/authContext';
import { useAuth } from '../../context/auth/AuthState';
import { useNavigate } from 'react-router-dom';
import ListOfStates from '../../components/events/ListOfStates.json';
import ListOfStatesAndCities from '../../components/events/ListOfStatesAndCities.json';
import AddLocation from '../location/AddLocation';
const AddNewArtist = (props) => {
  let navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const [authState, authDispatch] = useAuth();
  // const { error, isAuthenticated } = authState;
  const { user } = authState;
  let artistContext = useContext(ArtistContext);
  let { addNewArtist } = artistContext;

  const [artistDetails, setArtistDetails] = useState({
    name: '',
    image_url: '',
    artist_type: 'salsa',
    artist_is_also_a_dj: false,
    // artist_belongs_to_user: false,
    artist_belongs_to_user: true,
    claiming_user_id: props?.userIsOnboarding === true ? user._id : '',
    // claimed_by_user_id
    city: 'Charlotte',
    state: 'North Carolina',
    dj_name: '',
  });
  const {
    name,
    image_url,
    artist_type,
    artist_is_also_a_dj,
    artist_belongs_to_user,
    claiming_user_id,
    dj_name,
    // state,
    // city,
  } = artistDetails;
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [location, setLocation] = useState('Raleigh, North Carolina');

  const [file, setFile] = useState();
  const onSubmitNewArtist = (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('file', file); //image
    data.append('name', name);
    data.append('artist_type', artist_type);
    data.append('artist_is_also_a_dj', artist_is_also_a_dj);
    data.append('artist_belongs_to_user', artist_belongs_to_user);
    data.append('claiming_user_id', claiming_user_id);
    data.append('city', city);
    data.append('state', state);
    data.append('country', country);
    if (dj_name !== '') {
      data.append('dj_name', dj_name);
    }
    console.log('data--');
    console.log(artistDetails);

    if (props?.setShow && props.setShowNewArtistCreatedMsg) {
      props?.setShowNewArtistCreatedMsg(true);
      props?.setShow(false);
    }
    console.log('artistDetails are:');
    console.log(artistDetails);
    addNewArtist(data);

    if (props?.userIsOnboarding) {
      console.log('user is  onboarding, do not reroute');
    } else {
      if (artist_belongs_to_user) {
        navigate('/user/profile', {
          state: {
            messageText:
              'Your Artist Profile Was Created. Refresh this page and Check The Artist Tools page',
          },
        });
      }
    }
  };

  const listOfStates = ListOfStates;
  const listOfStatesAndCities = ListOfStatesAndCities;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);
  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    console.log('cities');
    console.log(cities);
    // setSelectedCity(cities[0]);
    setArtistDetails({ ...artistDetails, city: cities[0], state: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };

  const onChange = (e) =>
    setArtistDetails({ ...artistDetails, [e.target.name]: e.target.value });
  const handleArtistTypeChange = (type) => {
    console.log('type');
    console.log(type);
    // setWorkshopTime(time);
    setArtistDetails({ ...artistDetails, artist_type: type });
  };
  let artist_type_array = ['salsa', 'bachata', 'kizomba', 'zouk'];
  const [artistIsAlsoDJ, setArtistIsAlsoDJ] = useState(false);
  const dj_clicked = (c) => {
    setArtistDetails({
      ...artistDetails,
      artist_is_also_a_dj: c.target.checked,
    });
    setArtistIsAlsoDJ(!artistIsAlsoDJ);
  };
  const artist_owner_clicked = (c) => {
    setArtistDetails({
      ...artistDetails,
      claiming_user_id: user?._id,
      artist_belongs_to_user: c.target.checked,
    });
  };

  return (
    <div>
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Dancer Name"
        value={name}
        onChange={onChange}
        required
      />
      {/* <input
          id="descr"
          type="text"
          name="descr"
          placeholder="Event Description"
          value={descr}
          onChange={onChange}
          required
        /> */}

      <div className="col">
        {[DropdownButton].map((DropdownType, idx) => (
          <DropdownType
            as={ButtonGroup}
            key={idx}
            id={`dropdown-button-drop-${idx}`}
            size="sm"
            variant="secondary"
            title={artist_type}
            onSelect={handleArtistTypeChange}
            // onSelect={() => {
            //   console.log(idx);
            //   handleArtistTypeChange();
            // }}
            // disabled="true"

            // onClick={() => {
            //   setLevelButtonClicked(!levelButtonClicked);
            // }}
            //   onSelect={handleSelectLevel}
          >
            {artist_type_array.map((type) => (
              <Dropdown.Item eventKey={type}>{type}</Dropdown.Item>
            ))}
          </DropdownType>
        ))}
      </div>
      {/* 
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          name={'user is also a DJ'}
          // id={user._id}
          id={1}
          value="option1"
          onChange={console.log('changed')}
        />
      </div> */}

      {['user is also a dj'].map((dance_type) => (
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            name={dance_type}
            id={dance_type}
            value="option1"
            // onChange={onChange}
            onChange={dj_clicked}
          />

          <label class="form-check-label" for={dance_type}>
            {dance_type}
          </label>
        </div>
      ))}

      {artistIsAlsoDJ && (
        <div style={{ marginTop: '12px' }}>
          <input
            id="dj_name"
            type="text"
            name="dj_name"
            placeholder="DJ Name"
            value={dj_name}
            onChange={onChange}
            required
          />
        </div>
      )}
      <div style={{ marginTop: '12px' }}>
        {['Does this page belong to you?'].map((userOwnsArtist) => (
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              name={userOwnsArtist}
              id={userOwnsArtist}
              value="option2"
              // onChange={onChange}
              onChange={artist_owner_clicked}
              checked={artist_belongs_to_user}
            />

            <label class="form-check-label" for={userOwnsArtist}>
              {userOwnsArtist}
            </label>
          </div>
        ))}
      </div>
      <br />
      {/* <div>
        <h5>Artist Location</h5>
        <Form.Group controlId="formBasicSelect">
          <Form.Label>State</Form.Label>
          <Form.Control
            id="state"
            name="state"
            as="select"
            value={state}
            onChange={(e) => {
              
              let cities = selectedCities(e.target.value);
            }}
          >
            {listOfStates.map((state) => (
              <option value={state}>{state}</option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formBasicSelect">
          <Form.Label>City</Form.Label>
          <Form.Control
            id="city"
            name="city"
            as="select"
            value={city}
            required
            onChange={(e) => {
              setArtistDetails({
                ...artistDetails,
                [e.target.name]: e.target.value,
              });
            }}
          >
            {listOfCitiesForSelectedState.map((city) => (
              <option value={city}>{city}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </div> */}
      <hr />
      <AddLocation
        setCountry={setCountry}
        setState={setState}
        setCity={setCity}
        // setLocation={setLocation}
      />
      <hr />
      <div>
        <label for="image_url">Dancer Image:</label>
      </div>

      <input
        id="image_url"
        name="image_url"
        type="file"
        value={image_url}
        required
        onChange={(event) => {
          const file = event.target.files[0];
          setFile(file);
          setArtistDetails({
            ...artistDetails,
            [event.target.name]: event.target.value,
          });
        }}
      />
      <div>
        {/* <h5>
          trigger exit and show user that an artist was created (verified)
        </h5> */}
        <Button onClick={onSubmitNewArtist}>Add</Button>
      </div>
    </div>
  );
};

export default AddNewArtist;
