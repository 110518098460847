import React, { useContext, useEffect, useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import { useAuth } from '../../../context/auth/AuthState';
// import userContext from '../../../context/user/userContext'; // might have to capitalize
import eventContext from '../../context/event/eventContext'; // might have to capitalize
import MyItinerary from './MyItinerary';
const UserAgenda = (props) => {
  console.log('from UserAgenda within JoinedEvent.js');
  const { joined_event, id, user, isTrip, trip } = props;

  const eContext = useContext(eventContext);
  const { getMyItinerary, myItinerary, getOneEvent, event } = eContext;
  useEffect(() => {
    // if (isTrip) {
    //   console.log('getting event for trip');
    //   getOneEvent(trip.event_id);
    // }
    // getMyItinerary(joined_event?._id, id);
    // console.log('myItinerary from useEff');
    // console.log(myItinerary);

    console.log('user from ue');
    console.log(user);
    console.log('id from ue');
    console.log(id);
    // eslint-disable-next-line
  }, []);

  const [showLineup, setShowLineup] = useState(false);

  const handleCloseLineup = () => {
    console.log('closing ');
    setShowLineup(false);
  };
  const handleShowLineup = () => {
    if (isTrip) {
      console.log('getting event for trip');
      getOneEvent(trip.event_id);
    }
    if (id) {
      getMyItinerary(joined_event?._id, id);
    }
    console.log('myItinerary from useEff');
    console.log(myItinerary);
    setShowLineup(true);
    console.log(`showLineup is: ${showLineup}`);
  };
  console.log('myItinerary class');
  console.log(myItinerary);
  return (
    <div>
      {/* <div style={{ width: '800px' }}> */}
      <Button onClick={() => handleShowLineup()}>User Agenda</Button>
      <Modal
        show={showLineup}
        onHide={handleCloseLineup}
        className="edit-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {user?._id === id || user ? `Your Agenda` : `User's Agenda`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* will id always be available? if not how to handle?? */}
          <div className="col">
            {isTrip ? (
              <MyItinerary
                itinerary={myItinerary}
                date={'10-30-2022'}
                event={event}
                isTrip={true}
                currentUsersItinerary={user?._id === id}
              />
            ) : (
              <MyItinerary
                itinerary={myItinerary}
                date={'10-30-2022'}
                event={joined_event}
                isTrip={false}
                currentUsersItinerary={user?._id === id}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLineup}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleAddToEventLineup}>
            Save Lineup
          </Button> */}
        </Modal.Footer>
      </Modal>
      {/* </div> */}
      {/* render attendees under attendees h1 or bring the attendees to a child component */}
    </div>
  );
};
export default UserAgenda;
