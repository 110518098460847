import React from 'react';
import { Link } from 'react-router-dom';
// import { Document } from 'react-pdf';
// import { pdfjs } from 'react-pdf';

// import privacy-policy from
const Terms = () => {
  //   const gotText = privacyPolicy;
  //   pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  //     'pdfjs-dist/build/pdf.worker.min.js',
  //     import.meta.url
  //   ).toString();

  return (
    <div>
      <h1>Terms and Conditions</h1>
      <p>
        Please read these terms and conditions ("terms and conditions", "terms")
        carefully before using www.airmeus.com application ("app", "service")
        operated by Gilbert Brooks ("us", 'we", "our").
      </p>
      <h2>Conditions of use</h2>
      <p>
        By using this app, you certify that you have read and reviewed this
        Agreement and that you agree to comply with its terms. If you do not
        want to be bound by the terms of this Agreement, you are advised to stop
        using the app accordingly. Gilbert Brooks only grants use and access of
        this app, its products, and its services to those who have accepted its
        terms.
      </p>
      <h2>Consent To Messaging</h2>
      <p>
        By signing up you consent to receive marketing text messages (e.g.
        promos, cart reminders) from Twilio autodialer. Consent is not a
        condition of purchase. Msg & data rates may apply. Msg frequency varies.
        Unsubscribe at any time by replying STOP or clicking the unsubscribe
        link (where available). Privacy Policy & Terms
      </p>
      <p>
        1. This campaign is designed to keep users informed of time sensitive
        actions within the application. Some of the messages users can expect to
        recieve are:
      </p>
      <ul>
        <li> 1. User X would like to book a private lesson with you.</li>
        <li> 2. User Y has confirmed your private lesson request.</li>
      </ul>
      <p>
        2. You can cancel the SMS service at any time. Just text "STOP" to the
        short code. After you send the SMS message "STOP" to us, we will send
        you an SMS message to confirm that you have been unsubscribed. After
        this, you will no longer receive SMS messages from us. If you want to
        join again, just sign up as you did the first time and we will start
        sending SMS messages to you again.
      </p>
      <p>
        3. If you are experiencing issues with the messaging program you can
        reply with the keyword HELP for more assistance, or you can get help
        directly at airmeus.team@gmail.com.
      </p>
      <p>4. Carriers are not liable for delayed or undelivered messages</p>
      <p>
        5. As always, message and data rates may apply for any messages sent to
        you from us and to us from you. You will receive messages depending on
        the frequecy of user interactions with your account. If you have any
        questions about your text plan or data plan, it is best to contact your
        wireless provider.
      </p>
      <p>
        6. If you have any questions regarding privacy, please read our privacy
        policy:{' '}
        <Link to="/privacy-policy" style={{ color: 'blue' }}>
          {' '}
          privacy policy{' '}
        </Link>
      </p>
      <h2>Privacy policy</h2>
      <p>
        Before you continue using our app, we advise you to read our
        <Link to="/privacy-policy" style={{ color: 'blue' }}>
          {' '}
          privacy policy{' '}
        </Link>
        regarding our user data collection. It will help you better understand
        our practices
      </p>
      <h2>Age restriction</h2>
      <p>
        You must be at least 18 (eighteen) years of age before you can use this
        app. By using this app, you warrant that you are at least 18 years of
        age and you may legally adhere to this Agreement. Gilbert Brooks assumes
        no responsibility for liabilities related to age misrepresentation.
      </p>
      <h2>Intellectual property</h2>
      <p>
        You agree that all materials, products, and services provided on this
        app are the property of Gilbert Brooks, its affiliates, directors,
        officers, employees, agents, suppliers, or licensors including all
        copyrights, trade secrets, trademarks, patents, and other intellectual
        property. You also agree that you will not reproduce or redistribute the
        Gilbert Brooks' intellectual property in any way, including electronic,
        digital, or new trademark registrations. You grant Gilbert Brooks a
        royalty-free and non-exclusive license to display, use, copy, transmit,
        and broadcast the content you upload and publish. For issues regarding
        intellectual property claims, you should contact the company in order to
        come to an agreement.
      </p>
      <h2>User accounts</h2>
      <p>
        As a user of this app, you may be asked to register with us and provide
        private information. You are responsible for ensuring the accuracy of
        this information, and you are responsible for maintaining the safety and
        security of your identifying information. You are also responsible for
        all activities that occur under your account or password. If you think
        there are any possible issues regarding the security of your account on
        the app, inform us immediately so we may address them accordingly. We
        reserve all rights to terminate accounts, edit or remove content and
        cancel orders at our sole discretion.
      </p>
      <h2>Applicable law</h2>
      <p>
        By using this app, you agree that the laws of the United States of
        America, without regard to principles of conflict laws, will govern
        these terms and conditions, or any dispute of any sort that might come
        between Gilbert Brooks and you, or its business partners and associates.
      </p>{' '}
      <h2>Disputes</h2>
      <p>
        Any dispute related in any way to your use of this app or to products
        you purchase from us shall be arbitrated by state or federal courts
        within the United States of America and you consent to exclusive
        jurisdiction and venue of such courts.
      </p>
      <h2>Indemnification</h2>
      <p>
        You agree to indemnify Gilbert Brooks and its affiliates and hold
        Gilbert Brooks harmless against legal claims and demands that may arise
        from your use or misuse of our services. We reserve the right to select
        our own legal counsel.
      </p>{' '}
      <h2>Limitation on liability</h2>
      <p>
        Gilbert Brooks is not liable for any damages that may occur to you as a
        result of your misuse of our app. Gilbert Brooks reserves the right to
        edit, modify, and change this Agreement at any time. We shall let our
        users know of these changes through electronic mail. This Agreement is
        an understanding between Gilbert Brooks and the user, and this
        supersedes and replaces all prior agreements regarding the use of this
        app.
      </p>
      <p>Effective as of August 16, 2023</p>
    </div>
  );
};

export default Terms;
