import React, { Component, useState } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import PerformanceReviewContext from '../../context/PerformanceReview/performanceReviewContext'; // might have to capitalize
import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';
// import { useAuth } from '../../context/auth/AuthState';
import Button from 'react-bootstrap/Button';
import LeaveReviewFields from './LeaveReviewFields';
import ReviewPerformer from './ReviewPerformer';
import LoadCompaniesToAdd from './LoadCompaniesToAdd';
import ReviewCompanyPerformer from './ReviewCompanyPerformer';

const Performer = (props) => {
  const performanceReviewContext = useContext(PerformanceReviewContext);

  const {
    getPerformersForEvent,
    getReviewsForGroupForEvent,
    performersForEvent,
    addNewPerformersToEvent,
    getAllReviewsForEvent,
    all_performance_reviews_for_event,
    addNewCompanyPerformersToEvent,
    getAllCompanyReviewsForEvent,
    all_company_performance_reviews_for_event,
  } = performanceReviewContext;

  useEffect(() => {
    console.log(
      'props from performer.js -- should not be emptyy.. shouild be event'
    );
    console.log(props);
    console.log('props.event_id from performer.js');
    console.log(props.event._id);
    getPerformersForEvent(props.event._id);

    getAllReviewsForEvent(props.event._id);
    getAllCompanyReviewsForEvent(props.event._id);
    // getConnections(user?._id);
    // getUsersForSearchCriteria(searchText);
    // console.log('connections');
    // console.log(connections);
    // eslint-disable-next-line
  }, []);

  const [reviewClicked, setReviewClicked] = useState(false);
  const [showPerformerTextArea, setShowPerformerTextArea] = useState(false);
  const [artists, setArtists] = useState({ listOfPerformers: '' });

  const onChange = (e) => {
    console.log(e.target.value);
    console.log(e.target);
    setArtists({ ...artists, [e.target.name]: [e.target.value] });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('artists');
    console.log(artists);
    let artist_array = artists.listOfPerformers;
    let artist_csv = artist_array[0].split(',');

    console.log('artist_array');
    console.log(artist_array);
    let obj = {
      performers_to_create: artist_csv,
      event_id: props.event._id,
    };

    console.log('obj from performer');
    console.log(obj);
    addNewPerformersToEvent(obj);
  };
  return (
    <div>
      {props?.back_button_active && (
        <Button
          onClick={() => {
            // selectedChat = '';
            console.log('selectedChat is:');
            // console.log(selectedChat);
            props.setShowChatsFromChild(true);
          }}
        >
          Back
        </Button>
      )}
      {/* <div className="row">
        <div className="col-2">
          <h5>Aatma</h5>
        </div>
        <div className="col">
          <Button onClick={() => setReviewClicked(!reviewClicked)}>
            leave review
          </Button>
        </div>
      </div> */}
      <div>
        {reviewClicked && (
          <div>
            {/* <LeaveReviewFields /> */}
            {/* <LeaveReviewFields performer={performer} /> */}
          </div>
        )}
      </div>
      <hr />
      {/* <div className="row">
        <div className="col-2">
          <h5>Afincao</h5>
        </div>
        <div className="col">
          <Button>leave review</Button>
        </div>
      </div> */}
      <div>
        <Button
          onClick={() => setShowPerformerTextArea(!showPerformerTextArea)}
        >
          {' '}
          {!showPerformerTextArea ? `Add Performers` : `Close`}
        </Button>
      </div>
      {showPerformerTextArea && (
        <div>
          <LoadCompaniesToAdd event_id={props.event._id} />
          <form onSubmit={handleSubmit}>
            {/* <form> */}
            <label style={{ fontSize: '12px' }}>
              Note: input performers so each artist is separated by a comma
              <textarea
                placeholder="Daniel y Desirée , Que Candela, Etc"
                cols="200"
                id="listOfPerformers"
                name="listOfPerformers"
                // value={listOfPerformers}
                onChange={onChange}
              />
            </label>

            <input type="submit" value="Submit" />
          </form>
          {/* <Button>Add</Button> */}
        </div>
      )}

      {/* WIP 6.9.23 - uncomment */}
      {/* {performersForEvent.map((performer) => ( */}
      {!showPerformerTextArea &&
        all_performance_reviews_for_event.map((performer) => (
          // <Trip key={trip._id} trip={trip} user={user} />
          <div>
            <ReviewPerformer performer={performer} event={props.event} />
            <hr />
            {/* <div className="row">
            <div className="col-2">
              <h5>{performer.name}</h5>
            </div>
            <div className="col">
              <Button onClick={() => setReviewClicked(!reviewClicked)}>
                leave review
              </Button>
            </div>
          </div>
          <div>
            {reviewClicked && (
              <div>
                <LeaveReviewFields />
              //  <LeaveReviewFields performer={performer} />
              </div>
            )}
          </div> */}
          </div>
        ))}
      <hr />

      {!showPerformerTextArea &&
        all_company_performance_reviews_for_event.map((performer) => (
          // <Trip key={trip._id} trip={trip} user={user} />
          <div>
            <ReviewCompanyPerformer performer={performer} event={props.event} />
            <hr />
            {/* <div className="row">
            <div className="col-2">
              <h5>{performer.name}</h5>
            </div>
            <div className="col">
              <Button onClick={() => setReviewClicked(!reviewClicked)}>
                leave review
              </Button>
            </div>
          </div>
          <div>
            {reviewClicked && (
              <div>
                <LeaveReviewFields />
              //  <LeaveReviewFields performer={performer} />
              </div>
            )}
          </div> */}
          </div>
        ))}
      <hr />
    </div>
  );
};

export default Performer;
