/* eslint-disable import/no-anonymous-default-export */
// import React from 'react';
// import PropTypes from 'prop-types';

import {
  GET_EVENTS_THIS_WEEK,
  GET_BOOSTED_EVENTS_THIS_WEEK,
  CREATE_EVENT_THIS_WEEK,
  EVENTS_THIS_WEEK_ERROR,
  GET_EVENTS_THIS_WEEK_BY_LOCATION,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_EVENTS_THIS_WEEK:
      return {
        ...state,
        eventsThisWeek: action.payload,

        // loading: false,
      };
    case GET_BOOSTED_EVENTS_THIS_WEEK:
      return {
        ...state,
        boostedEventsThisWeek: action.payload,

        // loading: false,
      };
    case CREATE_EVENT_THIS_WEEK:
      return {
        ...state,
        event: action.payload,

        // loading: false,
      };
    case GET_EVENTS_THIS_WEEK_BY_LOCATION:
      return {
        ...state,
        eventsThisWeekByLocation: action.payload,

        // loading: false,
      };

    case EVENTS_THIS_WEEK_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
