import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { useState, Redirect } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../../context/user/userContext'; // might have to capitalize
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import { useContext, useEffect } from 'react';
// import UserSearchItem from './UserSearchItem';

const DynamicSearch = (props) => {
  // useEffect(() => {
  // });
  const { userSearchText, dynamicSearchResults } = props;
  const userContext = useContext(UserContext);

  const {
    getUsersForSearchCriteria,
    userSearchResults,
    // connections,
    // // getConnectionsForUserWithId,
    // getConnections,
  } = userContext;

  const search = useLocation().search;
  const searchText = new URLSearchParams(search).get('searchText');
  console.log('userSearchResults ---');
  console.log(userSearchResults);
  // console.log('userSearchText');
  // console.log(userSearchText);
  const onChange = (input) => {
    //this will run every time a user types
    //getSearchParametersDynamically(input)
    getUsersForSearchCriteria(userSearchText);
  };
  const [, updateState] = React.useState();

  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [display, setDisplay] = useState(false);
  const options = ['gil', 'billlllll', 'chillllll'];
  const possibleSearches = ['gil', 'billlllll', 'chillllll'];
  let navigate = useNavigate();
  const selectUser = (user_id) => {
    navigate(`/user/profile/${user_id}`, {
      // state: {
      //   advert: advert,
      //   user: user,
      //   // promotionText: promotionText,
      //   file: file,
      // },
    });
  };
  return (
    <div className="autoContainer">
      <h5 style={{ fontSize: '14px' }}>Try searching for</h5>
      {dynamicSearchResults.map((user) => (
        <div className="option search-card">
          {/* <Link
            to={`/user/profile/${user._id}`}
            className="btn btn-info"
            state={{ reloadState: true }}
          >
            <h5
              // onClick={() => this.forceUpdate}
              // onClick={() => window.location.reload()}
              style={{ color: 'black' }}
            >
              {user.name}
            </h5>
          </Link> */}
          <h3>{user.name}</h3>

          <button
            onClick={() => selectUser(user._id)}
            // to={`/user/profile/${user._id}`}
            className="btn btn-info"
          >
            View Profile
          </button>
        </div>
      ))}
      {/* {options.map((person) => (
        <div className="option">
          <h5 style={{ color: 'black' }}>{person}</h5>
        </div>
      ))} */}
    </div>
  );
};

export default DynamicSearch;
