import React, { Fragment, useContext, useEffect, useRef } from 'react';
// import { Fragment, useContext } from 'react';

// import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { useAuth, clearErrors, login } from '../../context/auth/AuthState';
import { useAuth } from '../../context/auth/AuthState';
import { useState, Redirect } from 'react';
// import { useHistory } from 'react';
import { useNavigate } from 'react-router-dom';
// import { logout, loadUser } from '../../context/auth/AuthState';
import { logout } from '../../context/auth/AuthState';
// import { Redirect } from 'react-router-dom';

// import User from '../user/User';

import AuthContext from '../../context/auth/authContext';
// import ContactContext from '../../context/contact/contactContext';
// const center = {
//   paddingRight: 300,
// };

import MuiDrawer from '../MuiDrawer';
import Search from '@material-ui/icons/SearchRounded';
import DynamicSearch from './DynamicSearch';
import UserContext from '../../context/user/userContext'; // might have to capitalize

const Navbar = ({ title, icon, props }) => {
  const wrapperRef = useRef(null);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  });
  const userContext = useContext(UserContext);
  const {
    getUsersForSearchCriteria,
    userSearchResults,
    // connections,
    // // getConnectionsForUserWithId,
    // getConnections,
  } = userContext;

  const handleClickOutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setDisplay(false);
    }
  };
  // const [authState, authDispatch] = useAuth();
  const [authState, authDispatch, loading] = useAuth();
  // const { error, isAuthenticated, logout } = authState;

  // const { user } = authState;

  const authContext = useContext(AuthContext);
  // const contactContext = useContext(ContactContext);
  const { isAuthenticated, user } = authState;
  // const { loadUser } = authContext;
  //   const { clearContacts } = contactContext;

  // useEffect(() => {
  //   loadUser();
  //   // eslint-disable-next-line
  // }, []);

  // const onLogout = () => {
  //   logout();
  //   // clearContacts();
  // };

  const onLogout = () => {
    logout(authDispatch);
    // clearContacts(contactDispatch);
  };

  const authLinks = (
    <Fragment>
      <li>{user && user.name}</li>
      {/* <li>
        <a onClick={onLogout} href="/">
          <i className="fas fa-sign-out-alt" />{' '}
          <span className="hide-sm">Logout</span>
        </a>
      </li> */}
      <li>
        <Link to="/notifications"> Notifications</Link>
      </li>
      <li>
        <Link to="/user/profile"> Profile</Link>
      </li>
    </Fragment>
  );

  const guestLinks = (
    <Fragment>
      {/* <li>
        <Link to="/register">Register</Link>
      </li> */}
      <li>
        <Link to="/login">Login</Link>
      </li>
    </Fragment>
  );
  console.log('user from nav is: --------');
  console.log(user);
  console.log('DONE ------------------------------------------------');

  const [details, setDetails] = useState({
    searchText: '',
  });

  const { searchText } = details;
  let navigate = useNavigate();

  const handleClick = () => {
    navigate('/search');

    // e.preventDefault();
  };
  const [dynamicText, setDynamicText] = useState('');
  const onChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
    console.log('text is:');
    console.log(e.target.value);
    if (e.target.value !== '') {
      getUsersForSearchCriteria(e.target.value);
      setDynamicText(e.target.value);
    }
  };
  const onSubmit2 = (e) => {
    // e.preventDefault();
    console.log('searching');
    //call backend function sending search parameters in request

    if (searchText === '') {
      // navigate('/all-users');
      // return <Redirect to="/all-users" />;
      // props.history.push('/all-users');
      // //no search params -> return all users
      // console.log('search empty');
    } else {
      navigate('search', { id: '123' });

      //if input -> return users matching or "like" params
      // props.history.push('/user/profile');
      // // return <Redirect to="/user/profile" />;

      // // e.preventDefault();

      // //push to another component
      // console.log('search not empty');
    }
  };

  // const handleSearch = () => {
  //   getUsersForSearchCriteria(userSearchText);
  //   return;
  // };

  const [display, setDisplay] = useState(false);
  const options = ['gil', 'billlllll', 'chillllll'];
  console.log('userSearchResults from navbar');
  console.log(userSearchResults);
  return (
    // <div className="navbar bg-primary">
    <div>
      {/* <div style={center}> */}
      <div>
        <form className="form-inline my-2 my-lg-0">
          <div className="navbar bg-primary">
            <div className="row">
              {/* <div className="row navbar bg-primary"> */}
              <div className="col-2">
                <MuiDrawer />
              </div>
              {/* <div className="col" style={{ length: '200px' }}> */}
              <div className="col">
                <input
                  id="searchText"
                  class="form-control mr-sm-2"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  name="searchText"
                  value={searchText}
                  onChange={onChange}
                  style={{ width: '150px' }}
                  onClick={() => setDisplay(!display)}
                  autoComplete="off"
                />
              </div>
              <div className="col" style={{ margin: 'auto' }}>
                {/* <div className="col"> */}
                <button
                  onClick={onSubmit2}
                  class="searchBtn my my-sm-0"
                  type="submit"
                  // style={{ fontSize: '10px' }}
                >
                  {<Search fontSize="10px" />}
                </button>
              </div>
            </div>
          </div>
          <div ref={wrapperRef} className="flex-container flex-column pos-rel">
            {display && (
              // <div className="autoContainer">
              <div>
                {/* <h5>Try searching for</h5> */}
                {/* {options.map((person) => (
                  <div className="option">
                    <h5 style={{ color: 'black' }}>{person}</h5>
                  </div>
                ))} */}
                <DynamicSearch
                  // onChangeValue={handleSearch}
                  userSearchText={dynamicText}
                  dynamicSearchResults={userSearchResults}
                />
              </div>
            )}
          </div>

          {/* <Link to="/all-users"> */}
        </form>
      </div>

      {/*       

      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
       
        <li>
          <Link to="/upcoming-events"> My Events</Link>
        </li>

        <li>
          <Link to="/events"> Events</Link>
        </li>       
      </ul> */}

      {/* <ul>{isAuthenticated ? authLinks : guestLinks}</ul> */}
    </div>
  );
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Navbar.defaultProps = {
  title: 'Roommate Finder',
  icon: 'fas fa-id-card-alt',
};

export default Navbar;
