// import React from 'react';
import React, { Fragment, useContext, useEffect } from 'react';
import { useState } from 'react';

import UserContext from '../../context/user/userContext';
import SubEventContext from '../../context/subevent/subEventContext';
import { useAuth } from '../../context/auth/AuthState';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import SubEventAttendee from './SubEventAttendee';

const SubEventAttendees = (props) => {
  const subEventContext = useContext(SubEventContext);
  const {
    joinSubEvent,
    joined_sub_event,
    getAttendeesForSubEvent,
    sub_event_attendees,
  } = subEventContext;
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log(
  //     'getting events from Event.js but need to change component name'
  //   );
  //   //returns all users -- we need to paginate this so we only get one at a time.
  //   //that comes later
  //   setLoading(true);
  //   getAttendeesForSubEvent(subevent._id);
  //   setLoading(false);
  //   // eslint-disable-next-line
  // }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    getAttendees();
    setShow(true);
  };

  const { subevent } = props;
  // console.log('-------------- user is from Attendee');
  // console.log(user);
  const userContext = useContext(UserContext);

  const [authState] = useAuth();
  const { user } = authState;

  const { passOnUser, likeUser } = userContext;

  //helper for submit button
  const getAttendees = () => {
    getAttendeesForSubEvent(subevent._id);
    // console.log("joining subevent")
  };
  const join_sub_event = () => {
    joinSubEvent(subevent._id, user._id);
    // console.log("joining subevent")
  };

  let alreadyJoined = false;

  const userAlreadyAttending = () => {
    console.log('subevent attendees');
    console.log(subevent.attendees);
    for (var i = 0; i < subevent.attendees.length; i++) {
      if (user && subevent.attendees[i] === user._id) {
        console.log(`${user._id} - ${user.name}  already in attendees list`);
        alreadyJoined = true;
        return true;
      }
    }
    console.log('user not in attendees yet');
    return false;
  };

  const joinButton = (
    <Fragment>
      <div className="col-3">
        <button
          type="button"
          className="btn btn-dark btn-block"
          onClick={join_sub_event}
          style={{
            background: 'white',
            // color: 'blue',
            borderStyle: 'solid',
            borderColor: 'rgb(1, 126, 252)',

            fontSize: '14px',
            backgroundColor: 'white',
            color: 'rgb(1, 126, 252)',
            textAlign: 'center',
            padding: '0px 12px',
            width: '120px',
            borderWidth: '1px',
          }}
        >
          Join Event
        </button>
      </div>
    </Fragment>
  );

  const alreadyJoinedButton = (
    <Fragment>
      <div className="col-3">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          Joined
        </button>
      </div>
    </Fragment>
  );
  const nouser = (
    <Fragment>
      <div className="col-3">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          sign in to join
        </button>
      </div>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!user) {
      console.log('no user found');
      return nouser;
    }
    if (user && userAlreadyAttending()) {
      return alreadyJoinedButton;
    } else {
      return joinButton;
    }
  };

  return (
    <div>
      <Button variant="outline-primary" onClick={handleShow}>
        {subevent.attendees.length} going
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Attending</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        {sub_event_attendees.map((attendee) => (
          <SubEventAttendee key={attendee.id} attendee={attendee} />
        ))}

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default SubEventAttendees;

// const onLike = () => {
//   console.log('user liked and my info below');
//   console.log(user);

//   likeUser(user);
//   //what should happen when you like a user
//   //the user's info should go to backend and the messages>liked users should be updated (may be a premium feature)
//   //delete current first user in the loaded in array of users and replace with the next one up
// };

// const onPass = () => {
//   console.log('passed on user');
//   // passOnUser(user._id);
//   passOnUser(user);
// };
