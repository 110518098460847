import React, { Component, useContext, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import EventType from '../EventType';
import { useAuth } from '../../../context/auth/AuthState';
import AlertContext from '../../../context/alert/alertContext';
import EventContext from '../../../context/event/eventContext';
import ListOfStatesAndCities from '../ListOfStatesAndCities.json';
import ListOfStates from '../ListOfStates.json';
import AddLocation from '../../location/AddLocation';

const AddEventPopup = (props) => {
  const eventContext = useContext(EventContext);
  const {
    getAllEvents,
    events,
    createNewEvent,
    eventsInLocation,
    getEventsByLocation,
    getHighlightedEvents,
    highlighted_events,
    getEventsWithEventType,
    allEventsWithEventType,
  } = eventContext;

  const [authState] = useAuth();
  const { user } = authState;
  const [the_event_type, setEventType] = useState('Dance Event');

  const [details, setDetails] = useState({
    descr: '',
    event_type: the_event_type,
    eventName: '',
    start_date: '',
    end_date: '',
    event_image: '',
    state: '',
    city: '',
    country: '',
    venueName: '',
    company_association: '',
    company_id:
      user?.companies && user?.companies.length > 0
        ? user?.companies[0]?.company_id
        : '',
  });
  const [file, setFile] = useState();
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const {
    descr,
    eventName,
    event_image,
    start_date,
    end_date,
    state,
    city,
    country,
    venueName,
    company_association,
    company_id,
    event_type,
  } = details;

  const onSubmit2 = (e) => {
    e.preventDefault();
    console.log('details from submission');
    console.log(details);
    const data = new FormData();
    data.append('file', file);
    data.append('descr', descr);
    data.append('event_type', event_type);
    data.append('name', eventName);
    data.append('state', state);
    data.append('city', city);
    data.append('country', country);
    data.append('venueName', venueName);
    data.append('start_date', start_date);
    data.append('end_date', end_date);
    data.append('image', event_image);
    data.append('hostId', user._id);
    data.append('hostName', user.name);
    data.append('hostImageUrl', user?.image?.url);
    data.append('hostImageFilename', user?.image?.filename);
    data.append('company_association', company_association);
    data.append('company_id', company_id);
    // const hostImage = {
    //   url: user.image.url,
    //   filename: user.image.filename,
    // };

    // data.append('hostImage', hostImage);
    if (descr === '') {
      setAlert('Please fill in description field', 'danger');
    }
    if (city === '') {
      setAlert('Please enter a city field', 'danger');
    } else {
      // login(authDispatch, {
      //   descr,
      // });
      // request_roommate();
      // createNewEvent(eventName, descr, user._id);
      console.log('data from EWP');
      console.log(data);

      createNewEvent(data);
    }
  };
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  const handleSelectEventType = (e) => {
    setEventType(e);
  };

  const listOfStatesAndCities = ListOfStatesAndCities;
  const listOfStates = ListOfStates;
  const [
    listOfCitiesForSelectedState,
    setListOfCitiesForSelectedState,
  ] = useState([]);

  const selectedCities = (state) => {
    let cities = listOfStatesAndCities[state];
    // console.log('cities');
    // console.log(cities);
    // setSelectedCity(cities[0]);
    setDetails({ ...details, ['city']: cities[0], ['state']: state });

    setListOfCitiesForSelectedState(cities);
    return cities;
  };
  const [companiesVisible, setCompaniesVisible] = React.useState(false);

  const onAssociationChange = () => {
    setCompaniesVisible(!companiesVisible);
    if (user?.companies) {
      setDetails({ ...details, company_id: user?.companies[0].company_id });
    }
    console.log(details);
  };
  const handleClose = () => props.setShow(false);

  const splitValue1 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[0];
  };
  const splitValue2 = (e) => {
    console.log('e from splitValue');
    console.log(e.target.value);
    let arr = e.target.value.split('/');
    console.log('arr');
    console.log(arr);
    // console.log('e split 0th index ');
    // console.log(e.target.value.split('/').get(0));
    // return e.target.value.split('/').get(0);
    return arr[1];
  };

  const setSelectedCountry = (country) => {
    setDetails({
      ...details,
      country: country,
    });
    console.log('details from set..');
    console.log(details);

    // setDetails({ ...details, ['city']: cities[0], ['state']: state });
  };
  const setSelectedState = (state) => {
    setDetails({
      ...details,
      state: state,
    });
    console.log('details from set..');
    console.log(details);

    // setDetails({ ...details, ['city']: cities[0], ['state']: state });
  };
  const setSelectedCity = (city) => {
    setDetails({
      ...details,
      city: city,
    });
    console.log('details from set..');
    console.log(details);

    // setDetails({ ...details, ['city']: cities[0], ['state']: state });
  };
  return (
    <div>
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onSubmit2}>
            <div className="form-group">
              <h1>Event Details</h1>

              <input
                id="eventName"
                type="text"
                name="eventName"
                placeholder="Event Name"
                value={eventName}
                onChange={onChange}
                required
              />
              <input
                id="descr"
                type="text"
                name="descr"
                placeholder="Event Description"
                value={descr}
                onChange={onChange}
                required
              />
              <div className="row">
                <div className="col-3">
                  <h5>Event Type</h5>
                </div>
                <div className="col">
                  <EventType
                    // setEventType={setEventType}
                    details={details}
                    setDetails={setDetails}
                    event_type={the_event_type}
                    handleSelectEventType={handleSelectEventType}
                  />
                </div>
              </div>
              {/* venue and city */}
              <input
                id="venueName"
                type="text"
                name="venueName"
                placeholder="Venue"
                value={venueName}
                onChange={onChange}
                required
              />
              {/* <input
                  id=""
                  type="date"
                  name=""
                  placeholder="Start Date"
                  value={}
                  onChange={onChange}
                  required
                /> */}

              <div>
                <AddLocation
                  setCountry={setSelectedCountry}
                  setState={setSelectedState}
                  setCity={setSelectedCity}
                />
              </div>

              {/* <Form.Group controlId="formBasicSelect">
                <Form.Label>State</Form.Label>
                <Form.Control
                  id="state"
                  name="state"
                  as="select"
                  value={state}
                  onChange={(e) => {
                    // setDetails({
                    //   ...details,
                    //   [e.target.name]: e.target.value,
                    // });
                    console.log(' e.target.value');
                    console.log(e.target.value);
                    // setSelectedState(e.target.value);
                    let cities = selectedCities(e.target.value);
                    // setSelectedCity(cities[0]);
                    console.log('details..city filled?');
                    console.log(details);
                  }}
                >
                  {listOfStates.map((state) => (
                    <option value={state}>{state}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formBasicSelect">
                <Form.Label>City</Form.Label>
                <Form.Control
                  id="city"
                  name="city"
                  as="select"
                  value={city}
                  required
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      [e.target.name]: e.target.value,
                    });
                    // setSelectedCity();
                    console.log(details);
                  }}
                >
                  {listOfCitiesForSelectedState.map((city) => (
                    <option value={city}>{city}</option>
                  ))}
                </Form.Control>
              </Form.Group> */}

              <label for="start_date">Start Date:</label>

              <input
                id="start_date"
                type="date"
                name="start_date"
                placeholder="Start Date"
                value={start_date}
                onChange={onChange}
                required
              />

              <label for="end_date">End Date:</label>

              <input
                id="end_date"
                type="date"
                name="end_date"
                placeholder="End Date"
                value={end_date}
                onChange={onChange}
                required
              />
              <input
                id="event_image"
                name="event_image"
                type="file"
                value={event_image}
                required
                onChange={(event) => {
                  const file = event.target.files[0];
                  setFile(file);
                  setDetails({
                    ...details,
                    [event.target.name]: event.target.value,
                  });
                }}
              />

              {user.companies &&
                user.companies.length > 0 && (
                  <div class="form-check">
                    {/* <label class="form-check-label" for={}>
                  {"Are you one of the owners of this studio?"}
                </label> */}

                    <input
                      //  id="name"
                      //  name="name" // as="text" type="text"
                      //  value={name}
                      id="associate_with_company"
                      type="checkbox"
                      name="associate_with_company"
                      value={'associate_with_company'}
                      onChange={onAssociationChange}
                    />

                    <label for="associate_with_company">
                      {' '}
                      Would you like to link this event with your company?
                    </label>

                    <Form.Group controlId="formBasicSelect">
                      <Form.Label>Which Company</Form.Label>
                      <Form.Control
                        id="company_association"
                        name="company_association"
                        as="select"
                        value={company_association}
                        onChange={(e) => {
                          setDetails({
                            ...details,
                            [e.target.name]: splitValue1(e),
                            company_id: splitValue2(e),
                          });

                          console.log('details..company filled?');
                          console.log(details);
                        }}
                      >
                        {companiesVisible &&
                          user.companies.map((company, index) => (
                            <option
                              value={
                                company.company_name + '/' + company.company_id
                              }
                            >
                              {company.company_name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                )}
            </div>

            <input
              type="submit"
              value="Create Event"
              // className="btn btn-primary btn-block"
              class="btn btn-secondary"
              // onClick={onSubmit2}
            />
          </form>

          {/* 


















*/}

          {/* <div style={{ paddingTop: '10px' }}>
            <Button onClick={handleFilter}>Filter</Button>
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddEventPopup;
