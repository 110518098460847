import React, { Component, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import spinner from '../events/spinner.gif';
import TriviaContext from '../../context/trivia/triviaContext';
import { useAuth } from '../../context/auth/AuthState';
import PotentialMatches from './PotentialMatches';
import SubEvents from '../events/SubEvents';

const GeneratedConnections = (props) => {
  const [authState] = useAuth();

  const { user } = authState;
  let triviaContext = useContext(TriviaContext);
  let { trivia_answers, trivia_id_from_event_page, event } = props;
  console.log('trivia_answers from register');
  console.log(trivia_answers);
  let {
    getPotentialConnectionsFromTrivia,
    potentialConnections,
    loading,
  } = triviaContext;
  // run matching algorithm and display users to connect with

  useEffect(() => {
    // here im conditionally calling getPotentialConnectionsFromTrivia() bcuz we could
    //get to this component from different parent components
    if (user) {
      if (trivia_answers) {
        getPotentialConnectionsFromTrivia(trivia_answers?.trivia_id, user?._id);
      }
      if (trivia_id_from_event_page) {
        getPotentialConnectionsFromTrivia(trivia_id_from_event_page, user?._id);
      }
    } else {
      //run get user function and make screen load
      console.log('user not loaded yet..');
    }

    // eslint-disable-next-line
  }, []);

  console.log('potentialConnections ---');
  console.log(potentialConnections);
  const dummyUsers = [
    {
      uname: 'timmy',
      uimage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQphO1iGa3a8wJpd43zAbREvXa8q4DmAIKww&s',
    },
    {
      uname: 'timmy',
      uimage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQphO1iGa3a8wJpd43zAbREvXa8q4DmAIKww&s',
    },
    {
      uname: 'timmy',
      uimage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQQphO1iGa3a8wJpd43zAbREvXa8q4DmAIKww&s',
    },
  ];
  let navigate = useNavigate();

  if (loading) {
    return (
      <div>
        <h5>generating potential connections for you</h5>
        <img
          src={spinner}
          style={{ width: '200px', margin: 'auto', display: 'block' }}
          alt="Loading..."
        />
      </div>
    );
  }
  return (
    <div className="card">
      <h4
        style={{
          fontSize: '24px',

          backgroundColor: 'rgb(144, 238, 144)',
          borderStyle: 'solid',
          borderColor: 'rgb(144, 238, 144)',
          borderWidth: '2px',
          borderRadius: '6px',
          color: 'white',
          textAlign: 'center',
          width: '300px',
        }}
      >
        Generated Connections
      </h4>

      <br />

      {potentialConnections?.length === 0 ? (
        <div>
          <h5>Waiting on others to submit their answers, refresh in a bit</h5>
          <br />
        </div>
      ) : (
        <div>
          <h5>
            Based on your answers, you might be good friends with the following
            users
          </h5>
          <br />
        </div>
      )}

      {potentialConnections?.length < 2 ? (
        <div>
          <h5 style={{ color: 'pink', fontSize: '12px' }}>
            select the shuffle button after a few moments to give others time to
            submit their responses
          </h5>
        </div>
      ) : (
        <></>
      )}

      {/* {dummyUsers.map((user) => ( */}
      {potentialConnections.map((userMatch) => (
        <div>
          <PotentialMatches
            userMatch={userMatch}
            trivia_id={
              trivia_id_from_event_page
                ? trivia_id_from_event_page
                : trivia_answers?.trivia_id
            }
          />
        </div>
      ))}

      {/* {potentialConnections?.length === 0 ? (
        <div>
          <h5>Waiting on others to submit their answers, refresh in a bit</h5>
        </div>
      ) : (
        <></>
      )} */}

      <div className="row">
        <div className="col">
          <Button
            onClick={() => window.location.reload()}
            style={{
              fontSize: '18px',

              backgroundColor: 'rgb(144, 238, 144)',
              borderStyle: 'solid',
              borderColor: 'rgb(144, 238, 144)',
              borderWidth: '2px',
              borderRadius: '6px',
              color: 'white',
              textAlign: 'center',
              width: '300px',
            }}
          >
            Shuffle Suggestions
          </Button>
        </div>

        {/* <div className="col">
          <Button
            onClick={() => {
              //   generatePotentialConnections();

              navigate('/potential-activities', {
                state: {
                  potentialConnections: potentialConnections,
                },
              });
            }}
          >
            Suggest Activity
          </Button>
        </div> */}
        {event ? (
          <div className="row" style={{ paddingTop: '28px' }}>
            <SubEvents event={event} />
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* loading potential connections */}
      {/* how are we handling unregistered users who fill out the trivia ?

obviously we'll nudge them to create an account.. perhaps, the nudge comes right before 
the generation of connections?? that way we have new users coming into the system

... the first couple ppl we can say, "send to your friends or social media so more ppl take trivia
and your suggestions are updated..."


so if user is registerd
        generate connections

if not registerd
        nudge to register and then generate connections



generated users will come from Trivia generatePotentialConnections()

and we'll render here - render 6 and then add shuffle button and use 
filters to shuffle - event, user's trivia answers, profile filters





*/}
    </div>
  );
};
export default GeneratedConnections;
