import React, { Component } from 'react';

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Link } from 'react-router-dom';

const PremiumPopoverUpsell = (props) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Access Premium Now</Popover.Header>
      <Popover.Body>
        <h5>
          Booking Privates is a premium feature. You can unlock this feature and
          others as a{' '}
          <strong>premium artist/instructor or premium event</strong>
        </h5>
        <br />
        <div className="col">
          <Link to={`/go-pro-event/${props.event?._id}`}>
            <button
              style={{
                width: '250px',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '1px',
                borderRadius: '2px',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
              }}
            >
              Access Premium Event
            </button>
          </Link>
        </div>
        <br />
        <div className="col">
          <Link to={`/go-pro-artist/${props.artist?._id}`}>
            <button
              style={{
                width: '250px',
                background: 'white',

                borderStyle: 'solid',
                borderColor: 'rgb(1, 126, 252)',
                borderWidth: '1px',
                borderRadius: '2px',

                fontSize: '14px',
                backgroundColor: 'white',
                color: 'rgb(1, 126, 252)',
                textAlign: 'center',
              }}
            >
              Access Premium Artist / Instructor
            </button>
          </Link>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
        <Button
          style={{
            // width: 'max-width',
            backgroundColor: 'white',
            // width: 'max-content',
            // width: '120px',
            width: 'max-content',

            background: 'white',
            color: 'rgb(1, 126, 252)',
            borderStyle: 'solid',
            // borderColor: 'rgb(1, 126, 252)',
            padding: '6px',
            marginTop: '10px',
            border: '0.5px solid rgb(1, 126, 252)',
          }}
        >
          {' '}
          Book Private ❌
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default PremiumPopoverUpsell;
