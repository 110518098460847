import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAuth } from '../../../context/auth/AuthState';
import StudioContext from '../../../context/studio/studioContext';
import { useParams } from 'react-router-dom';
// import StudioListForUser from './StudioListForUser';
// import { Link } from 'react-router-dom';

const ClassPopup = (props) => {
  const [classPopupLoading, setClassPopupLoading] = useState(false);

  const [authState] = useAuth();
  const { user, isAuthenticated, loading } = authState;
  const studioContext = useContext(StudioContext);

  const { enrollMemberToClass } = studioContext;

  useEffect(() => {
    //get studio by id
    setClassPopupLoading(true);
    if (user) {
      //   setUserEnrolled(true);
      userAlreadyEnrolled(user);
    } else {
      console.log('no user cuh');
    }
    setClassPopupLoading(false);
    // eslint-disable-next-line
  }, []);

  let { showClass, studioClass } = props;
  const [show, setShow] = useState(showClass);
  const handleClose = () => setShow(false);

  const [userEnrolled, setUserEnrolled] = useState(false);
  const userAlreadyEnrolled = (user) => {
    if (user?.level_progression?.scores_and_styles) {
      let classesEnrolled = user?.level_progression?.scores_and_styles;
      for (let i = 0; i < classesEnrolled.length; i++) {
        if (
          classesEnrolled[i]?.class_name?.trim() ===
            studioClass?.class_name?.trim() &&
          classesEnrolled[i]?.dance_style === studioClass.style
        ) {
          console.log('user already enrolled');
          //   setUserEnrolled(true);
          return true;
        }
      }
    }

    return false;
  };
  const { studio_id } = useParams();

  const handleEnrollment = () => {
    // assignOrUpdateMemberLevel({
    //   user_id: user._id,
    //   // company_id: user?.dance_company_id,
    //   company_id: studio_id,
    //   new_level: studioClass,
    // });

    enrollMemberToClass({
      user_id: user._id,
      // company_id: user?.dance_company_id,
      company_id: studio_id,
      new_level: studioClass.style + ' | ' + studioClass.class_name,
    });
    console.log('obj here');
    console.log({
      user_id: user._id,
      // company_id: user?.dance_company_id,
      company_id: studio_id,
      new_level: studioClass.style + ' | ' + studioClass.class_name,
    });
  };

  const block = (
    <Fragment>
      {userAlreadyEnrolled(user) === true ? (
        <div>
          <Button
            // onClick={}
            style={{
              width: '130px',
              borderStyle: 'solid',
              backgroundColor: 'rgb(1, 126, 252)',
            }}
          >
            Enrolled
          </Button>
        </div>
      ) : (
        <div>
          <Button
            onClick={handleEnrollment}
            style={{
              width: '130px',
              borderStyle: 'solid',
              backgroundColor: 'rgb(1, 126, 252)',
              background: 'white',
              borderColor: 'rgb(1, 126, 252)',
              fontSize: '14px',
              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '0px 12px',
            }}
          >
            Enroll in Class
          </Button>
        </div>
      )}
    </Fragment>
  );
  if (classPopupLoading) {
    return <h2>Loading...</h2>;
  }

  let weeks_of_class = ['week 1', 'week 2'];

  let skills_v2_with_detailsx = [
    {
      skill: 'lead left turn 1',
      skill_video_link: 'http',
      week_to_teach: 'week 1',
    },
    {
      skill: 'follow left turn 1',
      skill_video_link: 'http',
      week_to_teach: 'week 1',
    },
    {
      skill: 'follow left turn 2',
      skill_video_link: 'http',
      week_to_teach: 'week 2',
    },
    {
      skill: 'follow left turn 2',
      skill_video_link: 'http',
      week_to_teach: 'week 2',
    },
  ];

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{studioClass.class_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {user ? <div>{block}</div> : <></>}

          <div style={{ paddingTop: '14px', margin: '20px' }}>
            {studioClass?.skills.map((skill, index) => (
              <div className="row">{`${index + 1} ${skill}`}</div>
            ))}
          </div>
        </Modal.Body>

        {weeks_of_class.map((week) => {
          return (
            <div className="card row">
              <h1>{week}</h1>

              {skills_v2_with_detailsx.map((skill) => {
                return skill.week_to_teach === week ? (
                  <>
                    <h5>move: {skill.skill}</h5>

                    <h5>week : {skill.week_to_teach}</h5>
                  </>
                ) : (
                  <></>
                );
              })}
            </div>
          );
        })}
        <h5>enrolled students</h5>

        <div className="row">
          <h5>student1</h5>
          <h5>student1</h5>
          <h5>student1</h5>
          <h5>student1</h5>
          <h5>student1</h5>
          <h5>student1</h5>
        </div>
      </Modal>
    </div>
  );
};
export default ClassPopup;
