import React, { Component, useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

const EventFeedbackCategory = (props) => {
  const [feedbackFields, setFeedbackFields] = useState({
    bib_number: '',
    dancer_name: '',
  });
  // const scores = Array.from(Array(10).keys());

  const scores = [...Array(10).keys()].map((x) => ++x);

  const { category, indexToChange, setFieldsFromChildComponent } = props;

  const handleSelectRating = (e) => {
    // setLevel(e);
    setFeedbackFields({ ...feedbackFields, ['category']: e });
    console.log('feedbackFields is');
    console.log(feedbackFields);
    console.log(e);

    //do we need this ????? ------------------
    setCategoryScore(e);
    setFieldsFromChildComponent(e, indexToChange);
  };

  const [categoryScore, setCategoryScore] = useState(
    props.category.categoryScore
  );

  return (
    <div className="row">
      <div className="col">
        <h5 style={{ fontSize: '14px' }}>{category.category}</h5>
      </div>
      <div className="col">
        {[DropdownButton].map((DropdownType, idx) => (
          <DropdownType
            as={ButtonGroup}
            key={idx}
            id={`dropdown-button-drop-${idx}`}
            size="sm"
            variant="secondary"
            title={categoryScore}
            onSelect={handleSelectRating}
          >
            {scores.map((score) => (
              <Dropdown.Item eventKey={score}>{score}</Dropdown.Item>
            ))}
          </DropdownType>
        ))}
      </div>
    </div>
  );
};

export default EventFeedbackCategory;
{
  /*

 <h5>test</h5>
      <h5 style={{ fontSize: '14px' }}>DJs / Music Selection</h5>
      <hr />
      <h5 style={{ fontSize: '14px' }}>Schedule</h5>
      <hr />
      <h5 style={{ fontSize: '14px' }}>Social Dancing</h5>
      <hr />

      <div className="row">
        <h5 style={{ fontSize: '14px' }}>Facilities</h5>
        <label htmlFor="" style={{ fontSize: '10px' }}>
          including aoinaconacncoinaoin
        </label>
        <hr />
      </div>
      <h5 style={{ fontSize: '14px' }}>music</h5>


*/
}
