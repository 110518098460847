import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';
import StudioContext from '../../context/studio/studioContext';
import AddStudioLevel from './AddStudioLevel';
import Levels from './Levels';
import EventItem from '../events/EventItem';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import StudioMember from './StudioMember';
import { useAuth } from '../../context/auth/AuthState';
import CompanyPerformances from './CompanyPerformances';
import PremiumStudioPopoverDetail from './PremiumStudioPopoverDetail';
import ReactGA from 'react-ga4';
import StudioClaimOptionsPopup from './StudioClaimOptionsPopup';
import EditCompany from './EditCompany';
import CompanyMembersByRole from './CompanyMembersByRole';
import Curricula from './curricula/Curricula';
import CurriculumContext from '../../context/curriculum/curriculumContext';
import BuildCurriculumPopup3_v02 from '../curriculum/BuildCurriculumPopup3_v02';

const Company = (props) => {
  let curriculumContext = useContext(CurriculumContext);

  //curriculaForSchool will be list of curriculums
  const { getCurriculaForSchool, curriculaForSchool } = curriculumContext;

  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;

  const studioContext = useContext(StudioContext);
  const {
    getStudioById,
    studio,
    getStudioMembers,
    members,
    addNewMember,
    getStudioLevels,
    studio_levels,
    companyEvents,
    getUpcomingEvents,
    joinStudio,
    getAllPerformances,
    all_performances,
  } = studioContext;

  const userContext = useContext(UserContext);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;
  const { user } = authState;
  const { connectToUser } = userContext;
  let copyClicked = false;
  const location = useLocation();
  //   const { user } = location.state;

  // const { user, current_user_id, isCurrentUser } = props;
  const { studio_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [alreadyAMember, setAlreadyAMember] = useState(false);

  useEffect(() => {
    //get studio by id
    setLoading(true);
    // setAlreadyAMember(userAlreadyAMember);
    getStudioById(studio_id);
    console.log('studio_id from useeffect');
    console.log(studio);
    getStudioMembers(studio_id);
    getStudioLevels(studio_id);
    getUpcomingEvents(studio_id);
    getAllPerformances(studio_id);
    getCurriculaForSchool(studio_id);
    setLoading(false);
    console.log('studio os:');
    console.log(studio);
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: `user selected ${studio.name} /studio/${studio_id}`,
    });
    // eslint-disable-next-line
  }, []);

  const [shareButtonText, setShareButtonText] = React.useState('Share Profile');

  // const isNotConnected = () => {
  //   return (
  //     typeof current_user_id !== 'undefined' &&
  //     !alreadyConnected() &&
  //     !isCurrentUser
  //   );
  // };
  // const [connectButtonText, setConnectButtonText] = useState(
  //   isNotConnected ? `Connect` : `Connected`
  // );

  const connectToUserFunction = (e) => {
    e.preventDefault();

    let obj = {
      //   userIdToConnectTo: user._id,
      // current_user_id: current_user_id,
    };
    console.log(obj);
    //   setConnectButtonText('Connected');

    // let notification_obj = {
    //   current_user_id: user._id,
    //   // eventId: roomie_request.event,
    //   accepted_user_id: roomie_request.requestor,
    //   //will also need accepting users name
    // };

    connectToUser(obj);
    // sendSMSAlertForAcceptedRoommate(notification_obj);
    console.log('success????');
  };

  // const alreadyConnected = () => {
  //   return user.connections?.includes(current_user_id);
  // };

  // const { profile } = this.props;
  let image;
  //   if (user?.image) {
  //     image = user?.image.url;
  //   } else {
  //     image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  //   }

  image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const userAlreadyAMember = (user) => {
    if (user?.companies) {
      for (let i = 0; i < user?.companies.length; i++) {
        if (user.companies[i].company_id === studio_id) {
          return true;
        }
      }
    }

    return false;
  };
  const [joinStudioPopupClicked, setJoinStudioPopupClicked] = useState(false);
  // const [show, setShow] = useState(joinStudioPopupClicked);
  // const handleClose = () => setShow(false);
  const [roles, setRoles] = useState(['student']);
  const onChange4 = (e) => {
    if (roles.includes(e.target.id)) {
      console.log('removing role');
      role_array = roles;
      role_array = role_array.filter((item) => item !== e.target.id);
      setRoles(role_array);
    } else {
      console.log('adding role');
      role_array.push(e.target.id);
      let role = e.target.id;
      setRoles([...roles, role]);
    }

    const member_set = new Set(role_array);
    const uniqueMembers = [...member_set];
    console.log('role_array');
    console.log(role_array);
  };

  const handleCloseJoinStudioPopup = () => setJoinStudioPopupClicked(false);
  const joinStudioPopup = () => {
    console.log('open popup');
    return (
      <Modal show={joinStudioPopupClicked} onHide={handleCloseJoinStudioPopup}>
        <Modal.Header closeButton>
          <Modal.Title>Select Roles</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="">Role</label>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="student"
                id="student"
                value="student"
                onChange={onChange4}
                checked={roles.includes('student')}
              />

              <label class="form-check-label" for="student">
                student
              </label>
            </div>

            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="instructor"
                id="instructor"
                value="instructor"
                onChange={onChange4}
                checked={roles.includes('instructor')}
              />

              <label class="form-check-label" for="instructor">
                instructor
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="staff"
                id="staff"
                value="staff"
                onChange={onChange4}
              />

              <label class="form-check-label" for="staff">
                staff
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="director"
                id="director"
                value="director"
                onChange={onChange4}
              />

              <label class="form-check-label" for="director">
                director
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                name="owner"
                id="owner"
                value="owner"
                onChange={onChange4}
              />

              <label class="form-check-label" for="owner">
                owner
              </label>
            </div>
          </div>
          <Button
            onClick={() => {
              joinStudio({ user_id: user._id, studio_id, roles });
            }}
          >
            join studio
          </Button>
        </Modal.Body>
      </Modal>
    );
  };

  const block = (
    <Fragment>
      {userAlreadyAMember(user) === true ? (
        <div>
          <Button
            // onClick={}
            style={{
              width: '130px',
              borderStyle: 'solid',
              backgroundColor: 'rgb(1, 126, 252)',
            }}
          >
            Joined
          </Button>
        </div>
      ) : (
        <div>
          <Button
            onClick={() => {
              // joinStudio({ user_id: user._id, studio_id });
              setJoinStudioPopupClicked(!joinStudioPopupClicked);
            }}
            // onClick={() => joinStudio({ user_id: user._id, studio_id,role:"student" })}
            style={{
              width: '130px',
              borderStyle: 'solid',
              backgroundColor: 'rgb(1, 126, 252)',

              background: 'white',
              // color: 'blue',
              borderColor: 'rgb(1, 126, 252)',

              fontSize: '14px',

              color: 'rgb(1, 126, 252)',
              textAlign: 'center',
              padding: '0px 12px',
            }}
          >
            Join Studio
          </Button>

          {joinStudioPopup()}
        </div>
      )}
    </Fragment>
  );

  const notClicked = (
    <Fragment>
      <Button
        variant="primary"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          console.log('copied text');
          copyClicked = true;
          setShareButtonText('copied');
        }}
        style={{
          background: 'white',
          // color: 'blue',
          borderStyle: 'solid',
          borderColor: 'rgb(1, 126, 252)',

          fontSize: '14px',
          backgroundColor: 'white',
          color: 'rgb(1, 126, 252)',
          textAlign: 'center',
          padding: '6px',
        }}
      >
        {shareButtonText}
      </Button>
    </Fragment>
  );
  const clicked = (
    <Fragment>
      <div className="col-3">
        <button
          type="button"
          className="btn btn-dark"

          // onClick={join_event}
        >
          Profile Link Copied
        </button>
      </div>
    </Fragment>
  );

  const renderOptions = () => {
    console.log('trying to render');
    if (!copyClicked) {
      console.log('no user found');
      return notClicked;
    } else {
      return clicked;
    }
  };

  const premiumButtonOptions = (
    <Fragment>
      <div
        className="col"
        // style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        {studio?.premium_subscription?.premium_subscriber === true ? (
          // {studio?.premium_subscription[0].premium_subscriber === true ? (
          <div className="col">
            <PremiumStudioPopoverDetail />
          </div>
        ) : (
          <div className="col">
            <Link to={`/go-pro-subscription/${studio?._id}`}>
              <Button
                // className="rounded-pill"
                style={{
                  // width: '180px',
                  width: 'max-content',
                  background: 'white',
                  // color: 'blue',
                  borderStyle: 'solid',
                  borderColor: 'rgb(1, 126, 252)',

                  fontSize: '14px',
                  backgroundColor: 'white',
                  color: 'rgb(1, 126, 252)',
                  textAlign: 'center',
                  padding: '6px',
                }}
              >
                Access Premium Studio 🚀
              </Button>
            </Link>
          </div>
        )}
      </div>
    </Fragment>
  );

  // const usersNotConnected = (
  //   <Fragment>
  //     <div className="col-3">
  //       <button
  //         type="button"
  //         // className="btn btn-dark"
  //         className="btn btn-primary my-2 my-sm-0"
  //         onClick={connectToUserFunction}
  //         disabled={connectButtonText === 'Connected'}
  //       >
  //         {connectButtonText}
  //       </button>
  //     </div>
  //   </Fragment>
  // );

  // const connected = () => {
  //   if (
  //     typeof current_user_id !== 'undefined' &&
  //     !alreadyConnected() &&
  //     !isCurrentUser
  //   ) {
  //     return usersNotConnected;
  //   } else {
  //     return <></>;
  //   }
  // };

  //   const [disabled, setDisabled] = useState(false);
  //   const [likeCount, setLikeCount] = React.useState(
  //     user.likeCount ? user.likeCount : 0
  //   );

  //   const handleLike = async (liked_user_id) => {
  //     if (user) {
  //       //like user & disable like button
  //       let obj = {
  //         likedUserId: liked_user_id,
  //         likingUserId: user?._id,
  //         // eventId: roomie_request.event,
  //         // accepted_user_id: roomie_request.requestor,
  //       };
  //       console.log('obj is');
  //       console.log(obj);
  //       likeDancer(obj);
  //       setDisabled(true);
  //     } else {
  //       console.log('alert user to log in to like and put login button in box');
  //     }
  //   };

  let notLiked = '🤍';
  let liked = '💙';

  const [likedBtn, setLikedBtn] = useState(notLiked);

  const claimed = (dancer) => {
    if (dancer?.claimed) {
      console.log(' claimed');
      return `/user/profile/${dancer.claimed_id}`;
    } else {
      console.log('not claimed');
      return `/dancer-profile/${dancer?._id}`;
    }
  };
  const [visible, setVisible] = React.useState(false);

  const [addLevelVisible, setAddLevelVisible] = React.useState(false);
  const [levelsVisible, setLevelsVisible] = React.useState(false);
  const [performancesVisible, setPerformancesVisible] = React.useState(false);

  const [details, setDetails] = useState({
    dancer_first_name: '',
    dancer_last_name: '',
    // dance_company_id: studio?._id,
    // state: studio?.state,
    // city: studio?.city,
    dance_company_id: '',
    state: '',
    city: '',
  });
  const {
    dancer_first_name,
    dancer_last_name,
    city,
    state,
    dance_company_id,
  } = details;

  // const { director, student, performer, instructor } = roles;
  const onChange = (e) =>
    setDetails({ ...details, [e.target.name]: e.target.value });

  //   const onChangeRoles = (e) =>
  //     setRoles({ ...roles, e.target.value });
  //   const onChangeRoles = (e) =>
  //     setRoles({ ...roles, e.target.value });

  let role_array = [];

  const onChange3 = (e) => {
    console.log('changing list -- e is:');
    // console.log(e.target);
    // setMemberArray(...member_array, e.target.name);
    // console.log(member_array);

    if (roles.includes(e.target.id)) {
      console.log('removing role');
      role_array = roles;
      role_array = role_array.filter((item) => item !== e.target.id);
      setRoles(role_array);
    } else {
      console.log('adding role');
      role_array.push(e.target.id);
      let role = e.target.id;
      setRoles([...roles, role]);
    }

    const member_set = new Set(role_array);
    const uniqueMembers = [...member_set];
    console.log('role_array');
    console.log(role_array);
  };

  const onSubmit2 = (e) => {
    e.preventDefault();
    if (dancer_first_name === '' || dancer_last_name === '') {
      //   setAlert('Please fill in all fields', 'danger');
      console.log('type in dancer name');
    } else {
      setDetails({
        ...details,
        [dance_company_id]: 'help',
        // ['city']: studio?.city,
        // ['state']: studio?.state,
      });

      const newObj = {
        dancer_first_name,
        dancer_last_name,
        dance_company_id: studio._id,
        dance_company_city: studio.city,
        dance_company_state: studio.state,
        dance_company_name: studio.name,
        roles: roles,
        // roles: role_array,
      };
      console.log('newObj');
      console.log(newObj);

      addNewMember(newObj);
    }
  };

  const [upcomingEventsVisible, setUpcomingEventsVisible] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    console.log('closing ');
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    console.log(`show is: ${show}`);
  };

  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
  const claimUser = (id) => {
    // e.preventDefault();
    setShow(true);
    console.log('claiming user');
  };

  const editingEvent2 = (
    <Fragment>
      <div>
        <div>
          <div>
            <label htmlFor="">
              Are you sure you want to claim this profile?
            </label>
          </div>
          <div className="row">
            <div className="col">
              <Link to="/">
                <Button
                  onClick={() => {
                    // deleteEvent(event._id);
                    claimUser('user_id123');
                  }}
                  className="col btn btn-danger"
                >
                  Yes
                </Button>
              </Link>
            </div>
            <div className="col">
              <Button onClick={() => setDeleteButtonClicked(false)}>no</Button>
            </div>
          </div>
        </div>

        {/* render attendees under attendees h1 or bring the attendees to a child component */}
      </div>
    </Fragment>
  );

  // const editingEvent = (
  //   <Fragment>
  //     <div>
  //       <Modal show={show} onHide={handleClose}>
  //         <Modal.Header closeButton>
  //           <Modal.Title>Edit Event</Modal.Title>
  //         </Modal.Header>
  //         <Modal.Body>
  //           <Form onSubmit={onSubmit} />
  //         </Modal.Body>
  //         <Modal.Footer>
  //           <Button
  //             variant="primary"
  //             onClick={() => setDeleteButtonClicked(true)}
  //           >
  //             Delete
  //           </Button>
  //           <Button variant="secondary" onClick={handleClose}>
  //             Close
  //           </Button>
  //           <Button variant="primary" onClick={onSubmit}>
  //             Save Changes
  //           </Button>
  //           {deleteButtonClicked ? (
  //             <div>
  //               <div>
  //                 <label htmlFor="">
  //                   Are you sure you want to claim this profile?
  //                 </label>
  //               </div>
  //               <div className="row">
  //                 <div className="col">
  //                   <Link to="/">
  //                     <Button
  //                       onClick={() => {
  //                         // deleteEvent(event._id);
  //                         claimUser('user_id123');
  //                       }}
  //                       className="col btn btn-danger"
  //                     >
  //                       Yes
  //                     </Button>
  //                   </Link>
  //                 </div>
  //                 <div className="col">
  //                   <Button onClick={() => setDeleteButtonClicked(false)}>
  //                     no
  //                   </Button>
  //                 </div>
  //               </div>
  //             </div>
  //           ) : (
  //             <></>
  //           )}
  //         </Modal.Footer>
  //       </Modal>
  //       {/* render attendees under attendees h1 or bring the attendees to a child component */}
  //     </div>
  //   </Fragment>
  // );
  const notEditingRenderNothing = <Fragment />;

  const isOwner = () => {
    console.log('checking if user is a ownerr');
    for (let i = 0; i < studio?.owners?.length; i++) {
      if (studio.owners[i].toString() === user?._id) {
        console.log('si senor is owner');
        return true;
      }
    }
    return false;
  };

  const renderAddLevel = (
    <Fragment>
      {!visible ? (
        <div>
          <div className="row">
            {/* {renderSalsaButton()} */}
            {!visible &&
              user &&
              isOwner() && (
                <div className="col">
                  <button
                    onClick={() => setAddLevelVisible(!addLevelVisible)}
                    class="btn btn-secondary"
                  >
                    {addLevelVisible ? 'Cancel' : 'Add Level'}
                  </button>
                </div>
              )}
          </div>
          <hr />
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );

  const renderLevel = (
    <Fragment>
      <div>
        {!visible && (
          <div className="col">
            <button
              onClick={() => setLevelsVisible(!levelsVisible)}
              class="btn btn-secondary"
              style={{
                width: '140px',
                backgroundColor: levelsVisible ? 'rgb(1, 126, 252)' : 'white',
                color: levelsVisible ? 'white' : 'black',
                borderColor: levelsVisible ? 'white' : 'black',
                borderStyle: 'solid',
                fontWeight: 'lighter',
                border: '0.5px solid black',
              }}
            >
              {levelsVisible ? 'Hide' : 'Levels'}
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
  const renderPerformances = (
    <Fragment>
      <div>
        {!visible && (
          <div className="col">
            <button
              onClick={() => setPerformancesVisible(!performancesVisible)}
              class="btn btn-secondary"
              style={{
                width: '140px',
                marginTop: '10px',
                marginBottom: '10px',
                backgroundColor: performancesVisible
                  ? 'rgb(1, 126, 252)'
                  : 'white',
                color: performancesVisible ? 'white' : 'black',
                borderColor: performancesVisible ? 'white' : 'black',
                borderStyle: 'solid',
                fontWeight: 'lighter',
                border: '0.5px solid black',
              }}
            >
              {performancesVisible ? 'Hide' : 'Performances'}
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
  const [artistOptionsPopupSelected, setArtistOptionsPopupSelected] = useState(
    false
  );
  const handleCloseArtistOptions = () => {
    setArtistOptionsPopupSelected(!artistOptionsPopupSelected);
  };
  const popupArtistOptions = () => {
    setArtistOptionsPopupSelected(!artistOptionsPopupSelected);
  };

  const [showCurriculaPopup, setShowCurriculaPopup] = useState(false);

  const [addCurriculumSelected, setAddCurriculumSelected] = useState(false);
  const handleCloseCurricula = () => {
    console.log('closing ');
    setShowCurriculaPopup(false);
  };
  if (loading) {
    return <h2>Loading...</h2>;
  }

  return (
    <div className="row">
      <div
        className="promotionText"
        style={{ backgroundColor: '#00b300', color: 'white' }}
      >
        {location?.state?.messageText ? (
          <div>
            <h4>{location.state.messageText}</h4>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* <div className="col-md-12"> */}
      <div className="col-md-12">
        <div className="card card-body bg-primary text-white mb-3">
          <StudioClaimOptionsPopup
            artistOptionsPopupSelected={artistOptionsPopupSelected}
            handleClose={handleCloseArtistOptions}
            studio_id={studio_id}
          />
          <div className="row text-center">
            {!studio?.owners?.length > 0 ? (
              <div className="row">
                <Button
                  onClick={() => {
                    popupArtistOptions();
                  }}
                  style={{
                    width: 'max-content',
                    alignContent: 'center',
                    backgroundColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'black',
                    padding: 'inherit',
                    color: 'black',
                  }}
                  className="all-center"
                >
                  Claim
                </Button>
              </div>
            ) : (
              <></>
            )}
            <div className="col col-md-3 m-auto">
              <img
                className="rounded-circle"
                //   src={profile.user.avatar}
                // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
                // src={image}
                src={studio?.image ? studio?.image.url : image}
                alt=""
                height="150"
                width="150"
              />
            </div>

            <h3 className="display-4 text-center">
              {/* {user?.first_name + ' ' + user?.last_name} */}
              {studio?.name}
            </h3>

            <div className="col">
              <Button
                onClick={() => {
                  //   handleLike(user._id);
                  //   setLikeCount(likeCount + 1);
                  //   setDisabled(true);
                  //   console.log(user._id);
                }}
                // disabled={disabled || user.likes.includes(user?._id)}
              >
                {/* {user.likes.includes(user?._id) ? liked : notLiked}{' '} */}
              </Button>
              {/* <label style={{ fontSize: '14px' }}>{likeCount} likes</label> */}
            </div>
            <h4>{studio?.city + ', ' + studio?.state}</h4>

            {/* <h4>{user?.location}</h4>
            <Link to={`/my-connections`} state={{ target_user: user }}>
              {user?.connections?.length} Connections
            </Link> */}
            <div className="row">
              {/* {connected()} */}
              <div className="col">
                {/* <Button
                      variant="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        console.log('copied text');
                        copyClicked = true;
                      }}
                    >
                      Share Profile
                    </Button> */}
                {renderOptions()}

                {isOwner() || user?.email === 'airmeus.team@gmail.com' ? (
                  //  || user?.email === 'jzhuerta997@gmail.com' ? (
                  <div>
                    <div>
                      <Button onClick={handleShow}>Edit</Button>
                    </div>

                    {show ? (
                      <EditCompany
                        studio={studio}
                        showModal={show}
                        setShow={setShow}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col">{premiumButtonOptions}</div>
              {/* <div className="col">
                <Link to={`/studio/${user?.dance_company_id}`}>
                  <Button>{user?.dance_company_name}</Button>
                </Link>
              </div>
              {user?.instagram_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Instagram: {user?.instagram_name}
                </h5>
              ) : (
                <></>
              )}
              {user?.tiktok_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Tiktok: {user?.tiktok_name}
                </h5>
              ) : (
                <></>
              )}

              {user?.website_name ? (
                <h5 style={{ fontSize: '16px' }}>
                  {' '}
                  Website: {user?.website_name}
                </h5>
              ) : (
                <></>
              )}

              {user?.website_name ||
              user?.tiktok_name ||
              user?.instagram_name ? (
                <hr />
              ) : (
                <></>
              )}
              <h5 style={{ fontSize: '16px' }}>{user?.about_you}</h5> */}
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col">
            <div className="">
              <Button>Members</Button>
            </div>
          </div> */}
          <div className="col">
            <div className="">
              <button
                onClick={() => {
                  setUpcomingEventsVisible(!upcomingEventsVisible);
                }}
                class="btn btn-secondary"
                style={{
                  width: '140px',
                  backgroundColor: upcomingEventsVisible
                    ? 'rgb(1, 126, 252)'
                    : 'white',
                  color: upcomingEventsVisible ? 'white' : 'black',
                  borderColor: upcomingEventsVisible ? 'white' : 'black',
                  borderStyle: 'solid',
                  fontWeight: 'lighter',
                  border: '0.5px solid black',
                }}
              >
                {upcomingEventsVisible ? 'Close' : 'Upcoming Events'}
              </button>
            </div>
          </div>
          <div className="col">{renderLevel}</div>
          <div className="col">{renderPerformances}</div>
          <div className="row">
            <div className="col" style={{ paddingBottom: '14px' }}>
              <Button
                onClick={() => setShowCurriculaPopup(!showCurriculaPopup)}
              >
                Classes
              </Button>
            </div>
            <div className="col">
              <Button
                onClick={() => {
                  setAddCurriculumSelected(!addCurriculumSelected);
                }}
              >
                Add Curriculum
              </Button>
            </div>
          </div>

          {addCurriculumSelected ? (
            <div>
              <h5>Adding Curriclulm..fields</h5>
              <BuildCurriculumPopup3_v02
                buildCurriculumClicked={addCurriculumSelected}
                school_id={studio_id}
              />
            </div>
          ) : (
            <></>
          )}

          <div
            class="container-fluid  overflow-auto example"
            style={{ padding: '0px' }}
          >
            {/* curr */}

            <Curricula
              school_id={studio._id}
              curriculaForSchool={curriculaForSchool}
            />
          </div>
          {/* {showCurriculaPopup && (
            <div>
              <Modal show={showCurriculaPopup} onHide={handleCloseCurricula}>
                <Modal.Header closeButton>
                  <Modal.Title>Classes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Curricula school_id={studio._id} />
                </Modal.Body>
              </Modal>
            </div>
          )} */}

          {/* <div className="col">
            <div className="">
              <Button>Admin</Button>
            </div>
          </div> */}
          <hr />
        </div>
        <div className="row">
          {/* {!visible &&
            !addLevelVisible &&
            !levelsVisible && (
              <div className="col">
                <h3>Members</h3>
              </div>
            )} */}

          {!addLevelVisible &&
            !levelsVisible &&
            user &&
            isOwner() && (
              <div className="col">
                <button
                  onClick={() => setVisible(!visible)}
                  class="btn btn-secondary"
                >
                  {visible ? 'Cancel' : 'Add Member'}
                </button>
              </div>
            )}

          {/* commented on 7.20.23 to put in under the levels btn */}

          {/* {!visible &&
            user &&
            isOwner() && (
              <div className="col">
                <button
                  onClick={() => setAddLevelVisible(!addLevelVisible)}
                  class="btn btn-secondary"
                >
                  {addLevelVisible ? 'Cancel' : 'Add Level'}
                </button>
              </div>
            )} */}
          {/* commented on 7.20.23 to put in the same line as membres and upcoming events buttons */}
          {/* {!visible && (
            <div className="col">
              <button
                onClick={() => setLevelsVisible(!levelsVisible)}
                class="btn btn-secondary"
              >
                {levelsVisible ? 'Hide' : 'Levels'}
              </button>
            </div>
          )} */}
        </div>
        {visible && (
          <div className="form-container">
            <form onSubmit={onSubmit2}>
              <div className="form-group">
                <h3>Add New Member</h3>
                <input
                  id="dancer_first_name"
                  type="text"
                  name="dancer_first_name"
                  placeholder="First Name"
                  value={dancer_first_name}
                  onChange={onChange}
                  required
                />
                <input
                  id="dancer_last_name"
                  type="text"
                  name="dancer_last_name"
                  placeholder="Last Name"
                  value={dancer_last_name}
                  onChange={onChange}
                  required
                />
                <label htmlFor="">Role</label>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="student"
                    id="student"
                    value="student"
                    // onChange={onChangeRoles}
                    onChange={onChange3}
                    checked={roles.includes('student')}
                  />

                  <label class="form-check-label" for="student">
                    student
                  </label>
                </div>

                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="instructor"
                    id="instructor"
                    value="instructor"
                    // onChange={onChangeRoles}
                    onChange={onChange3}
                  />

                  <label class="form-check-label" for="instructor">
                    instructor
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="staff"
                    id="staff"
                    value="staff"
                    // onChange={onChangeRoles}
                    onChange={onChange3}
                  />

                  <label class="form-check-label" for="staff">
                    staff
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    name="director"
                    id="director"
                    value="director"
                    // onChange={onChangeRoles}
                    onChange={onChange3}
                  />

                  <label class="form-check-label" for="director">
                    director
                  </label>
                </div>

                {/* <label for="birthday">Birthday:</label>
  <input type="date" id="birthday" name="birthday"> */}
              </div>

              <input
                type="submit"
                value="Add Member"
                class="btn btn-secondary"
              />
            </form>
          </div>
        )}

        {!visible &&
          !addLevelVisible &&
          !levelsVisible && (
            <div className="row">
              <div className="col">
                <h3>Members</h3>
              </div>
              {user ? (
                <div className="col">{block}</div>
              ) : (
                <>
                  <Link to="/login">
                    <Button>log in to join your studio</Button>
                  </Link>
                </>
              )}
            </div>
          )}

        {!visible &&
          !addLevelVisible &&
          !levelsVisible &&
          !upcomingEventsVisible &&
          !performancesVisible && (
            <div>
              <CompanyMembersByRole studio={studio} members={members} />
              {/* {members.map((member) => (


                <StudioMember member={member} studioOwners={studio?.owners} />


              ))} */}

              {/* {members.map((member) => (
                <div className="row bigScreenAttendee smallScreenAttendee">
                  <div className="col-3">
                    <img
                      className="rounded-circle"
                      src={image}
                      alt=""
                      height="50"
                      width="50"
                    />
                  </div>
                  <div className="col">                  
                    <Link
                      to={`${claimed(member)}`}
                      state={{ user: member }}
                      style={{ color: 'black' }}
                    >
                      <h5>{member?.first_name + ' ' + member?.last_name}</h5>
                    </Link>
                    <Link
                      to={`/update/dancer/${member?._id}`}
                      state={{ user: member }}
                    >
                      <Button>Update Skills</Button>
                    </Link>
                    <div>
                      <Button onClick={() => claimUser()}>Claim User</Button>
                      {show ? editingEvent2 : notEditingRenderNothing}
                    </div>
                  </div>
                  <hr />
                </div>
              ))} */}
            </div>
          )}

        {addLevelVisible && (
          <div>
            <AddStudioLevel />
          </div>
        )}

        {levelsVisible && (
          <div>
            {renderAddLevel}
            <div className="row">
              <div className="col">
                <h3>
                  {studio?.levels_and_skills.length > 0
                    ? `levels below`
                    : `No Levels Yet`}
                </h3>
              </div>
              <div className="col">
                <Link to="/curriculum-marketplace">
                  {' '}
                  <Button
                    style={{
                      width: '170px',
                      margin: '12px',
                      backgroundColor: 'white',
                      color: 'rgb(1, 126, 252)',
                      borderStyle: 'solid',
                      borderColor: 'rgb(1, 126, 252)',
                      borderWidth: '1px',
                      borderRadius: '10px',
                    }}
                  >
                    Curriculum Marketplace
                  </Button>
                </Link>
              </div>
            </div>

            <div>
              {studio?.premium_subscription?.premium_subscriber ? (
                <Levels studio_levels={studio?.levels_and_skills} />
              ) : (
                // <></>

                <h5>
                  this is a premium feature. Go Pro with{' '}
                  <a
                    href={`/go-pro-subscription/${studio._id}`}
                    style={{ color: 'blue' }}
                  >
                    Premium Studio
                  </a>
                </h5>
              )}{' '}
            </div>
          </div>
        )}

        {performancesVisible && (
          <div>
            {all_performances.length === 0 ? (
              <div>
                <h1>no performances yet</h1>
              </div>
            ) : (
              <></>
            )}

            {all_performances.length > 0 &&
              all_performances.map((event) => (
                <div>
                  <CompanyPerformances event={event} />

                  {/* <h1>{event.event_name}</h1> */}
                </div>
              ))}
          </div>
        )}

        <div>
          {upcomingEventsVisible && <h1>events</h1>}
          {upcomingEventsVisible &&
            companyEvents?.map((event) => (
              <div>
                <EventItem event={event} />
                {/* <Link
                  to={`/event/${event._id}`}
                  state={{ event: event }}
                  style={{ color: 'black' }}
                >
                  {event.name}
                </Link> */}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Company;
