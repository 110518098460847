import React, {
  useContext,
  useEffect,
  Component,
  Fragment,
  useState,
} from 'react';
// import isEmpty from '../../validation/is-empty';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import UserContext from '../../context/user/userContext'; // might have to capitalize
// import { CompositionPage } from 'twilio/lib/rest/video/v1/composition';
import TopListContext from '../../context/topList/topListContext';
import StudioContext from '../../context/studio/studioContext';
import { useAuth } from '../../context/auth/AuthState';
import MemberRoles from './MemberRoles';

// import AddStudioLevel from './AddStudioLevel';
// import Levels from './Levels';
// import EventItem from '../events/EventItem';
// import Modal from 'react-bootstrap/Modal';
// import Form from 'react-bootstrap/Form';
// import { Divider } from '@material-ui/core';
const StudioMember = (props) => {
  const topListContext = useContext(TopListContext);
  const { likeDancer } = topListContext;

  const studioContext = useContext(StudioContext);
  const {
    getStudioById,
    studio,
    getStudioMembers,
    // members,
    // addNewMember,
    getStudioLevels,
    // studio_levels,
    // companyEvents,
    getUpcomingEvents,
    sendClaimProfileRequest,
  } = studioContext;

  const userContext = useContext(UserContext);

  const { connectToUser } = userContext;
  let copyClicked = false;
  const location = useLocation();
  //   const { user } = location.state;

  // const { user, current_user_id, isCurrentUser } = props;
  const { studio_id } = useParams();

  const { member, studioOwners } = props;

  useEffect(() => {
    // console.log('studio_id');
    // console.log(studio_id);
    // //get studio by id
    // getStudioById(studio_id);
    // getStudioMembers(studio_id);
    // getStudioLevels(studio_id);
    // console.log('studio from company');
    // console.log(studio);
    // getUpcomingEvents(studio_id);
    // eslint-disable-next-line
  }, []);

  const isOwner = () => {
    for (let i = 0; i < studio?.owners?.length; i++) {
      if (studio.owners[i].toString() === user?._id) {
        return true;
      }
    }
    return false;
  };

  let image;
  //   if (user?.image) {
  //     image = user?.image.url;
  //   } else {
  //     image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';
  //   }

  image = 'https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png';

  const claimed = (dancer) => {
    if (dancer?.claimed) {
      console.log(' claimed');
      return `/user/profile/${dancer.claimed_id}`;
    }

    if (dancer?.autoJoined) {
      console.log(' claimed');
      return `/user/profile/${dancer._id}`;
    } else {
      console.log('not claimed');
      return `/dancer-profile/${dancer?._id}`;
    }
  };
  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);

  const [show, setShow] = useState(false);
  const [showSentMessage, setShowSentMessage] = useState(false);
  const [authState, authDispatch] = useAuth();
  const { error, isAuthenticated } = authState;

  const { user } = authState;
  const claimUser = () => {
    // e.preventDefault();
    setShow(false);
    setShowSentMessage(true);
    console.log('member');
    console.log(member);
    const claimRequestObj = {
      studio_id,
      studioOwners,
      requestor_id: user._id,
      requestor_name: user.name,
      requestor_email: user.email,
      requested_profile_id: member._id,
      requested_profile_name: member.first_name + member.last_name,
    };
    // console.log('claimRequestOb');
    // console.log(claimRequestObj);

    //in future, if emails match we can automatically link the accounts
    //but the owner would need to provide that email when adding users
    if (user) {
      sendClaimProfileRequest(claimRequestObj);
    }
    console.log('claiming user');
  };

  const editingEvent2 = (
    <Fragment>
      <div>
        <div>
          <div>
            <label htmlFor="">
              Are you sure you want to claim this profile?
            </label>
          </div>
          <div className="row">
            <div className="col" style={{ color: '#1E90FF' }}>
              {/* <Link to="/"> */}
              <Button
                onClick={() => {
                  // deleteEvent(event._id);
                  claimUser();
                }}
                // className="col btn btn-danger"
              >
                Yes
              </Button>
              {/* </Link> */}
            </div>
            <div className="col">
              <Button onClick={() => setShow(false)}>No</Button>
            </div>
          </div>
        </div>

        {/* render attendees under attendees h1 or bring the attendees to a child component */}
      </div>
    </Fragment>
  );
  const notEditingRenderNothing = <Fragment />;

  return (
    <div>
      <div className="row bigScreenAttendee smallScreenAttendee">
        <div className="col-3">
          <img
            className="rounded-circle"
            //   src={profile.user.avatar}
            // src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png"
            src={image}
            alt=""
            height="50"
            width="50"
          />
        </div>
        <div className="col">
          {/* link may have to be dancer-profile */}
          {/* <Link to={`/user/profile/${member._id}`}> */}

          <Link
            to={`${claimed(member)}`}
            state={{ user: member }}
            style={{ color: 'black' }}
          >
            {member?.first_name ? (
              <h5>{member?.first_name + ' ' + member?.last_name}</h5>
            ) : (
              <h5>{member?.name}</h5>
            )}
          </Link>

          <div className="row">
            {/* {member.roles.map((role) => (

              <h5>{role}</h5>
            ))} */}
            <MemberRoles roles={member?.roles} />
          </div>
          {user && isOwner() ? (
            <Link
              to={`/update/dancer/${member?._id}`}
              state={{ user: member, studio_id: studio_id }}
              style={{ color: 'rgb(1, 126, 252)' }}
            >
              Update Skills
            </Link>
          ) : (
            <></>
          )}
          <div>
            {/* user should only have claimedTopDancerID if theyve claimed an account already */}
            {!member?.claimed &&
            !user?.claimedTopDancerID &&
            user &&
            !isOwner() &&
            !user?.autoJoined ? (
              <Button onClick={() => setShow(true)}>Claim User</Button>
            ) : (
              <></>
            )}
            {show ? editingEvent2 : notEditingRenderNothing}
            {showSentMessage ? (
              <label style={{ color: '#409E40' }}>
                Request sent to studio. They will follow up shortly.
              </label>
            ) : (
              <></>
            )}
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default StudioMember;
